import { PDFViewer } from '@react-pdf/renderer';
import { FC } from 'react';
import { PdfRosterDocument } from './PdfRosterDocument';
import { usePdfRosterProps } from './useRosterPrintProps';
import { RosterProvider } from '@/pages/daily/roster/providers/RosterService';
import { ReassignmentProvider } from '@/pages/daily/roster/providers/ReassignmentService';

export const PdfRosterPrintTestPage: FC = () => {
  return (
    <RosterProvider>
      <ReassignmentProvider>
        <PdfRosterPrintTestPageContainer />
      </ReassignmentProvider>
    </RosterProvider>
  );
};

const PdfRosterPrintTestPageContainer: FC = () => {
  const printProps = usePdfRosterProps();

  return (
    <div>
      <PDFViewer className="fixed h-screen w-screen top-0">
        {printProps.workingRoster && (
          <PdfRosterDocument
            {...printProps}
            workingRoster={printProps.workingRoster}
          />
        )}
      </PDFViewer>
    </div>
  );
};
