import { FC } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { CheckboxGroup } from '@/components/CheckboxGroup';
import { useStaffColumns } from './useStaffColumns';
import { TableColumnGroup } from './staffTable/StaffTable';

export type GroupId = string;

export interface CheckedState {
  [groupId: string]: boolean;
}

interface Props {
  checkedState: CheckedState;
  setCheckedState: (newState: CheckedState) => void;
}

export const StaffSettingsGroupsHeader: FC<Props> = ({ checkedState, setCheckedState }) => {
  const groups: TableColumnGroup[] = useStaffColumns();
  const handleCheckboxChange = (groupId: GroupId) => {
    const newState = { ...checkedState, [groupId]: !checkedState[groupId] };
    setCheckedState(newState);
    localStorage.setItem('checkedStaffSections', JSON.stringify(newState));
  };

  return (
    <SettingsSectionHeader hasBottomBorder={true}>
      <div className="flex justify-between">
        <div className="flex gap-5">
          <CheckboxGroup groups={groups} checkedState={checkedState} onCheckboxChange={handleCheckboxChange} />
        </div>
      </div>
    </SettingsSectionHeader>
  );
};
