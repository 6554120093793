import { SeverityType } from '@/components/warning/warningDefinitions';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterWarningModel, WarningsProps } from '../warnings.types';

export const getOutsideHoursShiftWarnings = (shift: WorkingShift, props: WarningsProps): RosterWarningModel[] => {
  const { workingRoster, strings } = props;
  const { startTime, endTime } = workingRoster;

  if (shift.start === undefined || shift.end === undefined) {
    return [];
  }

  const shiftWarnings: RosterWarningModel[] = [];

  if (shift.start && shift.start < startTime) {
    shiftWarnings.push({
      severity: SeverityType.CRITICAL,
      name: strings.beforeStartShift.message,
      details: [strings.beforeStartShift.description(secondsToTimeString(startTime))],
      shiftId: shift.id
    });
  }

  if (shift.end && shift.end > endTime) {
    shiftWarnings.push({
      severity: SeverityType.CRITICAL,
      name: strings.afterEndShift.message,
      details: [strings.afterEndShift.description(secondsToTimeString(endTime))],
      shiftId: shift.id
    });
  }

  return shiftWarnings;
};
