import { InactiveLeaveTooltip } from '@/components/tooltip/InactiveLeaveTooltip';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { useAdvancedLeaveDataForEmployee } from '@/services/staff/advancedLeave';
import { useAdvancedLeaveColumns } from '@/services/summary/useCompliance';
import { useSummary } from '@/services/summary/useSummaryService';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';

interface Props {
  employeeId: string;
}

export const AvailabilityDetailsLeaveForPeriod: FC<Props> = ({ employeeId }) => {
  const days = useDaysInCurrentMonth();
  const { homeEmployeesSummary: summary } = useSummary(days[0], days[days.length - 1]);

  if (!summary) {
    console.error('No summary found');
    return;
  }

  const employeeSchedule = summary.find((item) => item.id === employeeId);

  if (!employeeSchedule) {
    console.error('No employee schedule found');
    return;
  }

  const leaveCols = useAdvancedLeaveColumns([employeeSchedule.schedule]);
  const advancedLeave = useAdvancedLeaveDataForEmployee(employeeSchedule.schedule, leaveCols);

  return (advancedLeave &&
    <div className="grid grid-cols-5 gap-4">
      <div className="text-gray-500 col-span-2">{strings.settings.manageAvailability.leaveForPeriod}</div>
      <div className="text-gray-500 text-center">{strings.common.total}</div>
      <div className="text-gray-500 text-center">{strings.summary.max}</div>
      <div className="text-gray-500 text-center">{strings.summary.min}</div>
      {advancedLeave?.advancedLeaveData.map((leaveType) => {
        const name = leaveType.properties.displayName ? leaveType.properties.displayName : leaveType.name;
        const max = leaveType.limitSettings.max.value === 0 ? '-' : leaveType.limitSettings.max.value;
        const min = leaveType.limitSettings.min.value === 0 ? '-' : leaveType.limitSettings.min.value;

        return (
          <>
            <div className="col-span-2">
              {leaveType.active ? 
                name : 
                <div className="flex">
                  <span className="flex-1">{name}</span>
                  <InactiveLeaveTooltip leaveName={name}/>
                </div>}
            </div>
            <div className="text-center">{leaveType.count}</div>
            <div className="text-center">{max}</div>
            <div className="text-center">{min}</div>
          </>
        )
      })}
    </div>
  );
};
