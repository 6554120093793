import * as React from 'react';

export enum KeyColor {
  DEFAULT = '#000000',
  REASSIGNED = '#E8AA14',
  UNASSIGNED = 'repeating-linear-gradient(45deg, rgba(194, 57, 52, 0.94), rgba(194, 57, 52, 0.94) 6px, rgb(194, 57, 52) 10px, rgb(194, 57, 52) 16px)'
}

export interface KeyProps {
  label: string;
  textColor?: KeyColor;
  dotColor?: KeyColor;
}

export const Key: React.FC<KeyProps> = ({ label, textColor = KeyColor.DEFAULT, dotColor = KeyColor.DEFAULT }) => (
  <div className="px-2 h-8 rounded-md flex items-center gap-2 justify-center text-md font-bold bg-[transparent]">
    <div style={{ background: dotColor }} className="h-6 w-6 rounded-full"></div>
    <p style={{ color: textColor }}>{label}</p>
  </div>
);
