import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { convertToUtcDate, dateToApiFormat, secondsToHours, secondsToTimeString } from '@/helpers/dateHelper';
import {
  ReadRoster,
  ReadTemplate,
  UpdateRoster,
  UpdateRosterPosition,
  UpdateTemplate,
  UpdateTemplatePosition,
  UpdateTemplatePositionTimeSlot
} from '@/services/gql/graphql.generated';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { strings } from '@/services/translation/strings';
import { useCallback } from 'react';
import { DailyProperties, WorkingShift, WorkingShiftSubsection } from '../daily.types';
import { BLANK_GUID, workingIdToGqlId } from '../dailyHelper';
import { useBuildHourSummary } from '../useHourSummary';
import { AvailableShiftSlot } from './employeeSelector/useEmployeesForShift';
import { WorkingRoster } from './roster.types';

const updateStatusAndTimestamps = (
  workingRoster: WorkingRoster,
  gqlStatus: string,
  gqpLastModified?: string,
  gqlPublicationTime?: string
) => {
  workingRoster.published = gqlStatus === 'Published' || gqlStatus === 'Republished';
  workingRoster.lastSaved = convertToUtcDate(gqpLastModified);
  workingRoster.lastPublished = convertToUtcDate(gqlPublicationTime);
};

export const useGqlRosterToWorkingRoster = () => {
  const buildHourSummary = useBuildHourSummary();
  const defaultEnd = useSystemSettings(state => state.settings.maxTime);
  const defaultStart = useSystemSettings(state => state.settings.minTime);
  const { allTemplateOptions: templateOptions } = useTemplatesForLocation();

  return useCallback(
    (gqlRoster: ReadRoster) => {
      if (!gqlRoster.stations || !gqlRoster.stations[0].positions) {
        throw Error('Stations are not configured correctly in GQL Roster.');
      }

      const gqlShifts = gqlRoster.stations[0].positions;
      const workingShifts = gqlShifts.map(gqlShift => {
        let breaks: WorkingShiftSubsection[];
        if (!gqlShift.breaks) {
          breaks = [];
        } else {
          // Using brek instead of break, because it's a typescript keyword.
          breaks = gqlShift.breaks.map(brek => ({
            start: brek.start,
            end: brek.end,
            id: brek.id,
            type: ItemTypes.BREAK
          }));
        }

        const tasks: WorkingShiftSubsection[] = [];
        gqlShift.timeSlots?.forEach(slot => {
          try {
            if (slot.properties) {
              const properties = JSON.parse(slot.properties);
              if (properties.type === ItemTypes.TASK) {
                tasks.push({
                  start: slot.start,
                  end: slot.end,
                  id: slot.id,
                  type: ItemTypes.TASK,
                  typeContext: properties.context
                });
              }
            }
          } catch (error) {}
        });

        let contractId: string | undefined = undefined;

        if (gqlShift.employee?.contract?.contractTypeId) {
          contractId = gqlShift.employee?.contract?.contractTypeId;
        }

        const workingShift: WorkingShift = {
          id: gqlShift.id,
          start: gqlShift.start,
          end: gqlShift.end,
          assignedEmployeeId: gqlShift.employee?.identityId,
          assignedStaffHomeLocationId: gqlShift.employee?.homeLocationId,
          assignedContractId: contractId,
          breaks: breaks,
          tasks: tasks,
          properties: gqlShift.properties ? JSON.parse(gqlShift.properties) : {},
          locationId: gqlRoster.location?.id
        };

        return workingShift;
      });

      let scratchPad: number[] | undefined = undefined;
      let notes: string | undefined = undefined;
      let rosterHistoryNotes: string | undefined = undefined;

      const templateUsed = templateOptions.find(t => t.id === gqlRoster.templateId);
      let templateName = templateUsed?.name;

      let startTime = defaultStart;
      let endTime = defaultEnd;
      let openTime: number | undefined = undefined;
      let closeTime: number | undefined = undefined;

      const gqlRosterHours = gqlRoster.shiftOperatingHours;
      const gqlTemplateHours = templateUsed?.template?.shiftOperatingHours;

      if (gqlTemplateHours) {
        // If we have times from the template, use these.
        openTime = gqlTemplateHours.openTime;
        closeTime = gqlTemplateHours.closeTime;
        startTime = gqlTemplateHours.startTime !== undefined ? gqlTemplateHours.startTime : startTime;
        endTime = gqlTemplateHours.endTime !== undefined ? gqlTemplateHours.endTime : endTime;
      }

      if (gqlRosterHours) {
        // If the roster has times, apply these over the template ones.
        openTime = gqlRosterHours.openTime !== undefined ? gqlRosterHours.openTime : openTime;
        closeTime = gqlRosterHours.closeTime !== undefined ? gqlRosterHours.closeTime : closeTime;
        startTime = gqlRosterHours.startTime !== undefined ? gqlRosterHours.startTime : startTime;
        endTime = gqlRosterHours.endTime !== undefined ? gqlRosterHours.endTime : endTime;
      }

      try {
        if (gqlRoster.properties) {
          const properties = JSON.parse(gqlRoster.properties);

          notes = properties.notes as string;

          const scratchPadFromProperties = properties.scratchpad as number[];
          const expectedLength = secondsToHours(endTime - startTime) * 2;

          if (scratchPadFromProperties.length === expectedLength) {
            scratchPad = scratchPadFromProperties;
          }

          if (properties.libraryTemplateName && properties.importDate) {
            rosterHistoryNotes = strings.daily.templates.importNotes(
              properties.importDate,
              properties.libraryTemplateName
            );
          }

          if (!templateName) {
            templateName = properties.templateName;
          }
        }
      } catch (error) {}

      if (!scratchPad) {
        scratchPad = buildHourSummary(workingShifts, startTime, endTime);
      }

      const rosterDate = new Date(gqlRoster.rosterDate);

      const workingRoster: WorkingRoster = {
        shifts: workingShifts,
        id: gqlRoster.id,
        templateId: gqlRoster.templateId,
        scratchpad: scratchPad,
        date: rosterDate,
        notes,
        rosterHistoryNotes,
        startTime,
        endTime,
        openTime,
        closeTime,
        templateName
      };

      updateStatusAndTimestamps(workingRoster, gqlRoster.status, gqlRoster.lastModified, gqlRoster.publicationTime);

      const today = new Date();

      if (dateToApiFormat(today) > dateToApiFormat(rosterDate)) {
        workingRoster.readOnly = true;
      }

      return workingRoster;
    },
    [defaultEnd, defaultStart, buildHourSummary]
  );
};

export const useUpdateStatusAndTimestamps = () => {
  return useCallback((workingRoster: WorkingRoster, updatedRoster?: ReadRoster) => {
    if (updatedRoster) {
      updateStatusAndTimestamps(
        workingRoster,
        updatedRoster.status,
        updatedRoster.lastModified,
        updatedRoster.publicationTime
      );
    }
  }, []);
};

export const useWorkingRosterToGqlRoster = () => {
  const { allTemplateOptions: templateOptions } = useTemplatesForLocation();

  return useCallback((workingRoster: WorkingRoster, originalRoster: ReadRoster) => {
    if (!originalRoster.location) {
      throw Error('Roster must have location');
    }

    if (!originalRoster.stations || originalRoster.stations.length === 0) {
      throw Error('Rosters must have stations defined');
    }

    const positionList: UpdateRosterPosition[] = workingRoster.shifts.map((shift, index) => {
      const positionId = workingIdToGqlId(shift.id);

      const position: UpdateRosterPosition = {
        displayOrder: index + 1,
        id: positionId,
        start: shift.start,
        end: shift.end,
        employeeId: shift.assignedEmployeeId !== '' ? shift.assignedEmployeeId : undefined,
        properties: JSON.stringify(shift.properties || {}),
        breaks: shift.breaks.map(brek => ({ id: workingIdToGqlId(brek.id), start: brek.start, end: brek.end })),
        timeSlots: shift.tasks.map(task => ({
          id: workingIdToGqlId(task.id),
          start: task.start,
          end: task.end,
          properties: JSON.stringify({ type: ItemTypes.TASK, context: task.typeContext })
        }))
      };

      return position;
    });

    const templateUsed = templateOptions.find(t => t.id === workingRoster.templateId);

    const properties: DailyProperties = {
      scratchpad: workingRoster.scratchpad,
      notes: workingRoster.notes,
      startTime: workingRoster.startTime,
      endTime: workingRoster.endTime,
      templateName: templateUsed?.name || workingRoster.templateName
    };

    const gqlRoster: UpdateRoster = {
      id: originalRoster.id,
      locationId: originalRoster.location.id,
      status: originalRoster.status,
      rosterDate: originalRoster.rosterDate,
      templateId: workingRoster.templateId,
      afterHours: originalRoster.afterHours,
      platesTarget: originalRoster.platesTarget,
      stations: [
        {
          id: originalRoster.stations[0].id,
          stationId: originalRoster.stations[0].stationId,
          displayOrder: originalRoster.stations[0].displayOrder,
          excludeHours: originalRoster.stations[0].excludeHours,
          positions: positionList
        }
      ],
      properties: JSON.stringify(properties),
      shiftOperatingHours: {
        startTime: workingRoster.startTime,
        endTime: workingRoster.endTime,
        openTime: workingRoster.openTime,
        closeTime: workingRoster.closeTime
      }
    };

    return gqlRoster;
  }, []);
};

export const useWorkingRosterToGqlTemplate = () => {
  return useCallback(
    (
      workingRoster: WorkingRoster | undefined,
      usedTemplate: ReadTemplate | undefined,
      shouldIncludetasksInTemplate?: boolean,
      useBlankIds?: boolean
    ) => {
      if (!workingRoster) {
        throw Error('Working roster must be defined');
      }

      const properties: DailyProperties = {
        scratchpad: workingRoster.scratchpad
      };

      const hybridTemplateData: UpdateTemplate = {
        id: BLANK_GUID,
        name: '', // Will be set by caller
        locationId: -1, // Will be set by caller
        targetType: '', // Will be set by caller
        targetValue: 0, // Will be set by caller
        weekday: false, // Will be set by caller,
        shiftOperatingHours: {
          openTime: workingRoster.openTime,
          closeTime: workingRoster.closeTime,
          startTime: workingRoster.startTime,
          endTime: workingRoster.endTime
        },
        stations: [
          {
            id: BLANK_GUID,
            stationId: 1,
            displayOrder: 1,
            positions: rosterShiftsToTemplateStationPositions(
              workingRoster?.shifts,
              useBlankIds,
              shouldIncludetasksInTemplate
            ) as UpdateTemplatePosition[]
          }
        ],
        properties: JSON.stringify(properties)
      };
      return hybridTemplateData;
    },
    []
  );
};

const rosterShiftsToTemplateStationPositions = (
  shifts: WorkingShift[],
  useBlankIds?: boolean,
  extracttasks?: boolean
): UpdateTemplatePosition[] => {
  if (!shifts.length) {
    return [];
  }
  const stationPositions = shifts.map((shift, index) => {
    const breaks = shift?.breaks?.length
      ? shift.breaks.map(br => {
          return { id: getGqlId(br?.id, useBlankIds), start: br.start, end: br.end };
        })
      : [];

    const timeSlots: UpdateTemplatePositionTimeSlot[] = shift?.tasks?.length
      ? shift.tasks.map(task => {
          return {
            id: getGqlId(task?.id, useBlankIds),
            start: task.start,
            end: task.end,
            properties: JSON.stringify({ type: ItemTypes.TASK, context: task.typeContext })
          };
        })
      : [];

    const position: UpdateTemplatePosition = {
      id: getGqlId(shift?.id, useBlankIds),
      displayOrder: index + 1,
      start: shift.start,
      end: shift.end,
      breaks,
      timeSlots
    };

    if (extracttasks) {
      const tasks = shift?.tasks?.length
        ? shift.tasks.map(task => {
            return { id: getGqlId(task?.id, useBlankIds), start: task.start, end: task.end };
          })
        : [];
      (position as any)['tasks'] = tasks;
    }

    return position;
  });

  return stationPositions;
};

const getGqlId = (expectedId: string | number | undefined, useBlankId?: boolean): string => {
  expectedId = expectedId?.toString();
  return workingIdToGqlId(expectedId !== undefined && !useBlankId ? expectedId : 'CREATE');
};

/*
 * I'd like to apologise from the bottom of my heart for this type, but it's the only way.
 *
 * We have three competing definitions of a Shift in the system:
 * - The working roster version of a shift
 * - The monthly summary version of a shift
 * - The GQL version of a shift.
 *
 * I'd originally hoped to keep these definitions very separate, but someone accidentally
 * used the summary hooks in this component, and now it's very difficult to untangle.
 *
 * When we check if a staff member is available, we need to handle an array containing two
 * different types- the currently edited shifts are from the working roster types, but any
 * ghost shifts (ie. shifts from the previous day) are the summary type.
 *
 * There's a strong argument that it's unwise to solve the problem of having too many shift
 * types by creating a fourth shift type, but it's definitely going to be different this
 * time! Honestly!
 */
export interface RosterGeneralShift {
  start?: number;
  end?: number;
}

export const getAvailableSlots = (
  allShifts: RosterGeneralShift[],
  start: number,
  end: number,
  secondsBetween: number
) => {
  const shifts = allShifts.filter(s => s.start !== undefined && s.end !== undefined);

  if (!shifts?.length) {
    return [{ start: start, end: end, partiallyEnforcedStart: true, partiallyEnforcedEnd: true }];
  } else {
    const availableSlots: AvailableShiftSlot[] = [];

    const sorted = shifts.sort((a, b) => a.start! - b.start! || a.end! - b.end!);

    const firstSlotStart = start;
    const firstSlotEnd = Math.min(sorted[0].start! - secondsBetween, end);
    if (firstSlotEnd > firstSlotStart) {
      availableSlots.push({ start: firstSlotStart, end: firstSlotEnd, partiallyEnforcedStart: true });
    }

    for (let index = 1; index < sorted.length; index++) {
      const slotStart = Math.max(sorted[index - 1].end! + secondsBetween, start);
      const slotEnd = Math.min(sorted[index].start! - secondsBetween, end);

      if (slotStart < slotEnd) {
        availableSlots.push({ start: slotStart, end: slotEnd });
      }
    }

    const lastSlotStart = Math.max(sorted[sorted.length - 1].end! + secondsBetween, start);
    const lastSlotEnd = end;
    if (lastSlotEnd > lastSlotStart) {
      availableSlots.push({ start: lastSlotStart, end: lastSlotEnd, partiallyEnforcedEnd: true });
    }

    return availableSlots;
  }
};

export const getSelectedAvailableSlot = (
  availableSlots: AvailableShiftSlot[],
  requestedStartSlot: number = 0,
  requestedEndSlot: number = 0
) => {
  const matchedSlots = availableSlots.filter(s => s.end >= requestedStartSlot && s.start <= requestedEndSlot);
  const slotFound = matchedSlots.find(
    s =>
      (s.start <= requestedStartSlot && s.end >= requestedEndSlot) ||
      (s.start >= requestedStartSlot && s.end <= requestedEndSlot) ||
      (s.start <= requestedStartSlot && s.end <= requestedEndSlot && s.end > requestedStartSlot) ||
      (s.start >= requestedStartSlot && s.end >= requestedEndSlot && s.start < requestedEndSlot)
  );

  return slotFound;
};

export const getTimeAvailable = (selectedSlot: AvailableShiftSlot): string => {
  if (!selectedSlot) return '00:00 ~ 00:00';

  return `${secondsToTimeString(selectedSlot.start)} ~ ${secondsToTimeString(selectedSlot.end)}`;
};

export const toLower = (text: string): string => {
  return text && typeof text === 'string' ? text.toLowerCase() : text;
};
