import { Key, KeyColor } from '@/components/Key';
import { RadioButton } from '@/components/RadioButton';
import { DragDropButton } from '@/components/gantt/dragDrop/DragDropButton';
import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useTaskDisplay } from '@/services/tasks/useTaskDisplay';
import { useGetTaskIcon, useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { FC, HTMLAttributes, useMemo, useState } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { BsCupFill } from 'react-icons/bs';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';
import { DailyEditingType } from './daily.types';
import { itemTypeToColor } from './dailyHelper';

interface Props {
  type: DailyEditingType;
  readOnly?: boolean;
  showKeys?: boolean;
}

const DragDropSection: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children }) => {
  return <div className={twMerge(className, 'bg-background-mid p-2 border-2 rounded-md flex gap-5')}>{children}</div>;
};

const TASK_DISPLAY_LIMIT = 14;

export const DragDropButtons: FC<Props> = ({ type, readOnly, showKeys }) => {
  const tasks = useTasks();
  const mainType = type === DailyEditingType.ROSTER ? ItemTypes.ROSTER_SHIFT : ItemTypes.TEMPLATE_SHIFT;
  const getTaskIcon = useGetTaskIcon();

  const favouriteTaskIds = useLocalSettings(state => state.favouriteTaskIds);

  const [showAll, setShowAll] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const displayTask = useTaskDisplay();

  const filteredAndSortedTasks = useMemo(() => {
    const tasksToDisplay = tasks.filter(displayTask);
    const favouriteTasks = tasksToDisplay.filter(task => favouriteTaskIds.includes(task.id.toString()));
    const nonFavouriteTasks = tasksToDisplay.filter(task => !favouriteTaskIds.includes(task.id.toString()));

    let tasksToReturn = showAll ? [...favouriteTasks, ...nonFavouriteTasks] : [...favouriteTasks];

    if (!expanded) {
      tasksToReturn = tasksToReturn.slice(0, TASK_DISPLAY_LIMIT);
    }

    return tasksToReturn;
  }, [tasks, showAll, expanded]);

  return (
    <div className="w-full border-t px-5 py-2 space-y-2">
      <div className="flex justify-between items-center">
        {!readOnly && <div className="font-bold text-xl">{strings.daily.drag.instructions}</div>}
        <div className="flex gap-2">
          {showKeys && (
            <>
              <Key label={strings.keys.reassigned} dotColor={KeyColor.REASSIGNED} />
              <Key label={strings.keys.unassigned} dotColor={KeyColor.UNASSIGNED} />
            </>
          )}
        </div>
      </div>
      <div className="flex w-full gap-2">
        <DragDropSection>
          <div className="font-bold">{strings.daily.drag.shift}</div>
          <div className="flex flex-col gap-3 w-48">
            <DragDropButton
              name={strings.daily.drag.shiftTypes.work}
              color={itemTypeToColor(mainType)}
              item={{ type: mainType, main: true }}
              readOnly={readOnly}
              className="justify-center"
            />
            <DragDropButton
              name={strings.daily.drag.shiftTypes.break}
              color={itemTypeToColor(ItemTypes.BREAK)}
              item={{ type: ItemTypes.BREAK }}
              readOnly={readOnly}
              icon={<BsCupFill />}
              className="justify-center"
            />
          </div>
        </DragDropSection>
        <DragDropSection className="flex-1">
          <div>
            <div className="font-bold">{strings.daily.drag.tasks}</div>
            <div className="flex gap-2 items-center">
              <RadioButton checked={showAll} onClick={() => setShowAll(true)} />
              <div>{strings.daily.drag.showAll}</div>
            </div>
            <div className="flex gap-2 items-center">
              <RadioButton checked={!showAll} onClick={() => setShowAll(false)} />
              <div>{strings.daily.drag.showFavourites}</div>
              <AiOutlineStar />
            </div>
          </div>
          <div className="flex-1 flex gap-3 grid grid-cols-7">
            {filteredAndSortedTasks.map(task => (
              <DragDropButton
                key={task.id}
                name={task.displayName}
                color={task.color}
                item={{ type: ItemTypes.TASK, context: task.id.toString() }}
                readOnly={readOnly}
                textColor={task.textColor}
                icon={getTaskIcon(task.id.toString())}
                canFavourite={true}
                taskId={task.id}
                type={type}
              />
            ))}
          </div>
          {displayTask.length > TASK_DISPLAY_LIMIT && (
            <div className="flex flex-col items-center text-lg font-semibold">
              <p>{strings.daily.drag.expand}</p>
              <button onClick={() => setExpanded(!expanded)}>
                {expanded ? <FiChevronUp className="w-8 h-8" /> : <FiChevronDown className="w-8 h-8" />}
              </button>
            </div>
          )}
        </DragDropSection>
      </div>
    </div>
  );
};
