import { format } from 'date-fns';
import { useCallback } from 'react';
import { NotificationItem, NotificationStatus, NotificationType } from '../notifications/NotificationService';
import { strings } from '../translation/strings';
import { OptimiserJob, OptimiserNotificationContext, OptimiserStatus } from './optimiser.types';

export const parseOptimiserProperties = (name: string, properties: string) => {
  const propertiesArray: { name: string; value: string }[] = [];
  properties.split('&').forEach(propString => {
    const colonIndex = propString.indexOf(':');
    if (colonIndex > 0) {
      const splitPropString = {
        name: propString.substring(0, colonIndex),
        value: propString.substring(colonIndex + 1)
      };
      propertiesArray.push(splitPropString);
    }
  });

  const property = propertiesArray.find(prop => prop.name === name);
  if (property) {
    return property.value;
  }

  return '';
};

export const useJobToNotification = () => {
  // NOTE: There's a reason this is a hook, and not just a function.
  // Anything that uses the localised strings needs to be in a react component.
  // Otherwise, it determines the local language of the strings object
  // before the local settings can be applied to it.

  return useCallback((job: OptimiserJob) => {
    let text: string;
    let status: NotificationStatus;

    if (job.status === OptimiserStatus.FINISHED) {
      text = strings.optimiser.notification.generated;
      status = NotificationStatus.SUCCESS;
    } else if (job.status === OptimiserStatus.STARTED) {
      text = strings.optimiser.notification.progress;
      status = NotificationStatus.IN_PROGRESS;
    } else if (
      job.status === OptimiserStatus.QUEUED ||
      job.status === OptimiserStatus.STARTING ||
      job.status === OptimiserStatus.CREATED
    ) {
      text = strings.optimiser.notification.queued;
      status = NotificationStatus.IN_PROGRESS;
    } else if (job.status === OptimiserStatus.DELETED) {
      text = strings.optimiser.notification.deleted;
      status = NotificationStatus.SUCCESS;
    } else {
      text = strings.optimiser.notification.failed(job.error || '');
      status = NotificationStatus.FAIL;
    }

    const notification: NotificationItem<OptimiserNotificationContext> = {
      regularText: `${strings.optimiser.notification.rosterFor}`,
      boldText: `${format(job.startDate, 'yyyy/MM/dd')}~${format(job.endDate, 'yyyy/MM/dd')}`,
      colorizedText: text,
      context: {
        startDate: job.startDate,
        batchId: job.batchId
      },
      locationId: job.locationId,
      id: job.associatedNotificationId,
      type: NotificationType.ROSTER_GENERATION,
      status,
      date: job.started || job.created
    };

    return notification;
  }, []);
};
