import { Button } from '@/components/button';
import { ErrorMessage } from '@/components/error/ErrorMessage';
import { Modal } from '@/components/modal/Modal';
import { strings } from '@/services/translation/strings';
import { FC, useCallback, useState } from 'react';
import { EmployeeForShiftItem } from '../employeeSelector/employeeForShift.types';
import { useWorkingRoster } from './providers/WorkingRosterService';
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterShift } from './RosterShift';

export const RosterShifts: FC = () => {
  const { workingRoster, setWorkingRoster } = useWorkingRoster();

  const [isDeleteReassignmentModalOpen, setIsDeleteReassignmentModalOpen] = useState(false);

  const updateShift = useCallback(
    (shiftIndex: number) => (newShift: WorkingShift) => {
      if (!workingRoster) {
        throw Error('Working Roster should be set');
      }

      // updating working roster re-renders lots of components, and too expensive.
      // Deep comparison must be faster and avoids activating the Save button unnecessarily.
      const workingShiftAsString = JSON.stringify(workingRoster.shifts[shiftIndex]);
      const newShiftAsString = JSON.stringify(newShift);
      if (workingShiftAsString === newShiftAsString) {
        return;
      }

      const shifts = [...workingRoster.shifts];
      shifts.splice(shiftIndex, 1, newShift);
      setWorkingRoster({ ...workingRoster, shifts });
    },
    [setWorkingRoster, workingRoster]
  );

  const removeShift = useCallback(
    (shiftIndex: number) => () => {
      if (!workingRoster) {
        throw Error('Working Roster should be set');
      }

      const shifts = [...workingRoster.shifts];
      shifts.splice(shiftIndex, 1);
      setWorkingRoster({ ...workingRoster, shifts });
    },
    [setWorkingRoster, workingRoster]
  );

  const selectStaffMember = useCallback(
    (shiftIndex: number) => (selectedOption?: EmployeeForShiftItem, isStaffRequesting?: boolean) => {
      if (!workingRoster) {
        throw Error('Working Roster should be set');
      }

      const shifts = [...workingRoster.shifts];
      const shiftProperties = shifts[shiftIndex]?.properties || {};

      if (selectedOption === undefined) {
        delete shifts[shiftIndex].assignedEmployeeId;
        delete shifts[shiftIndex].assignedContractId;
      } else {
        shifts[shiftIndex].assignedEmployeeId = selectedOption.id;
        shifts[shiftIndex].assignedContractId = selectedOption.contractId;
        shifts[shiftIndex].assignedStaffHomeLocationId = selectedOption.details?.homeLocationId;

        const shiftProps = shifts[shiftIndex]?.properties;
        if (shiftProps) {
          if (shiftProperties?.requesting) {
            delete shiftProperties.requesting;
          }
        }
      }

      if (isStaffRequesting) {
        shiftProperties['requesting'] = true;
      }
      shifts[shiftIndex].properties = shiftProperties;

      setWorkingRoster({ ...workingRoster, shifts });
    },
    [setWorkingRoster, workingRoster]
  );

  const onCloseDeleteReassignmentModal = useCallback(() => {
    setIsDeleteReassignmentModalOpen(false);
  }, [setIsDeleteReassignmentModalOpen]);

  if (!workingRoster) {
    return <ErrorMessage message={strings.daily.roster.noRoster}></ErrorMessage>;
  } else {
    return (
      <>
        {workingRoster?.shifts.map((shift, index) => (
          <RosterShift
            key={shift.id}
            shift={shift}
            selectStaff={selectStaffMember(index)}
            removeShift={removeShift(index)}
            updateShift={updateShift(index)}
            showCannotRemoveModal={() => setIsDeleteReassignmentModalOpen(true)}
            readOnly={workingRoster.readOnly}
          />
        ))}
        <Modal onClose={onCloseDeleteReassignmentModal} open={isDeleteReassignmentModalOpen}>
          <p className={'m-4'}>{strings.daily.roster.deleteReassignedShiftWarning}</p>
          <div className="flex justify-end gap-5 items-center">
            <Button onClick={onCloseDeleteReassignmentModal}>{strings.common.ok}</Button>
          </div>
        </Modal>
      </>
    );
  }
};
