import { HeaderInfo } from '@/components/HeaderInfo';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { Timestamp } from '@/components/Timestamp';
import { Button } from '@/components/button';
import { ErrorMessage } from '@/components/error/ErrorMessage';
import { EditNotesFields } from '@/components/notes/NotesModal';
import { PageHeader } from '@/pages/layout/main/PageHeader';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate } from '@/services/gql/graphql.generated';
import { useMyPersonasAttributes } from '@/services/personas/useMyPersonaAttributes';
import { strings } from '@/services/translation/strings';
import { FC, Suspense, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';
import { Template } from '../Template';
import { TemplatePage } from '../TemplatePage';
import { TemplateAddModal } from '../menu/TemplateAddModal';
import { TemplateLibraryMenu } from './TemplateLibraryMenu';
import {
  TemplateLibraryProvider,
  useSelectedLibraryTemplate,
  useTemplateLibraryService,
  useWorkingLibraryTemplate
} from './TemplateLibraryService';
import { usePublishLibraryTemplate } from './usePublishLibraryTemplate';
import { useSaveLibraryTemplate } from './useSaveLibraryTemplate';
import { useValidateLibraryTemplateName } from './useValidateLibraryTemplateName';
import {TemplateProvider} from "@/pages/daily/template/shop/TemplateService";

interface TemplateLibraryHeaderProps {
  isSaveFetching: boolean;
}

export const TemplateLibraryHeader: FC<TemplateLibraryHeaderProps> = ({ isSaveFetching }) => {
  const { workingTemplate, setWorkingTemplate } = useWorkingLibraryTemplate();
  const { saveTemplate, fetching: saveFetching } = useSaveLibraryTemplate();
  const { publishTemplate, fetching: publishFetching } = usePublishLibraryTemplate();
  const templateList = useTemplateLibraryService(state => state.templateOptions);

  const onSave = async () => {
    const result = await saveTemplate();

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  };

  const onPublish = async () => {
    const result = await publishTemplate();

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  };

  const onClear = () => {
    if (!workingTemplate) {
      throw Error('Working template should be defined.');
    }

    workingTemplate.shifts = [];

    // reset the scratchpad
    workingTemplate.scratchpad = Array(workingTemplate.scratchpad.length).fill(0);

    setWorkingTemplate(workingTemplate);
  };

  const { saveNeeded } = useConfirm(state => ({ saveNeeded: state.confirmationNeeded }));

  return (
    <>
      {templateList.length > 0 && (
        <PageHeader className="w-full justify-between">
          <div className="flex gap-5 items-center">
            <TemplateLibraryMenu />
            {workingTemplate && (
              <>
                <HeaderInfo text={workingTemplate.version || '-'} label={strings.daily.templates.version}></HeaderInfo>
              </>
            )}
          </div>
          {workingTemplate && (
            <div className="flex gap-5">
              {workingTemplate.lastSaved && (
                <Timestamp
                  timestamp={workingTemplate.lastSaved}
                  label={strings.daily.roster.lastSaved}
                  color={'green'}
                />
              )}

              {workingTemplate.lastPublished && (
                <Timestamp
                  timestamp={workingTemplate.lastPublished}
                  label={strings.daily.roster.lastPublished}
                  color={'blue'}
                />
              )}

              <Button className="w-30" onClick={onClear}>
                {strings.daily.templates.clearTemplate}
              </Button>

              <Button
                className="w-24"
                onClick={onSave}
                loading={saveFetching || isSaveFetching}
                variant={saveNeeded ? 'submit' : undefined}
              >
                {strings.common.save}
              </Button>
              <Button className="w-24" onClick={onPublish} loading={publishFetching} variant={'primary'}>
                {strings.common.publish}
              </Button>
            </div>
          )}
        </PageHeader>
      )}
    </>
  );
};

interface TemplateLibraryPageContProps {
  setIsSaveFetching: (isSaveFetching: boolean) => void;
}

const TemplateLibraryPageCont: FC<TemplateLibraryPageContProps> = ({ setIsSaveFetching }) => {
  const { workingTemplate, setWorkingTemplate } = useWorkingLibraryTemplate();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const createTemplateAndNavigate = useTemplateLibraryService(s => s.createTemplateAndNavigate);
  const validateName = useValidateLibraryTemplateName();

  const templateData = useSelectedLibraryTemplate();
  const { saveTemplate, fetching } = useSaveLibraryTemplate();

  useEffect(() => {
    setIsSaveFetching(fetching);
  }, [fetching]);

  const submitNotes = async (data: EditNotesFields) => {
    if (!workingTemplate) {
      throw Error('Working template should be defined.');
    }
    workingTemplate.notes = data.notes;
    setWorkingTemplate(workingTemplate);
    saveTemplate();
  };


  if (!workingTemplate) {
    return (
      <div className="flex flex-col items-center gap-5 p-10">
        <TemplateAddModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onComplete={createTemplateAndNavigate}
          validateName={validateName}
          library={true}
        />
        <div>{strings.daily.templates.library.noneCreated}</div>
        <Button onClick={() => setIsAddModalOpen(true)} variant={'primary'}>
          {strings.daily.templates.library.create}
        </Button>
      </div>
    );
  } else {
    return (
      <Template
        workingTemplate={workingTemplate}
        setWorkingTemplate={setWorkingTemplate}
        templateData={templateData as ReadTemplate}
        submitNotes={submitNotes}
      />
    );
  }
};

export const TemplateLibraryPage: FC = () => {
  const { templateLibrarian } = useMyPersonasAttributes();

  // because both TemplateLibraryHeader and TemplateLibraryPageCont use saveTemplate,
  // the fetching state needs to be passed from TemplateLibraryPageCont to TemplateLibraryHeader
  // TemplateLibraryHeader has the save button
  const [isSaveFetching, setIsSaveFetching] = useState(false);

  if (!templateLibrarian) {
    return <ErrorMessage message={strings.daily.templates.library.notPermitted} />;
  }

  return (
    <TemplatePage>
      <TemplateProvider>
        <TemplateLibraryProvider>
            <TemplateLibraryHeader isSaveFetching={isSaveFetching} />
          <Suspense fallback={<LoadingIndicator />}>
            <TemplateLibraryPageCont setIsSaveFetching={setIsSaveFetching} />
          </Suspense>
        </TemplateLibraryProvider>
      </TemplateProvider>
    </TemplatePage>
  );
};
