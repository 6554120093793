import { FC, Suspense, useCallback, useState } from 'react';
import { TemplateAddModal } from '../menu/TemplateAddModal';
import { TemplateDeleteModal } from '../menu/TemplateDeleteModal';
import { TemplateMenuUpdateModal } from '../menu/TemplateUpdateModal';
import { TemplateLibraryDropdown } from './TemplateLibraryDropdown';
import { TemplateLibraryOption, useTemplateLibraryService } from './TemplateLibraryService';
import { useValidateLibraryTemplateName } from './useValidateLibraryTemplateName';

export const TemplateLibraryMenu: FC = () => {
  const createTemplateAndNavigate = useTemplateLibraryService(s => s.createTemplateAndNavigate);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState<TemplateLibraryOption | undefined>(undefined);
  const isEditModalOpen = !!templateToEdit;
  const [templateToDelete, setTemplateToDelete] = useState<TemplateLibraryOption | undefined>(undefined);
  const isDeleteModalOpen = !!templateToDelete;

  const validateName = useValidateLibraryTemplateName();

  const selectedTemplate = useTemplateLibraryService(s => s.selectedTemplateId);
  const setSelectedTemplate = useTemplateLibraryService(s => s.setSelectedTemplateId);
  const templateOptions = useTemplateLibraryService(s => s.templateOptions);
  const refetchTemplates = useTemplateLibraryService(s => s.refetchTemplateOptions);

  const onDeleteComplete = useCallback(() => {
    if (selectedTemplate === templateToDelete?.id) {
      const findNextTemplate = templateOptions.find(t => t.id !== templateToDelete?.id);
      setSelectedTemplate(findNextTemplate?.id);
    }
    refetchTemplates();
  }, [selectedTemplate, setSelectedTemplate, templateOptions, templateToDelete, refetchTemplates]);

  return (
    <>
      <TemplateAddModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onComplete={createTemplateAndNavigate}
        validateName={validateName}
        library={true}
      />
      <Suspense>
        {templateToEdit && (
          <TemplateMenuUpdateModal
            open={isEditModalOpen}
            onClose={() => setTemplateToEdit(undefined)}
            templateId={templateToEdit.id}
            defaultTemplateName={templateToEdit.name}
            defaultTagList={templateToEdit.template.templateTags}
            validateName={validateName}
            allowNameChange
          />
        )}
      </Suspense>
      {templateToDelete && (
        <TemplateDeleteModal
          open={isDeleteModalOpen}
          onClose={() => setTemplateToDelete(undefined)}
          templateIdToDelete={templateToDelete.id}
          publishedTemplateIdToDelete={templateToDelete.publishedVersionId}
          templateName={templateToDelete.name}
          onComplete={onDeleteComplete}
        />
      )}
      <TemplateLibraryDropdown
        onAdd={() => setIsAddModalOpen(true)}
        onEdit={setTemplateToEdit}
        onDelete={setTemplateToDelete}
      />
    </>
  );
};
