import { useGetRostersForMonth } from '@/services/rosters/useGetRostersForMonth';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useMemo } from 'react';

export const usePublishSummary = () => {
  const locationId = useLocalSettings(state => state.selectedLocationId);
  const { rosters, refetch } = useGetRostersForMonth(locationId);

  const summary = useMemo(() => {
    let latestPublishDate: string | undefined;
    let allPublished = true;

    if (rosters.length === 0) {
      allPublished = false;
    } else {
      rosters.forEach(roster => {
        if (!latestPublishDate || (roster.publicationTime && latestPublishDate < roster.publicationTime)) {
          latestPublishDate = roster.publicationTime;
        }

        if (roster.status !== 'Published' && roster.status !== 'Republished') {
          allPublished = false;
        }
      });
    }

    return { latestPublishDate, allPublished, rosterIds: rosters.map(roster => roster.id) };
  }, [rosters]);

  return { summary, refetch };
};
