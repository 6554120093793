import { TemplateShopOption } from '@/pages/daily/template/shop/TemplateService';
import { useFlag } from '@softwareimaging/backstage';
import { useMemo } from 'react';
import { ReadTemplate, useGetTemplatesForLocationQuery } from '../gql/graphql.generated';
import { useSelectedLocation } from '../settings/LocalSettingsProvider';
import { strings } from '../translation/strings';

// They generally want weeks to start on Mondays, and have a secret day of the week used for bank holidays.
const TEMPLATE_DAY_NUMBER_ORDER = [1, 2, 3, 4, 5, 6, 0, 7];
const TEMPLATE_DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Holiday'];

const buildMissingId = (dayNumber: number) => `TEMPLATE-DAY-${dayNumber}`;

export const useTemplatesForLocation = () => {
  const debug = useFlag('debug');
  const selectedLocationId = useSelectedLocation().id;
  const [{ data }, refetch] = useGetTemplatesForLocationQuery({ variables: { locationId: selectedLocationId } });

  return useMemo(() => {
    if (!data?.templatesForLocation) {
      throw Error('Could not get template list');
    }

    const templateOptions = TEMPLATE_DAY_NUMBER_ORDER.map(dayNumber => {
      const dayOfWeekTemplates = data.templatesForLocation?.filter(
        template => template.target?.type === 'DayOfWeek' && TEMPLATE_DAY_NAMES.includes(template.name)
      );

      const template = dayOfWeekTemplates?.find(template => template.target!.value === dayNumber);

      const templateOption: TemplateShopOption = {
        dayOfWeek: dayNumber,
        name: strings.common.daysOfWeekFull[dayNumber],
        dbName: template?.name || TEMPLATE_DAY_NAMES[dayNumber],
        template: template as ReadTemplate,
        id: template ? template.id : buildMissingId(dayNumber)
      };

      return templateOption;
    });

    const customTemplates = data.templatesForLocation
      .filter(temp => {
        return temp.target?.type !== 'DayOfWeek' || !TEMPLATE_DAY_NAMES.includes(temp.name);
      })
      .sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }

        return a.name > b.name ? 1 : -1;
      });

    const customTemplateOptions = customTemplates.map(template => {
      let name = template.name;

      if (debug) {
        if (template.target?.type === 'DayOfWeek' && !TEMPLATE_DAY_NAMES.includes(template.name)) {
          name += ` (${strings.common.daysOfWeekFull[template.target.value]} Copy)`;
        }
      }

      const option: TemplateShopOption = {
        name: name,
        dbName: name,
        template: template as ReadTemplate,
        id: template.id
      };

      return option;
    });

    const allTemplateOptions = [...templateOptions, ...customTemplateOptions];

    return { templateOptions, customTemplateOptions, allTemplateOptions, refetch };
  }, [data]);
};
