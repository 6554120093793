import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

//CustomSettingsTableHeaderDescriptionCell
export const HeaderCellDescription: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, style }) => {
  return (
    <div
      className={twMerge(
        'bg-background-mid border border-border px-5 py-2 font-semibold text-xs text-center flex items-center justify-center',
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};
