import { ReadDivision, useGetOrganisationHierarchyQuery } from './gql/graphql.generated';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useMemo } from 'react';

const getSvLocations = (locations: any[], supervisorId: string, locationId: number) => {
  const locationIds: number[] = [];
  locations
    .filter(l => l.id !== locationId)
    .forEach(l => {
      if (l.properties && JSON.parse(l.properties)?.supervisorId === supervisorId) {
        locationIds.push(l.id);
      }
    });
  return locationIds;
};

const getSiblingLocations = (node: ReadDivision, id: number) => {
  if (node.locations?.some(l => l.id === id)) {
    const location = node.locations.find(l => l.id === id);
    if (location?.properties) {
      const supervisorId = JSON.parse(location.properties)?.supervisorId;
      if (supervisorId) {
        return getSvLocations(node.locations, supervisorId, id);
      }
    }
    // Supervisor is not set. The parent is the next level upwards in the organisation hierarchy.
    return node.locations.filter(l => l.id !== id).map(l => l.id);
  }
  let locations;
  node.children?.some(c => {
    locations = getSiblingLocations(c, id);
    return locations !== undefined;
  });
  return locations;
};

export const useSiblingLocations = () => {
  const locationId = useSelectedLocation()?.id;
  const [{ data }] = useGetOrganisationHierarchyQuery();

  if (!data?.getOrganisationHierarchy) {
    throw Error('Could not get hierarchy for sibling locations');
  }

  return useMemo(() => {
    if (!locationId) {
      return undefined;
    }

    const siblingLocations = getSiblingLocations(data.getOrganisationHierarchy as ReadDivision, locationId);
    if (!siblingLocations) {
      throw Error('The selected location cannot be found in the organisation hierarchy');
    }

    return siblingLocations;
  }, [locationId, data]);
};
