import { useCallback } from 'react';
import {showToastMessage, toastError, toastSuccess} from '@/helpers/helper';
import {
  useDeleteAllAvailabilitiesMutation,
  useDeleteAllAvailabilityExceptionsMutation,
  useDeleteAllRostersMutation,
  useDeleteAllTemplatesMutation,
  useDeleteAllWorkingPatternsMutation, useResetAllPasswordsMutation
} from "@/services/gql/graphql.generated";
import {useEnsureDefaultTemplatesEndpoint} from "@/services/settings/useEnsureDefaultTemplatesEndpoint";

export const useSuperAdminApi = () => {
  const [{fetching: fetchingDeleteAllRosters}, deleteRosters] = useDeleteAllRostersMutation();
  const [{fetching: fetchingDeleteAllTemplates}, deleteTemplates] = useDeleteAllTemplatesMutation();
  const [{fetching: fetchingDeleteAllWorkingPatterns}, deleteWorkingPatterns] = useDeleteAllWorkingPatternsMutation();
  const [{fetching: fetchingDeleteAllAvailabilityExceptions}, deleteAvailabilityExceptions] = useDeleteAllAvailabilityExceptionsMutation();
  const [{fetching: fetchingDeleteAllAvailabilities}, deleteAvailabilities] = useDeleteAllAvailabilitiesMutation();
  const [{fetching: fetchingResetAllUsersPasswords}, resetPasswords] = useResetAllPasswordsMutation();
  const ensureDefaultTemplatesEndpoint = useEnsureDefaultTemplatesEndpoint(() => {
    toastSuccess();
  }, () => {
    toastError();
  })

  const deleteAllRosters = useCallback(async () => {
    const result = await deleteRosters({});
    showToastMessage(result);
  }, [deleteRosters]);

  const deleteAllTemplates = useCallback(async () => {
    const result = await deleteTemplates({});
    if (result.error) {
      showToastMessage(result);
    } else {
      await ensureDefaultTemplatesEndpoint();
    }
  }, [deleteTemplates, ensureDefaultTemplatesEndpoint]);

  const deleteAllAvailability = useCallback(async () => {
    const result1 = await deleteWorkingPatterns({});
    const result2 = await deleteAvailabilityExceptions({});
    const result3 = await deleteAvailabilities({});
    showToastMessage(result1, result2, result3);
  }, [deleteWorkingPatterns, deleteAvailabilityExceptions, deleteAvailabilities]);

  const resetAllUsersPasswords = useCallback(async () => {
    const result = await resetPasswords({});
    showToastMessage(result);
  }, [resetPasswords]);

  return {
    deleteAllRosters,
    fetchingDeleteAllRosters,
    deleteAllTemplates,
    fetchingDeleteAllTemplates,
    deleteAllAvailability,
    fetchingDeleteAllAvailability : fetchingDeleteAllWorkingPatterns || fetchingDeleteAllAvailabilityExceptions || fetchingDeleteAllAvailabilities,
    resetAllUsersPasswords,
    fetchingResetAllUsersPasswords
  };
};
