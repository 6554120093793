import { secondsToTimeString } from '@/helpers/dateHelper';
import { deltaToColorClass } from '@/helpers/deltaHelper';
import { EMPLOYEE_NAME_COLUMN_WIDTH, WEEKLY_TOTAL_COLUMN_WIDTH } from '@/pages/summary/constants';
import { useWeeklyBudgetForLocation } from '@/services/budget/useBudget';
import { useBudgetStats } from '@/services/budget/useBudgetStats';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { BudgetCell } from '../../budget/BudgetCell';
import { BudgetPadding } from '../../budget/BudgetCommon';
import { BudgetDay } from './BudgetDay';

interface Props {
  days: Date[];
}

export const WeeklyBudget: FC<Props> = ({ days }) => {
  const budgetData = useWeeklyBudgetForLocation(days);
  const budgetStats = useBudgetStats(days);

  return (
    <div className="bg-background-mid">
      <div className="w-screen">
        <div
          className="grid grid-flow-col grid-rows-5"
          style={{
            gridTemplateColumns: `${EMPLOYEE_NAME_COLUMN_WIDTH} ${WEEKLY_TOTAL_COLUMN_WIDTH} repeat(${budgetData.days.length}, 1fr)`
          }}
        >
          <BudgetCell className="font-semibold justify-end border-r">{strings.budget.rows.hqAllocated}</BudgetCell>
          <BudgetCell className="font-semibold justify-end border-r">{strings.budget.rows.locationForecast}</BudgetCell>
          <BudgetCell className="font-semibold justify-end border-r">{strings.budget.rows.rosteredBudget}</BudgetCell>
          <BudgetCell className="font-semibold justify-end border-r">{strings.budget.rows.deltaTime}</BudgetCell>
          <BudgetCell className="font-semibold justify-end border-r">{strings.budget.rows.totalEmployees}</BudgetCell>
          <BudgetCell className="font-semibold justify-end border-l-0">
            {secondsToTimeString(budgetData.hqAllocatedMonthlyTime || budgetStats.totalHq)}
          </BudgetCell>
          <BudgetCell className="font-semibold justify-end border-l-0">
            {secondsToTimeString(budgetStats.totalLocationForecast)}
          </BudgetCell>
          <BudgetCell className="font-semibold justify-end border-l-0">
            {secondsToTimeString(budgetStats.totalRostered)}
          </BudgetCell>
          <BudgetCell
            className={twMerge(deltaToColorClass(budgetStats.monthlyDelta), 'font-semibold justify-end border-l-0')}
          >
            {secondsToTimeString(budgetStats.monthlyDelta, true)}
          </BudgetCell>
          <BudgetCell className="font-semibold justify-end border-l-0">{`${budgetStats.totalFilledShifts}/${budgetStats.totalShifts}`}</BudgetCell>

          {budgetData.days.map(weekDay => (
            <BudgetDay day={weekDay} key={weekDay.date.toISOString()} />
          ))}
          <BudgetPadding />
        </div>
      </div>
    </div>
  );
};
