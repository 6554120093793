import { useActiveSkills } from '@/services/tasks/useSkills';
import { FC } from 'react';
import { StaffRowSectionProps } from '../../manageStaff/staffTable/StaffRow';
import { StaffRowCell } from '../../manageStaff/staffTable/StaffRowCell';

export const StaffRowSkillsSection: FC<StaffRowSectionProps> = ({ staff, hidden, onChangeSetting, group }) => {
  const toggleSkill = (skillId: string) => {
    const skills = [...staff.settings.assignedSkills];
    const skillIndex = skills.findIndex(s => s === skillId);
    if (skillIndex >= 0) {
      skills.splice(skillIndex, 1);
    } else {
      skills.push(skillId);
    }
    onChangeSetting('assignedSkills')(skills);
  };

  const assignedSkills = staff.settings.assignedSkills;
  const hasSkill = (skillId: string) => assignedSkills.includes(skillId);
  const skillsList = useActiveSkills();

  return (
    <>
      {skillsList.map((skill, index) => {
        return (
          <StaffRowCell staff={staff} key={skill.id} hidden={hidden || group.columns[index].isColumnHidden}>
            <input
              type="checkbox"
              checked={hasSkill(skill.id)}
              className={`col-span-1 text-center rounded-sm p-2`}
              onChange={() => toggleSkill(skill.id)}
            />
          </StaffRowCell>
        );
      })}
    </>
  );
};
