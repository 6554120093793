import { dateToApiFormat } from '@/helpers/dateHelper';
import { SummaryEmployee } from './summary.types';

export interface SummaryDayStats {
  date: Date;
  actualTotal: number;
  basicTotal: number;
  breakTotal: number;
}

/*
 * What does this do?
 * 
 * In general, the summary works on a row-by-row basis. It's an array of objects,
 * each of which represents on employee. Each object contain the schedule for that
 * specific employee across the month, and some summary data for that employee.
 * 
 * This function converts the 'row based' summary into a column one. Instead of giving
 * data about a particular employee, it gives data about each day in a range.
 */
export const buildSummaryDayStats = (summary: SummaryEmployee[], dates: Date[]) => {
  const stats: SummaryDayStats[] = dates.map(d => {
    const dayStats = { date: d, actualTotal: 0, basicTotal: 0, breakTotal: 0 };

    summary.forEach(employee => {
      if (!employee.homeEmployee) {
        // Away employees are not included in roster stats.
        return;
      }
      
      const daySummary = employee.schedule.find(s => s.date === dateToApiFormat(d));

      if (daySummary) {
        dayStats.actualTotal += daySummary.actualTime;
        dayStats.basicTotal += daySummary.basicTime;
        dayStats.breakTotal += daySummary.breakTime;
      }
    });

    return dayStats;
  });

  return stats;
};
