import { ReadEmployee, useGetEmployeesByIdsQuery } from '@/services/gql/graphql.generated';
import { useMemo } from 'react';
import { useRosterAllEmployeesSummary, useWorkingRoster } from '../providers/WorkingRosterService';
import { EmployeeSkills } from '../../employeeSelector/employeeForShift.types';
import {useEmployeesSkillsList} from "@/pages/daily/employeeSelector/useEmployeeSkillsList";

export const useAllEmployeeOptions = () => {
  const summary = useRosterAllEmployeesSummary();
  const { workingRoster } = useWorkingRoster();

  const employeeIdsFromOtherShops = workingRoster?.shifts
    .filter(shift => !!shift.assignedEmployeeId)
    .filter(shift => !summary.find(employee => employee.id === shift.assignedEmployeeId))
    ?.map(shift => shift.assignedEmployeeId!);

  const [{ data: employeeDataFromOtherShops }] = useGetEmployeesByIdsQuery({
    variables: { ids: employeeIdsFromOtherShops }
  });

  const homeEmployees = useMemo(() => {
    return summary.map(e => e.details);
  }, [summary, employeeIdsFromOtherShops]);

  const awayEmployees: ReadEmployee[] = (employeeDataFromOtherShops?.employeesByIds as ReadEmployee[]) || [];

  const employeeIds = homeEmployees.concat(awayEmployees).map(e => e.identityId);

  const employeesSkillsList: EmployeeSkills[] = useEmployeesSkillsList(employeeIds);

  return { homeEmployees, awayEmployees, employeesSkillsList };
};
