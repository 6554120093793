import { FC } from 'react';
import clsx from 'clsx';

interface Props {
  checked?: boolean;
  onClick?: () => void;
  className?: string;
  style?: any;
}

export const RadioButton: FC<Props> = ({ checked, onClick, className, style }) => {
  return <input type="radio" className={clsx(className, ' ')} style={style} checked={checked} onChange={onClick} />;
};
