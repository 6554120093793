import { useResetPasswordMutation } from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';

export const useResetPassword = (employeeId: string) => {
  const [{ fetching }, reset] = useResetPasswordMutation();

  const resetUserPassword = useCallback(async () => {
    const result = await reset({ employeeId });
    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  }, [reset]);

  return { resetUserPassword, fetching };
};
