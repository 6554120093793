import { useGetMeQuery } from "../gql/graphql.generated";
import { StaffPreferences } from "../settings/systemSettings.types";

export const getMePreferences = () : StaffPreferences | false => {
  const [{ data: myData }] = useGetMeQuery();
  const properties = myData?.myEmployee?.properties ? JSON.parse(myData?.myEmployee?.properties) : {};
  return properties.preferences ? properties.preferences as StaffPreferences : false;
}

export const showStaffPickerColumn = (key: string) : boolean => {
  const prefs = getMePreferences();
  return prefs && prefs.staffPicker[key as keyof typeof prefs.staffPicker] === false;
}