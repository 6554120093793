import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../PdfStyles';
import { StaffAvailabilityDay } from '@/services/availability/useStaffAvailability';
import { LeaveType } from '@/services/leaveManagement/leave.types';

// Context provider cannot be used inside the Document element
// The PdfAvailabilityParams object is passed in from outside the Document element.
export interface PdfAvailabilityParams {
  leaveTypes: LeaveType[];
  locationId: string;
  locationName: string;
}

interface Props {
  data: StaffAvailabilityDay;
  params: PdfAvailabilityParams;
}

export const PdfAvailabilityDay: FC<Props> = ({ data, params }) => {
  const { availableTimes, leaveTypeId, date } = data;

  const inPast = dateToApiFormat(date) < dateToApiFormat(new Date());

  let labelContent: JSX.Element = <></>;
  let mainContent: JSX.Element = <></>;

  const leaveType = params.leaveTypes.find(lt => lt.id === leaveTypeId);
  let leaveTypeName = leaveType?.properties.displayName ?? leaveType?.name ?? '';
  leaveTypeName = leaveTypeName.length > 5 ? leaveTypeName.substring(0, 4) : leaveTypeName;
  if (leaveType) {
    labelContent = (
      <View
        style={{
          width: '80%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          marginVertical: 1,
          backgroundColor: leaveType?.properties.color
        }}
      >
        <Text style={{ ...styles.tableCellSm, textTransform: 'uppercase', fontSize: 4 }}>{leaveTypeName}</Text>
      </View>
    );
  }

  if (data.rosteredShifts && data.rosteredShifts.length > 0) {
    // If you have an absence but have been rostered then hide the absence to help visibility
    labelContent = <></>;

    mainContent = (
      <View
        style={{
          backgroundColor: data.rosteredShifts[0].locationId === params.locationId ? '#1BB65C' : '#E8AA14',
          width: '80%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          marginVertical: 1
        }}
      >
        <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(data.rosteredShifts[0].start)}</Text>
        <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(data.rosteredShifts[0].end)}</Text>
      </View>
    );
  } else if (leaveTypeId) {
    mainContent = (
      <View
        style={{
          width: '80%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          marginVertical: 1,
          height: 16,
          backgroundColor: leaveType?.properties.color
        }}
      >
        <Text
          style={{
            ...styles.tableCellSm,
            backgroundColor: leaveType?.properties.color,
            alignItems: 'center',
            fontSize: 6
          }}
        >
          {strings.summary.holiday}
        </Text>
      </View>
    );
  } else if (availableTimes.length > 0) {
    mainContent = (
      <View
        style={{
          width: '80%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          marginVertical: 1,
          gap: 5
        }}
      >
        {availableTimes.map(t => (
          <View
            key={t.start}
            style={{
              width: '80%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text style={availableTimes.length === 1 ? { ...styles.tableCellSm } : { ...styles.tableCellXs }}>
              {secondsToTimeString(t.start)}
            </Text>
            <Text style={availableTimes.length === 1 ? { ...styles.tableCellSm } : { ...styles.tableCellXs }}>
              {secondsToTimeString(t.end)}
            </Text>
          </View>
        ))}
      </View>
    );
  }

  return (
    <View
      style={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: inPast ? '#F0F0F0' : undefined
      }}
    >
      {labelContent}
      {mainContent}
    </View>
  );
};
