import { FC } from 'react';

export const BudgetPadding: FC = () => {
  return (
    <>
      <div className="w-5 border-b"></div>
      <div className="w-5 border-b"></div>
      <div className="w-5 border-b"></div>
      <div className="w-5 border-b"></div>
      <div className="w-5 border-b"></div>
    </>
  );
};
