import { dateToApiFormat } from '@/helpers/dateHelper';
import { useGetRosterStatusesQuery } from '@/services/gql/graphql.generated';
import {SummaryEmployee} from "@/services/summary/summary.types";

export const useRosterStatuses = (date: Date, selectedLocationId: number, summary: SummaryEmployee[]) => {
  const employeesFromOtherShops = summary.filter(e => !!e.details.homeLocationId && e.details.homeLocationId !== selectedLocationId);
  const otherLocationIds: number[] = [];
  employeesFromOtherShops.forEach(e => {
    if (!otherLocationIds.find(id => e.details.homeLocationId === id)) {
      otherLocationIds.push(e.details.homeLocationId!);
    }
  });

  const [{ data }] = useGetRosterStatusesQuery({
    variables: {
      dateRangeAndLocations: {
        start: dateToApiFormat(date),
        end: dateToApiFormat(date),
        locationIds: otherLocationIds
      }
    },
    requestPolicy: 'cache-and-network'
  });

  return data?.rosterStatuses;
};
