import { showToastMessage, sortSimpleObjectArray } from '@/helpers/helper';
import {
  CreateSkill,
  ReadSkills,
  UpdateSkill,
  useCreateSkillsMutation,
  useDeleteSkillsMutation,
  useGetSkillsQuery,
  useUpdateSkillsMutation
} from '@/services/gql/graphql.generated';
import { SkillType } from '@/services/tasks/skillSettings.types';
import { useCallback, useMemo } from 'react';

export const useSkills = () => {
  const [{ data }] = useGetSkillsQuery();

  const skills = useMemo(() => {
    if (!data?.skills) {
      throw Error('Could not get skills');
    }
    return sortSimpleObjectArray(gqlToSkills(data.skills), 'name') as SkillType[];
  }, [data]);

  return skills;
};

export const useActiveSkills = () => {
  const skills = useSkills();

  return useMemo(() => skills.filter(s => s.isActive), [skills]);
};

export const useCreateSkills = (): any => {
  const [responseData, create] = useCreateSkillsMutation();

  const createSkills = useCallback(
    async (skills: SkillType[]) => {
      const result = await create({ skills: skillsToGQL(skills) });
      showToastMessage(result);
    },
    [create]
  );

  return { fetching: responseData.fetching, create: createSkills };
};

export const useUpdateSkills = (): any => {
  const [responseData, update] = useUpdateSkillsMutation();

  const updateSkills = useCallback(
    async (skills: SkillType[]) => {
      const result = await update({ skills: skillsToGQL(skills, true) as UpdateSkill[] });
      showToastMessage(result);
    },
    [update]
  );

  return { fetching: responseData.fetching, update: updateSkills };
};

export const useDeleteSkills = () => {
  const [{ fetching }, deleteSkillsBulk] = useDeleteSkillsMutation();

  const deleteSkills = useCallback(
    async (skillIds: string[]) => {
      const result = await deleteSkillsBulk({ skillIds });
      showToastMessage(result);
      return result;
    },
    [deleteSkillsBulk]
  );

  return { deleteSkills, fetching };
};

export const skillsToGQL = (skills: SkillType[], isUpdate?: boolean): CreateSkill[] | UpdateSkill[] => {
  return skills.map(s => {
    if (!isUpdate) {
      return {
        name: s.name,
        active: s.isActive,
        properties: JSON.stringify({ tooltip: s.tooltip })
      };
    }

    return {
      id: s.id,
      name: s.name,
      active: s.isActive,
      properties: JSON.stringify({ tooltip: s.tooltip })
    };
  });
};

export const gqlToSkills = (skills: ReadSkills[]): SkillType[] => {
  return skills.map(s => {
    let props: any = {};
    if (s.properties) {
      props = JSON.parse(s.properties);
    }
    return {
      id: s.id,
      name: s.name,
      isActive: !!s.active,
      tooltip: props.tooltip || ''
    };
  });
};
