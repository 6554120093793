import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { INDEX_COLUMN_WIDTH } from './SettingsTable';

interface Props {
  children?: ReactNode;
  className?: string;
  indexCell?: boolean;
  nameCell?: boolean;
  styles?: React.CSSProperties;
}

export const SettingsTableCell: FC<Props> = ({ children, className, indexCell, nameCell, styles }) => {
  const baseClass = 'bg-white border border-border p-2 flex items-center';
  const indexClass = 'sticky left-0';
  const nameClass = 'sticky';

  const stylesToApply: React.CSSProperties = styles ? { ...styles } : {};

  if (nameCell) {
    stylesToApply.left = INDEX_COLUMN_WIDTH;
  }

  return (
    <div
      className={twMerge(className, baseClass, indexCell ? indexClass : '', nameCell ? nameClass : '')}
      style={stylesToApply}
    >
      {children}
    </div>
  );
};
