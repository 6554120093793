import { Button } from '@/components/button';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const DailyRosterHeader: FC = () => {
  const prefStrings = strings.settings.preferences;
  const navigate = useNavigate();

  const location = useLocation();
  const isPageSelected = (page: string) => location.pathname.includes(page);

  return (
    <>
      <div className="px-5 py-2 bg-background-mid text-base w-full border-b flex gap-1">
        <h1 className="text-xl font-semibold">{prefStrings.dailyRoster.sectionTitle}</h1>
      </div>
      <div className="px-5 py-2 bg-background-mid text-base w-full border-b flex gap-1">
        <Button
          size="sm"
          variant={isPageSelected('staffPickerColumns') ? 'active' : 'default'}
          onClick={() => navigate(`/myProfile/staffPickerColumns`)}
          className={`h-12`}
        >
          {prefStrings.dailyRoster.staffPicker}
        </Button>
        <Button
          size="sm"
          variant={isPageSelected('taskDisplay') ? 'active' : 'default'}
          onClick={() => navigate(`/myProfile/taskDisplay`)}
          className={`h-12`}
        >
          {prefStrings.dailyRoster.taskDisplay}
        </Button>
        <Button
          size="sm"
          variant={isPageSelected('personalization') ? 'active' : 'default'}
          onClick={() => navigate(`/myProfile/personalization`)}
          className={`h-12`}
        >
          {prefStrings.dailyRoster.personalization}
        </Button>
      </div>
    </>
  );
};
