import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate, useGetTemplateQuery } from '@/services/gql/graphql.generated';
import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { TemplateOption, WorkingTemplate } from '../template.types';
import { useGqlTemplateToWorkingTemplate } from '../templateHelper';

export interface TemplateShopOption extends TemplateOption {
  dayOfWeek?: number;
  dbName: string;
}

export interface TemplateStore {
  templateOptions: TemplateShopOption[];
  customTemplateOptions: TemplateShopOption[];
  allTemplateOptions: TemplateShopOption[];
  selectedTemplateId?: string;
  setSelectedTemplateId: (newVal: string) => void;
  createTemplateAndNavigate: (template: ReadTemplate) => void;
  createNeeded?: boolean;
  workingTemplate?: WorkingTemplate;
  setWorkingTemplate: (newTemp?: WorkingTemplate) => void;
  initialiseWorkingTemplate: (gqlTemp?: ReadTemplate) => void;
  refetchTemplates: () => void;
}

export const TemplateContext = createContext<StoreApi<TemplateStore> | null>(null);

export const TemplateProvider: FC<PropsWithChildren> = ({ children }) => {
  const { templateOptions, customTemplateOptions, allTemplateOptions, refetch } = useTemplatesForLocation();

  const gqlTemplateToWorkingTemplate = useGqlTemplateToWorkingTemplate();

  const determineSelectedTemplate = useCallback(() => {
    const localStorageId = localStorage.getItem('selectedTemplateId');
    const localStorageName = localStorage.getItem('selectedTemplateName');

    const localTemplateInList = allTemplateOptions.find(option => option.id === localStorageId);
    const sameNameInList = allTemplateOptions.find(template => template.dbName === localStorageName);

    if (localTemplateInList) {
      return localStorageId;
    } else if (sameNameInList) {
      localStorage.setItem('selectedTemplateId', sameNameInList.id);
      localStorage.setItem('selectedTemplateName', sameNameInList.dbName);
      return sameNameInList.template?.id;
    } else if (templateOptions.length > 0) {
      const newTemplateToSelect = templateOptions[0];
      localStorage.setItem('selectedTemplateId', newTemplateToSelect.id);
      localStorage.setItem('selectedTemplateName', newTemplateToSelect.dbName);
      return newTemplateToSelect.id;
    }

    return undefined;
  }, [allTemplateOptions]);

  const { registerForConfirmation, requestConfirmation } = useConfirm(state => ({
    registerForConfirmation: state.registerForConfirmation,
    requestConfirmation: state.requestConfirmation
  }));

  const store = useMemo(() => {
    return createStore<TemplateStore>()((set, get) => ({
      templateOptions,
      customTemplateOptions,
      allTemplateOptions,
      selectedTemplateId: determineSelectedTemplate(),
      setSelectedTemplateId: (newVal: string) => {
        const allTemplateOptions = [...get().templateOptions, ...get().customTemplateOptions];
        const template = allTemplateOptions.find(opt => opt.id === newVal);

        if (!template) {
          throw Error('Could not find template in options list');
        }

        requestConfirmation(() => {
          localStorage.setItem('selectedTemplateId', newVal);
          localStorage.setItem('selectedTemplateName', template.dbName);

          set({ selectedTemplateId: newVal });
        });
      },
      setWorkingTemplate: (newTemp?: WorkingTemplate) => {
        registerForConfirmation(strings.daily.templates.unsaved);
        set({ workingTemplate: newTemp });
      },
      initialiseWorkingTemplate: (gqlTemp?: ReadTemplate) => {
        if (gqlTemp) {
          set({ workingTemplate: gqlTemplateToWorkingTemplate(gqlTemp), createNeeded: false });
        } else {
          set({ workingTemplate: undefined, createNeeded: true });
        }
      },
      createTemplateAndNavigate: (newTemplate: ReadTemplate) => {
        const newTemplateOptions = [...get().templateOptions];

        const newOption: TemplateShopOption = {
          id: newTemplate.id,
          dbName: newTemplate.name,
          name: newTemplate.name,
          template: newTemplate
        };

        newTemplateOptions.push(newOption);
        newTemplateOptions.sort((a, b) => (a.name > b.name ? 1 : -1));

        localStorage.setItem('selectedTemplateId', newTemplate.id);
        localStorage.setItem('selectedTemplateName', newTemplate.name);
        set({ templateOptions: newTemplateOptions, selectedTemplateId: newTemplate.id });
      },
      refetchTemplates: () => refetch({ requestPolicy: 'network-only' })
    }));
  }, [
    templateOptions,
    customTemplateOptions,
    allTemplateOptions,
    determineSelectedTemplate,
    gqlTemplateToWorkingTemplate
  ]);

  return <TemplateContext.Provider value={store}>{children}</TemplateContext.Provider>;
};

type SelectorReturn<S extends (s: TemplateStore) => any> = ReturnType<S>;
export function useTemplateService<S extends (s: TemplateStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error('useTemplate must be used within a TemplateProvider');
  }
  return useStore(context, selector);
}

export const useGetTemplateById = (templateId: string) => {
  const [{ data }] = useGetTemplateQuery({ variables: { id: templateId } });

  return data?.template;
};

export const useSelectedTemplate = () => {
  const { selectedTemplateId } = useTemplateService(state => ({
    selectedTemplateId: state.selectedTemplateId
  }));

  const [{ data }] = useGetTemplateQuery({ variables: { id: selectedTemplateId } });

  return data?.template;
};

export const useWorkingTemplate = () => {
  const { initialiseWorkingTemplate, setWorkingTemplate, selectedTemplateId, workingTemplate, templateOptions } =
    useTemplateService(state => ({
      initialiseWorkingTemplate: state.initialiseWorkingTemplate,
      setWorkingTemplate: state.setWorkingTemplate,
      selectedTemplateId: state.selectedTemplateId,
      workingTemplate: state.workingTemplate,
      templateOptions: [...state.templateOptions, ...state.customTemplateOptions]
    }));

  const templateOption = templateOptions.find(t => t.id === selectedTemplateId);

  useEffect(() => {
    if (templateOption && templateOption.id !== workingTemplate?.id) {
      initialiseWorkingTemplate(templateOption.template);
    } else if (!templateOption) {
      initialiseWorkingTemplate(undefined);
    }
  }, [workingTemplate, templateOption, initialiseWorkingTemplate, selectedTemplateId]);

  return { workingTemplate, setWorkingTemplate };
};
