import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useEmployeeList } from '@/services/staff/useEmployeeList';
import { TaskType } from '@/services/tasks/task.types';
import { useMemo } from 'react';
import { EmployeeSkills } from '../employeeSelector/employeeForShift.types';
import { useEmployeesSkillsList } from '../employeeSelector/useEmployeeSkillsList';
import { useRosterHomeEmployeesSummary } from '../roster/providers/WorkingRosterService';

export const useEmployeeIdsWithMandatorySkillsForTask = (task?: TaskType) => {
  const { homeEmployees: employees } = useEmployeeList();
  const employeeIds = useMemo(() => employees.map(emp => emp.identityId), [employees]);
  const employeesSkillsList: EmployeeSkills[] = useEmployeesSkillsList(employeeIds);
  const employeeIdsWithMandetorySkills: string[] = [];

  if (task?.skills) {
    employeesSkillsList?.map(skillListItem => {
      let hasAllSkills = true;

      // Go through all mandetory skills and check this employee has all of them
      task?.skills.map(mandSkill => {
        if (skillListItem.skills?.findIndex(skill => skill.skillId === mandSkill) === -1) {
          hasAllSkills = false;
        }
      });

      // If the employee does have all the mandetory skills then add them to the data to render in the tooltip
      if (hasAllSkills) {
        employeeIdsWithMandetorySkills.push(skillListItem.employeeId);
      }
    });
  } else {
    // If the task doesnt have any mandetory skills then all staff could do it
    employeeIds.map(empId => employeeIdsWithMandetorySkills.push(empId));
  }

  return employeeIdsWithMandetorySkills;
};

export const useCapableRosterStaffForTask = (task?: TaskType) => {
  const summary = useRosterHomeEmployeesSummary();
  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));

  const employeeIdsWithMandetorySkills = useEmployeeIdsWithMandatorySkillsForTask(task);

  const employeeDataWithMandSkills = summary
    .filter(summaryItem => employeeIdsWithMandetorySkills.includes(summaryItem.id))
    .sort((a, b) => {
      const schedule = a.schedule.find(s => s.date === selectedDate);

      return schedule && schedule?.availableTimes && schedule.availableTimes.length > 0 ? -1 : 1;
    });

  return employeeDataWithMandSkills;
};

export const useAvailableCapableRosteredStaffForTask = (task?: TaskType) => {
  const capableEmployees = useCapableRosterStaffForTask(task);

  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));

  return capableEmployees.reduce((prev, employee) => {
    const schedule = employee.schedule.find(s => s.date === selectedDate);


    if (schedule && schedule?.availableTimes && schedule.availableTimes.length > 0) {
      return prev + 1;
    }

    return prev;
  }, 0);
};
