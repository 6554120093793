import { FC, useCallback } from 'react';
import { WorkingShift } from '../daily.types';
import { WorkingTemplate } from './template.types';
import {EmployeeForShiftItem} from "@/pages/daily/employeeSelector/employeeForShift.types";
import {TemplateShift} from "@/pages/daily/template/TemplateShift";

interface Props {
  workingTemplate: WorkingTemplate;
  setWorkingTemplate: (newTemp: WorkingTemplate) => void;
}

export const TemplateShifts: FC<Props> = ({ workingTemplate, setWorkingTemplate }) => {
  const updateShift = useCallback(
    (shiftIndex: number) => (newShift: WorkingShift) => {
      if (!workingTemplate) {
        throw Error('Working template should be set');
      }

      const shifts = [...workingTemplate.shifts];
      shifts.splice(shiftIndex, 1, newShift);
      setWorkingTemplate({ ...workingTemplate, shifts });
    },
    [setWorkingTemplate, workingTemplate]
  );

  const removeShift = useCallback(
    (shiftIndex: number) => () => {
      const shifts = [...workingTemplate.shifts];
      shifts.splice(shiftIndex, 1);
      setWorkingTemplate({ ...workingTemplate, shifts });
    },
    [setWorkingTemplate, workingTemplate]
  );

  const selectStaffMember = useCallback(
    (shiftIndex: number) => (selectedOption?: EmployeeForShiftItem, isStaffRequesting?: boolean) => {
      if (!workingTemplate) {
        throw Error('Working Template should be set');
      }

      const shifts = [...workingTemplate.shifts];
      const shiftProperties = shifts[shiftIndex]?.properties || {};

      if (selectedOption === undefined) {
        delete shifts[shiftIndex].assignedEmployeeId;
        delete shifts[shiftIndex].assignedContractId;
      } else {
        shifts[shiftIndex].assignedEmployeeId = selectedOption.id;
        shifts[shiftIndex].assignedContractId = selectedOption.contractId;
        shifts[shiftIndex].assignedStaffHomeLocationId = selectedOption.details?.homeLocationId;

        const shiftProps = shifts[shiftIndex]?.properties;
        if (shiftProps) {
          if (shiftProperties?.requesting) {
            delete shiftProperties.requesting;
          }
        }
      }

      if (isStaffRequesting) {
        shiftProperties['requesting'] = true;
      }
      shifts[shiftIndex].properties = shiftProperties;

      setWorkingTemplate({ ...workingTemplate, shifts });
    },
    [setWorkingTemplate, workingTemplate]
  );

  return (
    <>
      {workingTemplate.shifts.map((shift, index) => (
        <TemplateShift
          key={shift.id}
          shift={shift}
          workingTemplate={workingTemplate}
          selectStaff={selectStaffMember(index)}
          removeShift={removeShift(index)}
          updateShift={updateShift(index)}
        />
      ))}
    </>
  );
};
