import { Button } from '@/components/button';
import { Form } from '@/components/form/Form';
import { TextAreaField } from '@/components/form/TextAreaInput';
import { Modal } from '@/components/modal/Modal';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdModeEdit, MdNoteAlt } from 'react-icons/md';

export interface EditNotesFields {
  notes?: string;
}

export interface NotesModalProps {
  open: boolean;
  submitNotes: (data: EditNotesFields) => Promise<void>;
  noteValue?: string;
  emptyNoteString: string;
  close: any;
  subsectionTitle?: string;
  subsectionNotes?: string;
  autoEdit?: boolean;
}

interface FormProps {
  noteValue?: string;
  onCancel: () => void;
  submitNotes: (data: EditNotesFields) => Promise<void>;
}

const NotesModalForm: FC<FormProps> = ({ noteValue, onCancel, submitNotes }) => {
  const methods = useForm<EditNotesFields>({
    defaultValues: {
      notes: noteValue
    }
  });

  const onSubmit = async (data: EditNotesFields) => {
    await submitNotes(data);
    onCancel();
  };


  return (
    <Form methods={methods} onSubmit={onSubmit} className="flex flex-col gap-2">
      <TextAreaField id="notes" name="notes"></TextAreaField>
      <div className="flex gap-2">
        <Button type="button" onClick={onCancel}>
          {strings.common.cancel}
        </Button>
        <Button variant={'primary'} type="submit">
          {strings.common.save}
        </Button>
      </div>
    </Form>
  );
};

export const NotesModal: FC<PropsWithChildren<NotesModalProps>> = ({
  children,
  open,
  submitNotes,
  noteValue,
  emptyNoteString,
  close,
  subsectionNotes,
  subsectionTitle,
  autoEdit
}) => {
  const [editNotes, setEditNotes] = useState<boolean>(false);

  useEffect(() => {
    if (autoEdit) {
      setEditNotes(true);
    }
  }, [autoEdit]);


const onClose = () => {
  close();
};

return (
  <Modal open={open} onClose={onClose} title={strings.common.notesModalTitle}>
    <div className="space-y-4 max-w-2xl w-screen">
      {children}
      {!editNotes && (
        <div>
          <div className="flex">
            {noteValue ? (
              <span className="flex-1 overflow-y-auto whitespace-pre-wrap">{noteValue}</span>
            ) : (
              <span className="italic mx-auto">{emptyNoteString}</span>
            )}
            <Button
              className="rounded-full bg-gray-600 hover:bg-gray-400 p-1 h-fit"
              onClick={() => setEditNotes(true)}
            >
              <MdModeEdit className="fill-white w-4 h-4" />
            </Button>
          </div>
          {subsectionTitle && subsectionNotes && (
            <div className="pt-5 border-t mt-5">
              <div className="font-semibold">{subsectionTitle}</div>
              <div>{subsectionNotes}</div>
            </div>
          )}
        </div>
      )}
      {editNotes && (
        <NotesModalForm submitNotes={submitNotes} noteValue={noteValue} onCancel={onClose} />
      )}
    </div>
  </Modal>
);
};

export const NotesButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ onClick }) => {
  return (
    <Button type="button" className="rounded-full bg-gray-600 hover:bg-gray-400 p-1 h-auto" onClick={onClick}>
      <MdNoteAlt className="fill-white w-4 h-4" />
    </Button>
  );
};
