import { dateToApiFormat } from '@/helpers/dateHelper';
import { ContractTypeIdMapping } from '@/services/contract/useContractOptions';
import { useCreateEmployeeMutation, useUpdateWorkingPatternsMutation } from '@/services/gql/graphql.generated';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useVariable } from '@softwareimaging/backstage';
import { useCallback } from 'react';
import uuid from 'react-uuid';
import { useRefreshStaffSettings } from './useRefreshStaffSettings';
import { useDefaultWorkingPattern } from '@/services/settings/useDefaultWorkingPattern';

export const useCreateStaff = () => {
  const [{ fetching }, createMutation] = useCreateEmployeeMutation();
  const selectedLocationId = useLocalSettings(state => state.selectedLocationId);
  useRefreshStaffSettings();

  const defaultPattern = useDefaultWorkingPattern();

  const [{ fetching: fetchingWorkingPatterns }, updateWorkingPatterns] = useUpdateWorkingPatternsMutation();

  const prefix = useVariable('casualStaffPrefix');

  const create = useCallback(
    async (firstName: string, lastName: string) => {
      const userName = `${prefix}-${uuid()}`;

      const result = await createMutation({
        createEmployee: {
          firstName: firstName,
          lastName: lastName,
          kanjiLastName: lastName,
          kanjiFirstName: firstName,
          locationIds: [selectedLocationId],
          homeLocationId: selectedLocationId,
          ignoreHourContribution: false,
          hireDate: dateToApiFormat(new Date()),
          isRosterable: true,
          role: 'Staff',
          userName: userName,
          contractTypeId: ContractTypeIdMapping.CasualStandardId
        }
      });

      const newId = result.data?.createUserAndEmployee?.identityId;
      if (!newId) {
        throw Error('Failed to create new employee');
      }

      const patternResult = await updateWorkingPatterns({
        workingPatterns: [
          {
            employeeId: newId,
            patterns: defaultPattern.map(day => ({
              startTime: day.startTime,
              endTime: day.endTime,
              dayNumber: day.day,
              available: true
            }))
          }
        ]
      });

      return { error: result.error || patternResult.error };
    },
    [prefix, createMutation, selectedLocationId, defaultPattern, updateWorkingPatterns]
  );

  return { create, fetching: fetching || fetchingWorkingPatterns };
};
