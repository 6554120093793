import { secondsToTimeString } from '@/helpers/dateHelper';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../../PdfStyles';
import { PdfTableCell } from '../../PdfTableCell';
import {PdfParams, PdfSummaryDay} from '../PdfSummaryDay';
import { WEEKLY_NAME_WIDTH, WEEKLY_TOTAL_WIDTH } from './PdfWeeklyDocument';

interface Props {
  days: Date[];
  employee: SummaryEmployee;
  params: PdfParams;
}

export const PdfWeeklyEmployeeRow: FC<Props> = ({ days, employee, params}) => {
  const total = employee.compliance.actualTime;

  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={WEEKLY_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{employee.name}</Text>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{employee.role}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={WEEKLY_TOTAL_WIDTH}>
        <Text style={{ ...styles.tableCell }}>{secondsToTimeString(total)}</Text>
      </PdfTableCell>
      {days.map((day: Date, dayIndex: number) => (
        <PdfTableCell lastCell={dayIndex === days.length - 1}>
          <PdfSummaryDay day={day} employee={employee} params={params} />
        </PdfTableCell>
      ))}
    </View>
  );
};
