import { FC, useEffect, useState } from 'react';
import { TemplateOption } from '../template.types';
import { TextInput } from '@/components/form/TextInput';
import { useDebouncedCallback } from 'use-debounce';
import { FiCheck } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';
import { strings } from '@/services/translation/strings';

interface Props {
  selectedTemplateId?: string;
  onSelect: (newId?: string) => void;
  templateOptions: TemplateOption[];
  addVersion?: boolean;
}

interface SearchHits<T> {
  value: T;
  hits: number;
}

const filterResults = (templateOptions: TemplateOption[], searchTerm: string) => {
  if (!searchTerm) {
    return templateOptions;
  }

  const optionsWithHits: SearchHits<TemplateOption>[] = templateOptions.map(o => ({ value: o, hits: 0 }));

  const splitQuery = searchTerm.split(' ');
  splitQuery.forEach(term => {
    optionsWithHits.forEach(o => {
      if (o.value.name.toLowerCase().includes(term.toLowerCase())) {
        o.hits++;
      }

      if (o.value.template.version && ('v' + o.value.template.version).includes(term)) {
        o.hits++;
      }

      o.value.template.templateTags?.forEach(tag => {
        if (tag.label.toLowerCase().includes(term.toLowerCase())) {
          o.hits++
        }
      });
    });
  });

  const optionsToReturn = optionsWithHits.filter(o => o.hits > 0);
  optionsToReturn.sort((a, b) => a.hits - b.hits);

  return optionsToReturn.map(o => o.value);
};

const TemplateSearchOption: FC<{
  option: TemplateOption;
  selected?: boolean;
  onSelect: () => void;
  addVersion?: boolean;
}> = ({ option, selected, onSelect, addVersion }) => {
  return (
    <div
      className={twMerge(
        'px-5 py-2 border-b last:border-b-0 flex items-center justify-between',
        selected ? 'bg-primary text-white font-semibold' : ''
      )}
      onClick={onSelect}
    >
      <div>
        <span>{addVersion ? `${option.name}- v${option.template.version}` : option.name}</span>
        <div className="flex items-center gap-2">
          {/* TODO 29063 DISPLAY TAGS */}
          {/*option.template.tags.map(t => <TagChit tag={t} key={t.name} />)*/}
        </div>
      </div>
      {selected ? <FiCheck className="h-6 w-6" /> : <div></div>}
    </div>
  );
};

export const TemplateSearchAndSelect: FC<Props> = ({ selectedTemplateId, onSelect, templateOptions, addVersion }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<TemplateOption[]>(templateOptions);

  const updateSearchResults = useDebouncedCallback((options: TemplateOption[], newSearchTerm: string) => {
    setFilteredOptions(filterResults(options, newSearchTerm));
  }, 300);

  useEffect(() => {
    updateSearchResults(templateOptions, searchText);
  }, [updateSearchResults, searchText, templateOptions]);

  return (
    <div className="min-w-[800px] space-y-2">
      <TextInput
        className="w-full"
        value={searchText}
        onChange={setSearchText}
        placeholder={strings.daily.templates.library.importModal.placeholder}
      />
      <div className="h-[400px]">
        <div className="border rounded-md max-h-[400px] overflow-auto flex flex-col items-stretch">
          {templateOptions.length === 0 && <div>{strings.daily.templates.library.importModal.noTemplates}</div>}
          {templateOptions.length > 0 && filteredOptions.length === 0 && (
            <div>{strings.daily.templates.library.importModal.noSearchResults}</div>
          )}
          {filteredOptions.map(o => (
            <TemplateSearchOption
              key={o.id}
              option={o}
              onSelect={() => onSelect(selectedTemplateId === o.id ? undefined : o.id)}
              selected={o.id === selectedTemplateId}
              addVersion={addVersion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
