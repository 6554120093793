import { SeverityType } from '@/components/warning/warningDefinitions';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { useBuildEmployeesForShift } from '../employeeSelector/useEmployeesForShift';
import { getRosterWarnings } from '../rosterWarnings/getRosterWarnings';
import { useBaseWarningProps } from '../rosterWarnings/useBaseWarningProps';
import { RosterWarningModel } from '../rosterWarnings/warnings.types';
import { useWorkingRoster } from './WorkingRosterService';

export interface RosterWarningsStore {
  warnings: RosterWarningModel[];
}

export const RosterWarningsContext = createContext<StoreApi<RosterWarningsStore> | null>(null);

export const RosterWarningsProvider: FC<PropsWithChildren> = ({ children }) => {
  const baseProps = useBaseWarningProps();
  const buildDropdownOptions = useBuildEmployeesForShift();
  const { workingRoster } = useWorkingRoster();

  const store = useMemo(() => {
    return createStore<RosterWarningsStore>()(() => ({
      warnings: !workingRoster
        ? []
        : getRosterWarnings({
            ...baseProps,
            workingRoster,
            buildEmployeeOptions: buildDropdownOptions
          })
    }));
  }, [baseProps, workingRoster, buildDropdownOptions]);

  return <RosterWarningsContext.Provider value={store}>{children}</RosterWarningsContext.Provider>;
};

type SelectorReturn<S extends (s: RosterWarningsStore) => any> = ReturnType<S>;
export function useRosterWarningsService<S extends (s: RosterWarningsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(RosterWarningsContext);
  if (!context) {
    throw new Error('useRosterWarnings must be used within a RosterWarningsProvider');
  }
  return useStore(context, selector);
}

export const useIsRosterValid = () => {
  const warnings = useRosterWarningsService(s => s.warnings);

  return !warnings.find(w => w.severity === SeverityType.CRITICAL);
};

export const useWarningsForShift = (shiftId: string) => {
  const warnings = useRosterWarningsService(s => s.warnings);

  return warnings.filter(w => w.shiftId === shiftId || !w.shiftId);
};
