import { getOption } from '@/helpers/dropdownOptionHelper';
import { strings } from '@/services/translation/strings';
import clsx from 'clsx';
import { Children, FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';

export interface DropdownSettings {
  position: string;
  height: number;
}

export interface DropdownOption {
  id: any;
  value: any;
  name?: string;
}

export interface GridSelectOptionProps {
  options: DropdownOption[];
  option: DropdownOption;
  className?: string;
  isSelected?: boolean;
  showName?: boolean;
  type?: GridDropdownType;
  onChange: (option: DropdownOption) => void;
}

export const GridSelectOption: FC<GridSelectOptionProps> = ({
  options,
  option,
  className,
  isSelected,
  showName,
  type = GridDropdownType.DEFAULT,
  onChange
}) => {
  const optionSize = '32px';
  const selectedOptionCheckmarkSize = '52%';
  return (
    <div
      className={clsx(
        'rounded hover:cursor-pointer hover:bg-[#1bb55c20]  flex items-center justify-center relative',
        className
      )}
      style={{ width: optionSize, height: optionSize }}
      onClick={() => onChange(option)}
    >
      {isSelected && (
        <div
          className="absolute flex items-center justify-center rounded-full bg-[#ffffffcc]"
          style={{ width: selectedOptionCheckmarkSize, height: selectedOptionCheckmarkSize }}
        >
          <FaCheck className="w-full h-full  text-[#1bb55ccc] border border-white rounded-full" />
        </div>
      )}
      {type === GridDropdownType.ICON && (
        <div
          className={clsx(
            'rounded-sm text-ellipsis overflow-hidden w-full h-full leading-normal',
            type === GridDropdownType.ICON ? 'flex items-center justify-center' : 'text-xs'
          )}
        >
          {getOption(options, option.id)?.value}
        </div>
      )}
      {type === GridDropdownType.COLOR && (
        <div
          className="w-full h-full rounded-full shadow border border-[#32323240]"
          style={{
            background: type === GridDropdownType.COLOR ? `${getOption(options!, option.id)?.value}` : ''
          }}
        ></div>
      )}
      {showName && getOption(options!, option.id)?.name?.length! > 0 && getOption(options!, option.id)?.name}
    </div>
  );
};

export const maxAllowedDropDownOptionsWithoutScroll = null;

export enum GridDropdownType {
  DEFAULT = 'default',
  ICON = 'icon',
  COLOR = 'color'
}

interface Props extends PropsWithChildren {
  className: string;
  itemsPerRow: number;
  selected: DropdownOption | undefined | any;
  dropdownOptions?: DropdownOption[] | undefined;
  dropdownId?: string;
  parentContainerId?: string;
  readOnly?: boolean;
  showDefaultEmptyOption?: boolean;
  dropdownSettings?: DropdownSettings;
  type: GridDropdownType;
}

export const GridSelect: FC<Props> = ({
  className,
  children,
  itemsPerRow,
  selected,
  dropdownOptions,
  readOnly,
  dropdownId,
  parentContainerId,
  showDefaultEmptyOption,
  dropdownSettings,
  type
}) => {
  const newRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleGridSelectOutsideClick = (e: any) => {
      if (newRef.current && !(newRef.current as any).contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleGridSelectOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleGridSelectOutsideClick);
    };
  }, [newRef, setIsDropdownOpen]);

  const selectedOption = useMemo(() => {
    setIsDropdownOpen(false);
    return selected;
  }, [selected]);

  const gridId = `grid-dropdown-id-${dropdownId}`;

  // TODO: the dropdown settings should be updated to make sure the dropdown menu will always be inside the active window
  const getDropdownSettings = (optionsCount: number, maxAllowedOptions: number | null): DropdownSettings => {
    const wrapperBoundaries = document
      .querySelector(parentContainerId ? `#${parentContainerId}` : 'body')
      ?.getBoundingClientRect();
    const optionsBoundaries = document.querySelector(`#${gridId}`)?.getBoundingClientRect();
    const wrapperBoundariesBottom = wrapperBoundaries?.bottom;
    const optionsStartingPoint = optionsBoundaries?.bottom;

    const minOptionsHeight = 28; // px
    const singleOptionHeight = 30;
    const offset = 0;

    const rowsToShow =
      (maxAllowedOptions || maxAllowedOptions === 0) && optionsCount > maxAllowedOptions
        ? maxAllowedOptions
        : optionsCount;
    const optionsHeight = singleOptionHeight * rowsToShow + offset;
    const totalDropdownHeight = minOptionsHeight + optionsHeight;

    const result = {
      position: '',
      height: optionsHeight
    };

    if (wrapperBoundariesBottom && optionsStartingPoint) {
      const topDiff = optionsBoundaries.top - wrapperBoundaries.top;
      const bottomDiff = wrapperBoundaries.bottom - optionsStartingPoint;
      const maxPossibleHeight = Math.max(topDiff, bottomDiff);
      const acceptableHeight = Math.min(totalDropdownHeight, maxPossibleHeight);

      result.height = Math.floor(acceptableHeight);

      if (wrapperBoundariesBottom < optionsStartingPoint + acceptableHeight) {
        result.position = `bottom-full`;
      }
    }

    return result;
  };

  const childrenCount = Children.count(children) ? Math.ceil(Children.count(children) / itemsPerRow) : 1;

  const dropdownSettingsCalc = getDropdownSettings(childrenCount, maxAllowedDropDownOptionsWithoutScroll);

  if (readOnly) {
    const option = dropdownOptions?.find(opt => opt.id === selected?.id);

    if (type === GridDropdownType.COLOR) {
      return (
        <div className="h-6 w-16 rounded-md" style={{ backgroundColor: selected, border: '1px solid #32323240' }}></div>
      );
    }

    return <div className="flex items-center">{option?.name}</div>;
  }

  if (!children && dropdownOptions?.length === showDefaultEmptyOption ? 1 : 0) {
    return <div className="text-xs border rounded-md p-2">{strings.dropdown.noOptions}</div>;
  }

  let headerTemplate: any = selected?.id
    ? dropdownOptions?.find(opt => opt.id === selected.id)?.name
    : strings.daily.employeesDropdown.select;

  switch (type) {
    case GridDropdownType.ICON:
      headerTemplate = selected?.id;
      break;

    default:
      break;
  }

  return (
    <div id={gridId} className={clsx(`select-none h-full relative`, className)} ref={newRef}>
      <div
        className={clsx(
          `flex justify-between items-center border rounded-md  h-full hover:cursor-pointer`,
          isDropdownOpen ? 'outline outline-[#0070D2] outline-2' : '',
          type === GridDropdownType.COLOR ? 'p-2 gap-1' : 'px-2 py-1'
        )}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="flex grow w-full h-full">
          <div
            className={clsx(
              'border-border rounded-sm text-ellipsis overflow-hidden w-full h-full leading-normal',
              type === GridDropdownType.ICON ? 'flex text-[20px] items-center justify-center' : 'text-xs'
            )}
            style={
              type === GridDropdownType.COLOR
                ? {
                    backgroundColor: selected,
                    border: '1px solid #32323240'
                  }
                : {}
            }
          >
            {type !== GridDropdownType.COLOR && getOption(dropdownOptions!, selectedOption)?.value}
          </div>
        </div>
        {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {isDropdownOpen && (
        <div
          className={twMerge(
            dropdownSettingsCalc?.position,
            `custom-dropdown-wrapper absolute flex flex-col w-max max-w-screen-md z-50 text-xs border border-[#32323240] rounded-lg bg-[#fafafa] p-2 gap-5 transition duration-500 ease-linear`
          )}
          style={{
            height: `${dropdownSettingsCalc?.height}px`
          }}
        >
          <div className={`overflow-y-auto`}>
            <div
              className={clsx(
                `grid grid-flow-row`,
                `grid-rows-${Children.count(children) ? Math.ceil(Children.count(children) / itemsPerRow) : 1}`,
                className
              )}
              style={{
                gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`
              }}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
