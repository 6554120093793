import { Button } from '@/components/button';
import { ErrorMessage } from '@/components/error/ErrorMessage';
import { DropdownAction } from '@/components/menu/Action';
import { Dropdown, DropdownOrigin } from '@/components/menu/Dropdown';
import { ConfirmationModal } from '@/components/modal/ConfirmationModal';
import { Modal } from '@/components/modal/Modal';
import { UpdateTemplate } from '@/services/gql/graphql.generated';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { strings } from '@/services/translation/strings';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { FiCheck, FiX } from 'react-icons/fi';
import { BLANK_GUID, generateDefaultTemplateName } from '../dailyHelper';
import { TemplateModalTypes } from './templateModal.types';
import { useCreateTemplate } from './useCreateTemplate';
import { useReplaceTemplate } from './useReplaceTemplate';

interface Props {
  templateId?: string;
  updateTemplate: UpdateTemplate;
  selectedDate?: Date | string;
  unsavedChanges?: boolean;
}

export const CreateReplaceTemplateMenu: FC<Props> = ({ templateId, updateTemplate, selectedDate, unsavedChanges }) => {
  const { allTemplateOptions: templateOptions, templateOptions: dailyTemplateOptions } = useTemplatesForLocation();
  const [modalOpenType, setModalOpenType] = useState<TemplateModalTypes>(TemplateModalTypes.NEW);
  const [newTemplateNameInput, setNewTemplateNameInput] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isTemplatesLimitModalOpen, setIsTemplatesLimitModalOpen] = useState<boolean>(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState<boolean>(false);
  const [templateIdToReplace, setTemplateIdToReplace] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { createTemplate, fetching } = useCreateTemplate();
  const { replaceTemplate, fetchingReplace } = useReplaceTemplate();
  const { selectedLocation } = useLocalSettings(state => ({
    selectedLocation: state.selectedLocationId
  }));

  const maxAllowedCustomTemplates = useSystemSettings(state => state.settings.maxCustomTemplates);
  const maxAllowedTemplates = dailyTemplateOptions.length + maxAllowedCustomTemplates;

  useEffect(() => {
    const initialSelectedTemplate = templateId || '';
    setTemplateIdToReplace(initialSelectedTemplate);
    setNewTemplateNameInput(generateDefaultTemplateName(selectedDate));
  }, [templateId, selectedDate]);

  const onInputChange = (newVal: string) => {
    setNewTemplateNameInput(newVal);
    setError('');
  };

  const onSave = async (isConfirmed?: boolean) => {
    const newTemplateName = (
      modalOpenType === TemplateModalTypes.NEW
        ? newTemplateNameInput
        : templateOptions.find(tl => tl.id === templateIdToReplace)?.dbName || ''
    ).trim();

    if (!newTemplateName) {
      setError(strings.daily.templates.myTemplates.nameRequired);
      return;
    }

    const templateListNames = templateOptions.map(template => template.dbName);

    const newTemplate = updateTemplate;
    newTemplate.name = newTemplateName;
    newTemplate.locationId = selectedLocation;

    let result;
    if (templateListNames.includes(newTemplateName)) {
      if (!isConfirmed) {
        // open confirmation modal for replacing template
        setIsConfirmationModalOpen(true);
        return;
      } else {
        // specify the replacement template id
        const templateToReplace = templateOptions.find(t => t.dbName === newTemplateName)?.template;
        newTemplate.id = templateToReplace?.id;
        newTemplate.weekday = !!templateToReplace?.weekday;
        newTemplate.targetType = templateToReplace?.target?.type || '';
        newTemplate.targetValue = templateToReplace?.target?.value || 0;

        // populate stations data
        const stationsToUpdate = templateToReplace?.stations;

        if (stationsToUpdate?.length) {
          const station0Data = {
            id: BLANK_GUID,
            stationId: stationsToUpdate[0].stationId,
            displayOrder: stationsToUpdate[0].displayOrder,
            positions: newTemplate.stations?.length ? newTemplate.stations[0].positions : []
          };

          if (newTemplate.stations?.length) {
            newTemplate.stations[0] = station0Data;
          } else {
            newTemplate.stations = [{ ...station0Data }];
          }
        }
        // initiate replace process
        result = await replaceTemplate(newTemplate);
      }
    } else {
      // create new template

      if (templateOptions.length < maxAllowedTemplates) {
        result = await createTemplate(newTemplate);
      } else {
        setIsTemplatesLimitModalOpen(true);
        return;
      }
    }

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }

    onClose();
  };

  const onClose = () => {
    setIsModalOpen(false);
    setTemplateIdToReplace(templateId || '');
  };

  const onModalOpen = (modalType: TemplateModalTypes) => {
    setModalOpenType(modalType);
    setNewTemplateNameInput(generateDefaultTemplateName(selectedDate));

    if (unsavedChanges) {
      setIsUnsavedChangesModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const onUnsavedChangesConfirm = () => {
    setIsUnsavedChangesModalOpen(false);
    setIsModalOpen(true);
  };

  const onUnsavedChangesClose = () => {
    setIsUnsavedChangesModalOpen(false);
  };

  const onConfirm = () => {
    onConfirmationClose();
    onSave(true);
  };

  const onConfirmationClose = () => {
    setIsConfirmationModalOpen(false);
    setIsTemplatesLimitModalOpen(false);
  };

  return (
    <>
      <Dropdown
        origin={DropdownOrigin.TOP_RIGHT}
        button={
          <Button
            size="default"
            className="flex align-center justify-center px-2 gap-3 text-[#0070D2] border-[1.5px] rounded-[8px] border-[#0070D2]"
          >
            <FaPlus />
            <div className="text-center">{strings.daily.roster.templates.label}</div>
            <BsFillCaretDownFill className=" text-[#706E6B]" />
          </Button>
        }
      >
        <DropdownAction
          onClick={() => onModalOpen(TemplateModalTypes.NEW)}
          className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light"
        >
          <span>{strings.daily.roster.templates.saveAsNew}</span>
        </DropdownAction>
        <DropdownAction
          onClick={() => onModalOpen(TemplateModalTypes.REPLACE)}
          className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light"
        >
          <span>{strings.daily.roster.templates.replaceExisting}</span>
        </DropdownAction>
      </Dropdown>
      <Modal onClose={() => onClose} open={isModalOpen}>
        <div className="space-y-8">
          <p className="text-lg text-[#0070D2]">
            {
              strings.daily.roster.templates[
                modalOpenType === TemplateModalTypes.NEW ? 'saveAsNewTitle' : 'replaceExistingTitle'
              ]
            }
          </p>
          <div className="space-y-2 flex justify-center">
            {modalOpenType === TemplateModalTypes.NEW && (
              <input
                className="w-[90%] rounded-md border border-[#32323240] text-[#323232cc] focus:outline-none focus:ring-1 focus:ring-gray-600 focus:border-transparent focus:text-[#323232]"
                value={newTemplateNameInput}
                onChange={e => onInputChange(e.target.value)}
                type="text"
                required
              ></input>
            )}
            {modalOpenType === TemplateModalTypes.REPLACE && (
              <select
                value={templateIdToReplace}
                onChange={e => setTemplateIdToReplace(e.target.value)}
                className="border rounded-md border-[#32323240] text-[#323232cc] focus:outline-none focus:ring-1 focus:ring-gray-600 focus:border-transparent focus:text-[#323232] hover:cursor-pointer"
              >
                {templateOptions.map(template => (
                  <option key={template.id} value={template.id} className="space-y-2">
                    {template.name}
                  </option>
                ))}
              </select>
            )}
            {error && <ErrorMessage message={error} />}
          </div>
          <div className="flex justify-end gap-5 items-center pt-1">
            <Button onClick={onClose} className="w-full text-[#0070D2] border-[#0070D2] hover:bg-slate-100">
              {strings.common.cancel}
            </Button>
            <Button
              onClick={() => onSave()}
              loading={fetching || fetchingReplace}
              className="w-full text-white border-[#0070D2] bg-[#0070D2] hover:bg-[#0070D2dd]"
            >
              {strings.daily.roster.templates[modalOpenType === TemplateModalTypes.NEW ? 'saveAsNew' : 'replace']}
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        open={isUnsavedChangesModalOpen}
        onClose={onUnsavedChangesClose}
        message={strings.daily.templates.createReplaceUnsaved}
        onConfirm={onUnsavedChangesConfirm}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        onClose={onConfirmationClose}
        message={strings.daily.roster.templates.replaceWarning(
          templateOptions.find(tl => tl.id === templateIdToReplace)?.name || ''
        )}
        onConfirm={onConfirm}
      />
      <ConfirmationModal
        open={isTemplatesLimitModalOpen}
        onClose={onConfirmationClose}
        message={strings.daily.roster.templates.maxTemplatesLimitReached(maxAllowedCustomTemplates)}
        onConfirm={onConfirmationClose}
      />
    </>
  );
};
