import { dateToApiFormat } from '@/helpers/dateHelper';
import { useEffect } from 'react';
import { useApproveAndApplyAvailabilitiesMutation } from './gql/graphql.generated';
import { useLocalSettings, useSelectedLocation } from './settings/LocalSettingsProvider';
import { useGetHomeEmployees } from '@/services/staff/useGetHomeEmployees';

/*
 * NOTE: This is a bit of a weird hack.
 * This hook will automatically approve all availability for the selected
 * location for the two months around the selected date.
 *
 * The availability system in the backend works on an approval basis- staff
 * add their availability, and managers then have to approve it before the
 * optimiser respects it.
 *
 * Currently, SNPS shift has no requirement for this- any exceptions added
 * are automatically respected by the optimiser.
 *
 * However, we strongly suspect that an approvals process will need to be
 * added in future, possibly at short notice.
 *
 * As a result, we've kept all of the original approval requirements in the
 * backend, but added this hook, which automatically approves everything.
 *
 * If/when we do need an approvals system, we can just rip out this hook,
 * and replace it with a more suitable one.
 */
export const useApproveAvailability = () => {
  const selectedLocationId = useSelectedLocation().id;
  const selectedDate = useLocalSettings(state => state.selectedDate);

  const { data: employees } = useGetHomeEmployees(selectedLocationId);

  const [, approve] = useApproveAndApplyAvailabilitiesMutation();

  useEffect(() => {
    const start = new Date(selectedDate);
    start.setMonth(start.getMonth() - 1);

    const end = new Date(selectedDate);
    end.setMonth(end.getMonth() + 1);

    // Wait three seconds before doing this- otherwise it holds up the render.
    setTimeout(
      () =>
        approve({
          applyAvailabilities: {
            start: dateToApiFormat(start),
            end: dateToApiFormat(end),
            employeeIds: employees.map(emp => emp.identityId)
          }
        }),
      3000
    );
  }, [approve, selectedDate, employees]);
};
