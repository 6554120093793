import { styles } from '@/pages/pdf/PdfStyles';
import { PdfTableCell } from '@/pages/pdf/PdfTableCell';
import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FunctionComponent, useContext } from 'react';
import { PdfStaffSkillsContext } from '../PdfStaffSkillsProvider';

interface PdfStaffSkillOverviewSectionProps {
  locationId: string;
  data: StaffWithSettings[];
  showHeader: boolean;
}

const PdfStaffSkillOverviewSection: FunctionComponent<PdfStaffSkillOverviewSectionProps> = ({
  locationId,
  data,
  showHeader
}) => {
  const { locations, skills } = useContext(PdfStaffSkillsContext);

  const location = locations.find(l => String(l.id) === locationId);
  if (!location) throw new Error(`Location not found with id: ${locationId}`);

  return (
    <View key={location.id} style={{ marginBottom: 10 }}>
      {showHeader && <Text>{`${location.id} ${location.name}`}</Text>}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <PdfTableCell width={50}>
            <Text style={{ ...styles.tableCell, textAlign: 'left' }}>Employee Id</Text>
          </PdfTableCell>
          <PdfTableCell width={60}>
            <Text style={{ ...styles.tableCell, textAlign: 'left' }}>Employee Name</Text>
          </PdfTableCell>
          <PdfTableCell>
            <Text style={{ ...styles.tableCell, textAlign: 'left' }}>Skills</Text>
          </PdfTableCell>
        </View>
        {data.map(item => {
          const employee = item.employee;
          const employeeId = employee.employeeId || strings.settings.manageStaff.casual;

          const assignedSkills = item.settings.assignedSkills;
          const skillList = skills.filter(skill => assignedSkills.includes(skill.id)).map(skill => skill.name);
          const formattedSkills = skillList.join(', ');

          return (
            <View key={`${location.id}-${item.employee.employeeId}`} style={styles.tableRow}>
              <PdfTableCell width={50}>
                <View style={{ padding: '2px 0px' }}>
                  <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{employeeId}</Text>
                </View>
              </PdfTableCell>
              <PdfTableCell width={60}>
                <View style={{ padding: '2px 0px' }}>
                  <Text style={{ ...styles.tableCell, textAlign: 'left' }}>
                    {displayName({
                      firstName: employee.firstName,
                      lastName: employee.lastName
                    })}
                  </Text>
                </View>
              </PdfTableCell>
              <PdfTableCell>
                <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{formattedSkills}</Text>
              </PdfTableCell>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default PdfStaffSkillOverviewSection;
