import { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  count: number;
  setCount: (newCount: number) => void;
  readonly?: boolean;
}

export const ScratchpadBox: FC<Props> = ({ count, setCount, readonly }) => {
  const [workingValue, setWorkingValue] = useState<string>(count.toString());

  useEffect(() => {
    if (parseFloat(workingValue) === count) {
      return;
    }

    if (workingValue === '') {
      setCount(0);
    } else {
      setCount(parseFloat(workingValue));
    }
  }, [workingValue]);

  useEffect(() => {
    if (parseFloat(workingValue) !== count) {
      setWorkingValue(count.toString());
    }
  }, [count]);

  const onBlur = () => {
    if (workingValue === '') {
      setWorkingValue('0');
    }
  };

  return (
    <input
      className={twMerge('w-full text-center py-1 rounded-md', readonly ? 'bg-background-mid' : '')}
      value={workingValue}
      onBlur={onBlur}
      readOnly={readonly}
      onChange={event => {
        const newInput = event.target.value;

        if (newInput !== '' && newInput !== '0') {
          const regExp = /^([1-9][0-9]*(\.[0-9]*)?|(0\.[0-9]*))$/;

          if (!regExp.test(newInput)) {
            return;
          }
        }

        setWorkingValue(newInput);
      }}
    ></input>
  );
};
