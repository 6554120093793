import {FC, useCallback, useState} from 'react';
import { IoMdLock } from 'react-icons/io';
import { MdLockOpen } from "react-icons/md";
import {twMerge} from "tailwind-merge";

interface Props {
  value: boolean;
  readOnly: boolean;
  hidden?: boolean;
  onChange?: (newValue: boolean) => void;
}

const baseIconStyle = 'p-[1px] rounded-full';

export const LockUnlockToggle: FC<Props> = ({ value, readOnly, hidden, onChange }) => {
  const [state, setState] = useState<boolean>(value);

  const onToggle = useCallback(() => {
    if (!readOnly) {
      setState(!state);
      onChange?.(!state);
    }
  }, [state, setState, onChange, readOnly]);

  if (hidden) return null;

  return (
    <div>
      {state ?
        <IoMdLock
          className={twMerge(baseIconStyle, 'text-white bg-red-600')}
          onClick={onToggle}
        /> :
        <MdLockOpen
          className={twMerge(baseIconStyle, 'text-blue-300 border border-blue-30')}
          onClick={onToggle}
        />
      }
    </div>
  );
};
