import { dateToApiFormat } from '@/helpers/dateHelper';
import { FoundShiftsInDateRange, useFindReassignedShiftsInDateRangeQuery } from '@/services/gql/graphql.generated';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useCallback, useMemo } from 'react';
import { useTasks } from '@/services/tasks/useTasks';
import { applyReassignedDataToSummary } from './buildReassignedSummary';
import { useSystemSettings } from '../settings/SystemSettingsProvider';

export const useReassignedShiftSummary = (startDate: Date, endDate: Date) => {
  const selectedLocationId = useSelectedLocation().id;

  const [{ data: reassignedShiftData }] = useFindReassignedShiftsInDateRangeQuery({
    variables: {
      searchParams: {
        homeLocationId: selectedLocationId,
        start: dateToApiFormat(startDate),
        end: dateToApiFormat(endDate)
      }
    },
    requestPolicy: 'cache-and-network'
  });

  const reassignedShiftDataInRange = useMemo(
    () => reassignedShiftData?.findReassignedShiftsInDateRange as FoundShiftsInDateRange[],
    [reassignedShiftData]
  );

  if (!reassignedShiftDataInRange) {
    throw Error('Could not get reassigned shifts');
  }

  const tasks = useTasks();
  const includeBreaks = useSystemSettings(s => s.settings.includeBreaksInTotals);

  const addReassignedShifts = useCallback(
    applyReassignedDataToSummary(reassignedShiftDataInRange, tasks, selectedLocationId, includeBreaks),
    [reassignedShiftDataInRange, selectedLocationId, tasks, includeBreaks]
  );

  return { addReassignedShifts };
};
