import { Button } from '@/components/button';
import { strings } from '@/services/translation/strings';
import { FunctionComponent, useState } from 'react';
import { ORGANISATION_CHART_VIEW, OrganisationChart } from './OrganisationChart';

interface OrganisationChartPageProps {}

const OrganisationChartPage: FunctionComponent<OrganisationChartPageProps> = () => {
  const [selectedView, setSelectedView] = useState(ORGANISATION_CHART_VIEW.current);

  return (
    <div className="h-full flex flex-col relative overflow-auto">
      <div className="absolute pt-2 pl-2">
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => setSelectedView(ORGANISATION_CHART_VIEW.all)}
            variant={selectedView === ORGANISATION_CHART_VIEW.all ? 'active' : 'default'}
          >
            {strings.settings.admin.organisationChart.filters.displayAll}
          </Button>
          <Button
            onClick={() => setSelectedView(ORGANISATION_CHART_VIEW.current)}
            variant={selectedView === ORGANISATION_CHART_VIEW.current ? 'active' : 'default'}
          >
            {strings.settings.admin.organisationChart.filters.displayCurrent}
          </Button>
          <Button
            onClick={() => setSelectedView(ORGANISATION_CHART_VIEW.past)}
            variant={selectedView === ORGANISATION_CHART_VIEW.past ? 'active' : 'default'}
          >
            {strings.settings.admin.organisationChart.filters.displayPast}
          </Button>
        </div>
      </div>
      <OrganisationChart view={selectedView} />
    </div>
  );
};

export default OrganisationChartPage;
