import { dateToApiFormat } from '@/helpers/dateHelper';
import { usePost } from "@/services/usePost";
import { useCallback } from 'react';
import { strings } from '@/services/translation/strings';
import {useLanguageProvider} from "@/services/settings/LanguageProvider";
import {enUS, ja} from "date-fns/locale";
import {format} from "date-fns";

export const useDownloadShiftSummaryEndpoint = (errorCallback?: (message: string) => void) => {
  const { post, ...others } = usePost(() => {}, errorCallback);
  const language = useLanguageProvider(state => state.language);
  const locale = language === 'ja' ? ja : enUS;

  const downloadShiftSummary = useCallback(
    async (startDate: Date, endDate: Date) => {
      const endpoint = `/SnpsShift/DownloadShiftSummary`;
      const monthString = format(startDate, 'MMMM-yyyy', { locale });
      const fileName = `${strings.summary.shiftSummaryFileName}-${monthString}`.toLowerCase() + '.xlsx';

      const body = {
        "start": dateToApiFormat(startDate),
        "end": dateToApiFormat(endDate),
        "fileName": fileName
      }

      await post(JSON.stringify(body), endpoint, 'application/json', fileName);
    },
    [post, locale]
  );

  return { downloadShiftSummary, ...others };
};

export const useDownloadTaskSummaryEndpoint = (errorCallback?: (message: string) => void) => {
  const { post, ...others } = usePost(() => {}, errorCallback);
  const language = useLanguageProvider(state => state.language);
  const locale = language === 'ja' ? ja : enUS;

  const downloadTaskSummary = useCallback(
    async (startDate: Date, endDate: Date) => {
      const endpoint = `/SnpsShift/DownloadTaskSummary`;
      const monthString = format(startDate, 'MMMM-yyyy', { locale });
      const fileName = `${strings.summary.taskSummaryFileName}-${monthString}`.toLowerCase() + '.xlsx';

      const body = {
        "start": dateToApiFormat(startDate),
        "end": dateToApiFormat(endDate),
        "fileName": fileName
      }

      await post(JSON.stringify(body), endpoint, 'application/json', fileName);
    },
    [post, locale]
  );

  return { downloadTaskSummary, ...others };
};
