import { dateToApiFormat } from '@/helpers/dateHelper';
import { TargetGt } from '../gql/graphql.generated';
import { HQ_ALLOCATED_MONTH_TYPE, SALES_MONTH_TYPE } from './budget.types';
import { getDaysInMonth } from 'date-fns';

export const getBudgetMonthlyValues = (firstDayOfPeriod: Date, lastDayOfPeriod: Date, targets: TargetGt[]) => {
  /*
   * Getting the monthly budget values is fairly complicated.
   *
   * Companies will often pay their employees part way through the month, while still defining targets for calendar months.
   *
   * As a result, we need to calculate a corrected value for the period estimate, which takes into account how the period
   * spans two calendar months.
   */

  /*
   * FIRST BIG STEP: From the targets array, get the targets associated with each part of the month.
   */
  // The monthly targets are defined for the first days of the month, regardless of what the period is set to.

  const firstCalendarMonth = new Date(firstDayOfPeriod);
  firstCalendarMonth.setDate(1);

  const secondCalendarMonth = new Date(lastDayOfPeriod);
  secondCalendarMonth.setDate(1);

  // First, get the targets associated with those days.
  const firstTargets = targets.filter(target => target.date === dateToApiFormat(firstCalendarMonth));
  const secondTargets = targets.filter(target => target.date === dateToApiFormat(secondCalendarMonth));

  const firstTimeTarget = firstTargets.find(target => target.type === HQ_ALLOCATED_MONTH_TYPE);
  const secondTimeTarget = secondTargets.find(target => target.type === HQ_ALLOCATED_MONTH_TYPE);
  const firstMoneyTarget = firstTargets.find(target => target.type === SALES_MONTH_TYPE);
  const secondMoneyTarget = secondTargets.find(target => target.type === SALES_MONTH_TYPE);

  // Get the values corresponding to the first and second month. If a value is missing, we can use a neighbouring value.
  const firstTimeMonthValue = firstTimeTarget?.value || secondTimeTarget?.value || 0;
  const secondTimeMonthValue = secondTimeTarget?.value || firstTimeTarget?.value || 0;
  const firstMoneyMonthValue = firstMoneyTarget?.value || secondMoneyTarget?.value || 0;
  const secondMoneyMonthValue = secondMoneyTarget?.value || firstMoneyTarget?.value || 0;

  /*
   * SECOND BIG STEP: Work out what these monthly targets mean per day of each month.
   */
  const firstMonthAllDayCount = getDaysInMonth(firstCalendarMonth);
  const secondMonthAllDayCount = getDaysInMonth(secondCalendarMonth);

  const firstTimeDailyValue = firstTimeMonthValue / firstMonthAllDayCount;
  const secondTimeDailyValue = secondTimeMonthValue / secondMonthAllDayCount;
  const firstMoneyDailyValue = firstMoneyMonthValue / firstMonthAllDayCount;
  const secondMoneyDailyValue = secondMoneyMonthValue / secondMonthAllDayCount;

  /*
   * THIRD BIG STEP: Apply the daily values based on how many days are in each month.
   */

  // Count the total number of days from the period which are in each month.
  // Need to add one to this to include the first day itself, BUT ONLY FOR THE FIRST PART.
  const firstMonthPeriodDayCount = firstMonthAllDayCount - firstDayOfPeriod.getDate() + 1;
  const secondMonthPeriodDayCount = firstCalendarMonth.getMonth() === secondCalendarMonth.getMonth() ? 0 : lastDayOfPeriod.getDate();

  const firstMonthTotalMoney = firstMoneyDailyValue * firstMonthPeriodDayCount;
  const secondMonthTotalMoney = secondMoneyDailyValue * secondMonthPeriodDayCount;
  const firstMonthTotalTime = firstTimeDailyValue * firstMonthPeriodDayCount;
  const secondMonthTotalTime = secondTimeDailyValue * secondMonthPeriodDayCount;

  return {
    time: Math.round(firstMonthTotalTime + secondMonthTotalTime),
    money: Math.round(firstMonthTotalMoney + secondMonthTotalMoney)
  };
};
