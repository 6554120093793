import { useCallback } from 'react';
import { usePostFiles } from './PostFiles';

export interface StaffSkillImportResponse {
  staffUpdatedCount: number;
}

export const useUploadSkills = (successCallback: (response: StaffSkillImportResponse) => void) => {
  const { postFiles, ...rest } = usePostFiles((response?: unknown) => {
    successCallback(response as StaffSkillImportResponse);
  });

  const endpoint = `/Uploads/UpdateStaffSkills`;

  const upload = useCallback(
    async (file: File) => {
      return await postFiles([{ formName: 'file', file }], endpoint);
    },
    [postFiles, endpoint]
  );

  return { upload, ...rest };
};
