import { dateToApiFormat } from '@/helpers/dateHelper';
import {
  ReadRoster,
  ReadTemplate,
  useGetFullRostersQuery,
  useGetTemplatesForLocationQuery
} from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { applyRosteredDataToBudget, buildRosteredBudgetData } from '../rosters/rosterHelper';
import { WorkingBudgetDay } from './budget.types';
import { useSummary, useSummaryDayStats } from '../summary/useSummaryService';
import { useTasks } from '../tasks/useTasks';

export const useSetRosteredBudgets = (locationId: number, days: Date[]) => {
  const [{ data: templateData }] = useGetTemplatesForLocationQuery({ variables: { locationId } });
  const templates = templateData?.templatesForLocation as ReadTemplate[];

  if (!templates) {
    throw Error('Template data not available');
  }

  const start = days[0];
  const end = days[days.length - 1];
  const [{ data: rosterData }, refetchRosters] = useGetFullRostersQuery({
    variables: { locationId, startDate: dateToApiFormat(start), endDate: dateToApiFormat(end) }
  });

  const rosters = rosterData?.rostersWithPositionsAndTasksInDateRange as ReadRoster[];

  if (!rosters) {
    throw Error('Roster data not available');
  }

  const { homeEmployeesSummary: summary } = useSummary(start, end, locationId);
  const dayStats = useSummaryDayStats(summary, days)
  const tasks = useTasks();

  const getRosteredBudget = useCallback(buildRosteredBudgetData(rosters, templates, dayStats, tasks), [
    rosters,
    templates,
    summary,
    tasks
  ]);

  const setRosteredBudgets = useCallback(
    (days: WorkingBudgetDay[]) => applyRosteredDataToBudget(days, getRosteredBudget),
    [getRosteredBudget]
  );

  return { setRosteredBudgets, refetch: refetchRosters };
};
