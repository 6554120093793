import { ReadTemplate, useGetTemplatesQuery } from '@/services/gql/graphql.generated';
import { TaskType } from '@/services/tasks/task.types';
import { useCallback } from 'react';

export type InUseTask = Map<string, ReadTemplate[]>;

const getInUseTasks = (templates: ReadTemplate[], tasks: TaskType[]) => {
  const taskTemplateMap: InUseTask = new Map();

  templates.forEach(template => {
    template.stations?.forEach(station => {
      station.positions?.forEach(position => {
        position.timeSlots?.forEach(timeSlot => {
          if (timeSlot.properties) {
            const parsedProperties = JSON.parse(timeSlot.properties) as { type: string; context: string };
            if (parsedProperties.type === 'task') {
              tasks.forEach(task => {
                if (task.id === parsedProperties.context) {
                  if (!taskTemplateMap.has(task.id)) {
                    taskTemplateMap.set(task.id, []);
                  }

                  taskTemplateMap.get(task.id)!.push(template);
                }
              });
            }
          }
        });
      });
    });
  });

  return taskTemplateMap;
};

export const useValidateTasksInUse = () => {
  const [{ data }] = useGetTemplatesQuery();

  const shopTemplates = data?.templates?.filter(template => template.location) as ReadTemplate[];
  const libraryTemplates = data?.templates?.filter(template => !template.location) as ReadTemplate[];

  return useCallback(
    (tasks: TaskType[]) => {
      const tasksInUseForShopTemplates = getInUseTasks(shopTemplates, tasks);
      const tasksInUseForLibraryTemplates = getInUseTasks(libraryTemplates, tasks);

      return {
        tasksInUseForShopTemplates,
        tasksInUseForLibraryTemplates
      };
    },
    [shopTemplates, libraryTemplates]
  );
};
