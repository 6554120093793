import { usePost } from '../usePost';
import { useCallback } from 'react';

export const useEnsureDefaultTemplatesEndpoint = (successCallback: () => void, errorCallback?: (message: string) => void) => {
  const { post } = usePost(successCallback, errorCallback);
  return useCallback(
    async () => {
      const endpoint = `/SnpsShift/EnsureTemplatesExist`;
      return await post(null, endpoint);
    },
    [post]
  );
};
