import { NotesButton, NotesModal } from '@/components/notes/NotesModal';
import { useEditSettingsService } from '@/pages/settings/services/EditSettingsService';
import { useUpdateStaffSettings } from '@/pages/settings/services/useUpdateStaffSettings';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';
import { StaffRowSectionProps } from '../../manageStaff/staffTable/StaffRow';
import { StaffRowCell } from '../../manageStaff/staffTable/StaffRowCell';
import StaffRowDetailsTooltip from '../../manageStaff/staffTable/StaffRowDetailsTooltip';

export interface EditNotesFields {
  notes?: string;
}

export const SkillStaffRowDetailsSection: FC<StaffRowSectionProps> = ({ staff, group }) => {
  const { update } = useUpdateStaffSettings();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onClose = () => {
    setModalOpen(false);
  };

  const submitNotes = async (data: EditNotesFields) => {
    staff.settings.notes = data.notes;
    await update([staff]);
  };

  const { workingStaffSettingsErrors } = useEditSettingsService(state => ({
    workingStaffSettingsErrors: state.workingStaffSettingsErrors
  }));

  const employeeId = staff.employee.employeeId || strings.settings.manageStaff.casual;

  const workingStaffErrors = workingStaffSettingsErrors.find(staff => staff.employeeId === employeeId);

  return (
    <>
      <NotesModal
        open={modalOpen}
        close={onClose}
        submitNotes={submitNotes}
        noteValue={staff.settings.notes}
        emptyNoteString={strings.settings.manageStaff.noNotes}
      >
        <h2>
          {displayName({
            firstName: staff.employee.firstName,
            lastName: staff.employee.lastName
          })}
        </h2>
      </NotesModal>
      <StaffRowCell staff={staff} left={0}>
        <div className="flex items-center space-x-2">
          <p>{employeeId}</p>
          {workingStaffErrors && workingStaffErrors.errors.length > 0 && (
            <StaffRowDetailsTooltip employeeId={workingStaffErrors.employeeId} errors={workingStaffErrors.errors} />
          )}
        </div>
      </StaffRowCell>
      <StaffRowCell staff={staff} left={70} hidden={group.columns[0].isColumnHidden} className="flex">
        <span className="flex-1">
          {displayName({
            firstName: staff.employee.firstName,
            lastName: staff.employee.lastName
          })}
        </span>
        <NotesButton onClick={() => setModalOpen(true)} />
      </StaffRowCell>
      <StaffRowCell staff={staff} left={200} hidden={group.columns[1].isColumnHidden}>
        {displayName({
          firstName: staff.employee.kanjiFirstName,
          lastName: staff.employee.kanjiLastName
        })}
      </StaffRowCell>
    </>
  );
};
