import { clsx } from 'clsx';
import { FC } from 'react';
import { TimeSlot, displaySecondsAsTime, useTimeSlots } from './ganttHelper';
import { useGanttService } from './GanttProvider';

interface GanttTimerBarProps {
  className?: string;
}

const GanttTimerCell: FC<{ slot: TimeSlot }> = ({ slot }) => {
  return (
    <div className="flex-1 relative" style={{ height: 40 }}>
      <div className="absolute w-full h-full flex items-center justify-center border-r box-border">
        {displaySecondsAsTime(slot.start)}
      </div>
    </div>
  );
};

export const GanttTimerBar: FC<GanttTimerBarProps> = ({ className }) => {
  const { minTime, maxTime } = useGanttService(state => ({ minTime: state.minTime, maxTime: state.maxTime }));

  const hourlySlots = useTimeSlots(minTime, maxTime, 60 * 60);

  return (
    <div className={clsx('flex w-full bg-background-mid border-b pr-9', className)}>
      <div className="flex-1 flex">
        {hourlySlots.map(slot => (
          <GanttTimerCell slot={slot} key={slot.start} />
        ))}
      </div>
    </div>
  );
};
