import { dateToApiFormat } from '@/helpers/dateHelper';
import { useSummary } from '@/services/summary/useSummaryService';
import { useGetContractName } from '@/services/contract/useGetContractName';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { displayName } from '@/services/translation/name';
import { useMemo } from 'react';
import { ReadEmployee, useLocationExceptionsQuery, useLocationWorkingPatternsQuery } from '../gql/graphql.generated';
import { useGetDayOfWeekAndHoliday } from '../holiday/usePublicHolidays';
import { useEmployeeList } from '../staff/useEmployeeList';
import { useLocalSettings } from '../settings/LocalSettingsProvider';

export interface StaffDayTimes {
  start: number;
  end: number;
}

export interface RosteredShift extends StaffDayTimes {
  locationId: string;
  locationName: string;
}

export interface StaffAvailabilityDay {
  date: Date;
  pattern?: StaffDayTimes;
  preferences: StaffDayTimes[];
  availableTimes: StaffDayTimes[];
  rosteredShifts?: RosteredShift[];
  leaveTypeId?: string;
  exceptionId?: string;
}

export interface StaffAvailabilityMonth {
  employee: ReadEmployee;
  days: StaffAvailabilityDay[];
  displayEmployeeName: string;
  displayContractName: string;
}

export const useStaffAvailability = (dates: Date[]) => {
  const { homeEmployees: employees } = useEmployeeList({ date: dates[0] });
  const { homeEmployeesSummary: summary } = useSummary(dates[0], dates[dates.length - 1]);
  const getDayOfWeekAndHoliday = useGetDayOfWeekAndHoliday(dates[0]);
  const getContractName = useGetContractName();
  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);

  const selectedLocation = useLocalSettings(state => state.selectedLocationId);
  const [{ data: availabilityData }] = useLocationExceptionsQuery({
    variables: {
      locationId: selectedLocation,
      startDate: dateToApiFormat(dates[0]),
      endDate: dateToApiFormat(dates[dates.length - 1])
    },
    requestPolicy: 'cache-and-network'
  });

  const employeeAvailabilityData = availabilityData?.locationAvailabilityExceptions?.employeeAvailabilityExceptions;

  const [{ data: locationWorkingPatternsData }] = useLocationWorkingPatternsQuery({
    variables: {
      locationId: selectedLocation,
      date: dateToApiFormat(dates[0])
    },
    requestPolicy: 'cache-and-network'
  });

  const workingPatterns = locationWorkingPatternsData?.locationWorkingPatterns?.employeePatterns;
  if (!workingPatterns) {
    throw Error('Could not get working patterns');
  }

  const staffAvailability: StaffAvailabilityMonth[] = useMemo(() => {
    const availabilityForMonth = employees.map(employee => {
      const summaryRow = summary.find(s => s.id === employee.identityId);

      if (!summaryRow) {
        throw Error('Employee not in summary');
      }

      const dataForEmployee = employeeAvailabilityData?.find(
        e => e.employeeId === employee.identityId
      )?.patternsAndExceptions;

      if (!dataForEmployee) {
        throw Error('Could not find employee availability data.');
      }

      const employeePatternData = workingPatterns.find(w => w.identityId === employee.identityId);
      if (!employeePatternData?.periods || !employeePatternData.periods[0]) {
        throw Error('Could not get patterns for employee');
      }
      const employeePatterns = employeePatternData.periods[0].patterns;

      const employeeExceptions = dataForEmployee[0].exceptions;
      if (!employeeExceptions) {
        throw Error('Could not get exceptions for employee');
      }

      const days = dates.map(day => {
        const dayNumber = getDayOfWeekAndHoliday(day);
        const patternDay = employeePatterns?.find(w => w.dayNumber === dayNumber);
        const scheduleDay = summaryRow.schedule.find(
          sch => dateToApiFormat(new Date(sch.date)) === dateToApiFormat(day)
        );

        const data: StaffAvailabilityDay = {
          date: day,
          preferences: [],
          availableTimes: []
        };

        if (patternDay && patternDay.startTime !== undefined && patternDay.endTime !== undefined) {
          data.pattern = { start: patternDay.startTime, end: patternDay.endTime };
        }

        if (scheduleDay) {
          if (scheduleDay.leaveType) {
            data.leaveTypeId = scheduleDay.leaveType;
          }

          if (scheduleDay.shifts.length > 0) {
            data.rosteredShifts = scheduleDay.shifts.map(shift => ({
              start: shift.start,
              end: shift.end,
              locationId: shift.locationId,
              locationName: shift.locationName
            }));
          }

          const exceptionData = employeeExceptions.filter(
            e => dateToApiFormat(new Date(e.date)) === dateToApiFormat(day)
          );

          exceptionData.forEach(e => {
            if (e.available) {
              data.preferences.push({ start: e.start, end: e.end });
            }
          });

          if (exceptionData.length > 0) {
            data.availableTimes = data.preferences.map(p => ({ ...p }));
          } else if (data.pattern) {
            data.availableTimes.push({ ...data.pattern });
          }

          data.availableTimes.sort((a, b) => a.start - b.start);
        }

        return data;
      });

      let displayEmployeeName = displayName(employee);
      if (displayIds) {
        displayEmployeeName += ` (${employee.employeeId})`;
      }

      const displayContractName = getContractName(employee.contract?.contractTypeId || '');

      return { employee: employee as ReadEmployee, days, displayEmployeeName, displayContractName };
    });

    return availabilityForMonth;
  }, [employees, dates, summary, getDayOfWeekAndHoliday, displayIds, getContractName]);

  return staffAvailability;
};
