import { PdfParams } from '@/pages/pdf/summary/PdfSummaryDay';
import { usePublishSummary } from '@/pages/summary/publish/usePublishSummary';
import { useBudgetForLocation } from '@/services/budget/useBudget';
import { useBudgetStats } from '@/services/budget/useBudgetStats';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useRosterNotes } from '@/services/rosters/useRosterNotes';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { useComplianceDataTotals } from '@/services/summary/useCompliance';
import { useSummary } from '@/services/summary/useSummaryService';
import { strings } from '@/services/translation/strings';
import { PDFViewer } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { FC } from 'react';
import { usePrintOptions } from '../../roster/usePrintOptions';
import { MonthlyReportAsPDF } from './PdfMonthlyDocument';

export const MonthlyPrintTestPage: FC = () => {
  const days = useDaysInCurrentMonth();
  const { homeEmployeesSummary: summary } = useSummary(days[0], days[days.length - 1]);
  const [viewType] = usePrintOptions(state => [state.viewType]);

  const { budgetData } = useBudgetForLocation(days);
  const budgetStats = useBudgetStats(days);

  const leaveTypes = useLeaveTypes();
  const params: PdfParams = { leaveTypes, viewType: viewType };

  const language = useLanguageProvider(s => s.language);

  const selectedLocation = useSelectedLocation();
  const { notes: rosterNotes } = useRosterNotes(days[0], days[days.length - 1], selectedLocation.id);

  const {
    summary: { latestPublishDate }
  } = usePublishSummary();
  const publishDate = latestPublishDate ? new Date(latestPublishDate + 'Z') : undefined;
  const publishDateTimeString = publishDate
    ? strings.summary.lastPublished + ': ' + format(publishDate, 'yyyy/MM/dd HH:mm')
    : undefined;

  const complianceTotals = useComplianceDataTotals(summary);

  return (
    <div>
      <PDFViewer className="fixed h-screen w-screen top-0">
        <MonthlyReportAsPDF
          locationId={selectedLocation.id.toString()}
          locationName={selectedLocation.name}
          publishDateTime={publishDateTimeString}
          days={days}
          summary={summary}
          notes={rosterNotes}
          language={language}
          budgetData={budgetData}
          budgetStats={budgetStats}
          params={params}
          complianceTotals={complianceTotals}
        />
      </PDFViewer>
    </div>
  );
};
