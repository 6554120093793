import { BUDGET_NAME_COLUMN_WIDTH, BUDGET_TOTAL_COLUMN_WIDTH } from '@/pages/summary/constants';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { DateCellHeaders } from '@/components/table/DateCellHeaders';
import { buildRosterTooltip } from '@/components/tooltip/RosterTooltip';

export const MonthlyBudgetTableHeader: FC = () => {
  const days = useDaysInCurrentMonth();

  const tooltips = buildRosterTooltip(days)

  return (
    <div className="bg-background-mid border-b flex pr-5">
      <div className="font-semibold flex items-center px-5 border-l" style={{ width: BUDGET_NAME_COLUMN_WIDTH }}>
        {strings.common.locationName}
      </div>
      <div className="font-semibold flex items-center px-2 border-l" style={{ width: BUDGET_TOTAL_COLUMN_WIDTH }}>
        {strings.common.total}
      </div>
      <DateCellHeaders days={days} tooltips={tooltips} />
    </div>
  );
};
