import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { MarkedButton } from '@/components/buttons/MarkedButton';
import { UpDownButton } from '@/components/buttons/UpDownButton';
import { TextInputField } from '@/components/form/TextInputField';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { PersonaType } from '../../../../services/personas/persona.types';
import { TableColumn } from '../../layout/SettingsTable';
import { SettingsTableCell } from '../../layout/SettingsTableCell';
import { getColumnWidthStyles, updateObjectByKeys } from '../../layout/settingsTableHelper';

interface Props {
  persona: PersonaType;
  deleteEnabled?: boolean;
  isToBeDeleted?: boolean;
  columns: TableColumn[];
  onUpdate: (persona: PersonaType) => void;
  onDelete: (personaId: string, isToBeDeleted: boolean) => void;
  onRankChange: (direction: 'up' | 'down') => void;
}

export const Persona: FC<Props> = ({
  persona,
  deleteEnabled,
  isToBeDeleted,
  columns,
  onUpdate,
  onDelete,
  onRankChange
}) => {
  const onChange = updateObjectByKeys(onUpdate, persona);

  return (
    <>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[0])}>
        <UpDownButton onDownClick={() => onRankChange('down')} onUpClick={() => onRankChange('up')} />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[1])}>
        <TextInputField value={persona.name} setValue={e => onChange('name', e)}></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[2])}>
        <Toggle value={persona.adminControlled} onChange={e => onChange('adminControlled', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[3])}>
        <Toggle value={persona.management} onChange={e => onChange('management', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[4])}>
        <Toggle value={persona.templateLibrarian} onChange={e => onChange('templateLibrarian', e)} />
      </SettingsTableCell>
      <SettingsTableCell styles={getColumnWidthStyles(columns[5])} />
      {deleteEnabled && (
        <SettingsTableCell styles={getColumnWidthStyles(columns[columns.length - 1])}>
          {!isToBeDeleted && (
            <Button size="sm" variant="destructive" onClick={() => onDelete(persona.id, true)} className="w-20">
              {strings.common.delete}
            </Button>
          )}
          {isToBeDeleted && (
            <MarkedButton
              label={strings.common.toBeDeleted}
              mainClass="text-[#c23934]"
              buttonClass="border-[#c23934] hover:bg-[#c2393420]"
              onButtonClick={() => onDelete(persona.id, false)}
            />
          )}
        </SettingsTableCell>
      )}
    </>
  );
};
