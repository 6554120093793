import { dateToApiFormat } from '@/helpers/dateHelper';
import { useGetEmployeesForLocationQuery } from '../gql/graphql.generated';
import { useLocalSettings } from '../settings/LocalSettingsProvider';
import { useMemo } from 'react';

interface EmployeeListOptions {
  includeDeleted?: boolean;
  date?: Date;
  specifiedLocation?: number;
}

export const useEmployeeList = (options?: EmployeeListOptions) => {
  const { includeDeleted, date } = options || {};
  
  const selectedLocationId = useLocalSettings(state => state.selectedLocationId);
  const locationId = options?.specifiedLocation || selectedLocationId;

  const [{ data }, refetch] = useGetEmployeesForLocationQuery({ variables: { locationId: locationId } });
  const employees = useMemo(() => {
    const employees = data?.employeesByLocation?.employees?.filter(employee => {
      if (!includeDeleted && !employee.isRosterable) {
        return false;
      }

      if (date && employee.leaveDate && dateToApiFormat(date) > dateToApiFormat(new Date(employee.leaveDate))) {
        return false;
      }

      return true;
    });

    const sortedEmployees = employees?.sort((a, b) => {
      if (a.employeeId && !b.employeeId) {
        return -1;
      } else if (!a.employeeId && b.employeeId) {
        return 1;
      } else if (a.employeeId && b.employeeId) {
        return a.employeeId > b.employeeId ? 1 : -1;
      }

      return a.hireDate > b.hireDate ? 1 : -1;
    });

    return sortedEmployees;
  }, [includeDeleted, date, data]);

  if (!employees) {
    throw Error('Could not get employee list');
  }

  const homeEmployees = useMemo(() => {
    return employees.filter(employee => employee.homeLocationId === locationId);
  }, [employees, locationId]);

  return {
    homeEmployees: homeEmployees,
    allEmployees: employees,
    refetch
  };
};
