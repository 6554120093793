import { toastError, toastSuccess } from '@/helpers/helper';
import { PersonaType } from '@/services/personas/persona.types';
import { useCreatePersonas, useDeletePersonas, usePersonas, useUpdatePersonas } from '@/services/personas/usePersonas';
import { strings } from '@/services/translation/strings';
import { useCallback, useState } from 'react';
import uuid from 'react-uuid';

export const useEditPersonas = () => {
  const fetchedPersonas = usePersonas();
  const [personas, setPersonas] = useState<PersonaType[]>(fetchedPersonas);

  const [canSave, setCanSave] = useState<boolean>(false);

  const [personaIdsToAdd, setPersonaIdsToAdd] = useState<string[]>([]);
  const [personaIdsToUpdate, setPersonaIdsToUpdate] = useState<string[]>([]);
  const [personaIdsToDelete, setPersonasIdsToDelete] = useState<string[]>([]);

  const { create } = useCreatePersonas();
  const { update } = useUpdatePersonas();
  const { deletePersonas } = useDeletePersonas();

  const onPersonAdd = useCallback(() => {
    setCanSave(true);

    let newPersonaName = `${strings.settings.managePersonas.persona} ${personas.length + 1}`;
    const similarNamePersonas = personas.filter(t => t.name === newPersonaName);
    if (similarNamePersonas.length) {
      newPersonaName += `-${similarNamePersonas.length}`;
    }

    const newRank = personas.reduce((highestRank, persona) => {
      return Math.max(highestRank, persona.rank);
    }, 0);

    const newPersona: PersonaType = {
      id: `CREATE-${uuid()}`,
      name: newPersonaName,
      management: false,
      adminControlled: false,
      templateLibrarian: false,
      rank: newRank
    };

    personaIdsToAdd.push(newPersona.id);

    const allPersonas = [...personas, newPersona];
    setPersonas(allPersonas);
  }, [personas]);

  const onPersonaUpdate = (persona: PersonaType) => {
    setCanSave(true);

    personaIdsToUpdate.push(persona.id);

    const newPersonas = [...personas];

    const personaIndex = personas.findIndex(s => s.id === persona.id);
    newPersonas[personaIndex] = { ...persona };
    setPersonas(newPersonas);
  };

  const onPersonaDelete = (personaId: number | string, isToBeDeleted: boolean) => {
    setCanSave(true);

    const personaIdsToBeDeleted = [...personaIdsToDelete];
    const deletePersonaIndex = personaIdsToBeDeleted.indexOf(personaId as any);

    if (deletePersonaIndex === -1 && isToBeDeleted) {
      personaIdsToBeDeleted.push(personaId as any);
    } else if (deletePersonaIndex >= 0 && !isToBeDeleted) {
      personaIdsToBeDeleted.splice(deletePersonaIndex, 1);
    }

    setPersonasIdsToDelete(personaIdsToBeDeleted);
  };

  const onPersonaRankChange = (personaToChange: PersonaType) => (direction: 'up' | 'down') => {
    setCanSave(true);

    const personaIndex = personas.findIndex(p => p.id === personaToChange.id);

    if (personaIndex === -1) {
      throw Error('Could not find persona in list');
    }

    const newPersonas = [...personas];
    newPersonas.forEach((p, index) => (p.rank = index));

    const persona = newPersonas[personaIndex];

    if (direction === 'up' && personaIndex !== 0) {
      const personaAbove = newPersonas[personaIndex - 1];
      persona.rank -= 1;
      personaAbove.rank += 1;
      personaIdsToUpdate.push(persona.id);
      personaIdsToUpdate.push(personaAbove.id);
    } else if (direction === 'down' && personaIndex !== personas.length - 1) {
      const personaBelow = newPersonas[personaIndex + 1];
      persona.rank += 1;
      personaBelow.rank -= 1;
      personaIdsToUpdate.push(persona.id);
      personaIdsToUpdate.push(personaBelow.id);
    }

    newPersonas.sort((a, b) => a.rank - b.rank);

    setPersonas(newPersonas);
  };

  const onSave = async () => {
    let errorHasOccured = false;

    const approvedForUpdate = personaIdsToUpdate.filter(t => !personaIdsToDelete.includes(t) && !t.includes('CREATE'));
    const approvedForAdd = personaIdsToAdd.filter(t => !personaIdsToDelete.includes(t));

    if (personaIdsToDelete.length) {
      setPersonaIdsToAdd(personaIdsToAdd.filter(t => !personaIdsToDelete.includes(t)));
      const approvedForDelete = personaIdsToDelete.filter(t => !t.includes('CREATE'));

      try {
        if (approvedForDelete.length) {
          const deleteResult = await deletePersonas(approvedForDelete);
          setPersonasIdsToDelete([]);

          if (deleteResult.error) {
            errorHasOccured = true;
          }
        }
      } catch (error) {
        console.log('Delete personas error', error);
      }

      setPersonas(personas.filter(t => !personaIdsToDelete.includes(t.id)));
      setPersonasIdsToDelete([]);
    }

    if (approvedForAdd.length) {
      try {
        const result = await create(personas.filter(p => approvedForAdd.includes(p.id)));

        if (result.error) {
          errorHasOccured = true;
        } else {
          setPersonaIdsToAdd([]);
        }
      } catch (error) {
        console.log('Add personas error', error);
      }
    }

    if (approvedForUpdate.length) {
      try {
        const result = await update(personas.filter(p => approvedForUpdate.includes(p.id)));

        if (result.error) {
          errorHasOccured = true;
        } else {
          setPersonaIdsToUpdate([]);
        }
      } catch (error) {
        console.log('Update personas error', error);
      }
    }

    if (errorHasOccured) {
      toastError();
    } else {
      toastSuccess();
    }

    setCanSave(false);
  };

  return {
    personas,
    onPersonAdd,
    onPersonaUpdate,
    onPersonaDelete,
    onSave,
    canSave,
    idsToBeDeleted: personaIdsToDelete,
    onPersonaRankChange
  };
};
