import {FoundShifts, ReadRosterPositions, useFindShiftsQuery} from './gql/graphql.generated';
import {useLocalSettings} from "@/services/settings/LocalSettingsProvider";
import {useCallback, useEffect, useState} from "react";
import {useSiblingLocations} from "@/services/useSiblingLocations";
import {dateToApiFormat} from "@/helpers/dateHelper";
import { useReassignmentService } from '@/pages/daily/roster/providers/ReassignmentService';

export const useRequestedShifts = () => {
  const [requestedShifts, setRequestedShifts] = useState<FoundShifts[]>();
  const date = new Date(useLocalSettings(state => state.selectedDate));
  const siblingLocationIds = useSiblingLocations();
  const staffSearchParams = {
    locationIds: siblingLocationIds,
    date: dateToApiFormat(date),
    propertyPath: 'requesting',
    propertyValue: 'true'
  };
  const [{ data}] = useFindShiftsQuery({
    variables: { searchParams: staffSearchParams },
    requestPolicy: 'network-only',
    pause: siblingLocationIds === undefined
  });
  const { reassignedShifts } = useReassignmentService(state => ({
    reassignedShifts: state.reassignedShifts
  }));

  useEffect(() => {
    if (data) {
      // Remove reassigned shifts from requested shifts. There will be overlaps while requested shifts are
      // picked up by the provider shop, but staff are not assigned yet.
      const filteredRequestedShifts: FoundShifts[] = [];
      (data.findShiftsByProperty as FoundShifts[]).forEach(location => {
        const filteredLocation = {...location};
        filteredLocation.shifts = filteredLocation.shifts?.filter(s => !reassignedShifts.length || !reassignedShifts.find(rs => rs.id === s.id));
        if (filteredLocation.shifts && filteredLocation.shifts.length > 0) {
          filteredRequestedShifts.push(filteredLocation);
        }
      });

      setRequestedShifts(filteredRequestedShifts);
    }
  }, [data, reassignedShifts, setRequestedShifts])

  const removeRequestedShift = useCallback((shift: ReadRosterPositions) => {
    const filteredRequestedShifts: FoundShifts[] = [];
    requestedShifts?.forEach(location => {
      const filteredLocation = {...location};
      filteredLocation.shifts = filteredLocation.shifts?.filter(s => s.id !== shift.id);
      if (filteredLocation.shifts && filteredLocation.shifts.length > 0) {
        filteredRequestedShifts.push(filteredLocation);
      }
    });

    setRequestedShifts(filteredRequestedShifts);

  }, [requestedShifts, setRequestedShifts]);

  return {requestedShifts, removeRequestedShift};
};
