import { FC } from 'react';
import { LocationSelector } from './layout/main/LocationSelector';
import { PageHeader } from './layout/main/PageHeader';
import { DateSelector } from './layout/DateSelector';
import { LocationMultiSelector } from './layout/main/LocationMultiSelector';
import { Navigate } from 'react-router-dom';
import { useNavBarOptions } from './layout/main/useNavigationOptions';

export const Home: FC = () => {
  const navbarOptions = useNavBarOptions();
  const redirectionPath = navbarOptions.length ? navbarOptions[0].path : '/';

  if (import.meta.env.DEV) {
    return (
      <PageHeader>
        <LocationSelector />
        <LocationMultiSelector />
        <DateSelector />
      </PageHeader>
    );
  } else {
    return <Navigate to={redirectionPath} />;
  }
};
