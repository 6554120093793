import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate, useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { useWorkingTemplateToGqlTemplate } from '../templateHelper';
import { useSelectedLibraryTemplate, useWorkingLibraryTemplate } from './TemplateLibraryService';

export const useSaveLibraryTemplate = () => {
  const { workingTemplate, setWorkingTemplate } = useWorkingLibraryTemplate();
  const selectedTemplate = useSelectedLibraryTemplate();
  const [{ fetching, error }, update] = useUpdateTemplateMutation();
  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));
  const workingTemplateToGqlTemplate = useWorkingTemplateToGqlTemplate();
  
  const saveTemplate = useCallback(
    async (updateMajorVersion?: boolean) => {
      if (!workingTemplate || !selectedTemplate) {
        throw Error('Template must be defined');
      }

      const version = workingTemplate.version;
      if (version) {
        const splitVersion = version.split('.');
        if (splitVersion.length !== 2) {
          throw Error('Version should be of the form XX.YY');
        }

        const majorVersion = parseInt(splitVersion[0]);
        const minorVersion = parseInt(splitVersion[1]);

        if (!updateMajorVersion) {
          workingTemplate.version = `${majorVersion}.${minorVersion + 1}`;
        } else {
          workingTemplate.version = `${majorVersion + 1}.0`;
          workingTemplate.lastPublished = new Date();
        }
      }
      
      setWorkingTemplate({ ...workingTemplate });
      const updateTemplate = workingTemplateToGqlTemplate(workingTemplate, selectedTemplate as ReadTemplate);
      updateTemplate.draft = true;

      const result = await update({
        updateTemplate
      });

      unregisterForConfirmation();

      return result;
    },
    [workingTemplate, setWorkingTemplate, update, selectedTemplate, workingTemplateToGqlTemplate]
  );

  return { saveTemplate, error, fetching };
};
