import { PropsWithChildren, FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface BudgetCellProps extends PropsWithChildren {
  className?: string;
  onClick?: () => void;
}

export const BudgetCell: FC<BudgetCellProps> = ({ children, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={twMerge('border-l border-b flex items-center px-1 text-xs overflow-hidden', className)}
    >
      {children}
    </div>
  );
};
