import { dateToApiFormat } from '@/helpers/dateHelper';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';
import {
  ReadEmployee,
  useGetEmployeeSkillsQuery,
  useGetEmployeeWorkRulesQuery,
  useLocationWorkingPatternsQuery
} from '../gql/graphql.generated';
import { useEmployeeList } from '../staff/useEmployeeList';
import { useLocalSettings } from './LocalSettingsProvider';
import { defaultStaffSettings, StaffSettings, StaffWithSettings, WorkingPatterns } from './systemSettings.types';
import { useDefaultWorkingPattern } from './useDefaultWorkingPattern';

interface StaffSettingsStore {
  settings: StaffWithSettings[];
  setSettings: (newSettings: StaffWithSettings[]) => void;
  settingsForStaffMember: (employeeId: string) => StaffSettings;
  refetchStaffSkills: () => void;
}

export const StaffSettingsContext = createContext<StoreApi<StaffSettingsStore> | null>(null);

export const StaffSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { allEmployees: employees, refetch: refetchEmployees } = useEmployeeList({ includeDeleted: true });

  const employeeIds = useMemo(() => employees.map(emp => emp.identityId), [employees]);
  const [{ data: workRulesData }, refetchWorkRules] = useGetEmployeeWorkRulesQuery({
    variables: { employeeIds: employeeIds }
  });

  const workRules = workRulesData?.workRulesByEmployeeIds;

  if (!workRules) {
    throw Error('Could not get work rules');
  }

  const selectedLocation = useLocalSettings(state => state.selectedLocationId);
  const [{ data: locationWorkingPatternsData }, refetchWorkingPatterns] = useLocationWorkingPatternsQuery({
    variables: {
      locationId: selectedLocation,
      date: dateToApiFormat(new Date())
    }
  });

  const workingPatterns = locationWorkingPatternsData?.locationWorkingPatterns;
  if (!workingPatterns) {
    throw Error('Could not get working patterns');
  }

  const [{ data: skillData }, refetchSkills] = useGetEmployeeSkillsQuery({ variables: { employeeIds } });
  const skillList = skillData?.employeeSkillsByEmployeeIds;
  if (!skillList) {
    throw Error('Could not get employee skills');
  }

  const defaultPattern = useDefaultWorkingPattern();

  const store = useMemo(() => {
    const settings: StaffWithSettings[] = employees.map(employee => {
      const rules = workRules.find(wr => wr.identityId === employee.identityId);

      const employeePattern = locationWorkingPatternsData?.locationWorkingPatterns?.employeePatterns?.find(
        pattern => pattern.identityId === employee.identityId
      );

      let workingPatternsObject: WorkingPatterns[] = [];

      if (employeePattern) {
        const workingPatternsFromData = employeePattern.periods![0]?.patterns;

        if (workingPatternsFromData?.length) {
          workingPatternsFromData?.forEach(workingPattern => {
            workingPatternsObject.push({
              day: workingPattern.dayNumber!,
              startTime: workingPattern.startTime || 0,
              endTime: workingPattern.endTime || 0,
              id: workingPattern.id
            });
          });
        }
      }

      if (workingPatternsObject.length === 0) {
        workingPatternsObject = defaultPattern;
      }

      const assignedSkills: string[] = [];
      const employeeSkills = skillList.find(s => s.employeeId === employee.identityId);
      employeeSkills?.skills?.forEach(skill => assignedSkills.push(skill.skillId));

      const assignedPersonaIds: string[] = employee.personas?.map(p => p.id) || [];
      const assignedLocationIds: number[] = employee.restaurants?.map(p => p.id) || [];

      const properties = employee.properties ? JSON.parse(employee.properties) : {};

      const employeeSettings: StaffSettings = {
        ...defaultStaffSettings,
        phoneNumber: employee.phoneNumber ?? defaultStaffSettings.phoneNumber,
        email: employee.email ?? defaultStaffSettings.email,
        assignedSkills,
        dateOfBirth: employee.dateOfBirth && new Date(employee.dateOfBirth),
        hiredDate: employee.hireDate && new Date(employee.hireDate),
        endProbationDate: employee.endOfProbationDate && new Date(employee.endOfProbationDate),
        leftCompanyDate: employee.leaveDate && new Date(employee.leaveDate),
        canMentor: employee.mentor,
        requiresMentor: employee.mentee,
        hasBeenDeleted: !employee.isRosterable,
        assignedPersonaIds,
        assignedLocationIds,
        notes: properties.notes
      };

      if (rules) {
        employeeSettings.minMonthlyHours =
          rules.minHoursPerMonth === undefined ? defaultStaffSettings.minMonthlyHours : rules.minHoursPerMonth;
        employeeSettings.maxMonthlyHours =
          rules.maxHoursPerMonth === undefined ? defaultStaffSettings.maxMonthlyHours : rules.maxHoursPerMonth;

        employeeSettings.minWeeklyHours =
          rules.minHoursPerWeek === undefined ? defaultStaffSettings.minWeeklyHours : rules.minHoursPerWeek;
        employeeSettings.maxWeeklyHours =
          rules.maxHoursPerWeek === undefined ? defaultStaffSettings.maxWeeklyHours : rules.maxHoursPerWeek;
      }

      if (workingPatternsObject.length > 0) {
        employeeSettings.workingPatterns = workingPatternsObject;
      }

      return {
        employee: employee as ReadEmployee,
        settings: employeeSettings
      };
    });

    return createStore<StaffSettingsStore>()((set, get) => ({
      settings,
      setSettings: settings => set({ settings }),
      settingsForStaffMember: employeeId => {
        const staffSettings = get().settings.find(emp => emp.employee.identityId === employeeId);

        if (!staffSettings) {
          return {} as StaffSettings;
        }

        return staffSettings.settings;
      },
      refetchStaffSkills: () => {
        refetchEmployees({ requestPolicy: 'network-only' });
        refetchWorkingPatterns({ requestPolicy: 'network-only' });
        refetchWorkRules({ requestPolicy: 'network-only' });
        refetchSkills({ requestPolicy: 'network-only' });
      }
    }));
  }, [
    employees,
    workRules,
    locationWorkingPatternsData?.locationWorkingPatterns?.employeePatterns,
    skillList,
    defaultPattern,
    refetchEmployees,
    refetchWorkingPatterns,
    refetchWorkRules,
    refetchSkills
  ]);

  return <StaffSettingsContext.Provider value={store}>{children}</StaffSettingsContext.Provider>;
};

type SelectorReturn<S extends (s: StaffSettingsStore) => any> = ReturnType<S>;
export function useStaffSettings<S extends (s: StaffSettingsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(StaffSettingsContext);
  if (!context) {
    throw new Error('useStaffSettings must be used within a StaffSettingsProvider');
  }
  return useStore(context, selector);
}
