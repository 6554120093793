import { GanttGridLineSpacing } from '@/services/settings/systemSettings.types';
import { FunctionComponent } from 'react';

interface GanttGridLineSpacingProps {
  setting: GanttGridLineSpacing;
  slotIndex: number;
}

const getNumberOfHourlyGridLineSpacingDivs = (setting: GanttGridLineSpacing) => {
  switch (setting) {
    case GanttGridLineSpacing.none:
      return 0;
    case GanttGridLineSpacing.tenMinutes:
      return 6;
    case GanttGridLineSpacing.fifteenMinutes:
      return 4;
    case GanttGridLineSpacing.thirtyMinutes:
      return 2;
    default:
      return 0;
  }
};

const GanttHourlyGridLinesSpacing: FunctionComponent<GanttGridLineSpacingProps> = ({ setting, slotIndex }) => {
  const hourlyLineSpacingAmount = getNumberOfHourlyGridLineSpacingDivs(setting);
  const hourlyLineSpacingWidth = +(100 / hourlyLineSpacingAmount).toFixed(2);
  return (
    <div className="w-full h-full flex flex-row [&>*:not(:last-child)]:border-r">
      {Array.from({ length: hourlyLineSpacingAmount }, (_, index) => (
        <div
          key={`${slotIndex}-${index}`}
          style={{ width: `${hourlyLineSpacingWidth}%` }}
          className={`opacity-30`}
        ></div>
      ))}
    </div>
  );
};

export default GanttHourlyGridLinesSpacing;
