import {FC, ReactElement, useState} from 'react';
import { DocumentProps } from '@react-pdf/types';
import {PdfPrintModal} from "@/pages/pdf/PdfPrintModal";
import {Button} from "@/components/button";
import {strings} from "@/services/translation/strings";

interface Props {
  createReport: () => ReactElement<DocumentProps>;
  name: string;
  testRoutePath?: string;
}

const DownloadReportButtonWithModal: FC<Props> = ({ createReport, name, testRoutePath }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <Button onClick={() => {setIsModalOpen(true)}}>
        {strings.common.print}
      </Button>
      <PdfPrintModal
        createDocument={createReport}
        fileName={name}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        testRoutePath={testRoutePath}
      />
    </div>
  );
};

export default DownloadReportButtonWithModal;
