import { Timestamp } from '@/components/Timestamp';
import { Button } from '@/components/button';
import { ConfirmationModal } from '@/components/modal/ConfirmationModal';
import { DateSelector } from '@/pages/layout/DateSelector';
import { LocationSelector } from '@/pages/layout/main/LocationSelector';
import { PageHeader } from '@/pages/layout/main/PageHeader';
import { PdfRosterDownloadButton } from '@/pages/pdf/roster/PdfRosterDownloadButton';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useDeleteRosterMutation } from '@/services/gql/graphql.generated';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useEmployeeList } from '@/services/staff/useEmployeeList';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { CreateReplaceTemplateMenu } from '../createReplaceTemplate/CreateReplaceTemplateMenu';
import { RosterTemplateSelector } from './RosterTemplateSelector';
import { useGetUpdateTemplateFromWorkingRoster } from './useGetUpdateTemplateFromWorkingRoster';
import { usePublishWorkingRoster } from './usePublishWorkingRoster';
import { useSaveRoster } from './useSaveRoster';
import { useIsRosterValid } from './providers/RosterWarningsService';
import { useWorkingRoster } from './providers/WorkingRosterService';

interface RosterHeaderProps {
  isSaveFetching: boolean;
}

export const RosterHeader: FC<RosterHeaderProps> = ({isSaveFetching}) => {
  const { saveRoster, fetching } = useSaveRoster();
  const { workingRoster } = useWorkingRoster();
  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));
  const { homeEmployees: employees } = useEmployeeList({ date: new Date(selectedDate) });

  const onPublish = async () => {
    if (saveNeeded) {
      return;
    }

    const result = await publishRoster();

    toastReaction(!!result.error);
  };

  const onSave = async (isConfirmed: boolean = false) => {
    const shiftWithNoTime = workingRoster?.shifts.filter(sh => !sh.start && !sh.end);

    if (shiftWithNoTime?.length && !isConfirmed) {
      setSaveConfirmModalOpen(true);
    } else {
      const result = await saveRoster();
      setSaveConfirmModalOpen(false);
      toastReaction(!!result.error, result.error?.message);
    }
  };

  const parseErrorMessage = (errorMessage?: string) => {
    if (!errorMessage) return undefined;

    const errorLines = errorMessage.split('\n');
    if (errorLines.length) {
      const gqlExecutionErrorMessage = errorLines[0]
        .replace('[GraphQL] ', '')
        .replace('GraphQL.ExecutionError:', '')
        .trim();

      if (
        gqlExecutionErrorMessage.startsWith('Employee ') &&
        gqlExecutionErrorMessage.includes(' already assigned to Location ')
      ) {
        const identityId = gqlExecutionErrorMessage.split(' ')[1];
        const employee = employees.find(e => e.identityId === identityId);
        if (employee) {
          return strings.daily.roster.errors.employeeAlreadyAssigned(
            displayName({
              firstName: employee.firstName,
              lastName: employee.lastName,
              kanjiFirstName: employee.kanjiFirstName,
              kanjiLastName: employee.kanjiLastName
            }),
            employee.employeeId
          );
        }
      }
    }
    return undefined;
  };

  const toastReaction = (error: boolean, errorMessage?: string) => {
    if (error) {
      const message = parseErrorMessage(errorMessage) ?? strings.common.error;
      toast(message, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  };

  const { saveNeeded } = useConfirm(state => ({
    saveNeeded: state.confirmationNeeded
  }));

  const rosterValid = useIsRosterValid();

  const canSave = saveNeeded && rosterValid;

  const canDelete = useSystemSettings(s => s.settings.canDeleteRosters);
  const [{ fetching: deleteFetching }, deleteRoster] = useDeleteRosterMutation();
  const navigate = useNavigate();

  const onDelete = async () => {
    await deleteRoster({ rosterId: workingRoster!.id });
    navigate('/monthly');
  };

  const { publishRoster, fetching: publishFetching } = usePublishWorkingRoster();

  const publishButton = workingRoster?.published ? (
    <Button variant={'submit'} className="space-x-2">
      <FiCheck className="h-6 w-6" />
      <p>{strings.daily.roster.published}</p>
    </Button>
  ) : (
    <Button onClick={onPublish} loading={publishFetching} variant={saveNeeded ? undefined : 'primary'}>
      {strings.daily.roster.publish}
    </Button>
  );

  const publishIndividual = useFlag('publishIndividual');

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [saveConfirmModalOpen, setSaveConfirmModalOpen] = useState<boolean>(false);

  const getUpdateTemplateFromWorkingRoster = useGetUpdateTemplateFromWorkingRoster();

  return (
    <PageHeader className="justify-between">
      <div className="flex gap-5 items-center">
        <LocationSelector />
        <DateSelector showNavigationArrows={true} />
        <RosterTemplateSelector />
      </div>
      <div className="flex gap-5 items-center">
        {workingRoster?.lastSaved && (
          <Timestamp timestamp={workingRoster.lastSaved} label={strings.daily.roster.lastSaved} />
        )}
        {workingRoster?.lastPublished && (
          <Timestamp
            timestamp={workingRoster.lastPublished}
            label={strings.daily.roster.lastPublished}
            color={workingRoster?.published ? 'green' : undefined}
          />
        )}
        {workingRoster && (
          <>
            <div className="flex gap-5 items-center">
              <PdfRosterDownloadButton />
            </div>
            {!workingRoster?.readOnly && (
              <>
                <div className="flex justify-end items-end flex-1">
                  <CreateReplaceTemplateMenu
                    templateId={workingRoster.templateId}
                    updateTemplate={getUpdateTemplateFromWorkingRoster(workingRoster)}
                    selectedDate={selectedDate}
                  />
                </div>
                <Button
                  className={`w-24 ${canSave ? 'hover:cursor-pointer' : 'hover:cursor-not-allowed'}`}
                  onClick={canSave ? () => onSave(false) : undefined}
                  loading={fetching || isSaveFetching}
                  variant={canSave ? 'submit' : undefined}
                >
                  {strings.common.save}
                </Button>
                {publishIndividual && publishButton}
              </>
            )}
            {canDelete && (
              <Button onClick={() => setDeleteModalOpen(true)} variant={'destructive'}>
                {strings.common.delete}
              </Button>
            )}
          </>
        )}
      </div>
      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        message={strings.daily.roster.deleteModal.message}
        header={strings.daily.roster.deleteModal.title}
        onConfirm={onDelete}
        confirmButtonText={strings.common.delete}
        loading={deleteFetching}
      />
      <ConfirmationModal
        open={saveConfirmModalOpen}
        onClose={() => setSaveConfirmModalOpen(false)}
        message={strings.daily.roster.saveWorkInProgressModal.message}
        header={strings.daily.roster.saveWorkInProgressModal.title}
        onConfirm={() => onSave(true)}
        confirmButtonText={strings.common.yes}
        cancelButtonText={strings.common.no}
        loading={fetching}
      />
    </PageHeader>
  );
};
