import {
  useLocationExceptionsQuery
} from '@/services/gql/graphql.generated';
import {useSelectedLocation} from '@/services/settings/LocalSettingsProvider';
import {createContext, FC, PropsWithChildren, useContext, useMemo} from 'react';
import {createStore, StoreApi, useStore} from 'zustand';
import {useAvailabilityExceptionChangeMonitor} from "@/services/availability/useAvailabilityExceptionChangeMonitor";
import {convertToUtcDate, dateToApiFormat} from "@/helpers/dateHelper";

interface LastWorkPreferencesUpdated {
  identityId: string;
  updatedOn?: Date;
}
interface Store {
  lastUpdated: LastWorkPreferencesUpdated[];
  setLastUpdated: (identityId: string, updated: Date) => void;
}

export const WorkPreferencesTrackingContext = createContext<StoreApi<Store> | null>(null);

interface Props extends PropsWithChildren {
  startDate: string | Date;
  endDate: string | Date;
}

export const WorkPreferencesTrackingProvider: FC<Props> = ({ startDate, endDate, children }) => {
  const selectedLocationId = useSelectedLocation().id;
  const [{ data }] = useLocationExceptionsQuery({
    variables: {
      locationId: selectedLocationId,
      startDate: typeof startDate === 'string' ? startDate : dateToApiFormat(startDate),
      endDate: typeof endDate === 'string' ? endDate : dateToApiFormat(endDate)
    },
    requestPolicy: 'network-only'
  });

  const store = useMemo(() => {
    let lastUpdated: LastWorkPreferencesUpdated[] = [];
    if (data?.locationAvailabilityExceptions?.employeeAvailabilityExceptions) {
      lastUpdated = data.locationAvailabilityExceptions.employeeAvailabilityExceptions.map(ex => {
        let lastUpdatedByEmployee: Date | undefined = undefined;
        ex.patternsAndExceptions?.forEach(pe => {
          pe.exceptions?.forEach(e => {
            const date = convertToUtcDate(e.updatedOn)!;
            if (!lastUpdatedByEmployee || lastUpdatedByEmployee < date) {
              lastUpdatedByEmployee = date;
            }
          });
        });
        return {
          identityId: ex.employeeId,
          updatedOn: lastUpdatedByEmployee
        }
      });
    }

    return createStore<Store>()((set, get) => ({
      lastUpdated: lastUpdated,
      setLastUpdated: (identityId, updated) => {
        const newLastUpdated = [...get().lastUpdated];
        const index = newLastUpdated.findIndex(x => x.identityId === identityId);
        if (index === -1) {
          newLastUpdated.push({identityId, updatedOn: updated});
        } else {
          newLastUpdated[index] = {identityId, updatedOn: updated};
        }
        set({lastUpdated: newLastUpdated});
      }
    }));
  }, [data]);

  return <WorkPreferencesTrackingContext.Provider value={store}>{children}</WorkPreferencesTrackingContext.Provider>;
};

type SelectorReturn<S extends (s: Store) => any> = ReturnType<S>;
export function useWorkPreferencesTrackingService<S extends (s: Store) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(WorkPreferencesTrackingContext);
  if (!context) {
    throw new Error('useWorkPreferencesTrackingService must be used within a WorkPreferencesTrackingProvider');
  }
  return useStore(context, selector);
}

export const useWorkPreferencesTracking = (startDate: string | Date, endDate: string | Date) => {
  const { setLastUpdated } = useWorkPreferencesTrackingService(state => ({
    setLastUpdated: state.setLastUpdated
  }));

  useAvailabilityExceptionChangeMonitor(
    typeof startDate === 'string' ? new Date(startDate) : startDate,
    typeof endDate === 'string' ? new Date(endDate) : endDate,
    setLastUpdated);
}