import { Button } from '@/components/button';
import { FileInput } from '@/components/form/FileInput';
import { Modal } from '@/components/modal/Modal';
import { useUploadBudget } from '@/services/file/useUploadBudget';
import { strings } from '@/services/translation/strings';
import { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useBudgetService } from '../BudgetService';

export const BudgetUpload = () => {
  const refreshBudget = useBudgetService(state => state.refreshLocations);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const onClose = () => {
    setModalOpen(false);

    // Wait a little bit before clearing the file- otherwise there's a weird flicker.
    setTimeout(() => setFile(undefined), 1000);
  };

  const onSuccesfulUpload = () => {
    refreshBudget();
    onClose();
  }

  const { upload, isLoading, error } = useUploadBudget(onSuccesfulUpload);

  const onSubmit = async () => {
    if (file) {
      await upload(file);
    }
  };

  return (
    <div>
      <Button onClick={() => setModalOpen(true)}>
        <FiUpload className="mr-2" />
        {strings.common.file.upload}
      </Button>
      <Modal open={modalOpen} onClose={onClose} title={strings.budget.uploadBudget}>
        <div className="space-y-4 max-w-2xl w-screen">
          <FileInput file={file} setFile={setFile} />
          {error && <p className="text-red-500">{error.message}</p>}
          <div className="flex justify-end gap-5 items-center">
            <Button onClick={onClose}>{strings.common.cancel}</Button>
            <Button loading={isLoading} onClick={onSubmit}>{strings.common.submit}</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
