import { useMemo } from "react";
import { CheckedState, GroupId } from "./StaffSettingsGroupsHeader";
import { useStaffColumns } from "./useStaffColumns";

export const useInitialGroups = () => {
  const groups = useStaffColumns();

  const initialCheckedState: CheckedState = useMemo(
    () =>
      groups.reduce((acc: Record<GroupId, boolean>, group) => {
        acc[group.id] = true;
        return acc;
      }, {}),
    []
  );

  const savedCheckedState = useMemo(() => {
    const localSavedState = localStorage.getItem('checkedStaffSections');

    if (!localSavedState) {
      return undefined;
    }

    const stateAsObj = JSON.parse(localSavedState) as CheckedState;

    return stateAsObj;
  }, []);


  return savedCheckedState || initialCheckedState;
}