import { SnpsLogo } from '@/components/decoration/SnpsLogo';
import { ConfirmedLink } from '@/components/link/ConfirmedLink';
import { DropdownAction } from '@/components/menu/Action';
import { Dropdown, DropdownOrigin } from '@/components/menu/Dropdown';
import { Notifications } from '@/components/notification/Notifications';
import { copyToClipboard, useGetGqlHeader } from '@/helpers/debugHelper';
import { useSignOut } from '@/services/authentication/hooks/auth';
import { useUser } from '@/services/authentication/hooks/user';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { strings } from '@/services/translation/strings';
import { useFlag, useVariable } from '@softwareimaging/backstage';
import { FC, useEffect, useRef, useState } from 'react';
import { FiCopy, FiGlobe, FiLogOut } from 'react-icons/fi';
import { MdFace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { NavigationBarOptions } from './NavigationBarOptions';
import { useOrganisationName } from '@/services/hierarchy/useOrganisationName';

export const MainHeader: FC = () => {
  const navigate = useNavigate();
  const user = useUser();
  const orgName = useOrganisationName();
  const { language, setLanguage } = useLanguageProvider(state => ({
    language: state.language,
    setLanguage: state.setLanguage
  }));

  const debug = useFlag('debug');
  const getGqlHeader = useGetGqlHeader();

  const signout = useSignOut();

  const [navbarHeight, setNavbarHeight] = useState(null);
  const navBarRef: any = useRef(null);

  useEffect(() => {
    setNavbarHeight(navBarRef?.current?.offsetHeight);
  }, []);

  return (
    <div className="w-full h-16 border-b flex items-center px-10 gap-10" ref={navBarRef}>
      <ConfirmedLink to="">
        <SnpsLogo />
      </ConfirmedLink>
      <NavigationBarOptions></NavigationBarOptions>
      <div id="spaceFiller" className="flex-1" />
      <Dropdown
        origin={DropdownOrigin.TOP_RIGHT}
        button={
          <div className="">
            <b>{orgName} - </b>
            {`${user.lastName} ${user.firstName}`}
          </div>
        }
      >
        <DropdownAction onClick={() => navigate(`/myProfile/staffPickerColumns`)} className="flex items-center gap-2">
          <MdFace className="h-8 w-8" />
          <span>{strings.layout.headerMenu.myProfile}</span>
        </DropdownAction>
        <DropdownAction
          onClick={async () => await setLanguage(language === 'en' ? 'ja' : 'en')}
          className="flex items-center gap-2"
        >
          <FiGlobe className="h-8 w-8" />
          <span>{strings.layout.headerMenu.language}</span>
        </DropdownAction>
        {debug && (
          <DropdownAction
            onClick={async () => {
              const header = await getGqlHeader();
              copyToClipboard(header);
            }}
            className="flex items-center gap-2"
          >
            <FiCopy className="h-8 w-8" />
            <span>Playground Header</span>
          </DropdownAction>
        )}
        <DropdownAction onClick={signout} className="flex items-center gap-2">
          <FiLogOut className="h-8 w-8" />
          <span>{strings.layout.headerMenu.logout}</span>
        </DropdownAction>
      </Dropdown>
      <Notifications navbarHeight={navbarHeight!} />
    </div>
  );
};
