import { SeverityType } from '@/components/warning/warningDefinitions';
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterWarningModel, WarningsProps } from '../warnings.types';

export const getShiftsOtherLocationWarnings = (
  shift: WorkingShift,
  props: WarningsProps
): RosterWarningModel | undefined => {
  const { otherShopStaffShifts: otherShopShifts, strings, myStaffShiftsAtOtherShop } = props;

  const shiftAtOtherShop = otherShopShifts.find(s => s.identityId === shift.assignedEmployeeId);

  if (shiftAtOtherShop) {
    return {
      name: strings.shiftAtOtherLocation.message,
      severity: SeverityType.CRITICAL,
      shiftId: shift.id
    };
  }

  const reassignedShiftAtOtherShop = myStaffShiftsAtOtherShop.find(
    s => s.assignedEmployeeId === shift.assignedEmployeeId
  );

  if (reassignedShiftAtOtherShop) {
    return {
      name: strings.reassignedAtOtherLocation.message,
      severity: SeverityType.CRITICAL,
      shiftId: shift.id
    };
  }

  return undefined;
};
