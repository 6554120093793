import { TextInput } from '@/components/form/TextInput';
import { EditNotesFields, NotesModal } from '@/components/notes/NotesModal';
import { strings } from '@/services/translation/strings';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import React, { Suspense, useState } from 'react';
import { EditButton } from '../buttons/EditButton';

interface NoteFieldProps {
  notes?: string;
  historyNotes?: string;
  emptyNoteString: string;
  subsectionTitle?: string;
  readonly?: boolean;
  submitNotes: (data: EditNotesFields) => Promise<void>;
}

const NotesField: React.FC<NoteFieldProps> = ({
  notes,
  historyNotes,
  emptyNoteString,
  subsectionTitle,
  readonly = false,
  submitNotes
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onClose = () => {
    setModalOpen(false);
  };

  const onChange = (e: any) => {
    // do nothing
  };

  // Check if notes exist and are not empty
  const haveValidNotes = notes && notes.length > 0;

  return (
      <div className='flex items-center'>
        <NotesModal
          open={modalOpen}
          close={onClose}
          submitNotes={submitNotes}
          noteValue={notes}
          emptyNoteString={emptyNoteString}
          subsectionTitle={subsectionTitle}
          subsectionNotes={historyNotes}
          autoEdit={true}
        />

        <div className="flex items-center px-2 text-center font-semibold">
          <div className="text-xs pr-1">{strings.common.notesModalTitle}</div>
          {/* Tooltip component uses icon for trigger whereas i need to use the disabled input as trigger */}
          <RadixTooltip.Provider delayDuration={100}>
            <RadixTooltip.Root>
              <RadixTooltip.Trigger>
                <TextInput
                  className="bg-rose-50 w-[30rem] text-xs border-gray-300"
                  onChange={onChange}
                  readOnly={true}
                  value={notes ?? ''}
                ></TextInput>
              </RadixTooltip.Trigger>

              {haveValidNotes && (
                <RadixTooltip.Portal>
                  <Suspense>
                    <RadixTooltip.Content
                      side="right"
                      className="z-[1000] flow-text break-words bg-white border rounded-sm p-2 w-[350px] text-sm"
                      sideOffset={5}
                    >
                      {notes}
                    </RadixTooltip.Content>
                  </Suspense>
                </RadixTooltip.Portal>
              )}
            </RadixTooltip.Root>
          </RadixTooltip.Provider>
        </div>

        {!readonly && <EditButton onClick={() => setModalOpen(true)} />}
      </div>
  );
};

export default NotesField;
