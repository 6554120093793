import {Button} from '@/components/button';
import {Modal} from '@/components/modal/Modal';
import {strings} from '@/services/translation/strings';
import {FC, ReactElement, useCallback, useEffect, useRef} from 'react';
import {PrintViewTypes, usePrintOptions} from "@/pages/pdf/roster/usePrintOptions";
import {saveAs} from 'file-saver';
import {DocumentProps} from "@react-pdf/types";
import {pdf} from "@react-pdf/renderer";
import {useFlag} from "@softwareimaging/backstage";
import {useNavigate} from "react-router-dom";

interface Props {
  createDocument: () => ReactElement<DocumentProps>;
  fileName: string;
  open: boolean;
  onClose: () => void;
  testRoutePath?: string;
}

export const PdfPrintModal: FC<Props> = ({ createDocument, fileName, open, onClose, testRoutePath }) => {
  const [viewType, setViewType] = usePrintOptions(state => [state.viewType, state.setViewType]);
  const screenRef = useRef<HTMLInputElement>(null);
  const posterRef = useRef<HTMLInputElement>(null);
  const debug = useFlag('debug');
  const navigate = useNavigate();
  const printing = useRef(false);

  useEffect(() => {
    if (open) {
      // The Modal component uses Headless UI to delay the rendering and refs are not ready immediately after the modal opens.
      // Use setTimeout...
      setTimeout(() => {
        // make sure selection and focus match
        if (viewType === PrintViewTypes.SCREEN) screenRef.current?.focus();
        if (viewType === PrintViewTypes.POSTER) posterRef.current?.focus();
      }, 0);
    }
  }, [open, viewType]);

  const onPrint = useCallback(async () => {
    if (!printing.current) {
      printing.current = true;
      const blob = await pdf(createDocument()).toBlob();
      saveAs(blob, fileName);
      printing.current = false;
    }
    onClose();
  }, [createDocument, fileName, onClose]);

  return (
    <Modal onClose={onClose} open={open} title={strings.common.printModal.title}>
      <div className="space-y-4">
        <div className="flex justify-center gap-5 items-center mb-8">
          <input type="radio" ref={screenRef} checked={viewType === PrintViewTypes.SCREEN} onClick={() => setViewType(PrintViewTypes.SCREEN)}/>
          <span className="text-sm">
            {strings.common.printModal.screenView}
          </span>
          <input type="radio" ref={posterRef} checked={viewType === PrintViewTypes.POSTER} onClick={() => setViewType(PrintViewTypes.POSTER)}/>
          <span className="text-sm">
            {strings.common.printModal.posterView}
          </span>
        </div>
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={onClose}>{strings.common.cancel}</Button>
          {debug && testRoutePath && <Button onClick={() => navigate(testRoutePath)}>
            {"Print Test"}
          </Button>}
          <Button onClick={onPrint} variant={'primary'} disabled={printing.current}>
            {strings.common.print}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
