import { SelectInput } from '@/components/form/SelectInput';
import { ConfirmationModal } from '@/components/modal/ConfirmationModal';
import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { strings } from '@/services/translation/strings';
import { FC, useMemo, useState } from 'react';
import { useWorkingRoster } from './providers/WorkingRosterService';
import { useReplaceRoster } from './useReplaceRoster';

export const RosterTemplateSelector: FC = () => {
  const { workingRoster } = useWorkingRoster();
  const replaceRoster = useReplaceRoster();

  const { allTemplateOptions: templateOptions } = useTemplatesForLocation();
  const rosterBaseTemplate = workingRoster?.templateId;

  const selectOptions = useMemo(() => {
    const regularOptions = templateOptions.map(t => ({ name: t.name, value: t.id }));

    if (regularOptions.find(o => o.value === rosterBaseTemplate)) {
      return regularOptions;
    } else {
      return [{ name: strings.daily.roster.selectTemplate, value: rosterBaseTemplate }, ...regularOptions];
    }
  }, [templateOptions, rosterBaseTemplate]);

  const [optionToSet, setOptionToSet] = useState<string | undefined>();

  const onConfirm = () => {
    if (!optionToSet) {
      throw Error('No new template option has been chosen');
    }

    replaceRoster(optionToSet);
    setOptionToSet(undefined);
  };

  if (!workingRoster) {
    return <div></div>;
  } else {
    return (
      <div>
        <SelectInput
          options={selectOptions}
          value={workingRoster.templateId}
          setValue={setOptionToSet}
          readOnly={workingRoster.readOnly}
          className="w-[10rem]"
        />
        <ConfirmationModal
          open={!!optionToSet}
          onClose={() => setOptionToSet(undefined)}
          message={strings.daily.roster.changeTemplateWarning}
          onConfirm={onConfirm}
        />
      </div>
    );
  }
};
