import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useSelectedLocation } from '../settings/LocalSettingsProvider';
import { useDaysInCurrentMonth } from '../settings/useDates';
import { strings } from '../translation/strings';
import { useOptimiserService } from './OptimiserService';
import { useOptimiserEndpoint } from './useOptimiserEndpoint';
import { FiX } from 'react-icons/fi';

export const useOptimiseRosters = () => {
  const days = useDaysInCurrentMonth();
  const { errorJob } = useOptimiserService(state => ({
    errorJob: state.errorJob
  }));

  const { id: locationId } = useSelectedLocation();

  const onError = useCallback(() => {
    toast(strings.common.error, {
      position: 'top-right',
      className: 'text-xl',
      icon: <FiX className="h-8 w-8" />
    });
    errorJob(locationId, days[0], strings.common.error);
  }, [locationId, days]);

  const { optimise, ...others } = useOptimiserEndpoint(onError);

  const optimiseRosters = useCallback(async () => {
    const startDate = days[0];
    const endDate = days[days.length - 1];

    // Minor hack- the optimiser excludes the end date, but the notification format
    // includes it. So we need the optimiser version of the end date to be a day forward.
    const endDateForEndpoint = new Date(endDate);
    endDateForEndpoint.setDate(endDate.getDate() + 1);

    await optimise(startDate, endDateForEndpoint, locationId);
  }, [days, optimise, locationId]);

  return { optimiseRosters, ...others };
};
