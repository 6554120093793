import { PdfPage } from '@/pages/pdf/PdfPage';
import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { strings } from '@/services/translation/strings';
import { Document } from '@react-pdf/renderer';
import { FC, useContext, useMemo } from 'react';
import { PdfStaffSkillsContext, PdfStaffSkillsProvider } from '../PdfStaffSkillsProvider';
import { useGroupStaffSkillSettingsByLocation } from '../useGroupStaffSkillSettingsByLocation';
import { PdfStaffSkillsProps } from '../useStaffSkillsPrintProps';
import PdfStaffSkillOverviewSection from './PdfStaffSkillOverviewSection';

export const ROSTER_NAME_WIDTH = 100;
export const ROSTER_TOTAL_CELL_WIDTH = 40;
export const ROSTER_DETAILS_WIDTH = ROSTER_NAME_WIDTH + ROSTER_TOTAL_CELL_WIDTH * 5;

const PAGE_LENGTH = 20;
const HEADER_LENGTH = 2;

interface Section {
  key: string;
  rows: StaffWithSettings[];
  showHeader: boolean;
}

const PdfStaffSkillOverviewDocumentContent: FC = () => {
  const { staffSettings } = useContext(PdfStaffSkillsContext);

  const printDate = new Date();
  const title = strings.settings.manageSkills.print.staffSkillsOverview;

  const data = useGroupStaffSkillSettingsByLocation(staffSettings);

  const pages: Section[][] = useMemo(() => {
    const pages: Section[][] = [[]];
    let currentPage = pages[0];
    let currentRecordKey: string | null = null;
    let currentSection: Section | null = null;

    Object.entries(data).forEach(([key, values]) => {
      const pageLimit = PAGE_LENGTH;

      // Check if the current page has enough space for the header and at least one item
      const currentPageRemainingSpace =
        pageLimit - currentPage.reduce((sum, section) => sum + section.rows.length + HEADER_LENGTH, 0);

      if (currentPageRemainingSpace < HEADER_LENGTH + 1) {
        currentPage = [];
        pages.push(currentPage);
      }

      // Initialize a new section for the current record key
      if (currentRecordKey !== key) {
        currentRecordKey = key;
        currentSection = { key, rows: [], showHeader: true };
        currentPage.push(currentSection);
      }

      values.forEach(value => {
        if (currentSection) {
          const totalItemsInCurrentPage = currentPage.reduce((count, section) => count + section.rows.length, 0);
          const itemCountIfAdded = totalItemsInCurrentPage + 1; // 1 for the new item

          if (itemCountIfAdded > pageLimit) {
            const sectionShouldShowHeader =
              pages.filter(page => page.some(section => section.key === currentRecordKey)).length === 1;

            currentSection.showHeader = sectionShouldShowHeader;

            currentPage = [];
            pages.push(currentPage);
            currentSection = { key, rows: [value], showHeader: false };
            currentPage.push(currentSection);
          } else {
            currentSection.rows.push(value);
          }
        }
      });
    });

    return pages;
  }, [data]);

  return (
    <Document>
      <PdfPage orientation={'landscape'} headerContent={title} datePrinted={printDate}>
        {pages[0].map((section: Section) => (
          <PdfStaffSkillOverviewSection
            key={section.key}
            locationId={section.key}
            data={section.rows}
            showHeader={section.showHeader}
          />
        ))}
      </PdfPage>
      {pages.slice(1).map((page, index) => (
        <PdfPage key={index} datePrinted={printDate} headerContent={title} orientation={'landscape'}>
          {page.map(section => (
            <PdfStaffSkillOverviewSection
              key={`${section.key}-${index}`}
              locationId={section.key}
              data={section.rows}
              showHeader={section.showHeader}
            />
          ))}
        </PdfPage>
      ))}
    </Document>
  );
};

export const PdfStaffSkillOverviewDocument: FC<PdfStaffSkillsProps> = props => {
  return (
    <PdfStaffSkillsProvider staffSkillPdfProps={props}>
      <PdfStaffSkillOverviewDocumentContent />
    </PdfStaffSkillsProvider>
  );
};
