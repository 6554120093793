import { strings } from '@/services/translation/strings';
import { FC, useRef } from 'react';
import { Button } from '../button';

interface Props {
  file?: File;
  setFile: (newFile: File) => void;
  selectMessage?: string;
  fileExtension?: string;
}

export const FileInput: FC<Props> = ({ file, setFile, selectMessage, fileExtension }) => {
  const hiddenInput = useRef(null);

  const onButtonClick = () => {
    // @ts-ignore
    hiddenInput.current.click();
  };

  const handleSetFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      // clear the value of the input element by setting empty file name, "".
      // This allows the input element to fire a change event even if the same file is selected again.
    }

    event.target.value = '';
  };

  return (
    <div className="flex gap-2 items-center">
      <Button onClick={onButtonClick}>{strings.common.file.selectFile}</Button>
      <div>{file?.name || selectMessage || strings.common.file.pleaseSelect}</div>
      <input
        hidden
        ref={hiddenInput}
        type="file"
        accept={fileExtension ? fileExtension : '.xlsx'}
        onChange={e => {
          handleSetFile(e);
        }}
      />
    </div>
  );
};
