import { FC } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { AvailabilityTable } from './AvailabilityTable';
import { LocationSelector } from '@/pages/layout/main/LocationSelector';
import { MonthSelector } from '@/pages/layout/MonthSelector';
import DownloadAvailabilityButton from "@/pages/pdf/availability/DownloadAvailabilityButton";
import {useDaysInCurrentMonth} from "@/services/settings/useDates";
import {useStaffAvailability} from "@/services/availability/useStaffAvailability";
import {WorkPreferencesTrackingProvider} from "@/services/availability/WorkPreferencesTrackingService";

export const AvailabilitySettings: FC = () => {
  const days = useDaysInCurrentMonth();
  const availabilityForMonth = useStaffAvailability(days);

  return (
    <>
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex gap-2 items-center">
          <LocationSelector/>
          <MonthSelector showNavigationArrows={true}/>
          <div id="spaceFiller" className="flex-1"/>
          <DownloadAvailabilityButton days={days} availabilityForMonth={availabilityForMonth}/>
        </div>
      </SettingsSectionHeader>
      <WorkPreferencesTrackingProvider startDate={days[0]} endDate={days[days.length - 1]}>
        <AvailabilityTable days={days} availabilityForMonth={availabilityForMonth} />
      </WorkPreferencesTrackingProvider>
    </>
  );
};
