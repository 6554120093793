import { WorkingShift } from "@/pages/daily/daily.types";
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { SearchAvailabilities, useSearchAvailabilitiesQuery, Location } from '@/services/gql/graphql.generated';
import { useMemo } from 'react';

export interface AvailabilityItem {
  start: number;
  end: number;
  employeeId: string;
  firstName: string;
  lastName: string;
  kanjiFirstName: string;
  kanjiLastName: string;
  role: string;
}

interface LocationAvailabilities {
  location: Location;
  availabilities: AvailabilityItem[];
}

export const useAvailabilities = (shift: WorkingShift): LocationAvailabilities[] => {
  const targetLocation = useSelectedLocation();

  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));

  const search: SearchAvailabilities = {
    targetLocationIds: [targetLocation.id],
    day: selectedDate,
    start: shift.start,
    end: shift.end
  };
  const [{ data }] = useSearchAvailabilitiesQuery({ variables: { search } });
  const availabilities = data?.searchAvailabilities as LocationAvailabilities[];

  if (!availabilities) {
    throw Error('Could not get availabilities from other locations list');
  }

  return useMemo(() => {
    // filter by location id, so we will not get the current location suggestions
    let filteredOptions = availabilities.filter(
      (sa: LocationAvailabilities) => sa.location.id !== targetLocation.id
    );

    // filter by role
    filteredOptions = [...filteredOptions];
    filteredOptions.forEach(o => {
      o.availabilities = o.availabilities.filter(a => a.role === 'Staff');
    });

    // filter by supervisor
    filteredOptions = filteredOptions.filter(
      (locationToCheck: LocationAvailabilities) => {
        if (!locationToCheck.location.properties || !targetLocation.properties) {
          return false;
        }

        const propsToCheck = JSON.parse(locationToCheck.location.properties);
        const propsTarget = JSON.parse(targetLocation.properties);

        return propsTarget.supervisorId === propsToCheck.supervisorId
      }
    )

    // filter the options to get those with provided availabilities only
    filteredOptions = filteredOptions.filter(
      (o: LocationAvailabilities) => o.availabilities?.length
    );

    return filteredOptions;
  }, [availabilities]);
};
