import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { MarkedButton } from '@/components/buttons/MarkedButton';
import { TextInputField } from '@/components/form/TextInputField';
import { strings } from '@/services/translation/strings';
import React, { FC, useState } from 'react';
import { SettingsTableCell } from '../../layout/SettingsTableCell';

export enum FilterOptions {
  ACTIVE = 'active',
  ALL = 'all',
  INACTIVE = 'inactive'
}

export interface Announcement {
  id: string;
  title: string;
  text: string;
  isActive: boolean;
  displayDate?: Date;
  showAtDate?: Date;
  // createdAt?: Date;
  // updatedAt?: Date;
}

interface AnnouncementProps {
  announcement: Announcement;
  index: number;
  showIndexColumn?: boolean;
  deleteAnnouncementEnabled?: boolean;
  isToBeDeleted?: boolean;
  onAnnouncementUpdate: (announcement: Announcement) => void;
  onAnnouncementDelete: (announcementId: string, isToBeDeleted: boolean) => void;
}

export const Announcement: FC<AnnouncementProps> = ({
  announcement,
  index,
  showIndexColumn,
  deleteAnnouncementEnabled,
  isToBeDeleted,
  onAnnouncementUpdate,
  onAnnouncementDelete
}) => {
  const [workingAnnouncement, setWorkingAnnouncement] = useState<Announcement>(announcement);

  const onChange = (propName: string, newValue: any) => {
    const newAnnouncement = { ...workingAnnouncement } as any;
    const keys = Object.keys(workingAnnouncement);
    const foundKey = keys?.find(k => k === propName);
    if (foundKey) {
      newAnnouncement[foundKey] = newValue;
    }

    setWorkingAnnouncement(newAnnouncement);
    onAnnouncementUpdate(newAnnouncement);
  };

  return (
    <React.Fragment key={workingAnnouncement.id}>
      {showIndexColumn && <SettingsTableCell indexCell>{index + 1}</SettingsTableCell>}
      <SettingsTableCell className="justify-center">
        <Toggle value={workingAnnouncement.isActive} onChange={e => onChange('isActive', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center">
        <TextInputField value={workingAnnouncement.title} setValue={e => onChange('title', e)}></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center">
        <TextInputField value={workingAnnouncement.text} setValue={e => onChange('text', e)}></TextInputField>
      </SettingsTableCell>
      {/* TODO: for future use!!! */}
      {/* <SettingsTableCell className="px-2 justify-center">
        <TextInputField
          value={workingAnnouncement.createdAt ? format(workingAnnouncement.createdAt, 'yyyy-MM-dd HH:mm') : '-'}
          setValue={e => {}}
          readOnly
        ></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center">
        <TextInputField
          value={workingAnnouncement.updatedAt ? format(workingAnnouncement.updatedAt, 'yyyy-MM-dd HH:mm') : '-'}
          setValue={e => {}}
          readOnly
        ></TextInputField>
      </SettingsTableCell> */}
      {/* <SettingsTableCell className="px-2 justify-center">
        <DateInput
          value={workingAnnouncement.displayDate || new Date()}
          setValue={(newValue?: Date) => {
            if (newValue) {
              onChange('displayDate', newValue);
            } else {
              console.log('Display date value undefined.');
            }
          }}
        />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center">
        <DateInput
          value={workingAnnouncement.showAtDate || new Date()}
          setValue={(newValue?: Date) => {
            if (newValue) {
              onChange('showAtDate', newValue);
            } else {
              console.log('Show at date value undefined.');
            }
          }}
        />
      </SettingsTableCell> */}
      {deleteAnnouncementEnabled && (
        <SettingsTableCell>
          {!isToBeDeleted && (
            <Button
              size="sm"
              variant="destructive"
              onClick={() => onAnnouncementDelete(announcement.id, true)}
              className="w-20"
            >
              {strings.common.delete}
            </Button>
          )}
          {isToBeDeleted && (
            <MarkedButton
              label={strings.common.toBeDeleted}
              mainClass="text-[#c23934]"
              buttonClass="border-[#c23934] hover:bg-[#c2393420]"
              onButtonClick={() => onAnnouncementDelete(announcement.id, false)}
            />
          )}
        </SettingsTableCell>
      )}
    </React.Fragment>
  );
};
