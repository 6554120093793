import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { FC, useMemo } from 'react';
import {
  StaffSettingsFilterOptions,
  useFilterStaffSettings
} from '../../../../../services/settings/useFilterStaffSettings';
import { useEditSettingsService } from '../../../services/EditSettingsService';
import { Header } from '../../manageStaff/staffTable/Header';
import { TableColumnGroup } from '../../manageStaff/staffTable/StaffTable';
import { StaffSkilsRow } from './StaffRow';

interface Props {
  groups: TableColumnGroup[];
  showIndexColumn?: boolean;
  onChangeSetting: (id: string) => (key: string) => (newVal: any) => void;
  filterOption: StaffSettingsFilterOptions;
}

export const STAFF_SKILL_TABLE_INDEX_COLUMN_WIDTH = 70;

export const StaffSkillTable: FC<Props> = ({ groups, showIndexColumn, onChangeSetting, filterOption }) => {
  const selectedLocationId = useSelectedLocation()?.id;
  const { settings } = useEditSettingsService(state => ({
    settings: state.workingStaffSettings.filter(s => !s.settings.hasBeenDeleted)
  }));

  const homeStaffSettings = settings.filter(s => s.employee.homeLocationId === selectedLocationId!);

  const gridTemplate = useMemo(() => {
    // Template starts with the number column.
    let gridTemplate = `${showIndexColumn ? STAFF_SKILL_TABLE_INDEX_COLUMN_WIDTH + 'px ' : ''}`;

    groups.forEach(group => {
      group.columns.forEach(col => {
        if (!col.isColumnHidden) {
          gridTemplate += ` ${col.width}px`;
        }
      });
    });

    return gridTemplate;
  }, [groups]);

  const filteredSettings = useFilterStaffSettings(homeStaffSettings, filterOption);

  return (
    <div className="grid overflow-auto" style={{ gridTemplateColumns: gridTemplate }}>
      <Header groups={groups} indexColumnWidth={STAFF_SKILL_TABLE_INDEX_COLUMN_WIDTH} indexColumnClassName="px-2" />
      {filteredSettings.map(staff => (
        <StaffSkilsRow
          staff={staff}
          key={staff.employee.identityId}
          onChangeSetting={onChangeSetting(staff.employee.identityId)}
          groups={groups}
        />
      ))}
    </div>
  );
};
