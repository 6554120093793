import { Button } from '@/components/button';
import { AddButton } from '@/components/buttons/AddButton';
import { FilterOption, RadioFilterSelector } from '@/components/flterSelector/RadioFilterSelector';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SkillType } from '../../../../services/tasks/skillSettings.types';
import { useCreateSkills, useDeleteSkills, useSkills, useUpdateSkills } from '../../../../services/tasks/useSkills';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { SettingsTable, TableColumn } from '../../layout/SettingsTable';
import { FilterOptions, Skill } from './Skill';
import SkillSettingErrorModal from './SkillSettingErrorModal';
import { useValidateSkillsInUse } from './useValidateSkillsInUse';

export const SkillsSettings: FC = () => {
  const fetchedSkills = useSkills();
  const [skills, setSkills] = useState<SkillType[]>(fetchedSkills);
  const [skillsToAdd, setSkillsToAdd] = useState<SkillType[]>([]);
  const [skillsToUpdate, setSkillsToUpdate] = useState<SkillType[]>([]);
  const [skillIdsToDelete, setSkillsIdsToDelete] = useState<string[]>([]);
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOptions>(FilterOptions.ALL);
  const { create } = useCreateSkills();
  const { update } = useUpdateSkills();
  const { deleteSkills } = useDeleteSkills();

  const deleteSkillEnabled = useFlag('deleteSkills');

  useEffect(() => {
    setSkills(fetchedSkills);
  }, [fetchedSkills]);

  const addSkill = useCallback(() => {
    let newSkillName = `${strings.settings.manageSkills.skill} ${skills.length + 1}`;
    const similarNameSkills = skills.filter(s => s.name === newSkillName);
    if (similarNameSkills.length) {
      newSkillName += `-${similarNameSkills.length}`;
    }

    const newSkill: SkillType = {
      id: `CREATE-${uuidv4()}`,
      name: newSkillName,
      tooltip: '',
      isActive: true
    };

    const newSkillsToAdd = [...skillsToAdd, newSkill];
    setSkillsToAdd(newSkillsToAdd);

    const allSkills = [...skills, newSkill];
    setSkills(allSkills);
  }, [skills]);

  const onSkillUpdate = (skill: SkillType) => {
    if (!skill.id.startsWith('CREATE')) {
      const skillsToBeUpdated = [...skillsToUpdate];
      const skillIndex = skillsToBeUpdated.findIndex(s => s.id === skill.id);

      if (skillIndex !== -1) {
        skillsToBeUpdated[skillIndex] = skill;
      } else {
        skillsToBeUpdated.push(skill);
      }

      setSkillsToUpdate(skillsToBeUpdated);
    } else {
      const skillsToBeAdded = [...skillsToAdd];
      const skillIndex = skillsToBeAdded.findIndex(s => s.id === skill.id);
      if (skillIndex !== -1) {
        skillsToBeAdded[skillIndex] = skill;
        setSkillsToAdd(skillsToBeAdded);
      }
    }

    // Need to also update the main skills array.
    const newSkills = [...skills];
    const skillIndex = newSkills.findIndex(s => s.id === skill.id);

    if (skillIndex === -1) {
      throw Error('Skill not found in skills array');
    }

    newSkills[skillIndex] = skill;
    setSkills(newSkills);
  };

  const onSkillDelete = (skillId: string, isToBeDeleted: boolean) => {
    const skillIdsToBeDeleted = [...skillIdsToDelete];
    const deleteSkillIndex = skillIdsToBeDeleted.indexOf(skillId);

    if (deleteSkillIndex === -1 && isToBeDeleted) {
      skillIdsToBeDeleted.push(skillId);
    } else if (deleteSkillIndex >= 0 && !isToBeDeleted) {
      skillIdsToBeDeleted.splice(deleteSkillIndex, 1);
    }

    setSkillsIdsToDelete(skillIdsToBeDeleted);
  };

  const onFilterChange = (value: any) => {
    let type = selectedFilterOption;

    if (value !== selectedFilterOption) {
      type = value;
    }

    setSelectedFilterOption(type);
  };

  const [skillSettingErrorModalOpen, setSkillSettingErrorModalOpen] = useState(false);
  const [skillSettingModalErrors, setSkillSettingModalErrors] = useState<string[]>([]);

  const validateInactiveSkills = useValidateSkillsInUse();

  const onSave = async () => {
    const approvedForUpdate = skillsToUpdate.filter(s => !skillIdsToDelete.includes(s.id));
    const approvedForAdd = skillsToAdd.filter(s => !skillIdsToDelete.includes(s.id));

    const errors = validateInactiveSkills(approvedForUpdate.filter(s => !s.isActive));
    if (errors.length) {
      setSkillSettingModalErrors(errors);
      setSkillSettingErrorModalOpen(true);
      return;
    }

    if (skillIdsToDelete.length) {
      setSkillsToAdd(skillsToAdd.filter(s => !skillIdsToDelete.includes(s.id)));
      const approvedForDelete = skillIdsToDelete.filter(s => !s.startsWith('CREATE'));

      if (!approvedForDelete.length) {
        setSkills(skills.filter(s => !skillIdsToDelete.includes(s.id)));
        setSkillsIdsToDelete([]);
      }

      try {
        if (approvedForDelete.length) {
          const deleteResult = await deleteSkills(approvedForDelete);
          setSkillsIdsToDelete([]);

          if (!deleteResult.error) {
            setSkills(skills.filter(s => !skillIdsToDelete.includes(s.id)));
          }
        }
      } catch (error) {
        console.log('Delete skills error', error);
      }
    }

    if (approvedForUpdate.length) {
      try {
        await update(approvedForUpdate);
        setSkillsToUpdate([]);
      } catch (error) {
        console.log('Update skills error', error);
      }
    }

    if (approvedForAdd.length) {
      try {
        await create(approvedForAdd);
        setSkillsToAdd([]);
      } catch (error) {
        console.log('Add skills error', error);
      }
    }
  };

  const filteredSkills = useMemo(() => {
    switch (selectedFilterOption) {
      case FilterOptions.ACTIVE:
        return skills.filter(s => s.isActive);
      case FilterOptions.INACTIVE:
        return skills.filter(s => !s.isActive);

      default:
        return skills;
    }
  }, [skills, selectedFilterOption]);

  const columns: TableColumn[] = useMemo(() => {
    const cols = [
      {
        name: strings.settings.manageSkills.activeInactive,
        width: 160
      },
      {
        name: strings.settings.manageSkills.skillName,
        width: 'auto'
      },
      {
        name: strings.settings.manageSkills.tooltip,
        width: 'auto'
      }
    ];

    if (deleteSkillEnabled) {
      cols.push({
        name: '',
        width: 100
      });
    }

    return cols;
  }, []);

  const filterOptions: FilterOption[] = useMemo(() => {
    return [
      {
        displayName: strings.settings.filter.displayActive,
        value: FilterOptions.ACTIVE
      },
      {
        displayName: strings.settings.filter.displayAll,
        value: FilterOptions.ALL
      },
      {
        displayName: strings.settings.filter.displayInactive,
        value: FilterOptions.INACTIVE
      }
    ];
  }, []);

  return (
    <>
      <SkillSettingErrorModal
        errors={skillSettingModalErrors}
        open={skillSettingErrorModalOpen}
        onClose={() => setSkillSettingErrorModalOpen(false)}
      />
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <AddButton label={strings.settings.manageSkills.skill} onButtonClick={addSkill} />
            <RadioFilterSelector
              options={filterOptions}
              selectedOption={selectedFilterOption}
              onSelect={onFilterChange}
            />
          </div>
          <Button
            className="bg-[#1BB55C] text-[white] hover:text-[white] hover:bg-[#1BB55C]"
            onClick={onSave}
            variant={skillsToUpdate.length || skillIdsToDelete.length || skillsToAdd.length ? 'submit' : undefined}
            size="sm"
            disabled={skillsToUpdate.length || skillIdsToDelete.length || skillsToAdd.length ? undefined : true}
          >
            {strings.common.save}
          </Button>
        </div>
      </SettingsSectionHeader>
      <SettingsTable columns={columns}>
        {filteredSkills.length > 0 &&
          filteredSkills.map((skill, index) => (
            <Skill
              key={skill.id}
              skill={skill}
              index={index}
              showIndexColumn={false}
              deleteSkillEnabled={deleteSkillEnabled}
              isToBeDeleted={skillIdsToDelete.includes(skill.id)}
              onSkillUpdate={onSkillUpdate}
              onSkillDelete={onSkillDelete}
            />
          ))}

        {!filteredSkills.length && (
          <div className="flex items-center justify-center col-span-full p-10 text-xl text-[#32323280] font-medium">
            {strings.settings.manageSkills.emptySkillsList}
          </div>
        )}
      </SettingsTable>
    </>
  );
};
