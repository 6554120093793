import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

//CustomSettingsTableHeaderCell
export const HeaderCell: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, style }) => {
  return (
    <div
      className={twMerge('bg-background-mid border border-border px-5 py-2 font-semibold text-xs', className)}
      style={style}
    >
      {children}
    </div>
  );
};
