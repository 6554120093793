import { CSSProperties, FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  description?: string;
  children: ReactNode;
  className?: string;
  descriptionStyle?: CSSProperties;
}

export const Setting: FC<Props> = ({ title, description, children, className, descriptionStyle }) => {
  return (
    <div className="border-b px-10 flex py-2">
      <div className="flex-1">
        <div className="font-bold">{title}</div>
        {description && (
          <div className="text-sm" style={descriptionStyle}>
            {description}
          </div>
        )}
      </div>
      <div className={twMerge(className, 'flex-1 flex items-center')}> {children}</div>
    </div>
  );
};
