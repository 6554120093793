import { FoundShiftsInDateRange } from '@/services/gql/graphql.generated';
import { TaskType } from '../tasks/task.types';
import { SummaryDay, SummaryEmployeeShift } from './summary.types';
import { calculateShiftTime } from '../rosters/rosterHelper';

/*
 * Why this is needed:
 *
 * The reassigned shift endpoint returns data slightly differently to the ones
 * used by the regular summary.
 *
 * As a result, to have a summary that includes both shifts worked at the home location
 * and shifts worked off site, we need to add the off site shifts separately, and re-sort
 * the shift arrays.
 */
export const applyReassignedDataToSummary =
  (
    reassignedShiftDataInRange: FoundShiftsInDateRange[],
    tasks: TaskType[],
    selectedLocationId: number,
    includeBreaks?: boolean
  ) =>
  (schedule: SummaryDay[], identityId: string): SummaryDay[] => {
    const reassignedSchedule: SummaryDay[] = [];
    reassignedShiftDataInRange.forEach(location => {
      location.shifts
        ?.filter(s => s.employee?.identityId === identityId)
        .forEach(shift => {
          let summaryDay = schedule.find(s => s.date === shift.date);
          const { breakTime, basicTime, actualTime, uncountedTaskTime } = calculateShiftTime(
            shift,
            tasks,
            includeBreaks
          );

          const reassignedShift: SummaryEmployeeShift = {
            locationId: location.locationId.toString(),
            locationName: location.locationName,
            start: shift.start,
            end: shift.end,
            breakLength: breakTime,
            uncountedTaskLength: uncountedTaskTime,
            basicTime: basicTime,
            actualTime: actualTime
          };

          if (summaryDay) {
            summaryDay.shifts.push(reassignedShift);
          } else {
            summaryDay = reassignedSchedule.find(rs => rs.date === shift.date);
            if (summaryDay) {
              summaryDay.shifts.push(reassignedShift);
            } else {
              summaryDay = {
                date: shift.date,
                shifts: [reassignedShift],
                availableTimes: [],
                basicTime: 0,
                actualTime: 0,
                breakTime: 0
              };
              reassignedSchedule.push(summaryDay);
            }
          }
          summaryDay.basicTime += reassignedShift.basicTime;
          summaryDay.actualTime += reassignedShift.actualTime;
          summaryDay.breakTime += reassignedShift.breakLength;
        });
    });

    if (reassignedSchedule.length) {
      schedule = schedule.concat(reassignedSchedule);
    }

    // sort summaries by date
    schedule.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    // sort shifts by time
    schedule.forEach(day => {
      day.shifts.sort((a, b) => a.start - b.start);
      day.os = !!day.shifts.find(s => s.locationId !== selectedLocationId.toString());
    });

    return schedule;
  };
