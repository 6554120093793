import DownloadReportButton from '@/pages/pdf/DownloadReportButton';
import { StaffSettingsFilterOptions } from '@/services/settings/useFilterStaffSettings';
import { strings } from '@/services/translation/strings';
import { FunctionComponent, useCallback } from 'react';
import { usePdfStaffSkillsProps } from '../useStaffSkillsPrintProps';
import { PdfStaffSkillCapabilityDocument } from './PdfStaffSkillCapabilityDocument';

interface PdfStaffSkillDownloadButtonProps {
  filterOption: StaffSettingsFilterOptions;
}

const PdfStaffSkillCapabilityDownloadButton: FunctionComponent<PdfStaffSkillDownloadButtonProps> = ({
  filterOption
}) => {
  const printProps = usePdfStaffSkillsProps(filterOption);

  const createDocument = useCallback(() => {
    return <PdfStaffSkillCapabilityDocument {...printProps} />;
  }, [printProps]);

  const fileName = 'staff-skills-capability';

  return (
    <DownloadReportButton
      name={fileName}
      createDocument={createDocument}
      buttonName={strings.settings.manageSkills.print.printBySkill} />
  );
};

export default PdfStaffSkillCapabilityDownloadButton;
