import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends PropsWithChildren {
  className?: string;
}

export const InfoCell: FC<Props> = ({ children, className }) => {
  return (
    <div className={twMerge(className, 'flex-1 flex items-center flex-col leading-tight justify-center text-center')}>
      {children}
    </div>
  );
};
