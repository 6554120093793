import { FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';
import { TableColumn } from './Table';

interface Props {
  header: TableColumn;
}

const TableHeaderCell: FunctionComponent<Props> = ({ header }) => {
  return (
    <div className={twMerge('bg-background-mid border border-border px-5 py-2 font-semibold text-xs')}>
      {header.text}
    </div>
  );
};

export default TableHeaderCell;
