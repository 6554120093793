import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadRoster, useGetRosterStaffQuery } from '@/services/gql/graphql.generated';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { WorkingRoster } from '../roster.types';
import { useGqlRosterToWorkingRoster } from '../rosterHelper';
import { useSelectedRoster } from './RosterOptionsService';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { buildSummaryDayStats, SummaryDayStats } from '@/services/summary/buildSummaryDayStats';
import { useBuildRosterSummary } from '@/services/rosters/useBuildRosterSummary';

export interface WorkingRosterStore {
  workingRoster?: WorkingRoster;
  setWorkingRoster: (newRoster?: WorkingRoster) => void;
  publishWorkingRoster: () => void;
  summary: SummaryEmployee[];
  rosterStats: SummaryDayStats;
}

export const WorkingRosterContext = createContext<StoreApi<WorkingRosterStore> | null>(null);

export const WorkingRosterProvider: FC<PropsWithChildren> = ({ children }) => {
  const selectedLocationId = useSelectedLocation().id;
  const gqlRosterToWorkingRoster = useGqlRosterToWorkingRoster();

  const { registerForConfirmation } = useConfirm(state => ({
    registerForConfirmation: state.registerForConfirmation
  }));

  const roster = useSelectedRoster();
  const buildSummary = useBuildRosterSummary();

  const date = useLocalSettings(s => s.selectedDate);

  const store = useMemo(() => {
    const workingRoster = roster ? gqlRosterToWorkingRoster(roster as ReadRoster) : undefined;
    const summary = workingRoster ? buildSummary(workingRoster.shifts) : [];

    return createStore<WorkingRosterStore>()((set, get) => ({
      workingRoster,
      summary: workingRoster ? buildSummary(workingRoster.shifts) : [],
      rosterStats: buildSummaryDayStats(summary, [new Date(date)])[0],
      setWorkingRoster: (newRoster?: WorkingRoster) => {
        registerForConfirmation(strings.daily.roster.unsaved);

        /*
         * Rebuild the summary and stats based on the new roster.
         */
        const newSummary = newRoster ? buildSummary(newRoster.shifts) : [];
        set({
          workingRoster: newRoster,
          summary: newSummary,
          rosterStats: buildSummaryDayStats(newSummary, [new Date(date)])[0]
        });
      },
      publishWorkingRoster: () => {
        const workingRoster = get().workingRoster;

        if (!workingRoster) {
          throw Error('No working roster is available to publish');
        }
        const publishedWorkingRoster: WorkingRoster = { ...workingRoster, published: true };
        set({ workingRoster: publishedWorkingRoster });
      }
    }));
  }, [selectedLocationId, roster, buildSummary, gqlRosterToWorkingRoster, registerForConfirmation]);

  return <WorkingRosterContext.Provider value={store}>{children}</WorkingRosterContext.Provider>;
};

type WorkingSelectorReturn<S extends (s: WorkingRosterStore) => any> = ReturnType<S>;
export function useWorkingRosterService<S extends (s: WorkingRosterStore) => any>(
  selector: S
): WorkingSelectorReturn<S> {
  const context = useContext(WorkingRosterContext);
  if (!context) {
    throw new Error('useWorkingRosterService must be used within a WorkingRosterProvider');
  }
  return useStore(context, selector);
}

export const useWorkingRoster = () => {
  const { setWorkingRoster, workingRoster } = useWorkingRosterService(state => ({
    setWorkingRoster: state.setWorkingRoster,
    workingRoster: state.workingRoster
  }));

  return { workingRoster, setWorkingRoster };
};

export const useRosterHomeEmployeesSummary = () => {
  const summary = useWorkingRosterService(s => s.summary);

  return useMemo(() => summary.filter(s => s.homeEmployee), [summary]);
};

export const useRosterAllEmployeesSummary = () => {
  return useWorkingRosterService(s => s.summary);
};

export const useRosterEmployeeSummary = (employeeId?: string) => {
  const summary = useRosterAllEmployeesSummary();

  return employeeId ? summary.find(s => s.id === employeeId) : undefined;
};

export const useRosterStats = () => {
  return useWorkingRosterService(s => s.rosterStats);
};

export const useRosterEmployeeCurrentDaySummary = (employeeId?: string) => {
  const employeeSummary = useRosterEmployeeSummary(employeeId);
  const selectedDate = useLocalSettings(s => s.selectedDate);

  return employeeSummary?.schedule.find(s => s.date === selectedDate);
};

export const useRosterableStaff = (selectedId?: string, contractTypeId?: string) => {
  const { workingRoster } = useWorkingRosterService(state => ({
    workingRoster: state.workingRoster
  }));

  const [{ data: rosterStaffData }] = useGetRosterStaffQuery({ variables: { id: workingRoster?.id } });
  const rosterStaff = rosterStaffData?.rosterStaff?.staffList;

  if (!rosterStaff) {
    throw Error('Could not get roster staff');
  }

  const rosterableStaff = useMemo(() => {
    const staffOptions = rosterStaff.map(staff => ({
      name: displayName(staff),
      id: staff.employeeId,
      contractId: staff.contractTypeId
    }));
    const filteredOptions = staffOptions.filter(option => {
      if (selectedId === option.id) {
        return true;
      }

      if (contractTypeId) {
        if (option.contractId !== contractTypeId) {
          return false;
        }
      }

      let staffInRoster = false;
      workingRoster?.shifts.forEach(shift => {
        if (shift.assignedEmployeeId === option.id) {
          staffInRoster = true;
        }
      });

      return !staffInRoster;
    });

    filteredOptions.splice(0, 0, { id: '', name: strings.common.select, contractId: '' });
    return filteredOptions;
  }, [workingRoster, rosterStaff, contractTypeId, selectedId]);

  return rosterableStaff;
};

export const useUnassignedEmployeesWithGhostShifts = () => {
  const summary = useRosterHomeEmployeesSummary();
  const workingRoster = useWorkingRosterService(s => s.workingRoster);

  if (!workingRoster) {
    throw Error('Working roster should be defined');
  }

  return useMemo(() => {
    const nonScheduledEmployees = summary.filter(s => {
      return !workingRoster.shifts.find(shift => s.id === shift.assignedEmployeeId);
    });

    const withGhostShifts = nonScheduledEmployees.filter(s => {
      if (s.schedule.length === 0) {
        return false;
      }

      if (!s.schedule[0].ghostShifts || s.schedule[0].ghostShifts.length === 0) {
        return false;
      }

      const ghostShifts = s.schedule[0].ghostShifts;

      const currentDayGhost = ghostShifts.find(g => {
        const yesterdayShift = workingRoster.startTime <= g.end && g.end <= workingRoster.endTime;
        const tomorrowShift = workingRoster.startTime <= g.start && g.start <= workingRoster.endTime;

        return yesterdayShift || tomorrowShift;
      });

      return !!currentDayGhost;
    });

    return withGhostShifts;
  }, [workingRoster, summary]);
};
