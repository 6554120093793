import { secondsToTimeString } from '@/helpers/dateHelper';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC, useContext } from 'react';
import { deltaToColor } from '../deltaToColor';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { PdfRosterContext } from './PdfRosterProvider';

interface BudgetField {
  label: string;
  value?: number;
  time?: boolean;
  delta?: boolean;
}

const BigBudgetValue: FC<BudgetField> = ({ label, value, time }) => {
  let valueToPrint: string;
  if (time) {
    valueToPrint = value !== undefined ? secondsToTimeString(value) : '--:--';
  } else {
    valueToPrint = value !== undefined ? value.toString() : '-';
  }

  return (
    <View style={{ alignItems: 'flex-end' }}>
      <Text style={{ fontSize: 10 }}>{label}</Text>
      <Text style={{ fontSize: 14 }}>{valueToPrint}</Text>
    </View>
  );
};

const SmallBudgetValue: FC<BudgetField> = ({ label, value, time, delta }) => {
  let valueToPrint: string;
  if (time) {
    valueToPrint = value !== undefined ? secondsToTimeString(value, delta) : '--:--';
  } else {
    valueToPrint = value !== undefined ? value.toString() : '-';
  }

  const valueColor = delta ? deltaToColor(value) : undefined;

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
      <Text style={{ fontSize: 8 }}>{`${label}:`}</Text>
      <Text style={{ fontSize: 8, color: valueColor }}>{valueToPrint}</Text>
    </View>
  );
};

const SmallBudgetValueSection: FC<{ fields: BudgetField[] }> = ({ fields }) => {
  return (
    <View style={{ alignItems: 'flex-end' }}>
      {fields.map(field => (
        <SmallBudgetValue key={field.label} {...field} />
      ))}
    </View>
  );
};

export const PdfRosterBudgetSummary: FC = () => {
  const {
    workingRoster,
    budgetDay,
    budgetLocation,
    budgetStats: { totalLocationForecast, totalRostered, monthlyDelta, rosteredBudget }
  } = useContext(PdfRosterContext);

  if (!workingRoster) {
    throw Error('Working roster should be defined');
  }

  const hqHours = budgetLocation.hqAllocatedMonthlyTime;

  const monthlyValues: BudgetField[] = [
    {
      label: strings.daily.roster.budgetStats.monthlyLocationForecast,
      value: totalLocationForecast,
      time: true
    },
    {
      label: strings.daily.roster.budgetStats.totalRostered,
      value: totalRostered,
      time: true
    },
    {
      label: strings.daily.roster.budgetStats.monthlyDelta,
      value: monthlyDelta,
      time: true,
      delta: true
    }
  ];

  const dailyValues: BudgetField[] = [
    {
      label: strings.daily.roster.budgetStats.locationForecast,
      value: budgetDay.locationForecast,
      time: true
    },
    {
      label: strings.daily.roster.budgetStats.rosteredBudget,
      value: rosteredBudget,
      time: true
    },
    {
      label: strings.daily.roster.budgetStats.deltaTime,
      value: rosteredBudget - budgetDay.locationForecast,
      time: true,
      delta: true
    }
  ];

  return (
    <View style={styles.tableRow}>
      <PdfTableCell lastCell>
        <View
          style={{
            flexDirection: 'row',
            display: 'flex',
            paddingVertical: 5,
            paddingHorizontal: 20,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              gap: 20
            }}
          >
            <BigBudgetValue label={strings.daily.roster.budgetStats.totalHqAllocatedBudget} value={hqHours} time />
            <SmallBudgetValueSection fields={monthlyValues} />
          </View>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              gap: 20
            }}
          >
            <BigBudgetValue
              label={strings.daily.roster.budgetStats.monthlySales}
              value={budgetLocation.hqAllocatedMonthlyBudget}
            />
            <BigBudgetValue label={strings.daily.roster.budgetStats.dailySales} value={budgetDay.financialTarget} />
            <SmallBudgetValueSection fields={dailyValues} />
          </View>
        </View>
      </PdfTableCell>
    </View>
  );
};
