import { secondsToTimeString } from '@/helpers/dateHelper';
import { hoursToSeconds } from 'date-fns';
import { useState } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  value: number;
  setValue: (newValue: number) => void;
  className?: string;
  dataTabId?: string;
  style?: any;
  maxValue?: number;
  readonly?: boolean;
  minuteIncrement?: number;
}

export const DurationInput = React.forwardRef<HTMLInputElement, Props>(
  ({ value, setValue, className, dataTabId, style, maxValue, readonly, minuteIncrement = 5 }, ref) => {
    const [inputValue, setInputValue] = useState<string | undefined>(undefined);

    const timePattern = /^[0-9]+[:.]([0-5]|[0-5][0-9])?$/;
    const numberPattern = /^[0-9]+$/;

    const onChange = (newVal: string) => {
      if (newVal !== '' && !timePattern.test(newVal) && !numberPattern.test(newVal)) {
        return;
      }

      setInputValue(newVal);
    };

    const onFocus = () => {
      setInputValue('');
    };

    const onBlur = () => {
      if (inputValue === undefined) {
        return;
      }

      let valueToSet: number | undefined = undefined;
      if (timePattern.test(inputValue)) {
        const split = inputValue.replaceAll('.', ':').split(':');
        const hours = parseInt(split[0]);
        const parsedMinutes = parseInt(split[1].padEnd(2, '0'));
        const actualMinutes = Math.round(parsedMinutes / minuteIncrement) * minuteIncrement;

        valueToSet = hours * 60 * 60 + actualMinutes * 60;
      } else if (numberPattern.test(inputValue)) {
        valueToSet = parseInt(inputValue) * 60 * 60;
      }

      if (valueToSet !== undefined && 0 <= valueToSet) {
        if (maxValue === undefined || valueToSet <= maxValue) {
          setValue(valueToSet);
        }
      }
      
      setInputValue(undefined);
    };

    return (
      <input
        ref={ref}
        data-tab-id={dataTabId}
        className={twMerge('p-0 text-center rounded-md border-border', className)}
        value={inputValue !== undefined ? inputValue : secondsToTimeString(value)}
        onChange={e => onChange(e.target.value)}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        style={style}
        disabled={readonly}
      ></input>
    );
  }
);
