import { styles } from '@/pages/pdf/PdfStyles';
import { PdfTableCell } from '@/pages/pdf/PdfTableCell';
import { Text, View } from '@react-pdf/renderer';
import { FunctionComponent, useContext } from 'react';
import { PdfStaffSkillsContext } from '../PdfStaffSkillsProvider';
import { StaffSkillCapabilities } from './useBuildStaffSkillCapabilityData';

interface PdfStaffSkillCapabilitySectionProps {
  locationId: string;
  data: StaffSkillCapabilities[];
  showHeader: boolean;
}

const PdfStaffSkillCapabilitySection: FunctionComponent<PdfStaffSkillCapabilitySectionProps> = ({
  locationId,
  data,
  showHeader
}) => {
  const { locations } = useContext(PdfStaffSkillsContext);

  const location = locations.find(l => String(l.id) === locationId);
  if (!location) throw new Error(`Location not found with id: ${locationId}`);

  return (
    <View key={location.id} style={{ marginBottom: 10 }}>
      {showHeader && <Text>{`${location.id} ${location.name}`}</Text>}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <PdfTableCell width={90}>
            <Text style={{ ...styles.tableCell, textAlign: 'left' }}>Skill</Text>
          </PdfTableCell>
          <PdfTableCell>
            <Text style={{ ...styles.tableCell, textAlign: 'left' }}>Employees</Text>
          </PdfTableCell>
        </View>
        {data.map(item => {
          const formattedEmployees = item.employees.join(', ');

          return (
            <View key={`${location.id}-${item.skillName}`} style={styles.tableRow}>
              <PdfTableCell width={90}>
                <View style={{ padding: '2px 0px' }}>
                  <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{item.skillName}</Text>
                </View>
              </PdfTableCell>
              <PdfTableCell>
                <View style={{ padding: '2px 0px' }}>
                  <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{formattedEmployees}</Text>
                </View>
              </PdfTableCell>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default PdfStaffSkillCapabilitySection;
