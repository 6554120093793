import { WarningModel, SeverityType } from './warningDefinitions';

export const getSeverityType = (warnings: WarningModel[]): SeverityType => {
  if (warnings.find(w => w.severity === SeverityType.CRITICAL)) {
    return SeverityType.CRITICAL;
  }

  if (warnings.length > 0) {
    return SeverityType.WARNING;
  }

  return SeverityType.LOW;
};
