import clsx from 'clsx';
import { FC, useRef } from 'react';
import { Bar } from './Bar';
import { TimeSlot, getTimeSlotIndexInSlider } from './ganttHelper';
import { GanttTaskCapabilityTooltip } from './GanttTimePicker';

interface GanttSliderProps {
  startSlot: number;
  endSlot: number;
  onChangeStart: (start: number) => void;
  onChangeEnd: (end: number) => void;
  onChangeStartAndEnd?: (start: number) => void;
  onClear?: () => void;
  readOnly?: boolean;
  className?: string;
  color: string;
  timeSlots: TimeSlot[];
  minSlotIndex: number;
  maxSlotIndex: number;
  minimumSlotLength?: number;
  maximumSlotLength?: number;
  resizeStartMaxSlot?: number;
  resizeEndMinSlot?: number;
  sub?: boolean;
  icon?: JSX.Element;
  iconColor?: string;
  tooltip?: string;
  capabilityTooltip?: GanttTaskCapabilityTooltip;
}

export const GanttSlider: FC<GanttSliderProps> = ({
  startSlot,
  endSlot,
  onChangeStart,
  onChangeEnd,
  onChangeStartAndEnd,
  onClear,
  readOnly,
  className,
  color,
  sub,
  timeSlots,
  minSlotIndex,
  maxSlotIndex,
  minimumSlotLength = 0,
  maximumSlotLength,
  icon,
  iconColor,
  tooltip,
  resizeEndMinSlot,
  resizeStartMaxSlot,
  capabilityTooltip
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const startEventToTime = (event: MouseEvent) => {
    if (!sliderRef.current) {
      return;
    }

    const slotIndex = getTimeSlotIndexInSlider(sliderRef.current, event, timeSlots);

    // Make sure the slider will go through the allowed slots only (main bar use all time slots and subsection use
    // only the slots that are within the main bar)

    // subsectionsFirstUsedSlot and subsectionsLastUsedSlot are only used by main bar so it will not pass any
    // of the subsections while using the sliders
    if (
      slotIndex === undefined ||
      slotIndex > endSlot ||
      (resizeStartMaxSlot && slotIndex > resizeStartMaxSlot) ||
      slotIndex < 0 ||
      slotIndex > timeSlots.length - 1
    ) {
      return;
    }

    let slotToSet = Math.min(slotIndex, endSlot - minimumSlotLength + 1);
    if (maximumSlotLength !== undefined) {
      slotToSet = Math.max(slotToSet, endSlot - maximumSlotLength);
    }

    if (slotToSet < minSlotIndex) {
      return;
    }

    return timeSlots[slotToSet].start;
  };

  const endEventToTime = (event: MouseEvent) => {
    if (!sliderRef.current) {
      return;
    }

    const slotIndex = getTimeSlotIndexInSlider(sliderRef.current, event, timeSlots);

    // Make sure the slider will go through the allowed slots only (main bar use all time slots and subsection use
    // only the slots that are within the main bar)

    // subsectionsFirstUsedSlot and subsectionsLastUsedSlot are only used by main bar so it will not pass any
    // of the subsections while using the sliders
    if (
      slotIndex === undefined ||
      slotIndex < startSlot ||
      (resizeEndMinSlot && slotIndex < resizeEndMinSlot) ||
      slotIndex < 0 ||
      slotIndex > timeSlots.length - 1
    ) {
      return;
    }

    let slotToSet = Math.max(slotIndex, startSlot + minimumSlotLength - 1);
    if (maximumSlotLength !== undefined) {
      slotToSet = Math.min(slotToSet, startSlot - maximumSlotLength);
    }

    if (slotToSet > maxSlotIndex) {
      return;
    }

    return timeSlots[slotToSet].end;
  };

  const dragEventToTime = (event: MouseEvent, dragStartEvent: MouseEvent) => {
    if (!sliderRef.current) {
      return;
    }

    const currentEventSlotIndex = getTimeSlotIndexInSlider(sliderRef.current, event, timeSlots);
    const dragStartEventSlotIndex = getTimeSlotIndexInSlider(sliderRef.current, dragStartEvent, timeSlots);

    if (currentEventSlotIndex === undefined || dragStartEventSlotIndex === undefined) {
      return;
    }

    const slotDifference = currentEventSlotIndex - dragStartEventSlotIndex;
    const startSlotToSet = startSlot + slotDifference;
    const currentSlotLength = endSlot - startSlot;

    // Not letting the end of the bar to go after the maxSlotIndex
    if (startSlotToSet + currentSlotLength > maxSlotIndex) {
      return;
    }

    // Not letting the end of the bar to go before the minSlotIndex
    if (startSlotToSet < minSlotIndex) {
      return;
    }

    return timeSlots[startSlotToSet].start;
  };

  const onDrag = (start: boolean, event: MouseEvent, dragStartEvent?: MouseEvent) => {
    if (readOnly) {
      return;
    }

    if (start) {
      const newTime = startEventToTime(event);
      if (newTime !== undefined) {
        onChangeStart(newTime);
      }
    } else if (dragStartEvent && onChangeStartAndEnd) {
      const newStartTime = dragEventToTime(event, dragStartEvent);
      if (newStartTime !== undefined) {
        onChangeStartAndEnd(newStartTime);
      }
    } else if (!start) {
      const newTime = endEventToTime(event);
      if (newTime !== undefined) {
        onChangeEnd(newTime);
      }
    }
  };

  const fixedLength = minimumSlotLength !== undefined && minimumSlotLength === maximumSlotLength;

  return (
    <div className={clsx(className, 'w-full h-full absolute')}>
      <div className={clsx(className, 'w-full h-full relative')} ref={sliderRef}>
        <Bar
          color={color}
          startSlot={startSlot}
          endSlot={endSlot}
          slotCount={timeSlots.length}
          onStartHandleDrag={(event: MouseEvent) => onDrag(true, event)}
          onEndHandleDrag={(event: MouseEvent) => onDrag(false, event)}
          onDragBar={(startEvent: MouseEvent) => (event: MouseEvent) => onDrag(false, event, startEvent)}
          onDelete={onClear}
          readOnly={readOnly}
          sub={sub}
          icon={icon}
          iconColor={iconColor}
          resizeable={!fixedLength}
          tooltip={tooltip}
          capabilityTooltip={capabilityTooltip}
        />
      </div>
    </div>
  );
};
