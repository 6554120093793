import { Button } from '@/components/button';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { EmployeeTable } from '@/pages/daily/employeeSelector/EmployeeTable';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import {FC, useContext, useState} from 'react';
import { twMerge } from 'tailwind-merge';
import { WorkingShift } from "@/pages/daily/daily.types";
import { DropdownSettings } from './EmployeeSelector';
import { EmployeeForShiftItem } from './employeeForShift.types';
import {EmployeeOptionSettingsContext} from "@/pages/daily/employeeSelector/EmployeeOptionSettingsProvider";

interface Props {
  employeeDropdownOptions: EmployeeForShiftItem[];
  dropdownSettings: DropdownSettings;
  shift: WorkingShift;
  onChange: (newOption: EmployeeForShiftItem) => void;
  onSetAllocationRequest: () => void;
  id: string;
}

export const EmployeeTables: FC<Props> = ({
  employeeDropdownOptions,
  dropdownSettings,
  shift,
  onChange,
  onSetAllocationRequest,
  id
}) => {
  const config = useContext(EmployeeOptionSettingsContext);
  const selectedLocation = useSelectedLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  const optionsInMyLocation = employeeDropdownOptions.filter(e => e.details?.homeLocationId === selectedLocation.id);
  const optionsFromOtherLocations = employeeDropdownOptions.filter(
    e => e.details?.homeLocationId !== selectedLocation.id
  );

  const header = config.reassignmentModel === 'disabled' ? <>
    <div className="text-sm">
      <div className="text-[#EA5788]">
        {selectedLocation ? `[${(selectedLocation as any)?.id}] ${(selectedLocation as any)?.name}` : '#'}
      </div>
    </div>
  </> : <>
    <Button variant={activeIndex === 0 ? 'active' : 'default'} onClick={() => setActiveIndex(0)}>
      <div className="location-data flex text-[#002742] gap-2">
        <div>{strings.daily.employeesDropdown.myLocation}</div>
        <div className="text-[#EA5788]">
          {selectedLocation ? `[${(selectedLocation as any)?.id}] ${(selectedLocation as any)?.name}` : '#'}
        </div>
      </div>
    </Button>
    <Button
      disabled={config.reassignmentModel !== 'pull'}
      variant={activeIndex === 1 ? 'active' : 'default'}
      onClick={() => setActiveIndex(1)}
    >
      {strings.daily.employeesDropdown.fromOtherLocations}
    </Button>
  </>;

  return (
    <div
      id={id}
      className={twMerge(
        `custom-dropdown-wrapper fixed flex flex-col w-max z-[1000] text-xs border border-[#32323240] rounded-lg bg-[#fafafa] py-2 px-4 gap-5 transition duration-500 ease-linear`
      )}
      style={{
        height: `${dropdownSettings.height}px`,
        left: dropdownSettings.left,
        top: dropdownSettings.top,
        bottom: dropdownSettings.bottom
      }}
    >
      <div className="flex gap-5">
        {header}
        <div className="grow"></div>
        <div className="time-required text-sm flex text-[#0070D2] gap-1">
          <div>{strings.daily.employeesDropdown.timeRequired}:</div>
          <div>{`${shift?.start !== undefined ? secondsToTimeString(shift?.start) : '--:--'} ~ ${
            shift?.end !== undefined ? secondsToTimeString(shift?.end) : '--:--'
          }`}</div>
        </div>
      </div>
      {activeIndex === 0 && (
        <EmployeeTable
          employeeDropdownOptions={optionsInMyLocation}
          shift={shift}
          onChange={onChange}
          onSetAllocationRequest={onSetAllocationRequest}
          dropdownSettings={dropdownSettings}
          noStaffAvailableMessage={strings.daily.employeesDropdown.noStaffAvailable}
        />
      )}
      {activeIndex === 1 && config.reassignmentModel === 'pull' && (
        <EmployeeTable
          employeeDropdownOptions={optionsFromOtherLocations}
          shift={shift}
          onChange={onChange}
          onSetAllocationRequest={onSetAllocationRequest}
          dropdownSettings={dropdownSettings}
          noStaffAvailableMessage={strings.daily.employeesDropdown.noStaffAvailableFromOtherLocations}
        />
      )}
    </div>
  );
};
