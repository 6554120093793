import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { WorkingShift } from "@/pages/daily/daily.types";
import { EmployeeForShiftItem } from './employeeForShift.types';

export const useBuildEmployeeSelectorDisplayName = (
  shift: WorkingShift,
  workingShifts?: WorkingShift[],
  allocatedEmployee?: EmployeeForShiftItem,
  displayIds?: boolean,
  readOnly?: boolean
) => {
  return useMemo(() => {
    const shiftsAllocatedToEmployee = workingShifts?.filter(s => s.assignedEmployeeId === allocatedEmployee?.id);

    const allocatedIndex = shiftsAllocatedToEmployee?.findIndex(s => s.id === shift.id);

    let nameToDisplay = allocatedEmployee?.name ?? strings.daily.employeesDropdown.select;

    const getAllocatedSuffix = () => `${allocatedIndex ? `+${allocatedIndex}` : ''}`;

    if (readOnly) {
      nameToDisplay = `${allocatedEmployee?.name ?? ''} ${getAllocatedSuffix()}`;
    } else if (displayIds && allocatedEmployee) {
      nameToDisplay = `${allocatedEmployee.name} (${allocatedEmployee.employeeId}) ${getAllocatedSuffix()}`;
    }

    return nameToDisplay;
  }, [allocatedEmployee, displayIds, shift, workingShifts, readOnly]);
};
