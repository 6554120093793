interface Props {
  hqAllocatedForMonth?: number;
  actualTotal: number
}

export const MonthlyBudgetCostTotal: React.FC<Props> = ({hqAllocatedForMonth, actualTotal}) => {
  let colorClass = '';

  if (hqAllocatedForMonth) {
    if (hqAllocatedForMonth > actualTotal) {
      colorClass = 'text-primary';
    } else if (hqAllocatedForMonth < actualTotal) {
      colorClass = 'text-secondary';
    }
  }
  
  if (!hqAllocatedForMonth) {
    return <div>
      {actualTotal}
    </div>
  } else if (!actualTotal) {
    return <div>
      {hqAllocatedForMonth}
    </div>
  } else {
    return <div className="flex flex-col items-center">
      <p>{hqAllocatedForMonth}</p>
      <p className={colorClass}>{`(${actualTotal})`}</p>
    </div>
  }
}