import { displaySecondsAsTime, useTimeSlots } from '@/components/gantt/gantt/ganttHelper';
import { Text, View } from '@react-pdf/renderer';
import { FC, useContext } from 'react';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { ROSTER_DETAILS_WIDTH, ROSTER_NAME_WIDTH } from './PdfRosterDocument';
import { PdfRosterContext } from './PdfRosterProvider';
import { secondsToHours } from 'date-fns';
import {PrintViewTypes} from "@/pages/pdf/roster/usePrintOptions";

const MOST_SANE_SLOTS = 16;

export const PdfRosterTimerBar: FC = () => {
  const { maxTime, minTime, viewType} = useContext(PdfRosterContext);

  const totalDayHours = secondsToHours(maxTime - minTime);
  const hoursPerSlot = Math.ceil(totalDayHours / MOST_SANE_SLOTS);

  const hourlySlots = useTimeSlots(minTime, maxTime, 60 * 60 * hoursPerSlot);

  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={viewType === PrintViewTypes.SCREEN ? ROSTER_DETAILS_WIDTH : ROSTER_NAME_WIDTH} />
      {hourlySlots.map((slot, index) => (
        <PdfTableCell key={slot.start} lastCell={index === hourlySlots.length - 1} flex={slot.width}>
          {slot.width === 1 && <Text style={styles.tableCell}>{displaySecondsAsTime(slot.start)}</Text>}
        </PdfTableCell>
      ))}
    </View>
  );
};
