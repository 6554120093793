import { Button } from '@/components/button';
import { AddButton } from '@/components/buttons/AddButton';
import { FIXED_LEAVE_TYPES } from '@/services/availability/availability.types';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { FC } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { SettingsTable } from '../../layout/SettingsTable';
import { Leave } from './Leave';
import { useEditAdvancedLeave } from './useEditAdvancedLeave';
import { useLeaveColumns } from './useLeaveColumns';

export const LeaveSettings: FC = () => {
  const advancedLeave = useSystemSettings(state => state.settings.advancedLeaveManagement);
  const { leaveTypes, onAdd, onUpdate, onDelete, onSave, canSave, idsToBeDeleted } = useEditAdvancedLeave();
  const columns = useLeaveColumns();
  const deleteEnabled = useFlag('deleteLeaveTypes');

  const allSelectedColors = leaveTypes.map(lt => lt.properties.color);

  return (
    <>
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            {advancedLeave && 
              <AddButton label={strings.settings.manageLeave.leaveType} onButtonClick={onAdd} />
            }
          </div>
          <Button
            className="bg-[#1BB55C] text-[white] hover:text-[white] hover:bg-[#1BB55C]"
            onClick={onSave}
            variant={canSave ? 'submit' : undefined}
            size="sm"
            disabled={canSave ? undefined : true}
          >
            {strings.common.save}
          </Button>
        </div>
      </SettingsSectionHeader>
      <SettingsTable columns={columns} id="leave-table">
        {leaveTypes.length > 0 &&
          leaveTypes.map(leaveType => (
            <Leave
              fixed={FIXED_LEAVE_TYPES.includes(leaveType.id)}
              key={leaveType.id}
              leaveType={leaveType}
              deleteEnabled={deleteEnabled}
              isToBeDeleted={idsToBeDeleted.includes(leaveType.id)}
              onUpdate={onUpdate}
              onDelete={onDelete}
              columns={columns}
              parentContainerId="leave-table"
              excludedColors={allSelectedColors.filter(c => c !== leaveType.properties.color)}
            />
          ))}
        {!leaveTypes.length && (
          <div className="flex items-center justify-center col-span-full p-10 text-xl text-[#32323280] font-medium">
            {strings.settings.manageLeave.emptyList}
          </div>
        )}
      </SettingsTable>
    </>
  );
};
