import { AvailabilityIndicator } from '@/components/availability/AvailabilityIndicator';
import { ReassignedIndicator } from "@/components/monthlyShift/ReassignedIndicator";
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { SummaryEmployeeRowStyle } from './SummaryEmployeeRow';
import { SummaryShift } from './SummaryShift';
import { SummaryDay } from '@/services/summary/summary.types';
import { useLeaveType } from '@/services/leaveManagement/LeaveTypesProvider';

interface Props {
  scheduleDay?: SummaryDay;
  rowStyle: SummaryEmployeeRowStyle;
}

export const SummaryEmployeeCell: FC<Props> = ({ scheduleDay, rowStyle }) => {
  const hasAbscence = scheduleDay?.leaveType !== undefined;
  const leaveType = useLeaveType(scheduleDay?.leaveType);
  
  let labelContent: JSX.Element = hasAbscence ? <AvailabilityIndicator leaveTypeId={scheduleDay.leaveType} /> : <></>;
  if (scheduleDay?.os) {
    labelContent = <ReassignedIndicator/>;
  }

  let mainContent: JSX.Element = <></>;

  if (scheduleDay && scheduleDay.shifts.length > 0) {
    mainContent = (
      <>
        {scheduleDay.shifts.map(shift => (
          <SummaryShift key={shift.start} shift={shift} rowStyle={rowStyle} miniMode={scheduleDay.shifts.length > 1} />
        ))}
      </>
    );
  } else if (hasAbscence) {
    if (rowStyle === SummaryEmployeeRowStyle.WEEK) {
      mainContent = (
        <div
          className="bg-background-mid rounded-md flex items-center justify-center flex-1 h-full"
          style={{ backgroundColor: leaveType?.properties.color }}
        >
          {strings.summary.holiday}
        </div>
      );
    } else {
      mainContent = (
        <div
          className="bg-background-mid rounded-md flex items-center justify-center flex-1"
          style={{ backgroundColor: leaveType?.properties.color }}
        >
          {strings.summary.holiday}
        </div>
      );
    }
  } else {
    if (rowStyle === SummaryEmployeeRowStyle.WEEK) {
      mainContent = (
        <div className="bg-background-mid rounded-md flex items-center justify-center flex-1 h-full">
          {strings.summary.available}
        </div>
      );
    } else {
      mainContent = (
        <div className="bg-background-mid rounded-md flex items-center justify-center flex-1">
          {strings.summary.available}
        </div>
      );
    }
  }

  let content = <></>;
  if (rowStyle === SummaryEmployeeRowStyle.WEEK) {
    content = (
      <div className="flex gap-1 w-full items-center py-2">
        {labelContent}
        {mainContent}
      </div>
    );
  } else {
    content = (
      <div className="flex flex-col gap-1 w-full">
        {labelContent}
        {mainContent}
      </div>
    );
  }

  return <div className="flex-1 border-l flex items-stretch justify-center p-1 overflow-hidden">{content}</div>;
};
