import { TableColumn } from "./SettingsTable";

export const getColumnWidthStyles = (column: TableColumn): React.CSSProperties => {
  let styles: React.CSSProperties = {};
  if (column.width === 'auto') {
    styles.flexGrow = 1;
  } else {
    styles.width = column.width + 'px';
  }

  return styles;
};

export const updateObjectByKeys = (onUpdate: (newObj: any) => void, object: any) => (propName: string, newValue: any) => {
  const newObject = { ...object };
  const splitPropName = propName.split('.');

  let currentObject = newObject;
  splitPropName.forEach((pn, index) => {
    const keys = Object.keys(currentObject);
    const foundKey = keys?.find(k => k === pn);
    if (foundKey) {
      if (index === splitPropName.length - 1) {
        currentObject[foundKey] = newValue;
      } else {
        currentObject = currentObject[foundKey];
      }
    }
  });

  onUpdate(newObject);
};