import React, { FC, useMemo } from 'react';
import { Button } from '@/components/button';
import { strings } from '@/services/translation/strings';
import { SettingsTableCell } from '../../layout/SettingsTableCell';
import { SettingsTable, TableColumn } from '../../layout/SettingsTable';
import { useUserAccounts } from '@/services/settings/useUserAccounts';
import { useResetPassword } from '../../services/useResetPassword';

export const UserAccountsSettings: FC = () => {
  const userAccounts = useUserAccounts();

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        name: strings.settings.location.locationName,
        width: 370
      },
      {
        name: strings.settings.manageStaff.resetPassword,
        width: 130
      }
    ];
  }, []);

  if (!userAccounts) {
    throw Error('Could not get user accounts list');
  }

  return (
    <div className="h-full flex flex-col">
      <SettingsTable columns={columns} showIndexColumn={false}>
        {userAccounts.managers.map(manager => {
          const { resetUserPassword, fetching } = useResetPassword(manager.identityId);
          return (
            <React.Fragment key={'user-account' + manager.identityId}>
              <SettingsTableCell>
                <div className="flex items-center text-[#323232] gap-2">
                  <span className="whitespace-nowrap">
                    [<span className="font-medium">{`${manager.firstName}`}</span>]
                  </span>
                  <span className="text-[#323232cc] break-keep">{manager.lastName}</span>
                </div>
              </SettingsTableCell>
              <SettingsTableCell className="flex justify-center">
                <Button
                  size="sm"
                  variant={'primary'}
                  loading={fetching}
                  onClick={() => resetUserPassword()}
                  className="w-24"
                >
                  {strings.settings.manageStaff.resetButton}
                </Button>
              </SettingsTableCell>
            </React.Fragment>
          );
        })}
      </SettingsTable>
    </div>
  );
};
