import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { clsx } from 'clsx';
import { FC } from 'react';

interface Props {
  className?: string;
  advancedLeaveCols?: string[];
}

export const ComplianceBar: FC<Props> = ({ className, advancedLeaveCols }) => {
  const advancedLeave: boolean = useSystemSettings(state => state.settings.advancedLeaveManagement);
  const leaveTypes = useLeaveTypes();

  return (
    <div className={clsx('flex w-full bg-background-mid border-l', className)}>
      <div className="flex-1 flex">
        {advancedLeave && (
          <div
            className="border-r flex flex-col"
            style={{ flex: advancedLeaveCols ? advancedLeaveCols.length + 1 : leaveTypes.length + 1 }}
          >
            <InfoHeaderCell className="border-b font-bold">
              <p>{strings.summary.compliance.advancedLeaveHeader}</p>
            </InfoHeaderCell>
            <div className="flex">
              {leaveTypes.map(leave => {
                const showCol = (advancedLeaveCols && advancedLeaveCols?.includes(leave.id)) || !advancedLeaveCols;

                return (
                  showCol && (
                    <InfoHeaderCell className="border-r">
                      <p>{leave.properties.displayName ? leave.properties.displayName : leave.name}</p>
                    </InfoHeaderCell>
                  )
                );
              })}
              <InfoHeaderCell>
                <p>{strings.summary.compliance.advancedLeaveTotal}</p>
              </InfoHeaderCell>
            </div>
          </div>
        )}
        {!advancedLeave && (
          <InfoHeaderCell className="border-r">
            <p>{strings.summary.compliance.totalHolidays}</p>
          </InfoHeaderCell>
        )}
        <InfoHeaderCell className="border-r">
          <p>{strings.summary.compliance.totalAttendance}</p>
        </InfoHeaderCell>
        <InfoHeaderCell className="border-r">
          <p>{strings.summary.compliance.totalShifts}</p>
        </InfoHeaderCell>
        <InfoHeaderCell className="border-r">
          <p>{strings.summary.compliance.totalUnassigned}</p>
        </InfoHeaderCell>
        <div className="w-5"></div>
      </div>
    </div>
  );
};
