import { strings } from '@/services/translation/strings';

export const ReassignedIndicator = () => {
  return (
    <span
      className="flex rounded-md p-1 h-4 justify-center items-center overflow-hidden"
      style={{ border: 'none', color: 'black', backgroundColor: '#E8AA14' }}
    >
      <span className="text-[10px] whitespace-nowrap">{strings.summary.reassigned}</span>
    </span>
  );
};
