import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';

interface MonthlyTotalHeaderProps {
  showDayTotals?: boolean;
}

export const MonthlyTotalHeader: FC<MonthlyTotalHeaderProps> = ({ showDayTotals }) => {
  return (
    <>
      <div className="text-center border-b">{strings.common.totals.monthlyTotal}</div>
      <div className="flex">
        {showDayTotals && (
          <>
            <InfoHeaderCell className="border-r flex-1 text-sm">
              <p>{strings.summary.compliance.notRostered}</p>
            </InfoHeaderCell>
            <InfoHeaderCell className="border-r flex-1 text-sm">
              <p>{strings.summary.compliance.rostered}</p>
            </InfoHeaderCell>
          </>
        )}
        <InfoHeaderCell className="border-r flex-1">
          <p>
            {strings.common.totals.max}/ {strings.common.totals.min}
          </p>
        </InfoHeaderCell>
        <InfoHeaderCell className="flex-1">
          <p>{strings.common.totals.periodTotal}</p>
        </InfoHeaderCell>
      </div>
    </>
  );
};
