import { ConfirmedLink } from '@/components/link/ConfirmedLink';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const HeaderLink: FC<{ to: string; name: string }> = ({ to, name }) => {
  const location = useLocation();
  const isActive = location.pathname.includes(to);

  const colour = useSystemSettings(state => state.settings.highlightSystemTabsColor);
  const textColour = useSystemSettings(state => state.settings.systemTabsTextColor);

  let activeTabBgColour = isActive ? colour : '';
  let activeTabTextColour = isActive ? textColour : '';

  return (
    <ConfirmedLink
      to={to}
      style={{ backgroundColor: activeTabBgColour, color: activeTabTextColour }}
      className={twMerge(
        `text-sm border-2 border-transparent p-1`,
        isActive
          ? `font-bold rounded-lg`
          : `font-light rounded-xl hover:border-2 hover:bg-gray-100 hover:border-secondary hover:rounded-lg hover:p-1`
      )}
    >
      {name}
    </ConfirmedLink>
  );
};
