import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { staffFirstSectionColour } from '../StaffSettings';
import { useVisibleGroups } from '../useVisibleGroups';
import { HeaderCell } from './HeaderCell';
import { HeaderCellDescription } from './HeaderCellDescription';
import { STAFF_TABLE_INDEX_COLUMN_WIDTH, TableColumnGroup } from './StaffTable';

interface Props {
  groups: TableColumnGroup[];
  className?: string;
  checkedState?: Record<string, boolean>;
  indexColumnWidth?: number;
  indexColumnClassName?: string;
}

//CustomSettingsTableHeader
export const Header: FC<Props> = ({
  groups,
  className,
  checkedState,
  indexColumnWidth = STAFF_TABLE_INDEX_COLUMN_WIDTH,
  indexColumnClassName
}) => {
  if (groups.length < 1) {
    throw Error('Must provide at least one column group');
  }

  const visibleGroups = useVisibleGroups(groups, checkedState);

  const columnTotal = visibleGroups.reduce((prevTotal, group) => prevTotal + group.columns.length, 0);

  return (
    <>
      <HeaderCell
        className={twMerge('sticky left-0 top-0 flex items-center', indexColumnClassName)}
        style={{
          width: indexColumnWidth,
          zIndex: columnTotal + 1,
          height: '80px',
          backgroundColor: staffFirstSectionColour
        }}
      >
        {strings.settings.manageStaff.employeeId}
      </HeaderCell>
      {visibleGroups.map((group, groupIndex) => {
        const firstVisibleColumn = group.columns.find(c => !c.isColumnHidden);

        let startingZIndex = columnTotal;

        for (let i = 0; i < groupIndex; i++) {
          startingZIndex -= visibleGroups[i].columns.length;
        }

        return group.columns.map((col, index) => {
          if (col.isColumnHidden) {
            return <></>;
          }

          const firstColumn = col === firstVisibleColumn;

          let styles: React.CSSProperties = {
            zIndex: startingZIndex - index
          };

          if (groupIndex === 0) {
            let leftInset = indexColumnWidth;

            for (var i = 0; i < index; i++) {
              leftInset += group.columns[i].width;
            }

            styles.left = leftInset;
          }

          const totalWidth = group.columns.reduce((prevTotal, column) => {
            if (column.isColumnHidden) {
              return prevTotal;
            }

            return prevTotal + column.width;
          }, 0);

          return (
            <div key={col.name} style={styles} className={twMerge(`sticky top-0`, className)}>
              {group.name && firstColumn && (
                <HeaderCellDescription
                  className={twMerge('', className)}
                  style={{
                    height: '40px',
                    width: totalWidth,
                    backgroundColor: group.color,
                    color: group.fontColor
                  }}
                >
                  {group.name}
                </HeaderCellDescription>
              )}
              <HeaderCell
                key={col.name}
                style={{
                  ...styles,
                  height: group.name ? '40px' : '80px',
                  backgroundColor: group.color,
                  top: group.name ? 40 : 0,
                  color: group.fontColor
                }}
                className={twMerge('sticky bottom-0 flex items-center', className)}
              >
                {col.name}
              </HeaderCell>
            </div>
          );
        });
      })}
    </>
  );
};
