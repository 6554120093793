import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import React, { FC } from 'react';
import { TableColumnGroup } from '../../manageStaff/staffTable/StaffTable';
import { SkillStaffRowDetailsSection } from './StaffRowDetailsSection';
import { StaffRowSkillsSection } from './StaffRowSkillsSection';

interface Props {
  onChangeSetting: (key: string) => (newVal: any) => void;
  staff: StaffWithSettings;
  groups: TableColumnGroup[];
}

export const StaffSkilsRow: FC<Props> = ({ staff, onChangeSetting, groups }) => {
  return (
    <React.Fragment>
      <SkillStaffRowDetailsSection group={groups[0]} staff={staff} onChangeSetting={onChangeSetting} />
      <StaffRowSkillsSection group={groups[1]} staff={staff} onChangeSetting={onChangeSetting} hidden={false} />
    </React.Fragment>
  );
};
