import { cn } from '@/lib/utils';
import { EMPLOYEE_DETAILS_COLUMN_WIDTH, EMPLOYEE_NAME_COLUMN_WIDTH } from '@/pages/summary/constants';
import { useGetContractName } from '@/services/contract/useGetContractName';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { FC } from 'react';
import { AttendanceTotalColumns } from '../AttendanceTotalColumns';
import { ShiftTimePicker } from '../ShiftTimePicker';
import { DailyEditingType } from '../daily.types';
import { MonthlyTotalColumns } from './MonthlyTotalColumns';
import { useUnassignedEmployeesWithGhostShifts } from './providers/WorkingRosterService';
import { WorkingShift } from "@/pages/daily/daily.types";
import { SummaryEmployee } from '@/services/summary/summary.types';

/*
 * What's an 'Unassigned Ghost Shift'?
 *
 * Example of a regular ghost shift:
 * On Monday, I start at 6pm and end at 2am.
 * On Tuesday, I start at 4pm and end at 11pm.
 * Tuesday's roster start time is 12am.
 * I need to be able to show the 2 hours of shift from Monday in the same row as my Tuesday shift.
 *
 * An unassigned ghost shift is like this, but for an employee not currently working at the store.
 * These are added to the bottom of the roster, as an FYI.
 * They disappear when that person is assigned to the roster- their ghost shift becomes displayed
 * as a regular one on their assigned row.
 */

export const fakeShift: WorkingShift = {
  id: '',
  breaks: [],
  tasks: []
};

const UnassignedGhostShift: FC<{
  data: SummaryEmployee;
}> = ({ data }) => {
  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);
  let nameToDisplay = data.name;
  if (displayIds) {
    nameToDisplay = `${data.name} (${data.employeeId})`;
  }

  const getContractName = useGetContractName();

  return (
    <div className="flex flex-row border-b">
      <div className={cn('flex sticky left-0 z-[60] bg-white border-r')}>
        <div
          className="flex flex-col justify-center px-2 font-semibold border-r"
          style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
        >
          <div className="flex flex-col w-[90%] gap-1 text-sm">{nameToDisplay}</div>
          <div className="text-xs">{data.contractId ? getContractName(data.contractId) : ''}</div>
        </div>
        <div style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }} className="flex">
          <div className="flex-[2] flex border-r">
            <MonthlyTotalColumns shift={fakeShift} />
          </div>
          <div className="flex-[3] flex">
            <AttendanceTotalColumns shift={fakeShift} />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <ShiftTimePicker
          key={data.id}
          shift={fakeShift}
          ghostShifts={data.schedule[0].ghostShifts!.map(g => ({ startTime: g.start, endTime: g.end }))}
          updateShift={() => {
            return;
          }}
          type={DailyEditingType.ROSTER}
          useColorizedBar={true}
          readOnly
        />
      </div>
    </div>
  );
};

export const UnassignedGhostShifts: FC = () => {
  const unassignedEmployees = useUnassignedEmployeesWithGhostShifts();

  return (
    <>
      {unassignedEmployees.map(employee => (
        <UnassignedGhostShift data={employee} key={employee.id}></UnassignedGhostShift>
      ))}
    </>
  );
};
