import { useCallback } from 'react';
import { usePostFiles } from './PostFiles';
import { dateToApiFormat } from '@/helpers/dateHelper';

export const useUploadBudget = (successCallback: () => void) => {
  const { postFiles, ...rest } = usePostFiles(() => {
    successCallback();
  });
  const startDate = new Date(0);
  const endDate = new Date(9999, 11, 31);

  const endpoint = `/SnpsShift/ImportTargets?dateStart=${dateToApiFormat(startDate)}&dateEnd=${dateToApiFormat(endDate)}`;

  const upload = useCallback(
    async (file: File) => {
      return await postFiles([{ formName: 'file', file }], endpoint);
    },
    [postFiles, endpoint]
  );

  return { upload, ...rest };
};
