import { dateToApiFormat, hoursToSeconds } from '@/helpers/dateHelper';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import {
  AvailabilityException,
  EmployeeAvailabilityExceptions,
  EmployeePattern,
  ReadEmployee,
  RosteredEmployeeIdAndSchedule,
  WorkingPatternDay
} from '@/services/gql/graphql.generated';
import { displayName } from '@/services/translation/name';
import { DayOfWeekAndHoliday } from '../holiday/holiday.types';
import { StaffSettings } from '../settings/systemSettings.types';
import { TaskType } from '../tasks/task.types';
import { SummaryDay, SummaryEmployee } from './summary.types';
import { buildComplianceDataForEmployee } from './buildCompliance';
import { calculateShiftTime } from '../rosters/rosterHelper';

export interface BuildSummaryProps {
  employees: ReadEmployee[];
  rosteredStaff: RosteredEmployeeIdAndSchedule[];
  dateRange: Date[];
  locationNumber?: number;
  locationName: string;
  workingPatterns: EmployeePattern[];
  getDayOfWeekAndHoliday: (date: Date) => DayOfWeekAndHoliday;
  tasks: TaskType[];
  includeBreaks?: boolean;
  allAvailabilityData: EmployeeAvailabilityExceptions[];
  addReassignedShifts: (sched: SummaryDay[], employeeId: string) => SummaryDay[];
  getContractName: (id: string) => string;
  getStaffSettings: (employeeId: string) => StaffSettings;
  mapToLeaveTypeId: (id?: string) => string | undefined;
}

export const buildSummary = (props: BuildSummaryProps) => {
  const {
    employees,
    rosteredStaff,
    dateRange,
    allAvailabilityData,
    addReassignedShifts,
    locationNumber,
    locationName,
    getContractName,
    getStaffSettings,
    workingPatterns,
    getDayOfWeekAndHoliday,
    mapToLeaveTypeId,
    tasks,
    includeBreaks
  } = props;

  return employees.map(employee => {
    let schedule: SummaryDay[] = dateRange.map(d => ({
      date: dateToApiFormat(d),
      shifts: [],
      availableTimes: [],
      basicTime: 0,
      actualTime: 0,
      breakTime: 0
    }));

    const employeeAvailabilityData = allAvailabilityData?.find(ex => ex.employeeId === employee.identityId);

    let employeeExceptions: AvailabilityException[] = [];
    if (
      employeeAvailabilityData?.patternsAndExceptions &&
      employeeAvailabilityData.patternsAndExceptions[0] &&
      employeeAvailabilityData.patternsAndExceptions[0].exceptions
    ) {
      employeeExceptions = employeeAvailabilityData.patternsAndExceptions[0].exceptions;
    }

    const rosters = rosteredStaff.filter(r => r.identityId === employee.identityId);

    const employeePatternData = workingPatterns.find(w => w.identityId === employee.identityId);

    let employeePattern: WorkingPatternDay[] = [];
    if (employeePatternData?.periods && employeePatternData.periods[0] && employeePatternData.periods[0].patterns) {
      employeePattern = employeePatternData.periods[0].patterns;
    }

    schedule.forEach(scheduleDay => {
      const date = new Date(scheduleDay.date);
      const dayNumber = getDayOfWeekAndHoliday(date);
      const patternDay = employeePattern?.find(p => p.dayNumber === dayNumber);

      // If it's a rostered day off, nothing else matters.
      if (patternDay && !patternDay.available) {
        scheduleDay.leaveType = FixedLeaveTypeId.ROSTER;
        return;
      }

      const exceptions = employeeExceptions?.filter(e => scheduleDay.date === dateToApiFormat(new Date(e.date)));

      // If there's an unexpected leave type, set that on the day.
      const absence = exceptions?.find(e => {
        return !e.available && e?.leaveType;
      });

      if (absence) {
        scheduleDay.leaveType = mapToLeaveTypeId(absence.leaveType?.id);
      }

      // Set the available times for the day.
      if (exceptions && exceptions?.length > 0) {
        // If there are exceptions for the data, use these for the available times.
        exceptions.forEach(exception => {
          if (exception.available) {
            scheduleDay.availableTimes!.push({ start: exception.start, end: exception.end });
          }
        });
      } else if (patternDay && patternDay.available) {
        // If there's no exceptions, fall back to the pattern.
        scheduleDay.availableTimes!.push({ start: patternDay.startTime, end: patternDay.endTime });
      }

      scheduleDay.availableTimes?.sort((a, b) => a.start - b.start);

      // Add any shifts to the day.
      const rosteredShifts = rosters.filter(r => dateToApiFormat(new Date(r.date)) === scheduleDay.date);

      rosteredShifts.forEach(rosteredShift => {
        const { breakTime, basicTime, actualTime, uncountedTaskTime } = calculateShiftTime(
          rosteredShift,
          tasks,
          includeBreaks
        );

        scheduleDay.shifts.push({
          start: rosteredShift.start,
          end: rosteredShift.end,
          locationId: locationNumber?.toString() || '',
          locationName: locationName,
          breakLength: breakTime,
          uncountedTaskLength: uncountedTaskTime,
          basicTime: basicTime,
          actualTime: actualTime
        });

        scheduleDay.actualTime += actualTime;
        scheduleDay.basicTime += basicTime;
        scheduleDay.breakTime += breakTime;
      });

      scheduleDay.shifts.sort((a, b) => a.start - b.start);

      /*
       * Find any 'ghost shifts'- these are shifts that span multiple days.
       *
       * Each shift belongs to a single day, which is the day the shift begins on.
       *
       * Eg. If I started at 10pm on Sunday and ended at 6am on Monday, that shift
       * would be counted as a Sunday shift, for the purposes of budgeting and reporting.
       *
       * However, we can't completely disregard these shifts- for determining availabilty,
       * we need to know if a user has a ghost shift in the morning/evening, so we don't
       * book them on two shifts at the same time.
       *
       * Hence, we build the list of ghost shifts separately to the regular shifts, so we
       * can refer to it whenever needed.
       */
      const dayBefore = new Date(scheduleDay.date);
      const dayAfter = new Date(scheduleDay.date);
      dayBefore.setDate(dayBefore.getDate() - 1);
      dayAfter.setDate(dayAfter.getDate() + 1);

      scheduleDay.ghostShifts = [];
      const shiftBefore = rosters.find(r => r.date === dateToApiFormat(dayBefore));
      if (shiftBefore) {
        // These shifts take place in the day before, so set the times back by a day.
        scheduleDay.ghostShifts.push({
          start: shiftBefore.start - hoursToSeconds(24),
          end: shiftBefore.end - hoursToSeconds(24),
          locationId: locationNumber?.toString() || '',
          locationName: locationName,
          breakLength: 0,
          uncountedTaskLength: 0,
          actualTime: shiftBefore.end - shiftBefore.start,
          basicTime: shiftBefore.end - shiftBefore.start
        });
      }

      const shiftAfter = rosters.find(r => r.date === dateToApiFormat(dayAfter));
      if (shiftAfter) {
        // These shifts take place in the day after, so set the times forwards by a day.
        scheduleDay.ghostShifts.push({
          start: shiftAfter.start + hoursToSeconds(24),
          end: shiftAfter.end + hoursToSeconds(24),
          locationId: locationNumber?.toString() || '',
          locationName: locationName,
          breakLength: 0,
          uncountedTaskLength: 0,
          actualTime: shiftAfter.end - shiftAfter.start,
          basicTime: shiftAfter.end - shiftAfter.start
        });
      }
    });

    schedule = addReassignedShifts(schedule, employee.identityId);

    const employeeSummary: SummaryEmployee = {
      id: employee.identityId,
      employeeId: employee.employeeId?.toString() || '',
      contractId: employee.contract?.contractTypeId || '',
      name: displayName(employee),
      role: employee.contract?.contractTypeId ? getContractName(employee.contract.contractTypeId) : '従業員種別',
      schedule,
      settings: getStaffSettings(employee.identityId),
      details: employee as ReadEmployee,
      compliance: buildComplianceDataForEmployee(schedule),
      homeEmployee: employee.homeLocationId === locationNumber
    };

    return employeeSummary;
  });
};
