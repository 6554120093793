import { useConfirm } from '@/services/confirm/ConfirmationService';
import {
  useUpdateEmployeesMutation
} from '@/services/gql/graphql.generated';
import { useStaffSettings } from '@/services/settings/StaffSettingsProvider';
import { useCallback } from 'react';
import { StaffWithSettings } from '../../../services/settings/systemSettings.types';
import { dateToApiFormat } from '@/helpers/dateHelper';
import { useEditSettingsService } from './EditSettingsService';

export const useDeleteStaff = () => {
  const setSettings = useStaffSettings(state => state.setSettings);
  const workingStaffSettings = useEditSettingsService(state => state.workingStaffSettings);
  const setWorkingStaffSettings = useEditSettingsService(state => state.setWorkingStaffSettings);

  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));

  const [{ fetching, error }, updateEmployees] = useUpdateEmployeesMutation();

  const deleteEmployees = useCallback(
    async (toDelete: StaffWithSettings[]) => {
      const employees = toDelete.map(staff => {
        return {
          identityId: staff.employee.identityId,
          employeeId: staff.employee.employeeId,
          firstName: staff.employee.firstName,
          lastName: staff.employee.lastName,
          kanjiFirstName: staff.employee.kanjiFirstName,
          kanjiLastName: staff.employee.kanjiLastName,
          role: staff.employee.role,
          isRosterable: false,
          hireDate: staff.settings.hiredDate && dateToApiFormat(staff.settings.hiredDate),
          leaveDate: staff.settings.leftCompanyDate && dateToApiFormat(staff.settings.leftCompanyDate),
          endOfProbationDate: staff.settings.endProbationDate && dateToApiFormat(staff.settings.endProbationDate),
          ignoreHourContribution: false
        };
      });

      const result = await updateEmployees({
        employees: employees
      });

      const newSettings = [...workingStaffSettings];
      
      newSettings.forEach(staff => {
        if (toDelete.find(s => s.employee.identityId === staff.employee.identityId)) {
          staff.settings.hasBeenDeleted = true;
        }
      });

      setSettings(newSettings);
      setWorkingStaffSettings(newSettings);
      unregisterForConfirmation();

      return result;
    },
    [updateEmployees, setSettings, unregisterForConfirmation, workingStaffSettings]
  );

  return {
    deleteEmployees,
    fetching,
    error
  };
};
