import { useSkills } from '@/services/tasks/useSkills';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';

interface Props {
  taskId?: string;
}

export const BaseTaskTooltipContent: FC<Props> = ({ taskId }) => {
  const tasks = useTasks();
  const skills = useSkills();
  const task = tasks.find(task => task.id == taskId);

  return (
    <div className="grid grid-cols-2 gap-1 ">
      <span className="font-bold">{strings.daily.drag.taskTooltips.mandatorySkills}</span>
      <span className="font-bold">{strings.daily.drag.taskTooltips.optionalSkills}</span>
      <div>
        {task?.skills && task.skills.length > 0 ? (
          task.skills.map((skill, index) => (
            <span key={`manSkill-${index}`}>{skills.find(item => item.id === skill)?.name}</span>
          ))
        ) : (
          <span>{strings.daily.drag.taskTooltips.noMandatorySkills}</span>
        )}
      </div>
      <div>
        {task?.optionalSkills && task.optionalSkills.length > 0 ? (
          task.optionalSkills.map((skill, index) => (
            <span key={`optSkill-${index}`}>{skills.find(item => item.id === skill)?.name}</span>
          ))
        ) : (
          <span>{strings.daily.drag.taskTooltips.noOptionalSkills}</span>
        )}
      </div>
    </div>
  );
};
