import { useOrganisationHierarchy } from '@/services/hierarchy/useOrganisationHierarchy';
import { strings } from '@/services/translation/strings';
import { FC, useEffect, useRef, useState } from 'react';

/* D3.js (ISC License)
Copyright 2010-2023 Mike Bostock

Permission to use, copy, modify, and/or distribute this software for any purpose
with or without fee is hereby granted, provided that the above copyright notice
and this permission notice appear in all copies.

THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND
FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS
OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER
TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF
THIS SOFTWARE.
 */
import { useOrphanedLocations } from '@/pages/settings/sections/organisationChart/useOrphanedLocations';
import { ReadEmployee, useGetEmployeesQuery } from '@/services/gql/graphql.generated';
import * as d3 from 'd3';
import { format } from 'date-fns';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  locationId?: number;
  collapsed?: boolean;
}

export interface DivisionData {
  children?: DivisionData[];
  divisionId?: string;
  level: number;
  locations?: LocationData[];
  name: string;
}

export interface LocationData {
  id: number;
  locationNumber?: number;
  name: string;
}

const getChildren = (locations?: LocationData[], children?: DivisionData[]) => {
  let nodes: TreeNode[] = [];
  if (locations && locations.length > 0) {
    const locationNodes: TreeNode[] = locations.map(location => {
      return { name: formatLocationName(location), locationId: location.id };
    });
    nodes = [...nodes, ...locationNodes];
  }
  if (children && children.length > 0) {
    const childNodes: TreeNode[] = children.map(child => {
      return {
        name: child.name,
        children: getChildren(child.locations, child.children)
      };
    });
    nodes = [...nodes, ...childNodes];
  }
  return nodes;
};

const countEdgeNodes = (node: d3.HierarchyNode<TreeNode>): number => {
  if (!node.children || node.data.collapsed) {
    return 1;
  }
  return d3.sum(node.children, countEdgeNodes);
};

const formatLocationName = (location: LocationData) => {
  return `${location.name} (${location.id})`;
};

const createEmployeeMap = (view: ORGANISATION_CHART_VIEW, employeeData?: ReadEmployee[]) => {
  const today = new Date();
  return employeeData
    ?.filter(e => {
      switch (view) {
        case ORGANISATION_CHART_VIEW.all: {
          return true;
        }
        case ORGANISATION_CHART_VIEW.current: {
          return !e.leaveDate;
        }
        case ORGANISATION_CHART_VIEW.past: {
          return e.leaveDate;
        }
      }
    })
    .filter(e => !!e.homeLocationId && e.contract?.contractTypeId) // filter out employees that don't have home location id and shop accounts that have no contract id.
    .filter(e => {
      if (e.hireDate) {
        const hireDate = new Date(e.hireDate);
        if (hireDate > today) return false;
      }
      if (e.leaveDate) {
        const leaveDate = new Date(e.leaveDate);
        if (leaveDate < today) return false;
      }
      return true;
    })
    .reduce((acc, employee) => {
      const locationId = employee.homeLocationId!;

      if (!acc.has(locationId)) {
        acc.set(locationId, []);
      }

      acc.get(locationId)!.push(employee);
      return acc;
    }, new Map<number, ReadEmployee[]>());
};

const addEmployees = (employeeMap: Map<number, ReadEmployee[]>, treeNodes?: TreeNode[]) => {
  treeNodes?.forEach(node => {
    if (node.locationId) {
      node.children = employeeMap
        .get(node.locationId)
        ?.sort((a, b) => {
          const employeeIdA = a.employeeId ?? '';
          const employeeIdB = b.employeeId ?? '';
          const employeeIdComparison = employeeIdA.localeCompare(employeeIdB);
          if (employeeIdComparison !== 0) {
            return employeeIdComparison;
          }

          const employeeNameA = `${a.lastName} ${a.firstName}`;
          const employeeNameB = `${b.lastName} ${b.firstName}`;
          return employeeNameA.localeCompare(employeeNameB);
        })
        .map(e => {
          const leaveDateSuffix = e.leaveDate ? format(new Date(e.leaveDate), 'dd/MM/yyyy') : '';

          return {
            name: e.employeeId
              ? `${e.lastName} ${e.firstName} (${e.employeeId}) ${leaveDateSuffix}`
              : `${e.lastName} ${e.firstName} ${leaveDateSuffix}`
          };
        });
      node.collapsed = false;
    } else {
      addEmployees(employeeMap, node.children);
    }
  });
};

export enum ORGANISATION_CHART_VIEW {
  all,
  current,
  past
}

interface OrganisationChartProps {
  view: ORGANISATION_CHART_VIEW;
}

export const OrganisationChart: FC<OrganisationChartProps> = ({ view }) => {
  const [{ data: employeeData }] = useGetEmployeesQuery();

  const employeeMap = createEmployeeMap(view, employeeData?.employees as ReadEmployee[]);

  const hierarchy = useOrganisationHierarchy();
  const data: TreeNode = {
    name: hierarchy.name,
    children: getChildren(hierarchy.locations, hierarchy.children)
  };
  const orphanedLocations = useOrphanedLocations(hierarchy);
  // Append orphanedLocations tree
  if (orphanedLocations.length) {
    const orphanedLocationNodes = orphanedLocations.map(orphanedLocation => {
      return { name: formatLocationName(orphanedLocation), locationId: orphanedLocation.id };
    });
    data.children = [
      ...(data.children ?? []),
      {
        name: strings.settings.admin.organisationChart.orphanedLocationsHolderName,
        children: orphanedLocationNodes
      }
    ];
  }

  employeeMap && addEmployees(employeeMap, [data]);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [rootNameWidth, setRootNameWidth] = useState<number | null>(null);
  const [rootData, setRootData] = useState<TreeNode>(data);

  useEffect(() => {
    employeeMap && addEmployees(employeeMap, [data]);
    setRootData({ ...data });
  }, [view]);

  // const rootData = useMemo(() => {
  //   employeeMap && addEmployees(employeeMap, [data]);
  //   return data;
  // }, [employeeMap]);

  useEffect(() => {
    const svgRefCopy = svgRef.current; // make sure that ref is valid at clean up
    if (svgRefCopy && rootNameWidth) {
      const root = d3.hierarchy(rootData, d => (d.collapsed ? null : d.children));
      const edgeNodes = countEdgeNodes(root);

      //TODO: The required width depends on the levels of hierarchy and how long division/location names are.
      const width = 3000;

      const gapBetweenTextAndNodeCircle = 10;
      const spaceForRootText = rootNameWidth + gapBetweenTextAndNodeCircle + 16; // left margin: 16
      const spaceForEdgeNodeText = 300; // space for edge node text. location names when all nodes are expanded
      const horizontalMargin = spaceForRootText + spaceForEdgeNodeText;
      const height = edgeNodes * 20; // lineHeight=20px

      const treeLayout = d3.tree<TreeNode>().size([height, width - horizontalMargin]);
      treeLayout(root);

      const svg = d3.select(svgRefCopy).attr('width', width).attr('height', height);

      const g = svg.append('g').attr('transform', `translate(${spaceForRootText},0)`);

      // Links
      g.selectAll('.link')
        .data(root.links())
        .enter()
        .append('path')
        .attr('class', 'link')
        .attr(
          'd',
          d3
            .linkHorizontal()
            .x(d => (d as any).y)
            .y(d => (d as any).x) as any
        )
        .style('fill', 'none')
        .style('stroke', '#BBB')
        .style('stroke-width', '0.5px');

      // Nodes
      const node = g
        .selectAll('.node')
        .data(root.descendants())
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', d => `translate(${d.y},${d.x})`)
        .on('click', (event, d) => {
          d.data.collapsed = !d.data.collapsed;
          setRootData({ ...rootData });
        });

      node
        .append('circle')
        .attr('r', 5)
        .style('fill', d => (d.data.children && d.data.collapsed ? 'lightcoral' : '#999'));

      node
        .append('text')
        .attr('dy', '0.35em')
        .attr('x', d => (d.children ? -gapBetweenTextAndNodeCircle : gapBetweenTextAndNodeCircle))
        .style('text-anchor', d => (d.children ? 'end' : 'start'))
        .text(d => d.data.name);
    }
    return () => {
      d3.select(svgRefCopy).selectAll('*').remove();
    };
  }, [rootData, rootNameWidth, setRootNameWidth]);

  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <MeasureText text={data.name} onMeasured={setRootNameWidth} />
      {setRootNameWidth !== null && <svg ref={svgRef} />}
    </div>
  );
};

// Utility component to measure text width with the current font.
const MeasureText: FC<{
  text: string;
  onMeasured: (width: number) => void;
}> = ({ text, onMeasured }) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (spanRef.current) {
      onMeasured(spanRef.current.offsetWidth);
    }
  }, [text, onMeasured]);

  return (
    <span ref={spanRef} style={{ position: 'absolute', whiteSpace: 'nowrap', visibility: 'hidden' }}>
      {text}
    </span>
  );
};
