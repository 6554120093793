import { SeverityType } from '@/components/warning/warningDefinitions';
import { SkillType } from '@/services/tasks/skillSettings.types';
import { TaskType } from '@/services/tasks/task.types';
import { WorkingShiftSubsection } from '../../../daily.types';
import { EmployeeSkill } from '../../../employeeSelector/employeeForShift.types';
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterWarningModel, WarningsProps } from '../warnings.types';

export const getMissingSkillsWarnings = (shift: WorkingShift, props: WarningsProps): RosterWarningModel[] => {
  const { tasks, skills: allSkills, strings, validationSettings, allEmployees, employeesSkillsList } = props;
  const enforceMandatory = validationSettings.mandatorySkills;

  const allocatedEmployee = allEmployees.find(e => e.identityId === shift.assignedEmployeeId);
  const allocatedEmployeeSkills = employeesSkillsList.find(
    esl => esl.employeeId === allocatedEmployee?.identityId
  )?.skills;

  if (!allocatedEmployeeSkills) {
    return [];
  }

  const { mandatorySkills, optionalSkills } = getMissingSkills(shift, tasks, allSkills, allocatedEmployeeSkills);

  const warnings: RosterWarningModel[] = [];

  if (mandatorySkills.length > 0) {
    warnings.push({
      name: strings.missingSkills.mandatory,
      severity: enforceMandatory ? SeverityType.CRITICAL : SeverityType.SEMICRITICAL,
      details: mandatorySkills.map(s => s.name),
      shiftId: shift.id
    });
  }

  if (optionalSkills.length > 0) {
    warnings.push({
      name: strings.missingSkills.optional,
      severity: SeverityType.WARNING,
      details: optionalSkills.map(s => s.name),
      shiftId: shift.id
    });
  }

  return warnings;
};

const getMissingSkills = (
  shift: WorkingShift,
  tasks: TaskType[],
  allSkills: SkillType[],
  allocatedEmployeeSkills: EmployeeSkill[]
) => {
  let mandatorySkills: SkillType[] = [];
  let optionalSkills: SkillType[] = [];

  if (shift.assignedEmployeeId) {
    shift.tasks.forEach((shiftTask: WorkingShiftSubsection) => {
      if (shiftTask.typeContext) {
        const taskDetails = tasks.find(t => t.id === shiftTask.typeContext!);
        const taskMandatorySkillsIds = taskDetails?.skills;
        const taskOptionalSkillsIds = taskDetails?.optionalSkills;

        taskMandatorySkillsIds?.forEach((skillId: string) => {
          if (!allocatedEmployeeSkills?.find((employeeSkill: EmployeeSkill) => employeeSkill.skillId === skillId)) {
            mandatorySkills = getUniqueMissingSkills(mandatorySkills, allSkills, skillId);
          }
        });

        taskOptionalSkillsIds?.forEach((skillId: string) => {
          if (!allocatedEmployeeSkills?.find((employeeSkill: EmployeeSkill) => employeeSkill.skillId === skillId)) {
            optionalSkills = getUniqueMissingSkills(optionalSkills, allSkills, skillId);
          }
        });
      }
    });
  }

  return {
    mandatorySkills,
    optionalSkills
  };
};

const getUniqueMissingSkills = (
  missingSkills: SkillType[],
  allSkills: SkillType[],
  skillIdToFind: string
): SkillType[] => {
  const foundSkill = allSkills.find((skill: SkillType) => skill.id === skillIdToFind);
  if (foundSkill && !missingSkills.find((missingSkill: SkillType) => missingSkill.id === foundSkill.id)) {
    return [...missingSkills, foundSkill];
  }

  return missingSkills;
};
