import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { DateInput } from '@/components/form/DateInput';
import { SelectInput, SelectOption } from '@/components/form/SelectInput';
import { showToastMessage } from '@/helpers/helper';
import { LocationSelector } from '@/pages/layout/main/LocationSelector';
import { useRole } from '@/services/authentication/hooks/user';
import { RoleType } from '@/services/authentication/roles';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useGetEmployeesQuery } from '@/services/gql/graphql.generated';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import React, { FC, useMemo, useState } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { SettingsTable, TableColumn } from '../../layout/SettingsTable';
import { SettingsTableCell } from '../../layout/SettingsTableCell';
import { useEditSettingsService } from '../../services/EditSettingsService';
import { useUpdateLocationSettings } from '../../services/useUpdateLocationSettings';
import { onChangeSettingsArray } from '../../settingsHelper';

export const LocationSettings: FC = () => {
  const { settings, editSettings } = useEditSettingsService(state => ({
    settings: state.workingLocationSettings,
    editSettings: state.setWorkingLocationSettings
  }));
  const { updateSettings: updateLocationSettings, fetching } = useUpdateLocationSettings();
  const { saveNeeded } = useConfirm(state => ({ saveNeeded: state.confirmationNeeded }));

  const onChangeSetting = onChangeSettingsArray(settings, editSettings);
  const workingLocationSettings = useEditSettingsService(state => state.workingLocationSettings);

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        name: strings.settings.location.companyCode,
        width: 200
      },
      {
        name: strings.settings.location.companyName,
        width: 200
      },
      {
        name: strings.settings.location.divisionName,
        width: 200
      },
      {
        name: strings.settings.location.brandName,
        width: 200
      },
      {
        name: strings.settings.location.SVName,
        width: 200
      },
      {
        name: strings.settings.location.prefecture,
        width: 200
      },
      {
        name: strings.settings.location.shopName,
        width: 200
      },
      {
        name: strings.settings.location.shopCode,
        width: 200
      },
      {
        name: strings.settings.location.openingDate,
        width: 200
      },
      {
        name: strings.settings.location.closedDate,
        width: 200
      },
      {
        name: strings.settings.location.inventoryDate,
        width: 200
      },
      {
        name: strings.settings.location.shiftRequestDeadline,
        width: 200
      },
      {
        name: strings.settings.location.deadlineESSReminder,
        width: 200
      },
      {
        name: strings.settings.location.maxLeaveEachDay,
        width: 200
      },
      {
        name: strings.settings.location.shopType,
        width: 200
      }
    ];
  }, []);

  const [{ data: staffData }] = useGetEmployeesQuery();

  const supervisorList = staffData?.employees?.filter(employee => employee.role === 'Supervisor');

  if (!supervisorList) {
    throw Error('Could not get supervisor list');
  }

  const supervisorOptions: SelectOption[] = useMemo(() => {
    const options = supervisorList.map(emp => ({
      name: displayName(emp),
      value: emp.identityId
    }));

    const blankOption = {
      name: strings.common.select,
      value: ''
    };

    return [blankOption, ...options];
  }, [supervisorList]);

  const onSave = async () => {
    try {
      const result = await updateLocationSettings(workingLocationSettings);
      showToastMessage(result);
    } catch (error) {
      console.log('Update location settings error', error);
    }
  };

  const [date, setDate] = useState<Date>(new Date());

  //TBD
  const shiftRequestDeadlineOptions: SelectOption[] = useMemo(() => {
    const blankOption = {
      name: strings.common.select,
      value: ''
    };

    return [blankOption];
  }, []);

  const [isToogle, setIsToogle] = useState<boolean>(true);

  //TBD
  const maxLeaveEachDayOptions: SelectOption[] = useMemo(() => {
    const blankOption = {
      name: strings.common.select,
      value: ''
    };

    return [blankOption];
  }, []);

  //TBD
  const shopTypeOptions: SelectOption[] = useMemo(() => {
    const blankOption = {
      name: strings.common.select,
      value: ''
    };

    return [blankOption];
  }, []);

  const role = useRole();

  const svSelectDisabled = role === RoleType.Manager || role === RoleType.Restaurant_Manager || role === RoleType.Staff;

  return (
    <div className="h-full flex flex-col">
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <LocationSelector />
          <Button
            className="bg-[#1BB55C] text-[white] hover:text-[white] hover:bg-[#1BB55C]"
            onClick={onSave}
            loading={fetching}
            variant={saveNeeded ? 'submit' : undefined}
            size="sm"
            disabled={saveNeeded ? undefined : true}
          >
            {strings.common.save}
          </Button>
        </div>
      </SettingsSectionHeader>
      <div className="overflow-x-auto">
        <SettingsTable
          columns={columns}
          showIndexColumn={false}
          className={`overflow-x-auto ${settings.length > 1 ? 'h-[90%]' : ''}`}
        >
          {settings.map((location, index) => (
            <React.Fragment key={location.location.id}>
              <SettingsTableCell>
                {location.details.companyCode || strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>
                {location.details.companyName || strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>
                {location.details.divisionName || strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>
                {location.details.brandName || strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>
                <SelectInput
                  readOnly={svSelectDisabled}
                  options={supervisorOptions}
                  value={location.settings.supervisorId}
                  setValue={onChangeSetting('supervisorId', index)}
                  className="w-full"
                />
              </SettingsTableCell>
              <SettingsTableCell>
                {location.details.perfecture || strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>{location.location.name}</SettingsTableCell>
              <SettingsTableCell>{`${location.location.id}`}</SettingsTableCell>
              <SettingsTableCell>
                {location.details.openingDate
                  ? format(location.details.openingDate, 'yyyy/MM/dd')
                  : strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>
                {location.details.closedDate
                  ? format(location.details.closedDate, 'yyyy/MM/dd')
                  : strings.settings.location.notDefined}
              </SettingsTableCell>
              <SettingsTableCell>
                <DateInput
                  value={date}
                  setValue={(newValue?: Date) => {
                    if (newValue) {
                      setDate(newValue);
                    } else {
                      console.log('Value undefined.');
                    }
                  }}
                />
              </SettingsTableCell>
              <SettingsTableCell>
                <SelectInput options={shiftRequestDeadlineOptions} value={''} setValue={() => {}} className="w-full" />
              </SettingsTableCell>
              <SettingsTableCell>
                <div className="w-full flex items-center justify-center">
                  <Toggle value={isToogle} onChange={() => setIsToogle(!isToogle)} />
                </div>
              </SettingsTableCell>
              <SettingsTableCell>
                <SelectInput options={maxLeaveEachDayOptions} value={''} setValue={() => {}} className="w-full" />
              </SettingsTableCell>
              <SettingsTableCell>
                <SelectInput options={shopTypeOptions} value={''} setValue={() => {}} className="w-full" />
              </SettingsTableCell>
            </React.Fragment>
          ))}
        </SettingsTable>
      </div>
    </div>
  );
};
