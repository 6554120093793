import { Button } from '@/components/button';
import { Modal, ModalProps } from '@/components/modal/Modal';
import { TaskType } from '@/services/tasks/task.types';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { FC, FunctionComponent } from 'react';
import { MdClose, MdWarning } from 'react-icons/md';
import { InUseTask } from './useValidateTasksInUse';

interface InUseTaskSectionProps {
  inUseTasks: InUseTask;
  infoMessageCallback: (taskName: string) => string;
  tasks: TaskType[];
}

const InUseTaskSection: FC<InUseTaskSectionProps> = ({ inUseTasks, infoMessageCallback, tasks }) => {
  return [...inUseTasks.entries()].map(([taskId, templates]) => {
    const task = tasks.find(t => t.id === taskId);
    if (!task) throw new Error('Task lookup failed');

    return (
      <div className="space-y-1" key={taskId}>
        <p>{infoMessageCallback(task.displayName)}</p>
        <div className="grid grid-cols-3">
          {templates.map((template, index) => (
            <p className="font-medium" key={`${task.id}-${template.id}-${index}`}>
              {template.location ? `${template.location.name} -` : ''} {template.name}
            </p>
          ))}
        </div>
      </div>
    );
  });
};

interface TasksSettingInUseTemplatesErrorModalProps extends ModalProps {
  inUseShopTemplates?: InUseTask;
  inUseLibraryTemplates?: InUseTask;
}

const TasksSettingInUseTemplatesErrorModal: FunctionComponent<TasksSettingInUseTemplatesErrorModalProps> = ({
  open,
  onClose,
  inUseShopTemplates,
  inUseLibraryTemplates
}) => {
  const tasks = useTasks();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-4">
        <div className="flex justify-between space-x-5">
          <div className="text-red-500 text-xl font-semibold flex items-center space-x-2">
            <MdWarning />
            <span>{strings.settings.manageTasks.errors.inUse}</span>
          </div>
          <Button variant={'ghost'} onClick={onClose}>
            <MdClose className="text-2xl" />
          </Button>
        </div>
        <div className="space-y-5 max-h-96 overflow-y-auto max-w-7xl">
          {inUseShopTemplates && (
            <InUseTaskSection
              inUseTasks={inUseShopTemplates}
              infoMessageCallback={(taskName: string) => strings.settings.manageTasks.errors.shopTemplate(taskName)}
              tasks={tasks}
            />
          )}
          {inUseLibraryTemplates && (
            <InUseTaskSection
              inUseTasks={inUseLibraryTemplates}
              infoMessageCallback={(taskName: string) => strings.settings.manageTasks.errors.libraryTemplate(taskName)}
              tasks={tasks}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TasksSettingInUseTemplatesErrorModal;
