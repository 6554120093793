
import { useGetTemplateById } from "@/pages/daily/template/shop/TemplateService";
import { strings } from "@/services/translation/strings";
import { FC } from "react";
import { Tooltip } from "./Tooltip";

interface Props {
  templateId: string;
  wrapperId: string;
}

const TemplateTooltipContent: FC<{ name: string; version?: string; }> = ({ name, version }) => {

  return (
    <div className="font-regular space-y-2 text-black">
      <div className="flex flex-col gap-1">
        <span>{strings.daily.roster.templates.tooltip.templateName(name)}</span>
        {version && <span>{strings.daily.roster.templates.tooltip.templateVersion(version)}</span>}
      </div>
    </div>
  );
};

export const TemplateTooltip: FC<Props> = ({ templateId, wrapperId }) => {
  const baseTemplate = useGetTemplateById(templateId);
  
  return baseTemplate &&
    <Tooltip>
      <TemplateTooltipContent name={baseTemplate.name} version={baseTemplate.version} />
    </Tooltip>;
};