import { secondsToTimeString } from '@/helpers/dateHelper';
import { Note, PdfRosterNotes } from '@/pages/pdf/roster/PdfRosterNotes';
import { PdfParams } from '@/pages/pdf/summary/PdfSummaryDay';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { BudgetStats, WorkingBudgetLocation } from '@/services/budget/budget.types';
import { strings } from '@/services/translation/strings';
import { Document, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { PdfPage } from '../../PdfPage';
import { styles } from '../../PdfStyles';
import { PdfTableCell } from '../../PdfTableCell';
import { PdfBudgetTable } from '../PdfBudgetTable';
import { PdfDateRange } from '../PdfDateRange';
import { PdfWeeklyEmployeeRow } from './PdfWeeklyEmployeeRow';

interface Props {
  days: Date[];
  summary: SummaryEmployee[];
  notes: Note[];
  budgetData: WorkingBudgetLocation;
  budgetStats: BudgetStats;
  params: PdfParams;
}

export const WEEKLY_NAME_WIDTH = 120;
export const WEEKLY_TOTAL_WIDTH = 40;
const WEEKLY_NOTE_DATE_WIDTH = 120;
const WEEKLY_NOTE_CONTENT_WIDTH = 400;

const WEEKLY_DATE_CELL_WIDTH_OVERRIDE = 50;

const FIRST_PAGE_LENGTH = 20;
const OTHER_PAGE_LENGTH = 23;

export const WeeklyReportAsPDF: FC<Props> = ({
  days,
  summary,
  notes,
  budgetData,
  budgetStats,
  params
}) => {
  const pages: SummaryEmployee[][] = useMemo(() => {
    const pages: SummaryEmployee[][] = [];

    const rowsToAdd = [...summary];

    const firstPage = rowsToAdd.splice(0, FIRST_PAGE_LENGTH);
    pages.push(firstPage);

    while (rowsToAdd.length > 0) {
      const pageToAdd = rowsToAdd.splice(0, OTHER_PAGE_LENGTH);
      pages.push(pageToAdd);
    }

    return pages;
  }, [summary]);

  const date = new Date();
  const title = strings.summary.print.weekly.fileTitle(format(days[0], 'yyyy/MM/dd'));

  return (
    <Document>
      <PdfPage datePrinted={date} headerContent={title} orientation={'portrait'}>
        <View style={styles.table}>
          <PdfBudgetTable
            days={days}
            budgetData={budgetData}
            budgetStats={budgetStats}
            nameWidth={WEEKLY_NAME_WIDTH}
            totalWidth={WEEKLY_TOTAL_WIDTH}
            notes={notes}
            params={params}
            dateCellWidthOverride={WEEKLY_DATE_CELL_WIDTH_OVERRIDE}
          ></PdfBudgetTable>
          <View style={styles.tableRow}>
            <PdfTableCell width={WEEKLY_NAME_WIDTH} height={20} />
            <PdfTableCell width={WEEKLY_TOTAL_WIDTH}>
              <Text style={styles.tableCell}>{strings.summary.weekly}</Text>
            </PdfTableCell>
          </View>
          <View style={styles.tableRow}>
            <PdfTableCell width={WEEKLY_NAME_WIDTH} height={20}>
              <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.summary.employee}</Text>
            </PdfTableCell>
            <PdfTableCell width={WEEKLY_TOTAL_WIDTH}>
              <Text style={styles.tableCell}>{secondsToTimeString(budgetStats.totalRostered)}</Text>
            </PdfTableCell>
          </View>
          {pages[0].map((employee: SummaryEmployee) => (
            <PdfWeeklyEmployeeRow
              key={employee.id}
              days={days}
              employee={employee}
              params={params}
            />
          ))}
        </View>
        {pages.length === 1 /* lastPage? */ && (
          <PdfRosterNotes notes={notes} dateWidth={WEEKLY_NOTE_DATE_WIDTH} contentWidth={WEEKLY_NOTE_CONTENT_WIDTH} />
        )}
      </PdfPage>
      {pages.slice(1).map((page, index) => (
        <PdfPage key={index} datePrinted={date} headerContent={title} orientation={'portrait'}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <PdfTableCell width={WEEKLY_NAME_WIDTH}>
                <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.summary.employee}</Text>
              </PdfTableCell>
              <PdfTableCell width={WEEKLY_TOTAL_WIDTH}>
                <Text style={styles.tableCell}>{strings.summary.weekly}</Text>
              </PdfTableCell>
              <PdfDateRange days={days} notes={notes} dateWidthOverride={WEEKLY_DATE_CELL_WIDTH_OVERRIDE} />
            </View>
            {page.map((employee: SummaryEmployee) => (
              <PdfWeeklyEmployeeRow
                key={employee.id}
                days={days}
                employee={employee}
                params={params}
              />
            ))}
          </View>
          {index == pages.length - 2 /* lastPage? */ && (
            <PdfRosterNotes notes={notes} dateWidth={WEEKLY_NOTE_DATE_WIDTH} contentWidth={WEEKLY_NOTE_CONTENT_WIDTH} />
          )}
        </PdfPage>
      ))}
    </Document>
  );
};
