import { useMemo } from 'react';
import { strings } from '../translation/strings';

export const useHolidayRegions = () => {
  return useMemo(() => {
    return [
      { name: strings.settings.system.roster.settings.holidayRegion.options.none, value: '' },
      { name: strings.settings.system.roster.settings.holidayRegion.options.japan, value: 'JPN' }
    ];
  }, []);
};
