import { convertBlobToJson, useBlobService } from '@/services/blob/useBlobService';
import { useVariable } from '@softwareimaging/backstage';

const CONTAINER_NAME = 'settings';

interface OptimizerSettings {
  islandStrategy: {
    eras: Era[];
  };
  engineParameters: {
    managementAttendance: boolean;
  }
}

interface Era {
  durationSeconds: number;
}

const updateEras = (entries: Era[], duration: number) => {
  entries.forEach(item => {
    item.durationSeconds = duration;
  });
};

export const useUpdateOptimizerSettings = () => {
  const optimizerBlobName = useVariable('optimizerBlobName');
  if (!optimizerBlobName) throw new Error('Missing optimizer blob name');

  const blobServiceClient = useBlobService();

  const update = async (duration: number, managementAttendance: boolean) => {
    const container = blobServiceClient.getContainerClient(CONTAINER_NAME);
    const blob = container.getBlobClient(optimizerBlobName);
    const data = await blob.download();
    const json = (await convertBlobToJson(data)) as OptimizerSettings;

    updateEras(json.islandStrategy.eras, duration);
    json.engineParameters.managementAttendance = managementAttendance;

    const newBlob = new Blob([JSON.stringify(json)], { type: 'text/plain;charset=utf-8' });
    container.uploadBlockBlob(optimizerBlobName, newBlob, newBlob.size);
  };

  return update;
};
