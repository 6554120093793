import { Button } from '@/components/button';
import { AddButton } from '@/components/buttons/AddButton';
import { RadioFilterSelector } from '@/components/flterSelector/RadioFilterSelector';
import { useSaveAnnouncements } from '@/services/announcements/useAnnouncements';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { FC, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { SettingsTable } from '../../layout/SettingsTable';
import { Announcement, FilterOptions } from './Announcement';
import { useAnnouncementColumns } from './useAnnouncementColumns';
import { useAnnouncementList } from './useAnnouncementList';
import { useFilterOptions } from './useFilterOptions';

// TODO: add the setting to the backstage settings
export const maxAllowedAnnouncements = 5;

export const AnnouncementsSettings: FC = () => {
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOptions>(FilterOptions.ALL);
  const filterOptions = useFilterOptions();

  const { announcements, setAnnouncements } = useAnnouncementList(selectedFilterOption);

  const [announcementIdsToDelete, setAnnouncementsIdsToDelete] = useState<string[]>([]);

  const { save } = useSaveAnnouncements();

  const deleteAnnouncementEnabled = useFlag('deleteAnnouncements');
  const columns = useAnnouncementColumns();

  const { registerForConfirmation, saveNeeded } = useConfirm(state => ({
    registerForConfirmation: state.registerForConfirmation,
    saveNeeded: state.confirmationNeeded
  }));

  const addAnnouncement = useCallback(() => {
    let newAnnouncementTitle = `${strings.settings.manageAnnouncements.announcement} ${announcements.length + 1}`;
    const similarTitleAnnouncements = announcements.filter(s => s.title === newAnnouncementTitle);
    if (similarTitleAnnouncements.length) {
      newAnnouncementTitle += `-${similarTitleAnnouncements.length}`;
    }

    const newAnnouncement: Announcement = {
      id: `${uuidv4()}`,
      title: newAnnouncementTitle,
      text: 'New announcement',
      isActive: true,
      showAtDate: undefined,
      displayDate: new Date()
    };

    const newAnnouncements = [...announcements, newAnnouncement];
    setAnnouncements(newAnnouncements);

    registerForConfirmation(strings.settings.manageAnnouncements.unsavedChanges);
  }, [announcements]);

  const onAnnouncementUpdate = (announcement: Announcement) => {
    const index = announcements.findIndex(a => a.id === announcement.id);

    if (index < 0) {
      throw Error('Unable to find announcement to update');
    }

    const newAnnouncements = [...announcements];
    newAnnouncements.splice(index, 1, announcement);
    setAnnouncements(newAnnouncements);
    
    registerForConfirmation(strings.settings.manageAnnouncements.unsavedChanges);
  };

  const onAnnouncementDelete = (announcementId: string, isToBeDeleted: boolean) => {
    const announcementIdsToBeDeleted = [...announcementIdsToDelete];
    const deleteAnnouncementIndex = announcementIdsToBeDeleted.indexOf(announcementId);

    if (deleteAnnouncementIndex === -1 && isToBeDeleted) {
      announcementIdsToBeDeleted.push(announcementId);
    } else if (deleteAnnouncementIndex >= 0 && !isToBeDeleted) {
      announcementIdsToBeDeleted.splice(deleteAnnouncementIndex, 1);
    }

    setAnnouncementsIdsToDelete(announcementIdsToBeDeleted);
    
    registerForConfirmation(strings.settings.manageAnnouncements.unsavedChanges);
  };

  const onSave = async () => {
    if (announcements.length) {
      const arrayToSave = announcements.filter(a => !announcementIdsToDelete.includes(a.id));

      try {
        await save(arrayToSave);
        setAnnouncements(arrayToSave);
        setAnnouncementsIdsToDelete([]);
      } catch (error) {
        console.log('Save announcements error', error);
      }
    }
  };

  return (
    <>
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <AddButton
              label={strings.settings.manageAnnouncements.announcement}
              onButtonClick={addAnnouncement}
              readOnly={!!(maxAllowedAnnouncements && announcements.length >= maxAllowedAnnouncements)}
            />
            <RadioFilterSelector
              options={filterOptions}
              selectedOption={selectedFilterOption}
              onSelect={setSelectedFilterOption}
            />
          </div>
          <Button
            className="bg-[#1BB55C] text-[white] hover:text-[white] hover:bg-[#1BB55C]"
            onClick={onSave}
            variant={
              saveNeeded
                ? 'submit'
                : undefined
            }
            size="sm"
            disabled={
              !saveNeeded
            }
          >
            {strings.common.save}
          </Button>
        </div>
      </SettingsSectionHeader>
      <SettingsTable columns={columns}>
        {announcements.length > 0 &&
          announcements.map((announcement, index) => (
            <Announcement
              key={announcement.id}
              announcement={announcement}
              index={index}
              showIndexColumn={false}
              deleteAnnouncementEnabled={deleteAnnouncementEnabled}
              isToBeDeleted={announcementIdsToDelete.includes(announcement.id)}
              onAnnouncementUpdate={onAnnouncementUpdate}
              onAnnouncementDelete={onAnnouncementDelete}
            />
          ))}

        {!announcements.length && (
          <div className="flex items-center justify-center col-span-full p-10 text-xl text-[#32323280] font-medium">
            {strings.settings.manageAnnouncements.emptyAnnouncementsList}
          </div>
        )}
      </SettingsTable>
    </>
  );
};
