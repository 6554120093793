import { dateToApiFormat } from '@/helpers/dateHelper';
import { SummaryDayStats } from '../summary/buildSummaryDayStats';
import { BudgetStats, WorkingBudgetLocation } from './budget.types';

export const buildBaseBudgetStats = (days: Date[], budgetData: WorkingBudgetLocation, dayToExclude?: Date) => {
  const stats: BudgetStats = {
    totalHq: 0,
    totalRostered: 0,
    monthlyDelta: 0,
    totalLocationForecast: 0,
    rosteredBudget: 0,
    totalFilledShifts: 0,
    totalShifts: 0
  };

  days.forEach(day => {
    const budgetDay = budgetData.days.find(budgetDay => dateToApiFormat(budgetDay.date) === dateToApiFormat(day));

    if (budgetDay) {
      stats.totalHq += budgetDay.hqAllocatedHours;
      stats.totalRostered += budgetDay.rosteredBudget;
      stats.totalLocationForecast += budgetDay.locationForecast;
      stats.totalShifts += budgetDay.totalShiftCount;
      stats.totalFilledShifts += budgetDay.filledShiftCount;
    }
  });

  if (dayToExclude) {
    const day = budgetData.days.find(day => dateToApiFormat(day.date) === dateToApiFormat(dayToExclude));

    if (!day) {
      throw Error('Could not find budget data for given day');
    }

    // Remove the rostered budget from the total- that will need to be added based on the working roster.
    stats.totalRostered -= day.rosteredBudget;
  }

  stats.monthlyDelta = stats.totalRostered - stats.totalLocationForecast;

  return stats;
};

export const applyRosteredStatsToBudget = (baseStats: BudgetStats, rosterStats: SummaryDayStats) => {
  const stats: BudgetStats = { ...baseStats };

  stats.rosteredBudget = rosterStats.actualTotal;
  stats.totalRostered += stats.rosteredBudget;
  stats.monthlyDelta = stats.totalRostered - stats.totalLocationForecast;

  return stats;
};
