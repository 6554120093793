import { useCallback } from 'react';

const useCSVDownload = () => {
  return useCallback((filename: string, csvString: string) => {
    try {
      const BOM = '\uFEFF';
      const blob = new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  }, []);
};

export default useCSVDownload;
