import { Button } from '@/components/button';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { strings } from '@/services/translation/strings';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {FiArrowRight, FiDownload, FiX} from 'react-icons/fi';
import {Modal} from "@/components/modal/Modal";
import {SelectInput, SelectOption} from "@/components/form/SelectInput";
import {DateInput} from "@/components/form/DateInput";
import toast from "react-hot-toast";
import {
  useDownloadShiftSummaryEndpoint,
  useDownloadTaskSummaryEndpoint
} from "@/pages/summary/download/useDownloadSummaryEndpoint";

export enum SummaryDataType {
  SHIFT = 'SHIFT',
  TASK = 'TASK'
}

export const SummaryExcelDownloadButton: FC = () => {
  const days = useDaysInCurrentMonth();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [summaryDataType, setSummaryDataType] = useState<SummaryDataType>(SummaryDataType.SHIFT);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  useEffect(() => {
    setStartDate(days[0]);
    setEndDate(days[days.length - 1]);
  }, [setStartDate, setEndDate, days]);

  const onError = useCallback(() => {
    toast(strings.common.error, {
      position: 'top-right',
      className: 'text-xl',
      icon: <FiX className="h-8 w-8" />
    });
  }, []);

  const { downloadShiftSummary } = useDownloadShiftSummaryEndpoint(onError);
  const { downloadTaskSummary } = useDownloadTaskSummaryEndpoint(onError);

  const onSave = useCallback(async () => {
    if (startDate && endDate) {
      setModalOpen(false);

      if (summaryDataType === SummaryDataType.SHIFT) {
        await downloadShiftSummary(startDate, endDate);
      } else if (summaryDataType === SummaryDataType.TASK) {
        await downloadTaskSummary(startDate, endDate);
      }
    }
  }, [startDate, endDate, setModalOpen, summaryDataType, downloadShiftSummary, downloadTaskSummary]);

  const onClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen, setStartDate, setEndDate]);

  const options: SelectOption[] = useMemo(() => {
    return [{
      name: strings.common.file.downloadSummaries.options.shifts,
      value: SummaryDataType.SHIFT
    }, {
      name: strings.common.file.downloadSummaries.options.tasks,
      value: SummaryDataType.TASK
    }];
  }, []);

  return (
    <div>
      <Button onClick={() => setModalOpen(true)}>
        <FiDownload className="mr-2" />
        {strings.common.file.downloadShifts}
      </Button>
      <Modal open={modalOpen}
             onClose={() => setModalOpen(false)}
             title={strings.common.file.downloadSummaries.title}>
        <div className="space-y-4 max-w-2xl w-screen">
          <SelectInput options={options} value={summaryDataType} setValue={setSummaryDataType} className="w-full" />
          <div className="flex flex-row">
            <DateInput
              className="flex-1"
              value={startDate}
              setValue={setStartDate}
            />
            <FiArrowRight className="w-5 ml-2 mr-2 h-auto flex items-center justify-center" />
            <DateInput
              className="flex-1"
              value={endDate}
              setValue={setEndDate}
            />
          </div>
          <div className="flex justify-end gap-5 items-center">
            <Button onClick={onClose}>{strings.common.cancel}</Button>
            <Button onClick={onSave}>{strings.common.save}</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
