import { dateToApiFormat } from '@/helpers/dateHelper';
import { DayOfWeekAndHoliday } from '@/services/holiday/holiday.types';
import { useGetDayOfWeekAndHoliday, usePublicHoliday } from '@/services/holiday/usePublicHolidays';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { FC, ReactElement } from 'react';
import { FiInfo } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';

export interface DateCellTooltipType {
  date: Date;
  content: ReactElement;
  hasNotes?: boolean;
}

interface DateCellHeadersProps {
  days: Date[];
  onClick?: (day: Date) => void;
  tooltips?: DateCellTooltipType[];
}

const DateCell: FC<{ day: Date; onClick?: () => void; tooltip?: DateCellTooltipType }> = ({
  day,
  onClick,
  tooltip
}) => {
  let colorClass = '';
  const publicHoliday = usePublicHoliday(day);
  const getDayOfWeekAndHoliday = useGetDayOfWeekAndHoliday(day);
  const dayOfWeekAndHoliday = getDayOfWeekAndHoliday(day);

  if (dayOfWeekAndHoliday === DayOfWeekAndHoliday.SUNDAY || dayOfWeekAndHoliday === DayOfWeekAndHoliday.HOLIDAY) {
    colorClass = 'text-secondary';
  } else if (dayOfWeekAndHoliday === DayOfWeekAndHoliday.SATURDAY) {
    colorClass = 'text-primary';
  }

  return (
    <div
      key={day.toString()}
      className={twMerge(
        colorClass,
        'flex-1 border-l flex flex-col items-center justify-center relative group',
        onClick ? 'hover:bg-active cursor-pointer' : ''
      )}
      onClick={onClick}
    >
      <p className={twMerge('font-semibold', tooltip?.hasNotes && 'bg-gray-300 px-2')}>{`${day.getDate()}`}</p>
      <p>{strings.common.daysOfWeek[day.getDay()]}</p>
      {(publicHoliday || tooltip?.content) && (
        <>
          <div
            className={twMerge(
              'absolute hidden group-hover:flex z-50 top-[105%] right-0 bg-white border rounded-sm ',
              'text-black p-2 w-[200px] text-sm',
              'flex-col gap-1'
            )}
          >
            <div className="font-semibold">{format(day, 'yyyy/MM/dd')}</div>
            {publicHoliday && <div>{publicHoliday.name}</div>}
            {tooltip?.content}
          </div>
          <FiInfo className="h-3 w-3 absolute top-0.5 right-0.5" />
        </>
      )}
    </div>
  );
};

export const DateCellHeaders: FC<DateCellHeadersProps> = ({ days, onClick, tooltips }) => {
  return (
    <>
      {days.map(day => {
        const tooltip = tooltips?.find(t => dateToApiFormat(t.date) === dateToApiFormat(day));

        return (
          <DateCell
            day={day}
            tooltip={tooltip}
            onClick={onClick ? () => onClick(day) : undefined}
            key={day.toISOString()}
          />
        );
      })}
    </>
  );
};
