import { dateToApiFormat } from '@/helpers/dateHelper';
import { SummaryDay, SummaryEmployee } from '../summary/summary.types';
import { WorkingShift } from '@/pages/daily/daily.types';
import { calculateShiftTime } from './rosterHelper';
import { TaskType } from '../tasks/task.types';
import { buildComplianceDataForEmployee } from '../summary/buildCompliance';

/*
 * WHAT THIS ACTUALLY DOES
 *
 * We have a problem in SNPS shift in that the summary is used everywhere, to display things like totals.
 * Building the summary is a bit of a pain- there's a lot of data to go through. We don't want to have to
 * build it from scratch each time.
 *
 * When we're editing a roster, we want to be able to show the stats updating as changes are made. But we
 * want the stats to be reliable- ideally, straight from the summary. But we don't want to do a full rebuild
 * each time a single shift changes.
 *
 * This function tries to alleviate that. We take a base version of the summary, which is based on the database
 * data. Then, we return a builder function, which takes the shifts from the roster. It then builds a new version
 * of the summary, replacing the old shift data with the new list, and updates any stats which have been affected.
 */
export const applyShiftsToSummary =
  (summary: SummaryEmployee[], date: Date, tasks: TaskType[], includeBreaks?: boolean) => (shifts: WorkingShift[]) => {
    return summary.map(employee => {
      const workingShifts = shifts.filter(s => s.assignedEmployeeId === employee.id);

      if (workingShifts.length === 0) {
        return employee;
      }

      const newSchedule = [...employee.schedule];
      const schedDayIndex = newSchedule.findIndex(s => s.date === dateToApiFormat(date));

      if (!(schedDayIndex >= 0)) {
        throw Error('Could not find schedule day index');
      }

      const newScheduleDay: SummaryDay = {
        ...newSchedule[schedDayIndex],
        shifts: [],
        actualTime: 0,
        breakTime: 0,
        basicTime: 0
      };
      newSchedule.splice(schedDayIndex, 1, newScheduleDay);

      if (!newScheduleDay.os) {
        workingShifts.forEach(s => {
          if (typeof s.start !== 'number' || typeof s.end !== 'number') {
            return;
          }

          const totals = calculateShiftTime(s, tasks, includeBreaks);

          newScheduleDay.shifts.push({
            locationId: s.locationId?.toString() || '',
            locationName: '',
            start: s.start,
            end: s.end,
            breakLength: totals.breakTime,
            uncountedTaskLength: totals.uncountedTaskTime,
            actualTime: totals.actualTime,
            basicTime: totals.basicTime
          });

          newScheduleDay.actualTime += totals.actualTime;
          newScheduleDay.basicTime += totals.basicTime;
          newScheduleDay.breakTime += totals.breakTime;
        });
      }

      const newEmployee: SummaryEmployee = {
        ...employee,
        compliance: buildComplianceDataForEmployee(newSchedule),
        schedule: newSchedule
      };

      return newEmployee;
    });
  };
