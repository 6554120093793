import { FC } from 'react';
import { strings } from '@/services/translation/strings';

export enum FilterOptions {
  ALL = 'ALL',
  ROSTER_GENERATION = 'ROSTER_GENERATION',
  ROSTER_PUBLISH = 'ROSTER_PUBLISH'
}

interface Props {
  selectedOption: string;
  onSelect: (event: any) => void;
}

export const NotificationsRadioButtonsFilter: FC<Props> = ({ selectedOption, onSelect }) => {
  return (
    <div className="flex gap-1 content-center items-center text-[#323232] px-5">
      <div className="flex gap-2.5 items-center">
        <input
          className="w-4 h-4 text-[#32323280] bg-white border-[#32323280] focus:ring-1 focus:ring-[#32323280]"
          type="radio"
          value={FilterOptions.ALL}
          checked={selectedOption === FilterOptions.ALL}
          onChange={onSelect}
        />
        <span>{strings.notifications.filter.all}</span>
      </div>
      <div className="flex gap-2.5 items-center px-2.5 py-1.5">
        <input
          className="w-4 h-4 text-[#32323280] bg-white border-[#32323280] focus:ring-1 focus:ring-[#32323280]"
          type="radio"
          value={FilterOptions.ROSTER_GENERATION}
          checked={selectedOption === FilterOptions.ROSTER_GENERATION}
          onChange={onSelect}
        />
        {strings.notifications.filter.roster_generation}
      </div>
      <div className="flex gap-2.5 items-center px-2.5 py-1.5">
        <input
          className="w-4 h-4 text-[#32323280] bg-white border-[#32323280] focus:ring-1 focus:ring-[#32323280]"
          type="radio"
          value={FilterOptions.ROSTER_PUBLISH}
          checked={selectedOption === FilterOptions.ROSTER_PUBLISH}
          onChange={onSelect}
        />
        {strings.notifications.filter.roster_publish}
      </div>
    </div>
  );
};
