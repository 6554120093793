import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { dateToApiFormat } from '@/helpers/dateHelper';
import { useJobToNotification } from './optimiserHelper';
import { useNotificationService } from '../notifications/NotificationService';
import { OptimiserJob, OptimiserStatus } from './optimiser.types';

export interface OptimiserStore {
  jobs: OptimiserJob[];
  startJob: (newJob: OptimiserJob) => void;
  completeJob: (batchId: string, status: OptimiserStatus) => void;
  errorJob: (locationId: number, startDate: Date, error: string) => void;
}

export const OptimiserContext = createContext<StoreApi<OptimiserStore> | null>(null);

export const OptimiserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { createNotification, updateNotification } = useNotificationService(state => ({
    createNotification: state.createNotification,
    updateNotification: state.updateNotification
  }));

  const jobToNotification = useJobToNotification();

  const store = useMemo(() => {
    return createStore<OptimiserStore>()((set, get) => ({
      jobs: [],
      startJob: (newJob: OptimiserJob) => {
        const jobs = get().jobs;

        const jobInQueue = jobs.find(job => job.batchId === newJob.batchId);

        if (jobInQueue) {
          jobInQueue.complete = false;
          jobInQueue.error = undefined;
          jobInQueue.status = newJob.status;
          jobInQueue.started = newJob.started;

          updateNotification(jobToNotification(jobInQueue));
        } else {
          newJob.created = new Date();
          jobs.push(newJob);

          const notification = createNotification(jobToNotification(newJob));
          newJob.associatedNotificationId = notification.id;
        }

        set({ jobs: [...jobs] });
      },
      completeJob: (batchId: string, status: OptimiserStatus) => {
        const jobList = get().jobs;

        const job = jobList.find(j => j.batchId === batchId);

        if (job) {
          job.status = status;
          if (status === OptimiserStatus.FINISHED) {
            job.complete = true;
          }
          set({ jobs: [...jobList] });

          const notification = jobToNotification(job);
          updateNotification(notification);
        }
      },
      errorJob: (locationId: number, startDate: Date, error: string) => {
        const jobList = get().jobs;

        const job = jobList.find(
          j => j.locationId === locationId && dateToApiFormat(j.startDate) === dateToApiFormat(startDate) && !j.error
        );

        if (job) {
          job.error = error;
          job.complete = true;
          set({ jobs: [...jobList] });

          const notification = jobToNotification(job);
          updateNotification(notification);
        }
      }
    }));
  }, [createNotification, updateNotification]);

  return <OptimiserContext.Provider value={store}>{children}</OptimiserContext.Provider>;
};

type SelectorReturn<S extends (s: OptimiserStore) => any> = ReturnType<S>;
export function useOptimiserService<S extends (s: OptimiserStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(OptimiserContext);
  if (!context) {
    throw new Error('useOptimiser must be used within a OptimiserProvider');
  }
  return useStore(context, selector);
}
