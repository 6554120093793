import { WarningsList } from '@/components/warning/WarningsList';
import { WarningPopup } from '@/components/warning/WarningPopup';
import { StaffAvailabilityDay, StaffDayTimes } from '@/services/availability/useStaffAvailability';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { displayTimes } from './manageAvailabilityHelper';
import { validateShift } from './useValidateAvailability';

interface Props {
  selectedDay: StaffAvailabilityDay;
  times: StaffDayTimes[];
}

interface ShiftProps {
  shift: StaffDayTimes;
  times: StaffDayTimes[];
}

const AvailabilityDetailsRosteredShift: FC<ShiftProps> = ({ shift, times }) => {
  const warning = validateShift(shift, times);

  return (
    <div className="flex gap-2 items-center">
      <div>{displayTimes(shift)}</div>
      {warning && (
        <WarningPopup
          severity={warning.severity}
          parentContainerId={'availability-details'}
        >
          <WarningsList
            warnings={[warning]}
          />
        </WarningPopup>
      )}
    </div>
  );
};

export const AvailabilityDetailsRostered: FC<Props> = ({ selectedDay, times }) => {
  return (
    <div className="flex-1">
      <div className="mb-1 text-gray-500">{strings.settings.manageAvailability.rosteredHours}</div>
      {selectedDay.rosteredShifts?.map((shift, index) => (
        <AvailabilityDetailsRosteredShift key={index} shift={shift} times={times} />
      )) || displayTimes()}
    </div>
  );
};
