import { useGetOrganisationHierarchyQuery } from '../gql/graphql.generated';

export const useOrganisationHierarchy = () => {
  const [{ data }] = useGetOrganisationHierarchyQuery();
  const organisationHierarchy = data?.getOrganisationHierarchy;

  if (!organisationHierarchy) {
    throw Error('The organisation hierarchy is missing!');
  }

  return organisationHierarchy;
};
