import { useBudgetForLocation } from '@/services/budget/useBudget';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useMemo } from 'react';
import { SummaryDayStats } from '../summary/buildSummaryDayStats';
import { applyRosteredStatsToBudget, buildBaseBudgetStats } from './buildBudgetStats';

export const useBudgetStats = (days: Date[], workingRosterStats?: SummaryDayStats) => {
  const selectedDate = useLocalSettings(state => state.selectedDate);
  const { budgetData } = useBudgetForLocation(days);

  const hasWorkingData = !!workingRosterStats;

  // First, get the stats based on just the budget data.
  const baseStats = useMemo(
    () => buildBaseBudgetStats(days, budgetData, hasWorkingData ? new Date(selectedDate) : undefined),
    [budgetData, selectedDate, hasWorkingData, days]
  );

  // Now add the working roster data to the base stats.
  // We do this because calculating the month's totals is expensive, and we don't want to have to do
  // it every time the roster changes.
  return useMemo(
    () => (workingRosterStats ? applyRosteredStatsToBudget(baseStats, workingRosterStats) : baseStats),
    [baseStats, workingRosterStats]
  );
};
