import { useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { useUpdatableTemplate } from './templateHelper';
import { TemplateTag } from '@/services/rosters/useTemplateTags';

export const useEditTemplateDetails = (templateId: string) => {
  const [{ fetching, error }, update] = useUpdateTemplateMutation();
  const template = useUpdatableTemplate(templateId);

  const updateTemplateName = useCallback(
    async (newName: string, newTags: TemplateTag[]) => {
      if (!template) {
        throw Error('Template must be defined');
      }

      template.name = newName;

      const result = await update({
        updateTemplate: { ...template, name: newName, templateTags: newTags.map(t => ({ label: t.label, templateId })) }
      });

      return result;
    },
    [update, template]
  );

  return { updateTemplateName, error, fetching };
};
