import { Button } from '@/components/button';
import useCSVDownload from '@/services/csv/useDownloadCSV';
import {
  NotificationStatus,
  NotificationType,
  useNotificationService
} from '@/services/notifications/NotificationService';
import { useDownloadStaffSkills } from '@/services/rest/hooks/download';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { useMyLocations } from '@/services/useLocations';
import { format } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';
import toast from 'react-hot-toast';
import { FiDownload } from 'react-icons/fi';
import uuid from 'react-uuid';

const StaffSkillsExportButton: FunctionComponent = () => {
  const download = useDownloadStaffSkills();

  const selectedLocationId = useLocalSettings(state => state.selectedLocationId);
  const locations = useMyLocations();
  const createNotification = useNotificationService(s => s.createNotification);

  const location = useMemo(() => {
    const loc = locations.find(l => l.id === selectedLocationId);
    if (!loc) throw new Error('location lookup failed');

    return loc;
  }, [locations, selectedLocationId]);

  const downloadCSV = useCSVDownload();

  const onDownload = async () => {
    const response = await download.mutateAsync({
      locationIds: [location.id]
    });

    if (!response) {
      toast('No data available to export');
      return;
    }

    const timestamp = format(new Date(), 'yyyy-MM-dd');
    const filename = `${location.id}_${location.name}_${timestamp}_StaffSkills.csv`;
    downloadCSV(filename, response);

    createNotification({
      regularText: strings.settings.manageSkills.export.success,
      id: uuid(),
      type: NotificationType.OTHER,
      status: NotificationStatus.SUCCESS,
      date: new Date(),
      locationId: selectedLocationId
    });
  };

  return (
    <Button onClick={onDownload}>
      <FiDownload className="mr-2" />
      {strings.common.export}
    </Button>
  );
};

export default StaffSkillsExportButton;
