import { WorkingShift } from "@/pages/daily/daily.types";
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useCallback } from 'react';
import { EmployeeForShiftItem, EmployeeStatus } from './employeeForShift.types';

const fixTapNumberForSorting = (tapNumber?: number) => {
  // First, we want any employees with no leave type (ie. put available employees at the top.)
  if (tapNumber === undefined) {
    return -2;
  }

  // Next- we need to push any employees with a weird leave type to the bottom.
  // Tap number -1 means the type is no longer active.
  if (tapNumber === -1) {
    return 1000000;
  }

  // Then just use tap number order.
  return tapNumber;
};

export const useSortEmployees = () => {
  const leaveTypes = useLeaveTypes();
  return useCallback(
    (employees: EmployeeForShiftItem[], shift: WorkingShift) => {

      employees.sort((a, b) => {
        //First- put the assigned staff member at the top.
        if (a.id === shift.assignedEmployeeId) {
          return -1;
        }

        if (b.id === shift.assignedEmployeeId) {
          return 1;
        }

        //Any assignable staff must come before unassignable.
        if (a.assignable && !b.assignable) {
          return -1;
        }

        if (!a.assignable && b.assignable) {
          return 1;
        }

        // Unavailable staff got to back of list
        const statusDiff =
          Object.values(EmployeeStatus).indexOf(a.status!) - Object.values(EmployeeStatus).indexOf(b.status!);

        if (statusDiff !== 0) {
          return statusDiff;
        }

        // Unallocated staff come before allocated.
        if (a.allocated !== undefined && b.allocated !== undefined && a.allocated !== b.allocated) {
          return a.allocated - b.allocated;
        }

        const leaveTypeA = leaveTypes.find(lt => lt.id == a.leaveType);
        const leaveTypeB = leaveTypes.find(lt => lt.id == b.leaveType);
        const tapNumberA = fixTapNumberForSorting(leaveTypeA?.properties.tapNumber);
        const tapNumberB = fixTapNumberForSorting(leaveTypeB?.properties.tapNumber);

        const availabilityDiff = tapNumberA - tapNumberB;
        if (availabilityDiff !== 0) {
          return availabilityDiff;
        }

        if (a.taskMatch?.length !== b.taskMatch?.length) {
          return b.taskMatch!.length - a.taskMatch!.length;
        }

        return a.name! > b.name! ? 1 : -1;
      });

      return employees;
    },
    [leaveTypes]
  );
};
