import { Button } from '@/components/button';
import { DurationInput } from '@/components/form/DurationInput';
import { SelectInput } from '@/components/form/SelectInput';
import { RosteredLocation } from '@/pages/settings/sections/manageAvailability/RosteredLocation';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { useAvailabilityTypes } from '@/services/availability/useAvailabilityTypes';
import {
  StaffAvailabilityDay,
  StaffAvailabilityMonth,
  StaffDayTimes
} from '@/services/availability/useStaffAvailability';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { format, hoursToSeconds } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { useSaveAvailability } from '../../services/useSaveAvailability';
import { AvailabilityDetailsLeaveForPeriod } from './AvailabilityDetailsLeaveForPeriod';
import { AvailabilityDetailsPattern } from './AvailabilityDetailsPattern';
import { AvailabilityDetailsRostered } from './AvailabilityDetailsRostered';
import { useValidateDay } from './useValidateAvailability';
import { OperationResult } from 'urql';
import { FiPlus, FiX } from 'react-icons/fi';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';

interface Props {
  selectedDay: StaffAvailabilityDay;
  selectedMonth: StaffAvailabilityMonth;
  onComplete: () => void;
}

export const AvailabilityDetails: FC<Props> = ({ selectedDay, selectedMonth, onComplete }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const employee = selectedMonth?.employee;

  const defaultStart = selectedDay.pattern?.start || 0;
  const defaultEnd = selectedDay.pattern?.end || 0;

  const [availableTimes, setAvailableTimes] = useState<StaffDayTimes[]>(() => {
    const availableTimes: StaffDayTimes[] = [];

    if (selectedDay.preferences.length > 0) {
      selectedDay.preferences.forEach(pref => availableTimes.push({ start: pref.start, end: pref.end }));
    } else {
      selectedDay.leaveTypeId ?
        availableTimes.push({ start: 0, end: 0 }) : availableTimes.push({ start: defaultStart, end: defaultEnd });
    }

    availableTimes.sort((a, b) => a.start - b.start);

    return availableTimes;
  });

  const onStartChange = (index: number) => (newStart: number) => {
    const newTimes = [...availableTimes];

    newTimes[index].start = newStart;

    setAvailableTimes(newTimes);
    setSaveNeeded(true);
  };

  const onEndChange = (index: number) => (newEnd: number) => {
    const newTimes = [...availableTimes];

    newTimes[index].end = newEnd;

    setAvailableTimes(newTimes);
    setSaveNeeded(true);
  };

  const onDelete = (index: number) => () => {
    const newTimes = [...availableTimes];
    newTimes.splice(index, 1);

    setAvailableTimes(newTimes);
    setSaveNeeded(true);
  };

  const onAdd = () => {
    const newTimes = [...availableTimes];
    newTimes.push({ start: defaultStart, end: defaultEnd });

    setAvailableTimes(newTimes);
    setSaveNeeded(true);
  };

  const availabilityTypes = useAvailabilityTypes();
  const leaveTypes = useLeaveTypes();
  const leaveIncludingCurrentlySelected = leaveTypes.filter(a => a.active || a.id === selectedDay.leaveTypeId);
  const availabilityOptions = useMemo(() => {
    return availabilityTypes
      .filter(type => type.leaveTypeId !== FixedLeaveTypeId.ROSTER)
      .filter(
        type =>
          type.name === strings.ess.availability.mode.name.available ||
          leaveIncludingCurrentlySelected.find(a => a.id === type.leaveTypeId)
      )
      .map(aType => {
        return {
          name: aType.name,
          // NOTE: We cast the undefined leaveTypeId to an empty string, because otherwise the select tries to use the name.
          value: aType.leaveTypeId || '',
          color: aType.color
        };
      });
  }, [availabilityTypes]);

  const [leaveType, setLeaveTypeId] = useState<string | undefined>(selectedDay.leaveTypeId);

  const onStateChange = (newState: string) => {
    const newTimes = [];
    if (newState === '') {
      // For the selected options, the empty string is used to denote an employee is available rather than undefined.
      newTimes.push({ start: defaultStart, end: defaultEnd });
    } else {
      newTimes.push({ start: 0, end: 0 });
    }
    setAvailableTimes(newTimes);

    // If newState is the empty string, instead go back to using undefined to denote an employee is available.
    setLeaveTypeId(newState || undefined);
    setSaveNeeded(true);
  };

  const { fetching, saveAvailability } = useSaveAvailability(selectedDay.date, selectedMonth.employee.identityId);

  const onSave = async () => {
    const timesToValidate = [...availableTimes];
    timesToValidate.sort((a, b) => a.start - b.start);

    setErrorMessage('');

    let error: string = '';
    let previousEnd: number | undefined = undefined;
    timesToValidate.forEach(t => {
      if (t.start > t.end) {
        error = strings.settings.manageAvailability.endBeforeStart;
      }

      if (previousEnd !== undefined && t.start < previousEnd) {
        error = "No overlaps";
      }

      previousEnd = t.end;
    });

    if (error) {
      setErrorMessage(error);
      return;
    }

    let result: OperationResult;

    if (
      availableTimes.length === 1 &&
      availableTimes[0].start === defaultStart &&
      availableTimes[0].end === defaultEnd
    ) {
      result = await saveAvailability(leaveType);
    } else {
      result = await saveAvailability(leaveType, availableTimes);
    }

    if (result.error) {
      setErrorMessage(strings.common.error);
    } else {
      onComplete();
    }
  };

  const dayValid = useValidateDay(selectedDay, availableTimes);

  const [saveNeeded, setSaveNeeded] = useState<boolean>(false);

  const availabilityLimit = useSystemSettings(s => s.settings.maxTimePeriods);
  const canAdd = availableTimes.length < availabilityLimit && !leaveType;
  const canDelete = availableTimes.length > 1;

  return (
    <div className="min-w-[450px]" id="availability-details">
      <div className="flex justify-between items-center pb-5 border-b">
        <div>{displayName(employee)}</div>
        <div className="text-blue-500">{format(selectedDay.date, 'yyyy/MM/dd')}</div>
      </div>
      <div className="py-5 border-b space-y-4">
        <div className="flex items-center">
          <div className="flex-1">{strings.settings.manageAvailability.availability}</div>
          <div className="flex-1">
            <SelectInput className="w-full" options={availabilityOptions} value={leaveType} setValue={onStateChange} />
          </div>
        </div>
        <div className="flex items-start">
          <div className="flex-1">{strings.settings.manageAvailability.hours}</div>
          <div className="space-y-2">
            {availableTimes.map((times, index) => (
              <div key={`${index}-${times.start}`} className="flex-1 flex items-center gap-1">
                <DurationInput
                  className="w-32"
                  readonly={!!leaveType}
                  value={times.start}
                  setValue={onStartChange(index)}
                  maxValue={hoursToSeconds(36)}
                />
                <div>~</div>
                <DurationInput
                  className="w-32"
                  readonly={!!leaveType}
                  value={times.end}
                  setValue={onEndChange(index)}
                  maxValue={hoursToSeconds(36)}
                />
                {canDelete && (
                  <button className="text-red-500" onClick={onDelete(index)}>
                    <FiX />
                  </button>
                )}
                {canAdd && index === availableTimes.length - 1 && (
                  <button onClick={onAdd}>
                    <FiPlus />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-start py-5">
        <AvailabilityDetailsRostered selectedDay={selectedDay} times={availableTimes} />
        <AvailabilityDetailsPattern selectedDay={selectedDay} />
      </div>
      <RosteredLocation selectedDay={selectedDay} employee={employee} />
      <div className="flex items-start py-5">
        <AvailabilityDetailsLeaveForPeriod employeeId={employee.identityId} />
      </div>
      {errorMessage && <p className="text-red-500 pb-5">{errorMessage}</p>}
      <div className="flex justify-between items-center">
        <Button onClick={onComplete}>{strings.common.cancel}</Button>
        <Button
          onClick={onSave}
          variant={dayValid && saveNeeded ? 'primary' : 'default'}
          loading={fetching}
          disabled={!dayValid || !saveNeeded}
        >
          {strings.common.save}
        </Button>
      </div>
    </div>
  );
};
