import { SummaryDay, SummaryEmployee } from '@/services/summary/summary.types';
import { ReadEmployee, ReadEmployeeSkills } from '@/services/gql/graphql.generated';
import { StaffPreferences } from '@/services/settings/systemSettings.types';
import { TaskType } from '@/services/tasks/task.types';

export enum EmployeeStatus {
  AVAILABLE = 'available',
  PARTLY_AVAILABLE = 'partly_available',
  NOT_AVAILABLE = 'unavailable'
}

export enum AllocationStatus {
  NOT_ALLOCATED,
  PARTLY_ALLOCATED,
  FULLY_ALLOCATED
}

export interface EmployeeForShiftItem {
  id: string;
  employeeId?: string;
  contractId?: string;
  name?: string;
  status?: EmployeeStatus;
  role?: string;
  timeAvailable?: string[];
  allocated?: AllocationStatus;
  leaveType?: string;
  assignable?: boolean;
  details?: ReadEmployee;
  skills?: ReadEmployeeSkills[];
  taskMatch?: TaskType[];
  employeeSummary?: SummaryEmployee;
  preferences?: StaffPreferences;
}

export type EmployeeSkill = {
  name: string;
  skillId?: string;
};

export type EmployeeSkills = {
  employeeId: string;
  skills: EmployeeSkill[];
};
