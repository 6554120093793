import { SelectOption } from '@/components/form/SelectInput';
import { dateToApiFormat, getCalendarStartDate } from '@/helpers/dateHelper';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useMonthDates } from '@/services/settings/useDates';
import { Popover } from '@headlessui/react';
import { addMonths, endOfMonth, format, getYear, subMonths } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { FC, useMemo, useState } from 'react';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { FiCalendar, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface Props {
  showNavigationArrows?: boolean;
  switchView?: any;
}

export const MonthSelector: FC<Props> = ({ showNavigationArrows, switchView }) => {
  const { selectedDate, setSelectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate,
    setSelectedDate: state.setSelectedDate
  }));

  const language = useLanguageProvider(state => state.language);

  const { start, end } = useMonthDates(new Date(selectedDate));

  const locale = language === 'ja' ? ja : enUS;
  const startMonth = format(start, 'MMMM yyyy', { locale });
  const endMonth = format(end, 'MMMM yyyy', { locale });

  const monthDisplay = startMonth === endMonth ? `${startMonth}` : `${startMonth} - ${endMonth}`;

  const [selectedYear, setSelectedYear] = useState<number>(start.getFullYear());

  const startOfMonth = useSystemSettings(state => state.settings.monthStartDay);

  const monthOptions: SelectOption[] = useMemo(() => {
    const options: SelectOption[] = [];
    for (let i = 0; i < 12; i++) {
      const realityMonthEnd = endOfMonth(new Date(selectedYear, i, 1));
      const snpsMonthStart = getCalendarStartDate(realityMonthEnd, startOfMonth);

      options.push({
        name: format(snpsMonthStart, 'MMM', { locale }),
        value: snpsMonthStart
      });
    }

    return options;
  }, [selectedYear, startOfMonth, locale]);

  const changeMonth = (isPrevious?: boolean): void => {
    let currentDate = new Date(selectedDate);
    const changeValue = 1;
    currentDate = (isPrevious ? subMonths : addMonths)(currentDate, changeValue);

    const currentYear = getYear(currentDate);

    if (selectedYear !== currentYear) {
      setSelectedYear(currentYear);
    }

    selectDate(currentDate);
  };

  const selectDate = (date: Date) => {
    switchView && switchView(false);
    
    setSelectedDate(dateToApiFormat(date));
  }

  return (
    <div className="flex items-center gap-1">
      {showNavigationArrows && (
        <AiFillCaretLeft className="cursor-pointer hover:text-blue-400 h-5 w-5" onClick={e => changeMonth(true)} />
      )}
      <Popover>
        <Popover.Button className="h-full py-3 border border-border rounded-md bg-white flex items-center leading-none px-3 gap-3">
          <FiCalendar />
          {monthDisplay}
        </Popover.Button>
        <Popover.Panel className="absolute border border-border bg-white p-5 z-50">
          <div className="flex gap-2 justify-center items-center">
            <button onClick={() => setSelectedYear(selectedYear - 1)}>
              <FiChevronLeft className="w-6 h-6" />
            </button>
            <div className="py-2 px-10 bg-active rounded-md overflow-hidden border border-border font-bold">
              {selectedYear}
            </div>
            <button onClick={() => setSelectedYear(selectedYear + 1)}>
              <FiChevronRight className="w-6 h-6" />
            </button>
          </div>
          <div className="grid grid-cols-4">
            {monthOptions.map(opt => (
              <div className="py-2 px-5 flex items-center justify-center" key={opt.name}>
                <button onClick={() => selectDate(opt.value)}>{opt.name}</button>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Popover>
      {showNavigationArrows && (
        <AiFillCaretRight className="cursor-pointer hover:text-blue-400 h-5 w-5" onClick={e => changeMonth()} />
      )}
    </div>
  );
};
