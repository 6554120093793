import { dateToApiFormat, getCalendarEndDate, getCalendarStartDate } from '@/helpers/dateHelper';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import {
  RosterComplianceData,
  SummaryDay,
  SummaryEmployee
} from '@/services/summary/summary.types';
import { useMemo } from 'react';
import { useGetFullRostersQuery } from '../gql/graphql.generated';
import { useLocalSettings } from '../settings/LocalSettingsProvider';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { useDaysInCurrentMonth } from '../settings/useDates';
import { buildComplianceDataTotals } from './buildCompliance';

export const useComplianceDataForRoster = (): RosterComplianceData => {
  const { selectedDate, selectedLocation } = useLocalSettings(state => ({
    selectedDate: state.selectedDate,
    selectedLocation: state.selectedLocationId
  }));

  const requestedStartMonthDate: number = useSystemSettings(state => state.settings.monthStartDay);
  const startCalendarDate = getCalendarStartDate(new Date(selectedDate), requestedStartMonthDate);
  const endCalendarDate = getCalendarEndDate(startCalendarDate, requestedStartMonthDate);

  const [{ data: rosterData }] = useGetFullRostersQuery({
    variables: {
      locationId: selectedLocation,
      startDate: dateToApiFormat(startCalendarDate),
      endDate: dateToApiFormat(endCalendarDate)
    }
  });

  let totalShifts = 0;
  let totalUnassignedShifts = 0;

  rosterData?.rostersWithPositionsAndTasksInDateRange?.forEach(rosterDay => {
    rosterDay.stations &&
      rosterDay.stations[0].positions?.forEach(pos => {
        pos.employee === null && totalUnassignedShifts++;
        totalShifts++;
      });
  });

  const totalWorkDays = useDaysInCurrentMonth();

  return {
    totalShifts,
    totalUnassignedShifts,
    totalWorkDays: totalWorkDays.length
  };
};

export const useAdvancedLeaveColumns = (employeeSchedules: SummaryDay[][]): string[] => {
  const leaveTypes = useLeaveTypes();

  return useMemo(() => {
    const columnsToShow: string[] = [];
    leaveTypes.map(type => {
      let showCol = false;

      type.active
        ? (showCol = true)
        : (showCol = employeeSchedules.some(schedule => {
            return schedule.some((item: SummaryDay) => item.leaveType === type.id);
          }));

      if (showCol) {
        columnsToShow.push(type.id);
      }
    });

    return columnsToShow;
  }, [leaveTypes, employeeSchedules]);
};

export const useComplianceDataTotals = (schedules: SummaryEmployee[]) => {
  return useMemo(() => buildComplianceDataTotals(schedules), [schedules]);
};
