import { secondsToTimeString } from '@/helpers/dateHelper';
import { BUDGET_NAME_COLUMN_WIDTH, BUDGET_TOTAL_COLUMN_WIDTH } from '@/pages/summary/constants';
import { useGetLocationQuery } from '@/services/gql/graphql.generated';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { deltaToColorClass } from '../../../helpers/deltaHelper';
import { WorkingBudgetLocation } from '../../../services/budget/budget.types';
import { useSetRosteredBudgets } from '../../../services/budget/useRosteredBudgets';
import { BudgetCell } from '../BudgetCell';
import { BudgetPadding } from '../BudgetCommon';
import { BudgetDay } from './BudgetDay';
import { MonthlyBudgetCostTotal } from './MonthlyBudgetCostTotal';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';

export const MonthlyBudgetLocation: FC<{
  location: WorkingBudgetLocation;
  locationIndex: number;
  setForecast: (dayIndex: number) => (newVal: number) => void;
  setSalesTarget: (dayIndex: number) => (newVal: number) => void;
}> = ({ location, locationIndex, setForecast, setSalesTarget }) => {
  const totalAllocated = location.days.reduce((prevTotal, day) => prevTotal + day.hqAllocatedHours, 0);
  const totalLocation = location.days.reduce((prevTotal, day) => prevTotal + day.locationForecast, 0);
  const totalMoney = location.days.reduce((prevTotal, day) => prevTotal + day.financialTarget, 0);

  const navigate = useNavigate();

  const { setLocation } = useLocalSettings(state => ({ setLocation: state.setSelectedLocationId }));
  const onLocationClick = () => {
    setLocation(location.id);
    navigate('/monthly');
  };

  const [{ data: locationData }] = useGetLocationQuery({ variables: { id: location.id } });

  const daysInMonth = useDaysInCurrentMonth();
  const { setRosteredBudgets } = useSetRosteredBudgets(location.id, daysInMonth);
  const totalRoster = setRosteredBudgets(location.days);
  const totalDelta = totalRoster - totalLocation;

  return (
    <div>
      <div className="h-16 bg-background-mid text-primary font-bold underline flex items-center px-5 border-b">
        <button tabIndex={-1} className="px-1 hover:bg-active rounded-sm" onClick={onLocationClick}>
          {locationData?.location?.name}
        </button>
      </div>
      <div
        className="grid grid-flow-col grid-rows-6"
        style={{
          gridTemplateColumns: `${BUDGET_NAME_COLUMN_WIDTH} ${BUDGET_TOTAL_COLUMN_WIDTH} repeat(${location.days.length}, 1fr)`
        }}
      >
        <BudgetCell className="font-semibold justify-end min-h-[35px]">{strings.budget.rows.financial}</BudgetCell>
        <BudgetCell className="font-semibold justify-end min-h-[35px]">{strings.budget.rows.hqAllocated}</BudgetCell>
        <BudgetCell className="font-semibold justify-end min-h-[35px]">
          {strings.budget.rows.locationForecast}
        </BudgetCell>
        <BudgetCell className="font-semibold justify-end min-h-[35px]">{strings.budget.rows.rosteredBudget}</BudgetCell>
        <BudgetCell className="font-semibold justify-end min-h-[35px]">{strings.budget.rows.deltaTime}</BudgetCell>
        <BudgetCell className="font-semibold justify-end min-h-[35px]">{strings.budget.rows.needStaff}</BudgetCell>
        <BudgetCell className="font-semibold justify-end">
          <MonthlyBudgetCostTotal hqAllocatedForMonth={location.hqAllocatedMonthlyBudget} actualTotal={totalMoney} />
        </BudgetCell>
        <BudgetCell className="font-semibold justify-end">
          {secondsToTimeString(location.hqAllocatedMonthlyTime || totalAllocated)}
        </BudgetCell>
        <BudgetCell className="font-semibold justify-end">{secondsToTimeString(totalLocation)}</BudgetCell>
        <BudgetCell className="font-semibold justify-end">{secondsToTimeString(totalRoster)}</BudgetCell>
        <BudgetCell className={twMerge(deltaToColorClass(totalDelta), 'font-semibold justify-end')}>
          {secondsToTimeString(totalDelta, true)}
        </BudgetCell>
        <BudgetCell></BudgetCell>
        {location.days.map((day, index) => (
          <BudgetDay
            day={day}
            location={location}
            locationIndex={locationIndex}
            totalDays={location.days.length}
            dayIndex={index}
            key={day.date.toISOString()}
            setForecast={setForecast(day.date.getDate())}
            setSalesTarget={setSalesTarget(day.date.getDate())}
          />
        ))}
        <BudgetPadding />
      </div>
    </div>
  );
};
