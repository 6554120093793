import { LoadingIndicator } from '@/components/LoadingIndicator';
import { GanttMarkerLine, GanttProvider } from '@/components/gantt/gantt/GanttProvider';
import { GanttTimerBar } from '@/components/gantt/gantt/GanttTimerBar';
import { TemplateTooltip } from '@/components/tooltip/TemplateToolTip';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { toastMessage } from '@/helpers/helper';
import { adjustPadLength, calculateScratchpadLength } from '@/helpers/scratchpadHelper';
import {
  EMPLOYEE_DETAILS_COLUMN_WIDTH,
  EMPLOYEE_INFO_WIDTH,
  EMPLOYEE_NAME_COLUMN_WIDTH
} from '@/pages/summary/constants';
import { ReadTemplate } from '@/services/gql/graphql.generated';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { OpenClosingTimeOptions } from '@/services/settings/systemSettings.types';
import { useOpenClosingTimePref } from '@/services/settings/useOpenClosingTimePref';
import { TaskType } from '@/services/tasks/task.types';
import { strings } from '@/services/translation/strings';
import { hoursToSeconds, secondsToHours } from 'date-fns';
import { FC, Suspense, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AttendanceTotalHeader } from '../AttendanceTotalHeader';
import { AttendanceTotalSummary } from '../AttendanceTotalSummary';
import { DragDropButtons } from '../DragDropButtons';
import { OpenClosingTimePicker } from '../OpenClosingTimePicker';
import { StartEndTimePicker } from '../StartEndTimePicker';
import { DailyEditingType, WorkingShift } from '../daily.types';
import { RosterProvider } from '@/pages/daily/roster/providers/RosterService';
import { useSubsections } from '../useSubsections';
import { TemplateHourSummary } from './TemplateHourSummary';
import { WorkingTemplate } from './template.types';
import NotesField from '@/components/notes/NotesField';
import { EditNotesFields } from '@/components/notes/NotesModal';
import { TemplateShifts } from '@/pages/daily/template/TemplateShifts';
import { TagsSummary } from '@/components/tags/TagsSummary';
import { useFlag } from '@softwareimaging/backstage';

interface Props {
  workingTemplate: WorkingTemplate;
  templateData?: ReadTemplate;
  tasks?: TaskType[];
  setWorkingTemplate: (newTemp: WorkingTemplate) => void;
  submitNotes: (data: EditNotesFields) => Promise<void>;
}

export const Template: FC<Props> = ({ workingTemplate, setWorkingTemplate, templateData, submitNotes }) => {
  const minuteIncrement = useSystemSettings(state => state.settings.minuteIncrement);
  const defaultShiftDuration = useSystemSettings(state => state.settings.defaultShiftDuration);

  const addShift = useCallback(() => {
    let startTime = workingTemplate.startTime + hoursToSeconds(2);
    let endTime = startTime + Number(defaultShiftDuration);

    if (endTime > workingTemplate.endTime) {
      startTime = workingTemplate.startTime;
      endTime = startTime + Number(defaultShiftDuration);
    }

    const newShift: WorkingShift = {
      id: `CREATE-${uuidv4()}`,
      start: startTime,
      end: endTime,
      breaks: [],
      tasks: [],
      properties: {}
    };

    const shifts = [...workingTemplate.shifts, newShift];
    setWorkingTemplate({ ...workingTemplate, shifts });
  }, [setWorkingTemplate, workingTemplate]);

  const subsections = useSubsections();

  const hoursCovered = secondsToHours(workingTemplate.endTime - workingTemplate.startTime);

  const onChangeStart = useCallback(
    (start: number) => {
      const errorStartRow = workingTemplate.shifts.find(shift => shift.start && shift.start < start);

      if (errorStartRow?.start) {
        toastMessage(strings.daily.changeTimes.startError(secondsToTimeString(errorStartRow.start)));
      } else {
        const newScratchpad = adjustPadLength(
          workingTemplate.scratchpad,
          'start',
          calculateScratchpadLength(start, workingTemplate.endTime)
        );
        setWorkingTemplate({
          ...workingTemplate,
          startTime: start,
          openTime: workingTemplate.openTime !== undefined ? Math.max(workingTemplate.openTime, start) : undefined,
          scratchpad: newScratchpad
        });
      }
    },
    [workingTemplate, setWorkingTemplate]
  );

  const onChangeEnd = useCallback(
    (end: number) => {
      const errorEndRow = workingTemplate.shifts.find(shift => shift.end && shift.end > end);

      if (errorEndRow?.end) {
        toastMessage(strings.daily.changeTimes.endError(secondsToTimeString(errorEndRow.end)));
      } else {
        const newScratchpad = adjustPadLength(
          workingTemplate.scratchpad,
          'end',
          calculateScratchpadLength(workingTemplate.startTime, end)
        );
        setWorkingTemplate({
          ...workingTemplate,
          endTime: end,
          closeTime: workingTemplate.closeTime !== undefined ? Math.min(workingTemplate.closeTime, end) : undefined,
          scratchpad: newScratchpad
        });
      }
    },
    [workingTemplate, setWorkingTemplate]
  );

  const openClosingTimesPref = useOpenClosingTimePref();

  const onChangeOpen = useCallback(
    (open?: number) => {
      if (!workingTemplate) {
        throw Error('Working roster should be defined');
      }

      if (typeof open === 'number' && open < workingTemplate.startTime) {
        toastMessage(strings.daily.changeTimes.openBeforeStartError);
        return;
      }

      if (
        typeof workingTemplate.closeTime === 'number' &&
        typeof open === 'number' &&
        open >= workingTemplate.closeTime
      ) {
        toastMessage(strings.daily.changeTimes.closeBeforeOpenError);
        return;
      }

      setWorkingTemplate({ ...workingTemplate, openTime: open });
    },
    [workingTemplate, setWorkingTemplate]
  );

  const onChangeClose = useCallback(
    (close?: number) => {
      if (!workingTemplate) {
        throw Error('Working roster should be defined');
      }

      if (close !== undefined && close > workingTemplate.endTime) {
        toastMessage(strings.daily.changeTimes.closeAfterEndError);
        return;
      }

      if (workingTemplate.openTime !== undefined && close !== undefined && close <= workingTemplate.openTime) {
        toastMessage(strings.daily.changeTimes.closeBeforeOpenError);
        return;
      }

      setWorkingTemplate({ ...workingTemplate, closeTime: close });
    },
    [workingTemplate]
  );

  const markerLines: GanttMarkerLine[] = useMemo(() => {
    if (openClosingTimesPref !== OpenClosingTimeOptions.all) {
      return [];
    }

    const lines: GanttMarkerLine[] = [];

    if (workingTemplate?.openTime) {
      lines.push({ time: workingTemplate.openTime, color: '#22c55e' });
    }

    if (workingTemplate?.closeTime) {
      lines.push({ time: workingTemplate.closeTime, color: '#ef4444' });
    }

    return lines;
  }, [workingTemplate, openClosingTimesPref]);

  const useTags = useFlag('enableTags');

  return (
    <GanttProvider
      markerLines={markerLines}
      subsections={subsections}
      minTime={workingTemplate.startTime}
      maxTime={workingTemplate.endTime}
      minuteIncrement={minuteIncrement}
    >
      <div className="flex py-1 flex-row border-b bg-background-mid justify-between px-10">
        <div className="flex items-center gap-5 font-semibold" style={{ width: EMPLOYEE_INFO_WIDTH }}>
          <StartEndTimePicker
            startTime={workingTemplate.startTime}
            endTime={workingTemplate.endTime}
            minLength={defaultShiftDuration}
            onChangeStart={onChangeStart}
            onChangeEnd={onChangeEnd}
          />
          {openClosingTimesPref !== OpenClosingTimeOptions.hidden && (
            <OpenClosingTimePicker
              key={workingTemplate.id}
              openTime={workingTemplate.openTime}
              closeTime={workingTemplate.closeTime}
              onChangeOpen={onChangeOpen}
              onChangeClose={onChangeClose}
            />
          )}
        </div>
        <div className="flex items-center gap-5">
          {useTags && <TagsSummary template={workingTemplate} />}
          <NotesField
            notes={workingTemplate.notes}
            historyNotes={workingTemplate.templateHistoryNotes}
            emptyNoteString={strings.daily.templates.noNotes}
            subsectionTitle={strings.daily.templates.templateHistory}
            submitNotes={submitNotes}
          />
        </div>
      </div>
      <div className="overflow-hidden flex-1">
        <div className="overflow-scroll w-full h-full relative">
          <div className="flex-1 flex flex-col" style={{ minWidth: 100 * hoursCovered }}>
            <div className="sticky top-0 z-[100]">
              <TemplateHourSummary workingTemplate={workingTemplate} setWorkingTemplate={setWorkingTemplate} />
              <div className="flex flex-row border-b bg-background-mid">
                <div className="flex sticky left-0 z-[70] border-r">
                  <div
                    className="border-r flex items-center justify-center"
                    style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
                  >
                    {templateData && templateData.baseTemplateId && (
                      <TemplateTooltip templateId={templateData.baseTemplateId} wrapperId="root" />
                    )}
                  </div>
                  <div className="flex" style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }}>
                    <div className="flex-[3]">
                      <AttendanceTotalHeader />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex sticky border-b left-0 z-[150] border-r bg-background-mid">
                  <div
                    className="flex items-center justify-center px-2 gap-2.5 font-semibold border-r"
                    style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
                  >
                    <span className="text-[#323232bf]">{strings.common.employee}</span>
                    {
                      <div className="flex items-center justify-center content-center gap-2.5">
                        <img
                          className="hover:cursor-pointer"
                          src="/add.svg"
                          alt="Add shift"
                          width="20 lg:25"
                          onClick={addShift}
                        />
                      </div>
                    }
                  </div>
                  <div className="flex" style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }}>
                    <AttendanceTotalSummary workingTemplate={workingTemplate} />
                  </div>
                </div>

                <GanttTimerBar />
              </div>
            </div>
            <div className="flex-1">
              <Suspense fallback={<LoadingIndicator />}>
                <RosterProvider>
                  <TemplateShifts workingTemplate={workingTemplate} setWorkingTemplate={setWorkingTemplate} />
                </RosterProvider>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <DragDropButtons type={DailyEditingType.TEMPLATE} />
    </GanttProvider>
  );
};
