import { Button } from '@/components/button';
import { useResetPassword } from '@/pages/settings/services/useResetPassword';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { StaffRowCell } from './StaffRowCell';

export const StaffRowActionSection: FC<StaffRowSectionProps> = ({ staff, hidden, onSetDelete, group }) => {
  const { resetUserPassword, fetching: fetchingResetPassword } = useResetPassword(staff.employee.identityId);
  const toBeDeleted = staff.settings.toBeDeleted;

  return (
    <>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[0].isColumnHidden} className="flex items-center justify-center">
        <Button
          size="sm"
          variant={'primary'}
          loading={fetchingResetPassword}
          onClick={() => resetUserPassword()}
          className="w-24"
        >
          {strings.settings.manageStaff.resetButton}
        </Button>
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[1].isColumnHidden} className="flex items-center justify-center">
        {onSetDelete && (
          <Button
            size="sm"
            variant={toBeDeleted ? 'default' : 'destructive'}
            onClick={() => onSetDelete(!toBeDeleted)}
            className="w-24"
          >
            {toBeDeleted ? strings.settings.manageStaff.deleteCancel : strings.settings.manageStaff.deleteButton}
          </Button>
        )}
      </StaffRowCell>
    </>
  );
};
