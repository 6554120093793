export type WarningModel = {
  name: string;
  details?: string[];
  severity: SeverityType;
};

export enum SeverityType {
  LOW,
  WARNING,
  SEMICRITICAL, // Appears as critical, but doesn't actually shut anything down.
  CRITICAL,
}

export enum SeverityColor {
  CRITICAL = '#FF0000',
  WARNING = '#F39802',
  LOW = '#1BB55C'
}

export const severityTypeToColor = (severity: SeverityType) => {
  switch (severity) {
    case SeverityType.CRITICAL:
    case SeverityType.SEMICRITICAL:
      return SeverityColor.CRITICAL;
    case SeverityType.WARNING:
      return SeverityColor.WARNING;
    case SeverityType.LOW:
      return SeverityColor.LOW;
  }
};
