import { FC, Suspense } from 'react';
import { BudgetProvider } from './BudgetService';
import { MonthlyBudgetTableHeader } from './editable/MonthlyBudgetTableHeader';
import { MonthlyBudget } from './editable/MonthlyBudget';
import { MonthlyBudgetHeader } from './editable/MonthlyBudgetHeader';
import { LoadingIndicator } from '@/components/LoadingIndicator';

export const BudgetPage: FC = () => {
  return (
    <div className="h-full flex flex-col">
      <Suspense fallback={<LoadingIndicator />}>
        <BudgetProvider>
          <MonthlyBudgetHeader />
          <MonthlyBudgetTableHeader />
          <MonthlyBudget />
        </BudgetProvider>
      </Suspense>
    </div>
  );
};
