import { Button } from '@/components/button';
import TableRowCell from '@/components/table/TableRowCell';
import { MONTHLY_SORT_TYPES } from '@/services/sort/MonthlySortProvider';
import { FunctionComponent, useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { ProcessedMonthlySortRowData } from './MonthlySortModal';

interface Props {
  data: ProcessedMonthlySortRowData;
  onSortChange: (type: MONTHLY_SORT_TYPES, asc: boolean) => void;
}

const MonthlySortRow: FunctionComponent<Props> = ({ data, onSortChange }) => {
  const [isAsc, setIsAsc] = useState(data.asc);

  return (
    <>
      <TableRowCell className="justify-start h-full">{data.text}</TableRowCell>
      <TableRowCell className="h-full">
        <Button
          onClick={() => {
            setIsAsc(!isAsc);
            onSortChange(data.type, !isAsc);
          }}
        >
          {isAsc ? <FiArrowUp /> : <FiArrowDown />}
        </Button>
      </TableRowCell>
    </>
  );
};

export default MonthlySortRow;
