import { InfoCell } from '@/components/table/InfoCell';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { FC } from 'react';
import { WorkingShift } from './daily.types';
import { useRosterEmployeeCurrentDaySummary, useRosterEmployeeSummary } from './roster/providers/WorkingRosterService';
import { twMerge } from 'tailwind-merge';
import { useCalculateShiftTime } from '@/services/rosters/useCalculateShiftTime';

interface AttendanceTotalColumnsProps {
  shift: WorkingShift;
  useShiftTime?: boolean;
}

export const AttendanceTotalColumns: FC<AttendanceTotalColumnsProps> = ({ shift, useShiftTime }) => {
  const employeeSummary = useRosterEmployeeSummary(shift.assignedEmployeeId);
  const daySummary = useRosterEmployeeCurrentDaySummary(shift.assignedEmployeeId);

  const calculateShiftTime = useCalculateShiftTime();

  let actualTotal = 0;
  let rosterTotal = 0;
  let breakTotal = 0;

  if (daySummary && !useShiftTime) {
    actualTotal = daySummary.actualTime;
    rosterTotal = daySummary.basicTime;
    breakTotal = daySummary.breakTime;
  } else {
    // IMPORTANT: 
    // 'Why aren't we just displaying the calculated shift times for every row?
    // Why even have the day summary data?'
    // 
    // It's because the day summary data allows for split shifts.
    // If I'm working two shifts on a single day, the totals should reflect all the
    // time and breaks across both shifts.
     
    const { actualTime, breakTime, basicTime } = calculateShiftTime(shift);
    actualTotal = actualTime;
    rosterTotal = basicTime;
    breakTotal = breakTime;
  }

  const baseClass = twMerge('flex-1', employeeSummary?.homeEmployee || useShiftTime ? '' : 'bg-gray-200');

  return (
    <>
      <InfoCell className={twMerge(baseClass, 'border-r')}>
        <p>{rosterTotal ? secondsToTimeString(rosterTotal) : '--:--'}</p>
      </InfoCell>
      <InfoCell className={twMerge(baseClass, 'border-r')}>
        <p>{actualTotal ? secondsToTimeString(actualTotal) : '--:--'}</p>
      </InfoCell>
      <InfoCell className={twMerge(baseClass)}>
        <p>{breakTotal ? secondsToTimeString(breakTotal) : '--:--'}</p>
      </InfoCell>
    </>
  );
};
