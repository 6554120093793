//Japan code format
export const countryCodeJapan = '+81';
export const japaneseMobilePhoneRegex = /^(\+81|\+81-)?[0-9]{9,14}$/;

export const formatJapanesePhoneNumber = (number: string) => {
  let numberToSend = number.replace(/[^\d+]/g, '');
  numberToSend = numberToSend.substring(0, 14);

  if (japaneseMobilePhoneRegex.test(numberToSend)) {
    if (numberToSend[0] === '0') {
      const arr = numberToSend.split('');
      arr.shift();
      arr.unshift(countryCodeJapan);
      numberToSend = arr.join('');
    } else if (!numberToSend.startsWith('+') && !numberToSend.startsWith(countryCodeJapan)) {
      // If the number doesn't start with a '+' or '+81', add '+81' to the beginning
      numberToSend = countryCodeJapan + numberToSend;
    }
    return true;
  }
};
