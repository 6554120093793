import { SeverityType } from '@/components/warning/warningDefinitions';
import { secondsToTimeString, timeStringToSecondsSinceMidnight } from '@/helpers/dateHelper';
import { WorkingShift } from '@/pages/daily/daily.types';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { EmployeeForShiftItem } from '../../../employeeSelector/employeeForShift.types';
import { RosterWarningModel, WarningsProps } from '../warnings.types';

export const getAvailabilityWarnings = (
  shift: WorkingShift,
  assignedStaff: EmployeeForShiftItem,
  props: WarningsProps
): RosterWarningModel[] => {
  const { strings } = props;

  const shiftWarnings: RosterWarningModel[] = [];
  if (assignedStaff.leaveType) {
    if (assignedStaff.leaveType !== FixedLeaveTypeId.ROSTER) {
      shiftWarnings.push({
        severity: SeverityType.WARNING,
        name: strings.paidOrPreferredDayOff.message,
        shiftId: shift.id
      });
    } else {
      shiftWarnings.push({
        severity: SeverityType.CRITICAL,
        name: strings.rosteredDayOff.message,
        shiftId: shift.id
      });
    }
  } else if (typeof shift.start === 'number' && typeof shift.end === 'number') {
    if (assignedStaff.timeAvailable && assignedStaff.timeAvailable.length > 0) {
      const availableSlot = assignedStaff.timeAvailable.find(timeSlot => {
        const assignedStaffTimes = timeSlot.split('~');
        const assignedStaffStartSeconds = timeStringToSecondsSinceMidnight(assignedStaffTimes[0]);
        const assignedStaffEndSeconds = timeStringToSecondsSinceMidnight(assignedStaffTimes[1]);

        return assignedStaffStartSeconds <= shift.start! && assignedStaffEndSeconds >= shift.end!;
      });

      if (!availableSlot) {
        const shiftTimeString = secondsToTimeString(shift.start!) + ' ~ ' + secondsToTimeString(shift.end!);
        shiftWarnings.push({
          severity: SeverityType.WARNING,
          name: strings.availableHoursDoNotFitShiftHours.message,
          details: [
            strings.availableHoursDoNotFitShiftHours.detailsAvailable(assignedStaff.timeAvailable.join(' / ')),
            strings.availableHoursDoNotFitShiftHours.detailsShift(shiftTimeString)
          ],
          shiftId: shift.id
        });
      }
    }
  }

  return shiftWarnings;
};
