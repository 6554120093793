import { SeverityType } from "@/components/warning/warningDefinitions";
import { ReadEmployee } from "@/services/gql/graphql.generated";
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterWarningModel, WarningsProps } from "../warnings.types";
import { WarningStringsType } from "@/services/translation/warningsStrings";

export const getAloneRookieWarnings = (props: WarningsProps): RosterWarningModel[] => {
  const { allEmployees, strings, workingRoster } = props;
  const shifts = workingRoster.shifts;
  
  const warnings: RosterWarningModel[] = [];
  const warningRookiesEndShift = aloneRookieEmployeesEndShift(shifts, allEmployees);
  warningRookiesEndShift.forEach(sh => {
    warnings.push({
      severity: SeverityType.WARNING,
      name: getRookieEmployeeWarningMessage(warningRookiesEndShift.length, strings),
      shiftId: sh.id
    });
  });

  return warnings;
};

const getRookieEmployeeWarningMessage = (numberOfEmployees: number, strings: WarningStringsType): string => {
  switch (numberOfEmployees) {
    case 1:
      return strings.probationaryEmployee.one;
    case 2:
      return strings.probationaryEmployee.two;
    default:
      return strings.probationaryEmployee.multiple(numberOfEmployees);
  }
};

const aloneRookieEmployeesEndShift = (
  shifts: WorkingShift[],
  allEmployees: ReadEmployee[]
): WorkingShift[] => {
  if (!shifts || !shifts.length) {
    return [];
  }

  let latestShiftEnd = 0;
  shifts.forEach((shift: WorkingShift) => {
    if (shift.end && shift.end > latestShiftEnd) {
      latestShiftEnd = shift.end;
    }
  });

  const latestShifts = shifts.filter(sh => sh.end === latestShiftEnd);

  const areOnlyProbationaryEmployees = latestShifts.every(shift => {
    const allocatedEmployee = allEmployees.find((e: any) => e.identityId === shift.assignedEmployeeId);

    const endOfProbation = (allocatedEmployee as any)?.endOfProbationDate;
    const endOfProbationDate = endOfProbation ? new Date(endOfProbation) : undefined;
    const today = new Date();

    return endOfProbationDate && today < endOfProbationDate;
  });

  return areOnlyProbationaryEmployees ? latestShifts : [];
};
