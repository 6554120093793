import { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { Tag } from './TagInput';

export const TagChit: FC<{ tag: Tag; onDelete?: () => void }> = ({ tag, onDelete }) => {
  return (
    <div className="rounded-md flex gap-1 border px-2 bg-white text-sm py-1 text-foreground font-normal">
      <div>{tag.label}</div>
      {onDelete && (
        <button onClick={onDelete}>
          <FiX />
        </button>
      )}
    </div>
  );
};
