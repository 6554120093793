import { PropsWithChildren } from "react";
import { InputProps, withField } from './withField';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, PropsWithChildren<InputProps<string>>>(
  ({ value, onChange, className, placeholder, placeholderClass, readOnly, ...props }, ref ) => {
    return (
      <textarea
        ref={ref}
        className={twMerge(
          'block w-full pb-2 pt-2 px-2 truncate rounded-md font-medium shadow-sm border-gray-300',
          readOnly
            ? 'bg-background-mid text-primary-50 cursor-default border-gray-200 focus:border-gray-200 focus:ring-0'
            : 'bg-secondary-std text-secondary-std',
          className + (placeholder && placeholderClass ? ' ' + placeholderClass : '')
        )}
        maxLength={2000}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        {...props}
      />
    );
  }
)

export const TextAreaField = withField(TextAreaInput);