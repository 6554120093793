import { SeverityType, WarningModel } from '@/components/warning/warningDefinitions';
import { StaffAvailabilityDay, StaffDayTimes } from '@/services/availability/useStaffAvailability';
import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';

export const validateShift = (shift: StaffDayTimes, times: StaffDayTimes[]): WarningModel | undefined => {
  const availableTime = times.find(t => t.start <= shift.start && t.end >= shift.end);
  const partlyAvailibleTime = times.find(
    t => (t.start < shift.end && t.start >= shift.start) || (t.end > shift.start && t.end <= shift.end)
  );

  if (!partlyAvailibleTime) {
    return {
      name: strings.settings.manageAvailability.warnings.noAvailability,
      severity: SeverityType.CRITICAL
    };
  } else if (!availableTime) {
    return {
      name: strings.settings.manageAvailability.warnings.partialAvailability,
      severity: SeverityType.WARNING
    };
  }

  return undefined;
};

const useDayWarnings = (day: StaffAvailabilityDay, times: StaffDayTimes[]) => {
  return useMemo(() => {
    let warnings: WarningModel[] = [];

    day.rosteredShifts?.forEach(shift => {
      const warning = validateShift(shift, times);

      if (warning) {
        warnings.push(warning);
      }
    });

    return warnings;
  }, [day, times]);
};

export const useValidateDay = (day: StaffAvailabilityDay, times: StaffDayTimes[]) => {
  const dayWarnings = useDayWarnings(day, times);

  return useMemo(() => {
    const severeWarnings = dayWarnings.filter(d => d.severity === SeverityType.CRITICAL);

    return severeWarnings.length === 0;
  }, [dayWarnings]);
};
