import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { SkillType } from '@/services/tasks/skillSettings.types';
import { displayName } from '@/services/translation/name';

export interface StaffSkillCapabilities {
  skillName: string;
  employees: string[];
}

export const useBuildStaffSkillCapabilityData = (
  groupedSettings: Record<string, StaffWithSettings[]>,
  skills: SkillType[]
) => {
  const processedData: Record<string, StaffSkillCapabilities[]> = {};

  Object.entries(groupedSettings).forEach(([locationId, settingsArray]) => {
    processedData[locationId] = [];

    const skillMap: Record<string, string[]> = {};
    settingsArray.forEach(settings => {
      const employee = settings.employee;

      skills.forEach(skill => {
        if (settings.settings.assignedSkills.includes(skill.id)) {
          if (!skillMap[skill.name]) {
            skillMap[skill.name] = [];
          }

          const name = displayName({
            firstName: employee.firstName,
            lastName: employee.lastName
          });

          skillMap[skill.name].push(name);
        }
      });
    });

    const resultForLocation = Object.entries(skillMap).map(([skillName, employees]) => ({
      skillName,
      employees
    }));

    processedData[locationId] = resultForLocation;
  });

  return processedData;
};
