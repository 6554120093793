import { Tag } from '@/components/tags/TagInput';
import { useGetTemplateTagsQuery } from '../gql/graphql.generated';

export interface TemplateTag extends Tag {}

export const useTemplateTags = () => {
  const [{ data }] = useGetTemplateTagsQuery();
  
  if (!data?.templateTags) {
    throw Error("Could not get template tags")
  }

  return data.templateTags;
};
