import { useFlag } from '@softwareimaging/backstage';
import { useCallback } from 'react';

export enum LoggerLevel {
  INFO,
  ERROR
}

export const useLogger = () => {
  const detailedLogs = useFlag('detailedLogs');

  const logger = useCallback(
    (message: string, level: LoggerLevel = LoggerLevel.INFO) => {
      if (detailedLogs) {
        let prefix: string = '';

        switch (level) {
          case LoggerLevel.INFO:
            prefix = 'INFO';
            break;
          case LoggerLevel.ERROR:
            prefix = 'ERROR';
            break;
        }

        console.log(`${prefix}: ${message}`);
      }
    },
    [detailedLogs]
  );

  return logger;
};
