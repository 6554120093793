import {useCallback} from 'react';
import {
  usePushNotificationsMutation,
  useSetNotificationsMutation
} from "@/services/gql/graphql.generated";
import {useDaysInCurrentMonth} from "@/services/settings/useDates";
import {convertDateToGQLFormat} from "@/helpers/dateHelper";
import {useSelectedLocation} from "@/services/settings/LocalSettingsProvider";
import {
  NotificationTriggerType,
  useGetUnifiedNotification
} from "@/pages/summary/notifyVacant/useGetUnifiedNotification";

export const useNotifyVacant = () => {
  const selectedLocationId = useSelectedLocation()?.id;
  const daysInMonth = useDaysInCurrentMonth();
  const {unifiedNotification, setUnifiedNotification, getNotificationProperties, notificationAlreadySent} = useGetUnifiedNotification(selectedLocationId, NotificationTriggerType.VACANT_SHIFT, daysInMonth)

  const [{ fetching: updateFetching, error: updateError }, update] = useSetNotificationsMutation();
  const [{ fetching: notifyFetching, error: notifyError }, notify] = usePushNotificationsMutation();

  const setNotifyVacant = useCallback(async (value: boolean) => {
    const actionRequired = value && !notificationAlreadySent();

    await update({
      notifications: daysInMonth.map(d => {
        const dateString = convertDateToGQLFormat(d);

        const propString = getNotificationProperties(dateString);
        let properties = {sent: true};
        if (propString) { // add the sent property to existing properties.
          properties = JSON.parse(propString);
          properties['sent'] = true;
        }

        return {
          locationId: selectedLocationId,
          date: dateString,
          triggerType: NotificationTriggerType.VACANT_SHIFT,
          value: value,
          properties: JSON.stringify(properties)
        };
      })
    });

    setUnifiedNotification(value);

    if (actionRequired) {
      await notify({
        notifications: {
          locationId: selectedLocationId,
          start:  convertDateToGQLFormat(daysInMonth[0]),
          end:  convertDateToGQLFormat(daysInMonth[daysInMonth.length - 1]),
          triggerType: NotificationTriggerType.VACANT_SHIFT
        }
      });
    }
  }, [
    update, notify, setUnifiedNotification, selectedLocationId, daysInMonth,
    getNotificationProperties, notificationAlreadySent
  ]);

  return {
    notifyVacant: unifiedNotification,
    setNotifyVacant,
    error: updateError || notifyError,
    loading: updateFetching || notifyFetching
  };
};
