import { Button } from '@/components/button';
import { DateCellHeaders } from '@/components/table/DateCellHeaders';
import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { buildRosterTooltip } from '@/components/tooltip/RosterTooltip';
import { dateToApiFormat } from '@/helpers/dateHelper';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useComplianceDataForRoster } from '@/services/summary/useCompliance';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_NAME_COLUMN_WIDTH } from './constants';
import { MonthlyPageTotalTooltip } from './MonthlyPageTotalTooltip';

interface Props {
  days: Date[];
  width?: string;
  emptyColumnRight?: boolean;
  switchView?: any;
  showCompliance?: boolean;
  isMonthlyShiftsPage?: boolean;
}

export const SummaryTableHeader: FC<Props> = ({
  days,
  width,
  emptyColumnRight,
  switchView,
  showCompliance,
  isMonthlyShiftsPage
}) => {
  const navigate = useNavigate();
  const { setDate } = useLocalSettings(state => ({
    setDate: state.setSelectedDate
  }));

  const tooltips = buildRosterTooltip(days);

  const rosterCompliance = useComplianceDataForRoster();

  return (
    <div className="flex border-b bg-background-mid pr-5">
      {emptyColumnRight && (
        <div
          className="border-r flex items-center justify-center min-h-[45px]"
          style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
        >
          {isMonthlyShiftsPage && (
            <Button variant={'whiteGhost'} onClick={() => switchView(!showCompliance)}>
              {showCompliance ? strings.summary.compliance.showMonthly : strings.summary.compliance.showCompliance}
            </Button>
          )}
        </div>
      )}
      {!showCompliance && (
        <>
          <div
            className="flex items-center justify-end px-2 font-semibold space-x-1"
            style={{ width: width ? width : EMPLOYEE_NAME_COLUMN_WIDTH }}
          >
            <span>{strings.summary.total}</span>
            {isMonthlyShiftsPage && <MonthlyPageTotalTooltip />}
          </div>
          <DateCellHeaders
            days={days}
            tooltips={tooltips}
            onClick={date => {
              setDate(dateToApiFormat(date));
              navigate('/rosters');
            }}
          />
        </>
      )}
      {showCompliance && (
        <div className="flex flex-col flex-1">
          <div className="text-center border-b">{strings.common.totals.periodTotal}</div>
          <div className="flex">
            <InfoHeaderCell className="border-r">
              <p>{strings.summary.compliance.totalWorkDays}</p>
              <p className="font-bold">{rosterCompliance.totalWorkDays}</p>
            </InfoHeaderCell>
            <InfoHeaderCell className="border-r">
              <p>{strings.summary.compliance.totalRosterShifts}</p>
              <p className="font-bold">{rosterCompliance.totalShifts}</p>
            </InfoHeaderCell>
            <InfoHeaderCell>
              <p>{strings.summary.compliance.totalRosterUnassigned}</p>
              <p className="font-bold">{rosterCompliance.totalUnassignedShifts}</p>
            </InfoHeaderCell>
          </div>
        </div>
      )}
    </div>
  );
};
