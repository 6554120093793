import { SummaryEmployee } from '@/services/summary/summary.types';

export const getWeeklyShiftsDurationForEmployee = (
  employee: SummaryEmployee,
  selectedDate: string,
  includeBreaks?: boolean
) => {
  let shiftsDuration = 0;

  if (employee && employee.schedule) {
    const startDate = new Date(selectedDate);

    employee.schedule.forEach(day => {
      const dayDate = new Date(day.date);
      const weekEndDate = new Date(startDate);
      weekEndDate.setDate(startDate.getDate() + 6);

      if (dayDate >= startDate && dayDate <= weekEndDate) {
        day.shifts.forEach(shift => {
          const duration = shift.end - shift.start;
          if (!isNaN(duration)) {
            shiftsDuration += duration;
          }

          if (!includeBreaks) {
            shiftsDuration -= shift.breakLength;
          }
        });
      }
    });
  }

  return shiftsDuration;
};
