import gql from "graphql-tag";
import {useSubscription} from "urql";
import {useCallback} from "react";
import {convertToUtcDate} from "@/helpers/dateHelper";

const AVAILABILITY_EXCEPTION_CHANGE_SUBSCRIPTION = gql`
    subscription AvailabilityExceptionChangeSubscription {
        availabilityExceptionChange {
            identityId
            date
            updatedOn
        }
    }
`;

export const useAvailabilityExceptionChangeMonitor = (startDate: Date, endDate: Date, callback: (identityId: string, updatedOn: Date)=>void) => {
  const handleSubscription = useCallback((previous: any, response: any) => {
    if (!response.availabilityExceptionChange) return;
    const date = new Date(response.availabilityExceptionChange.date);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // remove time components
    date.setHours(0, 0, 0, 0);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    if (date >= start && date <= end) {
      callback(response.availabilityExceptionChange.identityId, convertToUtcDate(response.availabilityExceptionChange.updatedOn)!);
    }
  }, [startDate, endDate, callback]);

  useSubscription({
      query: AVAILABILITY_EXCEPTION_CHANGE_SUBSCRIPTION,
    },
    handleSubscription);
};