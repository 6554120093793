import { Button } from '@/components/button';
import { Modal } from '@/components/modal/Modal';
import { useDeleteTemplateMutation } from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  templateIdToDelete: string;
  publishedTemplateIdToDelete?: string;
  templateName: string;
  onComplete: () => void;
  force?: boolean;
}

export const TemplateDeleteModal: FC<Props> = ({
  open,
  onClose,
  templateIdToDelete,
  publishedTemplateIdToDelete,
  templateName,
  onComplete
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [{ fetching }, deleteTemplate] = useDeleteTemplateMutation();

  const onDelete = async () => {
    const result = await deleteTemplate({ id: templateIdToDelete });
    if (result.error) {
      setError(strings.daily.templates.myTemplates.deleteModal.generalError);
    } else {
      if (publishedTemplateIdToDelete) {
        deleteTemplate({ id: publishedTemplateIdToDelete });
      }
      onComplete();
      onClose();
    }
  };

  const closeAndClear = () => {
    onClose();
    setTimeout(() => {
      setError(undefined);
    }, 500);
  };

  return (
    <Modal onClose={closeAndClear} open={open} title={strings.daily.templates.myTemplates.deleteModal.title}>
      <div className="space-y-4">
        <div className="space-y-2">
          <span className="font-semibold text-sm">
            {strings.daily.templates.myTemplates.deleteModal.deleteConfirmation(templateName)}
          </span>
        </div>
        {error && <p className="text-red-500 text-xs">{error}</p>}
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={closeAndClear}>{strings.common.cancel}</Button>
          <Button onClick={onDelete} loading={fetching} variant={'destructive'}>
            {strings.common.delete}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
