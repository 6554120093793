import { useMemo } from 'react';
import { useLocalSettings } from '../settings/LocalSettingsProvider';
import { useDaysInCurrentMonth } from '../settings/useDates';
import { useOptimiserService } from './OptimiserService';
import { dateToApiFormat } from '@/helpers/dateHelper';

export const useCurrentOptimiserJob = () => {
  const selectedLocationId = useLocalSettings(state => state.selectedLocationId);
  const currentDates = useDaysInCurrentMonth();

  const optimiserJobs = useOptimiserService(state => state.jobs);

  return useMemo(() => {
    const filteredJobs = optimiserJobs.filter(
      job =>
        job.locationId === selectedLocationId && dateToApiFormat(currentDates[0]) === dateToApiFormat(job.startDate)
    );

    if (filteredJobs.length === 0) {
      return undefined;
    }

    const sortedJobs = filteredJobs.sort((a, b) => {
      if (!a.created || !b.created) {
        throw Error("Notification create dates should have been set");
      }
      
      if (a.created > b.created) {
        return 1;
      } else if (b.created > a.created) {
        return -1;
      }

      return 0;
    });

    return { ...sortedJobs[sortedJobs.length - 1] };
  }, [selectedLocationId, currentDates, optimiserJobs]);
};
