import { dateToApiFormat } from '@/helpers/dateHelper';
import { useGetRosterQuery } from '@/services/gql/graphql.generated';
import { useGetRostersForMonth } from '@/services/rosters/useGetRostersForMonth';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

export interface RosterOption {
  rosterDate: string;
  id: string;
}

export interface RosterStaffOption {
  name: string;
  id: string;
}

export interface RosterOptionsStore {
  rosterOptions: RosterOption[];
  addRosterOption: (newOption: RosterOption) => void;
  refresh: () => void;
}

export const RosterOptionsContext = createContext<StoreApi<RosterOptionsStore> | null>(null);

export const RosterOptionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const selectedLocationId = useSelectedLocation().id;
  const { rosters, refetch } = useGetRostersForMonth(selectedLocationId);

  if (!rosters) {
    throw Error('Could not get Roster list');
  }

  const store = useMemo(() => {
    return createStore<RosterOptionsStore>()((set, get) => ({
        rosterOptions: rosters as RosterOption[],
        addRosterOption: (newOption: RosterOption) => {
          const newOptions = [...get().rosterOptions];
          newOptions.push(newOption);
          set({ rosterOptions: newOptions });
        },
        refresh: refetch
      }));
     }, [rosters, refetch]);

  return <RosterOptionsContext.Provider value={store}>{children}</RosterOptionsContext.Provider>;
};

type SelectorReturn<S extends (s: RosterOptionsStore) => any> = ReturnType<S>;
export function useRosterService<S extends (s: RosterOptionsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(RosterOptionsContext);
  if (!context) {
    throw new Error('useRoster must be used within a RosterProvider');
  }
  return useStore(context, selector);
}

export const useSelectedRoster = () => {
  const { rosterOptions } = useRosterService(state => ({
    rosterOptions: state.rosterOptions
  }));

  const { selectedDate } = useLocalSettings(state => ({ selectedDate: state.selectedDate }));

  const selectedRosterId = rosterOptions.find(opt => opt.rosterDate.includes(selectedDate))?.id;

  const [{ data }] = useGetRosterQuery({
    variables: { id: selectedRosterId },
    pause: !selectedRosterId,
    requestPolicy: 'network-only'
  });

  return selectedRosterId ? data?.roster : undefined;
};

export const usePreviousDayRoster = () => {
  const { rosterOptions } = useRosterService(state => ({
    rosterOptions: state.rosterOptions
  }));

  const { selectedDate } = useLocalSettings(state => ({ selectedDate: state.selectedDate }));
  const previousDay = useMemo(() => {
    const previousDate = new Date(selectedDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return dateToApiFormat(previousDate);
  }, [selectedDate]);

  const selectedRosterId = rosterOptions.find(opt => opt.rosterDate.includes(previousDay))?.id;

  const [{ data }] = useGetRosterQuery({
    variables: { id: selectedRosterId },
    pause: !selectedRosterId,
    requestPolicy: 'network-only'
  });

  return selectedRosterId ? data?.roster : undefined;
};

export const useNextDayRoster = () => {
  const { rosterOptions } = useRosterService(state => ({
    rosterOptions: state.rosterOptions
  }));

  const { selectedDate } = useLocalSettings(state => ({ selectedDate: state.selectedDate }));
  const nextDay = useMemo(() => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    return dateToApiFormat(nextDate);
  }, [selectedDate]);

  const selectedRosterId = rosterOptions.find(opt => opt.rosterDate.includes(nextDay))?.id;

  const [{ data }] = useGetRosterQuery({
    variables: { id: selectedRosterId },
    pause: !selectedRosterId,
    requestPolicy: 'network-only'
  });

  return selectedRosterId ? data?.roster : undefined;
};
