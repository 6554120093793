import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { Tooltip, TooltipTypes } from './Tooltip';

interface Props {
  leaveName: string;
}

export const InactiveLeaveTooltip: FC<Props> = ({ leaveName }) => {
  return (
    <Tooltip type={TooltipTypes.warn}>
      {strings.settings.manageAvailability.inactiveLeaveToolTip(leaveName)}
    </Tooltip>
  );
};