import { LoadingIndicator } from '@/components/LoadingIndicator';
import { Button } from '@/components/button';
import { EditNotesFields } from '@/components/notes/NotesModal';
import { LocationSelector } from '@/pages/layout/main/LocationSelector';
import { PageHeader } from '@/pages/layout/main/PageHeader';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate } from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { FC, Suspense, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';
import { CreateReplaceTemplateMenu } from '../../createReplaceTemplate/CreateReplaceTemplateMenu';
import { Template } from '../Template';
import { TemplatePage } from '../TemplatePage';
import { TemplateMenu } from './TemplateMenu';
import { TemplateProvider, useSelectedTemplate, useTemplateService, useWorkingTemplate } from './TemplateService';
import { useGetUpdateTemplateFromWorkingTemplate } from './useGetUpdateTemplateFromWorkingTemplate';
import { useSaveTemplate } from './useSaveTemplate';

interface TemplateHeaderProps {
  isFetching: boolean;
}

const TemplateHeader: FC<TemplateHeaderProps> = ({ isFetching }) => {
  const { templateOptions, selectedTemplateId, onChangeTemplate } = useTemplateService(state => ({
    templateOptions: state.templateOptions,
    selectedTemplateId: state.selectedTemplateId,
    onChangeTemplate: state.setSelectedTemplateId
  }));

  const { workingTemplate, setWorkingTemplate } = useWorkingTemplate();
  const { saveTemplate, fetching } = useSaveTemplate();

  const onSave = async () => {
    const result = await saveTemplate();

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  };

  const onClear = () => {
    if (!workingTemplate) {
      throw Error('Working template should be defined.');
    }

    workingTemplate.shifts = [];

    // reset the scratchpad
    workingTemplate.scratchpad = Array(workingTemplate.scratchpad.length).fill(0);

    setWorkingTemplate(workingTemplate);
  };

  const { saveNeeded } = useConfirm(state => ({ saveNeeded: state.confirmationNeeded }));

  const getUpdateTemplateFromWorkingTemplate = useGetUpdateTemplateFromWorkingTemplate(true);

  return (
    <>
      <PageHeader className="w-full justify-between">
        <div className="flex gap-5 items-center">
          <LocationSelector />
          {workingTemplate && (
            <div className="flex justify-end items-end flex-1">
              <CreateReplaceTemplateMenu
                templateId={workingTemplate?.id}
                updateTemplate={getUpdateTemplateFromWorkingTemplate(workingTemplate)}
                unsavedChanges={saveNeeded}
              />
            </div>
          )}
        </div>

        <div className='flex gap-5 items-center'>
          <Button className="w-30" onClick={onClear}>
            {strings.daily.templates.clearTemplate}
          </Button>

          <Button className="w-24" onClick={onSave} loading={fetching || isFetching} variant={saveNeeded ? 'submit' : undefined}>
            {strings.common.save}
          </Button>
        </div>
      </PageHeader>
      {templateOptions.length > 0 && (
        <PageHeader>
          {templateOptions.map(opt => (
            <Button
              size="sm"
              key={opt.id}
              variant={selectedTemplateId === opt.id ? 'active' : 'default'}
              onClick={() => onChangeTemplate(opt.id)}
              className="w-32"
            >
              {opt.name}
            </Button>
          ))}
          <div className="flex justify-end items-end flex-1">
            <TemplateMenu />
          </div>
        </PageHeader>
      )}
    </>
  );
};

interface TemplatePageContentProps {
  setIsFetching: (isFetching: boolean) => void;
}

const TemplatePageContent: FC<TemplatePageContentProps> = ({ setIsFetching }) => {
  const { workingTemplate, setWorkingTemplate } = useWorkingTemplate();
  const templateData = useSelectedTemplate();
  const { saveTemplate, fetching } = useSaveTemplate();

  useEffect(() => {
    setIsFetching(fetching);
  }, [fetching]);

  const submitNotes = async (data: EditNotesFields) => {
    if (!workingTemplate) {
      throw Error('Working template should be defined.');
    }
    workingTemplate.notes = data.notes;
    setWorkingTemplate(workingTemplate);
    saveTemplate();
  };

  return !workingTemplate ? (
    <LoadingIndicator />
  ) : (
    <Template
      workingTemplate={workingTemplate}
      setWorkingTemplate={setWorkingTemplate}
      submitNotes={submitNotes}
      templateData={templateData as ReadTemplate}
    />
  );
};

export const TemplateShopPage: FC = () => {
  // because both TemplateHeader and TemplatePageContent use saveTemplate,
  // the fetching state needs to be passed from TemplatePageContent to TemplateHeader
  // TemplateHeader has the save button
  const [isFetching, setIsFetching] = useState(false);

  return (
    <TemplatePage>
      <TemplateProvider>
        <TemplateHeader isFetching={isFetching} />
        <Suspense fallback={<LoadingIndicator />}>
          <TemplatePageContent setIsFetching={setIsFetching} />
        </Suspense>
      </TemplateProvider>
    </TemplatePage>
  );
};
