import { FC, useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../dragDrop/types';
import { useGanttService, useGanttSubsectionConfig } from './GanttProvider';
import { TimeSlot } from './ganttHelper';

export interface DropZoneItem {
  main?: boolean;
  type: string;
  context?: string;
}

interface Props {
  className?: string;
  onDrop: (item: DropZoneItem, start: number, end: number) => void;
  color: string;
  timeSlot: TimeSlot;
}

export const GanttDropZone: FC<Props> = ({ onDrop, className, color, timeSlot }) => {
  const { defaultMainLength, currentlyDragging, interval, minTime, maxTime } = useGanttService(state => ({
    defaultMainLength: state.defaultMainLength,
    currentlyDragging: state.currentlyDragging,
    interval: state.increment,
    minTime: state.minTime,
    maxTime: state.maxTime
  }));

  const getSubConfig = useGanttSubsectionConfig();

  const maxLength = maxTime - minTime;
  let dropZoneLength = getSubConfig(currentlyDragging)?.defaultLength ?? defaultMainLength;
  dropZoneLength = Math.min(dropZoneLength, maxLength);
  
  // The 'dropZone' is defined to be the area where a new gantt section would be created.
  const { dropZoneStart, dropZoneEnd } = useMemo(() => {
    const lengthInSlots = dropZoneLength / interval;
    
    // By default, we try to create a new section with the timeslot in the center.
    let newDropZoneStart: number = timeSlot.end - dropZoneLength / 2;
    if (lengthInSlots % 2 !== 0) {
      // For odd length sections, we need to nudge it back by half a slot- otherwise we'll be halfway between two slots.
      newDropZoneStart -= interval / 2;
    }

    let newDropZoneEnd = newDropZoneStart + dropZoneLength;

    // If this would put the drop zone before the start of the slider, instead put the drop zone
    // at the start of the slider.
    if (newDropZoneStart < minTime) {
      newDropZoneStart = minTime;
      newDropZoneEnd = newDropZoneStart + dropZoneLength;
    }

    // If this would put the drop zone over the end of the slider, instead put the drop zone
    // at the end of the slider.
    if (newDropZoneEnd > maxTime) {
      newDropZoneEnd = maxTime;
      newDropZoneStart = newDropZoneEnd - dropZoneLength;
    }

    return { dropZoneStart: newDropZoneStart, dropZoneEnd: newDropZoneEnd };
  }, [dropZoneLength, timeSlot, minTime, maxTime, interval]);

  const dropHandler = useCallback(
    (item: DropZoneItem) => {
      onDrop(item, dropZoneStart, dropZoneEnd);
    },
    [dropZoneStart, dropZoneEnd, onDrop]
  );

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: [ItemTypes.ROSTER_SHIFT, ItemTypes.TEMPLATE_SHIFT, ItemTypes.BREAK, ItemTypes.TASK],
      drop: dropHandler,
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [dropHandler]
  );
  
  // Calculate how wide the preview bar should be, as a percentage of a slot width.
  const previewWidthSlots = dropZoneLength / interval;
  // Don't let the preview width get to 0- zero length tasks actually appear as a
  // quarter of a slot, just to show where they'll be.
  const previewWidthPercentage = Math.max(previewWidthSlots * 100, 25);

  // Calculate how far left do we need to shift the bar to make the start of the start of the drop
  // zone agree with the start of the bar.
  const previewLeftShiftInSeconds = dropZoneStart - timeSlot.start;
  
  // If we'd be part way between two slots, round the value UP.
  // It's important that we round up at this point, because when we calculate the drop zone limits,
  // we subtract half a slot if it's between two. So the preview bar should shift left, in line with this.
  const previewLeftShiftInSlots = Math.ceil(previewLeftShiftInSeconds / interval);
  const previewLeftShiftPercentage = previewLeftShiftInSlots * 100;

  return (
    <div className={className} ref={dropRef} style={{ pointerEvents: !currentlyDragging ? 'none' : 'all' }}>
      {isOver && (
        <div
          className="pointer-events-none opacity-20 h-[100%]"
          style={{
            background: color,
            width: `${previewWidthPercentage}%`,
            marginLeft: `${previewLeftShiftPercentage}%`
          }}
        ></div>
      )}
    </div>
  );
};
