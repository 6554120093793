import { dateToApiFormat } from '@/helpers/dateHelper';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { FC } from 'react';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { addDays, addMonths, addWeeks, addYears, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { CalendarPeriodType } from './dateSelector.types';
('date-fns');


interface Props {
  showNavigationArrows?: boolean;
  requestedPeriod?: CalendarPeriodType;
}

export const DateSelector: FC<Props> = ({ showNavigationArrows, requestedPeriod }) => {
  const { selectedDate, setSelectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate,
    setSelectedDate: state.setSelectedDate
  }));

  const changeDate = (isPrevious?: boolean): void => {
    let currentDate = new Date(selectedDate);
    const changeValue = 1;

    switch (requestedPeriod) {
      case CalendarPeriodType.WEEKS:
        currentDate = (isPrevious ? subWeeks : addWeeks)(currentDate, changeValue);
        break;
      case CalendarPeriodType.MONTHS:
        currentDate = (isPrevious ? subMonths : addMonths)(currentDate, changeValue);
        break;
      case CalendarPeriodType.YEARS:
        currentDate = (isPrevious ? subYears : addYears)(currentDate, changeValue);
        break;
      default:
        currentDate = (isPrevious ? subDays : addDays)(currentDate, changeValue);
        break;
    }

    setSelectedDate(dateToApiFormat(currentDate));
  };

  return (
    <div className="flex items-center gap-1">
      {showNavigationArrows && (
        <AiFillCaretLeft className="cursor-pointer hover:text-blue-400 h-5 w-5" onClick={e => changeDate(true)} />
      )}
      <input
        className="border-border rounded-md"
        type="date"
        value={selectedDate}
        onChange={e => {
          setSelectedDate(dateToApiFormat(new Date(e.target.value)));
        }}
      />
      {showNavigationArrows && (
        <AiFillCaretRight className="cursor-pointer hover:text-blue-400 h-5 w-5" onClick={e => changeDate()} />
      )}
    </div>
  );
};
