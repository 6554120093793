import { useEditSettingsService } from '@/pages/settings/services/EditSettingsService';
import { Location } from '@/services/gql/graphql.generated';
import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { SkillType } from '@/services/tasks/skillSettings.types';
import { useActiveSkills } from '@/services/tasks/useSkills';
import { useAllLocations } from '@/services/useLocations';
import {
  StaffSettingsFilterOptions,
  useFilterStaffSettings
} from '@/services/settings/useFilterStaffSettings';
import {useSelectedLocation} from "@/services/settings/LocalSettingsProvider";

export interface PdfStaffSkillsProps {
  staffSettings: StaffWithSettings[];
  locations: Location[];
  skills: SkillType[];
}

export const usePdfStaffSkillsProps = (filterOption: StaffSettingsFilterOptions) => {
  const { settings } = useEditSettingsService(state => ({
    settings: state.workingStaffSettings.filter(s => !s.settings.hasBeenDeleted)
  }));

  let filteredSettings = useFilterStaffSettings(settings, filterOption);
  const selectedLocationId = useSelectedLocation().id;
  filteredSettings = filteredSettings.filter(s => s.employee.homeLocationId === selectedLocationId);

  const locations = useAllLocations() as Location[];

  const skills = useActiveSkills();

  const props: PdfStaffSkillsProps = {
    staffSettings: filteredSettings,
    locations,
    skills
  };

  return props;
};
