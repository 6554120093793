import { ErrorMessage } from '@/components/error/ErrorMessage';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useBudgetService } from '../BudgetService';
import { MonthlyBudgetLocation } from './MonthlyBudgetLocation';

export const MonthlyBudget: FC = () => {
  const { budgetData, setTarget } = useBudgetService(state => ({
    budgetData: state.locations,
    setTarget: state.setTarget
  }));

  if (budgetData.length === 0) {
    return <ErrorMessage message={strings.budget.noTargets} />;
  }

  return (
    <div className="overflow-y-scroll overflow-x-hidden">
      <div className="w-screen">
        {budgetData.map((loc, index) => (
          <MonthlyBudgetLocation
            key={loc.id}
            locationIndex={index}
            location={loc}
            setForecast={setTarget(loc.id, 'locationForecast')}
            setSalesTarget={setTarget(loc.id, 'financialTarget')}
          />
        ))}
      </div>
    </div>
  );
};
