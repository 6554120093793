import { TemplateLibraryOption } from '@/pages/daily/template/library/TemplateLibraryService';
import { useCallback, useMemo } from 'react';
import { ReadTemplate, useGetLibraryTemplatesQuery } from '../gql/graphql.generated';

export const useTemplateLibraryOptions = () => {
  const [{ data }, refetch] = useGetLibraryTemplatesQuery();

  const templateOptions = useMemo(() => {
    if (!data?.templatesForLocation) {
      throw Error('Could not get template list');
    }

    const libraryTemplates = data.templatesForLocation.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }

      return a.name > b.name ? 1 : -1;
    });

    const templateOptions = libraryTemplates.map(template => {
      let name = template.name;

      const option: TemplateLibraryOption = {
        name: name,
        template: template as ReadTemplate,
        id: template.id
      };

      return option;
    });

    return templateOptions;
  }, [data]);

  const refetchNetworkOnly = useCallback(() => refetch({ requestPolicy: 'network-only' }), [refetch]);

  return { templateOptions, refetch: refetchNetworkOnly };
};

export const useDraftTemplateLibraryOptions = () => {
  const { templateOptions, refetch } = useTemplateLibraryOptions();

  const draftOptions = useMemo(() => {
    const filteredOptions = templateOptions.filter(t => t.template.draft ?? true); // t.template.draft can be null
    return filteredOptions.map(o => {
      const option: TemplateLibraryOption = { ...o };

      const publishedVersion = templateOptions.find(t => t.template.baseTemplateId === option.id);

      if (publishedVersion) {
        option.publishedVersionId = publishedVersion.id;
      }

      return option;
    });
  }, [templateOptions]);

  return { templateOptions: draftOptions, refetch };
};

export const usePublishedTemplateLibraryOptions = () => {
  const { templateOptions } = useTemplateLibraryOptions();

  return useMemo(() => templateOptions.filter(t => !t.template.draft), [templateOptions]);
};
