import { LoadingIndicator } from '@/components/LoadingIndicator';
import { FC, Suspense, useState } from 'react';
import { Roster } from './Roster';
import { RosterHeader } from './RosterHeader';
import { RosterProvider } from './providers/RosterService';
import {WorkPreferencesTrackingProvider} from "@/services/availability/WorkPreferencesTrackingService";
import {useLocalSettings} from "@/services/settings/LocalSettingsProvider";

export const RosterPage: FC = () => {
  const selectedDate = useLocalSettings(s => s.selectedDate);

  // because both TemplateHeader and TemplatePageContent use saveTemplate,
  // the fetching state needs to be passed from TemplatePageContent to TemplateHeader
  // TemplateHeader has the save button
  const [isSaveFetching, setIsSaveFetching] = useState(false);

  return (
    <div className="h-full flex flex-col">
      <Suspense fallback={<LoadingIndicator />}>
        <RosterProvider>
          <WorkPreferencesTrackingProvider startDate={selectedDate} endDate={selectedDate}>
            <RosterHeader isSaveFetching={isSaveFetching} />
            <Roster setIsSaveFetching={setIsSaveFetching} />
          </WorkPreferencesTrackingProvider>
        </RosterProvider>
      </Suspense>
    </div>
  );
};
