export const FORECAST_TARGET_TYPE = 'DailyForecastHours';
export const HQ_ALLOCATED_MONTH_TYPE = 'MonthlyBudgetedHours';
export const HQ_ALLOCATED_DAY_TYPE = 'DailyBudgetedHours';
export const SALES_DAY_TYPE = 'DailyBudgetedMoney';
export const SALES_MONTH_TYPE = 'MonthlyBudgetedMoney';

export interface WorkingBudgetLocation {
  id: number;
  days: WorkingBudgetDay[];
  hqAllocatedMonthlyTime?: number;
  hqAllocatedMonthlyBudget?: number;
}

export interface WorkingBudgetShift { 
  start: number,
  end: number
}

export interface WorkingBudgetDay {
  date: Date;
  locationForecast: number;
  rosteredBudget: number;
  hqAllocatedHours: number;
  financialTarget: number;
  filledShiftCount: number;
  totalShiftCount: number;
  unfilledShifts: WorkingBudgetShift[];
  notes?: string;
}

export interface BudgetStats {
  totalHq: number;
  totalRostered: number;
  totalLocationForecast: number;
  monthlyDelta: number;
  rosteredBudget: number;
  totalShifts: number;
  totalFilledShifts: number;
}