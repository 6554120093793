import { WorkingShift } from "@/pages/daily/daily.types";
import { useEmployeesSkillsList } from "@/pages/daily/employeeSelector/useEmployeeSkillsList";
import { useSortEmployees } from "@/pages/daily/employeeSelector/useSortEmployees";
import {
  AvailableShiftSlot,
  displayNameWithShiftCount,
  getStatus
} from "@/pages/daily/roster/employeeSelector/useEmployeesForShift";
import { useWorkingTemplate } from "@/pages/daily/template/shop/TemplateService";
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import {
  ReadEmployee,
  ReadEmployeeSkills,
  useLocationWorkingPatternsQuery
} from '@/services/gql/graphql.generated';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useEmployeeList } from "@/services/staff/useEmployeeList";
import { displayName } from "@/services/translation/name";
import { useCallback, useMemo } from 'react';
import { AllocationStatus, EmployeeForShiftItem, EmployeeSkills, EmployeeStatus } from '../../employeeSelector/employeeForShift.types';
import { useTaskMatch } from '../../employeeSelector/useTaskMatch';
import { getAvailableSlots, getSelectedAvailableSlot, getTimeAvailable, RosterGeneralShift } from '../../roster/rosterHelper';

export const useBuildEmployeesForTemplateShift = () => {
  const { homeEmployees } = useEmployeeList({ date: new Date() });
  const { workingTemplate } = useWorkingTemplate();

  const selectedLocationId = useSelectedLocation().id;

  const [{ data: locationWorkingPatternsData }] = useLocationWorkingPatternsQuery({
    variables: {
      locationId: selectedLocationId
    },
    requestPolicy: 'network-only'
  });

  const workingPatterns = locationWorkingPatternsData?.locationWorkingPatterns?.employeePatterns;
  if (!workingPatterns) {
    throw Error('Could not get working patterns');
  }

  const employeeIds = homeEmployees.map(e => e.identityId);
  const employeesSkillsList: EmployeeSkills[] = useEmployeesSkillsList(employeeIds);

  const { maxNumber: maxShifts, minSecondsBetween: secondsBetween } = useSystemSettings(
    state => state.settings.splitShifts
  );

  const taskMatch = useTaskMatch();
  const sortEmployees = useSortEmployees();
  const dayNumber = workingTemplate?.dayNumber;
  const templateLibrary = dayNumber === undefined;

  return useCallback(
    (shift: WorkingShift) => {
      const getTaskMatch = taskMatch(shift);

      const employees: EmployeeForShiftItem[] = [];
      homeEmployees.forEach(employee => {
        const pattern = workingPatterns.find(p => p.identityId === employee.identityId)?.periods![0].patterns?.find(p => p.dayNumber === dayNumber);
        const skills = (employeesSkillsList.find(e => e.employeeId === employee.identityId)?.skills ||
          []) as ReadEmployeeSkills[];

        const startTime = pattern?.startTime || 0;
        const endTime = pattern?.endTime || 0;

        const shifts: RosterGeneralShift[] =
          workingTemplate?.shifts.filter(s => s.assignedEmployeeId === employee.identityId) || ([] as RosterGeneralShift[]);

        const availableSlots: AvailableShiftSlot[] = shifts
          ? getAvailableSlots(shifts, startTime, endTime, secondsBetween)
          : [{ start: startTime, end: endTime }];
        const selectedAvailableSlot = getSelectedAvailableSlot(availableSlots, shift?.start, shift?.end);

        const assignedShifts = workingTemplate?.shifts.filter(shift => shift.assignedEmployeeId === employee.identityId) || [];

        const status: EmployeeStatus =
          (templateLibrary || shift.assignedEmployeeId === employee.identityId)
              ? EmployeeStatus.AVAILABLE
              : getStatus(shift, selectedAvailableSlot);

        let allocationStatus = AllocationStatus.NOT_ALLOCATED;
        if (assignedShifts.length >= maxShifts) {
          allocationStatus = AllocationStatus.FULLY_ALLOCATED;
        } else if (assignedShifts.length > 0) {
          allocationStatus = AllocationStatus.PARTLY_ALLOCATED;
        }

        const dropdownItem: EmployeeForShiftItem = {
          id: employee.identityId,
          name: displayNameWithShiftCount(displayName(employee), shift, maxShifts, assignedShifts),
          contractId: employee.contract?.contractTypeId || '',
          status,
          timeAvailable: [getTimeAvailable({ start: startTime, end: endTime })],
          role: employee.role,
          leaveType: undefined,
          allocated: allocationStatus,
          assignable: true,
          employeeId: employee.employeeId?.toString() || '',
          details: employee as ReadEmployee,
          skills: skills,
          taskMatch: getTaskMatch(skills)
        };

        // If unavailable or allocated already, can't be reassigned.
        if (
          dropdownItem.status === EmployeeStatus.NOT_AVAILABLE ||
          dropdownItem.allocated === AllocationStatus.FULLY_ALLOCATED
        ) {
          dropdownItem.assignable = false;
        }

        // If the availability state is a fixed shift, we need to override all availability information.
        if (dropdownItem.leaveType === FixedLeaveTypeId.ROSTER) {
          dropdownItem.assignable = false;
          dropdownItem.status = EmployeeStatus.NOT_AVAILABLE;
          dropdownItem.timeAvailable = ['00:00 ~ 00:00'];
        }

        /*
        NOTE: THIS MUST BE AT THE END OF THE CHECKS!!!
        */
        // Exception- if a staff member is already assigned to this shift, they need to be assignable.
        // Attempting to assign someone twice causes an un-assign, so we need this to undo cases where someone's accidentally
        // been assigned when they're unavailable.
        if (shift.assignedEmployeeId === dropdownItem.id) {
          dropdownItem.assignable = true;
        }

        employees.push(dropdownItem);
      });

      return sortEmployees(employees, shift);
    },
    [
      workingTemplate,
      employeesSkillsList,
      taskMatch,
      maxShifts,
      secondsBetween,
      dayNumber,
      homeEmployees,
      workingPatterns
    ]
  );
};

export const useEmployeesForTemplateShift = (shift: WorkingShift) => {
  const buildAvailableEmployees = useBuildEmployeesForTemplateShift();

  return useMemo(
    () => buildAvailableEmployees(shift),
    [shift, buildAvailableEmployees]
  );
};
