import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  text: string;
  label: string;
  color?: 'green' | 'blue';
}

export const HeaderInfo: FC<Props> = ({ text, label, color }) => {
  let className = 'font-semibold';

  switch (color) {
    case 'blue':
      className = twMerge(className, 'text-blue-500');
      break;
    case 'green':
      className = twMerge(className, 'text-green-500');
      break;
  }

  return (
    <div className="flex flex-col items-center text-center">
      <p className={className}>{label}</p>
      <p>{text}</p>
    </div>
  );
};
