import { Button } from '@/components/button';
import { Modal } from '@/components/modal/Modal';
import { ReadTemplate } from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';
import { useCreateTemplate } from '../useCreateTemplate';
import { Tag, TagInput } from '@/components/tags/TagInput';
import { useFlag } from '@softwareimaging/backstage';
import { twMerge } from 'tailwind-merge';

interface Props {
  open: boolean;
  onClose: () => void;
  onComplete: (newTemplate: ReadTemplate) => void;
  validateName: (newName: string) => string | null;
  library?: boolean;
}

export const TemplateAddModal: FC<Props> = ({ open, onClose, onComplete, validateName, library }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>('');
  const [tagList, setTagList] = useState<Tag[]>([]);

  const { create, fetching } = useCreateTemplate(library);

  const onChange = (newVal: string) => {
    setInputValue(newVal);
    setError(undefined);
  };

  const onCreate = async () => {
    const errorMessage = validateName(inputValue);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    const result = await create(inputValue, tagList);
    const newTemplate = result.data?.createTemplate as ReadTemplate;

    if (result.error || !newTemplate) {
      setError(strings.daily.templates.myTemplates.addModal.generalError);
    } else {
      onClose();
      onComplete(newTemplate);
    }
  };

  const closeAndClear = () => {
    onClose();
    setTimeout(() => {
      setInputValue('');
      setError(undefined);
    }, 500);
  };

  const useTags = useFlag('enableTags');

  return (
    <Modal onClose={closeAndClear} open={open} title={strings.daily.templates.myTemplates.addModal.title}>
      <div className={twMerge("space-y-4", useTags ? "w-[800px]" : "")}>
        <div className="space-y-2">
          <span className="font-semibold text-sm">
            {strings.daily.templates.myTemplates.addModal.enterTemplateName}
          </span>
          <input
            className="w-full rounded-md border-border text-base"
            value={inputValue}
            onChange={e => onChange(e.target.value)}
            type="text"
            required
          ></input>
          {useTags && (
            <TagInput
              tags={tagList}
              setTags={setTagList}
              label={strings.daily.templates.tags.label}
              description={strings.daily.templates.tags.description}
              placeholder={strings.daily.templates.tags.placeholder}
            />
          )}
          {error && <p className="text-red-500 text-xs">{error}</p>}
        </div>
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={closeAndClear}>{strings.common.cancel}</Button>
          <Button onClick={onCreate} loading={fetching}>
            {strings.common.ok}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
