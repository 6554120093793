import React, {FC} from "react";
import {SummaryEmployeeRow, SummaryEmployeeRowStyle} from "@/pages/summary/SummaryEmployeeRow";
import {SummaryEmployee} from "@/services/summary/summary.types";
import {useWorkPreferencesTracking} from "@/services/availability/WorkPreferencesTrackingService";

interface Props {
  employees: SummaryEmployee[];
  days: Date[];
  showCompliance?: boolean;
  advancedLeaveCols?: string[];
}

export const SummaryEmployeeTable: FC<Props> = ({employees, days, showCompliance, advancedLeaveCols}) => {
  useWorkPreferencesTracking(days[0], days[days.length - 1]);
  const weekly = days.length <= 7;
  return (<>
    {employees.map(employee => (
        <SummaryEmployeeRow
          days={days}
          employee={employee}
          key={employee.id}
          rowStyle={weekly ? SummaryEmployeeRowStyle.WEEK : SummaryEmployeeRowStyle.MONTH}
          isWeeklyShiftsPage={weekly ? true : undefined}
          isMonthlyShiftsPage={weekly ? undefined : true}
          showCompliance={showCompliance}
          advancedLeaveCols={advancedLeaveCols}
        />
      ))
    }
    </>
  );
};
