import { UpdateTemplate, useCreateTemplateMutation, useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';

export const useCreateTemplate = () => {
  const [{ error }, update] = useUpdateTemplateMutation();
  const [responseData, create] = useCreateTemplateMutation();

  const createTemplate = useCallback(
    async (requestedTemplate: UpdateTemplate) => {
      const result = await create({
        locationId: requestedTemplate.locationId,
        name: requestedTemplate.name
      });

      const newTemplate = result.data?.createTemplate;

      if (!newTemplate) {
        throw Error('Template create failed!');
      }

      const populateTemplate = await update({
        updateTemplate: {
          ...requestedTemplate,
          id: newTemplate.id
        }
      });

      if (populateTemplate?.error) {
        throw Error('Importing roster data to the new template failed!');
      }

      return result;
    },
    [create]
  );

  return { createTemplate, error, fetching: responseData.fetching };
};
