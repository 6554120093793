import { format } from 'date-fns';
import { FC } from 'react';
import { HeaderInfo } from './HeaderInfo';

interface Props {
  timestamp: Date;
  label: string;
  color?: 'green' | 'blue';
}

export const Timestamp: FC<Props> = ({ timestamp, label, color }) => {
  return <HeaderInfo color={color} label={label} text={format(timestamp, 'yyyy/MM/dd (HH:mm)')} />;
};
