import { View } from '@react-pdf/renderer';
import {FC, ReactNode} from 'react';
import {Style} from "@react-pdf/types";
import {PdfTableCell} from "@/pages/pdf/PdfTableCell";
import {styles} from "@/pages/pdf/PdfStyles";
import {PdfPage} from "@/pages/pdf/PdfPage";
import {TaskType} from "@/services/tasks/task.types";
import {PdfTaskRow, PdfTaskRowHeader} from "@/pages/pdf/settings/PdfTaskRow";
import {SkillType} from "@/services/tasks/skillSettings.types";

interface Props {
  header: ReactNode
  pages: TaskType[][]
  skills: SkillType[]
}

export const MONTHLY_NAME_WIDTH = 100;

export const PdfTaskPages: FC<Props> = ({ header, pages, skills }) => {
  const date = new Date();

  const pageStyle: Style = {paddingHorizontal: 30, paddingVertical: 20, fontFamily: 'Nasu'}

  return (<>
    <PdfPage datePrinted={date} orientation={'landscape'} headerContent={header} pageStyle={pageStyle}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <PdfTableCell width={MONTHLY_NAME_WIDTH} />
        </View>
        <PdfTaskRowHeader/>
        {pages[0].map(task => (
          <PdfTaskRow key={task.id} task={task} skills={skills} />
        ))}
      </View>
    </PdfPage>
    {pages.slice(1).map((page, index) => (
      <PdfPage
        key={index}
        datePrinted={date}
        headerContent={header}
        orientation={'landscape'}
        pageStyle={pageStyle}
      >
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <PdfTableCell width={MONTHLY_NAME_WIDTH} />
          </View>
          {page.map(task => (
            <PdfTaskRow key={task.id} task={task} skills={skills} />
          ))}
        </View>
      </PdfPage>
    ))}
    </>
  );
};
