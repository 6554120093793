import { Button } from '@/components/button';
import { PdfAvailabilityParams } from "@/pages/pdf/availability/PdfAvailabilityDay";
import { MonthlyAvailabilityAsPDF } from "@/pages/pdf/availability/PdfAvailabilityDocument";
import { StaffAvailabilityMonth } from "@/services/availability/useStaffAvailability";
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useLanguageProvider } from "@/services/settings/LanguageProvider";
import { useSelectedLocation } from "@/services/settings/LocalSettingsProvider";
import { strings } from '@/services/translation/strings';
import { pdf } from '@react-pdf/renderer';
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { FC, useCallback } from 'react';

interface Props {
  days: Date[];
  availabilityForMonth: StaffAvailabilityMonth[];
}

const DownloadAvailabilityButton: FC<Props> = ({ days, availabilityForMonth }) => {
  const selectedLocation = useSelectedLocation();
  const language = useLanguageProvider(s => s.language);
  const leaveTypes = useLeaveTypes();
  const params: PdfAvailabilityParams = { leaveTypes, locationId: selectedLocation.id.toString(), locationName: selectedLocation.name };

  const onPrint = useCallback(async () => {
    const fileName = strings.settings.pages.availability.print.fileName(format(days[0], 'yyyy/MM/dd'));
    const blob = await pdf(<MonthlyAvailabilityAsPDF
      days={days}
      availabilityForMonth={availabilityForMonth}
      language={language}
      params={params}
    />).toBlob();
    saveAs(blob, fileName);
  }, [days, language, availabilityForMonth, params]);

  return (
    selectedLocation && <Button onClick={onPrint}>
      {strings.common.print}
    </Button>
  );
};

export default DownloadAvailabilityButton;
