import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import {FC} from 'react';
import {format} from "date-fns";

export interface Note {
  date: Date;
  text: string;
}

interface Props {
  notes?: Note[];
  dateWidth: number;
  contentWidth: number;
}

export const PdfRosterNotes: FC<Props> = ({notes, dateWidth, contentWidth}) => {
  if (!notes || notes.length === 0) return null;

  return <View style={{width: '100%', marginTop: 10}}>
    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
      {strings.common.notesPrintLabel}
    </Text>
    {notes.map((note, index) => (
      <View key={index} style={{display: 'flex', flexDirection: 'row'}}>
        <Text style={{
          width: dateWidth,
          fontSize: 12,
          fontWeight: 'bold'
        }}>
          {format(note.date, 'yyyy/MM/dd:')}
        </Text>
        <Text style={{
          width: contentWidth,
          fontSize: 12
        }}>{note.text}</Text>
      </View>
    ))}
  </View>;
};
