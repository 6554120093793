import { dateToApiFormat, getEmployeeAge } from '@/helpers/dateHelper';
import { ReadEmployee, useGetEmployeeSkillsQuery } from '@/services/gql/graphql.generated';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { useMyLocations } from '@/services/useLocations';
import { format } from 'date-fns';
import { FC } from 'react';
import {FiCalendar, FiFileText, FiMapPin, FiSend, FiUser, FiUserPlus} from 'react-icons/fi';
import { MdNoteAlt } from 'react-icons/md';
import { Tooltip } from './Tooltip';
import {BsCheckCircleFill, BsExclamationTriangleFill} from "react-icons/bs";
import {SeverityColor} from "@/components/warning/warningDefinitions";

interface Props {
  employee?: ReadEmployee;
  workPrefUpdatedOn?: Date;
}

const EmployeeTooltipContent: FC<{ employee: ReadEmployee, workPrefUpdatedOn?: Date }> = ({ employee, workPrefUpdatedOn }) => {
  const [{ data: skillsData }] = useGetEmployeeSkillsQuery({ variables: { employeeIds: [employee.identityId] } });

  const properties = employee.properties ? JSON.parse(employee.properties) : {};

  const skillsList = skillsData?.employeeSkillsByEmployeeIds;
  if (!skillsList || skillsList.length !== 1) {
    throw Error('Could not get employee skills list');
  }

  const employeeSkillNames = skillsList[0].skills?.map(s => s.name);
  const employeeSkillsString = employeeSkillNames?.join(', ');

  const endOfProbation = employee.endOfProbationDate;
  const endOfProbationDate = endOfProbation ? new Date(endOfProbation) : undefined;
  const today = new Date();

  let rookieEmployee = false;
  if (endOfProbationDate && today < endOfProbationDate) {
    rookieEmployee = true;
  }

  const foreignStudent = employee.contract?.foreigner;

  const locations = useMyLocations();
  const locationName = locations.find(l => l.id === employee.homeLocationId)?.name;

  const canMentor = employee.mentor;
  const needsMentor = employee.mentee;

  let employeeAge = null;
  if (employee.dateOfBirth) {
    employeeAge = getEmployeeAge(employee.dateOfBirth);
  }

  let leavingString = '';
  if (employee.leaveDate) {
    const leavingDate = new Date(employee.leaveDate);

    const stringBuilder =
      dateToApiFormat(leavingDate) >= dateToApiFormat(today)
        ? strings.daily.roster.tooltip.leaving
        : strings.daily.roster.tooltip.left;
    leavingString = stringBuilder(format(leavingDate, 'yyyy/MM/dd'));
  }

  return (
    <div className="font-regular space-y-2">
      <div className="flex justify-between gap-5">
        <div>{displayName(employee)}</div>
        <div className="flex items-center gap-1">
          <FiMapPin />
          <span>{locationName}</span>
        </div>
      </div>
      <div className="flex items-center gap-2 pb-1 mb-1">
      {
        workPrefUpdatedOn ? <>
          <BsCheckCircleFill color={SeverityColor.LOW}/>
          <span>{strings.daily.roster.tooltip.lastUpdated}</span>
          <span>{format(workPrefUpdatedOn, 'yyyy/MM/dd HH:mm')}</span>
        </> : <>
          <BsExclamationTriangleFill color={SeverityColor.WARNING}/>
          <span>{strings.daily.roster.tooltip.lastUpdated}</span>
          <span>-</span>
        </>
      }
      </div>
      {employee.personas && employee.personas.length > 0 && (
        <div className="flex items-center gap-2 border-b border-black pb-1 mb-1">
          <FiUser />
          <div className="flex flex-col gap-1">
            {employee.personas.map(persona => {
              return (
                <span>
                  {persona.name}
                  {persona.management && ` ${strings.daily.roster.tooltip.management}`}
                </span>
              );
            })}
          </div>
        </div>
      )}
      {rookieEmployee && endOfProbationDate && (
        <div className="flex items-center gap-2">
          <FiSend />
          <span>{strings.daily.roster.tooltip.probationary(format(endOfProbationDate, 'yyyy/MM/dd'))}</span>
        </div>
      )}
      {foreignStudent && (
        <div className="flex items-center gap-2">
          <FiUser />
          <span>{strings.daily.roster.tooltip.foreignStudent}</span>
        </div>
      )}
      {canMentor && (
        <div className="flex items-center gap-2">
          <FiUserPlus />
          <span>{strings.daily.roster.tooltip.canMentor}</span>
        </div>
      )}
      {needsMentor && (
        <div className="flex items-center gap-2">
          <FiUserPlus />
          <span>{strings.daily.roster.tooltip.requiresMentor}</span>
        </div>
      )}
      {employeeAge !== null && employeeAge < 18 && (
        <div className="flex items-center gap-2">
          <FiUserPlus />
          <span>{strings.daily.roster.tooltip.notAdult}</span>
        </div>
      )}
      {employeeAge === null && (
        <div className="flex items-center gap-2">
          <FiUserPlus />
          <span>{strings.daily.roster.tooltip.ageNotSpecified}</span>
        </div>
      )}
      {leavingString && (
        <div className="flex items-center gap-2">
          <FiCalendar />
          <span>{leavingString}</span>
        </div>
      )}
      {employeeSkillsString && (
        <div className="flex items-center gap-2 border-t border-black pt-1 mt-1">
          <FiFileText className="flex-shrink-0" />
          <div className="">{employeeSkillsString}</div>
        </div>
      )}
      {properties && properties.notes && (
        <div className="flex items-center gap-2 border-t border-black pt-1 mt-1">
          <MdNoteAlt className="flex-shrink-0" />
          <div className="max-h-32 overflow-y-auto whitespace-pre-wrap">{properties.notes}</div>
        </div>
      )}
    </div>
  );
};

export const EmployeeTooltip: FC<Props> = ({ employee, workPrefUpdatedOn }) => {
  return (
    <Tooltip
      iconColorOverride={workPrefUpdatedOn ? SeverityColor.LOW : "#9FA6B2"}
      solidIcon={workPrefUpdatedOn ? true : false}>
      {employee ? <EmployeeTooltipContent employee={employee} workPrefUpdatedOn={workPrefUpdatedOn}/> : strings.daily.roster.tooltip.noneAssigned}
    </Tooltip>
  );
};
