import { secondsToTimeString } from '@/helpers/dateHelper';
import { deltaToColorClass } from '@/helpers/deltaHelper';
import { useBudgetForDay, useBudgetForLocation } from '@/services/budget/useBudget';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { strings } from '@/services/translation/strings';
import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useBudgetStats } from '../../../services/budget/useBudgetStats';
import { useRosterStats } from './providers/WorkingRosterService';

const RosterBudgetSummaryCell: FC<{ label: string; value: number; delta?: boolean }> = ({ label, value, delta }) => {
  let width = '';

  switch (true) {
    case value >= 0 && value < 359999:
      width = 'w-12';
      break;
    case value >= 359999 && value < 3600000:
      width = 'w-16';
      break;
    case value >= -359999 && value < 0:
      width = 'w-12';
      break;
    case value >= -3600000 && value < -359999:
      width = 'w-16';
      break;
    default:
      width = 'w-[70px]';
      break;
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-2 items-end">
        <div className="flex justify-end">{`${label}:`}</div>
        <div className={`flex flex-col gap-2 items-end ${width}`}>
          <div className={twMerge('flex flex-col font-semibold', delta ? deltaToColorClass(value) : '')}>
            <div className="flex flex-row justify-end">{secondsToTimeString(value, delta)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RosterHqAndSalesSummaryCell: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <div className="flex flex-col items-end">
      <p className="text-lg">{label}</p>
      <p className="text-lg font-bold">{value}</p>
    </div>
  );
};

export const RosterBudgetSummary: FC = () => {
  const rosterStats = useRosterStats();
  const daysInMonth = useDaysInCurrentMonth();
  const {
    totalLocationForecast,
    totalRostered,
    monthlyDelta,
    rosteredBudget
  } = useBudgetStats(daysInMonth, rosterStats);
  const { budgetData: budgetForLocation } = useBudgetForLocation(daysInMonth);
  const budgetForDay = useBudgetForDay();

  const hqBudgetToDisplay = useMemo(() => {
    const hqAllocatedMonthlyBudget = budgetForLocation.hqAllocatedMonthlyTime;
    if (hqAllocatedMonthlyBudget) {
      return secondsToTimeString(hqAllocatedMonthlyBudget);
    } else {
      return "--:--"
    }
  }, [budgetForLocation]);

  return (
    <div className="flex flex-row border-b bg-background-mid justify-between px-10 py-2">
      <div className="flex flex-row justify-between gap-14">
        <RosterHqAndSalesSummaryCell
          label={strings.daily.roster.budgetStats.totalHqAllocatedBudget}
          value={hqBudgetToDisplay}
        />
        <div className="flex flex-col items-start">
          <RosterBudgetSummaryCell
            value={totalLocationForecast}
            label={strings.daily.roster.budgetStats.monthlyLocationForecast}
          />
          <RosterBudgetSummaryCell value={totalRostered} label={strings.daily.roster.budgetStats.totalRostered} />
        </div>
        <div className="flex flex-col items-end">
          <RosterBudgetSummaryCell value={monthlyDelta} label={strings.daily.roster.budgetStats.monthlyDelta} delta />
        </div>
      </div>
      <div className="flex flex-row justify-between gap-14">
        <RosterHqAndSalesSummaryCell
          label={strings.daily.roster.budgetStats.monthlySales}
          value={
            budgetForLocation.hqAllocatedMonthlyBudget ? budgetForLocation.hqAllocatedMonthlyBudget.toString() : '-'
          }
        />
        <RosterHqAndSalesSummaryCell
          label={strings.daily.roster.budgetStats.dailySales}
          value={budgetForDay.financialTarget ? budgetForDay.financialTarget.toString() : '-'}
        />
        <div className="flex flex-col items-end">
          <RosterBudgetSummaryCell
            value={budgetForDay.locationForecast}
            label={strings.daily.roster.budgetStats.locationForecast}
          />
          <RosterBudgetSummaryCell value={rosteredBudget} label={strings.daily.roster.budgetStats.rosteredBudget} />
        </div>
        <div className="flex flex-col items-end">
          <RosterBudgetSummaryCell value={rosteredBudget - budgetForDay.locationForecast} label={strings.daily.roster.budgetStats.deltaTime} delta />
        </div>
      </div>
    </div>
  );
};
