import {useGetLocationListQuery} from "@/services/gql/graphql.generated";
import {DivisionData, LocationData} from "@/pages/settings/sections/organisationChart/OrganisationChart";


const getLocations = (locations?: LocationData[], children?: DivisionData[]) => {
  let result: LocationData[] = [...(locations ?? [])]

  if (children && children.length > 0) {
    const childLocations: LocationData[] = children.flatMap(child => {
      return getLocations(child.locations, child.children);
    });
    result = [...result, ...childLocations];
  }
  return result;
};

export const useOrphanedLocations = (hierarchy: DivisionData) => {
  const [{ data }]= useGetLocationListQuery(); // Get all locations in the system.
  const locations = data?.locations;

  if (!locations) {
    throw Error('The location data is missing!');
  }

  const locationsInHierarchy = getLocations(hierarchy.locations, hierarchy.children);

  const orphanedLocations: LocationData[] = [];
  locations.forEach(location => {
    if (!locationsInHierarchy.find(locationInHierarchy => locationInHierarchy.id === location.id)) {
      orphanedLocations.push(location);
    }
  });

  return orphanedLocations;
};
