import { useCallback } from "react";
import { useContractOptions } from "./useContractOptions";

export const useGetContractName = () => {
  const contractOptions = useContractOptions();
  
  return useCallback((id: string) => {
    const contract = contractOptions.find(cont => cont.id === id);

    return contract?.name || "";
  }, [contractOptions])
}