import { useVariable } from '@softwareimaging/backstage';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useRequestToken } from './authentication/hooks/auth';
import { useOrganisationId } from './authentication/hooks/user';

export const usePost = (successCallback?: (response?: unknown) => void, errorCallback?: (message: string) => void) => {
  const requestToken = useRequestToken();
  const baseUrl = useVariable('apiUrl') + '/api';
  const orgId = useOrganisationId();

  const request = useCallback(
    async ({
      body,
      endpoint,
      contentType,
      fileName
    }: {
      body: any;
      endpoint: string;
      contentType: string | undefined;
      fileName: string | undefined;
    }) => {
      const token = await requestToken();

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      headers.append('tenant-id', orgId);
      if (contentType !== undefined) {
        headers.append('Content-Type', contentType);
      }

      const response = await fetch(baseUrl + endpoint, {
        method: 'POST',
        headers: headers,
        body
      });

      if (response.ok) {
        if (fileName) {
          const blob = await response.blob();
          saveAs(blob, fileName);
        }
        try {
          return await response.json();
        } catch (e) {
          return response;
        }
      }

      return response.text().then(text => {
        throw new Error(text);
      });
    },
    [requestToken, baseUrl, orgId]
  );

  const mutation = useMutation(request, {
    onSuccess: (response?: unknown) => {
      successCallback && successCallback(response);
    },
    onError: (e: Error) => {
      errorCallback && errorCallback(e.message);
    }
  });

  const post = useCallback(
    (
      body: any,
      endpoint: string,
      contentType: string | undefined = undefined,
      fileName: string | undefined = undefined
    ) => {
      mutation.mutate({ body, endpoint: endpoint, contentType, fileName });
    },
    [mutation]
  );

  return {
    post,
    ...mutation
  };
};
