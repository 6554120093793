import { Button } from '@/components/button';
import { FileInput } from '@/components/form/FileInput';

import { Modal } from '@/components/modal/Modal';
import { useResetWorkingSettings } from '@/pages/settings/services/EditSettingsService';
import { StaffSkillImportResponse, useUploadSkills } from '@/services/file/useUploadSkills';
import { useStaffSettings } from '@/services/settings/StaffSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FunctionComponent, useState } from 'react';
import { FiUpload } from 'react-icons/fi';

const StaffSkillsImportButton: FunctionComponent = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const onClose = () => {
    setSuccessMessage('');
    setModalOpen(false);
    reset();

    // Wait a little bit before clearing the file- otherwise there's a weird flicker.
    setTimeout(() => setFile(undefined), 1000);
  };

  const refetchStaffSkills = useStaffSettings(state => state.refetchStaffSkills);
  const resetEditSettings = useResetWorkingSettings();

  const [successMessage, setSuccessMessage] = useState('');
  const onSuccesfulUpload = async (response: StaffSkillImportResponse) => {
    setSuccessMessage(strings.settings.manageSkills.import.success(response.staffUpdatedCount));
    await refetchStaffSkills();
    resetEditSettings();
  };

  const { upload, isLoading, error, reset } = useUploadSkills(onSuccesfulUpload);

  const onSubmit = async () => {
    if (file) {
      reset();
      await upload(file);
    }
  };

  return (
    <div>
      <Button onClick={() => setModalOpen(true)}>
        <FiUpload className="mr-2" />
        {strings.common.import}
      </Button>
      <Modal open={modalOpen} onClose={onClose} title={strings.common.upload}>
        <div className="space-y-4 max-w-2xl w-screen">
          {!successMessage && <FileInput file={file} setFile={setFile} fileExtension=".csv" />}
          {error && <p className="text-red-500">{error.message}</p>}
          {successMessage && <p>{successMessage}</p>}
          <div className="flex justify-end gap-5 items-center">
            {successMessage ? (
              <Button onClick={onClose}>{strings.common.close}</Button>
            ) : (
              <>
                <Button onClick={onClose}>{strings.common.cancel}</Button>
                <Button loading={isLoading} disabled={!file} onClick={onSubmit}>
                  {strings.common.submit}
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StaffSkillsImportButton;
