import {
  EMPLOYEE_DETAILS_COLUMN_WIDTH,
  EMPLOYEE_NAME_COLUMN_WIDTH
} from '@/pages/summary/constants';
import { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { AttendanceTotalColumns } from '../AttendanceTotalColumns';
import { ShiftTimePicker } from '../ShiftTimePicker';
import { DailyEditingType, WorkingShift } from '../daily.types';
import { EmployeeSelector } from "@/pages/daily/employeeSelector/EmployeeSelector";
import { EmployeeForShiftItem } from "@/pages/daily/employeeSelector/employeeForShift.types";
import {useEmployeesForTemplateShift} from "@/pages/daily/template/employeeSelector/useEmployeesForTemplateShift";
import {EmployeeOptionSettingsProvider} from "@/pages/daily/employeeSelector/EmployeeOptionSettingsProvider";
import {WorkingTemplate} from "@/pages/daily/template/template.types";

interface Props {
  shift: WorkingShift;
  workingTemplate: WorkingTemplate;
  updateShift: (newShift: WorkingShift) => void;
  removeShift: () => void;
  selectStaff: (newStaff?: EmployeeForShiftItem, isStaffRequesting?: boolean) => void;
}

const showStaffPickerColumn = (key: string) => {
  switch (key) {
    case 'name':
    case 'role':
    case 'tasks':
    case 'taskNames':
      return false;
  }
  return true;
}

export const TemplateShift: FC<Props> = ({ shift, workingTemplate, updateShift, removeShift, selectStaff }) => {
  const employeeDropdownOptions = useEmployeesForTemplateShift(shift);

  return (
    <div key={shift.id} className="flex flex-row border-b">
      <div className="flex sticky left-0 bg-white z-[60] border-r">
        <div
          className="flex items-center px-2 font-semibold gap-3 border-r"
          style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
        >
          <button onClick={removeShift}>
            <FiX className="h-6 w-6" />
          </button>
          <div className="flex flex-col w-[90%] gap-1">
            <EmployeeOptionSettingsProvider showStaffPickerColumn={showStaffPickerColumn} reassignmentModel="disabled">
              <EmployeeSelector
                onSelect={selectStaff}
                readOnly={false}
                shift={shift}
                workingShifts={workingTemplate?.shifts}
                waitingAllocation={false}
                employeeDropdownOptions={employeeDropdownOptions}
              />
            </EmployeeOptionSettingsProvider>
          </div>
        </div>
        <div style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }} className="flex">
          <AttendanceTotalColumns shift={shift} useShiftTime />
        </div>
      </div>
      <div className="flex-1">
        <ShiftTimePicker
          shift={shift}
          updateShift={updateShift}
          type={DailyEditingType.TEMPLATE}
          useColorizedBar={true}
          hasSetEmployee={!!shift.assignedContractId}
        />
      </div>
    </div>
  );
};
