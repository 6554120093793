import { useMemo } from 'react';
import { TableColumnGroup } from './staffTable/StaffTable';

export const useVisibleGroups = (groups: TableColumnGroup[], checkedState?: Record<string, boolean>) => {
  const visibleGroups = useMemo(() => {
    if (!checkedState) return groups;

    return groups.filter(group => !group.isGroupHidden && checkedState[group.id]);
  }, [groups, checkedState]);

  return visibleGroups;
};
