import { SeverityType } from "@/components/warning/warningDefinitions";
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterWarningModel, WarningsProps } from "../warnings.types";

export const getOverlappingSplitShiftWarnings = (
  shift: WorkingShift,
  props: WarningsProps
): RosterWarningModel[] => {
  const { workingRoster, strings, splitShiftSettings, allEmployees } = props;
  const otherShifts = workingRoster.shifts;
  const assignedEmployee = allEmployees.find(e => e.identityId === shift.assignedEmployeeId);

  if (
    !assignedEmployee ||
    splitShiftSettings.maxNumber === 1 ||
    shift.start === undefined ||
    shift.end === undefined
  ) {
    return [];
  }

  const otherShiftsForEmployee = otherShifts.filter(
    s =>
      s.assignedEmployeeId === assignedEmployee?.identityId &&
      s.id !== shift.id &&
      s.start !== undefined &&
      s.end !== undefined
  );

  if (otherShiftsForEmployee.length === 0) {
    return [];
  }

  const shiftWarnings: RosterWarningModel[] = [];

  const minStart = shift.start - splitShiftSettings.minSecondsBetween;
  const maxEnd = shift.end + splitShiftSettings.minSecondsBetween;

  let hasOverlappingShift = false;
  otherShiftsForEmployee.forEach(otherShift => {
    if (
      (minStart <= otherShift.start! && otherShift.start! < maxEnd) ||
      (minStart < otherShift.end! && otherShift.end! <= maxEnd) ||
      (otherShift.start! <= minStart && otherShift.end! >= maxEnd)
    ) {
      hasOverlappingShift = true;
    }
  });

  if (hasOverlappingShift) {
    shiftWarnings.push({
      severity: SeverityType.CRITICAL,
      name: strings.overlappingShifts.message,
      details: [strings.overlappingShifts.description],
      shiftId: shift.id
    });
  }

  return shiftWarnings;
};
