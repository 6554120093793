import { FC, useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  value: string;
  setValue: (newValue: any) => void;
  readOnly?: boolean;
  className?: string;
}

export const TextInputField: FC<Props> = ({ value, setValue, readOnly, className }) => {
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value, setInputValue]);

  const onChange = (e: any) => {
    setInputValue(e.target.value);

    if (e.target.value !== inputValue) {
      setValue(e.target.value);
    }
  };

  return (
    <input
      className={twMerge(
        className,
        'w-full h-full bg-background px-3 flex items-center border border-border rounded-md text-sm'
      )}
      disabled={readOnly ? true : undefined}
      value={inputValue}
      onChange={e => (readOnly ? undefined : onChange(e))}
      type="text"
    ></input>
  );
};
