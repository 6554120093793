import { ChangeType, useOptimiserBatchSubscription } from "../gql/graphql.generated";
import { useLogger } from "../logger/useLogger";
import { useOptimiserService } from "./OptimiserService";
import { OptimiserStatus } from "./optimiser.types";
import { parseOptimiserProperties } from "./optimiserHelper";

export const useTrackOptimiserProgress = () => {
  const { start, complete, error } = useOptimiserService(state => ({
    start: state.startJob,
    complete: state.completeJob,
    error: state.errorJob
  }));

  const log = useLogger();

  useOptimiserBatchSubscription({}, (_, data) => {
    const change = data?.batchJobStateChange;

    if (change) {
      log(`Received update from optimiser batch ${change.batchId}`);
      const locationId = parseInt(parseOptimiserProperties('LocationId', change.properties));
      const startDate = new Date(parseOptimiserProperties('From', change.properties));
      const endDate = new Date(parseOptimiserProperties('To', change.properties));
      const status = parseOptimiserProperties('Status', change.properties) as OptimiserStatus;
      const started = change.started ? new Date(change.started) : undefined;

      log(`Processing update for location ${locationId} at ${startDate.toISOString()} with status: ${status}`);

      // Minor hack- the optimiser excludes the end date, so we have to nudge it down
      // a day for the notification.
      endDate.setDate(endDate.getDate() - 1);

      start({
        batchId: change.batchId,
        locationId: locationId,
        startDate: startDate,
        endDate: endDate,
        started: started,
        status: status || OptimiserStatus.UNKNOWN
      });

      if (status >= OptimiserStatus.ERROR) {
        error(locationId, startDate, status)
      } else if (change.finished && started) {
        log(`Completing batch update`)
        complete(change.batchId, status);
      } else if (change.changeType === ChangeType.Delete) {
        log(`Batch job deleted`)
        complete(change.batchId, OptimiserStatus.DELETED);
      }
    }

    return data;
  });
};