import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate, useGetTemplateQuery } from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { TemplateOption, WorkingTemplate } from '../template.types';
import { useGqlTemplateToWorkingTemplate } from '../templateHelper';
import { useDraftTemplateLibraryOptions } from '@/services/rosters/useTemplateLibraryOptions';

export interface TemplateLibraryOption extends TemplateOption {
  publishedVersionId?: string;
}

export interface TemplateStore {
  templateOptions: TemplateLibraryOption[];
  selectedTemplateId?: string;
  setSelectedTemplateId: (newVal?: string) => void;
  createTemplateAndNavigate: (template: ReadTemplate) => void;
  createNeeded?: boolean;
  workingTemplate?: WorkingTemplate;
  setWorkingTemplate: (newTemp?: WorkingTemplate) => void;
  refetchTemplateOptions: () => void;
}

export const TemplateLibraryContext = createContext<StoreApi<TemplateStore> | null>(null);

export const TemplateLibraryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { templateOptions, refetch } = useDraftTemplateLibraryOptions();

  const gqlTemplateToWorkingTemplate = useGqlTemplateToWorkingTemplate();

  const determineSelectedTemplate = useCallback(() => {
    const localStorageId = localStorage.getItem('selectedLibraryTemplateId');

    const localTemplateInList = templateOptions.find(option => option.id === localStorageId);

    if (localTemplateInList) {
      return localStorageId;
    } else if (templateOptions.length > 0) {
      const newTemplateToSelect = templateOptions[0];
      localStorage.setItem('selectedLibraryTemplateId', newTemplateToSelect.id);
      return newTemplateToSelect.id;
    }

    return undefined;
  }, [templateOptions]);

  const determineInitialWorkingTemplate = useCallback(() => {
    const templateId = determineSelectedTemplate();

    const localTemplateInList = templateOptions.find(option => option.id === templateId);

    if (!localTemplateInList) {
      return undefined;
    }

    return gqlTemplateToWorkingTemplate(localTemplateInList.template);
  }, [templateOptions, determineSelectedTemplate]);

  const { registerForConfirmation, requestConfirmation } = useConfirm(state => ({
    registerForConfirmation: state.registerForConfirmation,
    requestConfirmation: state.requestConfirmation
  }));

  const store = useMemo(() => {
    return createStore<TemplateStore>()((set, get) => ({
      templateOptions,
      selectedTemplateId: determineSelectedTemplate() || undefined,
      workingTemplate: determineInitialWorkingTemplate(),
      setSelectedTemplateId: (newVal?: string) => {
        const templateOptions = get().templateOptions;
        const template = templateOptions.find(opt => opt.id === newVal);

        requestConfirmation(() => {
          localStorage.setItem('selectedLibraryTemplateId', newVal || '');

          set({
            selectedTemplateId: newVal,
            workingTemplate: template ? gqlTemplateToWorkingTemplate(template.template) : undefined
          });
        });
      },
      setWorkingTemplate: (newTemp?: WorkingTemplate) => {
        registerForConfirmation(strings.daily.templates.unsaved);
        set({ workingTemplate: newTemp });
      },
      createTemplateAndNavigate: (newTemplate: ReadTemplate) => {
        const newTemplateOptions = [...get().templateOptions];

        const newOption: TemplateOption = {
          id: newTemplate.id,
          name: newTemplate.name,
          template: newTemplate
        };

        newTemplateOptions.push(newOption);
        newTemplateOptions.sort((a, b) => (a.name > b.name ? 1 : -1));

        localStorage.setItem('selectedLibraryTemplateId', newTemplate.id);
        set({
          templateOptions: newTemplateOptions,
          selectedTemplateId: newTemplate.id,
          workingTemplate: gqlTemplateToWorkingTemplate(newTemplate)
        });
      },
      refetchTemplateOptions: () => refetch()
    }));
  }, [
    templateOptions,
    determineSelectedTemplate,
    determineInitialWorkingTemplate,
    gqlTemplateToWorkingTemplate,
    refetch
  ]);

  return <TemplateLibraryContext.Provider value={store}>{children}</TemplateLibraryContext.Provider>;
};

type SelectorReturn<S extends (s: TemplateStore) => any> = ReturnType<S>;
export function useTemplateLibraryService<S extends (s: TemplateStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(TemplateLibraryContext);
  if (!context) {
    throw new Error('useTemplateLibrary must be used within a TemplateLibraryProvider');
  }
  return useStore(context, selector);
}

export const useSelectedLibraryTemplate = () => {
  const { selectedTemplateId } = useTemplateLibraryService(state => ({
    selectedTemplateId: state.selectedTemplateId
  }));

  const [{ data }] = useGetTemplateQuery({ variables: { id: selectedTemplateId } });

  return data?.template;
};

export const useWorkingLibraryTemplate = () => {
  const { setWorkingTemplate, workingTemplate } = useTemplateLibraryService(state => ({
    setWorkingTemplate: state.setWorkingTemplate,
    workingTemplate: state.workingTemplate
  }));

  return { workingTemplate, setWorkingTemplate };
};
