import * as RadixTooltip from '@radix-ui/react-tooltip';
import { FC, PropsWithChildren, Suspense } from 'react';
import { BiError, BiSolidError, BiSolidInfoCircle } from 'react-icons/bi';
import { FiAlertTriangle, FiInfo } from 'react-icons/fi';
import { TbAlertTriangleFilled } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

interface Props extends PropsWithChildren {
  position?: 'top' | 'right' | 'left' | 'bottom';
  type?: TooltipTypes;
  className?: string;
  triggerClassName?: string;
  iconColorOverride?: string;
  solidIcon?: boolean;
}

export enum TooltipTypes {
  'info',
  'warn',
  'error'
}

export const Tooltip: FC<Props> = ({
  children,
  type,
  position = 'right',
  className,
  triggerClassName,
  iconColorOverride,
  solidIcon
}) => {
  const ToolTipIcon: FC = () => {
    switch (type) {
      case TooltipTypes.warn: {
        const Icon = solidIcon ? TbAlertTriangleFilled : FiAlertTriangle;

        return (
          <Icon
            style={{ color: iconColorOverride }}
            className={twMerge('h-4 w-4', iconColorOverride ? '' : 'text-[#aa8200]')}
          />
        );
      }
      case TooltipTypes.error: {
        const Icon = solidIcon ? BiSolidError : BiError;

        return (
          <Icon
            style={{ color: iconColorOverride }}
            className={twMerge('h-4 w-4', iconColorOverride ? '' : 'text-red-500')}
          />
        );
      }
      case TooltipTypes.info:
      default: {
        const Icon = solidIcon ? BiSolidInfoCircle : FiInfo;

        return <Icon style={{ color: iconColorOverride }} className={twMerge('h-4 w-4', iconColorOverride && '')} />;
      }
    }
  };

  return (
    <RadixTooltip.Provider delayDuration={100}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger className={triggerClassName}>
          <ToolTipIcon />
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <Suspense>
            <RadixTooltip.Content
              side={position}
              className={twMerge('z-[1000] bg-background-mid border rounded-sm p-2 w-[350px] text-sm', className)}
              sideOffset={5}
            >
              {children}
            </RadixTooltip.Content>
          </Suspense>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
