import { ItemTypes } from '@/components/gantt/dragDrop/types';
import {
  ReadTemplate,
  UpdateTemplate,
  UpdateTemplatePosition,
  useGetTemplateQuery,
  ReadTemplateTag
} from '@/services/gql/graphql.generated';
import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DailyProperties, WorkingShift, WorkingShiftSubsection } from '../daily.types';
import { BLANK_GUID, workingIdToGqlId } from '../dailyHelper';
import { useBuildHourSummary } from '../useHourSummary';
import { WorkingTemplate } from './template.types';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { secondsToHours } from 'date-fns';

export const useGqlTemplateToWorkingTemplate = (wipeIds?: boolean) => {
  const buildHourSummary = useBuildHourSummary();
  const defaultEnd = useSystemSettings(state => state.settings.maxTime);
  const defaultStart = useSystemSettings(state => state.settings.minTime);

  return useCallback(
    (gqlTemplate: ReadTemplate) => {
      if (!gqlTemplate.stations) {
        throw Error('Stations are not configured correctly in GQL template.');
      }

      const gqlShifts = gqlTemplate.stations[0]?.positions ?? [];
      const workingShifts = gqlShifts.map(gqlShift => {
        let breaks: WorkingShiftSubsection[];
        if (!gqlShift.breaks) {
          breaks = [];
        } else {
          // Using brek instead of break, because it's a typescript keyword.
          breaks = gqlShift.breaks.map(brek => ({
            start: brek.start,
            end: brek.end,
            id: wipeIds ? `CREATE-${uuidv4()}` : brek.id,
            type: ItemTypes.BREAK
          }));
        }

        const tasks: WorkingShiftSubsection[] = [];
        gqlShift.timeSlots?.forEach(slot => {
          try {
            if (slot.properties) {
              const properties = JSON.parse(slot.properties);
              if (properties.type === ItemTypes.TASK) {
                tasks.push({
                  start: slot.start,
                  end: slot.end,
                  id: wipeIds ? `CREATE-${uuidv4()}` : slot.id,
                  type: ItemTypes.TASK,
                  typeContext: properties.context
                });
              }
            }
          } catch (error) {}
        });

        let assignedContractId: string | undefined;

        if (gqlShift.contracts && gqlShift.contracts.length > 0) {
          assignedContractId = gqlShift.contracts[0].contractTypeId;
        }

        const workingShift: WorkingShift = {
          id: wipeIds ? `CREATE-${uuidv4()}` : gqlShift.id,
          start: gqlShift.start,
          end: gqlShift.end,
          assignedEmployeeId: gqlShift.employee?.identityId,
          assignedStaffHomeLocationId: gqlShift.employee?.homeLocationId,
          assignedContractId: assignedContractId,
          breaks: breaks,
          tasks: tasks,
          properties: gqlShift.properties ? JSON.parse(gqlShift.properties) : {},
          locationId: gqlTemplate.location?.id
        };

        return workingShift;
      });

      let scratchPad: number[] | undefined = undefined;
      let notes: string | undefined = undefined;
      let lastPublished: Date | undefined = undefined;
      let templateHistoryNotes: string | undefined = undefined;

      const startTime =
        gqlTemplate.shiftOperatingHours?.startTime !== undefined
          ? gqlTemplate.shiftOperatingHours.startTime
          : defaultStart;
      const endTime =
        gqlTemplate.shiftOperatingHours?.endTime !== undefined ? gqlTemplate.shiftOperatingHours.endTime : defaultEnd;

      try {
        if (gqlTemplate.properties) {
          const properties = JSON.parse(gqlTemplate.properties);

          notes = properties.notes;

          if (properties.lastPublished) {
            lastPublished = new Date(properties.lastPublished);
          }

          if (properties.libraryTemplateName && properties.importDate) {
            templateHistoryNotes = strings.daily.templates.importNotes(
              properties.importDate,
              properties.libraryTemplateName
            );
          }

          const scratchPadFromProperties = properties.scratchpad as number[];

          const expectedLength = secondsToHours(endTime - startTime) * 2;

          if (scratchPadFromProperties.length === expectedLength) {
            scratchPad = scratchPadFromProperties;
          }
        }
      } catch (error) {}

      if (!scratchPad) {
        scratchPad = buildHourSummary(workingShifts, startTime, endTime);
      }

      let lastModifiedString = gqlTemplate.lastModified as string;
      if (!lastModifiedString.includes('Z')) {
        lastModifiedString = lastModifiedString + 'Z';
      }

      const workingTemplate: WorkingTemplate = {
        shifts: workingShifts,
        scratchpad: scratchPad,
        id: gqlTemplate.id,
        name: gqlTemplate.name,
        dayNumber: gqlTemplate.target?.type === 'DayOfWeek' ? gqlTemplate.target.value : undefined,
        notes,
        version: gqlTemplate.version,
        lastSaved: new Date(lastModifiedString),
        lastPublished,
        templateHistoryNotes,
        startTime,
        endTime,
        openTime: gqlTemplate.shiftOperatingHours?.openTime || undefined,
        closeTime: gqlTemplate.shiftOperatingHours?.closeTime || undefined,
        tags: gqlTemplate.templateTags?.map((t: ReadTemplateTag) => ({ label: t.label })) || []
      };

      return workingTemplate;
    },
    [defaultStart, defaultEnd, buildHourSummary]
  );
};

export const useWorkingTemplateToGqlTemplate = () => {
  return useCallback(
    (
      workingTemplate: WorkingTemplate,
      originalTemplate: ReadTemplate,
      includeTarget: boolean = true,
      wipeIds: boolean = false
    ) => {
      if (!originalTemplate.stations || originalTemplate.stations.length === 0) {
        throw Error('Templates must have stations defined');
      }

      const positionList: UpdateTemplatePosition[] = workingTemplate.shifts.map((shift, index) => {
        const positionId = workingIdToGqlId(shift.id);
        const employeeId = shift.assignedEmployeeId !== '' ? shift.assignedEmployeeId : undefined;

        // set the staffLocked true so that the optimiser will respect staff assignment
        let properties = shift.properties;
        if (employeeId) {
          properties = properties ?? {};
          properties['staffLocked'] = true;
        } else if (properties?.staffLocked) {
          delete properties.staffLocked;
          if (!Object.keys(properties).length) {
            properties = undefined;
          }
        }

        const position: UpdateTemplatePosition = {
          displayOrder: index + 1,
          id: wipeIds ? BLANK_GUID : positionId,
          start: shift.start,
          end: shift.end,
          employeeId: employeeId,
          properties: properties ? JSON.stringify(properties) : undefined,
          breaks: shift.breaks.map(brek => ({
            id: wipeIds ? BLANK_GUID : workingIdToGqlId(brek.id),
            start: brek.start,
            end: brek.end
          })),
          timeSlots: shift.tasks.map(task => ({
            id: wipeIds ? BLANK_GUID : workingIdToGqlId(task.id),
            start: task.start,
            end: task.end,
            properties: JSON.stringify({ type: ItemTypes.TASK, context: task.typeContext })
          }))
        };

        return position;
      });

      const properties: DailyProperties = {
        scratchpad: workingTemplate.scratchpad,
        notes: workingTemplate.notes,
        lastPublished: workingTemplate.lastPublished?.toISOString()
      };

      const gqlTemplate: UpdateTemplate = {
        id: originalTemplate.id,
        name: workingTemplate.name,
        weekday: originalTemplate.weekday,
        locationId: originalTemplate.location?.id,
        targetType: includeTarget && originalTemplate.target ? originalTemplate.target.type : 'NotSet',
        targetValue: includeTarget && originalTemplate.target ? originalTemplate.target.value : 0,
        stations: [
          {
            id: wipeIds ? BLANK_GUID : originalTemplate.stations[0].id,
            stationId: originalTemplate.stations[0].stationId,
            displayOrder: originalTemplate.stations[0].displayOrder,
            positions: positionList
          }
        ],
        properties: JSON.stringify(properties),
        version: workingTemplate.version,
        shiftOperatingHours: {
          startTime: workingTemplate.startTime,
          endTime: workingTemplate.endTime,
          openTime: workingTemplate.openTime,
          closeTime: workingTemplate.closeTime
        },
        templateTags: workingTemplate.tags?.map(t => ({ label: t.label }))
      };

      return gqlTemplate;
    },
    []
  );
};

/*
 * This is a bit of a weird one.
 *
 * The GQL sends us a template with some extra structures, like the locations
 * and targets, and also the typenames.
 *
 * The update expects these structures to be flattened, and the typenames to
 * be removed.
 */
export const useConvertToUpdatable = () => {
  // The easiest way to go from the GQL format for reading to the GQL format for
  // updating is to use the working template structure as a middle ground.
  const gqlToWorking = useGqlTemplateToWorkingTemplate();
  const workingToGql = useWorkingTemplateToGqlTemplate();

  return useCallback(
    (template: ReadTemplate) => {
      const working = gqlToWorking(template);
      return workingToGql(working, template);
    },
    [workingToGql, gqlToWorking]
  );
};

export const useUpdatableTemplate = (templateId: string) => {
  const [{ data: templateData }] = useGetTemplateQuery({ variables: { id: templateId } });

  const template = templateData?.template as ReadTemplate;
  if (!template) {
    throw Error(`Could not get template with ID ${templateId}`);
  }

  const convert = useConvertToUpdatable();

  return useMemo(() => convert(template), [template, convert]);
};
