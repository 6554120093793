import { useTemplateTags } from '@/services/rosters/useTemplateTags';
import { FC, useMemo } from 'react';
import { AutoSuggestInput } from '../form/AutoSuggestInput';
import { Label } from '../Label';
import { TagChit } from './TagChit';

export interface Tag {
  label: string;
}

interface Props {
  tags: Tag[];
  setTags: (newTags: Tag[]) => void;
  label: string;
  description?: string;
  placeholder?: string;
}

export const TagInput: FC<Props> = ({ tags, setTags, label, description, placeholder }) => {
  const onTagAdd = (newTagName: string) => {
    if (!newTagName) {
      return;
    }

    const newTagList = [...tags];
    if (!newTagList.find(t => t.label === newTagName)) {
      newTagList.push({ label: newTagName });
      setTags(newTagList);
    }
  };

  const onTagDelete = (index: number) => () => {
    const newTagList = [...tags];
    newTagList.splice(index, 1);
    setTags(newTagList);
  };

  const allTags = useTemplateTags();

  const suggestions = useMemo(() => {
    const unusedTags = allTags.filter(t => !tags.find(u => u.label === t.label));
    return unusedTags.map(t => t.label);
  }, [allTags, tags]);

  return (
    <div className="space-y-1">
      <Label>{label}</Label>
      {description && <div className="text-sm">{description}</div>}
      <AutoSuggestInput
        suggestions={suggestions}
        onConfirm={onTagAdd}
        placeholder={placeholder}
      />
      <div className="flex gap-2 flex-wrap">
        {tags.map((t, index) => (
          <TagChit key={t.label} tag={t} onDelete={onTagDelete(index)} />
        ))}
      </div>
    </div>
  );
};
