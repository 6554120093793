import { dateToApiFormat } from '@/helpers/dateHelper';
import { ReadTemplate, useCreateRosterMutation, useGetTemplatesForLocationQuery } from '@/services/gql/graphql.generated';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useCallback } from 'react';
import { RosterOption, useRosterService } from './providers/RosterOptionsService';
import { useGetPublicHoliday, usePublicHolidays } from '@/services/holiday/usePublicHolidays';

export const useCreateRoster = () => {
  const [responseData, create] = useCreateRosterMutation();
  const { selectedLocation, selectedDate } = useLocalSettings(state => ({
    selectedLocation: state.selectedLocationId,
    selectedDate: state.selectedDate
  }));

  const [{ data: templateData }] = useGetTemplatesForLocationQuery({ variables: { locationId: selectedLocation } });
  const templates = templateData?.templatesForLocation;

  const addRosterOption = useRosterService(s => s.addRosterOption);

  const getPublicHoliday = useGetPublicHoliday(new Date(selectedDate));

  const createRoster = useCallback(async () => {
    const date = new Date(selectedDate);

    let template: ReadTemplate | undefined;

    if (getPublicHoliday(date)) {
      template = templates?.find(template => {
        return template.target?.type === 'DayOfWeek' && template.target.value === 7;
      }) as ReadTemplate;
    } else {
      template = templates?.find(template => {
        return template.target?.type === 'DayOfWeek' && template.target.value === date.getDay();
      }) as ReadTemplate;
    }

    if (!template) {
      throw Error(`Could not find templates for ${selectedDate}`);
    }

    const result = await create({
      locationId: selectedLocation,
      date: dateToApiFormat(new Date(selectedDate)),
      templateId: template.id
    });

    if (result.data?.createRosterFromTemplate) {
      const newOption = result.data?.createRosterFromTemplate;

      if (!newOption.id) {
        throw Error("New roster does not have an ID");
      }

      addRosterOption(newOption as RosterOption);
    }
  }, [templates, create, selectedDate, selectedLocation, addRosterOption]);

  return { fetching: responseData.fetching, create: createRoster };
};
