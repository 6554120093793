import { secondsToTimeString } from '@/helpers/dateHelper';
import { PrintViewTypes } from '@/pages/pdf/roster/usePrintOptions';
import { BudgetStats, WorkingBudgetLocation } from '@/services/budget/budget.types';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { deltaToColor } from '../deltaToColor';
import { Note } from '../roster/PdfRosterNotes';
import { PdfDateRange } from './PdfDateRange';
import { PdfParams } from './PdfSummaryDay';

interface Props {
  days: Date[];
  budgetData: WorkingBudgetLocation;
  budgetStats: BudgetStats;
  nameWidth: number;
  totalWidth: number;
  params: PdfParams;
  notes: Note[];
  dateCellWidthOverride?: number;
}

export const PdfBudgetTable: FC<Props> = ({
  days,
  budgetData,
  budgetStats,
  nameWidth,
  totalWidth,
  params,
  notes,
  dateCellWidthOverride
}) => {
  return (
    <View>
      <View style={styles.tableRow}>
        <PdfTableCell width={nameWidth}></PdfTableCell>
        {params.viewType === PrintViewTypes.SCREEN && (
          <PdfTableCell width={totalWidth}>
            <Text style={styles.tableCell}>{strings.summary.total}</Text>
          </PdfTableCell>
        )}
        <PdfDateRange days={days} notes={notes} dateWidthOverride={dateCellWidthOverride} />
      </View>
      {params.viewType === PrintViewTypes.SCREEN && (
        <View style={styles.tableRow}>
          <PdfTableCell width={nameWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>{strings.budget.rows.hqAllocated}</Text>
          </PdfTableCell>
          <PdfTableCell width={totalWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>
              {secondsToTimeString(budgetData.hqAllocatedMonthlyTime || budgetStats.totalHq)}
            </Text>
          </PdfTableCell>
          {budgetData.days.map((budgetDay, dayIndex) => (
            <PdfTableCell key={budgetDay.date.toISOString()} lastCell={dayIndex === days.length - 1}>
              <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(budgetDay.hqAllocatedHours)}</Text>
            </PdfTableCell>
          ))}
        </View>
      )}
      {params.viewType === PrintViewTypes.SCREEN && (
        <View style={styles.tableRow}>
          <PdfTableCell width={nameWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>{strings.budget.rows.locationForecast}</Text>
          </PdfTableCell>
          <PdfTableCell width={totalWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>
              {secondsToTimeString(budgetStats.totalLocationForecast)}
            </Text>
          </PdfTableCell>
          {budgetData.days.map((budgetDay, dayIndex) => (
            <PdfTableCell key={budgetDay.date.toISOString()} lastCell={dayIndex === days.length - 1}>
              <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(budgetDay.locationForecast)}</Text>
            </PdfTableCell>
          ))}
        </View>
      )}
      {params.viewType === PrintViewTypes.SCREEN && (
        <View style={styles.tableRow}>
          <PdfTableCell width={nameWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>{strings.budget.rows.rosteredBudget}</Text>
          </PdfTableCell>
          <PdfTableCell width={totalWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>
              {secondsToTimeString(budgetStats.totalRostered)}
            </Text>
          </PdfTableCell>
          {budgetData.days.map((budgetDay, dayIndex) => (
            <PdfTableCell key={budgetDay.date.toISOString()} lastCell={dayIndex === days.length - 1}>
              <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(budgetDay.rosteredBudget)}</Text>
            </PdfTableCell>
          ))}
        </View>
      )}
      {params.viewType === PrintViewTypes.SCREEN && (
        <View style={styles.tableRow}>
          <PdfTableCell width={nameWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>{strings.budget.rows.deltaTime}</Text>
          </PdfTableCell>
          <PdfTableCell width={totalWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right', color: deltaToColor(budgetStats.monthlyDelta) }}>
              {secondsToTimeString(budgetStats.monthlyDelta, true)}
            </Text>
          </PdfTableCell>
          {budgetData.days.map((budgetDay, dayIndex) => {
            const delta = budgetDay.rosteredBudget - budgetDay.locationForecast;
            return (
              <PdfTableCell key={budgetDay.date.toISOString()} lastCell={dayIndex === days.length - 1}>
                <Text style={{ ...styles.tableCellSm, color: deltaToColor(delta) }}>
                  {secondsToTimeString(delta, true)}
                </Text>
              </PdfTableCell>
            );
          })}
        </View>
      )}
      <View style={styles.tableRow}>
        <PdfTableCell width={nameWidth}>
          <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>{strings.budget.rows.totalEmployees}</Text>
        </PdfTableCell>
        {params.viewType === PrintViewTypes.SCREEN && (
          <PdfTableCell width={totalWidth}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right' }}>{`${budgetStats.totalFilledShifts}/${budgetStats.totalShifts}`}</Text>
          </PdfTableCell>
        )}
        {budgetData.days.map((budgetDay, dayIndex) => (
          <PdfTableCell key={budgetDay.date.toISOString()} lastCell={dayIndex === days.length - 1}>
            <Text style={{ ...styles.tableCellSm }}>{budgetDay.filledShiftCount}</Text>
          </PdfTableCell>
        ))}
      </View>
    </View>
  );
};
