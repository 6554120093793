import { SelectInput } from '@/components/form/SelectInput';
import { toastMessage } from '@/helpers/helper';
import { strings } from '@/services/translation/strings';
import { secondsToHours } from 'date-fns';
import { FC } from 'react';
import { useMinMaxTimeOptions } from '../settings/sections/manageSystem/useMinMaxTimeOptions';

interface Props {
  startTime: number;
  endTime: number;
  minLength: number;
  onChangeStart: (start: number) => void;
  onChangeEnd: (end: number) => void;
  readonly?: boolean;
}

export const StartEndTimePicker: FC<Props> = ({
  startTime,
  endTime,
  minLength,
  onChangeStart,
  onChangeEnd,
  readonly
}) => {
  const minMaxOptions = useMinMaxTimeOptions();

  const handleStartChange = (newStart: number) => {
    if (endTime - newStart < minLength) {
      toastMessage(strings.daily.changeTimes.lengthError(secondsToHours(minLength)));
    } else {
      onChangeStart(newStart);
    }
  };

  const handleEndChange = (newEnd: number) => {
    if (newEnd - startTime < minLength) {
      toastMessage(strings.daily.changeTimes.lengthError(secondsToHours(minLength)));
    } else {
      onChangeEnd(newEnd);
    }
  };

  return (
    <div className="flex gap-2 text-xs">
      <div className="flex gap-2 items-center">
        <span>{strings.daily.changeTimes.startTime}</span>
        <SelectInput
          className="text-xs"
          options={minMaxOptions}
          value={startTime}
          setValue={newVal => handleStartChange(parseInt(newVal))}
          readOnly={readonly}
        />
      </div>
      <div className="flex gap-2 items-center">
        <span>{strings.daily.changeTimes.endTime}</span>
        <SelectInput
          className="text-xs"
          options={minMaxOptions}
          value={endTime}
          setValue={newVal => handleEndChange(parseInt(newVal))}
          readOnly={readonly}
        />
      </div>
    </div>
  );
};
