import { dateToApiFormat } from '@/helpers/dateHelper';
import { toastMessage } from '@/helpers/helper';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import {
  Exact,
  UpdateEmployee,
  UpdateEmployeeSkills,
  UpdateEmployeesMutation,
  useUpdateEmployeeSkillsMutation,
  useUpdateEmployeesMutation,
  useUpdateWorkRulesMutation,
  useUpdateWorkingPatternsMutation
} from '@/services/gql/graphql.generated';
import { useStaffSettings } from '@/services/settings/StaffSettingsProvider';
import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { useEmployeeList } from '@/services/staff/useEmployeeList';
import { useSkills } from '@/services/tasks/useSkills';
import { strings } from '@/services/translation/strings';
import { useCallback } from 'react';
import { OperationResult } from 'urql';
import { useEditSettingsService } from './EditSettingsService';

const toBoolean = (value: boolean | string | undefined) => {
  if (value === undefined) return undefined;
  if (typeof value === 'string') return value.toLowerCase() === 'true';
  return value;
};

export const useUpdateStaffSettings = () => {
  const setSettings = useStaffSettings(state => state.setSettings);
  const refetch = useStaffSettings(state => state.refetchStaffSkills);
  const workingStaffSettings = useEditSettingsService(state => state.workingStaffSettings);

  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));

  const skillList = useSkills();

  const [{ fetching: fetchingWorkRules, error: workRulesError }, updateWorkRules] = useUpdateWorkRulesMutation();
  const [{ fetching: fetchingEmployees, error: employeesError }, updateEmployees] = useUpdateEmployeesMutation();
  const [{ fetching: fetchingWorkingPatterns, error: workingPatternsError }, updateWorkingPatterns] =
    useUpdateWorkingPatternsMutation();
  const [{ fetching: fetchingSkills, error: skillsError }, updateSkills] = useUpdateEmployeeSkillsMutation();

  const updateWorkingPatternsSettings = useCallback(
    async (settings: StaffWithSettings[]) => {
      const dataToSend = settings.map(wp => {
        const workingPatterns = wp.settings.workingPatterns;
        return {
          employeeId: wp.employee.identityId!,
          patterns: workingPatterns.map(pattern => ({
            startTime: pattern.startTime,
            endTime: pattern.endTime,
            dayNumber: pattern.day,
            available: !(pattern.startTime === 0 && pattern.endTime === 0)
          }))
        };
      });
      const result = await updateWorkingPatterns({
        workingPatterns: dataToSend
      });
      setSettings(workingStaffSettings);
      unregisterForConfirmation();
      return result;
    },
    [updateWorkingPatterns, setSettings, unregisterForConfirmation, workingStaffSettings]
  );

  const updateEmployeesSettings = useCallback(
    async (settings: StaffWithSettings[]) => {
      const employees = settings.map(staff => {
        const locationIds = staff.settings.assignedLocationIds.length > 0 ? [...staff.settings.assignedLocationIds, staff.employee.homeLocationId!] :
          (staff.employee.homeLocationId ? [staff.employee.homeLocationId] : []);

        if (locationIds.length === 0) {
          throw Error('Employee should not have empty location ID list');
        }

        const properties = {
          notes: staff.settings.notes,
          preferences: staff.settings.preferences
        };

        return {
          identityId: staff.employee.identityId,
          contractTypeId: staff.employee.contract?.contractTypeId,
          employeeId: staff.employee.employeeId,
          firstName: staff.employee.firstName,
          lastName: staff.employee.lastName,
          kanjiFirstName: staff.employee.kanjiFirstName,
          kanjiLastName: staff.employee.kanjiLastName,
          email: staff.settings.email,
          phoneNumber: staff.settings.phoneNumber?.length > 0 ? staff.settings.phoneNumber : undefined,
          role: staff.employee.role,
          ignoreHourContribution: staff.employee.ignoreHourContribution,
          isRosterable: staff.employee.isRosterable,
          hourlyRate: staff.employee.contract?.hourlyRate ?? 0,
          holidayHourlyRate: staff.employee.contract?.holidayHourlyRate ?? 0,
          homeLocationId: staff.employee.homeLocationId,
          locationIds: locationIds,
          lateWorker: toBoolean(staff.employee.contract?.lateWorker),
          foreigner: toBoolean(staff.employee.contract?.foreigner),
          mentor: staff.settings.canMentor,
          mentee: staff.settings.requiresMentor,
          dateOfBirth: staff.settings.dateOfBirth && dateToApiFormat(staff.settings.dateOfBirth),
          hireDate: staff.settings.hiredDate && dateToApiFormat(staff.settings.hiredDate),
          leaveDate: staff.settings.leftCompanyDate && dateToApiFormat(staff.settings.leftCompanyDate),
          endOfProbationDate: staff.settings.endProbationDate && dateToApiFormat(staff.settings.endProbationDate),
          personaIds: staff.settings.assignedPersonaIds,
          properties: JSON.stringify(properties)
        };
      });

      let result:
        | OperationResult<UpdateEmployeesMutation, Exact<{ employees: UpdateEmployee | UpdateEmployee[] }>>
        | undefined;

      result = await updateEmployees({
        employees: employees
      });

      setSettings(workingStaffSettings);
      unregisterForConfirmation();

      return result;
    },
    [updateEmployees, setSettings, unregisterForConfirmation, workingStaffSettings]
  );

  const updateWorkRulesSettings = useCallback(
    async (settings: StaffWithSettings[]) => {
      const result = await updateWorkRules({
        workRules: settings.map(staff => {
          return {
            identityId: staff.employee.identityId,
            maxHoursPerMonth: staff.settings.maxMonthlyHours,
            minHoursPerMonth: staff.settings.minMonthlyHours,
            maxHoursPerWeek: staff.settings.maxWeeklyHours,
            minHoursPerWeek: staff.settings.minWeeklyHours,
            maxNumOfConsecutiveDays: 99999,
            totalNumberOfWorkDaysInMonth: 99999999
          };
        })
      });

      setSettings(workingStaffSettings);
      unregisterForConfirmation();

      return result;
    },
    [updateWorkRules, setSettings, unregisterForConfirmation, workingStaffSettings]
  );

  const updateSkillsSettings = useCallback(
    async (settings: StaffWithSettings[]) => {
      const result = await updateSkills({
        skills: settings.map(s => {
          const employeeSkills: UpdateEmployeeSkills[] = [];
          skillList.forEach(skill => {
            if (s.settings.assignedSkills.includes(skill.id)) {
              employeeSkills.push({
                skillId: skill.id,
                // Need to set a rating- it's often measured in stars, so use 5/5
                rating: 5
              });
            }
          });

          return {
            employeeId: s.employee.identityId,
            skills: employeeSkills
          };
        })
      });

      setSettings(workingStaffSettings);
      unregisterForConfirmation();

      return result;
    },
    [updateWorkRules, setSettings, unregisterForConfirmation, workingStaffSettings]
  );

  const update = useCallback(
    async (settings: StaffWithSettings[]) => {
      const invalidWeekly = settings.find((staff) => {
        return staff.settings.maxWeeklyHours > staff.settings.maxMonthlyHours
      });

      if (invalidWeekly) {
        toastMessage(strings.settings.manageStaff.mixMax.weeklyExceedsMonthly);
        return;
      }

      const employeesResult = await updateEmployeesSettings(settings);
      const workRulesResult = await updateWorkRulesSettings(settings);
      const workingPatternsResult = await updateWorkingPatternsSettings(settings);
      const skillsResult = await updateSkillsSettings(settings);

      //setTimeout(refetch, 3000);

      return {
        error: employeesResult.error || workRulesResult.error || workingPatternsResult.error || skillsResult.error
      };
    },
    [updateWorkRulesSettings, updateEmployeesSettings, updateWorkingPatternsSettings, updateSkillsSettings]
  );

  const fetching = fetchingEmployees || fetchingSkills || fetchingWorkRules || fetchingWorkingPatterns;
  const error = employeesError || skillsError || workingPatternsError || workRulesError;

  return {
    update,
    fetching,
    error
  };
};
