import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import React, { FC } from 'react';
import { useVisibleGroups } from '../useVisibleGroups';
import { StaffRowActionSection } from './StaffRowActionSection';
import { StaffRowDetailsSection } from './StaffRowDetailsSection';
import { StaffRowLocationSection } from './StaffRowLocationSection';
import { StaffRowMinMaxMonthlySection, StaffRowMinMaxWeeklySection } from './StaffRowMinMaxSection';
import { StaffRowPatternSection } from './StaffRowPatternSection';
import { StaffRowPersonalSection } from './StaffRowPersonalSection';
import { TableColumnGroup } from './StaffTable';

interface Props {
  onChangeSetting: (key: string) => (newVal: any) => void;
  onSetDelete: (del: boolean) => void;
  staff: StaffWithSettings;
  groups: TableColumnGroup[];
  checkedState: Record<string, boolean>;
}

export interface StaffRowSectionProps {
  hidden?: boolean;
  group: TableColumnGroup;
  staff: StaffWithSettings;
  onChangeSetting: (key: string) => (newVal: any) => void;
  onSetDelete?: (del: boolean) => void;
}

export interface Pattern {
  startTime: number | null;
  endTime: number | null;
  available: boolean;
  availabilityType: number;
  dayNumber: number;
}

export const bgWorkPatternColour = '#CBCBCB';

export const StaffRow: FC<Props> = ({ staff, onChangeSetting, onSetDelete, groups, checkedState }) => {
  const casualStaff = !staff.employee.employeeId;

  const visibleGroups = useVisibleGroups(groups, checkedState);

  const findHiddenValue = (id: string) => {
    const result = visibleGroups.find(item => item.id === id);

    if (result) {
      return result.isGroupHidden;
    } else {
      return true;
    }
  };

  const isWorkPatternsHidden = findHiddenValue('pattern')?.valueOf();
  const isMinMaxWeeklyHidden = findHiddenValue('minMaxWeekly')?.valueOf();
  const isMinMaxMonthlyHidden = findHiddenValue('minMaxMonthly')?.valueOf();
  const isPersonnelDetailsHidden = findHiddenValue('personnel')?.valueOf();
  const isLocationHidden = findHiddenValue('location')?.valueOf();

  return (
    <React.Fragment>
      <StaffRowDetailsSection group={groups[0]} staff={staff} onChangeSetting={onChangeSetting} />
      <StaffRowLocationSection
        group={groups[1]}
        staff={staff}
        onChangeSetting={onChangeSetting}
        hidden={isLocationHidden}
      />
      <StaffRowPatternSection
        group={groups[2]}
        staff={staff}
        onChangeSetting={onChangeSetting}
        hidden={isWorkPatternsHidden}
      />
      <StaffRowMinMaxMonthlySection
        group={groups[3]}
        staff={staff}
        onChangeSetting={onChangeSetting}
        hidden={isMinMaxMonthlyHidden}
      />
      <StaffRowMinMaxWeeklySection
        group={groups[4]}
        staff={staff}
        onChangeSetting={onChangeSetting}
        hidden={isMinMaxWeeklyHidden}
      />
      <StaffRowPersonalSection
        group={groups[5]}
        staff={staff}
        onChangeSetting={onChangeSetting}
        hidden={isPersonnelDetailsHidden}
      />
      <StaffRowActionSection
        group={groups[6]}
        staff={staff}
        onChangeSetting={onChangeSetting}
        onSetDelete={casualStaff ? onSetDelete : undefined}
      />
    </React.Fragment>
  );
};
