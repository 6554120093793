import { useCallback } from "react";
import { useSystemSettings } from "../settings/SystemSettingsProvider";
import { useTasks } from "../tasks/useTasks";
import { Shift, calculateShiftTime } from "./rosterHelper";

export const useCalculateShiftTime = () => {
  const tasks = useTasks();
  const includeBreaks = useSystemSettings(s => s.settings.includeBreaksInTotals);

  return useCallback((shift: Shift) => calculateShiftTime(shift, tasks, includeBreaks), [tasks, includeBreaks]);
};
