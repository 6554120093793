import { secondsToTimeString } from '@/helpers/dateHelper';
import { FC } from 'react';
import { SummaryContentContainer } from './SummaryContentContainer';
import { SummaryEmployeeRowStyle } from './SummaryEmployeeRow';
import { SummaryEmployeeShift } from '@/services/summary/summary.types';

interface Props {
  shift: SummaryEmployeeShift;
  rowStyle: SummaryEmployeeRowStyle;
  miniMode?: boolean;
}

export const SummaryShift: FC<Props> = ({ shift, rowStyle, miniMode }) => {
  const start = secondsToTimeString(shift.start);
  const end = secondsToTimeString(shift.end);

  if (rowStyle === SummaryEmployeeRowStyle.WEEK) {
    return (
      <div className="bg-on p-2 rounded-sm w-full text-center flex items-center justify-center">{`${start} - ${end}`}</div>
    );
  } else {
      return (
        <SummaryContentContainer miniMode={miniMode}>
          <div>{start}</div>
          <div>{end}</div>
        </SummaryContentContainer>
      );
  }
};
