import Excel from 'exceljs';
import { saveAs } from 'file-saver';

export interface ExcelJSColumn {
  header: string;
  key: string;
}

export interface ExcelJSSheet {
  name: string;
  columns: ExcelJSColumn[];
  data: Object[];
}

export interface ExcelJSWorkbook {
  name: string;
  sheets: ExcelJSSheet[];
}

export interface BaseNumberObject {
  [key: string]: number;
}

export const downloadFile = (text: string, type: string, name: string) => {
  // Weird hack- this is called the Byte Order Mark, or BOM.
  // Sticking at the start of a string indicates that the string includes weird UTF-8 characters.
  // We need it because just setting the charset in the blob type doesn't work for some reason.
  const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
  const url = window.URL.createObjectURL(new Blob([BOM, text], { type: `${type};charset=UTF-8` }));

  const elem = window.document.createElement('a');
  elem.href = url;
  elem.download = name;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

const calculateColumnsWidths = (data: Object[], extraSpace: number = 10): BaseNumberObject => {
  const columnWidths: BaseNumberObject = {};
  data.forEach((dataProps: any) => {
    const keys = Object.keys(dataProps);

    keys.forEach(key => {
      const hasProp = columnWidths.hasOwnProperty(key);
      if (!hasProp) {
        columnWidths[key] = key.length + extraSpace;
      } else {
        const propLength = dataProps[key].toString().length;
        if (columnWidths[key] < propLength) {
          columnWidths[key] = propLength + extraSpace;
        }
      }
    });
  });

  return columnWidths;
};

export const downloadExcelFile = async (
  workbookData: ExcelJSWorkbook,
  minColumnsWidth?: number,
  maxColumnsWidth?: number
) => {
  const { name: workBookName, sheets } = workbookData;
  const workbook = new Excel.Workbook();

  sheets.forEach(sheet => {
    const { name: workSheetName, columns, data } = sheet;
    const calculatedWidths = calculateColumnsWidths(data);

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach(column => {
      if (maxColumnsWidth) {
        column.width = maxColumnsWidth;
      } else {
        const columnKey = (column as ExcelJSColumn).key;
        if (calculatedWidths[columnKey]) {
          column.width = Math.max(calculatedWidths[columnKey], minColumnsWidth || 0);
        }
      }
      column.alignment = { horizontal: 'left' };
    });

    // loop through data and add each one to worksheet
    data.forEach(rowData => {
      worksheet.addRow(rowData);
    });
  });
  try {
    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${workBookName}.xlsx`);
  } catch (error: any) {
    console.error('Generating excel file Error', error);
  }
};
