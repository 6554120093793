import { strings } from '@/services/translation/strings';
import { Page, View, Text, Font } from '@react-pdf/renderer';
import { format } from 'date-fns';
import {FC, PropsWithChildren, ReactNode} from 'react';
import Nasu from '../../assets/fonts/Nasu/Nasu-Regular.ttf';
import {Style} from "@react-pdf/types";

interface Props extends PropsWithChildren {
  orientation: 'landscape' | 'portrait';
  headerContent?: ReactNode | string;
  datePrinted: Date;
  pageStyle?: Style;
}

Font.register({
  family: 'Nasu',
  src: Nasu
});

export const PdfPage: FC<Props> = ({ children, orientation, datePrinted, headerContent, pageStyle }) => {
  const header: ReactNode | undefined = typeof(headerContent) === 'string' ? <Text style={{ fontSize: 20, marginBottom: 10 }}>{headerContent}</Text> : headerContent;
  return (
    <Page size="A4" orientation={orientation}
          style={pageStyle ? pageStyle : {padding: 40, fontFamily: 'Nasu'}}
          wrap>
      {header}
      <View>{children}</View>
      <View style={{
          bottom: 30,
          left: 40,
          padding: '0px',
          position: 'absolute',
          width: '100%'
        }}
        fixed>
        <View style={{
            flexDirection: 'row',
            fontSize: 8,
            justifyContent: 'space-between',
        }}>
          <Text>{format(datePrinted, 'yyyy/MM/dd HH:mm:ss')}</Text>
          <Text render={({ pageNumber, totalPages }) => (
            strings.common.pagination.pageXOfY(pageNumber, totalPages))}/>
        </View>
      </View>
    </Page>
  );
};
