import { bgWorkPatternColour } from '@/pages/settings/sections/manageStaff/staffTable/StaffRow';
import { TableColumnGroup } from '@/pages/settings/sections/manageStaff/staffTable/StaffTable';
import React from 'react';
import { FC } from 'react';

interface Props {
  groups: TableColumnGroup[];
  checkedState: Record<string, boolean>;
  onCheckboxChange: any;
}

export const CheckboxGroup: FC<Props> = ({ groups, checkedState, onCheckboxChange }) => {
  return (
    <>
      {groups.map((group, groupIndex) => {
        let firstLastCheckboxes = groupIndex !== 0 && groupIndex !== groups.length - 1;
        const isChecked = checkedState[group.id];

        return !firstLastCheckboxes ? (
          <React.Fragment key={group.id}></React.Fragment>
        ) : (
          <div
            key={group.id}
            className="gap-2 inline-flex items-center justify-start border rounded-md text-sm font-medium py-1 px-1 focus:ring-none"
            style={{ backgroundColor: group.color }}
          >
            <input
              id={group.id}
              type="checkbox"
              checked={isChecked}
              onChange={() => onCheckboxChange(group.id)}
              className={`col-span-1 text-center rounded-sm p-2`}
              style={{ color: `${isChecked === true ? `${bgWorkPatternColour}` : ''}` }}
            />
            <span>{group.name}</span>
          </div>
        );
      })}
    </>
  );
};
