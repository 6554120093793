import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { useCallback } from 'react';
import { WorkingRoster } from './roster.types';
import { useWorkingRosterToGqlTemplate } from './rosterHelper';

export const useGetUpdateTemplateFromWorkingRoster = () => {
  const { allTemplateOptions: templateOptions } = useTemplatesForLocation();
  const workingRosterToGqlTemplate = useWorkingRosterToGqlTemplate();

  return useCallback(
    (workingRoster: WorkingRoster) => {
      const rosterOrigin = templateOptions.find(t => t.id === workingRoster?.templateId)?.template;

      return workingRosterToGqlTemplate(workingRoster, rosterOrigin, false, true);
    },
    [templateOptions, workingRosterToGqlTemplate]
  );
};
