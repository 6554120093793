import { Button } from '@/components/button';
import { AddButton } from '@/components/buttons/AddButton';
import { FilterOption, RadioFilterSelector } from '@/components/flterSelector/RadioFilterSelector';
import { colorArray, iconArray } from '@/helpers/dropdownOptionHelper';
import DownloadTasksButton from '@/pages/pdf/settings/DownloadTasksButton';
import { FilterOptions, TaskType, TextColors } from '@/services/tasks/task.types';
import { useSkills } from '@/services/tasks/useSkills';
import { useCreateTasks, useDeleteTasks, useTasks, useUpdateTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { TableColumn } from '../../layout/SettingsTable';
import { Task } from './Task';
import TasksSettingInUseTemplatesErrorModal from './TasksSettingErrorModal';
import { TasksTableHeader } from './TasksTableHeader';
import { InUseTask, useValidateTasksInUse } from './useValidateTasksInUse';

export const TasksSettings: FC = () => {
  const fetchedTasks = useTasks();
  const fetchedSkills = useSkills();
  const [tasks, setTasks] = useState<TaskType[]>(fetchedTasks);
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOptions>(FilterOptions.ALL);
  const [tasksToAdd, setTasksToAdd] = useState<TaskType[]>([]);
  const [tasksToUpdate, setTasksToUpdate] = useState<TaskType[]>([]);
  const [taskIdsToDelete, setTasksIdsToDelete] = useState<number[]>([]);
  const { create } = useCreateTasks();
  const { update } = useUpdateTasks();
  const { deleteTasks } = useDeleteTasks();
  const deleteTaskEnabled = useFlag('deleteTasks');

  useEffect(() => {
    setTasks(fetchedTasks);
  }, [fetchedTasks]);

  const addTask = useCallback(() => {
    let newTaskName = `${strings.settings.manageTasks.task} ${tasks.length + 1}`;
    const similarNameTasks = tasks.filter(t => t.name === newTaskName);
    if (similarNameTasks.length) {
      newTaskName += `-${similarNameTasks.length}`;
    }

    const newTask: TaskType = {
      id: '',
      name: newTaskName,
      displayName: `${strings.settings.manageTasks.displayName} ${tasks.length + 1}`,
      icon: iconArray[0].id,
      textColor: TextColors.BLACK,
      color: colorArray[0].value,
      defaultDuration: 15,
      isSupplementary: true,
      isInMonthlyTotals: false,
      isInCalculatedWorkingHours: true,
      skills: [],
      optionalSkills: [],
      isActive: true
    };

    const newTasksToAdd = [...tasksToAdd, newTask];
    setTasksToAdd(newTasksToAdd);

    const allTasks = [...tasks, newTask];
    setTasks(allTasks);
  }, [tasks]);

  const onTaskUpdate = (task: TaskType) => {
    if (task.id) {
      const tasksToBeUpdated = [...tasksToUpdate];
      const taskIndex = tasksToBeUpdated.findIndex(s => s.id === task.id);

      if (taskIndex !== -1) {
        tasksToBeUpdated[taskIndex] = task;
      } else {
        tasksToBeUpdated.push(task);
      }

      setTasksToUpdate(tasksToBeUpdated);
    } else {
      const tasksToBeAdded = [...tasksToAdd];
      const taskIndex = tasksToBeAdded.findIndex(t => t.id === task.id);

      if (taskIndex !== -1) {
        tasksToBeAdded[taskIndex] = task;
        setTasksToAdd(tasksToBeAdded);
      }
    }
  };

  const onTaskDelete = (taskId: number | string, isToBeDeleted: boolean) => {
    const taskIdsToBeDeleted = [...taskIdsToDelete];
    const deleteTaskIndex = taskIdsToBeDeleted.indexOf(taskId as any);

    if (deleteTaskIndex === -1 && isToBeDeleted) {
      taskIdsToBeDeleted.push(taskId as any);
    } else if (deleteTaskIndex >= 0 && !isToBeDeleted) {
      taskIdsToBeDeleted.splice(deleteTaskIndex, 1);
    }

    setTasksIdsToDelete(taskIdsToBeDeleted);
  };

  const onFilterChange = (e: any) => {
    let type = selectedFilterOption;

    if (e !== selectedFilterOption) {
      type = e;
    }

    setSelectedFilterOption(type);
  };

  const [inUseShopTemplates, setInUseShopTemplates] = useState<InUseTask>();
  const [inUseLibraryTemplates, setInUseLibraryTemplates] = useState<InUseTask>();
  const [tasksSettingErrorModalOpen, setTasksSettingErrorModalOpen] = useState(false);

  const validateTasks = useValidateTasksInUse();

  const onSave = async () => {
    const approvedForUpdate = tasksToUpdate.filter(t => !taskIdsToDelete.includes(t.id as any));
    const approvedForAdd = tasksToAdd.filter(t => !taskIdsToDelete.includes(t.id as any));

    const inUseTasks = validateTasks(approvedForUpdate.filter(s => !s.isActive));
    if (inUseTasks.tasksInUseForLibraryTemplates.size !== 0 || inUseTasks.tasksInUseForShopTemplates.size !== 0) {
      setInUseLibraryTemplates(inUseTasks.tasksInUseForLibraryTemplates);
      setInUseShopTemplates(inUseTasks.tasksInUseForShopTemplates);
      setTasksSettingErrorModalOpen(true);
      return;
    }

    if (taskIdsToDelete.length) {
      setTasksToAdd(tasksToAdd.filter(t => !taskIdsToDelete.includes(t.id as any)));
      const approvedForDelete = taskIdsToDelete.filter(t => typeof t === 'number');

      if (!approvedForDelete.length) {
        setTasks(tasks.filter(t => !(taskIdsToDelete as any[]).includes(t.id)));
        setTasksIdsToDelete([]);
      }
      try {
        if (approvedForDelete.length) {
          const deleteResult = await deleteTasks(approvedForDelete);
          setTasksIdsToDelete([]);

          if (!deleteResult.error) {
            setTasks(tasks.filter(t => !taskIdsToDelete.includes(t.id as any)));
          }
        }
      } catch (error) {
        console.log('Delete tasks error', error);
      }
    }

    if (approvedForUpdate.length) {
      try {
        await update(approvedForUpdate);
        setTasksToUpdate([]);
      } catch (error) {
        console.log('Update tasks error', error);
      }
    }

    if (approvedForAdd.length) {
      try {
        await create(approvedForAdd);
        setTasksToAdd([]);
      } catch (error) {
        console.log('Add tasks error', error);
      }
    }
  };

  const filteredTasks = useMemo(() => {
    switch (selectedFilterOption) {
      case FilterOptions.ACTIVE:
        return tasks.filter(s => s.isActive);
      case FilterOptions.INACTIVE:
        return tasks.filter(s => !s.isActive);

      default:
        return tasks;
    }
  }, [tasks, selectedFilterOption]);

  const columns: TableColumn[] = useMemo(() => {
    const cols = [
      {
        name: strings.settings.manageTasks.activeInactive,
        width: 102
      },
      {
        name: strings.settings.manageTasks.taskName,
        width: 200
      },
      {
        name: strings.settings.manageTasks.displayName,
        width: 200
      },
      {
        name: strings.settings.manageTasks.icon,
        width: 80
      },
      {
        name: strings.settings.manageTasks.textColor,
        width: 120
      },
      {
        name: strings.settings.manageTasks.colors,
        width: 90
      },
      {
        name: strings.settings.manageTasks.defaultDuration,
        width: 150
      },
      {
        name: strings.settings.manageTasks.supplementaryTask,
        width: 130
      },
      {
        name: strings.settings.manageTasks.includeInSummaryTotals,
        width: 130
      },
      {
        name: strings.settings.manageTasks.calculateWorkingHours,
        width: 130
      },
      {
        name: strings.settings.manageTasks.skill,
        width: 400
      },
      {
        name: strings.settings.manageTasks.optionalSkill,
        width: 400
      }
    ];

    if (deleteTaskEnabled) {
      cols.push({
        name: '',
        width: 100
      });
    }

    return cols;
  }, []);

  const filterOptions: FilterOption[] = useMemo(() => {
    return [
      {
        displayName: strings.settings.filter.displayActive,
        value: FilterOptions.ACTIVE
      },
      {
        displayName: strings.settings.filter.displayAll,
        value: FilterOptions.ALL
      },
      {
        displayName: strings.settings.filter.displayInactive,
        value: FilterOptions.INACTIVE
      }
    ];
  }, []);

  return (
    <>
      <TasksSettingInUseTemplatesErrorModal
        inUseLibraryTemplates={inUseLibraryTemplates}
        inUseShopTemplates={inUseShopTemplates}
        open={tasksSettingErrorModalOpen}
        onClose={() => setTasksSettingErrorModalOpen(false)}
      />
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <AddButton label={strings.settings.manageTasks.task} onButtonClick={addTask} />
            <RadioFilterSelector
              options={filterOptions}
              selectedOption={selectedFilterOption}
              onSelect={onFilterChange}
            />
          </div>
          <div className="flex gap-2">
            <DownloadTasksButton />
            <Button
              className="bg-[#1BB55C] text-[white] hover:text-[white] hover:bg-[#1BB55C]"
              onClick={onSave}
              variant={tasksToUpdate.length || taskIdsToDelete.length || tasksToAdd.length ? 'submit' : undefined}
              size="sm"
              disabled={tasksToUpdate.length || taskIdsToDelete.length || tasksToAdd.length ? undefined : true}
            >
              {strings.common.save}
            </Button>
          </div>
        </div>
      </SettingsSectionHeader>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div id="manage-tasks-table" className="flex-1 overflow-auto">
          <div className="flex flex-col h-full w-fit">
            <TasksTableHeader columns={columns} />
            {filteredTasks.length > 0 &&
              filteredTasks.map((task, index) => (
                <Task
                  key={task.id}
                  task={task}
                  index={index}
                  showIndexColumn={false}
                  deleteTaskEnabled={deleteTaskEnabled}
                  isToBeDeleted={taskIdsToDelete.includes(task.id as any)}
                  skillsList={fetchedSkills}
                  onTaskUpdate={onTaskUpdate}
                  onTaskDelete={onTaskDelete}
                  columns={columns}
                />
              ))}

            {!filteredTasks.length && (
              <div className="flex items-center justify-center col-span-full p-10 text-xl text-[#32323280] font-medium">
                {strings.settings.manageTasks.emptyTasksList}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
