import { FC, useEffect, useRef } from 'react';
import { TimeSlot, findSlotIndex } from './ganttHelper';
import { useGanttService, useGanttSubsectionConfig } from './GanttProvider';
import { GanttSlider } from './GanttSlider';
import { GanttSection } from './GanttTimePicker';

interface Props {
  subSection: GanttSection;
  otherSubSections: GanttSection[];
  max: number;
  min: number;
  timeSlots: TimeSlot[];
  minSubSlotIndex: number;
  maxSubSlotIndex: number;
  readOnly?: boolean;
  mainSection: GanttSection;
}

export const GanttSubsection: FC<Props> = ({
  subSection,
  otherSubSections,
  max,
  min,
  timeSlots,
  minSubSlotIndex,
  maxSubSlotIndex,
  readOnly,
  mainSection
}) => {
  const onChangeStart = (newTime: number) => {
    if (newTime < min) {
      return;
    }

    let overlapsOther = false;
    otherSubSections.forEach(otherSub => {
      // Does the new time lie within another section?
      if (otherSub.startTime < newTime && otherSub.endTime > newTime) {
        overlapsOther = true;
      }

      // Does the end of another section lie within the new section?
      if (otherSub.endTime > newTime && otherSub.endTime < subSection.endTime) {
        overlapsOther = true;
      }
    });

    if (overlapsOther) {
      return;
    }
    subSection.onChangeTimes(newTime, subSection.endTime);
  };

  const onChangeEnd = (newTime: number) => {
    if (newTime > max) {
      return;
    }

    let overlapsOther = false;
    otherSubSections.forEach(otherSub => {
      // Does the new time lie within another subsection?
      if (otherSub.startTime < newTime && otherSub.endTime > newTime) {
        overlapsOther = true;
      }

      // Does the start time lie within the new section bounds?
      if (newTime > otherSub.startTime && otherSub.startTime > subSection.startTime) {
        overlapsOther = true;
      }
    });

    if (overlapsOther) {
      return;
    }

    subSection.onChangeTimes(subSection.startTime, newTime);
  };

  const onChangeStartAndEnd = (newStart: number, newEnd: number) => {
    if (newEnd > max || newStart < min) {
      return;
    }

    let overlapsOther = false;
    otherSubSections.forEach(otherSub => {
      // Is the new start within another section?
      if (otherSub.startTime < newStart && otherSub.endTime > newStart) {
        overlapsOther = true;
      }

      // Is the new end within another section?
      if (otherSub.startTime < newEnd && otherSub.endTime > newEnd) {
        overlapsOther = true;
      }

      // Does another section start lie within the subsection?
      if (newEnd > otherSub.startTime && otherSub.startTime > newStart) {
        overlapsOther = true;
      }

      // Does another section start lie within the subsection?
      if (newEnd > otherSub.endTime && otherSub.endTime > newStart) {
        overlapsOther = true;
      }

      // Do the sections exactly overlap?
      if (newEnd === otherSub.endTime && newStart === otherSub.startTime) {
        overlapsOther = true;
      }
    });

    if (overlapsOther) {
      return;
    }

    subSection.onChangeTimes(newStart, newEnd);
  };

  let subSectionStartSlot = findSlotIndex(subSection.startTime, timeSlots, true);
  let subSectionEndSlot = findSlotIndex(subSection.endTime, timeSlots, false);

  if (subSection.startTime === subSection.endTime) {
    // If we have a subsection with 0 length, there are sometimes issues with the section trying to start
    // in a slot that doesn't exist.
    //
    // If this issue happens to start or end, we can just infer the correct value from the other value if
    // correct.
    if (subSectionStartSlot < 0 && subSectionEndSlot >= 0) {
      subSectionStartSlot = subSectionEndSlot + 1;
    } else if (subSectionEndSlot < 0 && subSectionStartSlot >= 0) {
      subSectionEndSlot = subSectionStartSlot - 1;
    }
  }

  const getSubConfig = useGanttSubsectionConfig();
  const slotLength = useGanttService(state => state.increment);
  const minLengthInSeconds = getSubConfig(subSection.typeContext || subSection.type)?.minLength ?? 0;
  const maxLengthInSeconds = getSubConfig(subSection.typeContext || subSection.type)?.maxLength;
  const minLengthInSlots = minLengthInSeconds / slotLength;
  const maxLengthInSlots = maxLengthInSeconds !== undefined ? maxLengthInSeconds / slotLength : undefined;

  return (
    <GanttSlider
      sub
      readOnly={readOnly}
      // startTime={subSection.startTime}
      // endTime={subSection.endTime}
      startSlot={subSectionStartSlot}
      endSlot={subSectionEndSlot}
      onChangeStart={onChangeStart}
      onChangeEnd={onChangeEnd}
      onChangeStartAndEnd={newStart =>
        onChangeStartAndEnd(newStart, newStart + (subSection.endTime - subSection.startTime))
      }
      onClear={subSection.clear}
      color={subSection.color}
      timeSlots={timeSlots}
      minSlotIndex={minSubSlotIndex}
      maxSlotIndex={maxSubSlotIndex}
      icon={subSection.icon}
      iconColor={subSection.iconColor}
      tooltip={subSection.tooltip}
      minimumSlotLength={minLengthInSlots}
      maximumSlotLength={maxLengthInSlots}
      capabilityTooltip={subSection.capabilityTooltip}
    />
  );
};
