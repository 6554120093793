import { SelectOption } from '@/components/form/SelectInput';
import { displaySecondsAsTime } from '@/components/gantt/gantt/ganttHelper';
import { hoursToSeconds } from 'date-fns';
import { useMemo } from 'react';

export const useHourOptions = (min: number = 0, max: number = 23) => {
  return useMemo(() => {
    let options: number[] = [min];

    for (let i = min + 1; i <= max; i++) {
      options.push(i);
    }

    let selectOptions: SelectOption[] = options.map(val => ({
      value: val,
      name: displaySecondsAsTime(hoursToSeconds(val))
    }));

    return selectOptions;
  }, [min, max]);
};
