import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { OrganisationNameType } from '../settings/systemSettings.types';
import { strings } from '../translation/strings';
import { useCurrentLocationHierarchyDetails } from './useLocationHierarchyDetails';

export const useOrganisationName = () => {
  const { customName, type } = useSystemSettings(state => state.settings.organisationName);
  const { brandName, divisionName, companyName } = useCurrentLocationHierarchyDetails();

  switch (type) {
    case OrganisationNameType.CUSTOM:
      if (customName) {
        return customName;
      }
    case OrganisationNameType.BRAND:
      if (brandName) {
        return brandName;
      }
    case OrganisationNameType.DIVISION:
      if (divisionName) {
        return divisionName;
      }
    case OrganisationNameType.COMPANY:
      if (companyName) {
        return companyName;
      }
  }

  return strings.settings.system.orgName.default;
};
