import { FC, ReactNode } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

interface Props {
  children?: ReactNode;
  message: string;
}
export const ErrorMessage: FC<Props> = ({ children, message }) => {
  console.log(message);

  return (
    <div className="p-10 flex flex-col items-center gap-2">
      <FiAlertTriangle className="h-12 w-12" />
      <p>{message}</p>
      {children}
    </div>
  );
};
