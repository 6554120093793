import { useEffect, useState } from 'react';
import { useCurrentOptimiserJob } from './useCurrentOptimiserJob';

export const useOptimising = (onComplete: () => void) => {
  const optimiserJob = useCurrentOptimiserJob();

  const [optimising, setOptimising] = useState<boolean>(!!optimiserJob && !optimiserJob.complete);

  useEffect(() => {
    const newOptimising = !!optimiserJob && !optimiserJob.complete;

    if (optimising === true && newOptimising === false) {
      onComplete();
    }

    setOptimising(newOptimising);
  }, [optimiserJob, optimising, onComplete]);

  return optimising;
};
