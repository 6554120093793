import DownloadReportButton from '@/pages/pdf/DownloadReportButton';
import { StaffSettingsFilterOptions } from '@/services/settings/useFilterStaffSettings';
import { strings } from '@/services/translation/strings';
import { FunctionComponent, useCallback } from 'react';
import { usePdfStaffSkillsProps } from '../useStaffSkillsPrintProps';
import { PdfStaffSkillOverviewDocument } from './PdfStaffSkillOverviewDocument';

interface PdfStaffSkillOverviewDownloadButtonProps {
  filterOption: StaffSettingsFilterOptions;
}

const PdfStaffSkillOverviewDownloadButton: FunctionComponent<PdfStaffSkillOverviewDownloadButtonProps> = ({
  filterOption
}) => {
  const printProps = usePdfStaffSkillsProps(filterOption);

  const createDocument = useCallback(() => {
    return <PdfStaffSkillOverviewDocument {...printProps} />;
  }, [printProps]);

  const fileName = 'staff-skills-overview';

  return (
    <DownloadReportButton
      name={fileName}
      createDocument={createDocument}
      buttonName={strings.settings.manageSkills.print.printByStaffName}
    />
  );
};

export default PdfStaffSkillOverviewDownloadButton;
