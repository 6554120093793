import { dateToApiFormat } from "@/helpers/dateHelper";
import { hoursToSeconds } from "date-fns";
import { TargetGt } from "../gql/graphql.generated";
import { WorkingBudgetLocation, WorkingBudgetDay, HQ_ALLOCATED_DAY_TYPE, FORECAST_TARGET_TYPE, SALES_DAY_TYPE } from "./budget.types";
import { getBudgetMonthlyValues } from "./getBudgetMonthlyValues";

export const buildBudgetFromData = (locationIds: number[], dates: Date[], targets: TargetGt[]) => {
  const locations: WorkingBudgetLocation[] = locationIds.map(id => {
    const locationTargets = targets?.filter(target => target.locationId === id);
    const days: WorkingBudgetDay[] = dates.map(date => ({
      date: date,
      locationForecast: 0,
      rosteredBudget: 0,
      financialTarget: 0,
      hqAllocatedHours: 0,
      staffMembers: 0,
      totalShiftCount: 0,
      filledShiftCount: 0,
      unfilledShifts: []
    }));

    days?.forEach(day => {
      const dayTargets = locationTargets.filter(
        target => dateToApiFormat(new Date(target.date)) === dateToApiFormat(day.date)
      );
      dayTargets.forEach(target => {
        if (target.type === HQ_ALLOCATED_DAY_TYPE) {
          day.hqAllocatedHours = hoursToSeconds(target.value);
        }

        if (target.type === FORECAST_TARGET_TYPE) {
          day.locationForecast = hoursToSeconds(target.value);
        }

        if (target.type === SALES_DAY_TYPE) {
          day.financialTarget = target.value;
        }
      });
    });

    const monthlyTargets = getBudgetMonthlyValues(dates[0], dates[dates.length - 1], locationTargets);

    return {
      id,
      days,
      hqAllocatedMonthlyTime: hoursToSeconds(monthlyTargets.time),
      hqAllocatedMonthlyBudget: monthlyTargets.money
    };
  });

  return locations;
};
