import { FC } from 'react';
import { strings } from '@/services/translation/strings';
import { AvailabilityItem, useAvailabilities } from './useAvailabilities';
import { WorkingShift } from "@/pages/daily/daily.types";

interface Props {
  shift: WorkingShift;
  selectedLocation: number;
  tableColumnsCount: number;
  onAllocationRequest: () => void;
}

export const AvailabilitiesFromOtherLocations: FC<Props> = ({ shift, tableColumnsCount, onAllocationRequest }) => {
  const fetchedAvailabilities = useAvailabilities(shift);

  const getAvailabilityCount = (
    availabilities: AvailabilityItem[]
  ): { fullyAvailable: number; partlyAvailable: number } => {
    const fullyAvailable = availabilities.filter(
      avail => avail.start <= shift.start! && avail.end >= shift.end!
    ).length;

    return {
      fullyAvailable,
      partlyAvailable: availabilities.length - fullyAvailable
    };
  };

  return (
    <tr className="">
      <td colSpan={tableColumnsCount} className="pb-3">
        <div className="flex flex-col w-full border-2 border-[#E8AA14] rounded-lg p-2 mt-3 gap-2 text-[#323232]">
          <div className="text-md text-[#002742]">{strings.daily.employeesDropdown.availabilities.header}:</div>
          <div className="flex flex-col items-center justify-center gap-3">
            <div id="availabilities" className="w-full flex flex-col">
              <table className="custom-table w-full border-collapse h-full" cellSpacing="0" cellPadding="0">
                <tbody>
                  {fetchedAvailabilities.length > 0 &&
                    fetchedAvailabilities.map(({ location, availabilities }, index) => {
                      const { fullyAvailable, partlyAvailable } = getAvailabilityCount(availabilities);
                      const generalClass = `flex h-full py-0.5 leading-none
                        border-y-2 border-[transparent]
                        group-first:text-start group-first:border-l-2 group-first:rounded-s-lg
                        group-last:border-e-2 group-last:rounded-e-lg group/av:rounded
                        ${index % 2 === 0 ? 'bg-[#DD820F10]' : ''}`;

                      return (
                        <tr className="group/av font-thin h-9" key={location.id}>
                          <td className="group p-0 max-w-[250px] ">
                            <div
                              className={`${generalClass} text-[#002742] text-start items-center pl-1.5`}
                            >{`${location.id} - ${location.name}`}</div>
                          </td>
                          <td className="group w-32">
                            <div className={`${generalClass} items-center justify-center font-semibold px-1.5`}>
                              {'000 0000 0000'}
                            </div>
                          </td>
                          <td className="group">
                            <div
                              className={`${generalClass} flex-col items-end justify-center text-[#0070D2] lowercase gap-0.5 pr-1.5`}
                            >
                              {fullyAvailable > 0 && (
                                <div className="text-end">
                                  <span className="font-bold ">{fullyAvailable}</span>{' '}
                                  {strings.daily.employeesDropdown.employeeStatus.available}
                                </div>
                              )}
                              {partlyAvailable > 0 && (
                                <div className="text-[#DD820F] drop-shadow-md text-end">
                                  <span className="font-bold">{partlyAvailable}</span>{' '}
                                  {strings.daily.employeesDropdown.employeeStatus.partly_available}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {fetchedAvailabilities.length === 0 && (
                <div className="flex justify-center text-[#EA5788] text-md">
                  {strings.daily.employeesDropdown.availabilities.notFound}
                </div>
              )}
            </div>
            <div
              className={`flex items-center justify-center font-bold py-1.5 px-5 rounded-lg ${
                fetchedAvailabilities.length
                  ? 'bg-[#0070D2] text-white hover:cursor-pointer hover:bg-[#0070D2CC] hover:outline outline-2 outline-[#E8AA14]'
                  : 'bg-[#32323220] text-[#00274270] hover:cursor-not-allowed'
              }`}
              onClick={() => (fetchedAvailabilities.length ? onAllocationRequest() : '')}
            >
              {strings.daily.employeesDropdown.availabilities.action}
            </div>
          </div>
          <div className="font-normal">{strings.daily.employeesDropdown.availabilities.footer}</div>
        </div>
      </td>
    </tr>
  );
};
