import { secondsToTimeString } from '@/helpers/dateHelper';
import { Note, PdfRosterNotes } from '@/pages/pdf/roster/PdfRosterNotes';
import { PrintViewTypes } from '@/pages/pdf/roster/usePrintOptions';
import { PdfParams } from '@/pages/pdf/summary/PdfSummaryDay';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { BudgetStats, WorkingBudgetLocation } from '@/services/budget/budget.types';
import { strings } from '@/services/translation/strings';
import { Document, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { format } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { FC, ReactNode, useMemo } from 'react';
import { PdfPage } from '../../PdfPage';
import { styles } from '../../PdfStyles';
import { PdfTableCell } from '../../PdfTableCell';
import { PdfBudgetTable } from '../PdfBudgetTable';
import { PdfDateRange } from '../PdfDateRange';
import { PdfMonthlyEmployeeRow } from './PdfMonthlyEmployeeRow';
import { ComplianceTotals } from '@/services/summary/buildCompliance';

interface Props {
  locationId: string;
  locationName: string;
  publishDateTime?: string;
  days: Date[];
  summary: SummaryEmployee[];
  notes: Note[];
  budgetData: WorkingBudgetLocation;
  budgetStats: BudgetStats;
  language: string;
  params: PdfParams;
  complianceTotals: ComplianceTotals;
}

export const MONTHLY_NAME_WIDTH = 90;
export const MONTHLY_TOTAL_WIDTH = 140;
const MONTHLY_NOTE_DATE_WIDTH = 90;
const MONTHLY_NOTE_CONTENT_WIDTH = 670;

const MONTHLY_DATE_CELL_WIDTH_OVERRIDE = 20;

const FIRST_PAGE_LENGTH = 11;
const OTHER_PAGE_LENGTH = 15;

const POSTER_FIRST_PAGE_LENGTH = 15;
const POSTER_OTHER_PAGE_LENGTH = 15;

const MonthlyReportTotalsHeader: FC = () => {
  return (
    <View style={{ flexDirection: 'column', width: MONTHLY_TOTAL_WIDTH }}>
      <PdfTableCell height={16} width={MONTHLY_TOTAL_WIDTH}>
        <Text style={styles.tableCell}>{strings.summary.monthlyTotal}</Text>
      </PdfTableCell>
      <View style={{ flexDirection: 'row', borderTopWidth: 0.5 }}>
        <PdfTableCell height={24} width={MONTHLY_TOTAL_WIDTH / 4}>
          <Text style={styles.tableCellSm}>{strings.summary.compliance.notRostered}</Text>
        </PdfTableCell>
        <PdfTableCell height={24} width={MONTHLY_TOTAL_WIDTH / 4}>
          <Text style={styles.tableCellSm}>{strings.summary.compliance.rostered}</Text>
        </PdfTableCell>
        <PdfTableCell height={24} width={MONTHLY_TOTAL_WIDTH / 4}>
          <View>
            <Text style={styles.tableCell}>{strings.summary.max}</Text>
            <Text style={styles.tableCell}>{strings.summary.min}</Text>
          </View>
        </PdfTableCell>
        <PdfTableCell height={24} width={MONTHLY_TOTAL_WIDTH / 4}>
          {' '}
          {/* height 24 fits for 2 lines. */}
          <Text style={styles.tableCell}>{strings.summary.periodTotal}</Text>{' '}
          {/* this text will be clipped if it wraps into more than 2 lines. */}
        </PdfTableCell>
      </View>
    </View>
  );
};

export const MonthlyReportAsPDF: FC<Props> = ({
                                                locationId,
                                                locationName,
                                                publishDateTime,
                                                days,
                                                summary,
                                                notes,
                                                language,
                                                budgetData,
                                                budgetStats,
                                                params,
                                                complianceTotals
                                              }) => {
  const pages: SummaryEmployee[][] = useMemo(() => {
    const pages: SummaryEmployee[][] = [];

    const rowsToAdd = [...summary];

    const firstPage = rowsToAdd.splice(
      0,
      params.viewType === PrintViewTypes.SCREEN ? FIRST_PAGE_LENGTH : POSTER_FIRST_PAGE_LENGTH
    );
    pages.push(firstPage);

    while (rowsToAdd.length > 0) {
      const pageToAdd = rowsToAdd.splice(
        0,
        params.viewType === PrintViewTypes.SCREEN ? OTHER_PAGE_LENGTH : POSTER_OTHER_PAGE_LENGTH
      );
      pages.push(pageToAdd);
    }

    return pages;
  }, [summary]);

  const date = new Date();
  const formatString = language === 'ja' ? 'yyyy年MMMM' : 'MMMM yyyy';
  let months = format(days[0], formatString, { locale: language === 'ja' ? ja : enUS });
  if (days[days.length - 1].getMonth() !== days[0].getMonth()) {
    months += ` - ${format(days[days.length - 1], formatString, { locale: language === 'ja' ? ja : enUS })}`;
  }

  const title = strings.summary.print.monthly.fileTitle(months);
  const header: ReactNode = (
    <View>
      <Text style={{ fontSize: 10 }}>{`${locationId}: ${locationName}`}</Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={{ fontSize: 18 }}>{title}</Text>
        <Text style={{ fontSize: 8, alignSelf: 'flex-end' }}>{publishDateTime}</Text>
      </View>
    </View>
  );
  const pageStyle: Style = { paddingHorizontal: 30, paddingVertical: 20, fontFamily: 'Nasu' };

  return (
    <Document>
      <PdfPage datePrinted={date} orientation={'landscape'} headerContent={header} pageStyle={pageStyle}>
        <View style={styles.table}>
          <PdfBudgetTable
            days={days}
            budgetData={budgetData}
            budgetStats={budgetStats}
            nameWidth={MONTHLY_NAME_WIDTH}
            totalWidth={MONTHLY_TOTAL_WIDTH}
            notes={notes}
            params={params}
            dateCellWidthOverride={MONTHLY_DATE_CELL_WIDTH_OVERRIDE}
          />
          {params.viewType === PrintViewTypes.SCREEN && (
            <View style={styles.tableRow}>
              <PdfTableCell width={MONTHLY_NAME_WIDTH} />
              <MonthlyReportTotalsHeader />
            </View>
          )}
          {params.viewType === PrintViewTypes.SCREEN && (
            <View style={styles.tableRow}>
              <PdfTableCell width={MONTHLY_NAME_WIDTH} height={20}>
                <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.summary.employee}</Text>
              </PdfTableCell>
              <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
                <Text style={styles.tableCell}>{complianceTotals.holidayDays}</Text>
              </PdfTableCell>
              <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
                <Text style={styles.tableCell}>{complianceTotals.attendanceDays}</Text>
              </PdfTableCell>
              <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}></PdfTableCell>
              <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
                <Text style={styles.tableCell}>{secondsToTimeString(complianceTotals.actualTime)}</Text>
              </PdfTableCell>
            </View>
          )}
          {pages[0].map((employee: SummaryEmployee) => (
            <PdfMonthlyEmployeeRow
              key={employee.id}
              days={days}
              employee={employee}
              params={params}
            />
          ))}
        </View>
        {pages.length === 1 /* lastPage? */ && (
          <PdfRosterNotes notes={notes} dateWidth={MONTHLY_NOTE_DATE_WIDTH} contentWidth={MONTHLY_NOTE_CONTENT_WIDTH} />
        )}
      </PdfPage>
      {pages.slice(1).map((page, index) => (
        <PdfPage key={index} datePrinted={date} headerContent={header} orientation={'landscape'} pageStyle={pageStyle}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <PdfTableCell width={MONTHLY_NAME_WIDTH}>
                <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.summary.employee}</Text>
              </PdfTableCell>
              {params.viewType === PrintViewTypes.SCREEN && <MonthlyReportTotalsHeader />}
              <PdfDateRange days={days} notes={notes} dateWidthOverride={MONTHLY_DATE_CELL_WIDTH_OVERRIDE} />
            </View>
            {page.map((employee: SummaryEmployee) => (
              <PdfMonthlyEmployeeRow
                key={employee.id}
                days={days}
                employee={employee}
                params={params}
              />
            ))}
          </View>
          {index == pages.length - 2 /* lastPage? */ && (
            <PdfRosterNotes
              notes={notes}
              dateWidth={MONTHLY_NOTE_DATE_WIDTH}
              contentWidth={MONTHLY_NOTE_CONTENT_WIDTH}
            />
          )}
        </PdfPage>
      ))}
    </Document>
  );
};
