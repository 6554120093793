export const deltaToColor = (delta?: number) => {
  let color: string;
  if (delta !== undefined && delta < 0) {
    color = '#0070D1';
  } else if (delta !== undefined && delta > 0) {
    color = '#D53D6B';
  } else if (delta === 0) {
    color = '#1bb65c';
  } else {
    color = '#000000';
  }

  return color;
};
