import { Button } from '@/components/button';
import { Modal, ModalProps } from '@/components/modal/Modal';
import { strings } from '@/services/translation/strings';
import { FunctionComponent } from 'react';
import { MdClose, MdWarning } from 'react-icons/md';

interface StaffSettingsErrorModalProps extends ModalProps {
  errors: string[];
}

const StaffSettingsErrorModal: FunctionComponent<StaffSettingsErrorModalProps> = ({ open, onClose, errors }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-4">
        <div className="flex justify-between space-x-5">
          <div className="text-red-500 text-xl font-semibold flex items-center space-x-2">
            <MdWarning />
            <span>{strings.settings.staffFilter.errors.dataError}</span>
          </div>
          <Button variant={'ghost'} onClick={onClose}>
            <MdClose className="text-2xl" />
          </Button>
        </div>
        <div className="space-y-2">
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default StaffSettingsErrorModal;
