import { GridDropdownType, GridSelect, GridSelectOption } from '@/components/form/GridSelect';
import { colorArray } from '@/helpers/dropdownOptionHelper';
import clsx from 'clsx';
import React from 'react';

interface ColorSelectProps {
  selectedColor: string;
  onSelectColor: (color: string) => void;
  dropdownId: string;
  parentContainerId?: string;
  className: string;
  readOnly?: boolean;
  excludedColors?: string[];
}

const ColorSelect: React.FC<ColorSelectProps> = ({
  selectedColor,
  onSelectColor,
  dropdownId,
  parentContainerId,
  className,
  readOnly,
  excludedColors
}) => {
  const colorOptions = !excludedColors ? colorArray : colorArray.filter(c => !excludedColors?.includes(c.value));

  return (
    <GridSelect
      readOnly={readOnly}
      type={GridDropdownType.COLOR}
      selected={selectedColor}
      dropdownOptions={colorOptions}
      itemsPerRow={6}
      className={className}
      dropdownId={`colors-${dropdownId}`}
      parentContainerId={parentContainerId}
    >
      {colorOptions.map((color, index) => (
        <GridSelectOption
          key={index}
          className={clsx('p-1.5 text-2xl w-8 h-8 rounded-full')}
          isSelected={color.value === selectedColor}
          onChange={() => onSelectColor(color.value)}
          options={colorArray}
          option={color}
          type={GridDropdownType.COLOR}
        ></GridSelectOption>
      ))}
    </GridSelect>
  );
};

export default ColorSelect;
