import { Tooltip } from '@/components/tooltip/Tooltip';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { INDEX_COLUMN_WIDTH, TableColumn } from './SettingsTable';
import { SettingsTableHeaderCell } from './SettingsTableHeaderCell';

interface Props {
  columns: TableColumn[];
  showIndexColumn?: boolean;
  calculatedOffset?: number;
  className?: string;
}

export const SettingsTableHeader: FC<Props> = ({ columns, showIndexColumn, calculatedOffset = 0, className }) => {
  if (columns.length < 1) {
    throw Error('Must provide at least one column');
  }

  return (
    <>
      {showIndexColumn && (
        <SettingsTableHeaderCell
          className="sticky left-0 top-0"
          style={{ width: INDEX_COLUMN_WIDTH, zIndex: columns.length + 1 }}
        />
      )}
      {columns.map((col, index) => {
        let styles: React.CSSProperties = {
        };

        if (!col.ignoreZIndex) {
          styles.zIndex = columns.length - index
        }

        if (col.isFixedPosition) {
          styles.left =
            calculatedOffset !== undefined
              ? calculatedOffset + (showIndexColumn ? INDEX_COLUMN_WIDTH : 0)
              : calculatedOffset;
        }

        return (
          <SettingsTableHeaderCell
            key={`column-${index}`}
            style={styles}
            className={twMerge('top-0 select-none flex justify-between items-center', className)}
          >
            <span>{col.name}</span>
            {col.tooltipText && <Tooltip position={'bottom'}>{col.tooltipText}</Tooltip>}
          </SettingsTableHeaderCell>
        );
      })}
    </>
  );
};
