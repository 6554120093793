import { useAnnouncements } from '@/services/announcements/useAnnouncements';
import { useEffect, useMemo, useState } from 'react';
import { Announcement, FilterOptions } from './Announcement';

export const useAnnouncementList = (filterOption?: FilterOptions) => {
  const fetchedAnnouncements = useAnnouncements();
  const [announcements, setAnnouncements] = useState<Announcement[]>(fetchedAnnouncements);

  useEffect(() => {
    setAnnouncements(fetchedAnnouncements);
  }, [fetchedAnnouncements]);

  const filteredAnnouncements = useMemo(() => {
    switch (filterOption) {
      case FilterOptions.ACTIVE:
        return announcements.filter(a => a.isActive);
      case FilterOptions.INACTIVE:
        return announcements.filter(a => !a.isActive);

      default:
        return announcements;
    }
  }, [announcements, filterOption]);

  return { announcements: filteredAnnouncements, setAnnouncements };
};
