import { cn } from '@/lib/utils';
import { GanttGridLineSpacing } from '@/services/settings/systemSettings.types';
import { getMePreferences } from '@/services/staff/preferences';
import { FC } from 'react';
import { DropZoneItem, GanttDropZone } from './GanttDropZone';
import GanttHourlyGridLinesSpacing from './GanttGridLineSpacing';
import { useTimeSlots } from './ganttHelper';
import { useGanttService, useGanttTimeSlots } from './GanttProvider';

interface GridProps {
  onDrop: (item: DropZoneItem, start: number, end: number) => void;
}

export const MINUTES_PER_SLOT = 15;

export const Grid: FC<GridProps> = ({ onDrop }) => {
  const { minTime, maxTime } = useGanttService(state => ({
    minTime: state.minTime,
    maxTime: state.maxTime
  }));
  const timeSlots = useGanttTimeSlots();
  const hourlySlots = useTimeSlots(minTime, maxTime, 60 * 60);

  const prefs = getMePreferences();

  const lineSpacing = prefs ? prefs.personalization.ganttGridLines : GanttGridLineSpacing.none;

  const markerLines = useGanttService(s => s.markerLines);

  return (
    <div className="flex w-full h-full border-dashed absolute">
      {hourlySlots.map((slot, hourlySlotIndex) => {
        return (
          <div className={cn('flex-1 w-full h-full border-r border-dashed z-40 pointer-events-none')} key={slot.start}>
            {lineSpacing !== GanttGridLineSpacing.none && (
              <GanttHourlyGridLinesSpacing setting={lineSpacing} slotIndex={hourlySlotIndex} />
            )}
          </div>
        );
      })}
      <div className="absolute w-full h-full flex">
        {timeSlots.map(slot => (
          <GanttDropZone
            timeSlot={slot}
            color={'#AAAAAA'}
            className="h-full flex-1 z-[1000]"
            key={slot.start}
            onDrop={onDrop}
          />
        ))}
      </div>
      {markerLines?.map((line, index) => (
        <div
          key={`${index}-${line.time}`}
          style={{ left: `${(100 * (line.time - minTime)) / (maxTime - minTime)}%`, backgroundColor: line.color }}
          className="absolute w-[4px] h-full -ml-[2px]"
        />
      ))}
    </div>
  );
};
