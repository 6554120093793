import { Button } from '@/components/button';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { useOptimising } from '@/services/optimiser/useOptimising';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateRoster } from './useCreateRoster';
import { useRosterService } from './providers/RosterOptionsService';

export const RosterCreatePage: FC = () => {
  const { create, fetching } = useCreateRoster();
  const navigate = useNavigate();

  const refresh = useRosterService(state => state.refresh);
  const optimising = useOptimising(refresh);

  if (optimising) {
    return <LoadingIndicator loadingLabel={strings.optimiser.inProgress} />;
  }

  return (
    <div className="flex flex-col items-center gap-5 p-5">
      <p>{strings.daily.roster.noRoster}</p>
      <div className="flex gap-5 items-center">
        <Button onClick={() => navigate('/monthly')}>{strings.common.cancel}</Button>
        <Button onClick={fetching ? undefined : create} loading={fetching} variant={'primary'}>
          {strings.common.create}
        </Button>
      </div>
    </div>
  );
};
