import { AvailabilityIndicator } from '@/components/availability/AvailabilityIndicator';
import { WorkingShift } from "@/pages/daily/daily.types";
import { EmployeeOptionSettingsContext } from "@/pages/daily/employeeSelector/EmployeeOptionSettingsProvider";
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { TaskType } from '@/services/tasks/task.types';
import { strings } from '@/services/translation/strings';
import { FC, useContext } from 'react';
import { columnProps } from './EmployeeTable';
import { AllocationStatus, EmployeeForShiftItem, EmployeeStatus } from './employeeForShift.types';

interface Props {
  employee: EmployeeForShiftItem;
  shift: WorkingShift;
  isEmptyOption?: boolean;
  onClick: () => void;
}

export const EmployeeOption: FC<Props> = ({ employee, onClick, shift, isEmptyOption }) => {
  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);
  const config = useContext(EmployeeOptionSettingsContext);
  const complianceData = employee.employeeSummary?.compliance;

  const getPropertyValue = (propName: string, employee: EmployeeForShiftItem) => {
    const propValue = (employee as any)[propName];

    if (propName === 'status') {
      return (strings.daily.employeesDropdown.employeeStatus as any)[propValue];
    }

    if (propName === 'name') {
      if (displayIds) {
        return `${employee.name} (${employee.employeeId})`;
      }
    }

    if (propName === 'allocation') {
      if (employee.allocated !== AllocationStatus.NOT_ALLOCATED) {
        return strings.daily.employeesDropdown.Allocation.allocated;
      }

      if (employee.leaveType) {
        return <AvailabilityIndicator leaveTypeId={employee.leaveType} fullName={true} />;
      }

      return strings.daily.employeesDropdown.Allocation.notAllocated;
    }

    if (propName === 'holidays') {
      if (!complianceData || complianceData.holidays === 0) {
        return '-';
      }

      return complianceData.holidays;
    }

    if (propName === 'attendances') {
      if (!complianceData || complianceData.attendanceDays === 0) {
        return '-';
      }

      return complianceData.attendanceDays;
    }

    if (propName === 'workingHours') {
      if (!complianceData || complianceData.actualTime === 0) {
        return '-';
      }

      return Math.round(complianceData.actualTime / 60 / 60 * 100) / 100;
    }

    if (propName === 'tasks') {
      if (shift.tasks.length === 0) {
        return '-';
      }

      return `${employee.taskMatch?.length}/${shift.tasks.length}`;
    }

    if (propName === 'taskNames') {
      if (!employee.taskMatch?.length) {
        return '-';
      }

      return `${employee.taskMatch?.map((t: TaskType) => t.displayName).join(', ')}`;
    }

    if (propName === 'timeAvailable') {
      if (!employee.timeAvailable || employee.timeAvailable.length === 0) {
        return '-';
      }

      return <div>
        {employee.timeAvailable.map(t => <div key={t}>{t}</div>)}
      </div>;
    }

    if (typeof propValue === 'object') {
      return propValue.length ? propValue.join(', ') : '#';
    }

    return propValue || '#';
  };

  const firstColumnIndex = columnProps.findIndex(p => !config.showStaffPickerColumn(p.key));

  return (
    <tr className="group/tr">
      {columnProps.map((prop, index) => {
        return config.showStaffPickerColumn(prop.key) ? (
          <></>
        ) : (
          <td
            key={prop.key}
            className={`
                    group
                      ${
              isEmptyOption
                ? index === firstColumnIndex
                  ? ''
                  : 'text-[transparent]'
                : prop.key === 'status'
                  ? employee.status === EmployeeStatus.NOT_AVAILABLE
                    ? 'text-[#ff0000]'
                    : employee.status === EmployeeStatus.AVAILABLE
                      ? 'text-[#0070D2]'
                      : employee.status === EmployeeStatus.PARTLY_AVAILABLE
                        ? 'text-[#DD820F]'
                        : 'text-[#323232]'
                  : ''
            }
                        `}
          >
            <div className={`flex h-full w-full py-1`}>
              <div
                className={`p-1 text-center flex-grow
                      border-y border-[#32323280]
                      group-first:text-start group-first:border-l group-first:rounded-s-lg
                      group-last:border-r group-last:rounded-e-lg ${
                  employee.assignable
                    ? 'group-hover/tr:cursor-pointer group-hover/tr:bg-[#cbd5e160]'
                    : 'group-hover/tr:cursor-not-allowed bg-[#32323240]'
                } ${employee.id === shift.assignedEmployeeId ? 'group-hover/tr:bg-[#EFEFC2] bg-[#EFEFC2]' : ''} ${
                  prop.wrapText ? '' : 'w-max'
                }`}
                onClick={onClick}
              >
                {isEmptyOption ? (index === firstColumnIndex ? 'Select' : '#') : getPropertyValue(prop.key, employee)}
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
};
