import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate, useCopyTemplateMutation, useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { DailyProperties } from '../../daily.types';
import { useConvertToUpdatable, useWorkingTemplateToGqlTemplate } from '../templateHelper';
import { useSelectedLibraryTemplate, useWorkingLibraryTemplate } from './TemplateLibraryService';
import { useSaveLibraryTemplate } from './useSaveLibraryTemplate';
import { useTemplateLibraryOptions } from '@/services/rosters/useTemplateLibraryOptions';

export const usePublishLibraryTemplate = () => {
  const { workingTemplate } = useWorkingLibraryTemplate();
  const { templateOptions, refetch } = useTemplateLibraryOptions();
  const selectedTemplate = useSelectedLibraryTemplate();
  const { saveTemplate, error: saveError, fetching: saveFetching } = useSaveLibraryTemplate();
  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));
  const [{ fetching: copyFetching, error: copyError }, copy] = useCopyTemplateMutation();
  const [{ fetching: updateFetching, error: updateError }, update] = useUpdateTemplateMutation();

  const convertToUpdatable = useConvertToUpdatable();

  const workingToGql = useWorkingTemplateToGqlTemplate();
  const workingAsGql = useMemo(() => {
    if (!workingTemplate || !selectedTemplate) {
      return undefined;
    }

    return workingToGql(workingTemplate, selectedTemplate as ReadTemplate);
  }, [workingToGql, workingTemplate, selectedTemplate]);

  const publishTemplate = useCallback(async () => {
    if (!workingTemplate || !workingAsGql || !workingAsGql.stations) {
      throw Error('Working template should be defined');
    }

    const saveResult = await saveTemplate(true);

    if (saveResult.error) {
      return saveResult;
    }

    const newVersion = saveResult.data?.updateTemplate?.version;

    if (!newVersion) {
      throw Error('Unable to get up to date template version');
    }

    let publishedTemplate = templateOptions.find(t => t.template.baseTemplateId === workingTemplate.id)?.template;

    if (!publishedTemplate) {
      const result = await copy({ sourceTemplateId: workingTemplate.id, name: workingTemplate.name });

      if (result.error) {
        return result;
      }

      publishedTemplate = result.data?.copyTemplate as ReadTemplate;

      if (!publishTemplate) {
        throw Error('Could not create copy of template');
      }
    }

    const updateTemplate = convertToUpdatable(publishedTemplate);

    // Take a copy of the working template's locations.
    updateTemplate.stations = cloneDeep(workingAsGql.stations);

    // We need to walk through the stations, positions and timeslots and remove
    // all the IDs.
    // If we don't do this, the original template gets it's data wiped.
    updateTemplate.stations.forEach(s => {
      s.id = undefined;
      s.positions?.forEach(p => {
        p.id = undefined;
        p.timeSlots?.forEach(ts => (ts.id = undefined));
      });
      s.tasks?.forEach(t => (t.id = undefined));
    });

    const newProperties: DailyProperties = {
      scratchpad: workingTemplate.scratchpad,
      lastPublished: new Date().toISOString(),
      notes: workingTemplate.notes
    };

    updateTemplate.properties = JSON.stringify(newProperties);
    updateTemplate.draft = false;
    updateTemplate.baseTemplateId = workingTemplate.id;
    updateTemplate.version = newVersion;

    const updateResult = await update({ updateTemplate });

    refetch();

    unregisterForConfirmation();

    return updateResult;
  }, [saveTemplate, copy, unregisterForConfirmation, workingTemplate, templateOptions]);

  return {
    publishTemplate,
    error: saveError || copyError || updateError,
    fetching: saveFetching || copyFetching || updateFetching
  };
};
