import { SkillType } from '@/services/tasks/skillSettings.types';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { useCallback } from 'react';

export const useValidateSkillsInUse = () => {
  const tasks = useTasks();

  return useCallback(
    (skills: SkillType[]) => {
      const errors: string[] = [];

      skills.forEach(s => {
        tasks.forEach(t => {
          if (t.skills.includes(s.id)) {
            errors.push(strings.settings.manageSkills.errors.mandatory(s.name, t.displayName));
          }

          if (t.optionalSkills.includes(s.id)) {
            errors.push(strings.settings.manageSkills.errors.optional(s.name, t.displayName));
          }
        });
      });

      return errors;
    },
    [tasks]
  );
};
