import { EmployeeComplianceData, SummaryDay, SummaryEmployee } from './summary.types';

export const buildComplianceDataForEmployee = (schedule: SummaryDay[]) => {
  const complianceData: EmployeeComplianceData = {
    holidays: 0,
    attendanceDays: 0,
    shifts: 0,
    avaliableDays: 0,
    actualTime: 0,
    basicTime: 0
  };

  schedule.forEach(day => {
    if (day.shifts.length === 0) {
      complianceData.holidays += 1;

      if (!day.leaveType) {
        complianceData.avaliableDays += 1;
      }
    } else {
      complianceData.attendanceDays += 1;

      day.shifts.forEach(shift => {
        complianceData.basicTime += shift.basicTime;
        complianceData.actualTime += shift.actualTime;
        complianceData.shifts += 1;
      });
    }
  });

  return complianceData;
};

export interface ComplianceTotals {
  attendanceDays: number;
  holidayDays: number;
  actualTime: number;
}

export const buildComplianceDataTotals = (schedules: SummaryEmployee[]): ComplianceTotals => {
  let totalAttendanceDays = 0;
  let totalHolidayDays = 0;
  let totalTime = 0;

  schedules.forEach(schedule => {
    if (schedule.homeEmployee) {
      totalAttendanceDays += schedule.compliance.attendanceDays;
      totalHolidayDays += schedule.compliance.holidays;
      totalTime += schedule.compliance.actualTime;
    }
  });

  return { attendanceDays: totalAttendanceDays, holidayDays: totalHolidayDays, actualTime: totalTime };
};
