import {Text, View} from '@react-pdf/renderer';
import { FC } from 'react';
import {styles} from "@/pages/pdf/PdfStyles";
import {PdfTableCell} from "@/pages/pdf/PdfTableCell";
import {TaskType} from "@/services/tasks/task.types";
import {strings} from "@/services/translation/strings";
import {iconArray} from "@/helpers/dropdownOptionHelper";
import {SvgIcon} from "@/pages/pdf/SvgIcon";
import {SkillType} from "@/services/tasks/skillSettings.types";
import {PdfTaskSkills} from "@/pages/pdf/settings/PdfTaskSkills";

interface Props {
  task: TaskType;
  skills: SkillType[]
}

export const TASK_NAME_WIDTH = 100;
export const DISPLAY_NAME_WIDTH = 100;
export const ICON_WIDTH = 40;
export const SUPPLEMENTARY_TASK_WIDTH = 80;
export const INC_SUMMARY_TOTALS_WIDTH = 80;
export const WORK_HOURS_WIDTH = 80;
export const SKILLS_WIDTH = 160;
export const OPTIONAL_SKILLS_WIDTH = 160;

export const PdfTaskRowHeader: FC = () => {
  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={TASK_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.taskName}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={DISPLAY_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.displayName}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={ICON_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.icon}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={SUPPLEMENTARY_TASK_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.supplementaryTask}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={INC_SUMMARY_TOTALS_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.includeInSummaryTotals}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={WORK_HOURS_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.calculateWorkingHours}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={SKILLS_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.skill}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={OPTIONAL_SKILLS_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{strings.settings.manageTasks.optionalSkill}</Text>
        </View>
      </PdfTableCell>
    </View>
  );
};

export const PdfTaskRow: FC<Props> = ({ task, skills }) => {
  const icon = iconArray.find(icon => icon.id === task.icon);

  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={TASK_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{task.name}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={DISPLAY_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{task.displayName}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={ICON_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          {icon ? <View style={{ padding: `0px ${Math.round((ICON_WIDTH - 16)/2)}px` }}> {/* 16 is target viewBoxSize. 12 is target svg width/height*/}
              <SvgIcon paths={icon.svgPaths} viewBoxSize={icon.svgViewboxSize} size={12} color={task.color} />
            </View> : <Text />
          }
        </View>
      </PdfTableCell>
      <PdfTableCell width={SUPPLEMENTARY_TASK_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'center' }}>{task.isSupplementary ? strings.settings.manageTasks.yes : strings.settings.manageTasks.no}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={INC_SUMMARY_TOTALS_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'center' }}>{task.isInMonthlyTotals ? strings.settings.manageTasks.yes : strings.settings.manageTasks.no}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={WORK_HOURS_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'center' }}>{task.isInCalculatedWorkingHours ? strings.settings.manageTasks.yes : strings.settings.manageTasks.no}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={SKILLS_WIDTH}>
        <PdfTaskSkills skillIds={task.skills} skills={skills}/>
      </PdfTableCell>
      <PdfTableCell width={OPTIONAL_SKILLS_WIDTH}>
        <PdfTaskSkills skillIds={task.optionalSkills} skills={skills}/>
      </PdfTableCell>
    </View>
  );
};
