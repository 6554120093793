import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../PdfStyles';
import { PrintViewType } from '../roster/usePrintOptions';
import { LeaveType } from '@/services/leaveManagement/leave.types';

// Context provider cannot be used inside the Document element
// The PdfParams object is passed in from outside the Document element.
export interface PdfParams {
  leaveTypes: LeaveType[];
  viewType: PrintViewType
}

interface Props {
  day: Date;
  employee: SummaryEmployee;
  params: PdfParams;
}

export const PdfSummaryDay: FC<Props> = ({ day, employee, params}) => {
  const shift = employee.schedule.find(s => dateToApiFormat(new Date(s.date)) === dateToApiFormat(day));

  let labelContent: JSX.Element = <></>;
  let mainContent: JSX.Element = (
    <Text style={{ fontSize: 7 }}>{shift?.leaveType ? strings.summary.holiday : strings.summary.available}</Text>
  );

  const leaveType = params.leaveTypes.find(lt => lt.id === shift?.leaveType);
  let leaveTypeName = leaveType?.properties.displayName ?? leaveType?.name ?? '';

  leaveTypeName = leaveTypeName.length > 5 ? leaveTypeName.substring(0, 4) : leaveTypeName;

  if (shift?.leaveType) {
    labelContent = <Text style={{ ...styles.tableCellSm, textTransform: 'uppercase' }}>{leaveTypeName}</Text>;
  } else if (shift?.os) {
    labelContent = <Text style={{ ...styles.tableCellSm, textTransform: 'uppercase' }}>{strings.summary.reassigned}</Text>;
  }

  if (shift && shift.shifts.length > 0) {
    mainContent = (
      <>
        {shift.shifts.map(currentShift => (
          <View
            style={{
              backgroundColor: '#c2f9d8',
              width: '80%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              marginVertical: 1
            }}
          >
            <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(currentShift.start)}</Text>
            <Text style={{ ...styles.tableCellSm }}>{secondsToTimeString(currentShift.end)}</Text>
          </View>
        ))}
      </>
    );
  }

  let backgroundColor: string | undefined = undefined;
  if (shift?.leaveType) {
    backgroundColor = leaveType?.properties.color;
  } else if (shift?.os) {
    backgroundColor = '#E8AA14';
  }

  return (
    <View
      style={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor
      }}
    >
      {labelContent}
      {mainContent}
    </View>
  );
};
