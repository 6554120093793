import { LoadingIndicator } from '@/components/LoadingIndicator';
import { FC, PropsWithChildren, Suspense } from 'react';
import { SettingsHeader, SettingsSubpage } from './layout/SettingsHeader';
import { EditSettingsProvider } from './services/EditSettingsService';

interface Props extends PropsWithChildren {
  hideDefaultSaveFor?: SettingsSubpage[];
}

export const SettingsPage: FC<Props> = ({ hideDefaultSaveFor, children }) => {
  return (
    <EditSettingsProvider>
      <div className="h-full flex flex-col">
        <Suspense fallback={<LoadingIndicator />}>
          <SettingsHeader hideDefaultSaveFor={hideDefaultSaveFor!} />
          <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
        </Suspense>
      </div>
    </EditSettingsProvider>
  );
};
