import { EmployeeTooltip } from '@/components/tooltip/EmployeeTooltip';
import { StaffAvailabilityDay, StaffAvailabilityMonth } from '@/services/availability/useStaffAvailability';
import { useGetContractName } from '@/services/contract/useGetContractName';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { displayName } from '@/services/translation/name';
import { FC } from 'react';
import { EMPLOYEE_NAME_COLUMN_WIDTH } from './AvailabilityTable';
import { AvailabilityTableCell } from './AvailabilityTableCell';
import {useWorkPreferencesTrackingService} from "@/services/availability/WorkPreferencesTrackingService";

interface Props {
  data: StaffAvailabilityMonth;
  onClick: (day: StaffAvailabilityDay) => void;
}

export const AvailabilityTableRow: FC<Props> = ({ data, onClick }) => {
  const { days, employee } = data;

  const { lastUpdated } = useWorkPreferencesTrackingService(state => ({
    lastUpdated: state.lastUpdated
  }));

  const workPrefUpdatedOn = lastUpdated.find(x => x.identityId === employee.identityId)?.updatedOn;

  return (
    <div className="flex border-b">
      <div className="border-r p-2 flex items-center justify-between" style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}>
        <div>
          <p>{data.displayEmployeeName}</p>
          <p>{data.displayContractName}</p>
        </div>
        <EmployeeTooltip employee={employee} workPrefUpdatedOn={workPrefUpdatedOn} />
      </div>
      {days.map(day => (
        <AvailabilityTableCell data={day} key={day.date.toISOString()} onClick={() => onClick(day)} />
      ))}
      <div className="w-5"></div>
    </div>
  );
};
