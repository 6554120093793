import { StaffSettingsFilterOptions } from '@/services/settings/useFilterStaffSettings';
import { FC, useState } from 'react';
import { useEditSettingsService } from '../../services/EditSettingsService';
import { StaffSettingsAddCasual } from './StaffSettingsAddCasual';
import { StaffSettingsFilterHeader } from './StaffSettingsFilterHeader';
import { StaffSettingsGroupsHeader } from './StaffSettingsGroupsHeader';
import { StaffTable, TableColumnGroup } from './staffTable/StaffTable';
import { useInitialGroups } from './useInitialGroups';
import { useStaffColumns } from './useStaffColumns';

export const staffFirstSectionColour = '#D2EACC';
export const workPatternColour = '#72ADDB';
export const personnelDetailsColour = '#EFEFC2';
export const minMaxColour = '#727272';
export const locationColour = '#FF8AD8';

export const StaffSettings: FC = () => {
  const [selectedFilterOption, setSelectedFilterOption] = useState<StaffSettingsFilterOptions>(
    StaffSettingsFilterOptions.ALL
  );
  const groups: TableColumnGroup[] = useStaffColumns();

  const { settings, editSettings } = useEditSettingsService(state => ({
    settings: state.workingStaffSettings,
    editSettings: state.setWorkingStaffSettings
  }));

  const [employeeIdsToUpdate, setEmployeeIdsToUpdate] = useState<string[]>([]);
  const [employeeIdsToDelete, setEmployeeIdsToDelete] = useState<string[]>([]);

  const onEmployeesUpdate = (id: string) => (propertyName: string) => (newValue: any) => {
    const employeeToUpdate = settings.find(s => s.employee.identityId === id);

    if (!employeeToUpdate) {
      throw Error('Could not find employee');
    }

    (employeeToUpdate.settings as any)[propertyName] = newValue;

    const employeeId = employeeToUpdate.employee.identityId;
    if (!employeeIdsToUpdate.includes(employeeId)) {
      const newIds = [...employeeIdsToUpdate];
      newIds.push(employeeId);
      setEmployeeIdsToUpdate(newIds);
    }

    editSettings(settings);
  };

  const onEmployeeDelete = (id: string) => (del: boolean) => {
    const employeeToDelete = settings.find(s => s.employee.identityId === id);

    if (!employeeToDelete) {
      throw Error('Could not find employee');
    }

    employeeToDelete.settings.toBeDeleted = del;

    const employeeId = employeeToDelete.employee.identityId;
    let newIds = [...employeeIdsToDelete];

    if (del) {
      newIds.push(employeeId);
    } else {
      newIds = newIds.filter(id => id !== employeeId);
    }

    setEmployeeIdsToDelete(newIds);

    editSettings(settings);
  };

  const initialCheckedState = useInitialGroups();
  const [checkedState, setCheckedState] = useState(initialCheckedState);

  return (
    <>
      <StaffSettingsFilterHeader
        filterOption={selectedFilterOption}
        setFilterOption={setSelectedFilterOption}
        employeeIdsToUpdate={employeeIdsToUpdate}
        employeeIdsToDelete={employeeIdsToDelete}
      />
      <StaffSettingsAddCasual />
      <StaffSettingsGroupsHeader checkedState={checkedState} setCheckedState={setCheckedState} />
      <StaffTable
        filterOption={selectedFilterOption}
        groups={groups}
        showIndexColumn={true}
        onChangeSetting={onEmployeesUpdate}
        onSetDelete={onEmployeeDelete}
        checkedState={checkedState}
      />
    </>
  );
};
