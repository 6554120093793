import { useCallback } from 'react';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { useTasks } from '../tasks/useTasks';
import { applyShiftsToSummary } from './rosterSummaryHelper';
import { useDaysInCurrentMonth } from '../settings/useDates';
import { useSummary } from '../summary/useSummaryService';
import { useLocalSettings } from '../settings/LocalSettingsProvider';

export const useBuildRosterSummary = () => {
  const days = useDaysInCurrentMonth();
  const { allEmployeesSummary: baseSummary } = useSummary(days[0], days[days.length - 1]);

  const date = useLocalSettings(s => s.selectedDate);

  const tasks = useTasks();
  const includeBreaks = useSystemSettings(s => s.settings.includeBreaksInTotals);

  return useCallback(applyShiftsToSummary(baseSummary, new Date(date), tasks, includeBreaks), [
    baseSummary,
    date,
    tasks,
    includeBreaks
  ]);
};
