import { ReadEmployeeSkills } from '@/services/gql/graphql.generated';
import { TaskType } from '@/services/tasks/task.types';
import { useTasks } from '@/services/tasks/useTasks';
import { useCallback } from 'react';
import { WorkingShiftSubsection } from '../daily.types';
import { WorkingShift } from "@/pages/daily/daily.types";

export const useTaskMatch = () => {
  const tasksList = useTasks();
  return useCallback(
    (shift: WorkingShift) =>
      (employeeSkills: ReadEmployeeSkills[]): TaskType[] => {
        const tasks = shift.tasks;

        const taskMatch: TaskType[] = [];
        tasks.forEach((sub: WorkingShiftSubsection) => {
          let canDoTask = true;
          const task = tasksList.find((t: TaskType) => sub.typeContext && t.id === sub.typeContext);

          if (task) {
            task.skills.forEach(skill => {
              if (!employeeSkills.find(s => s.skillId === skill)) {
                canDoTask = false;
              }
            });

            if (canDoTask) {
              taskMatch.push(task);
            }
          }
        });

        return taskMatch;
      },
    [tasksList]
  );
};
