import Toggle from '@/components/Toggle';
import { FC } from 'react';
import { useNotifyVacant } from './useNotifyVacant';
import { strings } from '@/services/translation/strings';

interface Props {
  disabled?: boolean;
}

export const NotifyVacantToggle: FC<Props> = ({}) => {
  const { notifyVacant, setNotifyVacant } = useNotifyVacant();

  return (
    <div className="font-semibold uppercase items-center space-y-1 flex flex-col text-xs">
      <span>{strings.daily.roster.notifyVacant}</span>
      <Toggle value={notifyVacant} onChange={async newValue => await setNotifyVacant(newValue)}></Toggle>
    </div>
  );
};
