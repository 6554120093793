import Divider from '@/components/Divider';
import { Button } from '@/components/button';
import { DropdownAction } from '@/components/menu/Action';
import { Dropdown, DropdownOrigin } from '@/components/menu/Dropdown';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { FaStar } from 'react-icons/fa';
import { FiBookOpen, FiDelete, FiEdit } from 'react-icons/fi';
import { IoAdd } from 'react-icons/io5';
import { TemplateLimitModal } from './TemplateLimitModal';
import { TemplateShopOption, useSelectedTemplate, useTemplateService } from './TemplateService';
import { usePublishedTemplateLibraryOptions } from '@/services/rosters/useTemplateLibraryOptions';

interface Props {
  onAdd: () => void;
  onEdit: (option: TemplateShopOption) => void;
  onDelete: (option: TemplateShopOption) => void;
  onImport: () => void;
}

export const TemplateMenuOptions: FC<Props> = ({ onAdd, onDelete, onEdit, onImport }) => {
  const templateListMax = useSystemSettings(state => state.settings.maxCustomTemplates);

  const selectedTemplate = useSelectedTemplate();
  const customTemplateList = useTemplateService(state => state.customTemplateOptions);
  const setSelectedTemplateId = useTemplateService(state => state.setSelectedTemplateId);

  const onTemplateSelect = (template: any) => {
    setSelectedTemplateId(template.id);
  };

  const selectedCustomTemplate = customTemplateList.find(c => c.id === selectedTemplate?.id);
  const selectedCustomTemplateName = selectedCustomTemplate?.name;

  const libraryTemplates = usePublishedTemplateLibraryOptions();

  const limitReached = customTemplateList?.length >= templateListMax;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openLimitModal = () => setIsModalOpen(true);

  return (
    <>
      <TemplateLimitModal onClose={() => setIsModalOpen(false)} open={isModalOpen} />
      <Dropdown
        origin={DropdownOrigin.TOP_RIGHT}
        button={
          <Button size="sm" className="px-2 w-96 relative" variant={selectedCustomTemplateName ? 'active' : 'default'}>
            <div className="flex items-center justify-between">
              <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                <FaStar />
              </div>
              <span className="whitespace-nowrap">
                {selectedCustomTemplateName || strings.daily.templates.myTemplates.label}
              </span>
              <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                <BsFillCaretDownFill className="text-grey-100" />
              </div>
            </div>
          </Button>
        }
        className="max-h-72 overflow-y-auto w-96"
      >
        <DropdownAction
          onClick={limitReached ? openLimitModal : onAdd}
          className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light"
        >
          <IoAdd />
          <span>{strings.daily.templates.myTemplates.createNewTemplate}</span>
        </DropdownAction>
        {libraryTemplates.length > 0 && (
          <DropdownAction
            onClick={limitReached ? openLimitModal : onImport}
            className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light"
          >
            <FiBookOpen />
            <span>{strings.daily.templates.library.importModal.title}</span>
          </DropdownAction>
        )}
        <Divider thickness={2} />
        {customTemplateList?.length === 0 ? (
          <DropdownAction className="text-sm lg:text-sm text-primary font-base lg:font-light">
            <span>{strings.daily.templates.myTemplates.noTemplates}</span>
          </DropdownAction>
        ) : (
          <>
            {customTemplateList?.map(template => (
              <DropdownAction
                key={template.id}
                onClick={() => onTemplateSelect(template)}
                className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light justify-between"
              >
                <span>{template.name}</span>
                <div className="flex gap-2">
                  <Button
                    id={template.id}
                    size="sm"
                    className="px-2 gap-10  bg-white hover:bg-primary text-primary hover:text-white "
                    onClick={event => {
                      event.preventDefault();
                      onEdit(template);
                    }}
                    variant={selectedTemplate ? 'active' : 'default'}
                  >
                    <FiEdit />
                  </Button>
                  <Button
                    id={template.id}
                    size="sm"
                    className="px-2 gap-10 bg-white hover:bg-secondary text-secondary hover:text-white"
                    onClick={event => {
                      event.preventDefault();
                      onDelete(template);
                    }}
                    variant={'destructive'}
                  >
                    <FiDelete />
                  </Button>
                </div>
              </DropdownAction>
            ))}
          </>
        )}
      </Dropdown>
    </>
  );
};
