import { useRole } from '@/services/authentication/hooks/user';
import { RoleType } from '@/services/authentication/roles';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  roles?: RoleType[];
}

export const RoleRestricted: FC<Props> = ({ children, roles }) => {
  const role = useRole();

  const hasPermission = !roles?.length || (roles?.length && roles.includes(role));

  if (hasPermission) {
    return children;
  } else {
    return <div>Not allowed</div>;
  }
};
