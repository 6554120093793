import { ReadRoster } from "@/services/gql/graphql.generated";
import { useNextDayRoster, usePreviousDayRoster } from "./providers/RosterOptionsService";

const findShiftInRoster = (employeeId?: string, roster?: ReadRoster) => {
  if (!employeeId || !roster || !roster.stations || !roster.stations[0]) {
    return undefined;
  }

  const shifts = roster.stations[0].positions;

  return shifts?.find(s => s.employee?.identityId === employeeId);
}

export const useNextDayShift = (employeeId?: string) => {
  const roster = useNextDayRoster() as ReadRoster;

  return findShiftInRoster(employeeId, roster);
}

export const usePreviousDayShift = (employeeId?: string) => {
  const roster = usePreviousDayRoster() as ReadRoster;

  return findShiftInRoster(employeeId, roster);
}