import { useMemo } from 'react';
import { getMePreferences } from '../staff/preferences';
import { useTasks } from '../tasks/useTasks';
import {
  GanttGridLineSpacing,
  GanttGridLinesThickness,
  OpenClosingTimeOptions,
  PersonalizationPreferences,
  StaffPickerPrefernces,
  StaffPreferences
} from './systemSettings.types';

export const DEFAULT_PERSONALIZATION_VALUES: PersonalizationPreferences = {
  ganttGridLines: GanttGridLineSpacing.none,
  ganttGridLinesThickness: GanttGridLinesThickness.extraLarge,
  openClosingTimes: OpenClosingTimeOptions.all
};

export const DEFAULT_STAFF_PICKER_VALUES: StaffPickerPrefernces = {
  status: true,
  timeAvailable: true,
  allocation: true,
  role: true,
  holidays: true,
  attendances: true,
  workingHours: true,
  tasks: true,
  taskNames: true
};

export const useGetDefaultPreferences = () => {
  const prefs = getMePreferences();

  const tasks = useTasks();
  const defaultTaskDisplay = useMemo(() => {
    const taskDisplay: { [key: string]: boolean } = {};

    if (prefs && prefs.taskDisplay) {
      tasks.forEach(task => {
        taskDisplay[task.id] = prefs.taskDisplay[task.id] === undefined ? true : prefs.taskDisplay[task.id];
      });
    }

    return taskDisplay;
  }, [prefs, tasks]);

  return {
    staffPicker: prefs ? prefs.staffPicker : DEFAULT_STAFF_PICKER_VALUES,
    taskDisplay: prefs ? prefs.taskDisplay : defaultTaskDisplay,
    personalization: prefs ? prefs.personalization : DEFAULT_PERSONALIZATION_VALUES,
    monthlySortOrder: prefs ? prefs.monthlySortOrder : []
  } as StaffPreferences;
};
