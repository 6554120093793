import { Button } from '@/components/button';
import { strings } from '@/services/translation/strings';
import { FunctionComponent, useState } from 'react';
import MonthlySortModal from './MonthlySortModal';

const MonthlySortButton: FunctionComponent = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onOpen = () => {
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <MonthlySortModal open={modalOpen} onClose={onClose} />
      <Button onClick={onOpen}>{strings.common.sort}</Button>
    </>
  );
};

export default MonthlySortButton;
