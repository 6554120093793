import { useMemo } from 'react';
import { ReadEmployee, useAllUserAccountsQuery } from '../gql/graphql.generated';
import { RoleType } from '../authentication/roles';
import { sortSimpleObjectArray } from '@/helpers/helper';

export interface UserAccountsModel {
  restaurantManagers: ReadEmployee[];
  supervisors: ReadEmployee[];
  allUserAccounts: ReadEmployee[];
}

export const useUserAccounts = () => {
  const [{ data }] = useAllUserAccountsQuery();

  const allUserAccounts = data?.employees;

  if (!allUserAccounts) {
    throw Error('Could not get full user accounts list');
  }

  return useMemo(() => {
    const restaurantManagers = allUserAccounts.filter(
      user => user.role === RoleType.Restaurant_Manager && !user.contract?.contractTypeId
    );
    const supervisors = allUserAccounts.filter(user => user.role === RoleType.Supervisor);

    const sortedManagers = sortSimpleObjectArray(restaurantManagers, 'firstName') as ReadEmployee[];

    return {
      managers: sortedManagers,
      supervisors,
      allUserAccounts
    };
  }, [allUserAccounts]);
};
