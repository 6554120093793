import { DailyEditingType } from '@/pages/daily/daily.types';
import { RosterTaskTooltip } from '@/pages/daily/taskListTooltip/RosterTaskTooltip';
import { TemplateTaskTooltip } from '@/pages/daily/taskListTooltip/TemplateTaskTooltip';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';
import { DropZoneItem } from '../gantt/GanttDropZone';
import { useGanttService } from '../gantt/GanttProvider';

interface Props {
  color: string;
  textColor?: string;
  name: string;
  icon?: React.ReactNode;
  item: DropZoneItem;
  readOnly?: boolean;
  className?: string;
  canFavourite?: boolean;
  taskId?: string;
  type?: DailyEditingType;
}

export const DragDropButton: FC<Props> = ({
  color,
  textColor = '#000000',
  name,
  item,
  icon,
  readOnly,
  className,
  canFavourite,
  taskId,
  type
}) => {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: item.type,
      item: item,
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    }),
    []
  );

  const { setCurrentlyDragging } = useGanttService(state => ({ setCurrentlyDragging: state.setCurrentlyDragging }));
  useEffect(() => {
    if (!isDragging) {
      setCurrentlyDragging(undefined);
    } else {
      setCurrentlyDragging(item.context ?? item.type);
    }
  }, [isDragging, setCurrentlyDragging, item.type, item.context]);

  const favouriteTaskIds = useLocalSettings(state => state.favouriteTaskIds);
  const toggleFavouriteTaskId = useLocalSettings(state => state.toggleFavouriteTaskId);

  const onFavourite = useCallback(() => {
    if (!item.context) {
      throw Error('Task drop items should have a context set');
    }

    toggleFavouriteTaskId(item.context);
  }, [item, toggleFavouriteTaskId]);

  const isFavourite = useMemo(() => {
    return favouriteTaskIds.includes(item.context?.toString() || '');
  }, [item, favouriteTaskIds]);

  return (
    <div
      ref={!readOnly ? dragRef : undefined}
      style={{ background: color, color: textColor }}
      className={twMerge(
        'h-[20px] px-2 py-1 rounded-lg text-black flex justify-between items-center border shadow text-sm font-semibold select-none cursor-grab',
        className
      )}
    >
      <div className={twMerge('flex items-center gap-2 flex-1 overflow-hidden', !canFavourite && 'justify-center')}>
        {icon && <div className="text-lg">{icon}</div>}
        <p className="truncate">{name}</p>
      </div>
      {canFavourite && <button onClick={onFavourite}>{isFavourite ? <AiFillStar /> : <AiOutlineStar />}</button>}
      {taskId && type === DailyEditingType.ROSTER ? (
        <RosterTaskTooltip color={color} taskId={taskId} />
      ) : (
        <TemplateTaskTooltip color={color} taskId={taskId} />
      )}
    </div>
  );
};
