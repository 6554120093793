import { Button } from '@/components/button';
import { strings } from '@/services/translation/strings';
import {pdf} from '@react-pdf/renderer';
import { DocumentProps } from '@react-pdf/types';
import {FC, ReactElement, useCallback} from 'react';
import {saveAs} from "file-saver";

interface Props {
  createDocument: () => ReactElement<DocumentProps>;
  name: string;
  buttonName?: string;
}

const DownloadReportButton: FC<Props> = ({ createDocument, name, buttonName = strings.common.print }) => {
  const onPrint = useCallback(async () => {
    const blob = await pdf(createDocument()).toBlob();
    saveAs(blob, name);
  }, [createDocument, name]);

  return (
    <Button onClick={onPrint} variant={'primary'}>
      {buttonName}
    </Button>
  );
};

export default DownloadReportButton;
