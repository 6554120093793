import { CSSProperties, FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  title: string;
  additionalTitle?: string;
  additionalTitleStyle?: CSSProperties;
}

export const SettingsSection: FC<Props> = ({ title, additionalTitle, additionalTitleStyle, children }) => {
  return (
    <div>
      <div className="px-5 py-2 bg-background-mid text-base w-full border-b flex gap-1">
        {title}
        {additionalTitle && (
          <span>
            - <span style={additionalTitleStyle}>{additionalTitle}</span>
          </span>
        )}
      </div>
      {children}
    </div>
  );
};

export const SettingsSubSection: FC<Props> = ({ title, children }) => {
  return (
    <div>
      <div className="px-5 py-2 font-bold text-base w-full border-b flex gap-1">{title}</div>
      {children}
    </div>
  );
};
