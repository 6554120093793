import { SummaryDay } from '@/services/summary/summary.types';
import { useMemo } from 'react';
import { LeaveType } from '../leaveManagement/leave.types';
import { useLeaveTypes } from '../leaveManagement/LeaveTypesProvider';

export interface AdvancedLeaveData extends LeaveType {
  count: number;
}

export interface AdvancedLeaveDataForEmployee {
  advancedLeaveData: AdvancedLeaveData[];
  advancedLeaveTotal: number;
}

export const useAdvancedLeaveDataForEmployee = (
  employeeSchedule: SummaryDay[],
  leaveCols?: string[]
): AdvancedLeaveDataForEmployee => {
  const leaveTypes = useLeaveTypes();

  return useMemo(() => {
    const advancedLeaveData: AdvancedLeaveData[] = [];
    let advancedLeaveTotal = 0;

    leaveTypes
      .filter(type => (leaveCols && leaveCols?.includes(type.id)) || !leaveCols)
      .map(type => {
        const count = employeeSchedule?.filter(item => item.leaveType === type.id).length;
        advancedLeaveTotal += count;

        advancedLeaveData.push({
          ...type,
          count
        });
      });

    return {
      advancedLeaveData,
      advancedLeaveTotal
    };
  }, [leaveTypes, leaveCols, employeeSchedule]);
};
