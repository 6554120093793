import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { WorkingShift } from "@/pages/daily/daily.types";
import { displayName } from '@/services/translation/name';
import { Text, View } from '@react-pdf/renderer';
import { FC, useContext } from 'react';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { PdfGanttBar } from './PdfGanttBar';
import { ROSTER_NAME_WIDTH, ROSTER_TOTAL_CELL_WIDTH } from './PdfRosterDocument';
import { PdfRosterContext } from './PdfRosterProvider';
import {PrintViewTypes} from "@/pages/pdf/roster/usePrintOptions";

export const PdfRosterRow: FC<{
  shift: WorkingShift;
}> = ({ shift }) => {
  const { getContractName, employees, summary, workingRoster, viewType } = useContext(PdfRosterContext);

  if (!workingRoster) {
    throw Error('Working roster should be defined');
  }

  const employee = employees.find(e => e.identityId === shift.assignedEmployeeId);
  const contractName = shift.assignedContractId ? getContractName(shift.assignedContractId) : undefined;

  const summaryData = summary.find(s => s.id === shift.assignedEmployeeId);

  const scheduleDay = summaryData?.schedule.find(s => s.date === dateToApiFormat(workingRoster.date));

  const ghostShifts = scheduleDay?.ghostShifts || [];

  const stats = {
    maxPeriodTime: summaryData?.settings.maxMonthlyHours || 0,
    minPeriodTime: summaryData?.settings.minMonthlyHours || 0,
    periodTotal: summaryData?.compliance.actualTime || 0,
    rosterTotal: scheduleDay?.basicTime || 0,
    actualTotal: scheduleDay?.actualTime || 0,
    breakTime: scheduleDay?.breakTime || 0
  }

  const maxPeriod = stats?.maxPeriodTime;
  const minPeriod = stats?.minPeriodTime;

  const total = stats?.periodTotal || 0;

  let totalColor: string | undefined = undefined;
  if (minPeriod && total < minPeriod) {
    totalColor = '#0070D1';
  } else if (maxPeriod && total > maxPeriod) {
    totalColor = '#D53D6B';
  }

  return (
    <View style={styles.tableRow}>
      <PdfTableCell height={25} width={ROSTER_NAME_WIDTH}>
        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{employee ? displayName(employee) : '-'}</Text>
          {contractName && <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{contractName}</Text>}
        </View>
      </PdfTableCell>
      {viewType === PrintViewTypes.SCREEN && <>
      <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Text style={styles.tableCell}>{stats ? secondsToTimeString(stats.maxPeriodTime) : '--:--'}</Text>
          <Text style={styles.tableCell}>{stats ? secondsToTimeString(stats.minPeriodTime) : '--:--'}</Text>
        </View>
      </PdfTableCell>
      <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
        <Text style={{ ...styles.tableCell, color: totalColor }}>
          {stats ? secondsToTimeString(stats.periodTotal) : '--:--'}
        </Text>
      </PdfTableCell>
      <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
        <Text style={styles.tableCell}>{stats ? secondsToTimeString(stats.rosterTotal) : '--:--'}</Text>
      </PdfTableCell>
      <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
        <Text style={styles.tableCell}>{stats ? secondsToTimeString(stats.actualTotal) : '--:--'}</Text>
      </PdfTableCell>
      <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
        <Text style={styles.tableCell}>{stats ? secondsToTimeString(stats.breakTime) : '--:--'}</Text>
      </PdfTableCell>
      </>}
      <PdfGanttBar shift={shift} ghostShifts={ghostShifts} />
    </View>
  );
};
