import { StaffWithSettings } from '@/services/settings/systemSettings.types';

export const useGroupStaffSkillSettingsByLocation = (settings: StaffWithSettings[]) => {
  const groupedByLocation = settings.reduce((acc, obj) => {
    const key = obj.employee.homeLocationId ?? '';
    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(obj);

    return acc;
  }, {} as Record<string, StaffWithSettings[]>);

  return groupedByLocation;
};
