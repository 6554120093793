import { dateToApiFormat } from '@/helpers/dateHelper';
import { WorkingShift } from '@/pages/daily/daily.types';
import { useSortEmployees } from '@/pages/daily/employeeSelector/useSortEmployees';
import { useOtherShopShiftsForCurrentRoster } from '@/pages/summary/useGetDayShiftsOfStaffFromOtherShops';
import { useRosterStatuses } from '@/pages/summary/useRosterStatuses';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { ReadEmployee, ReadEmployeeSkills } from '@/services/gql/graphql.generated';
import { useGetDayOfWeekAndHoliday } from '@/services/holiday/usePublicHolidays';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { displayName } from '@/services/translation/name';
import { useCallback, useMemo } from 'react';
import { AllocationStatus, EmployeeForShiftItem, EmployeeStatus } from '../../employeeSelector/employeeForShift.types';
import { useTaskMatch } from '../../employeeSelector/useTaskMatch';
import { useRosterAllEmployeesSummary, useWorkingRoster } from '../providers/WorkingRosterService';
import { getAvailableSlots, getSelectedAvailableSlot, getTimeAvailable, RosterGeneralShift } from '../rosterHelper';
import { useAllEmployeeOptions } from './useAllEmployeeOptions';

export const displayNameWithShiftCount = (
  name: string,
  currentShift: WorkingShift,
  shiftLimit: number,
  assignedShifts?: WorkingShift[]
) => {
  if (!assignedShifts || assignedShifts.length === 0 || shiftLimit === assignedShifts.length) {
    return name;
  }

  let shiftIndex = assignedShifts.findIndex(s => s.id === currentShift.id);
  if (shiftIndex < 0) {
    shiftIndex = assignedShifts.length;
  }

  if (shiftIndex === 0) {
    return name;
  } else {
    return `${name} +${shiftIndex}`;
  }
};

export const getStatus = (shift: WorkingShift, availableSlot?: AvailableShiftSlot): EmployeeStatus => {
  if (
    !availableSlot ||
    shift?.start === undefined ||
    shift?.end === undefined ||
    availableSlot.start === availableSlot.end
  ) {
    return EmployeeStatus.NOT_AVAILABLE;
  }

  if (availableSlot.start <= shift.start && availableSlot.end >= shift.end) {
    return EmployeeStatus.AVAILABLE;
  }

  let partiallyAvailable = true;
  if (availableSlot.end < shift.end && !availableSlot.partiallyEnforcedEnd) {
    partiallyAvailable = false;
  }

  if (availableSlot.start > shift.start && !availableSlot.partiallyEnforcedStart) {
    partiallyAvailable = false;
  }

  if (partiallyAvailable) {
    return EmployeeStatus.PARTLY_AVAILABLE;
  }

  return EmployeeStatus.NOT_AVAILABLE;
};

export interface AvailableShiftSlot {
  start: number;
  end: number;
  partiallyEnforcedStart?: boolean;
  partiallyEnforcedEnd?: boolean;
}
export const useBuildEmployeesForShift = () => {
  const summary = useRosterAllEmployeesSummary();
  const { workingRoster } = useWorkingRoster();

  const { selectedDate: selectedDateString } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));
  const selectedDate = useMemo(() => new Date(selectedDateString), [selectedDateString]);

  const selectedLocationId = useSelectedLocation().id;
  const rosterStatuses = useRosterStatuses(selectedDate, selectedLocationId, summary);
  const dayShiftsOfStaffFromOtherShops = useOtherShopShiftsForCurrentRoster();

  const reassignment = useSystemSettings(state => state.settings.reassignment);

  const isHomeStaffOrRosterableStaffFromOtherShops = useCallback(
    (employee: ReadEmployee) => {
      // the staff member at the home location is always rosterable.
      if (employee.homeLocationId === selectedLocationId) {
        return true;
      }

      // the staff member from other shop, who is already assigned to a shift on the day, is not rosterable.
      if (
        dayShiftsOfStaffFromOtherShops?.find(
          x =>
            x.identityId === employee.identityId &&
            x.shifts!.filter(s => s.status === 'Published' || s.status === 'Republished').length > 0
        )
      ) {
        return false;
      }

      // the staff member from other shop is rosterable in the on-request model even if a roster of the shop hasn't been published.
      if (reassignment.model === 'on-request') {
        return true;
      }

      // staff from other shop are rosterable if their home rosters are published
      return (
        rosterStatuses?.find(s => {
          const rosterDate = dateToApiFormat(new Date(s.rosterDate));
          return (
            s.locationId === employee.homeLocationId &&
            rosterDate === selectedDateString &&
            (s.status === 'Published' || s.status === 'Republished')
          );
        }) !== undefined
      );
    },
    [rosterStatuses, dayShiftsOfStaffFromOtherShops, selectedLocationId, reassignment, selectedDateString]
  );

  const { awayEmployees, employeesSkillsList } = useAllEmployeeOptions();
  if (!employeesSkillsList) {
    throw Error('Could not get skills data.');
  }

  const { maxNumber: maxShifts, minSecondsBetween: secondsBetween } = useSystemSettings(
    state => state.settings.splitShifts
  );

  const taskMatch = useTaskMatch();

  const getDayOfWeekAndHoliday = useGetDayOfWeekAndHoliday(selectedDate);

  const sortEmployees = useSortEmployees();

  return useCallback(
    (shift: WorkingShift, reassignedEmployees: string[] = []) => {
      const getTaskMatch = taskMatch(shift);

      let employees: EmployeeForShiftItem[] = [];

      summary.forEach(employee => {
        const schedule = employee.schedule.find(s => s.date === selectedDateString);
        const skills = (employeesSkillsList.find(e => e.employeeId === employee.id)?.skills ||
          []) as ReadEmployeeSkills[];

        /*
         * Weird bit:
         *
         * We need to find the when an employee can work, by looking at their shifts and finding an
         * available gap.
         *
         * This requires us to look at both their shifts from the working roster (the one that's being
         * edited currently) and also their ghost shifts, which are shifts from the previous or next day
         * which hang over into this day.
         *
         * These are two entirely different types, so we have to cast them to the RosterGeneralShift
         * type, which has the relevant common fields.
         */
        const workingRosterShifts =
          workingRoster?.shifts.filter(s => s.assignedEmployeeId === employee.id) || ([] as RosterGeneralShift[]);
        const ghostShifts = schedule?.ghostShifts || ([] as RosterGeneralShift[]);

        const shifts: RosterGeneralShift[] = [...workingRosterShifts, ...ghostShifts];

        let availableSlots: AvailableShiftSlot[] = [];
        
        schedule?.availableTimes?.forEach(availableTime => {
          if (!shifts) {
            availableSlots.push(availableTime);
          } else {
            availableSlots = [...availableSlots, ...getAvailableSlots(shifts, availableTime.start, availableTime.end, secondsBetween)]
          }
        });
        
        const selectedAvailableSlot = getSelectedAvailableSlot(availableSlots, shift?.start, shift?.end);

        const assignedShifts = workingRoster?.shifts.filter(shift => shift.assignedEmployeeId === employee.id) || [];
        const employeeReassigned = reassignedEmployees.find(id => id === employee.id) !== undefined;

        let status: EmployeeStatus = EmployeeStatus.NOT_AVAILABLE;
        if (!employeeReassigned) {
          status =
            shift.assignedEmployeeId === employee.id
              ? EmployeeStatus.AVAILABLE
              : getStatus(shift, selectedAvailableSlot);
        }

        let allocationStatus = AllocationStatus.NOT_ALLOCATED;
        if (assignedShifts.length >= maxShifts || employeeReassigned) {
          allocationStatus = AllocationStatus.FULLY_ALLOCATED;
        } else if (assignedShifts.length > 0) {
          allocationStatus = AllocationStatus.PARTLY_ALLOCATED;
        }

        let timeAvailable: string[] = [getTimeAvailable({ start: 0, end: 0 })];

        if (schedule?.availableTimes && schedule.availableTimes.length > 0) {
          timeAvailable = schedule.availableTimes.map(t => getTimeAvailable(t));
        }

        const dropdownItem: EmployeeForShiftItem = {
          id: employee.id,
          name: displayNameWithShiftCount(employee.name, shift, maxShifts, assignedShifts),
          contractId: employee.contractId,
          status,
          timeAvailable,
          role: employee.role,
          leaveType: schedule?.leaveType,
          allocated: allocationStatus,
          assignable: true,
          employeeId: employee.employeeId,
          details: employee.details,
          skills: skills,
          taskMatch: getTaskMatch(skills),
          employeeSummary: employee
        };

        // If unavailable or allocated already, can't be reassigned.
        if (
          dropdownItem.status === EmployeeStatus.NOT_AVAILABLE ||
          dropdownItem.allocated === AllocationStatus.FULLY_ALLOCATED
        ) {
          dropdownItem.assignable = false;
        }

        // If the availability state is a fixed shift, we need to override all availability information.
        if (dropdownItem.leaveType === FixedLeaveTypeId.ROSTER) {
          dropdownItem.assignable = false;
          dropdownItem.status = EmployeeStatus.NOT_AVAILABLE;
          dropdownItem.timeAvailable = ['00:00 ~ 00:00'];
        }

        /*
       NOTE: THIS MUST BE AT THE END OF THE CHECKS!!!
       */
        // Exception- if a staff member is already assigned to this shift, they need to be assignable.
        // Attempting to assign someone twice causes an un-assign, so we need this to undo cases where someone's accidentally
        // been assigned when they're unavailable.
        if (shift.assignedEmployeeId === dropdownItem.id) {
          dropdownItem.assignable = true;
        }

        employees.push(dropdownItem);
      });

      // filter out staff from other shops based on roster status
      // then filter out staff from other shops based on availability
      employees = employees
        .filter(employee => isHomeStaffOrRosterableStaffFromOtherShops(employee.details!))
        .filter(
          employee =>
            employee.details?.homeLocationId === selectedLocationId || employee.status === EmployeeStatus.AVAILABLE
        );

      // Add items for reassigned staff from other shops
      awayEmployees.forEach(employee => {
        const skills = (employeesSkillsList.find(e => e.employeeId === employee.identityId)?.skills ||
          []) as ReadEmployeeSkills[];

        employees.push({
          id: employee.identityId,
          employeeId: employee.employeeId || '',
          name: displayName(employee),
          contractId: employee.contract?.contractTypeId,
          status: EmployeeStatus.AVAILABLE, //TODO: Must be fully available?
          timeAvailable: [getTimeAvailable({ start: shift.start || 0, end: shift.end || 0 })], //TODO: Does time available match the time slot of the shift?
          role: employee.role,
          leaveType: undefined, //TODO: Is this important for reassigned staff?
          allocated: AllocationStatus.FULLY_ALLOCATED,
          assignable: true,
          skills: skills,
          taskMatch: getTaskMatch(skills),
          details: employee
        });
      });

      return sortEmployees(employees, shift);
    },
    [
      summary,
      workingRoster,
      selectedDate,
      awayEmployees,
      employeesSkillsList,
      taskMatch,
      maxShifts,
      secondsBetween,
      sortEmployees,
      getDayOfWeekAndHoliday,
      selectedDateString,
      selectedLocationId,
      isHomeStaffOrRosterableStaffFromOtherShops
    ]
  );
};

export const useEmployeesForShift = (shift: WorkingShift, reassignedEmployees: string[] = []) => {
  const buildAvailableEmployees = useBuildEmployeesForShift();

  return useMemo(
    () => buildAvailableEmployees(shift, reassignedEmployees),
    [shift, reassignedEmployees, buildAvailableEmployees]
  );
};
