import { FC } from "react";
import { MdEdit } from "react-icons/md";
import { Button } from "../button";

export const EditButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ onClick }) => {
  return (
    <Button type="button" className="bg-transparent border-none p-1 h-auto" onClick={onClick}>
      <MdEdit className="w-4 h-4" />
    </Button>
  );
};