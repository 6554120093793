import { FC, PropsWithChildren } from 'react';
import { useRole } from './hooks/user';
import { RoleType } from './roles';
import { strings } from '../translation/strings';

interface Props extends PropsWithChildren {
  id: string;
  showError?: boolean;
}

interface Restriction {
  id: string;
  permittedRoles: RoleType[];
}

const restrictionList: Restriction[] = [
  {
    id: 'main',
    permittedRoles: [
      RoleType.Manager,
      RoleType.Senior_Manager,
      RoleType.Supervisor,
      RoleType.Admin,
      RoleType.Super_Admin
    ]
  }
];

export const Restricted: FC<Props> = ({ children, showError, id }) => {
  const role = useRole();

  const restriction = restrictionList.find(res => res.id === id);

  if (!restriction) {
    throw Error('Could not find restriction in list');
  }

  const permittedRoles = restriction.permittedRoles;

  if (permittedRoles.includes(role)) {
    return children;
  }

  if (showError) {
    throw Error(strings.common.notPermitted);
  } else {
    return <></>;
  }
};
