import { EMPLOYEE_INFO_WIDTH } from '@/pages/summary/constants';
import { FC } from 'react';
import { ShiftHourSummary } from '../ShiftHourSummary';
import { useHourSummary } from '../useHourSummary';
import { useWorkingRoster } from './providers/WorkingRosterService';
import { useReassignmentService } from './providers/ReassignmentService';

interface RosterHourSummaryProps {}

export const RosterHourSummary: FC<RosterHourSummaryProps> = ({}) => {
  const { workingRoster, setWorkingRoster } = useWorkingRoster();
  const reassignedShifts = useReassignmentService(s => s.reassignedShifts);

  if (!workingRoster) {
    throw Error('Working template should be defined');
  }

  const onSetValue = (index: number) => (newValue: number) => {
    const newWorkingRoster = { ...workingRoster };

    newWorkingRoster.scratchpad[index] = newValue;

    setWorkingRoster(newWorkingRoster);
  };

  const counts = useHourSummary(
    [...workingRoster.shifts, ...reassignedShifts],
    workingRoster.startTime,
    workingRoster.endTime,
    false
  );

  return (
    <ShiftHourSummary
      id={workingRoster.id}
      counts={counts}
      scratchpad={workingRoster.scratchpad}
      setCount={onSetValue}
      columnWidth={EMPLOYEE_INFO_WIDTH}
      readonly={workingRoster.readOnly}
      startTime={workingRoster.startTime}
      endTime={workingRoster.endTime}
    />
  );
};
