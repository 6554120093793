import { FC, useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '../button';
import { strings } from '@/services/translation/strings';

interface Props {
  suggestions: string[];
  onConfirm: (val: string) => void;
  placeholder?: string;
}

const buildNewSuggestions = (allSuggestions: string[], filterText: string) => {
  return allSuggestions.filter(s => s.toLowerCase().includes(filterText.toLowerCase()));
};

export const AutoSuggestInput: FC<Props> = ({ suggestions, onConfirm, placeholder }) => {
  const [inputText, setInputText] = useState<string>('');

  const [filteredSuggestions, setFilteredSuggestions] = useState<string[] | undefined>(undefined);

  const debouncedBuild = useDebouncedCallback(() => {
    return setFilteredSuggestions(buildNewSuggestions(suggestions, inputText));
  }, 500);

  useEffect(() => {
    if (!inputText) {
      setFilteredSuggestions(undefined);
    } else {
      if (!filteredSuggestions) {
        debouncedBuild();
      } else {
        setFilteredSuggestions(buildNewSuggestions(suggestions, inputText));
      }
    }
  }, [inputText, suggestions, inputText]);

  const onSelectSuggestion = (val: string) => {
    onConfirm(val);
    setInputText('');
  };

  return (
    <div className="relative">
      <Combobox value={inputText} onChange={onSelectSuggestion}>
        <div className="flex items-center gap-5">
          <Combobox.Input
            className="block w-full pb-2 pt-2 px-2 truncate rounded-md font-medium shadow-sm border-gray-300"
            aria-label="Assignee"
            onChange={event => setInputText(event.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onSelectSuggestion(inputText);
              }
            }}
            placeholder={placeholder}
          />
          <Button variant={'primary'} onClick={() => onSelectSuggestion(inputText)}>
            {strings.common.add}
          </Button>
        </div>
        <Combobox.Options className="border empty:invisible rounded-md overflow-auto absolute top-[105%] w-[300px] max-h-[300px] bg-white">
          {filteredSuggestions?.map(suggestion => (
            <Combobox.Option
              key={suggestion}
              value={suggestion}
              className="border-b last:border-b-0 py-1 px-2 truncate"
            >
              {suggestion}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};
