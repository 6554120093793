import { strings } from "@/services/translation/strings";
import { useCallback } from "react";
import { useTemplateLibraryService } from "./TemplateLibraryService";

export const useValidateLibraryTemplateName = () => {
  const templateList = useTemplateLibraryService(state => state.templateOptions);

  return useCallback((inputValue: string) => {
    if (!inputValue.trim()) {
      return strings.daily.templates.myTemplates.nameRequired;
    }

    if (templateList.some(template => template.name === inputValue)) {
      return strings.daily.templates.myTemplates.nameExists;
    }

    return null;
  }, [templateList]);
}