import { getDaysInWeek, getStartDate } from '@/helpers/dateHelper';
import { PdfParams } from "@/pages/pdf/summary/PdfSummaryDay";
import { useSummary } from '@/services/summary/useSummaryService';
import { useWeeklyBudgetForLocation } from '@/services/budget/useBudget';
import { useBudgetStats } from '@/services/budget/useBudgetStats';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useRosterNotes } from "@/services/rosters/useRosterNotes";
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { PDFViewer } from '@react-pdf/renderer';
import { FC } from 'react';
import { PrintViewTypes } from '../../roster/usePrintOptions';
import { WeeklyReportAsPDF } from './PdfWeeklyDocument';

export const WeeklyPrintTestPage: FC = () => {
  const leaveTypes = useLeaveTypes();
  const params: PdfParams = { leaveTypes, viewType: PrintViewTypes.SCREEN };

  const startDay = useSystemSettings(state => state.settings.weekStartDay);
  const { selectedDate } = useLocalSettings(state => ({ selectedDate: state.selectedDate }));

  const startDate = getStartDate(startDay, selectedDate);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  const { homeEmployeesSummary: summary } = useSummary(startDate, endDate);
  const days = getDaysInWeek(startDate);

  const includeBreaks = useSystemSettings(state => state.settings.includeBreaksInTotals);
  const budgetData = useWeeklyBudgetForLocation(days);
  const budgetStats = useBudgetStats(days);

  const selectedLocationId = useSelectedLocation().id;
  const {notes: rosterNotes} = useRosterNotes(startDate, endDate, selectedLocationId);

  return (
    <div>
      <PDFViewer className="fixed h-screen w-screen top-0">
        <WeeklyReportAsPDF
          days={days}
          summary={summary}
          notes={rosterNotes}
          budgetData={budgetData}
          budgetStats={budgetStats}
          params={params}
        />
      </PDFViewer>
    </div>
  );
};
