import { SeverityType } from '@/components/warning/warningDefinitions';
import { isEmployeeUnderage, secondsSinceMidnightToDate } from '@/helpers/dateHelper';
import { EmployeeForShiftItem } from '../../../employeeSelector/employeeForShift.types';
import { WorkingShift } from "@/pages/daily/daily.types";
import { RosterWarningModel, WarningsProps } from '../warnings.types';

export const getWarningsForUnderageNightShifts = (
  shift: WorkingShift,
  assignedEmployee: EmployeeForShiftItem,
  props: WarningsProps
): RosterWarningModel[] => {
  const { strings } = props;
  const shiftWarnings: RosterWarningModel[] = [];
  const employeeDateOfBirth = assignedEmployee.details?.dateOfBirth;

  if (
    employeeDateOfBirth &&
    isEmployeeUnderage(employeeDateOfBirth) &&
    secondsSinceMidnightToDate(shift.end!).getHours() > 22
  ) {
    shiftWarnings.push({
      severity: SeverityType.CRITICAL,
      name: strings.staffCannotBeAssigned.message,
      details: [strings.staffCannotBeAssigned.underAgeNightShift],
      shiftId: shift.id
    });
  }

  return shiftWarnings;
};
