import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import {
  WorkingBudgetLocation
} from '../../services/budget/budget.types';
import { useBudget } from '@/services/budget/useBudget';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';

export interface BudgetStore {
  locations: WorkingBudgetLocation[];
  refreshLocations: () => void;
  setTarget: (
    locationId: number,
    targetName: 'financialTarget' | 'locationForecast'
  ) => (dayOfMonth: number) => (newTarget: number) => void;
}

export const BudgetContext = createContext<StoreApi<BudgetStore> | null>(null);

export const BudgetProvider: FC<PropsWithChildren> = ({ children }) => {
  const selectedLocationIds = useLocalSettings(state => state.viewableLocationIds);
  const daysInMonth = useDaysInCurrentMonth();
  const { locations, refetch } = useBudget(selectedLocationIds, daysInMonth);

  const registerForConfirmation = useConfirm(state => state.registerForConfirmation);

  const store = useMemo(() => {
    return createStore<BudgetStore>()((set, get) => ({
      locations,
      refreshLocations: () => {
        refetch();
      },
      setTarget: (locationId, targetName) => dayOfMonth => newTarget => {
        const newLocations = [...get().locations];
        const locationToEdit = newLocations.find(loc => loc.id === locationId);

        if (!locationToEdit) {
          throw Error('Unable to find location to update');
        }

        const dayToEdit = locationToEdit.days.find(day => new Date(day.date).getDate() === dayOfMonth);

        if (!dayToEdit) {
          throw Error('Attempting to edit incorrect date');
        }

        dayToEdit[targetName] = newTarget;

        set({ locations: newLocations });
        registerForConfirmation(strings.budget.unsavedChanges);
      }
    }));
  }, [locations, refetch]);

  return <BudgetContext.Provider value={store}>{children}</BudgetContext.Provider>;
};

type SelectorReturn<S extends (s: BudgetStore) => any> = ReturnType<S>;
export function useBudgetService<S extends (s: BudgetStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(BudgetContext);
  if (!context) {
    throw new Error('useBudget must be used within a BudgetProvider');
  }
  return useStore(context, selector);
}
