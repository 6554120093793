import { Button } from '@/components/button';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { PageHeader } from '@/pages/layout/main/PageHeader';
import { useMyPersonasAttributes } from '@/services/personas/useMyPersonaAttributes';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TemplatePageHeader: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <PageHeader className="w-full justify-between">
      <div className="flex gap-5">
        <Button
          size="sm"
          variant={pathname === '/templates' ? 'active' : 'default'}
          onClick={() => navigate('/templates')}
        >
          {strings.daily.templates.shopTemplates}
        </Button>
        <Button
          size="sm"
          variant={pathname === '/templates/library' ? 'active' : 'default'}
          onClick={() => navigate('/templates/library')}
        >
          {strings.daily.templates.libraryTemplates}
        </Button>
      </div>
    </PageHeader>
  );
};

export const TemplatePage: FC<PropsWithChildren> = ({ children }) => {
  const { templateLibrarian } = useMyPersonasAttributes();

  return (
    <div className="h-full flex flex-col" id="roster-shifts-wrapper">
      {templateLibrarian && <TemplatePageHeader />}
      <Suspense fallback={<LoadingIndicator />}>
        {children}
      </Suspense>
    </div>
  );
};
