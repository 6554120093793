import { FC } from 'react';
import { strings } from '@/services/translation/strings';
import { StaffRequestingShift } from '../../daily.types';
import { secondsToTimeString } from '@/helpers/dateHelper';

interface Props {
  requestingShifts: StaffRequestingShift[];
}

export const StaffRequestingInfo: FC<Props> = ({ requestingShifts }) => {
  return (
    <div className="flex flex-col text-[#323232bf] bg-[#E8E8E8] gap-1 w-48 border border-[#32323240] rounded-lg p-1.5 select-none z-[9999]">
      <div className="flex font-bold text-base px-1 justify-center">{strings.daily.staffAllocation.staffRequest}</div>
      <div className="flex flex-col gap-0.5">
        {requestingShifts.map((shift, index) => {
          return (
            <div
              key={`${shift.start}-${shift.end}-${index}`}
              className="flex justify-between gap-2 text-sm items-center"
            >
              <div className="px-1">{`${secondsToTimeString(shift.start)} ~ ${secondsToTimeString(shift.end)}`}</div>
              <div className="flex items-center content-center justify-center">
                <img
                  className=""
                  src={`/${shift.employee ? 'check-dark-alpha' : 'person-dark-alpha'}.svg`}
                  alt={shift.employee ? 'Staff allocated' : 'Waiting staff allocation'}
                  width="15 lg:20"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
