import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { Location } from '../gql/graphql.generated';
import { useGetLocationHierarchyDetails } from '../hierarchy/useLocationHierarchyDetails';
import { useMyLocations } from '../useLocations';
import { LocationSettings, LocationWithSettings, defaultLocationSettings } from './systemSettings.types';

interface LocationSettingsStore {
  settings: LocationWithSettings[];
  setSettings: (newSettings: LocationWithSettings[]) => void;
  settingsForLocation: (locationId: number) => LocationSettings;
}

export const LocationSettingsContext = createContext<StoreApi<LocationSettingsStore> | null>(null);

export const LocationSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const locationsList = useMyLocations();
  const getHierarchyDetails = useGetLocationHierarchyDetails();

  const store = useMemo(() => {
    const locationsWithSettings: LocationWithSettings[] = locationsList.map(location => {
      let locationSettings: LocationSettings;

      if (!location.properties) {
        locationSettings = { ...defaultLocationSettings };
      } else {
        const properties = JSON.parse(location.properties);
        locationSettings = { ...defaultLocationSettings, ...properties };
      }

      const hierarchyDetails = getHierarchyDetails(location as Location);

      return {
        location,
        settings: locationSettings,
        details: hierarchyDetails
      };
    }) as LocationWithSettings[];

    return createStore<LocationSettingsStore>()((set, get) => ({
      settings: locationsWithSettings,
      setSettings: settings => set({ settings }),
      settingsForLocation: locationId => {
        const LocationSettings = get().settings.find(loc => loc.location.id === locationId);

        if (!LocationSettings) {
          throw Error('Could not get staff settings');
        }

        return LocationSettings.settings;
      }
    }));
  }, [locationsList, getHierarchyDetails]);

  return <LocationSettingsContext.Provider value={store}>{children}</LocationSettingsContext.Provider>;
};

type SelectorReturn<S extends (s: LocationSettingsStore) => any> = ReturnType<S>;
export function useLocationSettings<S extends (s: LocationSettingsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(LocationSettingsContext);
  if (!context) {
    throw new Error('useLocationSettings must be used within a LocationSettingsProvider');
  }
  return useStore(context, selector);
}
