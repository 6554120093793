import { StaffAvailabilityDay } from '@/services/availability/useStaffAvailability';
import { strings } from '@/services/translation/strings';
import { FC, useCallback } from 'react';
import { ReadEmployee } from "@/services/gql/graphql.generated";

interface Props {
  selectedDay: StaffAvailabilityDay;
  employee?: ReadEmployee
}

export const RosteredLocation: FC<Props> = ({ selectedDay, employee }) => {
  const getLocations = useCallback(() => {
    if (!employee || !selectedDay.rosteredShifts) {
      return <div className="pt-1">{strings.settings.manageAvailability.rosteredLocationNotRostered}</div>;
    }
    return <div>{selectedDay.rosteredShifts?.map((shift, index) => {
      if (shift.locationId === employee.homeLocationId?.toString()) {
        return <div key={index} className="bg-[#1BB65C] p-1">{strings.settings.manageAvailability.rosteredLocationHome(shift.locationId, shift.locationName)}</div>
      }
      return <div key={index} className="bg-[#E8AA14] p-1">{strings.settings.manageAvailability.rosteredLocationRemote(shift.locationId, shift.locationName)}</div>;
    })}</div>;
  }, [selectedDay, employee]);

  return (<div className="flex">
    <div className="flex-1 pt-1">{strings.settings.manageAvailability.rosteredLocation}</div>
    <div className="flex-1">
      {getLocations()}
    </div>
  </div>)
};
