import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';

export enum ContractTypeIdMapping {
  ShopManagerStandard = 'ccc9db13-2e5b-40ba-bddd-6c5136f1eac5', // shop manager
  ShopManagerForeignerId = 'f811fb61-e3cf-45e8-8cdc-57ea5e4f8839', // shop manager
  DeputyShopManagerStandardId = '89129a31-984c-405b-a1fe-6a35ef111510', // deputy shop manager
  DeputyShopManagerForeignerId = '9d7c1fc9-fbbf-4f34-bb05-af1f072fc13d', // deputy shop manager
  FullTimeStandardId = 'f4316689-c3ee-4460-bc25-d31d66b16903', // employee
  FullTimeForeignerId = '6e05b124-1103-466b-8169-43ff23ae99f9', // employee
  PartTimeStandardId = 'f4316689-c3ee-4460-bc25-d31d66b16903', // partner, short time
  PartTimeForeignerId = '6e05b124-1103-466b-8169-43ff23ae99f9', // partner, short time
  ReducedHoursStandardId = '833f14a9-369b-4f8f-8115-27be8bbb2b2c',
  ReducedHoursForeignerId = 'f72a9c6f-9580-4848-a093-f6a827a32c9e',
  ZeroHoursStandardId = '723aca84-df2f-4572-8b2c-4ed43fdc773f', // sales staff, part time
  ZeroHoursForeignerId = 'f47bebe5-602d-45ed-8670-d1f13af028d3', // sales staff, part time
  CasualStandardId = '57366ee7-2923-49db-b82b-c618bf6e7018', // casual/temporary staff
  CasualForeignerId = '3641ba47-7ad6-48a4-a8f5-10c188ea7438', // casual/temporary staff
  StudentStandardId = 'aa33e3c6-90e1-4659-95d6-f22c30a365ca',
  StudentForeignerId = 'e01af3d7-d7ca-403e-9da0-2d47a1d2cea5'
}

export const useContractOptions = () => {
  return useMemo(
    () => [
      {
        name: strings.daily.templates.selectRole,
        id: ''
      },
      {
        name: strings.daily.templates.contractTypes.shopManager,
        id: ContractTypeIdMapping.ShopManagerStandard
      },
      {
        name: strings.daily.templates.contractTypes.deputyShopManager,
        id: ContractTypeIdMapping.DeputyShopManagerStandardId
      },
      {
        name: strings.daily.templates.contractTypes.fullTime,
        id: ContractTypeIdMapping.FullTimeStandardId
      },
      {
        name: strings.daily.templates.contractTypes.partTime,
        id: ContractTypeIdMapping.PartTimeStandardId
      },
      {
        name: strings.daily.templates.contractTypes.reducedHours,
        id: ContractTypeIdMapping.ReducedHoursStandardId
      },
      {
        name: strings.daily.templates.contractTypes.zeroHours,
        id: ContractTypeIdMapping.ZeroHoursStandardId
      },
      {
        name: strings.daily.templates.contractTypes.casual,
        id: ContractTypeIdMapping.CasualStandardId
      },
      {
        name: strings.daily.templates.contractTypes.student,
        id: ContractTypeIdMapping.StudentStandardId
      }
    ],
    []
  );
};
