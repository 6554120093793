import { StaffSettingsFilterOptions } from '@/services/settings/useFilterStaffSettings';
import { useFlag } from '@softwareimaging/backstage';
import { FC, Fragment, useState } from 'react';
import { useEditSettingsService } from '../../../services/EditSettingsService';
import { StaffSettingsFilterHeader } from '../../manageStaff/StaffSettingsFilterHeader';
import { TableColumnGroup } from '../../manageStaff/staffTable/StaffTable';
import StaffSkillsExportButton from '../csv/StaffSkillsExportButton';
import StaffSkillsImportButton from '../csv/StaffSkillsImportButton';
import PdfStaffSkillCapabilityDownloadButton from '../print/capability/PdfStaffSkillCapabilityDownloadButton';
import PdfStaffSkillOverviewDownloadButton from '../print/overview/PdfStaffSkillOverviewDownloadButton';
import { useStaffSkillColumns } from '../useStaffSkillColumns';
import { StaffSkillTable } from './StaffSkillsTable';

export const StaffSkillsSettings: FC = () => {
  const [selectedFilterOption, setSelectedFilterOption] = useState<StaffSettingsFilterOptions>(
    StaffSettingsFilterOptions.ALL
  );
  const groups: TableColumnGroup[] = useStaffSkillColumns();

  const { settings, editSettings } = useEditSettingsService(state => ({
    settings: state.workingStaffSettings,
    editSettings: state.setWorkingStaffSettings
  }));

  const [employeeIdsToUpdate, setEmployeeIdsToUpdate] = useState<string[]>([]);

  const onEmployeesUpdate = (id: string) => (propertyName: string) => (newValue: any) => {
    const employeeToUpdate = settings.find(s => s.employee.identityId === id);

    if (!employeeToUpdate) {
      throw Error('Could not find employee');
    }

    (employeeToUpdate.settings as any)[propertyName] = newValue;

    const employeeId = employeeToUpdate.employee.identityId;
    if (!employeeIdsToUpdate.includes(employeeId)) {
      const newIds = [...employeeIdsToUpdate];
      newIds.push(employeeId);
      setEmployeeIdsToUpdate(newIds);
    }

    editSettings(settings);
  };

  const importExportEnabled = useFlag('importExportEnabled');

  return (
    <>
      <StaffSettingsFilterHeader
        filterOption={selectedFilterOption}
        setFilterOption={setSelectedFilterOption}
        employeeIdsToUpdate={employeeIdsToUpdate}
        employeeIdsToDelete={[]}
        additionalControls={[
          importExportEnabled && (
            <Fragment key={'importExport'}>
              <StaffSkillsImportButton />
              <StaffSkillsExportButton />
            </Fragment>
          ),
          <PdfStaffSkillCapabilityDownloadButton filterOption={selectedFilterOption} key={'printBySkill'} />,
          <PdfStaffSkillOverviewDownloadButton filterOption={selectedFilterOption} key={'printByStaffName'} />
        ]}
      />
      <StaffSkillTable
        filterOption={selectedFilterOption}
        groups={groups}
        showIndexColumn={true}
        onChangeSetting={onEmployeesUpdate}
      />
    </>
  );
};
