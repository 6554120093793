export const roundToDecimalPlaces = (number: number, decimalPlaces: number) => {
  const numberAsString = number.toString();
  const splitNumber = numberAsString.split('.');

  if (splitNumber.length === 1) {
    // No decimal part.
    return numberAsString;
  }

  const decimalString = splitNumber[1];

  if (decimalString.length < decimalPlaces) {
    // Decimal is already accurate enough.
    return numberAsString;
  }

  return number.toFixed(decimalPlaces);
} 