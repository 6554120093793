import { LoadingIndicator } from '@/components/LoadingIndicator';
import { Button } from '@/components/button';
import { InfoCell } from '@/components/table/InfoCell';
import { getDaysInWeek, getStartDate, secondsToTimeString } from '@/helpers/dateHelper';
import { PdfParams } from '@/pages/pdf/summary/PdfSummaryDay';
import { useWeeklyBudgetForLocation } from '@/services/budget/useBudget';
import { useBudgetStats } from '@/services/budget/useBudgetStats';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useRosterNotes } from '@/services/rosters/useRosterNotes';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useGetHomeEmployees } from "@/services/staff/useGetHomeEmployees";
import { strings } from '@/services/translation/strings';
import { useApproveAvailability } from '@/services/useApproveAvailability';
import { useFlag } from '@softwareimaging/backstage';
import { format } from 'date-fns';
import { FC, Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { DateSelector } from '../layout/DateSelector';
import { CalendarPeriodType } from '../layout/dateSelector.types';
import { LocationSelector } from '../layout/main/LocationSelector';
import { PageHeader } from '../layout/main/PageHeader';
import DownloadReportButton from '../pdf/DownloadReportButton';
import { PrintViewTypes } from '../pdf/roster/usePrintOptions';
import { WeeklyReportAsPDF } from '../pdf/summary/weekly/PdfWeeklyDocument';
import { getWeeklyShiftsDurationForEmployee } from './SummaryHelper';
import { SummaryTableHeader } from './SummaryTableHeader';
import { WeeklyTotalHeader } from './WeeklyTotalHeader';
import { WeeklyBudget } from './budget/WeeklyBudget';
import { EMPLOYEE_NAME_COLUMN_WIDTH } from './constants';
import { useSummary } from '@/services/summary/useSummaryService';
import {WorkPreferencesTrackingProvider} from "@/services/availability/WorkPreferencesTrackingService";
import {SummaryEmployeeTable} from "@/pages/summary/SummaryEmployeeTable";

export const WeeklyPage: FC = () => {
  const includeBreaks = useSystemSettings(state => state.settings.includeBreaksInTotals);

  const { selectedDate } = useLocalSettings(state => ({ selectedDate: state.selectedDate }));
  const startDay = useSystemSettings(state => state.settings.weekStartDay);

  const startDate = getStartDate(startDay, selectedDate);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  const { homeEmployeesSummary: summary } = useSummary(startDate, endDate);
  const days = getDaysInWeek(startDate);

  const leaveTypes = useLeaveTypes();
  const params: PdfParams = { leaveTypes, viewType: PrintViewTypes.SCREEN };

  // Weird hack- see comment above the hook for why we're doing this.
  useApproveAvailability();

  const selectedLocationId = useSelectedLocation().id;
  const { notes: rosterNotes } = useRosterNotes(startDate, endDate, selectedLocationId);

  const { data: employees } = useGetHomeEmployees(selectedLocationId);
  const employeeList = employees.filter(employee => employee.isRosterable);

  const totalForAllEmployees = useMemo(() => {
    let total = 0;
    summary.forEach(employee => {
      const duration = getWeeklyShiftsDurationForEmployee(employee, selectedDate, includeBreaks);
      total += duration;
    });

    return total;
  }, [summary, employeeList, selectedDate, includeBreaks]);

  const budgetData = useWeeklyBudgetForLocation(days);
  const budgetStats = useBudgetStats(days);
  const debug = useFlag('debug');
  const navigate = useNavigate();

  const createDocument = useCallback(() => {
    return <WeeklyReportAsPDF
      days={days}
      notes={rosterNotes}
      summary={summary}
      budgetData={budgetData}
      budgetStats={budgetStats}
      params={params}
    />;
  }, [includeBreaks, days, rosterNotes, summary, budgetData, budgetStats, params]);

  return (
    <div className="h-full flex flex-col">
      <Suspense fallback={<LoadingIndicator />}>
        <PageHeader className="justify-between">
          <div className="flex gap-5 items-center">
            <LocationSelector />
            <DateSelector showNavigationArrows={true} requestedPeriod={CalendarPeriodType.WEEKS} />
          </div>
          <div className="flex gap-5 items-center">
            <DownloadReportButton
              name={strings.summary.print.weekly.fileName(format(days[0], 'yyyy/MM/dd'))}
              createDocument={createDocument}
            />
            {debug && <Button onClick={() => navigate('/weeklyTest')}>Print Test</Button>}
          </div>
        </PageHeader>
        <SummaryTableHeader days={days} emptyColumnRight width="140px" />
        <WeeklyBudget days={days} />
        <div className="flex flex-row border-b bg-background-mid">
          <div className="flex">
            <div className="border-r" style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }} />
            <div className="flex" style={{ width: 140 }}>
              <div className="flex-[2]">
                <WeeklyTotalHeader />
              </div>
            </div>
            <div className="border-l"></div>
          </div>
        </div>
        <div className="flex flex-row border-b bg-background-mid">
          <div className="flex">
            <div
              className="flex items-center px-2 font-semibold border-r"
              style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
            >
              {strings.common.employee}
            </div>
            <div className="flex" style={{ width: 140 }}>
              <InfoCell className={'flex-1 border-r'}></InfoCell>

              <InfoCell className={'flex-1'}>
                <p>{secondsToTimeString(totalForAllEmployees)}</p>
              </InfoCell>
            </div>
            <div className="border-l"></div>
          </div>
        </div>
        <div id="summary-table" className="flex-1 overflow-y-scroll overflow-x-hidden">
          <div className="w-screen">
            <WorkPreferencesTrackingProvider startDate={days[0]} endDate={days[days.length - 1]}>
              <SummaryEmployeeTable
                days={days}
                employees={summary}
              />
            </WorkPreferencesTrackingProvider>
          </div>
        </div>
      </Suspense>
    </div>
  );
};
