import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { Note } from '../roster/PdfRosterNotes';

interface Props {
  days: Date[];
  notes?: Note[];
  dateWidthOverride?: number;
}

export const PdfDateRange: FC<Props> = ({ days, notes = [], dateWidthOverride }) => {
  const getDayColor = (dayNumber: number) => {
    switch (dayNumber) {
      case 0:
        return '#0070D1';
      case 6:
        return '#D53D6B';
      default:
        return '#000000';
    }
  };

  return (
    <>
      {days.map((day: Date, dayIndex: number) => {
        const note = notes.find(n => n.date.getDay() === day.getDay());

        const noteStyling: Record<string, string | number> = {};
        if (note) {
          noteStyling.backgroundColor = '#D1D5DB';

          if (dateWidthOverride) {
            noteStyling.width = dateWidthOverride;
          }
        }
        return (
          <PdfTableCell key={dayIndex} lastCell={dayIndex === days.length - 1}>
            <View>
              <Text
                style={{
                  ...styles.tableHeaderCell,
                  color: getDayColor(day.getDay()),
                  ...noteStyling
                }}
              >
                {day.getDate()}
              </Text>
              <Text
                style={{
                  ...styles.tableHeaderCell,
                  color: getDayColor(day.getDay())
                }}
              >
                {strings.common.daysOfWeek[day.getDay()]}
              </Text>
            </View>
          </PdfTableCell>
        );
      })}
    </>
  );
};
