import { FC } from 'react';
import { useEditSettingsService } from '../../services/EditSettingsService';
import { onChangeSettings } from '../../settingsHelper';
import { SelectInput, SelectOption } from '@/components/form/SelectInput';
import { strings } from '@/services/translation/strings';

interface Props {
  field: 'dateOfBirth' | 'endProbationDate' | 'hiredDate' | 'leftCompanyDate';
}

enum FieldLevel {
  NONE = 'None',
  VIEW = 'View',
  EDIT = 'Edit'
}

export const EditPersonnelPicker: FC<Props> = ({ field }) => {
  const { settings, editSettings } = useEditSettingsService(state => ({
    settings: state.workingSystemSettings,
    editSettings: state.setWorkingSystemSettings
  }));

  const canEdit = settings.editableDetails[field];
  const canView = settings.viewableDetails[field];

  let level = FieldLevel.NONE;
  if (canEdit) {
    level = FieldLevel.EDIT;
  } else if (canView) {
    level = FieldLevel.VIEW;
  }

  const onChangeSetting = (level: FieldLevel) => {
    let newCanView = false;
    let newCanEdit = false;
    switch (level) {
      case FieldLevel.EDIT:
        newCanEdit = true;
      case FieldLevel.VIEW:
        newCanView = true;
    }

    onChangeSettings(settings, editSettings)(`viewableDetails.${field}`)(newCanView);
    onChangeSettings(settings, editSettings)(`editableDetails.${field}`)(newCanEdit);
  };

  const options: SelectOption[] = [
    { name: strings.settings.system.personnelDetails.level.edit, value: FieldLevel.EDIT },
    { name: strings.settings.system.personnelDetails.level.view, value: FieldLevel.VIEW },
    { name: strings.settings.system.personnelDetails.level.hide, value: FieldLevel.NONE }
  ];

  return <SelectInput options={options} value={level} setValue={onChangeSetting}></SelectInput>;
};
