import { Button } from '@/components/button';
import { ConfirmationModal } from '@/components/modal/ConfirmationModal';
import { useOptimiseRosters } from '@/services/optimiser/useOptimiseRosters';
import { useOptimising } from '@/services/optimiser/useOptimising';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { FC, useState } from 'react';

interface Props {
  onOptimiseComplete: () => void;
  disabled?: boolean;
  haveBeenGenerated?: boolean;
}

export const OptimiserButton: FC<Props> = ({ onOptimiseComplete, disabled, haveBeenGenerated }) => {
  const { optimiseRosters } = useOptimiseRosters();

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const days = useDaysInCurrentMonth();
  const period = `${format(days[0], 'yyyy/MM/dd')} - ${format(days[days.length - 1], 'yyyy/MM/dd')}`;

  const optimising = useOptimising(onOptimiseComplete);

  const onClick = () => {
    if (!haveBeenGenerated) {
      optimiseRosters();
    } else {
      setConfirmModalOpen(true);
    }
  };

  return (
    <div className="flex gap-5 items-center font-semibold">
      <Button
        variant={optimising || disabled ? 'default' : 'secondary'}
        disabled={optimising || disabled}
        onClick={onClick}
        loading={optimising}
      >
        {strings.summary.generate}
      </Button>
      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        message={strings.summary.generateConfirmation(period)}
        onConfirm={() => {
          optimiseRosters();
          setConfirmModalOpen(false);
        }}
      />
    </div>
  );
};
