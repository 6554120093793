import {Text, View} from '@react-pdf/renderer';
import { FC } from 'react';
import {SkillType} from "@/services/tasks/skillSettings.types";

interface Props {
  skillIds?: string[];
  skills: SkillType[]
}

export const PdfTaskSkills: FC<Props> = ({ skillIds, skills }) => {
  return (<View style={{ padding: '2px 0px' }}>
    {skillIds?.map((skillId, index) => {
      const skillObj = skills.find(s => s.id === skillId);
      if (skillObj) {
        return <View key={index} style={{ padding: '1px', color: skillObj.isActive ? 'black' : 'white'}}>
          <Text style={{ padding: '0px 5px', fontSize: 8, backgroundColor: 'lightgray', borderRadius: 4}}>{skillObj.name}</Text>
        </View>
      }
      return <View key={index}/>;
    })}
  </View>);
};
