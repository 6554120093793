import { WorkingBudgetDay } from '@/services/budget/budget.types';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { BudgetCell } from '../../budget/BudgetCell';

export const BudgetShiftsCell: FC<{
  day: WorkingBudgetDay;
}> = ({ day }) => {
  return (
    <BudgetCell
      className={twMerge(
        day.totalShiftCount > day.filledShiftCount ? 'text-secondary font-semibold' : undefined,
        'justify-center'
      )}
    >
      {`${day.filledShiftCount}/${day.totalShiftCount}`}{' '}
    </BudgetCell>
  );
};
