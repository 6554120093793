import { StaffAvailabilityDay } from '@/services/availability/useStaffAvailability';
import { FC } from 'react';
import { strings } from '@/services/translation/strings';
import { displayTimes } from './manageAvailabilityHelper';

interface Props {
  selectedDay: StaffAvailabilityDay;
}

export const AvailabilityDetailsPattern: FC<Props> = ({ selectedDay }) => {
  return (
    <div className="flex-1">
      <div className="mb-1 text-gray-500">{strings.settings.manageAvailability.pattern}</div>
      <div>{displayTimes(selectedDay.pattern)}</div>
    </div>
  );
};
