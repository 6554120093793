import {create} from "zustand";

export enum PrintViewTypes {
  SCREEN = 'Screen',
  POSTER = 'Poster'
}
export type PrintViewType = PrintViewTypes.SCREEN | PrintViewTypes.POSTER;

export interface PrintOptions {
  viewType: PrintViewType,
  getViewType: () => PrintViewType,
  setViewType: (viewType?: PrintViewType) => void
}

export const usePrintOptions = create<PrintOptions>((set, get) => {
  return {
    viewType: get()?.viewType ?? PrintViewTypes.SCREEN,
    getViewType: () => {
      return get()?.viewType ?? PrintViewTypes.SCREEN;
    },
    setViewType: (newViewType) => {
      const currentViewType = get()?.viewType;
      if (newViewType !== currentViewType) {
        set({viewType: newViewType});
      }
    }
  }
});
