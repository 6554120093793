import { useRole } from '@/services/authentication/hooks/user';
import { FC } from 'react';
import { HeaderLink } from './HeaderLink';
import { useNavBarOptions } from './useNavigationOptions';

export const NavigationBarOptions: FC = () => {
  const role = useRole();
  const navBarOptions = useNavBarOptions();

  return (
    <>
      {navBarOptions.map((opt, index) => {
        if (!opt.roles?.length || opt.roles.some(r => r === role)) {
          return <HeaderLink key={`nav-option-${index}`} to={opt.path} name={opt.name} />;
        }
      })}
    </>
  );
};
