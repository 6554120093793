import { SelectOption } from '@/components/form/SelectInput';
import { strings } from '@/services/translation/strings';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';
import { OperationResult } from 'urql';

export const sortSimpleObjectArray = (
  array: object[],
  propertyName: string,
  sortOrder: 'ASC' | 'DESC' = 'ASC'
): object[] => {
  return array.sort((a: any, b: any) => {
    const first = sortOrder === 'ASC' ? a : b;
    const second = sortOrder === 'ASC' ? b : a;

    const firstVal = first[propertyName];
    const secondVal = second[propertyName];

    if (typeof firstVal === 'string') {
      return firstVal.localeCompare(secondVal, undefined, { numeric: true });
    } else if (typeof firstVal === 'number') {
      return firstVal - secondVal;
    }

    return 0;
  });
};

export const generateSelectOptions = (
  options: any[],
  paramToUseForName: string,
  paramToUseForValue: string
): SelectOption[] => {
  return options
    ? options.map(opt => {
        const selectOption = { name: opt[paramToUseForName], value: opt[paramToUseForValue] };
        return selectOption;
      })
    : [];
};

export const toastSuccess = () => {
  toast(strings.common.success, {
    position: 'top-right',
    className: 'text-xl',
    icon: <FiCheck className="h-8 w-8" />
  });
};

export const toastError = () => {
  toast(strings.common.error, {
    position: 'top-right',
    className: 'text-xl',
    icon: <FiX className="h-8 w-8" />
  });
};

export const toastMessage = (text: string) => {
  toast(text, {
    position: 'top-right',
    className: 'text-xl',
    icon: <FiX className="h-8 w-8" />
  });
}

export const showToastMessage = (
  response: OperationResult,
  response2?: OperationResult,
  response3?: OperationResult
) => {
  if (response?.error || response2?.error || response3?.error) {
    toastError();
  } else {
    toastSuccess();
  }
};

// required for cloning nested objects
export const objectDeepClone = (input: any) => {
  if (input === null || input === undefined || typeof input !== 'object') {
    return input;
  }

  const initialOutput = Array.isArray(input) ? [] : {};

  return Object.keys(input).reduce((acc: any, key: any) => {
    acc[key] = objectDeepClone(input[key]);
    return acc;
  }, initialOutput);
};
