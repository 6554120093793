import React from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, Route } from 'react-router-dom';
import App from './App';
import './index.css';
import { Home } from './pages/Home';
import { RoleRestricted } from './pages/authentication/RoleRestricted';
import { PageContainer } from './pages/layout/main/PageContainer';
import { RouteElement } from './pages/layout/main/navigation.types';
import { getNavBarRoutes } from './pages/layout/main/useNavigationOptions';
import { PdfRosterPrintTestPage } from './pages/pdf/roster/PdfRosterTest';
import { MonthlyPrintTestPage } from './pages/pdf/summary/monthly/MonthlyTest';
import { WeeklyPrintTestPage } from './pages/pdf/summary/weekly/WeeklyTest';
import { Personalization } from './pages/settings/preferences/Personalization';
import { StaffPickerColumns } from './pages/settings/preferences/StaffPickerColumns';
import { TaskDisplay } from './pages/settings/preferences/TaskDisplay';
import { Restricted } from './services/authentication/Restricted';
import { NotificationProvider } from './services/notifications/NotificationService';
import { OptimiserProvider } from './services/optimiser/OptimiserService';
import { LanguageProvider } from './services/settings/LanguageProvider';
import { LocalSettingsProvider } from './services/settings/LocalSettingsProvider';
import { LocationSettingsProvider } from './services/settings/LocationSettingsProvider';
import { StaffSettingsProvider } from './services/settings/StaffSettingsProvider';
import { EditSettingsProvider } from './pages/settings/services/EditSettingsService';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App>
      <Route
        element={
          <LanguageProvider>
            <LocalSettingsProvider>
              <NotificationProvider>
                <OptimiserProvider>
                  <StaffSettingsProvider>
                    <LocationSettingsProvider>
                      <PageContainer>
                        <Restricted id="main" showError>
                          <Outlet></Outlet>
                        </Restricted>
                      </PageContainer>
                    </LocationSettingsProvider>
                  </StaffSettingsProvider>
                </OptimiserProvider>
              </NotificationProvider>
            </LocalSettingsProvider>
          </LanguageProvider>
        }
      >
        <Route path="/" element={<Home />} />

        {/* We are adding dynamically navigation bar options based on logged in user */}
        {getNavBarRoutes().map((r: RouteElement) => (
          <Route key={r.path} path={r.path} element={<RoleRestricted roles={r.roles}>{r.element}</RoleRestricted>} />
        ))}
        <Route path="/weeklyTest" element={<WeeklyPrintTestPage />} />
        <Route path="/monthlyTest" element={<MonthlyPrintTestPage />} />
        <Route path="/rosterTest" element={<PdfRosterPrintTestPage />} />
        <Route path="/myProfile">
          <Route
            path="staffPickerColumns"
            element={
              <EditSettingsProvider>
                <StaffPickerColumns />
              </EditSettingsProvider>
            }
          />
          <Route
            path="taskDisplay"
            element={
              <EditSettingsProvider>
                <TaskDisplay />
              </EditSettingsProvider>
            }
          />
          <Route
            path="personalization"
            element={
              <EditSettingsProvider>
                <Personalization />
              </EditSettingsProvider>
            }
          />
        </Route>
      </Route>
    </App>
  </React.StrictMode>
);
