import { Button } from '@/components/button';
import { PageHeader } from '@/pages/layout/main/PageHeader';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';
import { MonthSelector } from '../../layout/MonthSelector';
import { BudgetCsvDownload } from '../files/BudgetCsvDownload';
import { BudgetUpload } from '../files/BudgetUpload';
import { useSaveBudget } from '../useSaveBudget';
import { LocationSelector } from '@/pages/layout/main/LocationSelector';

export const MonthlyBudgetHeader: FC = () => {
  const { saveBudget, fetching } = useSaveBudget();
  const saveNeeded = useConfirm(state => state.confirmationNeeded);

  const onSave = async () => {
    const result = await saveBudget();

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  };

  return (
    <PageHeader className="flex justify-between">
      <div className="flex gap-5 items-center">
        <LocationSelector isMultiSelector={true} />
        <MonthSelector showNavigationArrows={true} />
        <BudgetCsvDownload />
        <BudgetUpload />
      </div>
      <div className="flex gap-5 items-center">
        <Button onClick={onSave} loading={fetching} variant={saveNeeded ? 'submit' : undefined}>
          {strings.common.save}
        </Button>
      </div>
    </PageHeader>
  );
};
