import { useGetEmployeeSkillsQuery } from '@/services/gql/graphql.generated';
import { EmployeeSkills } from './employeeForShift.types';

export const useEmployeesSkillsList = (employeeIds: string[]) => {
  const [{ data: skillsData }] = useGetEmployeeSkillsQuery({ variables: { employeeIds: employeeIds } });
  const employeesSkillsList: EmployeeSkills[] = skillsData?.employeeSkillsByEmployeeIds
    ? skillsData?.employeeSkillsByEmployeeIds?.map(emp => {
        return {
          employeeId: emp.employeeId,
          skills: emp.skills?.length ? emp.skills?.map(skill => ({ name: skill.name, skillId: skill?.skillId })) : []
        };
      })
    : [];

  if (!employeesSkillsList) {
    throw Error('Could not get skills data.');
  }

  return employeesSkillsList;
};
