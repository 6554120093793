import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends PropsWithChildren {
  className?: string;
}

export const PageHeader: FC<Props> = ({ children, className }) => {
  return (
    <div className={twMerge(className, 'flex items-center gap-5 px-10 py-2 bg-background-mid border-b')}>
      {children}
    </div>
  );
};
