import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { ROSTER_TOTAL_CELL_WIDTH } from './PdfRosterDocument';

export const PdfRosterTotalsHeader: FC = () => {
  return (
    <View style={{ flexDirection: 'column', width: ROSTER_TOTAL_CELL_WIDTH * 2 }}>
      <PdfTableCell height={16} width={ROSTER_TOTAL_CELL_WIDTH * 2}>
        <Text style={styles.tableCell}>{strings.summary.monthlyTotal}</Text>
      </PdfTableCell>
      <View style={{ flexDirection: 'row', borderTopWidth: 0.5 }}>
        <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
          <View>
            <Text style={styles.tableCell}>{strings.summary.max}</Text>
            <Text style={styles.tableCell}>{strings.summary.min}</Text>
          </View>
        </PdfTableCell>
        <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
          <Text style={styles.tableCell}>{strings.summary.periodTotal}</Text>
        </PdfTableCell>
      </View>
    </View>
  );
};

export const PdfRosterAttendanceHeader: FC = () => {
  return (
    <View style={{ flexDirection: 'column', width: ROSTER_TOTAL_CELL_WIDTH * 3 }}>
      <PdfTableCell height={16} width={ROSTER_TOTAL_CELL_WIDTH * 3}>
        <Text style={styles.tableCell}>{strings.common.totals.attendanceTotal}</Text>
      </PdfTableCell>
      <View style={{ flexDirection: 'row', borderTopWidth: 0.5 }}>
        <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
          <Text style={styles.tableCell}>{strings.common.totals.dailyTotal}</Text>
        </PdfTableCell>
        <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
          <Text style={styles.tableCell}>{strings.common.totals.actualTotal}</Text>
        </PdfTableCell>
        <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
          <Text style={styles.tableCell}>{strings.common.totals.break}</Text>
        </PdfTableCell>
      </View>
    </View>
  );
};
