import { AvailabilityIndicator } from '@/components/availability/AvailabilityIndicator';
import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { StaffAvailabilityDay } from '@/services/availability/useStaffAvailability';
import { useLeaveType } from '@/services/leaveManagement/LeaveTypesProvider';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';

interface Props {
  data: StaffAvailabilityDay;
  onClick: () => void;
}

export const AvailabilityTableCell: FC<Props> = ({ data, onClick }) => {
  const { availableTimes, leaveTypeId, date } = data;
  const { id: locationId } = useSelectedLocation();

  const inPast = dateToApiFormat(date) < dateToApiFormat(new Date());

  const canEdit = leaveTypeId !== FixedLeaveTypeId.ROSTER && !inPast;

  let labelContent: JSX.Element = leaveTypeId ? <AvailabilityIndicator leaveTypeId={leaveTypeId} /> : <></>;

  const leaveType = useLeaveType(data.leaveTypeId);

  let mainContent: JSX.Element = <></>;

  if (data.rosteredShifts && data.rosteredShifts.length > 0) {
    // If you have an absence but have been rostered then hide the absence to help visibility
    labelContent = <></>;

    const bgColour = data.rosteredShifts[0].locationId === locationId.toString() ? 'bg-on' : 'bg-[#E8AA14]';
    mainContent = (
      <div className={'flex-1 space-y-1 rounded-sm text-sm flex flex-col items-center justify-center ' + bgColour}>
        <div>{secondsToTimeString(data.rosteredShifts[0].start)}</div>
        <div>{secondsToTimeString(data.rosteredShifts[0].end)}</div>
      </div>
    );
  } else if (leaveTypeId) {
    mainContent = (
      <div
        className="bg-background-mid rounded-md flex items-center justify-center flex-1"
        style={{ backgroundColor: leaveType?.properties.color }}
      >
        {strings.summary.holiday}
      </div>
    );
  } else if (availableTimes.length > 0) {
    mainContent = (
      <div className="flex-1 rounded-sm flex flex-col items-center justify-center space-y-1">
        {availableTimes.map(t => (
          <div
            style={{ fontSize: 14 - Math.min(availableTimes.length, 3) }}
            className={availableTimes.length === 1 ? 'leading-tight' : 'leading-none'}
            key={t.start}
          >
            <div>{secondsToTimeString(t.start)}</div>
            <div>{secondsToTimeString(t.end)}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        'flex-1 border-l flex items-stretch justify-center p-1 overflow-hidden',
        inPast ? 'bg-background-mid' : undefined
      )}
    >
      <button
        onClick={canEdit ? onClick : undefined}
        className="flex flex-col gap-1 w-full items-stretch uppercase"
        style={{ cursor: !canEdit ? 'default' : undefined }}
      >
        {labelContent}
        {mainContent}
      </button>
    </div>
  );
};
