import { Button } from '@/components/button';
import { AddButton } from '@/components/buttons/AddButton';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { SettingsTable } from '../../layout/SettingsTable';
import { Persona } from '../managePersonas/Persona';
import { useEditPersonas } from './useEditPersonas';
import { usePersonaColumns } from './usePersonaColumns';
import { useFlag } from '@softwareimaging/backstage';

export const PersonaSettings: FC = () => {
  const {
    personas,
    onPersonAdd,
    onPersonaUpdate,
    onPersonaDelete,
    onSave,
    canSave,
    idsToBeDeleted,
    onPersonaRankChange
  } = useEditPersonas();
  const columns = usePersonaColumns();
  const deletePersonaEnabled = useFlag('deletePersonas');

  return (
    <>
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <AddButton label={strings.settings.managePersonas.persona} onButtonClick={onPersonAdd} />
          </div>
          <Button
            className="bg-[#1BB55C] text-[white] hover:text-[white] hover:bg-[#1BB55C]"
            onClick={onSave}
            variant={canSave ? 'submit' : undefined}
            size="sm"
            disabled={canSave ? undefined : true}
          >
            {strings.common.save}
          </Button>
        </div>
      </SettingsSectionHeader>
      <SettingsTable columns={columns}>
        {personas.length > 0 &&
          personas.map((persona) => (
            <Persona
              key={persona.id}
              persona={persona}
              deleteEnabled={deletePersonaEnabled}
              isToBeDeleted={idsToBeDeleted.includes(persona.id as any)}
              onUpdate={onPersonaUpdate}
              onDelete={onPersonaDelete}
              onRankChange={onPersonaRankChange(persona)}
              columns={columns}
            />
          ))}
        {!personas.length && (
          <div className="flex items-center justify-center col-span-full p-10 text-xl text-[#32323280] font-medium">
            {strings.settings.managePersonas.emptyList}
          </div>
        )}
      </SettingsTable>
    </>
  );
};
