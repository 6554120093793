import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { ComplianceTotals } from '@/services/summary/buildCompliance';
import { FC } from 'react';

interface MonthlyTotalSummaryProps {
  showDayTotals?: boolean;
  complianceTotals: ComplianceTotals;
}

export const MonthlyTotalSummary: FC<MonthlyTotalSummaryProps> = ({ showDayTotals, complianceTotals }) => {
  return (
    <>
      {showDayTotals && (
        <>
          <InfoHeaderCell className="border-r flex-1">{complianceTotals.holidayDays}</InfoHeaderCell>
          <InfoHeaderCell className="border-r flex-1">{complianceTotals.attendanceDays}</InfoHeaderCell>
        </>
      )}
      <InfoHeaderCell className="border-r flex-1" />
      <InfoHeaderCell className="flex-1">{secondsToTimeString(complianceTotals.actualTime)}</InfoHeaderCell>
    </>
  );
};
