import { SeverityType } from "@/components/warning/warningDefinitions";
import { RosterWarningModel, WarningsProps } from "../warnings.types";

export const getManagerRequiredWarning = (props: WarningsProps): RosterWarningModel | undefined => {
  const { workingRoster, allEmployees, strings } = props;
  const shifts = workingRoster.shifts;
  
  let hasManager = false;
  const assignedRosterShifts = shifts?.filter(s => s.assignedEmployeeId);

  shifts.forEach(shift => {
    const foundManager = allEmployees.find(
      emp => emp.identityId === shift.assignedEmployeeId && !!emp.personas?.find(p => p.management)
    );

    if (foundManager) {
      hasManager = true;
    }
  });

  if (assignedRosterShifts && assignedRosterShifts.length && !hasManager) {
    return {
      severity: SeverityType.CRITICAL,
      name: strings.managerRequired
    };
  }

  return;
};