import { secondsToTimeString } from '@/helpers/dateHelper';
import { WorkingShift } from '@/pages/daily/daily.types';
import { Note, PdfRosterNotes } from '@/pages/pdf/roster/PdfRosterNotes';
import { PrintViewTypes } from '@/pages/pdf/roster/usePrintOptions';
import { strings } from '@/services/translation/strings';
import { Document, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { FC, useContext, useMemo } from 'react';
import { PdfPage } from '../PdfPage';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { PdfRosterBudgetSummary } from './PdfRosterBudgetSummary';
import { PdfRosterContext, PdfRosterProvider } from './PdfRosterProvider';
import { PdfRosterRow } from './PdfRosterRow';
import { PdfRosterScratchpad } from './PdfRosterScratchpad';
import { PdfRosterAttendanceHeader, PdfRosterTotalsHeader } from './PdfRosterTableHeaders';
import { PdfRosterTaskKey, TASK_ROW_LENGTH } from './PdfRosterTaskKey';
import { PdfRosterTimerBar } from './PdfRosterTimerBar';
import { PdfRosterProps } from './useRosterPrintProps';
import { buildComplianceDataTotals } from '@/services/summary/buildCompliance';
import { buildSummaryDayStats } from '@/services/summary/buildSummaryDayStats';

export const ROSTER_NAME_WIDTH = 100;
export const ROSTER_TOTAL_CELL_WIDTH = 40;
export const ROSTER_DETAILS_WIDTH = ROSTER_NAME_WIDTH + ROSTER_TOTAL_CELL_WIDTH * 5;
const ROSTER_NOTE_DATE_WIDTH = 100;
const ROSTER_NOTE_CONTENT_WIDTH = 660;

const FIRST_PAGE_BASE_LENGTH = 11;
const OTHER_PAGE_BASE_LENGTH = 15;
const POSTER_FIRST_PAGE_BASE_LENGTH = 16;
const POSTER_OTHER_PAGE_BASE_LENGTH = 16;

const PdfRosterDocumentContent: FC = () => {
  const { workingRoster, reassignedShifts, unassignedGhostShifts, locationName, taskList, viewType, summary } =
    useContext(PdfRosterContext);

  if (!workingRoster) {
    throw Error('Working roster should be defined');
  }

  const printDate = new Date();
  const totals = buildComplianceDataTotals(summary);
  const rosterStats = buildSummaryDayStats(summary, [workingRoster.date])[0];
  
  const title = strings.daily.roster.print.title(format(workingRoster.date, 'yyyy/MM/dd'), locationName);

  const taskRows = Math.ceil(taskList.length / TASK_ROW_LENGTH);
  const firstPageLength =
    (viewType === PrintViewTypes.SCREEN ? FIRST_PAGE_BASE_LENGTH : POSTER_FIRST_PAGE_BASE_LENGTH) - taskRows;
  const otherPageLength =
    (viewType === PrintViewTypes.SCREEN ? OTHER_PAGE_BASE_LENGTH : POSTER_OTHER_PAGE_BASE_LENGTH) - taskRows;

  const pages: WorkingShift[][] = useMemo(() => {
    const pages: WorkingShift[][] = [[]];
    const shifts = workingRoster.shifts;

    [...shifts, ...reassignedShifts, ...unassignedGhostShifts].forEach(shift => {
      let currentPage = pages[pages.length - 1];
      const pageLimit = pages.length === 1 ? firstPageLength : otherPageLength;

      if (currentPage.length === pageLimit) {
        currentPage = [];
        pages.push(currentPage);
      }

      currentPage.push(shift);
    });

    return pages;
  }, [workingRoster, firstPageLength, otherPageLength]);

  const notes: Note[] | undefined = useMemo(() => {
    return workingRoster.notes
      ? [
          {
            date: workingRoster.date,
            text: workingRoster.notes
          }
        ]
      : undefined;
  }, [workingRoster]);

  return (
    <Document>
      <PdfPage orientation={'landscape'} headerContent={title} datePrinted={printDate}>
        <View style={styles.table}>
          {viewType === PrintViewTypes.SCREEN && <PdfRosterBudgetSummary />}
          <PdfRosterTimerBar />
          <PdfRosterScratchpad />
          {viewType === PrintViewTypes.SCREEN && (
            <View style={styles.tableRow}>
              <PdfTableCell width={ROSTER_NAME_WIDTH} />
              <PdfRosterTotalsHeader />
              <PdfRosterAttendanceHeader />
            </View>
          )}
          {viewType === PrintViewTypes.SCREEN && (
            <View style={styles.tableRow}>
              <PdfTableCell width={ROSTER_NAME_WIDTH}>
                <Text style={styles.tableCell}>{strings.common.employee}</Text>
              </PdfTableCell>
              <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}></PdfTableCell>
              <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
                <Text style={styles.tableCell}>{secondsToTimeString(totals.actualTime)}</Text>
              </PdfTableCell>
              <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
                <Text style={styles.tableCell}>{secondsToTimeString(rosterStats.basicTotal)}</Text>
              </PdfTableCell>
              <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
                <Text style={styles.tableCell}>{secondsToTimeString(rosterStats.actualTotal)}</Text>
              </PdfTableCell>
              <PdfTableCell width={ROSTER_TOTAL_CELL_WIDTH}>
                <Text style={styles.tableCell}>{secondsToTimeString(rosterStats.breakTotal)}</Text>
              </PdfTableCell>
            </View>
          )}
          {pages[0].map(shift => (
            <PdfRosterRow key={shift.id} shift={shift} />
          ))}
        </View>
        <PdfRosterTaskKey />
        {pages.length === 1 /* lastPage? */ && (
          <PdfRosterNotes notes={notes} dateWidth={ROSTER_NOTE_DATE_WIDTH} contentWidth={ROSTER_NOTE_CONTENT_WIDTH} />
        )}
      </PdfPage>
      {pages.slice(1).map((page, index) => (
        <PdfPage key={index} orientation={'landscape'} headerContent={title} datePrinted={printDate}>
          <View style={styles.table}>
            <PdfRosterTimerBar />
            {viewType === PrintViewTypes.SCREEN && (
              <View style={styles.tableRow}>
                <PdfTableCell width={ROSTER_NAME_WIDTH}>
                  <Text style={styles.tableCell}>{strings.common.employee}</Text>
                </PdfTableCell>
                <PdfRosterTotalsHeader />
                <PdfRosterAttendanceHeader />
              </View>
            )}
            {page.map(shift => (
              <PdfRosterRow key={shift.id} shift={shift} />
            ))}
          </View>
          <PdfRosterTaskKey />
          {index == pages.length - 2 /* lastPage? */ && (
            <PdfRosterNotes notes={notes} dateWidth={ROSTER_NOTE_DATE_WIDTH} contentWidth={ROSTER_NOTE_CONTENT_WIDTH} />
          )}
        </PdfPage>
      ))}
    </Document>
  );
};

export const PdfRosterDocument: FC<PdfRosterProps> = props => {
  return (
    <PdfRosterProvider rosterPdfProps={props}>
      <PdfRosterDocumentContent />
    </PdfRosterProvider>
  );
};
