import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  table: {
    width: 'auto',
    borderWidth: 0.5
  },
  tableRow: {
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    borderBottomWidth: 0.5
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    fontSize: 8,
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 1
  },
  tableCell: {
    width: '100%',
    padding: '0px 5px',
    fontSize: 8,
    textAlign: 'center'
  },
  tableCellSm: {
    margin: 1,
    fontSize: 5,
    width: '100%',
    textAlign: 'center'
  },
  tableCellXs: {
    margin: 1,
    fontSize: 4,
    width: '100%',
    textAlign: 'center',
    lineHeight: 1
  },
  box: {
    width: '90%',
    height: '90%',
    borderRadius: 4,
    borderWidth: 0.5,
    alignItems: 'center',
    justifyContent: 'center'
  }
});
