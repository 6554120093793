import { FC } from 'react';
import { ScratchpadBox } from './ScratchpadBox';
import { twMerge } from 'tailwind-merge';
import { strings } from '@/services/translation/strings';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { roundToDecimalPlaces } from '@/helpers/numberHelper';
import { minutesToSeconds } from 'date-fns';

interface Props {
  counts: number[];
  scratchpad: number[];
  setCount: (index: number) => (newCount: number) => void;
  columnWidth: string;
  id: string;
  readonly?: boolean;
  startTime: number;
  endTime: number;
}

const differenceToColour = (difference: number) => {
  if (difference === 0) {
    return 'text-black';
  } else if (difference < 0) {
    return 'text-primary';
  } else if (difference > 0) {
    return 'text-secondary';
  }
};

export const ShiftHourSummary: FC<Props> = ({ counts, scratchpad, setCount, columnWidth, id, readonly, startTime }) => {
  const minuteIncrement = useSystemSettings(state => state.settings.minuteIncrement);
  const secondIncrement = minuteIncrement * 60;

  const scratchTotal = scratchpad.reduce((prevTot, currVal) => prevTot + currVal, 0);
  const scratchTotalSeconds = scratchTotal * 30 * 60;

  const countTotal = counts.reduce((prevTot, currVal) => prevTot + currVal, 0);
  // Because we're rounding each count as we calculate them, the total can end up being out by 0.01 or so.
  // To rectify this, we need to round the total to the nearest multiple of the smallest increment in number of seconds.
  const countTotalSecondsUnrounded = countTotal * 30 * 60;
  const countTotalSeconds = Math.round(countTotalSecondsUnrounded / secondIncrement) * secondIncrement;

  const totalDifference = countTotalSeconds - scratchTotalSeconds;

  return (
    <>
      <div className="flex flex-row">
        <div className="flex bg-background-mid sticky left-0 z-40 border-r">
          <div
            className="flex items-center justify-end font-semibold text-sm text-right pr-2"
            style={{ width: columnWidth }}
          >{`${strings.daily.summary.scratchTotal} (${strings.daily.summary.totalTime}: ${secondsToTimeString(
            scratchTotalSeconds
          )})`}</div>
        </div>
        <div className="flex-1">
          <div className="flex flex-row bg-background-mid pr-9">
            {scratchpad.map((count, index) => {
              return (
                <div className="flex-1 py-1 px-1" key={`${id}-${startTime + minutesToSeconds(30 * index)}`}>
                  <ScratchpadBox count={count} setCount={setCount(index)} readonly={readonly} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex border-b bg-background-mid sticky left-0 z-40 border-r">
          <div className="flex items-center justify-end font-semibold text-sm pr-2" style={{ width: columnWidth }}>
            <span>
              {`${strings.daily.summary.totalActualEmployee} (${strings.daily.summary.totalTime}: `}
              <span className={differenceToColour(totalDifference)}>{secondsToTimeString(countTotalSeconds)}</span>
              {')'}
            </span>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-row bg-background-mid border-b pr-9">
            {counts.map((count, index) => {
              const difference = count - scratchpad[index];
              return (
                <div className={twMerge(differenceToColour(difference), 'flex-1 py-2 text-center')} key={index}>
                  {roundToDecimalPlaces(count, 2)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
