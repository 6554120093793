import { bgWorkPatternColour } from '@/pages/settings/sections/manageStaff/staffTable/StaffRow';
import { FC } from 'react';
import { RadioButton } from '../RadioButton';

export enum FilterOptionsAlignment {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export interface FilterOption {
  displayName: string;
  value: any;
}

interface Props {
  selectedOption: any;
  options: FilterOption[];
  optionsAlignment?: FilterOptionsAlignment;
  shouldFillRemainingSpace?: boolean;
  onSelect: (event: any) => void;
}

export const RadioFilterSelector: FC<Props> = ({
  selectedOption,
  options,
  optionsAlignment = FilterOptionsAlignment.HORIZONTAL,
  shouldFillRemainingSpace,
  onSelect
}) => {
  return (
    <div
      className={`relative flex content-center gap-1 text-[#323232] select-none ${
        optionsAlignment === FilterOptionsAlignment.VERTICAL ? 'flex-col items-start' : 'items-center'
      } ${shouldFillRemainingSpace ? 'grow' : ''}`}
    >
      {options.map((option, index) => {
        return (
          <div key={`${option.value}:${index}`} className="flex gap-2.5 items-center px-2.5">
            <RadioButton
              key={`${option.value}:${index}`}
              checked={selectedOption === option.value}
              onClick={() => onSelect(option.value)}
              className={`focus:ring-2 focus:ring-gray-400 border-gray-400`}
              style={{ color: `${bgWorkPatternColour}` }}
            />
            <span className="leading-none text-sm">{option.displayName}</span>
          </div>
        );
      })}
    </div>
  );
};
