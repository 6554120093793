import { cellToId, idToCell, useCellHelper } from './cellHelper';

export const useBudgetTab = (locationCount: number, dayCount: number, indexCount: number) => {
  const { cellLeft, cellDown, cellRight, cellUp } = useCellHelper(locationCount, dayCount, indexCount);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.key.includes('Arrow')) {
      event.preventDefault(); // Stop the default tab action

      if (!(event.target instanceof HTMLElement)) return;
      const dataTabId = event.target.dataset.tabId;
      if (!dataTabId) return;

      const currentCell = idToCell(dataTabId);
      let newCell = currentCell;

      // Decide where to send focus next
      if (event.key === 'Tab') {
        if (event.shiftKey) {
          newCell = cellLeft(currentCell);
        } else {
          newCell = cellRight(currentCell);
        }
      }

      if (event.key === 'ArrowUp') {
        newCell = cellUp(currentCell);
      } else if (event.key === 'ArrowDown') {
        newCell = cellDown(currentCell);
      } else if (event.key === 'ArrowRight') {
        newCell = cellRight(currentCell);
      } else if (event.key === 'ArrowLeft') {
        newCell = cellLeft(currentCell);
      }

      document.querySelector<HTMLElement>(`[data-tab-id="${cellToId(newCell)}"]`)?.focus();
    }
  };

  return (node: HTMLElement | null) => {
    if (node) {
      node.addEventListener('keydown', handleKeyDown);

      // Cleanup: remove the event listener when the component unmounts or the node changes
      return () => {
        node.removeEventListener('keydown', handleKeyDown);
      };
    }
  };
};
