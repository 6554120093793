import { FC } from 'react';
import { strings } from '@/services/translation/strings';
import { IoCloseOutline } from 'react-icons/io5';

interface Props {
  onClose?: () => void;
}

export const NotificationBoxHeader: FC<Props> = ({ onClose }) => {
  return (
    <div className="flex justify-between items-center w-full px-5 pt-2">
      <span className="text-lg font-bold text-[#0070D2] leading-none">{strings.layout.headerLinks.notifications}</span>
      <IoCloseOutline className="rounded w-6 h-6 hover:cursor-pointer hover:bg-[#32323240]" onClick={onClose} />
    </div>
  );
};
