import { EMPLOYEE_INFO_WIDTH } from '@/pages/summary/constants';
import { FC } from 'react';
import { ShiftHourSummary } from '../ShiftHourSummary';
import { useHourSummary } from '../useHourSummary';
import { WorkingTemplate } from './template.types';

interface Props {
  workingTemplate: WorkingTemplate;
  setWorkingTemplate: (newTemp: WorkingTemplate) => void;
}

export const TemplateHourSummary: FC<Props> = ({ workingTemplate, setWorkingTemplate }) => {
  const onSetValue = (index: number) => (newValue: number) => {
    const newWorkingTemplate = { ...workingTemplate };

    newWorkingTemplate.scratchpad[index] = newValue;

    setWorkingTemplate(newWorkingTemplate);
  };

  const counts = useHourSummary(workingTemplate.shifts, workingTemplate.startTime, workingTemplate.endTime, true);

  return (
    <ShiftHourSummary
      id={workingTemplate.id}
      counts={counts}
      scratchpad={workingTemplate.scratchpad}
      setCount={onSetValue}
      columnWidth={EMPLOYEE_INFO_WIDTH}
      startTime={workingTemplate.startTime}
      endTime={workingTemplate.endTime}
    />
  );
};
