import { BudgetPage } from '@/pages/budget/BudgetPage';
import { RosterPage } from '@/pages/daily/roster/RosterPage';
import { TemplateLibraryPage } from '@/pages/daily/template/library/TemplateLibraryPage';
import { TemplateShopPage } from '@/pages/daily/template/shop/TemplateShopPage';
import { SettingsSubpage } from '@/pages/settings/layout/SettingsHeader';
import { LeaveSettings } from '@/pages/settings/sections/manageAdvancedLeave/LeaveSettings';
import { AnnouncementsSettings } from '@/pages/settings/sections/manageAnnouncements/AnnouncementsSettings';
import { AvailabilitySettings } from '@/pages/settings/sections/manageAvailability/AvailabilitySettings';
import { PersonaSettings } from '@/pages/settings/sections/managePersonas/PersonaSettings';
import { AdminStaffSettings } from '@/pages/settings/sections/manageStaff/AdminStaffSettings';
import { StaffSettings } from '@/pages/settings/sections/manageStaff/StaffSettings';
import { StaffSkillsSettings } from '@/pages/settings/sections/manageStaffSkills/table/StaffSkillsSettings';
import { SystemSettingsView } from '@/pages/settings/sections/manageSystem/SystemSettingsView';
import { UserAccountsSettings } from '@/pages/settings/sections/manageUserAccounts/UserAccountsSettings';
import OrganisationChartPage from '@/pages/settings/sections/organisationChart/OrganisationChartPage';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { MonthlyPage } from '@/pages/summary/monthly/MonthlyPage';
import { WeeklyPage } from '@/pages/summary/WeeklyPage';
import { useRole } from '@/services/authentication/hooks/user';
import { RoleType } from '@/services/authentication/roles';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { MonthlySortProvider } from '@/services/sort/MonthlySortProvider';
import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { LocationSettings } from '../../settings/sections/manageLocation/LocationSettings';
import { SkillsSettings } from '../../settings/sections/manageSkills/SkillsSettings';
import { TasksSettings } from '../../settings/sections/manageTasks/TasksSettings';
import { NavBarElement, RouteElement } from './navigation.types';

export const useNavBarOptions = () => {
  const advancedLeave = useSystemSettings(state => state.settings.advancedLeaveManagement);
  const role = useRole();

  return useMemo(() => {
    return getNavBarOptions({ userRole: role, advancedLeave });
  }, [advancedLeave, role]);
};

export const getNavBarOptions = (options?: { userRole?: RoleType; advancedLeave?: boolean }): NavBarElement[] => {
  const navBarOptions: NavBarElement[] = [
    {
      name: strings.layout.headerLinks.monthlyBudgets,
      path: '/budgets',
      element: <BudgetPage />,
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: []
    },
    {
      name: strings.layout.headerLinks.monthlyShifts,
      path: '/monthly',
      element: (
        <MonthlySortProvider>
          <MonthlyPage />
        </MonthlySortProvider>
      ),
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: []
    },
    {
      name: strings.layout.headerLinks.weeklyShifts,
      path: '/weekly',
      element: <WeeklyPage />,
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: []
    },
    {
      name: strings.layout.headerLinks.dailyShifts,
      path: '/rosters',
      element: <RosterPage />,
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: []
    },
    {
      name: strings.layout.headerLinks.templates,
      path: '/templates',
      element: <TemplateShopPage />,
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: [
        {
          name: strings.layout.headerLinks.templates,
          path: '/library',
          element: <TemplateLibraryPage />,
          roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
          rolesExcept: [],
          permissions: [],
          subPages: []
        }
      ]
    },

    {
      name: strings.settings.pages.manageStaff,
      path: '/settingsStaff',
      element: (
        <SettingsPage hideDefaultSaveFor={[SettingsSubpage.STAFF]}>
          <StaffSettings />
        </SettingsPage>
      ),
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: [
        {
          name: strings.settings.pages.manageStaff,
          path: '/staff',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.STAFF]}>
              <StaffSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.manageSkills,
          path: `/${SettingsSubpage.STAFF_SKILLS}`,
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.STAFF_SKILLS]}>
              <StaffSkillsSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.availability.name,
          path: '/availability',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.AVAILABILITY]}>
              <AvailabilitySettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        }
      ]
    },
    {
      name: strings.settings.pages.locations.label,
      path: '/settingsLocation',
      element: (
        <SettingsPage hideDefaultSaveFor={[SettingsSubpage.MANAGE_LOCATIONS, SettingsSubpage.ESS_MESSAGES]}>
          <AnnouncementsSettings />
        </SettingsPage>
      ),
      roles: [RoleType.Manager, RoleType.Senior_Manager, RoleType.Supervisor, RoleType.Staff],
      rolesExcept: [],
      permissions: [],
      subPages: [
        {
          name: strings.settings.pages.locations.essMessages,
          path: '/essMessages',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.ESS_MESSAGES]}>
              <AnnouncementsSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.locations.myLocation,
          path: '/manageLocations',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.MANAGE_LOCATIONS]}>
              <LocationSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        }
      ]
    },
    {
      name: strings.layout.headerLinks.systemSetup,
      path: '/systemSetup',
      element: (
        <SettingsPage>
          <SystemSettingsView />
        </SettingsPage>
      ),
      roles: [RoleType.Admin, RoleType.Super_Admin],
      rolesExcept: [],
      permissions: [],
      subPages: [
        {
          name: strings.settings.pages.system,
          path: '/system',
          element: (
            <SettingsPage>
              <SystemSettingsView />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.skills,
          path: '/skills',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.SKILLS]}>
              <SkillsSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.tasks,
          path: '/tasks',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.TASKS]}>
              <TasksSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.personas,
          path: '/personas',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.PERSONAS]}>
              <PersonaSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: !options?.advancedLeave ? strings.settings.pages.standardLeave : strings.settings.pages.advancedLeave,
          path: '/advancedLeave',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.LEAVE]}>
              <LeaveSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.organisationChart,
          path: '/organisationChart',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.ORGANISATION_CHART]}>
              <OrganisationChartPage />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        }
      ]
    },
    {
      name: strings.layout.headerLinks.location,
      path: '/location',
      element: (
        <SettingsPage hideDefaultSaveFor={[SettingsSubpage.STAFF]}>
          <AdminStaffSettings />
        </SettingsPage>
      ),
      roles: [RoleType.Admin, RoleType.Super_Admin],
      rolesExcept: [],
      permissions: [],
      subPages: [
        {
          name: strings.settings.pages.manageStaff,
          path: '/staff',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.STAFF]}>
              <AdminStaffSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.locations.label,
          path: '/manageLocations',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.MANAGE_LOCATIONS]}>
              <LocationSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        },
        {
          name: strings.settings.pages.userAccounts,
          path: '/accounts',
          element: (
            <SettingsPage hideDefaultSaveFor={[SettingsSubpage.ACCOUNTS]}>
              <UserAccountsSettings />
            </SettingsPage>
          ),
          roles: [],
          rolesExcept: [],
          permissions: [],
          subPages: []
        }
      ]
    }
  ];

  if (options?.userRole) {
    return navBarOptions.filter(
      opt => opt.roles.includes(options!.userRole!) || opt.permissions.includes(options!.userRole!)
    );
  }

  return navBarOptions;
};

export const getNavBarRoutes = (): RouteElement[] => {
  const routesArr: any = [];

  getNavBarOptions().forEach(opt => {
    if (opt.path) {
      routesArr.push({ path: opt.path, element: opt.element, roles: opt.roles });
    }
    if (opt.subPages?.length) {
      opt.subPages.forEach(subp => {
        if (subp.path) {
          routesArr.push({ path: `${opt.path}${subp.path}`, element: subp.element, roles: subp.roles });
        }
      });
    }
  });

  return routesArr;
};

export const useSubPages = (mainRoute: string): NavBarElement[] => {
  return useNavBarOptions().find(opt => opt.path === mainRoute)?.subPages || [];
};
