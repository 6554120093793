import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  children?: ReactNode;
  className?: string;
  left?: number;
  hidden?: boolean;
  staff: StaffWithSettings;
}

export const StaffRowCell: FC<Props> = ({ children, className, left, hidden, staff }) => {
  const baseClass = 'bg-white border border-border px-2 py-2 flex items-center text-xs';
  const bgClass = staff.settings.toBeDeleted ? 'bg-background-mid' : 'bg-white';
  const hiddenClass = hidden ? 'hidden' : '';

  return (
    <div
      className={twMerge(baseClass, bgClass, className, left !== undefined ? 'sticky' : '', hiddenClass)}
      style={{ left }}
    >
      {children}
    </div>
  );
};
