import { useCallback } from 'react';
import { usePost } from '../usePost';

export interface FileToUpload {
  formName: string;
  file?: any;
}

export const usePostFiles = (
  successCallback?: (response?: unknown) => void,
  errorCallback?: (message: string) => void
) => {
  const { post, ...mutation } = usePost(successCallback, errorCallback);

  const postFiles = useCallback(
    (files: FileToUpload[], endpoint: string) => {
      const formData = new FormData();
      files.forEach(f => formData.append(f.formName, f.file));
      post(formData, endpoint);
    },
    [mutation]
  );

  return {
    postFiles,
    ...mutation
  };
};
