import { useTimeSlots } from '@/components/gantt/gantt/ganttHelper';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useCallback } from 'react';
import { WorkingShift } from './daily.types';
import { useTasks } from '@/services/tasks/useTasks';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';

export const useBuildHourSummary = (includeUnassigned?: boolean) => {
  const includeBreaks = useSystemSettings(state => state.settings.includeBreaksInTotals);
  const tasks = useTasks();
  const selectedLocation = useLocalSettings(s => s.selectedLocationId);

  return useCallback(
    (shifts: WorkingShift[], start: number, end: number) => {
      const assignedShifts = shifts.filter((shift: any) => shift.assignedEmployeeId && shift.assignedStaffHomeLocationId === selectedLocation);
      const shiftsToUse = includeUnassigned ? shifts : assignedShifts;
      const halfHourlySlots = useTimeSlots(start, end, 30 * 60);

      return halfHourlySlots.map(slot => {
        return shiftsToUse.reduce((prevTotal, shift) => {
          // Shift times not defined.
          if (!shift.start || !shift.end) {
            return prevTotal;
          }

          // Shift doesn't cover half hour slot.
          if (shift.start >= slot.end || shift.end <= slot.start) {
            return prevTotal;
          }

          let timeToCount = 30 * 60;

          if (slot.start < shift.start && shift.start < slot.end) {
            // Shift starts part way through the slot- only need to add the last bit of the slot.
            timeToCount = slot.end - shift.start;
          }

          if (slot.start < shift.end && shift.end < slot.end) {
            // Shift ends part way through the slot- only need to add the first bit of the slot.
            timeToCount = shift.end - slot.start;
          }

          if (!includeBreaks) {
            // Shift overlaps a break.
            shift.breaks.forEach(brek => {
              if (brek.start <= slot.start && slot.end <= brek.end) {
                // Break completely overlaps slot- don't need to add anything to the total.
                timeToCount = 0;
              } else if (slot.start < brek.start && brek.start < slot.end) {
                // The start of the break lies in the slot, so only add the bit before the break start.
                timeToCount = brek.start - slot.start;
              } else if (slot.start < brek.end && brek.end < slot.end) {
                // The end of the break lies in the slot, so only add the bit after the break ends.
                timeToCount = slot.end - brek.end;
              }
            });
          }

          // find the task that fits the slot
          if (shift.tasks) {
            // find all tasks that overlap the slot
            const tasksInSlot = shift.tasks.filter(task => {
              return task.start < slot.end && task.end > slot.start;
            });

            // for each tasksInSlot, find from tasks that isInCalculatedWorkingHours is false
            const tasksToCount = tasksInSlot.filter(task => {
              return tasks && tasks.find(t => t.id === task.typeContext && !t.isInCalculatedWorkingHours);
            });

            // get the total time of the tasksToCount
            const taskTime = tasksToCount.reduce((prev, task) => {
              const taskStart = Math.max(task.start, slot.start);
              const taskEnd = Math.min(task.end, slot.end);

              return prev + (taskEnd - taskStart);
            }, 0);

            // add the task time to the minutesToCount
            timeToCount -= taskTime
          }

          const countToAdd = timeToCount / (30 * 60);

          return prevTotal + countToAdd;
        }, 0);
      });
    },
    [includeBreaks, includeUnassigned, tasks]
  );
};

export const useHourSummary = (shifts: WorkingShift[], start: number, end: number, includeUnassigned?: boolean) => {
  const buildHourSummary = useBuildHourSummary(includeUnassigned);

  return buildHourSummary(shifts, start, end);
};
