import { useMemo } from 'react';
import { ReadEmployee, useGetEmployeesForLocationQuery } from '../gql/graphql.generated';

export const useGetHomeEmployees = (locationId: number) => {
  const [{ data }, refetch] = useGetEmployeesForLocationQuery({ variables: { locationId: locationId } });
  const employees = useMemo(() => {
    return data?.employeesByLocation?.employees?.filter(employee => employee.homeLocationId === locationId);
  }, [data, locationId]);

  if (!employees) {
    throw Error('Could not get home employees');
  }

  return { data: employees as ReadEmployee[], refetch };
};
