import { iconArray } from '@/helpers/dropdownOptionHelper';
import { showToastMessage, sortSimpleObjectArray } from '@/helpers/helper';
import {
  CreateTask,
  ReadTask,
  UpdateTask,
  useCreateTasksMutation,
  useDeleteTasksMutation,
  useGetTasksQuery,
  useUpdateTasksMutation
} from '@/services/gql/graphql.generated';
import { TaskType } from '@/services/tasks/task.types';
import { useCallback, useMemo } from 'react';

export const useTasks = () => {
  const [{ data }] = useGetTasksQuery();

  const tasks = useMemo(() => {
    return data?.tasks ? (sortSimpleObjectArray(gqlToTasks(data.tasks as any), 'name') as TaskType[]) : [];
  }, [data?.tasks]);

  return tasks;
};

export const useCreateTasks = (): any => {
  const [responseData, create] = useCreateTasksMutation();

  const createTasks = useCallback(
    async (tasks: TaskType[]) => {
      const result = await create({ tasks: tasksToGQL(tasks) });
      showToastMessage(result);
    },
    [create]
  );

  return { fetching: responseData.fetching, create: createTasks };
};

export const useUpdateTasks = (): any => {
  const [responseData, update] = useUpdateTasksMutation();

  const updateTasks = useCallback(
    async (tasks: TaskType[]) => {
      const result = await update({ tasks: tasksToGQL(tasks, true) as UpdateTask[] });
      showToastMessage(result);
    },
    [update]
  );

  return { fetching: responseData.fetching, update: updateTasks };
};

export const useDeleteTasks = () => {
  const [{ fetching }, deleteTasksBulk] = useDeleteTasksMutation();

  const deleteTasks = useCallback(
    async (taskIds: number[]) => {
      const result = await deleteTasksBulk({ taskIds });
      showToastMessage(result);
      return result;
    },
    [deleteTasksBulk]
  );

  return { deleteTasks, fetching };
};

export const tasksToGQL = (tasks: TaskType[], isUpdate?: boolean): CreateTask[] | UpdateTask[] => {
  return tasks.map(t => {
    const taskProps = {
      displayName: t.displayName,
      icon: t.icon,
      textColor: t.textColor,
      color: t.color,
      defaultDuration: t.defaultDuration,
      isSupplementary: t.isSupplementary,
      isInMonthlyTotals: t.isInMonthlyTotals,
      isInCalculatedWorkingHours: t.isInCalculatedWorkingHours
    };

    if (!isUpdate) {
      return {
        name: t.name,
        active: t.isActive,
        sortOrder: 1,
        stationId: 1, // Should use real id
        skills: t.skills,
        optionalSkills: t.optionalSkills,
        properties: JSON.stringify(taskProps)
      };
    }

    return {
      id: parseInt(t.id),
      name: t.name,
      sortOrder: 1,
      stationId: 1, // Should use real id
      active: t.isActive,
      skills: t.skills,
      optionalSkills: t.optionalSkills,
      properties: JSON.stringify(taskProps)
    };
  });
};

export const gqlToTasks = (tasks: ReadTask[]): TaskType[] => {
  return tasks.map(t => {
    let props: any = {};
    if (t.properties) {
      props = JSON.parse(t.properties);
    }
    return {
      id: t.id.toString(),
      name: t.name,
      isActive: !!t.active,
      displayName: props.displayName,
      icon: props.icon,
      textColor: props.textColor,
      color: props.color,
      defaultDuration: parseInt(props.defaultDuration),
      isSupplementary: props.isSupplementary,
      isInMonthlyTotals: props.isInMonthlyTotals,
      isInCalculatedWorkingHours: props.isInCalculatedWorkingHours ?? true,
      skills: t.skills?.map(s => s.id) || [],
      optionalSkills: t.optionalSkills?.map(s => s.id) || []
    };
  });
};

export const useGetTask = () => {
  const taskList = useTasks();

  return useCallback(
    (taskId: string) => {
      const task = taskList.find(t => t.id == taskId);

      return task;
    },
    [taskList]
  );
};

export const useGetTaskIcon = () => {
  const taskList = useTasks();

  return useCallback(
    (taskId: string) => {
      const task = taskList.find(t => t.id == taskId);

      if (!task || !task.icon) {
        return undefined;
      }

      const iconOption = iconArray.find(opt => opt.id.toString() === task?.icon.toString());

      return iconOption?.value;
    },
    [taskList]
  );
};
