import { useActiveSkills } from '@/services/tasks/useSkills';
import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { TableColumnGroup } from '../manageStaff/staffTable/StaffTable';
import { useSkillStaffDetailSection } from './useStaffDetailSection';

export const skillsColour = '#F39803';

export const STAFF_SKILL_COLUMN_ID = 'skills';

export const useStaffSkillColumns = () => {
  const activeSkills = useActiveSkills();
  const skillColumns = useMemo(() => {
    return activeSkills.map(skill => ({
      name: skill.name,
      width: 100
    }));
  }, [activeSkills]);

  const staffDetailSection = useSkillStaffDetailSection();

  const groups: TableColumnGroup[] = useMemo(() => {
    return [
      staffDetailSection,
      {
        id: STAFF_SKILL_COLUMN_ID,
        columns: skillColumns,
        color: skillsColour,
        name: strings.settings.manageStaff.skills.headerLabel,
        isGroupHidden: false
      }
    ];
  }, []);

  return groups;
};
