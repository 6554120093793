import { SelectInput } from '@/components/form/SelectInput';
import { useGanttService } from '@/components/gantt/gantt/GanttProvider';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useOpenClosingTimeOptions } from '../settings/sections/manageSystem/useOpenClosingTimeOptions';

interface Props {
  openTime?: number;
  closeTime?: number;
  onChangeOpen: (start?: number) => void;
  onChangeClose: (end?: number) => void;
  readonly?: boolean;
}

export const OpenClosingTimePicker: FC<Props> = ({ openTime, closeTime, onChangeOpen, onChangeClose, readonly }) => {
  const { minTime, maxTime } = useGanttService(state => ({
    minTime: state.minTime,
    maxTime: state.maxTime
  }));

  const minMaxOptions = useOpenClosingTimeOptions(minTime, maxTime);

  const handleStartChange = (newStart?: number) => {
    onChangeOpen(newStart);
  };

  const handleEndChange = (newEnd?: number) => {
    onChangeClose(newEnd);
  };

  return (
    <div className="flex gap-2 text-xs">
      <div className="flex gap-2 items-center">
        <span>{strings.daily.changeOpenClosingTimes.openTime}</span>
        <SelectInput
          className="text-xs"
          options={minMaxOptions}
          value={openTime}
          setValue={newVal => handleStartChange(newVal === '--:--' ? undefined : parseInt(newVal))}
          readOnly={readonly}
        />
      </div>
      <div className="flex gap-2 items-center">
        <span>{strings.daily.changeOpenClosingTimes.closeTime}</span>
        <SelectInput
          className="text-xs"
          options={minMaxOptions}
          value={closeTime}
          setValue={newVal => handleEndChange(newVal === '--:--' ? undefined : parseInt(newVal))}
          readOnly={readonly}
        />
      </div>
    </div>
  );
};
