import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { StoreApi, createStore, useStore } from 'zustand';

export enum NotificationType {
  ROSTER_GENERATION = 'ROSTER_GENERATION',
  ROSTER_PUBLISH = 'ROSTER_PUBLISH',
  OTHER = 'OTHER'
}

export enum NotificationStatus {
  SUCCESS = 'Success',
  IN_PROGRESS = 'In progress',
  FAIL = 'Fail'
}

// I wanted to call this 'Notification' but it's already used by some common javascript-y stuff.
export interface NotificationItem<T = any> {
  regularText?: string;
  boldText?: string;
  colorizedText?: string;
  status?: NotificationStatus;
  type?: NotificationType;
  context?: T;
  id?: string;
  isRead?: boolean;
  date?: Date;
  locationId?: number;
}

export interface NotificationStore {
  notifications: NotificationItem[];
  filterNotifications: (locationId?: number) => NotificationItem[];
  createNotification: (newNotification: NotificationItem) => NotificationItem;
  updateNotification: (updateNotification: NotificationItem) => NotificationItem;
  markAsRead: (id: string) => void;
}

export const NotificationContext = createContext<StoreApi<NotificationStore> | null>(null);

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const store = useMemo(() => {
    return createStore<NotificationStore>()((set, get) => ({
      notifications: [],
      filterNotifications: (locationId?: number) => {
        if (!locationId) {
          return get().notifications.filter(n => !n.locationId);
        } else {
          return get().notifications.filter(n => !n.locationId || n.locationId === locationId);
        }
      },
      createNotification: (newNotification: NotificationItem) => {
        const newList = [...get().notifications];
        const notificationWithId = { ...newNotification, id: uuidv4() };

        newList.push(notificationWithId);
        set({ notifications: newList });

        return notificationWithId;
      },
      updateNotification: (updatedNotification: NotificationItem) => {
        if (!updatedNotification.id) {
          throw Error('Update notification should only be called for notifications with an attached ID');
        }

        const newList = [...get().notifications];

        const notificationIndex = newList.findIndex(n => n.id === updatedNotification.id);

        if (notificationIndex < 0) {
          throw Error("Attempting to update notification which doesn't exist");
        }

        updatedNotification.isRead = false;

        newList.splice(notificationIndex, 1, updatedNotification);

        let sortedList = newList.sort((a, b) => {
          if (!a.date || !b.date) {
            return 0;
          }

          if (a.date > b.date) {
            return -1;
          }

          if (b.date > a.date) {
            return 1;
          }

          return 0;
        });

        set({ notifications: sortedList });

        return updatedNotification;
      },
      markAsRead: (id: string) => {
        const newList = [...get().notifications];
        const notificationToRead = newList.find(n => n.id === id);

        if (notificationToRead) {
          notificationToRead.isRead = true;
          set({ notifications: newList });
        }
      }
    }));
  }, []);

  return <NotificationContext.Provider value={store}>{children}</NotificationContext.Provider>;
};

type SelectorReturn<S extends (s: NotificationStore) => any> = ReturnType<S>;
export function useNotificationService<S extends (s: NotificationStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return useStore(context, selector);
}
