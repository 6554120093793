import { TaskType } from '@/services/tasks/task.types';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { Tooltip, TooltipTypes } from '../../../components/tooltip/Tooltip';
import { BaseTaskTooltipContent } from './BaseTaskTooltipContent';
import { useEmployeeIdsWithMandatorySkillsForTask } from './useCapableEmployees';

interface TaskTooltipProps {
  taskId?: string;
  color: string;
}

interface TaskTooltipContentProps {
  task?: TaskType;
  color: string;
  capableEmployeeCount: number;
}

const TaskTooltipContent: FC<TaskTooltipContentProps> = ({ task, color, capableEmployeeCount }) => {
  return (
    <div className="font-regular space-y-2 text-black">
      <div className="flex justify-between gap-5">
        <div className="font-bold whitespace-nowrap" style={{ color }}>
          {task?.displayName}
        </div>
        <div className="flex items-center gap-1">
          <p>{strings.daily.drag.taskTooltips.capableStaffCountTemplate(capableEmployeeCount)}</p>
        </div>
      </div>
      <BaseTaskTooltipContent taskId={task?.id} />
    </div>
  );
};

export const TemplateTaskTooltip: FC<TaskTooltipProps> = ({ taskId, color }) => {
  const tasks = useTasks();
  const task = tasks.find(task => task.id == taskId);
  const employeeIdsWithMandatorySkillsForTask = useEmployeeIdsWithMandatorySkillsForTask(task);

  return (
    <Tooltip
      position="top"
      className="max-h-[500px] overflow-y-auto w-[400px]"
      type={employeeIdsWithMandatorySkillsForTask.length === 0 ? TooltipTypes.error : TooltipTypes.info}
    >
      <TaskTooltipContent
        color={color}
        task={task}
        capableEmployeeCount={employeeIdsWithMandatorySkillsForTask.length}
      />
    </Tooltip>
  );
};
