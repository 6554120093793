import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { NumberInput } from '@/components/form/NumberInput';
import { SelectInput, SelectOption } from '@/components/form/SelectInput';
import { ConfirmationModal, ConfirmationModalProps } from '@/components/modal/ConfirmationModal';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { useSuperAdminApi } from '@/services/settings/useSuperAdminApi';
import { strings } from '@/services/translation/strings';
import { FC, useMemo, useState } from 'react';
import { GiUnlitBomb } from 'react-icons/gi';
import { Setting } from '../../layout/Setting';
import { SettingsSection } from '../../layout/SettingsSection';
import { useEditSettingsService } from '../../services/EditSettingsService';
import { onChangeSettings } from '../../settingsHelper';

export const AdminSettings: FC = () => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>();

  const { settings, editSettings } = useEditSettingsService(state => ({
    settings: state.workingSystemSettings,
    editSettings: state.setWorkingSystemSettings
  }));
  const language = useLanguageProvider(state => state.language);
  const {
    deleteAllRosters,
    fetchingDeleteAllRosters,
    deleteAllTemplates,
    fetchingDeleteAllTemplates,
    deleteAllAvailability,
    fetchingDeleteAllAvailability,
    resetAllUsersPasswords,
    fetchingResetAllUsersPasswords
  } = useSuperAdminApi();

  const onChangeSetting = onChangeSettings(settings, editSettings);

  const incrementValues = [5, 10, 15, 30, 60];
  const incrementOptions: SelectOption[] = incrementValues.map(value => {
    return {
      name: strings.settings.admin.settings.minimumIncrement.minutes(value),
      value: value
    };
  });

  const buttonsWidthHeight = `${language === 'en' ? 'w-32' : 'w-44'} h-10 flex gap-2`;
  const openConfirmationModal = (header: string, message: string, onClose: () => void, onConfirm: () => void) => {
    const modalProps: ConfirmationModalProps = {
      confirmButtonText: strings.common.continue,
      header,
      onClose,
      message,
      onConfirm
    };
    setConfirmationModalProps(modalProps);
    setIsConfirmationModalOpen(true);
  };

  const onConfirmationModalConfirm = (executeMethod: () => void) => {
    setIsConfirmationModalOpen(false);
    executeMethod();
  };

  const templateMaxOptions = useMemo(() => {
    const options: SelectOption[] = [];

    for (let i = 1; i <= 30; i++) {
      options.push({ name: i.toString(), value: i });
    }

    return options;
  }, []);

  return (
    <div>
      <SettingsSection title={strings.settings.admin.title}>
        <Setting
          title={strings.settings.admin.settings.minimumIncrement.title}
          description={strings.settings.admin.settings.minimumIncrement.description}
        >
          <SelectInput
            options={incrementOptions}
            value={settings.minuteIncrement}
            setValue={onChangeSetting('minuteIncrement')}
          />
        </Setting>
        <Setting
          title={strings.settings.admin.settings.canDeleteRosters.title}
          description={strings.settings.admin.settings.canDeleteRosters.description}
        >
          <Toggle value={settings.canDeleteRosters} onChange={onChangeSetting('canDeleteRosters')} />
        </Setting>
        <Setting
          title={strings.settings.admin.settings.displayEmployeeIds.title}
          description={strings.settings.admin.settings.displayEmployeeIds.description}
        >
          <Toggle value={settings.displayEmployeeIds} onChange={onChangeSetting('displayEmployeeIds')} />
        </Setting>
        <Setting
          title={strings.settings.admin.settings.maxCustomTemplates.title}
          description={strings.settings.admin.settings.maxCustomTemplates.description}
        >
          <SelectInput
            value={settings.maxCustomTemplates}
            setValue={onChangeSetting('maxCustomTemplates')}
            options={templateMaxOptions}
          />
        </Setting>
      </SettingsSection>
      <SettingsSection
        title={strings.settings.admin.leaveManagement.title}
      >
        <Setting
          title={strings.settings.admin.leaveManagement.advanced.title}
          description={strings.settings.admin.leaveManagement.advanced.description}>
          <div className="flex gap-4">
            <Toggle value={settings.advancedLeaveManagement} onChange={onChangeSetting('advancedLeaveManagement')} />
            <div>{settings.advancedLeaveManagement ? strings.settings.admin.leaveManagement.advanced.labelOn : strings.settings.admin.leaveManagement.advanced.labelOff}</div>
          </div>
        </Setting>
      </SettingsSection>
      <SettingsSection
        title={strings.settings.admin.optimizer.title}
        additionalTitle={strings.settings.admin.useWithCaution}
        additionalTitleStyle={{ color: '#c64540', fontWeight: 'bold' }}
      >
        <Setting
          title={strings.settings.admin.optimizer.runtimeDuration.title}
          description={strings.settings.admin.optimizer.runtimeDuration.description}
        >
          <div className="h-full w-40">
            <NumberInput
              className="h-full text-lg"
              value={settings.optimizerDuration}
              setValue={onChangeSetting('optimizerDuration')}
            />
          </div>
        </Setting>
        <Setting
          title={strings.settings.admin.optimizer.management.title}
          description={strings.settings.admin.optimizer.management.description}
        >
          <div className="h-full w-40">
            <Toggle
              className="h-full text-lg"
              value={settings.optimizerManagementAttendance}
              onChange={onChangeSetting('optimizerManagementAttendance')}
            />
          </div>
        </Setting>
      </SettingsSection>
      <SettingsSection
        title={strings.settings.admin.dataCleanUp.title}
        additionalTitle={strings.settings.admin.useWithCaution}
        additionalTitleStyle={{ color: '#c64540', fontWeight: 'bold' }}
      >
        <Setting title={strings.settings.admin.dataCleanUp.deleteAllRosters.title} description={''}>
          <Button
            size="sm"
            variant={'destructive'}
            loading={fetchingDeleteAllRosters}
            onClick={() =>
              openConfirmationModal(
                strings.settings.admin.dataCleanUp.deleteAllRosters.confirmationHeader,
                strings.settings.admin.dataCleanUp.deleteAllRosters.confirmationMessage,
                () => setIsConfirmationModalOpen(false),
                () => onConfirmationModalConfirm(deleteAllRosters)
              )
            }
            className={buttonsWidthHeight}
            disabled={false}
          >
            <GiUnlitBomb />
            {strings.common.deleteAll}
          </Button>
        </Setting>
        <Setting
          title={strings.settings.admin.dataCleanUp.deleteAllTemplates.title}
          description={strings.settings.admin.dataCleanUp.deleteAllTemplates.subtitle}
          descriptionStyle={{ color: '#c64540' }}
        >
          <Button
            size="sm"
            variant={'destructive'}
            loading={fetchingDeleteAllTemplates}
            onClick={() =>
              openConfirmationModal(
                strings.settings.admin.dataCleanUp.deleteAllTemplates.confirmationHeader,
                strings.settings.admin.dataCleanUp.deleteAllTemplates.confirmationMessage,
                () => setIsConfirmationModalOpen(false),
                () => onConfirmationModalConfirm(deleteAllTemplates)
              )
            }
            className={buttonsWidthHeight}
            disabled={false}
          >
            <GiUnlitBomb />
            {strings.common.deleteAll}
          </Button>
        </Setting>
        <Setting title={strings.settings.admin.dataCleanUp.deleteAllAvailability.title} description={''}>
          <Button
            size="sm"
            variant={'destructive'}
            loading={fetchingDeleteAllAvailability}
            onClick={() =>
              openConfirmationModal(
                strings.settings.admin.dataCleanUp.deleteAllAvailability.confirmationHeader,
                strings.settings.admin.dataCleanUp.deleteAllAvailability.confirmationMessage,
                () => setIsConfirmationModalOpen(false),
                () => onConfirmationModalConfirm(deleteAllAvailability)
              )
            }
            className={buttonsWidthHeight}
            disabled={false}
          >
            <GiUnlitBomb />
            {strings.common.deleteAll}
          </Button>
        </Setting>
      </SettingsSection>
      <SettingsSection
        title={strings.settings.admin.userMaintenance.title}
        additionalTitle={strings.settings.admin.useWithCaution}
        additionalTitleStyle={{ color: '#c64540', fontWeight: 'bold' }}
      >
        <Setting title={strings.settings.admin.userMaintenance.resetAllPasswords.title} description={''}>
          <Button
            size="sm"
            variant={'primary'}
            loading={fetchingResetAllUsersPasswords}
            onClick={() =>
              openConfirmationModal(
                strings.settings.admin.userMaintenance.resetAllPasswords.confirmationHeader,
                strings.settings.admin.userMaintenance.resetAllPasswords.confirmationMessage,
                () => setIsConfirmationModalOpen(false),
                () => onConfirmationModalConfirm(resetAllUsersPasswords)
              )
            }
            className={buttonsWidthHeight}
            disabled={false}
          >
            <GiUnlitBomb />
            {strings.common.resetAll}
          </Button>
        </Setting>
      </SettingsSection>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        header={confirmationModalProps?.header!}
        onClose={confirmationModalProps?.onClose!}
        message={confirmationModalProps?.message!}
        onConfirm={confirmationModalProps?.onConfirm!}
        confirmButtonText={confirmationModalProps?.confirmButtonText}
      />
    </div>
  );
};
