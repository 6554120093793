import Divider from '@/components/Divider';
import { Button } from '@/components/button';
import { DropdownAction } from '@/components/menu/Action';
import { Dropdown, DropdownOrigin } from '@/components/menu/Dropdown';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { IoAdd } from 'react-icons/io5';
import { TemplateLibraryOption, useSelectedLibraryTemplate, useTemplateLibraryService } from './TemplateLibraryService';

interface Props {
  onAdd: () => void;
  onEdit: (option: TemplateLibraryOption) => void;
  onDelete: (option: TemplateLibraryOption) => void;
}

export const TemplateLibraryDropdown: FC<Props> = ({ onAdd, onEdit, onDelete }) => {
  const selectedTemplate = useSelectedLibraryTemplate();
  const templateList = useTemplateLibraryService(state => state.templateOptions);
  const setSelectedTemplateId = useTemplateLibraryService(state => state.setSelectedTemplateId);

  const onTemplateSelect = (template: any) => {
    setSelectedTemplateId(template.id);
  };

  return (
    <Dropdown
      origin={DropdownOrigin.TOP_RIGHT}
      button={
        <Button size="sm" className="px-2 w-96 relative">
          <div className="flex justify-between w-full items-center px-2">
            <span className="whitespace-nowrap">
              {selectedTemplate?.name || strings.daily.templates.myTemplates.label}
            </span>
            <div className="">
              <BsFillCaretDownFill className="text-grey-100" />
            </div>
          </div>
        </Button>
      }
      className="max-h-72 overflow-y-auto w-96"
    >
      {templateList?.length === 0 ? (
        <DropdownAction className="text-sm lg:text-sm text-primary font-base lg:font-light">
          <span>{strings.daily.templates.myTemplates.noTemplates}</span>
        </DropdownAction>
      ) : (
        <>
          {templateList?.map(template => (
            <DropdownAction
              key={template.id}
              onClick={() => onTemplateSelect(template)}
              className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light justify-between"
            >
              <span>{template.name}</span>
              <div className="flex gap-2 items-center">
                <div className="text-black font-semibold">{`v${template.template.version}`}</div>
                <Button
                  id={template.id}
                  size="sm"
                  className="px-2 gap-10  bg-white hover:bg-primary text-primary hover:text-white "
                  onClick={event => {
                    event.preventDefault();
                    onEdit(template);
                  }}
                >
                  <FiEdit />
                </Button>
                <Button
                  id={template.id}
                  size="sm"
                  className="px-2 gap-10 bg-white hover:bg-secondary text-secondary hover:text-white"
                  onClick={event => {
                    event.preventDefault();
                    onDelete(template);
                  }}
                  variant={'destructive'}
                >
                  <FiDelete />
                </Button>
              </div>
            </DropdownAction>
          ))}
        </>
      )}
      <Divider thickness={2} />
      <DropdownAction
        onClick={onAdd}
        className="flex items-center gap-2 text-sm lg:text-sm text-primary font-base lg:font-light"
      >
        <IoAdd />
        <span>{strings.daily.templates.myTemplates.createNewTemplate}</span>
      </DropdownAction>
    </Dropdown>
  );
};
