import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
interface Props {
  value: number;
  setValue: (newValue: number) => void;
  dataTabId?: string;
  className?: string;
}

export const NumberInput = React.forwardRef<HTMLInputElement, Props>(
  ({ value, setValue, dataTabId, className }, ref) => {
    const [inputValue, setInputValue] = useState<string>(value.toString());

    useEffect(() => {
      setInputValue(value.toString());
    }, [value, setInputValue]);

    const checkAndUpdateValue = (newInput: string) => {
      setInputValue(newInput);

      const setPattern = /^\-?[0-9]+$/;
      if (setPattern.test(newInput)) {
        const valueToSet = parseInt(newInput);
        if (valueToSet !== value) {
          setValue(valueToSet);
        }
      }
    };

    const onChange = (newVal: string) => {
      const enterPattern = /^\-?[0-9]*$/;
      if (!enterPattern.test(newVal)) {
        return;
      }

      checkAndUpdateValue(newVal);
    };

    const onBlur = () => {
      if (Number.isNaN(parseInt(inputValue))) {
        checkAndUpdateValue('0');
      }
    };

    return (
      <input
        ref={ref}
        data-tab-id={dataTabId}
        className={twMerge('p-0 w-full text-center rounded-md border-border text-xs', className)}
        value={inputValue}
        onChange={e => onChange(e.target.value)}
        type="text"
        onBlur={onBlur}
      ></input>
    );
  }
);
