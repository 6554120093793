import { objectDeepClone } from '@/helpers/helper';
import { useOrganisationHierarchy } from '@/services/hierarchy/useOrganisationHierarchy';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useMyLocations } from '@/services/useLocations';
import { TreeNode } from 'primereact/treenode';
import { TreeSelect } from 'primereact/treeselect';
import { FC, useMemo, useState } from 'react';

export interface Location {
  id: number;
  name: string;
}

export interface OrganisationHierarchy {
  divisionId: string;
  level: number;
  name: string;
  locations: Location[];
  children: OrganisationHierarchy[];
}

interface Props {
  isMultiSelector?: boolean;
}

export const LocationSelector: FC<Props> = ({ isMultiSelector }) => {
  const { selectedLocationId, setSelectedLocationId } = useLocalSettings(state => ({
    selectedLocationId: state.selectedLocationId,
    setSelectedLocationId: state.setSelectedLocationId
  }));
  const { viewableLocationIds, setViewableLocationIds } = useLocalSettings(state => ({
    viewableLocationIds: state.viewableLocationIds,
    setViewableLocationIds: state.setViewableLocationIds
  }));

  const [selectedNodeKeys, setSelectedNodeKeys] = useState(isMultiSelector ? {} : '');
  const [expandedKeys, setExpandedKeys] = useState({});
  const locations = useMyLocations();
  const orgHierarchy = useOrganisationHierarchy();

  const onNodeSelect = (e: any) => {
    const node = e.node;

    if (node?.data) {
      if (isMultiSelector) {
        const newKeys = { ...selectedNodeKeys } as any;
        const newLocations = new Map();
        viewableLocationIds.forEach(l => {
          newLocations.set(l, true);
        });

        const keysOnly = Object.keys(newKeys);
        if (keysOnly.includes(node.key)) {
          if (keysOnly.length > 1) {
            delete newKeys[node.key];
            newLocations.delete(node.data.id);
          }
        } else {
          newKeys[node.key] = true;
          newLocations.set(node.data.id, true);
        }

        const newViewKeys = Array.from(newLocations.keys());
        setViewableLocationIds(newViewKeys);
        setSelectedNodeKeys(newKeys);
      } else {
        setSelectedLocationId(node.data.id);
        setSelectedNodeKeys(node.key);
      }
    }
  };

  const getTreeNode = (
    hierarchy: OrganisationHierarchy,
    parentLevel: string = '',
    startingIndex: number = 0,
    selectedLocationId: number
  ): TreeNode => {
    const children = [];
    const hasChildren = hierarchy?.children?.length;
    const currentLevel = `${parentLevel ? parentLevel + '-' + startingIndex : '0'}`;
    if (hasChildren) {
      children.push(...hierarchy.children.map((c, i) => getTreeNode(c, currentLevel, i, selectedLocationId)));
    }

    if (hierarchy?.locations?.length) {
      children.push(...extractLocations(hierarchy.locations, currentLevel, !!hasChildren, selectedLocationId));
    }

    // TreeNode params
    // {
    //   id: undefined,
    //   key: organisationHierarchy.level,
    //   label: '',
    //   data: undefined,
    //   icon: undefined,
    //   children: [],
    //   style: undefined,
    //   className: '',
    //   droppable: false,
    //   draggable: false,
    //   selectable: true,
    //   leaf: false,
    //   expanded: true
    // }

    return {
      key: currentLevel,
      label: hierarchy?.name,
      selectable: false,
      expanded: false,
      children: children as any
    };
  };

  const extractLocations = (
    locationsToTreeNodes: Location[],
    level: string,
    hasChildren: boolean,
    selectedLocationId?: number
  ): TreeNode[] => {
    return locationsToTreeNodes.map((l, index) => {
      const key = `${level}-${hasChildren ? index + 1 : index}`;

      if (isMultiSelector) {
        if (viewableLocationIds.includes(l.id)) {
          const keys = { ...selectedNodeKeys } as any;
          keys[key] = true;
          setSelectedNodeKeys(keys);
          expandNodes(key);
        }
      } else {
        if (selectedLocationId === l.id) {
          setSelectedNodeKeys(key);
          expandNodes(key);
        }
      }
      return {
        key,
        label: `${l.id} - ${l.name}`,
        data: l
      };
    });
  };

  const expandNodes = (selectedKey: string) => {
    let keys = {};
    if (!selectedKey?.length) {
    } else if (selectedKey.length === 1) {
      setExpandedKeys({ selectedKey: true });
    } else {
      let keysToExpand = new Map();
      const levels = selectedKey.split('-');
      let nodeAt = levels[0];
      for (let index = 0; index < levels.length - 1; index++) {
        keysToExpand.set(nodeAt, true);
        nodeAt += `-${levels[index + 1]}`;
      }

      keys = Object.fromEntries(keysToExpand);
    }
    setExpandedKeys(keys);
  };

  const filterLocations = (locationsArray: Location[], requiredIds: number[]) => {
    return locationsArray?.filter(l => requiredIds.includes(l.id)) || [];
  };

  const initialOrgLocationsFilter = (accumulator: any, item: any, matchingFunction: Function) => {
    item.children = (item.children || []).reduce(
      (a: any, i: any) => initialOrgLocationsFilter(a, i, matchingFunction),
      []
    );
    item.locations = matchingFunction(item);

    if (!item.children.length) delete item.children;
    if (!item.locations.length) delete item.locations;

    if (item?.locations || item?.children) {
      accumulator.push(item);
    }
    return accumulator;
  };

  const initialLocationsFilter = (organisationLocations: OrganisationHierarchy[], requiredLocationsIds: number[]) => {
    return organisationLocations.reduce(
      (a, i) =>
        initialOrgLocationsFilter(a, i, (hierarchy: OrganisationHierarchy) =>
          filterLocations(hierarchy.locations, requiredLocationsIds)
        ),
      []
    );
  };

  const nodes = useMemo(() => {
    const requiredLocationsIds = locations.map(l => l.id);
    const hierarchyToArray = [objectDeepClone(orgHierarchy)] as OrganisationHierarchy[];
    const filteredLocations = initialLocationsFilter(hierarchyToArray, requiredLocationsIds);
    const availableLocationsToSelect = filteredLocations.map((item: any) =>
      getTreeNode(item as any, '', 0, selectedLocationId)
    );
    return availableLocationsToSelect;
  }, [orgHierarchy, locations]);

  return (
    <TreeSelect
      value={selectedNodeKeys}
      onNodeSelect={onNodeSelect}
      onNodeUnselect={onNodeSelect}
      options={nodes}
      metaKeySelection={false}
      className="w-[22rem]"
      selectionMode={isMultiSelector ? 'multiple' : 'single'}
      placeholder="Select Items"
      expandedKeys={expandedKeys}
      filter
    ></TreeSelect>
  );
};
