import React, { FC, PropsWithChildren } from 'react';
import { PdfStaffSkillsProps } from './useStaffSkillsPrintProps';

interface Props extends PropsWithChildren {
  staffSkillPdfProps: PdfStaffSkillsProps;
}

export const PdfStaffSkillsContext = React.createContext<PdfStaffSkillsProps>({
  staffSettings: [],
  locations: [],
  skills: []
});

export const PdfStaffSkillsProvider: FC<Props> = ({ staffSkillPdfProps, children }) => {
  return <PdfStaffSkillsContext.Provider value={staffSkillPdfProps}>{children}</PdfStaffSkillsContext.Provider>;
};
