import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  miniMode?: boolean;
}

export const SummaryContentContainer: FC<Props> = ({ children, miniMode }) => {
  return (
    <div
      style={{ fontSize: miniMode ? '8px' : '12px' }}
      className="flex-1 bg-on rounded-sm flex flex-col items-center justify-center"
    >
      {children}
    </div>
  );
};
