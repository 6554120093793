import { dateToApiFormat } from '@/helpers/dateHelper';
import { Shifts, useGetDayShiftsQuery } from '@/services/gql/graphql.generated';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { useMemo } from 'react';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useRosterAllEmployeesSummary } from '../daily/roster/providers/WorkingRosterService';

export const useGetDayShiftsOfStaffFromOtherShops = (
  date: Date,
  selectedLocationId: number,
  summary: SummaryEmployee[]
) => {
  const employeesFromOtherShops = summary.filter(
    e => !!e.details.homeLocationId && e.details.homeLocationId !== selectedLocationId
  );
  const identityIdsFromOtherShops: string[] = [];
  employeesFromOtherShops.forEach(e => {
    if (!identityIdsFromOtherShops.find(id => e.details.identityId === id)) {
      identityIdsFromOtherShops.push(e.details.identityId!);
    }
  });

  const [{ data }] = useGetDayShiftsQuery({
    variables: {
      dateAndIdentityIds: {
        date: dateToApiFormat(date),
        identityIds: identityIdsFromOtherShops
      }
    },
    requestPolicy: 'cache-and-network'
  });

  return useMemo(() => {
    data?.dayShifts?.forEach(day => {
      day.shifts = day.shifts?.filter(s => s.location?.id !== selectedLocationId);
    });

    return data?.dayShifts?.filter(d => d.shifts?.length !== undefined && d.shifts.length > 0);
  }, [data]);
};

export const useOtherShopShiftsForCurrentRoster = () => {
  const summary = useRosterAllEmployeesSummary();
  const { selectedDate: selectedDateString } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));
  const selectedDate = useMemo(() => new Date(selectedDateString), [selectedDateString]);

  const selectedLocationId = useSelectedLocation().id;
  const shifts = useGetDayShiftsOfStaffFromOtherShops(selectedDate, selectedLocationId, summary);

  return (shifts as Shifts[]) || [];
};
