import { OrganisationNameType } from '@/services/settings/systemSettings.types';
import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';

export const useOrgNameOptions = () => {
  return useMemo(() => {
    return [
      { name: strings.settings.system.orgName.options.company, value: OrganisationNameType.COMPANY },
      { name: strings.settings.system.orgName.options.division, value: OrganisationNameType.DIVISION },
      { name: strings.settings.system.orgName.options.brand, value: OrganisationNameType.BRAND },
      { name: strings.settings.system.orgName.options.custom, value: OrganisationNameType.CUSTOM }
    ];
  }, []);
};
