import { FC, useState } from 'react';
import { StaffRowCell } from './StaffRowCell';
import { bgWorkPatternColour } from './StaffRow';
import { DurationInput } from '@/components/form/DurationInput';
import { StaffWithSettings, WorkingPatterns } from '@/services/settings/systemSettings.types';
import { hoursToSeconds } from 'date-fns';

interface Props {
  staff: StaffWithSettings;
  pattern: WorkingPatterns;
  hidden?: boolean;
  setTimes: (startTime: number, endTime: number) => void;
}

export const StaffRowPatternSectionDay: FC<Props> = ({ hidden, pattern, setTimes, staff }) => {
  const startTime = pattern.startTime;
  const endTime = pattern.endTime;
  const available = endTime !== 0 || startTime !== 0;

  const [lastStartTime, setLastStartTime] = useState<number>(startTime || hoursToSeconds(9));
  const [lastEndTime, setLastEndTime] = useState<number>(endTime || hoursToSeconds(17));

  const onToggleCheckbox = () => {
    if (available) {
      setLastStartTime(startTime);
      setLastEndTime(endTime);
      setTimes(0, 0);
    } else {
      setTimes(lastStartTime, lastEndTime);
    }
  };

  return (
    <StaffRowCell staff={staff} hidden={hidden}>
      <div className="grid grid-cols-7 gap-3 items-center mr-2">
        <div className="col-span-1">
          <div className="grid grid-cols-1">
            <input
              type="checkbox"
              checked={available}
              className={`col-span-1 text-center rounded-sm p-2`}
              style={{ color: `${available ? `${bgWorkPatternColour}` : ''}` }}
              onChange={onToggleCheckbox}
            />
          </div>
        </div>
        <div className="col-span-6">
          <div className="grid grid-cols-7">
            <div className="col-span-7">
              <div className="grid grid-rows-1">
                <div className="grid grid-cols-7">
                  <DurationInput
                    className="text-xs p-2 col-span-3"
                    style={{ backgroundColor: !available ? `${bgWorkPatternColour}` : '' }}
                    value={startTime}
                    setValue={value => {
                      setTimes(value, endTime);
                    }}
                    maxValue={hoursToSeconds(36)}
                  />
                  <div className="text-xs flex items-center justify-center">~</div>
                  <DurationInput
                    className="text-xs p-2 col-span-3"
                    style={{ backgroundColor: !available ? `${bgWorkPatternColour}` : '' }}
                    value={endTime}
                    setValue={value => {
                      setTimes(startTime, value);
                    }}
                    maxValue={hoursToSeconds(36)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StaffRowCell>
  );
};
