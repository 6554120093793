import { ReadTemplate } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { WorkingTemplate } from '../template.types';
import { useWorkingTemplateToGqlTemplate } from '../templateHelper';
import { useSelectedTemplate } from './TemplateService';

export const useGetUpdateTemplateFromWorkingTemplate = (wipeIds: boolean = false) => {
  const workingTemplateToGqlTemplate = useWorkingTemplateToGqlTemplate();
  const selectedTemplate = useSelectedTemplate();

  return useCallback(
    (workingTemplate: WorkingTemplate) => {
      if (!selectedTemplate) throw new Error('Missing selected template');

      return workingTemplateToGqlTemplate(workingTemplate, selectedTemplate as ReadTemplate, false, wipeIds);
    },
    [selectedTemplate, workingTemplateToGqlTemplate]
  );
};
