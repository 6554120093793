import { dateToApiFormat } from '@/helpers/dateHelper';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { UpdateTargetByValue, useUpdateTargetsMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { FORECAST_TARGET_TYPE, SALES_DAY_TYPE } from '../../services/budget/budget.types';
import { useBudgetService } from './BudgetService';

export const useSaveBudget = () => {
  const locations = useBudgetService(state => state.locations);
  const [{ fetching, error }, update] = useUpdateTargetsMutation();
  const { unregisterForConfirmation } = useConfirm(state => ({ unregisterForConfirmation: state.unregisterForConfirmation}));

  const saveBudget = useCallback(async () => {
    const targetsToUpdate: UpdateTargetByValue[] = [];
    locations.forEach(location => {
      location.days.forEach(day => {
        targetsToUpdate.push({
          locationId: location.id,
          targetType: FORECAST_TARGET_TYPE,
          value: day.locationForecast / (60 * 60),
          date: dateToApiFormat(day.date)
        });

        targetsToUpdate.push({
          locationId: location.id,
          targetType: SALES_DAY_TYPE,
          value: day.financialTarget,
          date: dateToApiFormat(day.date)
        });
      });
    });
    const result = await update({
      targets: targetsToUpdate
    });

    unregisterForConfirmation();

    return result;
  }, [update, locations]);

  return { saveBudget, error, fetching };
};
