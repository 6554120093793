import { FC } from 'react';
import { usePublishRosters } from './usePublishRosters';
import { strings } from '@/services/translation/strings';
import { Button } from '@/components/button';
import { FiCheck, FiX } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';

interface Props {
  onComplete: () => void;
  rosterIds: string[];
  published?: boolean;
  disabled?: boolean;
}

export const PublishRostersButton: FC<Props> = ({ onComplete, rosterIds, published, disabled }) => {
  const { publishRosters, fetching } = usePublishRosters();
  const daysInMonth = useDaysInCurrentMonth();

  const onPublish = async () => {
    const result = await publishRosters(rosterIds, daysInMonth[0], daysInMonth[daysInMonth.length - 1]);
    onComplete();

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiX className="h-8 w-8" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-right',
        className: 'text-xl',
        icon: <FiCheck className="h-8 w-8" />
      });
    }
  };

  if (published) {
    return (
      <Button variant={'submit'} className="space-x-2">
        <FiCheck className="h-6 w-6" />
        <p>{strings.daily.roster.published}</p>
      </Button>
    );
  }

  return (
    <>
      <Button onClick={onPublish} loading={fetching} variant={disabled ? 'default' : 'primary'} disabled={disabled}>
        {strings.daily.roster.publish}
      </Button>
    </>
  );
};
