import { FC, KeyboardEventHandler, useRef, useState } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { TextInput } from '@/components/form/TextInput';
import { strings } from '@/services/translation/strings';
import { Button } from '@/components/button';
import { useCreateStaff } from '../../services/useCreateStaff';
import { toastSuccess } from '@/helpers/helper';

export const StaffSettingsAddCasual: FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const { create, fetching } = useCreateStaff();

  const lastNameRef = useRef<HTMLInputElement>(null);

  const onAdd = async () => {
    const result = await create(firstName, lastName);
    if (!result.error) {
      toastSuccess();
      setFirstName('');
      setLastName('');
      lastNameRef.current?.focus();
    }
  };

  const canAdd = firstName && lastName;

  const onKeyDown: KeyboardEventHandler<HTMLElement> = event => {
    if (event.key === 'Enter') {
      if (canAdd) {
        onAdd();
      }
    }
  };

  return (
    <SettingsSectionHeader hasBottomBorder={true}>
      <div className="flex items-center gap-2">
        <TextInput
          ref={lastNameRef}
          className="w-54 h-10"
          value={lastName}
          onChange={setLastName}
          placeholder={strings.settings.manageStaff.addCasual.lastName}
          onKeyDown={onKeyDown}
        />
        <TextInput
          className="w-54 h-10"
          value={firstName}
          onChange={setFirstName}
          placeholder={strings.settings.manageStaff.addCasual.firstName}
          onKeyDown={onKeyDown}
        />
        <Button onClick={onAdd} loading={fetching} variant={canAdd ? 'submit' : undefined} disabled={!canAdd}>
          {strings.settings.manageStaff.addCasual.add}
        </Button>
      </div>
    </SettingsSectionHeader>
  );
};
