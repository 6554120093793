import { useSubsections } from '@/pages/daily/useSubsections';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { useTimeSlots } from './ganttHelper';

export interface SubsectionConfig {
  id: string;
  defaultLength: number;
  // Minimum time needed before/after the subsection
  startBuffer?: number;
  endBuffer?: number;
  minLength?: number;
  maxLength?: number;
}

interface GanttStore {
  minTime: number;
  maxTime: number;
  increment: number;
  subsections?: SubsectionConfig[];
  defaultMainLength: number;
  minMainLength: number;
  currentlyDragging?: string;
  setCurrentlyDragging: (newVal?: string) => void;
  markerLines?: GanttMarkerLine[];
}

export interface GanttMarkerLine {
  time: number;
  color: string;
}

interface Props extends PropsWithChildren {
  minTime: number;
  maxTime: number;
  minuteIncrement?: number;
  subsections?: SubsectionConfig[];
  defaultMainLength?: number;
  minMainLength?: number;
  markerLines?: GanttMarkerLine[];
}

const GanttContext = createContext<StoreApi<GanttStore> | null>(null);

export const DEFAULT_MINUTE_INCREMENT = 15;
export const DEFAULT_MAIN_LENGTH_IN_MINUTES = 120;

export const GanttProvider: FC<Props> = ({
  children,
  subsections,
  minTime,
  maxTime,
  minuteIncrement = DEFAULT_MINUTE_INCREMENT,
  defaultMainLength: defaultMainLengthInMinutes = DEFAULT_MAIN_LENGTH_IN_MINUTES * 60,
  minMainLength: minMainLengthInMinutes = 0,
  markerLines
}) => {
  const store = useMemo(() => {
    return createStore<GanttStore>((set) => ({
      minTime,
      maxTime,
      increment: minuteIncrement * 60,
      subsections,
      defaultMainLength: defaultMainLengthInMinutes,
      markerLines,
      minMainLength: minMainLengthInMinutes,
      setCurrentlyDragging: (newVal?: string) => {
        set(state => ({ ...state, currentlyDragging: newVal }));
      }
    }));
  }, [minTime, maxTime, minuteIncrement, subsections, markerLines]);

  return <GanttContext.Provider value={store}>{children}</GanttContext.Provider>;
};

type SelectorReturn<S extends (s: GanttStore) => any> = ReturnType<S>;

export function useGanttService<S extends (s: GanttStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(GanttContext);
  if (!context) {
    throw new Error('useGanttService must be used within a GanttProvider');
  }
  return useStore(context, selector);
}

export const useGanttTimeSlots = () => {
  const { minTime, maxTime, increment } = useGanttService(state => ({
    minTime: state.minTime,
    maxTime: state.maxTime,
    increment: state.increment
  }));

  return useMemo(() => useTimeSlots(minTime, maxTime, increment), [minTime, maxTime, increment]);
};

export const useGanttSubsectionConfig = () => {
  const subsections = useSubsections();

  return useCallback((id?: string) => {
    return subsections.find(sub => sub.id === id);
  }, [subsections])
}