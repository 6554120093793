import { useGetAvailabilityString } from '@/services/availability/useAvailabilityTypes';
import { useLeaveType } from '@/services/leaveManagement/LeaveTypesProvider';
import { FC } from 'react';

interface Props {
  leaveTypeId?: string;
  fullName?: boolean;
}

export const AvailabilityIndicator: FC<Props> = ({ leaveTypeId, fullName }) => {
  const availabilityName = useGetAvailabilityString(leaveTypeId, fullName);
  const leaveType = useLeaveType(leaveTypeId);

  return (
    <div
      className="flex rounded-md p-1 h-4 justify-center items-center"
      style={{ border: 'none', color: 'black', backgroundColor: leaveType?.properties.color }}
    >
      <span className="text-[10px] whitespace-nowrap overflow-ellipsis max-w-[100px] overflow-hidden">{availabilityName}</span>
    </div>
  );
};
