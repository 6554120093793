export interface TimeSlot {
  start: number;
  end: number;
  width: number;
}

export const useTimeSlots = (startTime: number, endTime: number, secondsPerSlot: number, addBuffer?: boolean) => {
  const slots: TimeSlot[] = [];
  let currentStartTime = startTime;

  const addTimeSlot = (slotStart: number) => {
    const slotEnd = Math.min(slotStart + secondsPerSlot, endTime);

    slots.push({ start: slotStart, end: slotEnd, width: (slotEnd - slotStart) / secondsPerSlot });

    return slotEnd;
  };

  while (currentStartTime < endTime) {
    currentStartTime = addTimeSlot(currentStartTime);
  }

  if (addBuffer) {
    addTimeSlot(currentStartTime);
  }

  return slots;
};

export const findSlotIndex = (time: number, timeSlots: TimeSlot[], start: boolean) => {
  const exactSlot = timeSlots.findIndex(slot => {
    if (start && slot.start === time) {
      // If we're looking for a start time, and the current slot starts there, it's a match.
      return true;
    } else if (!start && slot.end === time) {
      // If we're looking for an end time, and the current slot ends there, it's a match.
      return true;
    } else if (slot.start < time && slot.end > time) {
      // If the time we're looking for lies in the current slot, it's right either way.
      return true;
    }

    return false;
  });

  if (exactSlot >= 0) {
    return exactSlot;
  }

  // We now know the time lies outside of the timeslot range.

  // We're going to make a big assumption here, which is that the timeslots have no gaps.

  // If this is the case, we can decide if the time is bigger or smaller than all the timeslots
  // by just looking at one of them.

  // If the time is outside of the slot range, we return either a very big or small value- this
  // means the bars will stretch off the edge of whatever container they're in.
  if (time < timeSlots[0].start) {
    return -1000;
  }

  if (time > timeSlots[0].end) {
    return timeSlots.length + 1000;
  }

  // This should have covered all the possible cases?
  throw Error('Unable to calculate timeslot');
};

export const getTimeSlotIndexInSlider = (slider: HTMLDivElement, event: MouseEvent, timeSlots: TimeSlot[]) => {
  /*
   * This function works out where your mouse is along the slider, and sets the
   * appropriate slot index.
   */
  const sliderRect = slider.getBoundingClientRect();

  if (!sliderRect) {
    return;
  }
  // X position of your mouse, relative to the screen.
  const mouseScreenX = event.clientX;

  // X position of the start of the slider, relative to the screen.
  const sliderStartX = sliderRect.left;

  // X position of your mouse, relative to the start of the slider.
  const mouseSliderX = mouseScreenX - sliderStartX;

  // Total width of the slider.
  const sliderWidth = sliderRect.width;

  let newSlotIndex = -1;

  if (mouseSliderX <= 0) {
    //Can't go below 0.
    newSlotIndex = 0;
  } else if (mouseSliderX >= sliderWidth) {
    //Can't go past the end of the slider.
    newSlotIndex = timeSlots.length - 1;
  } else {
    // This is how wide a single slot is.
    const slotLength = sliderWidth / timeSlots.length;

    // How many slots along the slider your mouse is.
    const slotPosition = mouseSliderX / slotLength;
    newSlotIndex = Math.floor(slotPosition);
  }

  return newSlotIndex;
};

export const dateToMinutes = (date: Date) => {
  return date.getMinutes() + date.getHours() * 60;
};

export const displaySecondsAsTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const isExactHour = (time: number) => {
  const secondsInAnHour = 3600;

  if (time % secondsInAnHour === 0) return true;
  return false;
};
