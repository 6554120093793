import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { dateToApiFormat } from '@/helpers/dateHelper';
import { WorkingShift } from '@/pages/daily/daily.types';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useFindReassignedShiftsQuery, useFindShiftsQuery } from '@/services/gql/graphql.generated';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { useSiblingLocations } from '@/services/useSiblingLocations';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

interface ReassignmentStore {
  reassignedShifts: WorkingShift[];
  setReassignedShifts: (newShifts: WorkingShift[]) => void;
  refetchReassignedShifts: () => void;
  getReassignedEmployees: () => string[];
}

export const ReassignmentContext = createContext<StoreApi<ReassignmentStore> | null>(null);

export const ReassignmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));
  const locationId = useSelectedLocation()?.id;

  const searchParams = {
    homeLocationId: locationId,
    date: selectedDate ? dateToApiFormat(new Date(selectedDate)) : undefined
  };

  const [{ data, fetching: dataFetching }, refetchData] = useFindReassignedShiftsQuery({
    pause: searchParams.date === undefined,
    variables: { searchParams: searchParams },
    requestPolicy: 'network-only'
  });

  const siblingLocationIds = useSiblingLocations();

  // providingData - The shift is taken by the providing location, but no employee is assigned. The requesting flag is unset,
  //                 but providingLocationId is set instead.
  const [{ data: providingData, fetching: providingDataFetching }, refetchProvidingData] = useFindShiftsQuery({
    pause: searchParams.date === undefined,
    variables: {
      searchParams: {
        locationIds: siblingLocationIds,
        date: searchParams.date ? searchParams.date : undefined,
        propertyPath: 'providingLocationId',
        propertyValue: locationId.toString()
      }
    },
    requestPolicy: 'network-only'
  });

  const registerForConfirmation = useConfirm(state => state.registerForConfirmation);

  const store = useMemo(() => {
    const reassignedShifts: WorkingShift[] = [];
    if (data && providingData && !dataFetching && !providingDataFetching) {
      [...data.findReassignedShifts!, ...providingData.findShiftsByProperty!].forEach(location => {
        location.shifts?.forEach(s => {
          reassignedShifts.push({
            id: s.id,
            start: s.start,
            end: s.end,
            tasks:
              s.timeSlots?.map(t => ({
                start: t.start,
                end: t.end,
                id: t.id,
                type: ItemTypes.TASK,
                typeContext: JSON.parse(t.properties || '{}').context
              })) || [],
            breaks: s.breaks?.map(b => ({ start: b.start, end: b.end, id: b.id, type: ItemTypes.BREAK })) || [],
            properties: s.properties ? JSON.parse(s.properties) : undefined,
            assignedEmployeeId: s.employee?.identityId,
            assignedContractId: s.employee?.contract?.contractTypeId,
            assignedStaffHomeLocationId: s.employee?.homeLocationId,
            locationId: location.locationId
          });
        });
      });
    }

    return createStore<ReassignmentStore>()((set, get) => ({
      reassignedShifts: reassignedShifts,
      setReassignedShifts: reassignedShifts => {
        set({ reassignedShifts });
        registerForConfirmation(strings.daily.roster.unsaved);
      },
      refetchReassignedShifts: () => {
        refetchData();
        refetchProvidingData();
      },
      getReassignedEmployees: () => {
        return (
          get()
            ?.reassignedShifts.filter(s => s.assignedEmployeeId !== undefined)
            .map(s => s.assignedEmployeeId as string) ?? []
        );
      }
    }));
  }, [
    data,
    dataFetching,
    providingData,
    providingDataFetching,
    registerForConfirmation,
    refetchData,
    refetchProvidingData
  ]);

  return <ReassignmentContext.Provider value={store}>{children}</ReassignmentContext.Provider>;
};

type SelectorReturn<S extends (s: ReassignmentStore) => any> = ReturnType<S>;
export function useReassignmentService<S extends (s: ReassignmentStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(ReassignmentContext);
  if (!context) {
    throw new Error('useReassignment must be used within a ReassignmentProvider');
  }
  return useStore(context, selector);
}

export const isReasignedShift = (shift: WorkingShift) => {
  if (
    shift.assignedEmployeeId &&
    shift.assignedStaffHomeLocationId &&
    shift.locationId &&
    shift.locationId !== shift.assignedStaffHomeLocationId
  ) {
    return true;
  }

  if (
    shift.properties?.requesting || // requesting staff
    shift.properties?.providingLocationId
  ) {
    // accepted the request, and selecting a staff to send
    return true;
  }

  return false;
};
