import { WorkingShift } from "@/pages/daily/daily.types";
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC, Suspense, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { EmployeeTables } from './EmployeeTables';
import { EmployeeForShiftItem, EmployeeStatus } from './employeeForShift.types';
import { useBuildEmployeeSelectorDisplayName } from './useBuildEmployeeSelectorDisplayName';

export interface DropdownSettings {
  bottom?: number;
  top?: number;
  left: number;
  height: number;
}
interface Props {
  onSelect: (selectedOption?: EmployeeForShiftItem, isStaffRequesting?: boolean) => void;
  readOnly?: boolean;
  workingShifts?: WorkingShift[];
  shift: WorkingShift;
  waitingAllocation: boolean;
  employeeDropdownOptions: EmployeeForShiftItem[];
}

export const maxAllowedDropDownOptionsWithoutScroll = null;

export const EmployeeSelector: FC<Props> = ({
  onSelect,
  waitingAllocation,
  readOnly,
  workingShifts,
  shift,
  employeeDropdownOptions
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);

  const dropdownMenuId = `employee-dropdown-menu-${shift.id}`;

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      const dropdownElement = document.getElementById(dropdownMenuId);

      if (!dropdownElement) {
        return;
      }

      if (!dropdownElement.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setIsDropdownOpen, dropdownMenuId]);

  const handleOptionChange = (newOption?: EmployeeForShiftItem, isStaffRequesting?: boolean) => {
    if (newOption === undefined || newOption?.id === shift?.assignedEmployeeId) {
      onSelect(undefined, isStaffRequesting);
    } else {
      onSelect(newOption, isStaffRequesting);
    }

    setIsDropdownOpen(false);
  };

  const onSetAllocationRequest = () => {
    handleOptionChange(
      {
        id: '',
        employeeId: '',
        contractId: '',
        name: '',
        status: EmployeeStatus.AVAILABLE,
        role: '',
        timeAvailable: [],
        skills: [],
        taskMatch: []
      },
      true
    );
  };

  const dropdownId = `dropdown-id-${shift?.id}`;
  const getDropdownSettings = (options: any[], maxAllowedOptions: number | null): DropdownSettings => {
    const wrapperBoundaries = document.querySelector('#roster-shifts-wrapper')?.getBoundingClientRect();
    const optionsBoundaries = document.querySelector(`#${dropdownId}`)?.getBoundingClientRect();
    const wrapperBoundariesBottom = wrapperBoundaries?.bottom;
    const optionsStartingPoint = optionsBoundaries?.bottom;

    const minOptionsHeight = 88; // px
    const singleOptionHeight = 32;
    const offset = 10;

    const optionsToShow =
      (maxAllowedOptions || maxAllowedOptions === 0) && options.length > maxAllowedOptions
        ? maxAllowedOptions
        : options.length;
    const optionsHeight = singleOptionHeight * optionsToShow + offset;
    const totalDropdownHeight = minOptionsHeight + optionsHeight;

    const result: DropdownSettings = {
      height: optionsHeight,
      left: optionsBoundaries?.left || 0
    };

    if (wrapperBoundariesBottom && optionsStartingPoint) {
      const topDiff = optionsBoundaries.top - wrapperBoundaries.top;
      const bottomDiff = wrapperBoundaries.bottom - optionsStartingPoint;
      const maxPossibleHeight = Math.max(topDiff, bottomDiff);
      const acceptableHeight = Math.min(totalDropdownHeight, maxPossibleHeight);

      result.height = Math.floor(acceptableHeight);

      if (wrapperBoundariesBottom < optionsStartingPoint + acceptableHeight) {
        result.top = optionsBoundaries.top - result.height;
      } else {
        result.top = optionsBoundaries.bottom;
      }
    }

    return result;
  };

  const allocatedEmployee = shift.assignedEmployeeId
    ? employeeDropdownOptions.find(e => e.id === shift.assignedEmployeeId)
    : undefined;

  const nameToDisplay = useBuildEmployeeSelectorDisplayName(shift, workingShifts, allocatedEmployee, displayIds, readOnly);

  if (readOnly) {
    return <div className="flex items-center">{nameToDisplay}</div>;
  }

  if (employeeDropdownOptions.length === 1) {
    return <div className="text-xs border rounded-md p-2">{strings.daily.roster.noStaff}</div>;
  }

  return (
    <div id={dropdownId} className={`select-none relative`}>
      {waitingAllocation && !shift.assignedEmployeeId ? (
        <div className="flex justify-center items-center text-sm h-8 rounded-md py-1 leading-normal font-medium text-[#002742] bg-[#e8aa14] hover:cursor-not-allowed">
          {strings.daily.employeesDropdown.waitingAllocation}
        </div>
      ) : (
        <>
          <div
            className={`flex justify-between items-center border rounded-md pl-3 pr-2 py-1 h-8 hover:cursor-pointer ${
              isDropdownOpen ? 'outline outline-[#0070D2] outline-2' : ''
            } ${!shift.assignedEmployeeId ? 'bg-[#c64540] text-white' : ''}`}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className="flex flex-col">
              <div className="border-border rounded-md text-xs text-ellipsis overflow-hidden h-4 leading-normal">
                {/* TODO: Reassigned staff from other shops are not included in the employeeDropdownOptions array. So, the assignedStaffMemberId (GUID) is
                          displayed at the moment. */}
                {nameToDisplay}
              </div>
            </div>
            {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
          </div>
          {isDropdownOpen && (
            <Suspense>
              {
                /*
                 * Crazy bit! We ended up in z-index hell here with the locked header and column.
                 * The header needs a higher z-index than the rows so it's above them.
                 * But having a lower z-index on the rows meant the tooltips were going behind the header.
                 *
                 * The solution is to create a portal. Essentially, this is a bit of React tech that moves the
                 * dropdown in the DOM to the root. It can still be managed here, but in the DOM, it's much, much
                 * higher up.
                 *
                 * This means we can set a high z-index on the table, and not have it be capped by the row's index.
                 */
                createPortal(
                  <EmployeeTables
                    id={dropdownMenuId}
                    employeeDropdownOptions={employeeDropdownOptions}
                    shift={shift}
                    onChange={handleOptionChange}
                    onSetAllocationRequest={onSetAllocationRequest}
                    dropdownSettings={getDropdownSettings(
                      employeeDropdownOptions,
                      maxAllowedDropDownOptionsWithoutScroll
                    )}
                  />,
                  document.body
                )
              }
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};
