import { Tooltip } from '@/components/tooltip/Tooltip';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';

export const MonthlyPageTotalTooltip: FC = () => {
  return (
    <Tooltip className="bg-white">
      <div className="font-regular space-y-2 text-black">
        <span className="text-lg">{strings.summary.monthlyTotalTooltip.totals}</span>

        <div>
          <p>
            <span className="font-semibold">{strings.summary.monthlyTotalTooltip.employeeRosteredRequiredHeader}</span>{' '}
            {strings.summary.monthlyTotalTooltip.employeeRosteredRequired}
          </p>
        </div>
        <div>
          <p>
            <span className="font-semibold">{strings.summary.monthlyTotalTooltip.monthlyTotalsHeader}</span>{' '}
            {strings.summary.monthlyTotalTooltip.monthlyTotals}
          </p>
        </div>
      </div>
    </Tooltip>
  );
};
