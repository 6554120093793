import { clsx } from 'clsx';
import { FC, HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import GanttBarTooltip from './GanttBarTooltip';
import { GanttTaskCapabilityTooltip } from './GanttTimePicker';
import { Handle } from './Handle';

interface BarProps extends HTMLAttributes<HTMLDivElement> {
  color: string;
  slotCount: number;
  startSlot: number;
  endSlot: number;
  onStartHandleDrag?: (event: MouseEvent) => void;
  onEndHandleDrag?: (event: MouseEvent) => void;
  onDragBar?: (startEvent: MouseEvent) => (event: MouseEvent) => void;
  readOnly?: boolean;
  onDelete?: () => void;
  sub?: boolean;
  icon?: JSX.Element;
  iconColor?: string;
  tooltip?: string;
  resizeable?: boolean;
  ghost?: boolean;
  capabilityTooltip?: GanttTaskCapabilityTooltip;
}

const BAR_HEIGHT_PERCENT = 64;

export const Bar: FC<BarProps> = ({
  color,
  className,
  slotCount,
  startSlot,
  endSlot,
  onStartHandleDrag,
  onEndHandleDrag,
  onDragBar,
  onDelete,
  sub,
  readOnly,
  icon,
  iconColor = 'black',
  tooltip,
  capabilityTooltip,
  resizeable = true,
  ghost = false
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // How far along the container the bar should start.
  const startPercent = (startSlot / slotCount) * 100;

  // How much of the container the bar should take up.
  // NOTE: We need to add a 1 here, because both the start and end slot are included in the width.
  // eg. If the bar starts in slot 0, and ends in slot 2, it spans slot 0, 1 and 2, which is three slots.
  const lengthInSlots = endSlot - startSlot + 1;
  const widthPercent = (lengthInSlots / slotCount) * 100;
  const zeroLengthSlot = lengthInSlots === 0;

  const heightPercent = sub ? BAR_HEIGHT_PERCENT * 0.6 : BAR_HEIGHT_PERCENT
  const style = {
    left: `calc(${startPercent}% - ${zeroLengthSlot ? 4 : 0}px)`,
    width: !zeroLengthSlot ? `${widthPercent}%` : '12px',
    height: `${heightPercent}%`,
    top: `${(100 - heightPercent) / 2}%`,
    zIndex: zeroLengthSlot ? '50' : undefined,
    borderColor: color,
    cursor: 'pointer'
  };

  const onMouseUp = useCallback(
    (func: (event: MouseEvent) => void) => () => {
      window.removeEventListener('mousemove', func);
    },
    []
  );

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      if (!onDragBar) {
        return;
      }

      if (window.getSelection) {
        window.getSelection()?.removeAllRanges();
      }

      const newDragEvent = onDragBar(event);

      window.addEventListener('mousemove', newDragEvent);
      window.addEventListener('mouseup', onMouseUp(newDragEvent), { once: true });
    },
    [onDragBar, onMouseUp]
  );

  useEffect(() => {
    if (!onDragBar) {
      return;
    }

    const element = ref.current;

    element?.addEventListener('mousedown', onMouseDown);

    return () => {
      element?.removeEventListener('mousedown', onMouseDown);
    };
  }, [onMouseDown, onMouseUp, onDragBar, ref]);

  const leftAlignTooltips = startPercent < 7;

  return (
    <div
      style={style}
      className={clsx(
        className,
        'absolute flex group z-20',
        ghost ? 'border-4 border-dashed' : '',
        ghost && startSlot < 0 ? 'border-l-0' : '',
        ghost && endSlot > slotCount ? 'border-r-0' : ''
      )}
    >
      {!readOnly && resizeable && onStartHandleDrag && (
        <div
          className={clsx(
            'w-fit hidden group-hover:block',
            sub ? 'z-30' : 'rounded-sm h-full'
          )}
        >
          <Handle internal={startSlot === 0} start onDragHandle={onStartHandleDrag} sub={sub} />
        </div>
      )}
      <div
        ref={ref}
        style={{ background: color, borderColor: color, opacity: ghost ? 0.3 : undefined }}
        className={clsx(
          'flex z-20',
          sub ? 'border-l border-r' : 'rounded-sm h-full',
          zeroLengthSlot ? 'w-[4px] mx-auto' : 'w-full'
        )}
      >
        {zeroLengthSlot ? (
          <div style={{ color: 'black' }} className="absolute bottom-[-10px] left-[-7px] z-[1000]">
            {icon}
          </div>
        ) : (
          <div style={{ color: iconColor }} className="opacity-30 flex items-center justify-center w-full">
            {icon}
          </div>
        )}

        {capabilityTooltip && tooltip && (
          <GanttBarTooltip
            className={lengthInSlots <= 1 ? 'absolute top-[-15px]' : 'flex items-start'}
            capabilityTooltip={capabilityTooltip}
            taskTooltip={{
              text: tooltip,
              color,
              bgColor: iconColor
            }}
          />
        )}
      </div>
      {!readOnly && resizeable && onEndHandleDrag && (
        <div
          className={clsx(
            'w-fit hidden group-hover:block',
            sub ? 'z-30' : 'rounded-sm h-full'
          )}
        >
          <Handle onDragHandle={onEndHandleDrag} sub={sub} />
        </div>
      )}
      {onDelete && !readOnly && (
        <div
          className={`absolute bg-yellow-100 border border-black rounded-sm hidden group-hover:flex items-center z-50`}
          style={{
            whiteSpace: 'nowrap',
            right: leftAlignTooltips ? undefined : sub ? '-10px' : '-19px',
            left: leftAlignTooltips ? (sub ? '-10px' : '-19px') : undefined,
            top: sub ? '-0.7rem' : '-1rem'
          }}
        >
          {onDelete && (
            <button
              onMouseDown={event => {
                event.preventDefault();
                onDelete();
              }}
            >
              <FiX className="h-[0.88rem] w-[0.88rem] text-black opacity-70"></FiX>
            </button>
          )}
        </div>
      )}
      {tooltip && !capabilityTooltip && (
        <span
          className="select-none hidden group-hover:block text-xs rounded-md font-semibold px-1 absolute z-50 top-[-10px]"
          style={{
            background: color,
            color: iconColor,
            whiteSpace: 'nowrap',
            right: leftAlignTooltips ? undefined : '0.44rem',
            left: leftAlignTooltips ? '0.44rem' : undefined
          }}
        >
          {tooltip}
        </span>
      )}
    </div>
  );
};
