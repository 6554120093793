import { DateCellTooltipType } from '@/components/table/DateCellHeaders';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { useWorkingRoster } from '@/pages/daily/roster/providers/WorkingRosterService';
import { useGetTemplateById } from '@/pages/daily/template/shop/TemplateService';
import { useBudgetForLocation as useTooltipForLocation } from '@/services/budget/useBudget';
import { useGetPublicHoliday } from '@/services/holiday/usePublicHolidays';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC, useMemo } from 'react';
import { MdNoteAlt } from 'react-icons/md';
import { Tooltip } from './Tooltip';

export const buildRosterTooltip = (days: Date[]) => {
  const { budgetData: tooltipData } = useTooltipForLocation(days);

  const tooltips = useMemo(() => {
    const tooltips: DateCellTooltipType[] = [];

    tooltipData.days.forEach(day => {
      if (day.unfilledShifts.length > 0 || day.notes) {
        tooltips.push({
          date: day.date,
          content: (
            <>
              {day.unfilledShifts.length > 0 && (
                <>
                  <div className="flex justify-between gap-5">
                    <div className="font-semibold">{strings.budget.vacantShifts.title(day.unfilledShifts.length)}</div>
                  </div>
                  {day.unfilledShifts.map((shift, index) => (
                    <div key={index} className="ml-5">{`${secondsToTimeString(shift.start)}-${secondsToTimeString(
                      shift.end
                    )}`}</div>
                  ))}
                </>
              )}
              {day.notes && (
                <div className="flex items-center gap-2 border-t border-black pt-1 mt-1">
                  <MdNoteAlt className="flex-shrink-0" />
                  <div className="max-h-32 overflow-y-auto whitespace-pre-wrap">{day.notes}</div>
                </div>
              )}
            </>
          ),
          hasNotes: Boolean(day.notes)
        });
      }
    });

    return tooltips;
  }, [tooltipData]);

  return tooltips;
};

const DailyRosterTooltipVerison: FC = () => {
  const { workingRoster } = useWorkingRoster();

  if (!workingRoster?.templateId) {
    throw Error('Tooltip cannot be displayed without assigned template');
  }

  const template = useGetTemplateById(workingRoster?.templateId);

  return template?.version ? <span>{strings.daily.roster.tooltip.templateVersion(template.version)}</span> : <></>;
};

export const DailyRosterToolTip: FC = () => {
  const { workingRoster } = useWorkingRoster();

  const selectedDate = useLocalSettings(s => s.selectedDate);
  const date = new Date(selectedDate);

  const getHoliday = useGetPublicHoliday(date);
  const holiday = getHoliday(date);

  return (
    <Tooltip className="bg-white">
      <div className="font-regular space-y-2 text-black">
        <div className="flex flex-col gap-1">
          {holiday && <span>{holiday.name}</span>}
          <span>
            {workingRoster?.templateName
              ? strings.daily.roster.tooltip.templateName(workingRoster?.templateName)
              : strings.daily.roster.tooltip.unableToGetTemplate}
          </span>
          {workingRoster?.templateId && <DailyRosterTooltipVerison />}
        </div>
      </div>
    </Tooltip>
  );
};
