import { dateToApiFormat } from '@/helpers/dateHelper';
import { WorkingBudgetDay, WorkingBudgetShift } from '../budget/budget.types';
import { ReadRoster, ReadTemplate } from '../gql/graphql.generated';
import { TaskType } from '../tasks/task.types';
import { SummaryDayStats } from '../summary/buildSummaryDayStats';

export interface AssignedEmployee {
  identityId?: string;
}

export interface Slot {
  start?: number;
  end?: number;
  properties?: string;
  typeContext?: string;
}

export interface Shift extends Slot {
  breaks?: Slot[];
  employee?: AssignedEmployee;
  timeSlots?: Slot[];
  tasks?: Slot[];
}

export const calculateShiftTime = (shift: Shift, tasks: TaskType[], includeBreaks?: boolean) => {
  const basicTime = (shift.end || 0) - (shift.start || 0);
  const breakTime =
    shift.breaks?.reduce((prev, b) => {
      return prev + (b.end || 0) - (b.start || 0);
    }, 0) || 0;

  // Some places use tasks and some use timeSlots. Accept either.
  const taskList = [...(shift.timeSlots || []), ...(shift.tasks || [])];
  const uncountedTaskTime =
    taskList?.reduce((prev, t) => {
      const properties = JSON.parse(t.properties || '{}');
      const taskId = properties.context || t.typeContext;
      const task = tasks.find(t => t.id === taskId);

      if (task?.isInCalculatedWorkingHours) {
        return prev;
      }

      return prev + (t.end || 0) - (t.start || 0);
    }, 0) || 0;

  const actualTime = basicTime - uncountedTaskTime - (includeBreaks ? 0 : breakTime);

  return { actualTime, breakTime, basicTime, uncountedTaskTime };
};

export const calculateRosterTime = (roster: ReadRoster | ReadTemplate, tasks: TaskType[], includeBreaks?: boolean) => {
  if (roster.stations && roster.stations[0].positions) {
    const shifts = roster.stations[0].positions as Shift[];
    const assignedShifts = shifts.filter(s => s?.employee?.identityId);

    return assignedShifts.reduce((previousTotal, shift) => {
      const { actualTime: length } = calculateShiftTime(shift, tasks, includeBreaks);
      return previousTotal + length;
    }, 0);
  } else {
    return 0;
  }
};

export const countRosterFilledShifts = (roster: ReadRoster) => {
  if (!roster.stations) {
    return 0;
  }

  return roster.stations?.reduce((prevStationTotal, station) => {
    if (!station.positions) {
      return prevStationTotal;
    }

    return station.positions?.reduce((prevTotal, position) => {
      if (!position.employee) {
        return prevTotal;
      } else {
        return prevTotal + 1;
      }
    }, prevStationTotal);
  }, 0);
};

export const countRosterTotalShifts = (roster: ReadRoster) => {
  if (!roster.stations) {
    return 0;
  }

  return roster.stations?.reduce((prevStationTotal, station) => {
    return prevStationTotal + (station.positions?.length || 0);
  }, 0);
};

export const countTemplateShifts = (template: ReadTemplate) => {
  return (
    template.stations?.reduce((prevStationTotal, station) => {
      return prevStationTotal + (station.positions?.length || 0);
    }, 0) || 0
  );
};

export const findUnfilledShifts = (roster: ReadRoster) => {
  return (
    roster.stations?.reduce((prevShiftList, station) => {
      const stationUnfilledShifts = station.positions?.filter(pos => !pos.employee);
      const shifts = stationUnfilledShifts?.map(s => ({ start: s.start, end: s.end })) || [];
      return [...prevShiftList, ...shifts];
    }, [] as WorkingBudgetShift[]) || []
  );
};

export const findUnfilledTemplateShifts = (template: ReadTemplate) => {
  return (
    template.stations?.reduce((prevShiftList, station) => {
      const stationUnfilledShifts = station.positions?.filter(pos => !pos.employee);
      const shifts = stationUnfilledShifts?.map(s => ({ start: s.start, end: s.end })) || [];
      return [...prevShiftList, ...shifts];
    }, [] as WorkingBudgetShift[]) || []
  );
};

export interface RosteredBudgetDataShift {
  start: number;
  end: number;
}

export interface RosteredBudgetData {
  time: number;
  filledShiftCount: number;
  totalShiftCount: number;
  unfilledShifts: RosteredBudgetDataShift[];
  notes?: string;
}

export const buildRosteredBudgetData =
  (rosters: ReadRoster[], templates: ReadTemplate[], summaryStats: SummaryDayStats[], tasks: TaskType[]) =>
  (day: Date): RosteredBudgetData => {
    const roster = rosters?.find(roster => roster.rosterDate.substring(0, 10) === dateToApiFormat(day));
    const properties = roster?.properties ? JSON.parse(roster?.properties) : {};

    const template = templates?.find(
      template => template.target?.type === 'DayOfWeek' && template.target?.value === day.getDay()
    );

    const templateProperties = template?.properties ? JSON.parse(template?.properties) : {};

    const dayStats = summaryStats.find(s => dateToApiFormat(s.date) === dateToApiFormat(day));

    if (roster) {
      return {
        time: dayStats?.actualTotal || 0,
        filledShiftCount: countRosterFilledShifts(roster),
        totalShiftCount: countRosterTotalShifts(roster),
        unfilledShifts: findUnfilledShifts(roster),
        notes: properties && properties.notes
      };
    } else if (template) {
      return {
        time: calculateRosterTime(template, tasks),
        filledShiftCount: 0,
        totalShiftCount: countTemplateShifts(template),
        unfilledShifts: findUnfilledTemplateShifts(template),
        notes: templateProperties && templateProperties.notes
      };
    } else {
      return { time: 0, filledShiftCount: 0, totalShiftCount: 0, unfilledShifts: [], notes: undefined };
    }
  };

export const applyRosteredDataToBudget = (
  days: WorkingBudgetDay[],
  getRosteredData: (day: Date) => RosteredBudgetData
) => {
  days.forEach(day => {
    if (day && day.date) {
      const { time, filledShiftCount, totalShiftCount, unfilledShifts, notes } = getRosteredData(day.date);
      day.rosteredBudget = time;
      day.filledShiftCount = filledShiftCount;
      day.totalShiftCount = totalShiftCount;
      day.unfilledShifts = unfilledShifts;
      day.notes = notes;
    }
  });

  return days.reduce((prevTotal, day) => {
    if (day && day.rosteredBudget) {
      return prevTotal + day.rosteredBudget;
    }
    return prevTotal;
  }, 0);
};
