import { useGetContractName } from '@/services/contract/useGetContractName';
import { strings } from '@/services/translation/strings';
import { useAllLocations, useMyLocations } from '@/services/useLocations';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { FC } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { StaffRowCell } from './StaffRowCell';

export const StaffRowLocationSection: FC<StaffRowSectionProps> = ({ staff, hidden, onChangeSetting, group }) => {
  const locations = useMyLocations();
  const assignedLocation = locations.find(location => location.id === staff?.employee?.homeLocationId);

  const getTooltip = (): string => {
    const assignedLocations = staff.settings.assignedLocationIds.filter(p => p !== assignedLocation?.id);
    return assignedLocations
      .map(locationId => {
        return `${locationId} - ${allLocations.find(loc => loc.id === locationId)?.name}`;
      })
      .join(', ');
  };

  const allLocations = useAllLocations();
  const locationOptions: SelectItemOptionsType = allLocations
    .filter(p => p.id !== assignedLocation?.id)
    .map(p => ({ label: `${p.locationNumber} - ${p.name}`, value: p.id }));

  const getContractName = useGetContractName();
  const contractName = staff?.employee?.contract?.contractTypeId
    ? getContractName(staff.employee.contract.contractTypeId)
    : null;

  return (
    <>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[0].isColumnHidden}>
        {assignedLocation ? assignedLocation.name : ''}
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[1].isColumnHidden}>
        {contractName}
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[2].isColumnHidden}>
        <MultiSelect
          id={`staff-locations-multiselect-${staff.employee.identityId}`}
          value={staff.settings.assignedLocationIds.filter(p => p !== assignedLocation?.id)}
          tooltip={getTooltip()}
          onChange={e => onChangeSetting('assignedLocationIds')(e.value)}
          options={locationOptions}
          filter
          display="chip"
          placeholder={strings.settings.manageStaff.selectLocations}
          className="skills-multiselect w-full"
        />
      </StaffRowCell>
    </>
  );
};
