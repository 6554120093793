import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { useMemo } from 'react';
import { TableColumn } from '../../layout/SettingsTable';

export const useLeaveColumns = () => {
  const deleteLeaveEnabled = useFlag('deleteLeaveTypes');
  const columns: TableColumn[] = useMemo(() => {
    const cols: TableColumn[] = [
      {
        name: strings.settings.manageLeave.active,
        width: 100,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.leaveUnavailabilityType,
        width: 250,
        tooltipText: strings.settings.manageLeave.leaveUnavailabilityTypeToolTip,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.displayName,
        width: 250,
        tooltipText: strings.settings.manageLeave.displayNameToolTip,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.systemCode,
        width: 250,
        tooltipText: strings.settings.manageLeave.systemCodeTooltip,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.colors,
        width: 100,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.maximumTotal,
        width: 400,
        tooltipText: strings.settings.manageLeave.maximumTotalTooltip,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.minimumTotal,
        width: 400,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.maximumConsecutive,
        width: 350,
        tooltipText: strings.settings.manageLeave.maximumConsecutiveToolTip,
        ignoreZIndex: true
      },
      {
        name: strings.settings.manageLeave.availableInEss,
        width: 90,
        ignoreZIndex: true
      }
    ];

    if (deleteLeaveEnabled) {
      cols.push({
        name: '',
        width: 100
      });
    }

    return cols;
  }, [deleteLeaveEnabled]);

  return columns;
};
