import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { SubsectionConfig } from '@/components/gantt/gantt/GanttProvider';
import { useGetBreakOffsets } from '@/services/settings/useGetBreakOffsets';
import { useTasks } from '@/services/tasks/useTasks';
import { useMemo } from 'react';

export const useSubsections = () => {
  const tasks = useTasks();
  const offsetData = useGetBreakOffsets();

  return useMemo(() => {
    const basicSubsections: SubsectionConfig[] = [
      {
        id: ItemTypes.BREAK,
        defaultLength: 60 * 60,
        startBuffer: offsetData?.timeOffsetBeforeFirstBreak || 0,
        endBuffer: offsetData?.timeOffsetAfterLastBreak || 0,
        minLength: 15 * 60
      }
    ];

    const taskSubsections: SubsectionConfig[] = tasks.map(task => ({
      id: task.id.toString(),
      defaultLength: task.defaultDuration * 60,
      startBuffer: 0,
      endBuffer: 0,
      minLength: task.defaultDuration === 0 ? 0 : 15 * 60,
      maxLength: task.defaultDuration === 0 ? 0 : undefined
    }));


    return [...basicSubsections, ...taskSubsections];
  }, [tasks]);
};
