import { convertDateToGQLFormat, dateToApiFormat } from '@/helpers/dateHelper';
import {
  WorkingBudgetLocation
} from '@/services/budget/budget.types';
import { useCallback, useMemo } from 'react';
import { useGetTargetsByLocationIdsQuery } from '../gql/graphql.generated';
import { useLocalSettings } from '../settings/LocalSettingsProvider';
import { useSetRosteredBudgets } from './useRosteredBudgets';
import { buildBudgetFromData } from './buildBudgetFromData';

export const useBudget = (locationIds: number[], dates: Date[]) => {
  // We need to get the targets from more than just the current month period.
  // The monthly target data is only defined on the first of each calendar month.
  const startDate = new Date(dates[0]);
  startDate.setDate(1);
  const endDate = new Date(dates[dates.length - 1]);

  const [{ data: targetData }, refetch] = useGetTargetsByLocationIdsQuery({
    variables: {
      locationIds,
      startDate: convertDateToGQLFormat(startDate),
      endDate: convertDateToGQLFormat(endDate)
    },
    requestPolicy: 'network-only'
  });

  const targets = targetData?.targetsByLocationIds || [];

  const locations: WorkingBudgetLocation[] = useMemo(
    () => buildBudgetFromData(locationIds, dates, targets),
    [targets, locationIds, dates]
  );

  return { locations, refetch };
};

export const useBudgetForLocation = (dates: Date[]) => {
  const selectedLocation = useLocalSettings(state => state.selectedLocationId);
  const locationIdsArray = useMemo(() => [selectedLocation], [selectedLocation]);
  const { locations, refetch: refetchBudget } = useBudget(locationIdsArray, dates);

  if (locations.length === 0) {
    throw Error('Could not get budget for location');
  }

  const { setRosteredBudgets, refetch: refetchRosters } = useSetRosteredBudgets(selectedLocation, dates);

  const budgetData = useMemo(() => {
    const locationData: WorkingBudgetLocation = { ...locations[0] };
    locationData.days = locationData.days.map(day => ({ ...day }));
    setRosteredBudgets(locationData.days);

    return locationData;
  }, [setRosteredBudgets, locations]);

  const refetch = useCallback(() => {
    refetchBudget();
    refetchRosters({ requestPolicy: 'network-only' });
  }, [refetchBudget, refetchRosters]);

  return { budgetData, refetch };
};

export const useWeeklyBudgetForLocation = (dates: Date[]) => {
  const { budgetData } = useBudgetForLocation(dates);

  const budgetForWeek = useMemo(() => {
    const days = budgetData.days.filter(day => {
      const inTheWeek = dates.find(dayOfWeek => dateToApiFormat(dayOfWeek) === dateToApiFormat(day.date));
      return !!inTheWeek;
    });

    return { ...budgetData, days };
  }, [dates, budgetData]);

  return budgetForWeek;
};

export const useBudgetForDay = () => {
  const selectedDate = useLocalSettings(state => state.selectedDate);
  const dateArray = useMemo(() => {
    return [new Date(selectedDate)];
  }, [selectedDate]);

  const { budgetData: budgetForLocation } = useBudgetForLocation(dateArray);

  const budgetForDay = budgetForLocation.days[0];

  if (!budgetForDay) {
    throw Error('Could not get budget for day');
  }

  return budgetForDay;
};
