export interface OptimiserNotificationContext {
  startDate: Date;
  batchId: string;
}

export enum OptimiserStatus {
  UNKNOWN = '0',
  CREATED = '1',
  QUEUED = '2',
  THREE = '3',
  STARTING = '4',
  STARTED = '5',
  FINISHED = '6',
  ERROR = '7',
  DELETED = '8' // This was defined for the FE to handle delete events.
} 

export interface OptimiserJob {
  batchId: string;
  locationId: number;
  created?: Date;
  started?: Date;
  startDate: Date;
  endDate: Date;
  complete?: boolean;
  error?: string;
  associatedNotificationId?: string;
  status: OptimiserStatus
}
