import { Button } from '@/components/button';
import { Modal, ModalProps } from '@/components/modal/Modal';
import { List, Orderable } from '@/components/orderable/Orderable';
import { TableColumn } from '@/components/table/Table';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import { TABLE_ROW_CELL_CLASSES } from '@/components/table/TableRowCell';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { MONTHLY_SORT_TYPES, MonthlySortConfig, useMonthlySortService } from '@/services/sort/MonthlySortProvider';
import { strings } from '@/services/translation/strings';
import { Card } from '@softwareimaging/react';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import MonthlySortRow from './MonthlySortRow';

const headers: TableColumn[] = [
  {
    text: strings.summary.monthlySort.sort
  },
  {
    text: strings.summary.monthlySort.item
  },
  {
    text: strings.summary.monthlySort.ascDes
  }
];

interface MonthlySortRowData {
  text: string;
  type: MONTHLY_SORT_TYPES;
}

export interface ProcessedMonthlySortRowData extends MonthlySortRowData {
  asc: boolean;
}

const rows: MonthlySortRowData[] = [
  {
    text: strings.summary.monthlySort.max,
    type: MONTHLY_SORT_TYPES.max
  },
  {
    text: strings.summary.monthlySort.min,
    type: MONTHLY_SORT_TYPES.min
  },
  {
    text: strings.summary.monthlySort.periodTotal,
    type: MONTHLY_SORT_TYPES.periodTotal
  },
  {
    text: strings.summary.monthlySort.shopPosition,
    type: MONTHLY_SORT_TYPES.shopPosition
  },
  {
    text: strings.summary.monthlySort.name,
    type: MONTHLY_SORT_TYPES.name
  },
  {
    text: strings.summary.monthlySort.employeeId,
    type: MONTHLY_SORT_TYPES.employeeId
  }
];

const MonthlySortModal: FunctionComponent<ModalProps> = ({ open, onClose }) => {
  const { sortOrder, setSortOrder } = useMonthlySortService(state => ({
    sortOrder: state.sortOrder,
    setSortOrder: state.setSortOrder
  }));

  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);

  const processedRows = useMemo(() => {
    const defaultSortOrderMap = new Map(sortOrder.map((item, index) => [item.type, { asc: item.asc, index }]));

    return rows
      .filter(row => (displayIds ? true : row.type !== MONTHLY_SORT_TYPES.employeeId))
      .map(row => {
        const lookup = defaultSortOrderMap.get(row.type);

        return {
          ...row,
          asc: lookup?.asc
        } as ProcessedMonthlySortRowData;
      })
      .sort((a, b) => {
        const orderA = defaultSortOrderMap.get(a.type)?.index ?? -1;
        const orderB = defaultSortOrderMap.get(b.type)?.index ?? -1;

        return orderA - orderB;
      });
  }, [sortOrder, displayIds]);

  const [dragDropRows, setDragDropRows] = useState(processedRows);

  const onReorder = useCallback(
    async (source: number, destination: number) => {
      const array = [...dragDropRows];
      const [removed] = array.splice(source, 1);
      array.splice(destination, 0, removed);
      setDragDropRows([...array]);
    },
    [dragDropRows]
  );

  const onSortTypeChange = (type: MONTHLY_SORT_TYPES, asc: boolean) => {
    const row = dragDropRows.find(r => r.type === type);
    if (!row) throw new Error('Lookup failed');

    row.asc = asc;

    setDragDropRows([...dragDropRows]);
  };

  const [loading, setLoading] = useState(false);
  const onSave = () => {
    setLoading(true);
    const payload: MonthlySortConfig[] = dragDropRows.map(row => {
      return {
        type: row.type,
        asc: row.asc
      };
    });
    setSortOrder(payload);
    setLoading(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-4">
        <div className="flex justify-between space-x-5">
          <div className="text-xl font-semibold flex items-center space-x-2">
            <span>{strings.summary.monthlySort.sort}</span>
          </div>
        </div>
        <div>
          <Card className={twMerge('border-[#DDDDDD] w-full')}>
            <div className={'grid'} style={{ gridTemplateColumns: '0.4fr 1fr 1fr' }}>
              {headers.map((h, index) => (
                <TableHeaderCell header={h} key={index} />
              ))}
            </div>
            <div>
              <List
                id="monthlySort"
                itemIds={dragDropRows.map(item => item.type as unknown as string)}
                className={`mb-2 -mt-2`}
                onReorder={onReorder}
              >
                {dragDropRows.map((row, index) => (
                  <Orderable
                    buttonClassname={twMerge(TABLE_ROW_CELL_CLASSES, 'h-full')}
                    className="justify-start gap-0 grid"
                    style={{ gridTemplateColumns: '0.4fr 1fr 1fr' }}
                    key={`categoryOrderable-${index}`}
                    id={row.type as unknown as string}
                    index={index}
                  >
                    <MonthlySortRow data={row} onSortChange={onSortTypeChange} />
                  </Orderable>
                ))}
              </List>
            </div>
          </Card>
        </div>
        <div className="flex justify-end gap-5 mt-5 items-center">
          <Button onClick={onClose}>{strings.common.cancel}</Button>
          <Button loading={loading} variant={'primary'} onClick={onSave}>
            {strings.common.set}
          </Button>
        </div>
        <div className="space-y-2"></div>
      </div>
    </Modal>
  );
};

export default MonthlySortModal;
