import { LoadingIndicator } from '@/components/LoadingIndicator';
import { ErrorMessage } from '@/components/error/ErrorMessage';
import { SummaryEmployeeTable } from '@/pages/summary/SummaryEmployeeTable';
import { WorkPreferencesTrackingProvider } from '@/services/availability/WorkPreferencesTrackingService';
import { useBudgetForLocation } from '@/services/budget/useBudget';
import { useGetRostersForMonth } from '@/services/rosters/useGetRostersForMonth';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { useAdvancedLeaveColumns, useComplianceDataTotals } from '@/services/summary/useCompliance';
import { useSummary } from '@/services/summary/useSummaryService';
import { strings } from '@/services/translation/strings';
import { useApproveAvailability } from '@/services/useApproveAvailability';
import { FC, Suspense, useCallback, useMemo, useState } from 'react';
import { ComplianceBar } from '../ComplianceBar';
import { SummaryTableHeader } from '../SummaryTableHeader';
import { MonthlyBudget } from '../budget/MonthlyBudget';
import { COMPLIANCE_TOTAL_COLUMN_WIDTH, EMPLOYEE_NAME_COLUMN_WIDTH, MONTHLY_TOTAL_COLUMN_WIDTH } from '../constants';
import { MonthlyPageHeader } from './MonthlyPageHeader';
import { MonthlyTotalHeader } from './MonthlyTotalHeader';
import { MonthlyTotalSummary } from './MonthlyTotalSummary';
import MonthlySortButton from './sort/MonthlySortButton';
import { useSortMonthlyData } from './sort/useSortMonthlyData';

export const MonthlyPage: FC = () => {
  const days = useDaysInCurrentMonth();
  const { homeEmployeesSummary: summary, refetch: refetchSummary } = useSummary(days[0], days[days.length - 1]);
  const { budgetData, refetch: refetchBudget } = useBudgetForLocation(days);
  const selectedLocationId = useSelectedLocation().id;
  const { refetch: refetchRosters } = useGetRostersForMonth(selectedLocationId);
  const [showCompliance, switchCompliance] = useState(false);

  const refetch = useCallback(() => {
    switchCompliance(false);
    refetchBudget();
    refetchSummary();
    refetchRosters();
  }, [refetchBudget, refetchSummary]);

  // Weird hack- see comment above the hook for why we're doing this.
  useApproveAvailability();

  const advancedLeaveCols = useAdvancedLeaveColumns(summary.map(emp => emp.schedule));

  const totalColumnWidth = showCompliance ? COMPLIANCE_TOTAL_COLUMN_WIDTH : MONTHLY_TOTAL_COLUMN_WIDTH;

  const complianceTotals = useComplianceDataTotals(summary);

  const sortMonthlyData = useSortMonthlyData();

  const sortedSummary = useMemo(() => {
    return sortMonthlyData(summary);
  }, [summary, sortMonthlyData]);

  return (
    <div className="h-full flex flex-col">
      <Suspense fallback={<LoadingIndicator />}>
        <MonthlyPageHeader
          onOptimiseComplete={refetch}
          summary={summary}
          switchView={switchCompliance}
          complianceTotals={complianceTotals}
        />
        {summary.length === 0 ? (
          <ErrorMessage message={strings.summary.noData} />
        ) : (
          <>
            <SummaryTableHeader
              isMonthlyShiftsPage
              switchView={switchCompliance}
              showCompliance={showCompliance}
              days={days}
              emptyColumnRight
              width={`${totalColumnWidth}px`}
            />
            {!showCompliance && <MonthlyBudget budgetData={budgetData} />}
            <div className="flex flex-row border-b bg-background-mid">
              <div className="flex">
                <div
                  className="flex items-center justify-center border-r"
                  style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
                >
                  <MonthlySortButton />
                </div>
                <div className="flex" style={{ width: totalColumnWidth }}>
                  <div className="flex-[2]">
                    <MonthlyTotalHeader showDayTotals={!showCompliance} />
                  </div>
                </div>
              </div>
              {showCompliance ? (
                <ComplianceBar advancedLeaveCols={advancedLeaveCols} />
              ) : (
                <div className="border-l"></div>
              )}
            </div>
            <div className="flex flex-row border-b bg-background-mid">
              <div className="flex">
                <div
                  className="flex items-center px-2 font-semibold border-r"
                  style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
                >
                  {strings.common.employee}
                </div>
                <div className="flex" style={{ width: totalColumnWidth }}>
                  <MonthlyTotalSummary
                    showDayTotals={!showCompliance}
                    complianceTotals={complianceTotals}
                  />
                </div>
              </div>
              <div className="border-l"></div>
            </div>
            <div id="summary-table" className="flex-1 overflow-y-scroll overflow-x-hidden">
              <div className="w-screen">
                <WorkPreferencesTrackingProvider startDate={days[0]} endDate={days[days.length - 1]}>
                  <SummaryEmployeeTable
                    days={days}
                    employees={summary}
                    showCompliance={showCompliance}
                    advancedLeaveCols={advancedLeaveCols}
                  />
                </WorkPreferencesTrackingProvider>
              </div>
            </div>
          </>
        )}
      </Suspense>
    </div>
  );
};
