import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { FC } from 'react';
import { getDailyAttendanceTotals } from './dailyHelper';
import { WorkingTemplate } from './template/template.types';
import { useTasks } from '@/services/tasks/useTasks';
import { SummaryDayStats } from '@/services/summary/buildSummaryDayStats';

interface AttendanceTotalSummaryProps {
  workingTemplate?: WorkingTemplate;
  rosterStats?: SummaryDayStats;
}

export const AttendanceTotalSummary: FC<AttendanceTotalSummaryProps> = ({ workingTemplate, rosterStats }) => {
  const tasks = useTasks();
  const dailyAttendanceTotals = workingTemplate && getDailyAttendanceTotals(workingTemplate, tasks);

  let basicTotal = 0;
  let actualTotal = 0;
  let breakTotal = 0;

  if (dailyAttendanceTotals) {
    basicTotal = dailyAttendanceTotals.templateTotalDaily;
    actualTotal = dailyAttendanceTotals.templateTotalActual;
    breakTotal = dailyAttendanceTotals.templateTotalBreak;
  } else if (rosterStats) {
    basicTotal = rosterStats.basicTotal;
    actualTotal = rosterStats.actualTotal;
    breakTotal = rosterStats.breakTotal;
  }

  return (
    <>
      <InfoHeaderCell className="border-r">{secondsToTimeString(basicTotal)}</InfoHeaderCell>
      <InfoHeaderCell className="border-r">{secondsToTimeString(actualTotal)}</InfoHeaderCell>
      <InfoHeaderCell className="">{secondsToTimeString(breakTotal)}</InfoHeaderCell>
    </>
  );
};
