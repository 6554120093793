import { RadioButton } from '@/components/RadioButton';
import Toggle from '@/components/Toggle';
import { SelectInput } from '@/components/form/SelectInput';
import { TextInput } from '@/components/form/TextInput';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { useHolidayRegions } from '@/services/holiday/useHolidayRegions';
import { OrganisationNameType } from '@/services/settings/systemSettings.types';
import { strings } from '@/services/translation/strings';
import { hoursToSeconds } from 'date-fns';
import { FC, useMemo } from 'react';
import ColorSelect from '../../ColorSelect';
import { Setting } from '../../layout/Setting';
import { SettingsSection } from '../../layout/SettingsSection';
import { useEditSettingsService } from '../../services/EditSettingsService';
import { onChangeSettings } from '../../settingsHelper';
import { useStartDateOptions } from '../buildStartDateOptions';
import { textColorOptions } from '../manageTasks/Task';
import { EditPersonnelPicker } from './EditPersonnelPicker';
import { useHourOptions } from './useHourOptions';
import { useMinMaxTimeOptions } from './useMinMaxTimeOptions';
import { useMinuteOptions } from './useMinuteOptions';
import { useOrgNameOptions } from './useOrgNameOptions';
import { useFlag } from '@softwareimaging/backstage';

const shiftTimes = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12];

export const SystemSettings: FC = () => {
  const { settings, editSettings } = useEditSettingsService(state => ({
    settings: state.workingSystemSettings,
    editSettings: state.setWorkingSystemSettings
  }));

  const onChangeSetting = onChangeSettings(settings, editSettings);

  const onChangeFirstBreakOffset = (value: string) => {
    onChangeSetting('timeOffsetBeforeFirstBreak')(parseInt(value));
  };

  const onChangeLastBreakOffset = (value: string) => {
    onChangeSetting('timeOffsetAfterLastBreak')(parseInt(value));
  };

  const rosterDateOptions = useStartDateOptions();
  const weekViewOptions = strings.common.daysOfWeekFull
    .slice(0, strings.common.daysOfWeekFull.length - 1)
    .map((day, index) => {
      return { name: day, value: index };
    });
  const futureAvailabilityOptions = [...Array(12).keys()].map(index => {
    return {
      name: strings.settings.system.roster.settings.futureAvailabilityMonths.monthCount(index + 1),
      value: index + 1
    };
  });

  const hourOptions = useHourOptions();
  const breakOffsetOptions = useMinuteOptions(0, 2, 5);
  const minMaxOptions = useMinMaxTimeOptions();

  const orgNameOptions = useOrgNameOptions();

  const splitShiftCountOptions = useMemo(() => {
    const splitShiftCounts = [1, 2, 3];
    return splitShiftCounts.map(count => ({
      name: strings.settings.admin.settings.splitShifts.maxNumber.count(count),
      value: count
    }));
  }, []);

  const splitShiftTimeBetweenOptions = useMemo(() => {
    const timeBetweenHours = [0, 0.5, 1, 1.5, 2, 2.5, 3];
    return timeBetweenHours.map(count => ({
      name: secondsToTimeString(hoursToSeconds(count)),
      value: hoursToSeconds(count)
    }));
  }, []);

  const maxTimePeriodOptions = useMemo(() => {
    const options = [1, 2, 3];
    return options.map(count => ({
      name: strings.settings?.system.roster.settings.maxTimePeriods.count(count),
      value: count
    }));
  }, []);

  const holidayRegionOptions = useHolidayRegions();

  const shiftDurationOptions = shiftTimes.map(time => {
    return { name: secondsToTimeString(hoursToSeconds(time)), value: hoursToSeconds(time) };
  });

  const allowMultipleAvailabilities = useFlag('allowMultipleAvailableTimes')

  return (
    <div>
      <SettingsSection title={strings.settings.system.desktopAppBehaviour.label}>
        <Setting
          className="gap-10 flex-1 flex items-center"
          title={strings.settings?.system.desktopAppBehaviour.title}
          description={strings.settings?.system.desktopAppBehaviour.description}
        >
          {/* TEXT COLOR DROPDOWN */}
          <SelectInput
            options={textColorOptions}
            value={settings.systemTabsTextColor}
            setValue={onChangeSetting('systemTabsTextColor')}
          />
          {/* COLORS DROPDOWN */}
          <ColorSelect
            className="w-[10%]"
            selectedColor={settings.highlightSystemTabsColor}
            onSelectColor={onChangeSetting('highlightSystemTabsColor')}
            dropdownId={`colors-${'settings.id'}`}
            parentContainerId={'body'}
          />
        </Setting>
        <Setting
          className="gap-10 flex-1 flex items-center"
          title={strings.settings.system.orgName.title}
          description={strings.settings.system.orgName.description}
        >
          <SelectInput
            options={orgNameOptions}
            value={settings.organisationName.type}
            setValue={onChangeSetting('organisationName.type')}
          />
          {settings.organisationName.type === OrganisationNameType.CUSTOM && (
            <TextInput
              value={settings.organisationName.customName}
              onChange={onChangeSetting('organisationName.customName')}
              placeholder={strings.settings.system.orgName.options.custom}
            ></TextInput>
          )}
        </Setting>
      </SettingsSection>
      <SettingsSection title={strings.settings.system.roster.title}>
        <Setting
          title={strings.settings?.system.roster.settings.rosterMonthStart.title}
          description={strings.settings?.system.roster.settings.rosterMonthStart.description}
        >
          <SelectInput
            options={rosterDateOptions}
            value={settings.monthStartDay}
            setValue={onChangeSetting('monthStartDay')}
          />
        </Setting>
        <Setting
          title={strings.settings?.system.roster.settings.rosterWeekStart.title}
          description={strings.settings?.system.roster.settings.rosterWeekStart.description}
        >
          <SelectInput
            options={weekViewOptions}
            value={settings.weekStartDay}
            setValue={onChangeSetting('weekStartDay')}
          />
        </Setting>
        <Setting
          title={strings.settings.system.roster.settings.defaultStartEnd.title}
          description={strings.settings.system.roster.settings.defaultStartEnd.description}
          className="space-x-5"
        >
          <SelectInput options={minMaxOptions} value={settings.minTime} setValue={onChangeSetting('minTime')} />
          <span>{strings.settings.system.roster.settings.defaultStartEnd.startTime}</span>
          <SelectInput options={minMaxOptions} value={settings.maxTime} setValue={onChangeSetting('maxTime')} />
          <span>{strings.settings.system.roster.settings.defaultStartEnd.endTime}</span>
        </Setting>
        <Setting
          title={strings.settings?.system.roster.settings.futureAvailabilityMonths.title}
          description={strings.settings?.system.roster.settings.futureAvailabilityMonths.description}
        >
          <SelectInput
            options={futureAvailabilityOptions}
            value={settings.futureAvailabilityMonths}
            setValue={onChangeSetting('futureAvailabilityMonths')}
          />
        </Setting>
        <Setting
          title={strings.settings?.system.roster.settings.includeBreaks.title}
          description={strings.settings?.system.roster.settings.includeBreaks.description}
        >
          <Toggle value={settings.includeBreaksInTotals} onChange={onChangeSetting('includeBreaksInTotals')} />
        </Setting>

        <Setting
          title={strings.settings?.system.roster.settings.breakOffset.title}
          description={strings.settings?.system.roster.settings.breakOffset.description}
        >
          <div className="flex gap-3 items-center">
            <SelectInput
              options={breakOffsetOptions}
              value={settings.timeOffsetBeforeFirstBreak}
              setValue={onChangeFirstBreakOffset}
            />

            <p>{strings.settings?.system.roster.settings.breakOffset.firstBreak}</p>

            <SelectInput
              options={breakOffsetOptions}
              value={settings.timeOffsetAfterLastBreak}
              setValue={onChangeLastBreakOffset}
            />

            <p>{strings.settings?.system.roster.settings.breakOffset.lastBreak}</p>
          </div>
        </Setting>

        <Setting
          title={strings.settings?.system.roster.settings.availabilityDeadline.title}
          description={strings.settings?.system.roster.settings.availabilityDeadline.description}
          className="gap-10"
        >
          <SelectInput
            options={rosterDateOptions}
            value={settings.availabilityDeadlineSettings.deadlineMonthDay}
            setValue={onChangeSetting('availabilityDeadlineSettings.deadlineMonthDay')}
          />
          <div className="flex gap-3 items-center">
            <p>{strings.settings.system.roster.settings.availabilityDeadline.toggle}</p>
            <Toggle
              value={settings.availabilityDeadlineSettings.sendReminders}
              onChange={onChangeSetting('availabilityDeadlineSettings.sendReminders')}
            />
            {settings.availabilityDeadlineSettings.sendReminders && (
              <>
                <p>{strings.settings.system.roster.settings.availabilityDeadline.notificationDate}</p>
                <SelectInput
                  options={rosterDateOptions}
                  value={settings.availabilityDeadlineSettings.notifyMonthDay}
                  setValue={onChangeSetting('availabilityDeadlineSettings.notifyMonthDay')}
                />
                <p>{strings.settings.system.roster.settings.availabilityDeadline.notificationTime}</p>
                <SelectInput
                  options={hourOptions}
                  value={settings.availabilityDeadlineSettings.notifyHour}
                  setValue={onChangeSetting('availabilityDeadlineSettings.notifyHour')}
                />
              </>
            )}
          </div>
        </Setting>
        <Setting
          title={strings.settings?.system.roster.settings.holidayRegion.title}
          description={strings.settings?.system.roster.settings.holidayRegion.description}
        >
          <SelectInput
            options={holidayRegionOptions}
            value={settings.holidayRegion}
            setValue={onChangeSetting('holidayRegion')}
          />
        </Setting>
        <Setting
          title={strings.settings?.system.roster.settings.minShiftDuration.title}
          description={strings.settings?.system.roster.settings.minShiftDuration.description}
        >
          <SelectInput
            options={shiftDurationOptions}
            value={settings.minShiftDuration}
            setValue={onChangeSetting('minShiftDuration')}
          />
        </Setting>
        <Setting
          title={strings.settings?.system.roster.settings.defaultShiftDuration.title}
          description={strings.settings?.system.roster.settings.defaultShiftDuration.description}
        >
          <SelectInput
            options={shiftDurationOptions}
            value={settings.defaultShiftDuration}
            setValue={onChangeSetting('defaultShiftDuration')}
          />
        </Setting>

        {allowMultipleAvailabilities && <Setting
          title={strings.settings?.system.roster.settings.maxTimePeriods.title}
          description={strings.settings?.system.roster.settings.maxTimePeriods.description}
        >
          <SelectInput
            options={maxTimePeriodOptions}
            value={settings.maxTimePeriods}
            setValue={onChangeSetting('maxTimePeriods')}
          />
        </Setting>}
      </SettingsSection>
      <SettingsSection title={strings.settings.admin.settings.splitShifts.sectionName}>
        <Setting
          title={strings.settings.admin.settings.splitShifts.maxNumber.title}
          description={strings.settings.admin.settings.splitShifts.maxNumber.description}
        >
          <SelectInput
            options={splitShiftCountOptions}
            value={settings.splitShifts.maxNumber}
            setValue={onChangeSetting('splitShifts.maxNumber')}
          />
        </Setting>
        <Setting
          title={strings.settings.admin.settings.splitShifts.minTimeBetween.title}
          description={strings.settings.admin.settings.splitShifts.minTimeBetween.description}
        >
          <SelectInput
            options={splitShiftTimeBetweenOptions}
            value={settings.splitShifts.minSecondsBetween}
            setValue={onChangeSetting('splitShifts.minSecondsBetween')}
          />
        </Setting>
      </SettingsSection>
      <SettingsSection title={strings.settings.admin.settings.reassignment.sectionName}>
        <Setting title={strings.settings.admin.settings.reassignment.model.title}>
          <div>
            <div className="flex gap-2 items-center">
              <RadioButton
                checked={settings.reassignment.model === 'on-request'}
                onClick={() => onChangeSetting('reassignment.model')('on-request')}
              />
              <div>{strings.settings.admin.settings.reassignment.model.onRequestModel}</div>
            </div>
            <div className="flex gap-2 items-center">
              <RadioButton
                checked={settings.reassignment.model === 'pull'}
                onClick={() => onChangeSetting('reassignment.model')('pull')}
              />
              <div>{strings.settings.admin.settings.reassignment.model.pullModel}</div>
            </div>
            <div className="flex gap-2 items-center">
              <RadioButton
                checked={settings.reassignment.model === 'disabled'}
                onClick={() => onChangeSetting('reassignment.model')('disabled')}
              />
              <div>{strings.settings.admin.settings.reassignment.model.disabled}</div>
            </div>
          </div>
        </Setting>
        <div className={settings.reassignment.model === 'disabled' ? 'text-gray-400' : ''}>
          <Setting
            title={strings.settings.admin.settings.reassignment.locations.title}
            description={strings.settings.admin.settings.reassignment.locations.description}
          >
            <div className="flex gap-4">
              <Toggle
                disabled={settings.reassignment.model === 'disabled'}
                value={settings.reassignment.workAtAllLocations}
                onChange={onChangeSetting('reassignment.workAtAllLocations')}
              />
              {settings.reassignment.model !== 'disabled' && (
                <div>
                  {settings.reassignment.workAtAllLocations
                    ? strings.settings.admin.settings.reassignment.locations.allLocations
                    : strings.settings.admin.settings.reassignment.locations.allocatedLocations}
                </div>
              )}
            </div>
          </Setting>
        </div>
      </SettingsSection>
      <SettingsSection title={strings.settings.system.roster.settings.validation.title}>
        <Setting
          title={strings.settings.system.roster.settings.validation.managementAttendance.title}
          description={strings.settings.system.roster.settings.validation.managementAttendance.description}
        >
          <Toggle
            value={settings.rosterValidation.managementAttendance}
            onChange={onChangeSetting('rosterValidation.managementAttendance')}
          />
        </Setting>
        <Setting
          title={strings.settings.system.roster.settings.validation.mandatorySkills.title}
          description={strings.settings.system.roster.settings.validation.mandatorySkills.description}
        >
          <Toggle
            value={settings.rosterValidation.mandatorySkills}
            onChange={onChangeSetting('rosterValidation.mandatorySkills')}
          />
        </Setting>
      </SettingsSection>
      <SettingsSection
        title={strings.settings.system.personnelDetails.title}
        additionalTitle={strings.settings.system.personnelDetails.description}
      >
        <Setting title={strings.settings.system.personnelDetails.editable.dateOfBirth}>
          <EditPersonnelPicker field="dateOfBirth" />
        </Setting>
        <Setting title={strings.settings.system.personnelDetails.editable.endProbDate}>
          <EditPersonnelPicker field="endProbationDate" />
        </Setting>
        <Setting title={strings.settings.system.personnelDetails.editable.hiredDate}>
          <EditPersonnelPicker field="hiredDate" />
        </Setting>
        <Setting title={strings.settings.system.personnelDetails.editable.leftDate}>
          <EditPersonnelPicker field="leftCompanyDate" />
        </Setting>
      </SettingsSection>
    </div>
  );
};
