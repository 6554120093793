import { cn } from '@/lib/utils';
import { EmployeeSelector } from '@/pages/daily/employeeSelector/EmployeeSelector';
import { EMPLOYEE_DETAILS_COLUMN_WIDTH, EMPLOYEE_NAME_COLUMN_WIDTH } from '@/pages/summary/constants';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useAllLocations } from '@/services/useLocations';
import { FC, useCallback } from 'react';
import { AttendanceTotalColumns } from '../AttendanceTotalColumns';
import { ShiftTimePicker } from '../ShiftTimePicker';
import { DailyEditingType } from '../daily.types';
import { MonthlyTotalColumns } from './MonthlyTotalColumns';
import { EmployeeForShiftItem } from '../employeeSelector/employeeForShift.types';
import { useReassignmentService } from './providers/ReassignmentService';
import {useEmployeesForShift} from "@/pages/daily/roster/employeeSelector/useEmployeesForShift";
import { WorkingShift } from "@/pages/daily/daily.types";
import {useWorkingRoster} from "@/pages/daily/roster/providers/WorkingRosterService";
import {EmployeeOptionSettingsProvider} from "@/pages/daily/employeeSelector/EmployeeOptionSettingsProvider";
import {showStaffPickerColumn} from "@/services/staff/preferences";
import {useSystemSettings} from "@/services/settings/SystemSettingsProvider";

interface ReassignedShiftsProps {
  readonlyEmployeeSelector?: boolean;
}

const ReassignedShift: FC<{
  shift: WorkingShift;
  readonly?: boolean;
  selectStaffMember: (newEmployee?: EmployeeForShiftItem) => void;
}> = ({ shift, readonly, selectStaffMember }) => {
  const locations = useAllLocations();

  const shiftLocation = locations.find(l => l.id === shift.locationId);

  const { getReassignedEmployees } = useReassignmentService(state => ({
    getReassignedEmployees: state.getReassignedEmployees
  }));
  const employeeDropdownOptions = useEmployeesForShift(shift, getReassignedEmployees());
  const reassignment = useSystemSettings(state => state.settings.reassignment);

  const { workingRoster } = useWorkingRoster();

  return (
    <div className="flex flex-row border-b">
      <div className={cn('flex sticky left-0 z-[60] bg-white border-r')}>
        <div
          className="flex items-center px-2 font-semibold gap-3 border-r"
          style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
        >
          <div className="flex flex-col w-[90%] gap-1">
            <EmployeeOptionSettingsProvider showStaffPickerColumn={showStaffPickerColumn} reassignmentModel={reassignment.model}>
              <EmployeeSelector
                onSelect={selectStaffMember}
                readOnly={readonly}
                workingShifts={workingRoster?.shifts}
                shift={shift}
                waitingAllocation={false}
                employeeDropdownOptions={employeeDropdownOptions}
              />
            </EmployeeOptionSettingsProvider>
            <div className="flex flex-row justify-between items-center">
              <span className="text-xs">
                {shiftLocation ? `${shiftLocation.locationNumber} - ${shiftLocation.name}` : undefined}
              </span>
            </div>
          </div>
        </div>
        <div style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }} className="flex">
          <div className="flex-[2] flex border-r">
            <MonthlyTotalColumns shift={shift} />
          </div>
          <div className="flex-[3] flex">
            <AttendanceTotalColumns shift={shift} />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <ShiftTimePicker
          key={shift.id}
          shift={shift}
          updateShift={() => {
            return;
          }}
          type={DailyEditingType.REASSIGNED}
          useColorizedBar={true}
          hasSetEmployee={!!shift.assignedEmployeeId}
          readOnly
        />
      </div>
    </div>
  );
};

export const ReassignedShifts: FC<ReassignedShiftsProps> = ({ readonlyEmployeeSelector }) => {
  const { reassignedShifts, setReassignedShifts } = useReassignmentService(state => ({
    reassignedShifts: state.reassignedShifts,
    setReassignedShifts: state.setReassignedShifts
  }));
  const { id: locationId } = useSelectedLocation();

  const selectStaffMember = useCallback(
    (shiftIndex: number) => (newEmployee?: EmployeeForShiftItem) => {
      const newEmployeeId = newEmployee?.id;
      const shifts = [...reassignedShifts];
      const shift = { ...shifts[shiftIndex] };
      shifts.splice(shiftIndex, 1);

      shift.locationId = locationId;

      if (newEmployeeId) {
        shift.assignedEmployeeId = newEmployeeId;
        shift.assignedStaffHomeLocationId = locationId;
      } else {
        shift.assignedEmployeeId = undefined;
        shift.assignedStaffHomeLocationId = undefined;
      }

      if (shift.assignedEmployeeId) {
        if (shift.properties) {
          if (shift.properties.providingLocationId) {
            delete shift.properties.providingLocationId;
          }
          if (!Object.keys(shift.properties).length) {
            shift.properties = undefined;
          }
        }
      } else {
        shift.properties = {};
        shift.properties['providingLocationId'] = locationId;
      }

      shifts.push(shift);
      setReassignedShifts(shifts);
    },
    [locationId, reassignedShifts, setReassignedShifts]
  );

  return (
    <>
      {reassignedShifts.map((shift, index) => (
        <ReassignedShift
          selectStaffMember={selectStaffMember(index)}
          shift={shift}
          readonly={readonlyEmployeeSelector}
          key={shift.id}
        ></ReassignedShift>
      ))}
    </>
  );
};
