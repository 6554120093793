import { strings } from "@/services/translation/strings";
import { useFlag } from "@softwareimaging/backstage";
import { useMemo } from "react";
import { TableColumn } from "../../layout/SettingsTable";

export const useAnnouncementColumns = () => {
  const deleteAnnouncementEnabled = useFlag('deleteAnnouncements');
  const columns: TableColumn[] = useMemo(() => {
    const cols = [
      {
        name: strings.settings.manageAnnouncements.activeInactive,
        width: 160
      },
      {
        name: strings.settings.manageAnnouncements.title,
        width: 300
      },
      {
        name: strings.settings.manageAnnouncements.text,
        width: 'auto'
      }
      // TODO: for future use!!!
      // {
      //   name: strings.settings.manageAnnouncements.createdAt,
      //   width: 160
      // },
      // {
      //   name: strings.settings.manageAnnouncements.updatedAt,
      //   width: 160
      // },
      // {
      //   name: strings.settings.manageAnnouncements.displayDate,
      //   width: 160
      // },
      // {
      //   name: strings.settings.manageAnnouncements.showAtDate,
      //   width: 160
      // }
    ];

    if (deleteAnnouncementEnabled) {
      cols.push({
        name: '',
        width: 100
      });
    }

    return cols;
  }, []);

  return columns;
};
