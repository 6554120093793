import { useSetRosterStatusesMutation } from '@/services/gql/graphql.generated';
import {
  NotificationStatus,
  NotificationType,
  useNotificationService
} from '@/services/notifications/NotificationService';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { useCallback } from 'react';
import uuid from 'react-uuid';

export const usePublishRosters = () => {
  const [{ fetching, error }, updateStatuses] = useSetRosterStatusesMutation();
  const createNotification = useNotificationService(s => s.createNotification);
  const selectedLocationId = useLocalSettings(s => s.selectedLocationId);

  const publishRosters = useCallback(
    async (rosterIds: string[], startDate: Date, endDate: Date) => {
      if (rosterIds.length === 0) {
        throw Error('Roster ID array contains no IDs');
      }

      const result = await updateStatuses({
        updateStatuses: {
          rosterIds: rosterIds,
          status: 'Published'
        }
      });

      const formattedRosterDates = `${format(startDate, 'yyyy/MM/dd')}~${format(endDate, 'yyyy/MM/dd')}`;
      if (!result.error) {
        createNotification({
          regularText: strings.summary.publishNotification.prefix,
          boldText: formattedRosterDates,
          colorizedText: strings.summary.publishNotification.success,
          id: uuid(),
          type: NotificationType.ROSTER_PUBLISH,
          status: NotificationStatus.SUCCESS,
          date: new Date(),
          locationId: selectedLocationId
        });
      } else {
        createNotification({
          regularText: strings.summary.publishNotification.prefix,
          boldText: formattedRosterDates,
          colorizedText: strings.summary.publishNotification.failure,
          id: uuid(),
          type: NotificationType.ROSTER_PUBLISH,
          status: NotificationStatus.FAIL,
          date: new Date(),
          locationId: selectedLocationId
        });
      }

      return result;
    },
    [updateStatuses]
  );

  return { publishRosters, error, fetching };
};
