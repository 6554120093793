import { convertDateToGQLFormat } from '@/helpers/dateHelper';
import React from 'react';
import { useState } from 'react';
import {twMerge} from "tailwind-merge";

interface Props {
  value?: Date;
  setValue: (newValue?: Date) => void;
  disabled?: boolean;
  className?: string;
}

export const DateInput = React.forwardRef<HTMLInputElement, Props>(
  ({ value, setValue, disabled, className }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const onChange = (newVal: string) => {
    setInputValue(newVal);
    if (!newVal) {
      setValue(undefined);
    }
  };

  const onBlur = (newVal: string) => {
    if (Date.parse(newVal)) {
      const dateParts = newVal.split('-');
      const date = new Date()
      date.setFullYear(parseInt(dateParts[0]));
      date.setMonth(parseInt(dateParts[1]) - 1);
      date.setDate(parseInt(dateParts[2]));
      setValue(date);
    } else {
      setInputValue('');
      setValue(undefined);
    }
  }

  return (
    <input
      type="date"
      className={twMerge(`border-border rounded-md ${disabled ? 'bg-background-mid' : ''}`, className)}
      value={inputValue ? inputValue : (value ? convertDateToGQLFormat(value) : undefined)}
      onChange={e => onChange(e.target.value)}
      onBlur={e => onBlur(e.target.value)}
      disabled={disabled}
    />
  );
});
