export enum FilterOptions {
  ACTIVE = 'active',
  ALL = 'all',
  INACTIVE = 'inactive'
}

export enum TextColors {
  BLACK = 'black',
  WHITE = 'white'
}

export interface TaskType {
  id: string;
  name: string;
  displayName: string;
  icon: string;
  textColor: TextColors;
  color: string;
  defaultDuration: number;
  isSupplementary: boolean;
  isInMonthlyTotals: boolean; // STRONGLY SUSPECT THIS DOESN'T DO ANYTHING
  isInCalculatedWorkingHours: boolean;
  skills: string[];
  optionalSkills: string[];
  isActive: boolean;
}
