import { useTimeSlots } from '@/components/gantt/gantt/ganttHelper';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC, useContext } from 'react';
import { styles } from '../PdfStyles';
import { PdfTableCell } from '../PdfTableCell';
import { ROSTER_DETAILS_WIDTH, ROSTER_NAME_WIDTH } from './PdfRosterDocument';
import { PdfRosterContext } from './PdfRosterProvider';
import { deltaToColor } from '../deltaToColor';
import {PrintViewTypes} from "@/pages/pdf/roster/usePrintOptions";

const ScratchpadBox: FC<{ value: number; border?: boolean; delta?: number }> = ({ value, border, delta }) => {
  const color = delta ? deltaToColor(delta) : undefined;

  return (
    <View
      style={{
        height: 12,
        borderWidth: border ? 0.5 : 0,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: color
      }}
    >
      <Text style={styles.tableCellSm}>{value}</Text>
    </View>
  );
};

export const PdfRosterScratchpad: FC = () => {
  const {
    workingRoster,
    maxTime,
    minTime,
    counts,
    minuteIncrement,
    viewType
  } = useContext(PdfRosterContext);

  if (!workingRoster) {
    throw Error("Working roster should be defined");
  }
  
  const slots = useTimeSlots(minTime, maxTime, 30 * 60);

  const scratchTotal = workingRoster.scratchpad.reduce((prevTot, currVal) => prevTot + currVal, 0);
  const scratchTotalSeconds = scratchTotal * 30 * 60;

  const countTotal = counts.reduce((prevTot, currVal) => prevTot + currVal, 0);
  const countTotalSecondsUnrounded = countTotal * 30 * 60;
  const secondIncrement = minuteIncrement * 60;
  const countTotalSeconds = Math.round(countTotalSecondsUnrounded / secondIncrement) * secondIncrement;

  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={viewType === PrintViewTypes.SCREEN ? ROSTER_DETAILS_WIDTH : ROSTER_NAME_WIDTH}>
        <View style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
          {viewType === PrintViewTypes.SCREEN &&
          <View style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right', paddingRight: 5 }}>
              {`${strings.daily.summary.scratchTotal} (${strings.daily.summary.totalTime}: ${secondsToTimeString(
                scratchTotalSeconds
              )})`}
            </Text>
          </View>}
          <View style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ ...styles.tableCellSm, textAlign: 'right', paddingRight: 5 }}>
              {`${strings.daily.summary.totalActualEmployee} (${strings.daily.summary.totalTime}: `}
              <Text style={{ color: deltaToColor(scratchTotalSeconds - countTotalSeconds) }}>
                {secondsToTimeString(countTotalSeconds)}
              </Text>
              {')'}
            </Text>
          </View>
        </View>
      </PdfTableCell>
      <PdfTableCell lastCell={true} height={viewType === PrintViewTypes.SCREEN ? 30 : 15}>
        {slots.map((slot, index) => (
          <View
            key={slot.start}
            style={{ padding: 2, flexDirection: 'column', display: 'flex', gap: 2, flex: 1, height: '100%' }}
          >
            {viewType === PrintViewTypes.SCREEN && <ScratchpadBox value={workingRoster.scratchpad[index]} border />}
            <ScratchpadBox value={counts[index]} delta={counts[index] - workingRoster.scratchpad[index]} />
          </View>
        ))}
      </PdfTableCell>
    </View>
  );
};
