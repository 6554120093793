import { dateToApiFormat } from '@/helpers/dateHelper';
import { StaffWithSettings } from '@/services/settings/systemSettings.types';
import { useMemo } from 'react';

export enum StaffSettingsFilterOptions {
  CURRENT = 'current',
  ALL = 'all',
  PAST = 'past'
}

export const useFilterStaffSettings = (settings: StaffWithSettings[], filterOption: StaffSettingsFilterOptions) => {
  return useMemo(() => {
    if (filterOption === StaffSettingsFilterOptions.ALL) {
      return settings;
    }

    const today = dateToApiFormat(new Date());

    const filteredStaff: StaffWithSettings[] = [];
    settings.forEach(s => {
      let employeeHasLeft = false;
      const leavingDate = s.employee.leaveDate;

      if (leavingDate && today >= dateToApiFormat(new Date(leavingDate))) {
        employeeHasLeft = true;
      }

      if (filterOption === StaffSettingsFilterOptions.CURRENT) {
        if (!employeeHasLeft) {
          filteredStaff.push(s);
        }
      } else if (filterOption === StaffSettingsFilterOptions.PAST) {
        if (employeeHasLeft) {
          filteredStaff.push(s);
        }
      }
    });

    return filteredStaff;
  }, [filterOption, settings]);
};
