import { usePublishRosterMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { useWorkingRoster, useWorkingRosterService } from './providers/WorkingRosterService';

export const usePublishWorkingRoster = () => {
  const [{ fetching, error }, publish] = usePublishRosterMutation();
  const updateWorkingRoster = useWorkingRosterService(state => state.publishWorkingRoster);
  const { workingRoster } = useWorkingRoster();

  const publishRoster = useCallback(async () => {
    if (!workingRoster) {
      throw Error('Working roster should be defined');
    }

    const result = await publish({
      rosterId: workingRoster?.id
    });

    updateWorkingRoster();

    return result;
  }, [publish]);

  return { publishRoster, error, fetching };
};
