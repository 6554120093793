import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { staffFirstSectionColour } from './StaffSettings';

export const useStaffDetailSection = () => {
  return useMemo(() => {
    return {
      id: 'details',
      columns: [
        {
          name: strings.settings.manageStaff.employeeName,
          width: 130,
          isFixedPosition: true
        },
        {
          name: strings.settings.manageStaff.katakanaName,
          width: 100,
          isFixedPosition: true
        },
        {
          name: strings.settings.manageStaff.authority,
          width: 250,
          isFixedPosition: true
        }
      ],
      color: staffFirstSectionColour,
      isGroupHidden: false
    };
  }, []);
};
