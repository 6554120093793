import { sortSimpleObjectArray } from '@/helpers/helper';
import {
  CreatePersona,
  Persona,
  UpdatePersona,
  useCreatePersonasMutation,
  useDeletePersonasMutation,
  useGetPersonasQuery,
  useUpdatePersonasMutation
} from '@/services/gql/graphql.generated';
import { PersonaType } from '@/services/personas/persona.types';
import { useCallback, useMemo } from 'react';
import { useRole } from '../authentication/hooks/user';
import { RoleType } from '../authentication/roles';

export const usePersonas = () => {
  const [{ data }] = useGetPersonasQuery();

  if (!data?.personas) {
    throw Error('Could not get personas');
  }

  const role = useRole();

  return useMemo(() => {
    let personas = gqlToPersonas( data.personas as Persona[]);
    
    if (role !== RoleType.Admin) {
      personas = personas.filter(p => !p.adminControlled)
    }

    return sortSimpleObjectArray(personas, 'rank') as PersonaType[];
  }, [data, role]);
};

export const useCreatePersonas = (): any => {
  const [{ fetching }, create] = useCreatePersonasMutation();
  const createPersonas = useCallback(async (personas: PersonaType[]) => {
    const result = await create({ personas: personasToGQL(personas) });

    return result;
  }, []);

  return { fetching, create: createPersonas };
};

export const useUpdatePersonas = (): any => {
  const [{ fetching }, update] = useUpdatePersonasMutation();

  const updatePersonas = useCallback(async (personas: PersonaType[]) => {
    const result = await update({ personas: personasToGQL(personas, true) as UpdatePersona[] });

    return result;
  }, []);

  return { fetching, update: updatePersonas };
};

export const useDeletePersonas = () => {
  const [{ fetching }, deleteCallback] = useDeletePersonasMutation();

  const deletePersonas = useCallback(async (personaIds: string[]) => {
    const result = await deleteCallback({ personaIds });

    return result;
  }, []);

  return { deletePersonas: deletePersonas, fetching };
};

export const personasToGQL = (personas: PersonaType[], isUpdate?: boolean): CreatePersona[] | UpdatePersona[] => {
  return personas.map(p => {
    const baseInfo = {
      name: p.name,
      management: p.management,
      displayOrder: p.rank,
      templateLibrarian: p.templateLibrarian,
      adminControlled: p.adminControlled
    };

    if (!isUpdate) {
      return {
        ...baseInfo
      };
    }

    return {
      id: p.id,
      ...baseInfo
    };
  });
};

export const gqlToPersonas = (personas: Persona[]): PersonaType[] => {
  return personas.map((p, index) => {
    return {
      id: p.id,
      name: p.name,
      rank: p.displayOrder !== undefined ? p.displayOrder : index,
      management: p.management || false,
      templateLibrarian: p.templateLibrarian || false,
      adminControlled: p.adminControlled || false
    };
  });
};

export const useGetPersona = () => {
  const personaList = usePersonas();

  return useCallback(
    (personaId: string) => {
      return personaList.find(t => t.id == personaId);
    },
    [personaList]
  );
};
