import { useEditSettingsService } from '@/pages/settings/services/EditSettingsService';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { FC, useMemo } from 'react';
import {
  StaffSettingsFilterOptions,
  useFilterStaffSettings
} from '../../../../../services/settings/useFilterStaffSettings';
import { useVisibleGroups } from '../useVisibleGroups';
import { Header } from './Header';
import { StaffRow } from './StaffRow';

export interface TableColumnGroup {
  id: string;
  name?: string;
  color?: string;
  fontColor?: string;
  columns: TableColumn[];
  isGroupHidden: boolean;
}

export interface TableColumn {
  name: string;
  width: number;
  isColumnHidden?: boolean;
}

interface Props {
  groups: TableColumnGroup[];
  showIndexColumn?: boolean;
  onChangeSetting: (id: string) => (key: string) => (newVal: any) => void;
  onSetDelete: (id: string) => (del: boolean) => void;
  checkedState: Record<string, boolean>;
  filterOption: StaffSettingsFilterOptions;
}

export const STAFF_TABLE_INDEX_COLUMN_WIDTH = 110;

//CustomSettingsTable
export const StaffTable: FC<Props> = ({
  groups,
  showIndexColumn,
  onChangeSetting,
  onSetDelete,
  checkedState,
  filterOption
}) => {
  const selectedLocationId = useSelectedLocation()?.id;
  const { settings } = useEditSettingsService(state => ({
    settings: state.workingStaffSettings.filter(s => !s.settings.hasBeenDeleted)
  }));

  const homeStaffSettings = settings.filter(s => s.employee.homeLocationId === selectedLocationId!);

  const visibleGroups = useVisibleGroups(groups, checkedState);

  const gridTemplate = useMemo(() => {
    // Template starts with the number column.
    let gridTemplate = `${showIndexColumn ? STAFF_TABLE_INDEX_COLUMN_WIDTH + 'px ' : ''}`;

    visibleGroups.forEach(group => {
      group.columns.forEach(col => {
        if (!col.isColumnHidden) {
          gridTemplate += ` ${col.width}px`;
        }
      });
    });

    return gridTemplate;
  }, [visibleGroups]);

  const filteredSettings = useFilterStaffSettings(homeStaffSettings, filterOption);

  return (
    <div className="grid overflow-auto" style={{ gridTemplateColumns: gridTemplate }}>
      <Header groups={groups} checkedState={checkedState} />
      {filteredSettings.map(staff => (
        <StaffRow
          staff={staff}
          key={staff.employee.identityId}
          onChangeSetting={onChangeSetting(staff.employee.identityId)}
          onSetDelete={onSetDelete(staff.employee.identityId)}
          groups={groups}
          checkedState={checkedState}
        />
      ))}
    </div>
  );
};
