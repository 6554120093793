import { dateToApiFormat, getDatePeriod } from '@/helpers/dateHelper';
import { useReassignedShiftSummary } from '@/services/summary/useReassignedShiftSummary';
import { useMapToLeaveTypeId } from '@/services/availability/useAvailabilityTypes';
import { useGetContractName } from '@/services/contract/useGetContractName';
import {
  EmployeeAvailabilityExceptions,
  EmployeePattern,
  ReadEmployee,
  useGetRosteredStaffInDateRangeQuery,
  useLocationExceptionsQuery,
  useLocationWorkingPatternsQuery
} from '@/services/gql/graphql.generated';
import { useGetDayOfWeekAndHoliday } from '@/services/holiday/usePublicHolidays';
import { useLocationDetails, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useStaffSettings } from '@/services/settings/StaffSettingsProvider';
import { useEmployeeList } from '@/services/staff/useEmployeeList';
import { useCallback, useMemo } from 'react';
import { buildSummary } from './buildSummary';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { useTasks } from '../tasks/useTasks';
import { buildSummaryDayStats } from './buildSummaryDayStats';
import { SummaryEmployee } from './summary.types';

export const useSummary = (startDate: Date, endDate: Date, specifiedLocationId?: number) => {
  const selectedLocationId = useSelectedLocation().id;
  const locationId = specifiedLocationId || selectedLocationId;
  const { locationNumber: locationNumber, name: locationName } = useLocationDetails(locationId);

  const { allEmployees: employees } = useEmployeeList({ date: startDate, specifiedLocation: specifiedLocationId });

  const { addReassignedShifts } = useReassignedShiftSummary(startDate, endDate);

  // Fetch a day outside of the requested range.
  // We'll need these for ghost shifts.
  const startDateToFetch = new Date(startDate);
  startDateToFetch.setDate(startDateToFetch.getDate() - 1);

  const endDateToFetch = new Date(endDate);
  endDateToFetch.setDate(endDateToFetch.getDate() + 1);

  const [{ data: rosterData }, refetchRosteredStaff] = useGetRosteredStaffInDateRangeQuery({
    variables: {
      startDate: dateToApiFormat(startDateToFetch),
      endDate: dateToApiFormat(endDateToFetch),
      locationId
    },
    requestPolicy: 'cache-and-network'
  });

  if (!rosterData?.rosteredStaffInDateRange) {
    throw Error('Could not get rosters');
  }

  const rosteredStaff = rosterData.rosteredStaffInDateRange;

  const [{ data: exceptionData }] = useLocationExceptionsQuery({
    variables: {
      locationId,
      startDate: dateToApiFormat(startDate),
      endDate: dateToApiFormat(endDate)
    },
    requestPolicy: 'cache-and-network'
  });

  const allAvailabilityData = exceptionData?.locationAvailabilityExceptions?.employeeAvailabilityExceptions;

  const dateRange = useMemo(() => getDatePeriod(startDate, endDate), [startDate, endDate]);

  const getContractName = useGetContractName();

  const [{ data: locationWorkingPatternsData }] = useLocationWorkingPatternsQuery({
    variables: {
      locationId,
      date: dateToApiFormat(startDate)
    },
    requestPolicy: 'cache-and-network'
  });

  const workingPatterns = locationWorkingPatternsData?.locationWorkingPatterns?.employeePatterns;
  if (!workingPatterns) {
    throw Error('Could not get working patterns');
  }

  const getStaffSettings = useStaffSettings(state => state.settingsForStaffMember);

  const mapToLeaveTypeId = useMapToLeaveTypeId();
  const getDayOfWeekAndHoliday = useGetDayOfWeekAndHoliday(startDateToFetch);

  const tasks = useTasks();
  const includeBreaks = useSystemSettings(state => state.settings.includeBreaksInTotals);

  const summary = useMemo(() => {
    return buildSummary({
      employees: employees as ReadEmployee[],
      rosteredStaff,
      dateRange,
      allAvailabilityData: allAvailabilityData as EmployeeAvailabilityExceptions[],
      addReassignedShifts,
      locationName,
      locationNumber,
      getContractName,
      getStaffSettings,
      workingPatterns: workingPatterns as EmployeePattern[],
      getDayOfWeekAndHoliday,
      mapToLeaveTypeId,
      tasks,
      includeBreaks
    });
  }, [
    employees,
    rosteredStaff,
    dateRange,
    allAvailabilityData,
    addReassignedShifts,
    locationNumber,
    locationName,
    getContractName,
    getStaffSettings,
    tasks,
    includeBreaks
  ]);

  const refetch = useCallback(() => {
    refetchRosteredStaff();
  }, [refetchRosteredStaff]);

  const homeEmployeesSummary = useMemo(() => {
    return summary.filter(employee => employee.details.homeLocationId === selectedLocationId);
  }, [summary]);

  return {
    homeEmployeesSummary,
    allEmployeesSummary: summary,
    refetch
  };
};

export const useSummaryDayStats = (summary: SummaryEmployee[], dateRange: Date[]) => {
  return useMemo(() => {
    return buildSummaryDayStats(summary, dateRange);
  }, [summary, dateRange]);
};

export const useSummaryStatsForDay = (summary: SummaryEmployee[], date: Date) => {
  return useMemo(() => {
    const stats = buildSummaryDayStats(summary, [date]);

    if (!stats || stats.length === 0) {
      throw Error('No stats provided for selected day.');
    }

    return stats[0];
  }, [summary, date]);
};
