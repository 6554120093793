import { strings } from '@/services/translation/strings';
import { Document, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import {FC, ReactNode, useMemo} from 'react';
import {Style} from "@react-pdf/types";
import {StaffAvailabilityMonth} from "@/services/availability/useStaffAvailability";
import {PdfTableCell} from "@/pages/pdf/PdfTableCell";
import {styles} from "@/pages/pdf/PdfStyles";
import {PdfPage} from "@/pages/pdf/PdfPage";
import {PdfDateRange} from "@/pages/pdf/summary/PdfDateRange";
import {PdfAvailabilityEmployeeRow} from "@/pages/pdf/availability/PdfAvailabilityEmployeeRow";
import {PdfAvailabilityParams} from "@/pages/pdf/availability/PdfAvailabilityDay";

interface Props {
  days: Date[];
  availabilityForMonth: StaffAvailabilityMonth[];
  language: string;
  params: PdfAvailabilityParams;
}

export const MONTHLY_NAME_WIDTH = 100;

const PAGE_LENGTH = 15;

export const MonthlyAvailabilityAsPDF: FC<Props> = ({days, availabilityForMonth, language, params}) => {
  const pages: StaffAvailabilityMonth[][] = useMemo(() => {
    const pages: StaffAvailabilityMonth[][] = [];
    const rowsToAdd = [...availabilityForMonth];
    while (rowsToAdd.length > 0) {
      const pageToAdd = rowsToAdd.splice(0, PAGE_LENGTH);
      pages.push(pageToAdd);
    }

    return pages;
  }, [availabilityForMonth]);

  const date = new Date();
  const formatString = language === 'ja' ? 'yyyy年MMMM' : 'MMMM yyyy';
  let months = format(days[0], formatString, { locale: language === 'ja' ? ja : enUS });
  if (days[days.length - 1].getMonth() !== days[0].getMonth()) {
    months += ` - ${format(days[days.length - 1], formatString, { locale: language === 'ja' ? ja : enUS })}`;
  }

  const title = strings.settings.pages.availability.print.fileTitle(months);
  const header: ReactNode =(<View>
    <Text style={{ fontSize: 10 }}>{`${params.locationId}: ${params.locationName}`}</Text>
    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
      <Text style={{ fontSize: 18 }}>{title}</Text>
    </View>
  </View>);
  const pageStyle: Style = {paddingHorizontal: 30, paddingVertical: 20, fontFamily: 'Nasu'}

  return (
    <Document>
      <PdfPage datePrinted={date} orientation={'landscape'} headerContent={header} pageStyle={pageStyle}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <PdfTableCell width={MONTHLY_NAME_WIDTH} />
            <PdfDateRange days={days} />
          </View>
          {pages[0].map((employee: StaffAvailabilityMonth) => (
            <PdfAvailabilityEmployeeRow key={employee.employee.identityId} data={employee} params={params} />
          ))}
        </View>
      </PdfPage>
      {pages.slice(1).map((page, index) => (
        <PdfPage
          key={index}
          datePrinted={date}
          headerContent={header}
          orientation={'landscape'}
          pageStyle={pageStyle}
        >
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <PdfTableCell width={MONTHLY_NAME_WIDTH} />
              <PdfDateRange days={days} />
            </View>
            {page.map((employee: StaffAvailabilityMonth) => (
              <PdfAvailabilityEmployeeRow key={employee.employee.identityId} data={employee} params={params} />
            ))}
          </View>
        </PdfPage>
      ))}
    </Document>
  );
};
