import { secondsToHours } from 'date-fns';

export const adjustPadLength = (scratchpad: number[], pad: 'start' | 'end', length: number) => {
  let newScratchpad = [...scratchpad];

  if (newScratchpad.length > length) {
    if (pad === 'start') {
      newScratchpad = newScratchpad.slice(newScratchpad.length - length, newScratchpad.length);
    } else {
      newScratchpad = newScratchpad.slice(0, length)
    }
  }

  while (newScratchpad.length < length) {
    newScratchpad.splice(pad === 'start' ? 0 : newScratchpad.length, 0, 0)
  }

  return newScratchpad;
};

export const calculateScratchpadLength = (start: number, end: number) => {
  return secondsToHours(end - start) * 2;
};
