import { convertBlobToJson, useBlobService } from '@/services/blob/useBlobService';
import { useVariable } from '@softwareimaging/backstage';

const TENANTS_TO_UPDATE = ['pal', 'sundi', 'demo'];
const AVAILABILITY_DEADLINE_CRONJOB = 'SendAvailabilityDeadlineNotification';
const CONTAINER_NAME = 'scheduled-jobs';

interface CronJob {
  Name: string;
  RequestUrl: string;
  Schedule: string;
  Suspend: boolean;
}

const updateSchedular = (entries: any[], deadlineDay: number, deadlineHour: number) => {
  entries.forEach(item => {
    const job = item.CronJobs.find((item: CronJob) => item.Name === AVAILABILITY_DEADLINE_CRONJOB) as CronJob;
    const scheduleArray = job.Schedule.split(' ');
    scheduleArray[1] = String(deadlineHour);
    scheduleArray[2] = String(deadlineDay);

    job.Schedule = scheduleArray.join(' ');
  });
};

export const useUpdateAvailabilityDeadline = () => {
  const cronjobBlobName = useVariable('cronjobBlobName');
  if (!cronjobBlobName) throw new Error('Missing Cron job blob name');

  const blobServiceClient = useBlobService();

  const update = async (deadlineDay: number, deadlineHour: number) => {
    const container = blobServiceClient.getContainerClient(CONTAINER_NAME);
    const blob = container.getBlobClient(cronjobBlobName);
    const data = await blob.download();
    const json = (await convertBlobToJson(data)) as any[];

    const entriesToUpdate = json.filter(item => TENANTS_TO_UPDATE.includes((item.TenantName as string).toLowerCase()));
    updateSchedular(entriesToUpdate, deadlineDay, deadlineHour);

    const newBlob = new Blob([JSON.stringify(json)], { type: 'text/plain;charset=utf-8' });
    container.uploadBlockBlob(cronjobBlobName, newBlob, newBlob.size);
  };

  return update;
};
