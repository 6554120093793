import { SelectOption } from '@/components/form/SelectInput';
import { displaySecondsAsTime } from '@/components/gantt/gantt/ganttHelper';
import { minutesToSeconds } from 'date-fns';
import { useMemo } from 'react';

export const useMinuteOptions = (minHour: number = 0, maxHour: number = 2, increments: number = 5) => {
  const minutesInHour = 60;

  return useMemo(() => {
    const options: number[] = [];

    // for each hour, add the increments
    for (let i = minHour; i < maxHour; i++) {
      // Fix here: iterate from minHour to maxHour inclusively
      for (let j = 0; j < minutesInHour; j += increments) {
        options.push(j + i * minutesInHour); // Correctly generate minute increments within the hour
      }
    }

    options.push(maxHour * minutesInHour); // Add the last hour

    const selectOptions: SelectOption[] = options.map(val => ({
      value: minutesToSeconds(val), // Converts the value to seconds
      name: displaySecondsAsTime(val * 60) // Display in the correct format, assumes function works properly
    }));

    return selectOptions;
  }, [minHour, maxHour, increments]);
};
