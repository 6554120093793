import { Button } from '@/components/button';
import { showToastMessage, toastError, toastMessage } from '@/helpers/helper';
import { useSubPages } from '@/pages/layout/main/useNavigationOptions';
import { useUpdateSystemSettings } from '@/pages/settings/services/useUpdateSystemSettings';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OperationResult } from 'urql';
import { PageHeader } from '../../layout/main/PageHeader';
import { useEditSettingsService } from '../services/EditSettingsService';

export enum SettingsSubpage {
  SYSTEM = 'system',
  STAFF = 'staff',
  STAFF_SKILLS = 'staffSkills',
  AVAILABILITY = 'availability',
  MANAGE_LOCATIONS = 'manageLocations',
  SKILLS = 'skills',
  TASKS = 'tasks',
  PERSONAS = 'personas',
  LEAVE = 'advancedLeave',
  ACCOUNTS = 'accounts',
  ESS_MESSAGES = 'essMessages',
  ORGANISATION_CHART = 'organisationChart'
}

export const SettingsHeader: FC<{ hideDefaultSaveFor: SettingsSubpage[] }> = ({ hideDefaultSaveFor = [] }) => {
  const { updateSettings: updateSystemSettings, fetching } = useUpdateSystemSettings();

  const workingSystemSettings = useEditSettingsService(state => state.workingSystemSettings);

  const { saveNeeded } = useConfirm(state => ({ saveNeeded: state.confirmationNeeded }));
  const location = useLocation();
  const mainPath = location.pathname.split('/');
  const mainRoute = '/' + mainPath[1];
  const subpages = useSubPages(mainRoute);

  const selectedSubpage = (mainPath[2] || subpages[0]?.path?.slice(1)) as SettingsSubpage;
  const selectedSubpageRoute = '/' + selectedSubpage;

  const onSave = async () => {
    let result;
    switch (selectedSubpage) {
      case SettingsSubpage.SYSTEM:
        if (Number(workingSystemSettings.minShiftDuration) > Number(workingSystemSettings.defaultShiftDuration)) {
          toastMessage(strings.settings.system.roster.settings.minShiftDuration.minShiftCannotExceedDefaultDuration);
          return;
        }

        result = await updateSystemSettings(workingSystemSettings);
        break;
    }

    result ? showToastMessage(result as OperationResult) : toastError();
  };

  const navigate = useNavigate();

  return (
    <PageHeader className="justify-between h-14">
      <div className="flex gap-5">
        {subpages.map(subp => (
          <Button
            key={mainRoute + subp.path}
            size="sm"
            variant={selectedSubpageRoute === subp.path ? 'active' : subp.disabled ? 'ghost' : 'default'}
            onClick={!subp.disabled ? () => navigate(`${mainRoute}${subp.path}`) : undefined}
            className={`${subp.name.length > 14 ? 'max-w-[200px]' : 'w-100'} h-12`}
          >
            {subp.name}
          </Button>
        ))}
      </div>
      {/* User story for changing the position of the saving button is going to be added. For now we just don't need it if managing skills page is selected */}
      {(hideDefaultSaveFor.length === 0 || !hideDefaultSaveFor.includes(selectedSubpage)) && (
        <Button onClick={onSave} loading={fetching} variant={saveNeeded ? 'submit' : undefined}>
          {strings.common.save}
        </Button>
      )}
    </PageHeader>
  );
};
