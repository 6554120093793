import { DurationInput } from '@/components/form/DurationInput';
import { NumberInput } from '@/components/form/NumberInput';
import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { deltaToColorClass } from '../../../helpers/deltaHelper';
import { WorkingBudgetDay, WorkingBudgetLocation } from '../../../services/budget/budget.types';
import { BudgetCell } from '../BudgetCell';
import { useFindShiftsQuery } from '@/services/gql/graphql.generated';
import { StaffRequestingLocation } from '@/pages/daily/daily.types';
import { StaffRequestingInfo } from '@/pages/daily/roster/employeeSelector/StaffRequestingInfo';
import { isBefore, startOfDay } from 'date-fns';
import { buildCellId } from './cellHelper';
import { useBudgetTab } from './useBudgetTab';
import { useBudgetService } from '../BudgetService';

export const BudgetDay: FC<{
  day: WorkingBudgetDay;
  location: WorkingBudgetLocation;
  locationIndex: number;
  totalDays: number;
  dayIndex: number;
  setForecast: (newVal: number) => void;
  setSalesTarget: (newVal: number) => void;
}> = ({ day, location, totalDays, dayIndex, locationIndex, setForecast, setSalesTarget }) => {
  const staffSearchParams = {
    locationIds: [location.id],
    date: dateToApiFormat(day.date),
    propertyPath: 'requesting',
    propertyValue: 'true'
  };
  const [{ data: requestingData }] = useFindShiftsQuery({
    variables: { searchParams: staffSearchParams },
    requestPolicy: 'network-only'
  });

  const staffRequestingShifts = useMemo(() => {
    if (requestingData) {
      const requestingLocations = (requestingData?.findShiftsByProperty as StaffRequestingLocation[]) || [];
      const foundLocation = requestingLocations.find(l => l.locationId === location.id);
      return foundLocation?.shifts || [];
    }

    return [];
  }, []);

  const delta = day.rosteredBudget - day.locationForecast;

  const navigate = useNavigate();

  const { setLocation, setDate } = useLocalSettings(state => ({
    setLocation: state.setSelectedLocationId,
    setDate: state.setSelectedDate
  }));

  const onDeltaClick = () => {
    setDate(dateToApiFormat(day.date));
    setLocation(location.id);
    navigate('/rosters');
  };

  const infoPopupPosition = `${
    dayIndex === totalDays - 2 ? 'right-[-60px]' : dayIndex === totalDays - 1 ? 'right-[-20px]' : 'left-[-80px]'
  }`;

  const getWaitingAllocationIcon = (): string => {
    const currentDate = startOfDay(new Date());

    const isDateBefore = isBefore(day.date, currentDate);

    let icon = 'bg-[url("/person.svg")]';
    if (isDateBefore) {
      if (staffRequestingShifts.every(s => s.employee)) {
        icon = 'bg-[url("/person-fulfilled.svg")]';
      }
      icon = 'bg-[url("/person-expired.svg")]';
    }

    return icon;
  };

  const { budgetData } = useBudgetService(state => ({
    budgetData: state.locations
  }));

  const locationCount = budgetData.length;
  const dayCount = budgetData[0].days.length;

  const refCallback = useBudgetTab(locationCount, dayCount, 2);

  return (
    <>
      <BudgetCell className="justify-center">
        <NumberInput
          value={day.financialTarget}
          setValue={setSalesTarget}
          dataTabId={buildCellId(locationIndex, dayIndex, 0)}
          ref={refCallback}
        ></NumberInput>
      </BudgetCell>
      <BudgetCell className="justify-center">{secondsToTimeString(day.hqAllocatedHours)}</BudgetCell>
      <BudgetCell className="justify-center py-0.5">
        <DurationInput
          className="text-xs w-full"
          value={day.locationForecast}
          setValue={setForecast}
          dataTabId={buildCellId(locationIndex, dayIndex, 1)}
          ref={refCallback}
        />
      </BudgetCell>
      <BudgetCell className="justify-center">{secondsToTimeString(day.rosteredBudget)}</BudgetCell>
      <BudgetCell className={twMerge(deltaToColorClass(delta), 'justify-center underline')}>
        <button tabIndex={-1} className="px-1 hover:bg-active rounded-sm" onClick={onDeltaClick}>
          {secondsToTimeString(delta, true)}
        </button>
      </BudgetCell>
      <BudgetCell className="justify-center pointer-events-none hover:bg-[#F3980220]">
        {staffRequestingShifts.length > 0 && (
          <div className="absolute group hover:cursor-pointer pointer-events-auto">
            <div
              className={twMerge(
                `left-0 bg-[url('/person.svg')] hover:bg-[url('/person-hover.svg')] bg-center w-5 h-5`,
                getWaitingAllocationIcon()
              )}
              onClick={onDeltaClick}
            ></div>
            <div className={`${infoPopupPosition} hidden absolute top-6 group-hover:block`}>
              <StaffRequestingInfo requestingShifts={staffRequestingShifts} />
            </div>
          </div>
        )}
      </BudgetCell>
    </>
  );
};
