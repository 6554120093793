import { NotesButton, NotesModal } from '@/components/notes/NotesModal';
import { useEditSettingsService } from '@/pages/settings/services/EditSettingsService';
import { useUpdateStaffSettings } from '@/pages/settings/services/useUpdateStaffSettings';
import { usePersonas } from '@/services/personas/usePersonas';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { FC, useState } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { StaffRowCell } from './StaffRowCell';
import StaffRowDetailsTooltip from './StaffRowDetailsTooltip';

export interface EditNotesFields {
  notes?: string;
}

export const StaffRowDetailsSection: FC<StaffRowSectionProps> = ({ staff, group, onChangeSetting }) => {
  const { update } = useUpdateStaffSettings();

  const personas = usePersonas();
  const personaOptions: SelectItemOptionsType = personas.map(p => ({ label: p.name, value: p.id }));

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onClose = () => {
    setModalOpen(false);
  };

  const submitNotes = async (data: EditNotesFields) => {
    staff.settings.notes = data.notes;
    await update([staff]);
  };

  const { workingStaffSettingsErrors } = useEditSettingsService(state => ({
    workingStaffSettingsErrors: state.workingStaffSettingsErrors
  }));

  const employeeId = staff.employee.employeeId || strings.settings.manageStaff.casual;

  const workingStaffErrors = workingStaffSettingsErrors.find(staff => staff.employeeId === employeeId);

  return (
    <>
      <NotesModal
        open={modalOpen}
        close={onClose}
        submitNotes={submitNotes}
        noteValue={staff.settings.notes}
        emptyNoteString={strings.settings.manageStaff.noNotes}
      >
        <h2>
          {displayName({
            firstName: staff.employee.firstName,
            lastName: staff.employee.lastName
          })}
        </h2>
      </NotesModal>
      <StaffRowCell staff={staff} left={0}>
        <div className="flex items-center space-x-2">
          <p>{employeeId}</p>
          {workingStaffErrors && workingStaffErrors.errors.length > 0 && (
            <StaffRowDetailsTooltip employeeId={workingStaffErrors.employeeId} errors={workingStaffErrors.errors} />
          )}
        </div>
      </StaffRowCell>
      <StaffRowCell staff={staff} left={110} hidden={group.columns[0].isColumnHidden} className="flex">
        <span className="flex-1">
          {displayName({
            firstName: staff.employee.firstName,
            lastName: staff.employee.lastName
          })}
        </span>
        <NotesButton onClick={() => setModalOpen(true)} />
      </StaffRowCell>
      <StaffRowCell staff={staff} left={240} hidden={group.columns[1].isColumnHidden}>
        {displayName({
          firstName: staff.employee.kanjiFirstName,
          lastName: staff.employee.kanjiLastName
        })}
      </StaffRowCell>
      <StaffRowCell staff={staff} left={340} hidden={group.columns[2].isColumnHidden}>
        <MultiSelect
          id={`staff-persona-multiselect-${staff.employee.identityId}`}
          value={staff.settings.assignedPersonaIds.filter(id => personas.find(p => p.id === id))}
          onChange={e => onChangeSetting('assignedPersonaIds')(e.value)}
          options={personaOptions}
          filter
          display="chip"
          placeholder={strings.settings.manageStaff.selectPersonas}
          className="skills-multiselect multiselect--expanded w-full"
        />
      </StaffRowCell>
    </>
  );
};
