import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { MarkedButton } from '@/components/buttons/MarkedButton';
import { SelectInput } from '@/components/form/SelectInput';
import { TextInputField } from '@/components/form/TextInputField';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { strings } from '@/services/translation/strings';
import { FC, useMemo } from 'react';
import ColorSelect from '../../ColorSelect';
import { TableColumn } from '../../layout/SettingsTable';
import { SettingsTableCell } from '../../layout/SettingsTableCell';
import { getColumnWidthStyles, updateObjectByKeys } from '../../layout/settingsTableHelper';
import { LeaveType, useLeavePeriodOptions } from '@/services/leaveManagement/leave.types';

interface Props {
  leaveType: LeaveType;
  deleteEnabled?: boolean;
  isToBeDeleted?: boolean;
  columns: TableColumn[];
  onUpdate: (leave: LeaveType) => void;
  onDelete: (id: string, isToBeDeleted: boolean) => void;
  parentContainerId: string;
  fixed?: boolean;
  excludedColors: string[];
}

export const Leave: FC<Props> = ({
  leaveType,
  deleteEnabled,
  isToBeDeleted,
  columns,
  onUpdate,
  onDelete,
  parentContainerId,
  fixed,
  excludedColors
}) => {
  const onChange = updateObjectByKeys(onUpdate, leaveType);

  const optionsForUnits = useMemo(() => Array.from(Array(151).keys()).map(n => ({ value: n, name: n.toString() })), []);
  const periodOptions = useLeavePeriodOptions();

  const isRdo = leaveType.id === FixedLeaveTypeId.ROSTER;

  return (
    <>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[0])}>
        {!fixed && <Toggle value={leaveType.active} onChange={e => onChange('active', e)} />}
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[1])}>
        {fixed ? (
          <div>{leaveType.name}</div>
        ) : (
          <TextInputField value={leaveType.name} setValue={e => onChange('name', e)}></TextInputField>
        )}
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[2])}>
        <TextInputField
          value={leaveType.properties.displayName}
          setValue={e => onChange('properties.displayName', e)}
        ></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[3])}>
        <TextInputField
          value={leaveType.properties.systemCode}
          setValue={e => onChange('properties.systemCode', e)}
        ></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[4])}>
        <ColorSelect
          excludedColors={excludedColors}
          readOnly={fixed}
          className="w-full"
          selectedColor={leaveType.properties.color}
          onSelectColor={color => onChange('properties.color', color)}
          dropdownId={`colors-${leaveType.id}`}
          parentContainerId={parentContainerId}
        />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center gap-3" styles={getColumnWidthStyles(columns[5])}>
        {isRdo ? (
          <span>{strings.common.na}</span>
        ) : (
          <>
            <SelectInput
              value={leaveType.limitSettings.max.value}
              options={optionsForUnits}
              setValue={e => onChange('limitSettings.max.value', parseInt(e))}
            />
            <div>{strings.settings.manageLeave.per}</div>
            <SelectInput
              value={leaveType.limitSettings.max.period}
              options={periodOptions}
              setValue={e => onChange('limitSettings.max.period', e)}
            />
            <div className="flex flex-col items-center space-y-1">
              <div>{strings.settings.manageLeave.mandatory}</div>
              <Toggle
                value={leaveType.limitSettings.max.mandatory}
                onChange={e => onChange('limitSettings.max.mandatory', e)}
              />
            </div>
          </>
        )}
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center gap-3" styles={getColumnWidthStyles(columns[6])}>
        {isRdo ? (
          <span>{strings.common.na}</span>
        ) : (
          <>
            <SelectInput
              value={leaveType.limitSettings.min.value}
              options={optionsForUnits}
              setValue={e => onChange('limitSettings.min.value', parseInt(e))}
            />
            <div>{strings.settings.manageLeave.per}</div>
            <SelectInput
              value={leaveType.limitSettings.min.period}
              options={periodOptions}
              setValue={e => onChange('limitSettings.min.period', e)}
            />
            <div className="flex flex-col items-center space-y-1">
              <div>{strings.settings.manageLeave.mandatory}</div>
              <Toggle
                value={leaveType.limitSettings.min.mandatory}
                onChange={e => onChange('limitSettings.min.mandatory', e)}
              />
            </div>
          </>
        )}
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center gap-3" styles={getColumnWidthStyles(columns[7])}>
        {isRdo ? (
          <span>{strings.common.na}</span>
        ) : (
          <>
            <SelectInput
              value={leaveType.limitSettings.consecutiveMax}
              options={optionsForUnits}
              setValue={e => onChange('limitSettings.consecutiveMax', parseInt(e))}
            />
            <div>{strings.settings.manageLeave.canOverride}</div>
            <Toggle
              value={leaveType.limitSettings.consecutiveOverride}
              onChange={e => onChange('limitSettings.consecutiveOverride', e)}
            />
          </>
        )}
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center gap-3" styles={getColumnWidthStyles(columns[8])}>
        {isRdo ? (
          <span>{strings.settings.manageLeave.alwaysShown}</span>
        ) : (
          <Toggle
            value={leaveType.limitSettings.availableInEss}
            onChange={e => onChange('limitSettings.availableInEss', e)}
          />
        )}
      </SettingsTableCell>
      {deleteEnabled && (
        <SettingsTableCell styles={getColumnWidthStyles(columns[columns.length - 1])}>
          {!isToBeDeleted && !fixed && (
            <Button size="sm" variant="destructive" onClick={() => onDelete(leaveType.id, true)} className="w-20">
              {strings.common.delete}
            </Button>
          )}
          {isToBeDeleted && (
            <MarkedButton
              label={strings.common.toBeDeleted}
              mainClass="text-[#c23934]"
              buttonClass="border-[#c23934] hover:bg-[#c2393420]"
              onButtonClick={() => onDelete(leaveType.id, false)}
            />
          )}
        </SettingsTableCell>
      )}
    </>
  );
};
