import { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';

interface Props {
  label: string;
  mainClass: string;
  buttonClass: string;
  onButtonClick: () => void;
}

export const MarkedButton: FC<Props> = ({ label, mainClass, buttonClass, onButtonClick }) => {
  return (
    <div
      className={twMerge(`flex flex-row items-center rounded-md gap-1.5 font-thin select-none text-primary`, mainClass)}
    >
      <span className="text-sm leading-none text-center">{label}</span>
      <div
        className={twMerge(
          `rounded-md bg-white hover:cursor-pointer border border-[#0070d2] hover:bg-[#0070d210]`,
          buttonClass
        )}
        onClick={onButtonClick}
      >
        <FiX className={`h-4 w-4`} />
      </div>
    </div>
  );
};
