import { SelectOption } from '@/components/form/SelectInput';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { useMemo } from 'react';

export const useStartDateOptions = () => {
  const language = useLanguageProvider(state => state.language);

  const options = useMemo(() => {
    const rosterDateOptions: SelectOption[] = [];
    const currentDate = new Date(0);

    while (rosterDateOptions.length < 28) {
      rosterDateOptions.push({
        name: format(currentDate, 'do', { locale: language === 'ja' ? ja : enUS }),
        value: currentDate.getDate()
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    rosterDateOptions.push({ name: strings.settings.system.lastOfMonth, value: 31 });

    return rosterDateOptions;
  }, [language]);

  return options;
};
