import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { SettingsTableHeader } from './SettingsTableHeader';

export interface TableColumn {
  name: string;
  width: number | string;
  tooltipText?: string;
  isFixedPosition?: boolean;
  ignoreZIndex?: boolean;
}

interface Props {
  id?: string;
  columns: TableColumn[];
  showIndexColumn?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const INDEX_COLUMN_WIDTH = 100;

export const SettingsTable: FC<Props> = ({ id, columns, showIndexColumn, children, className }) => {
  const gridTemplate = useMemo(() => {
    // Template starts with the number column.
    let gridTemplate = `${showIndexColumn ? INDEX_COLUMN_WIDTH + 'px ' : ''}`;

    columns.forEach((col, idx) => {
      gridTemplate += `${[0, columns.length].includes(idx) ? '' : ' '}${
        typeof col.width !== 'string' ? col.width + 'px' : col.width
      }`;
    });

    return gridTemplate;
  }, [columns]);

  return (
    <div id={id} className={twMerge(className, 'grid overflow-auto')} style={{ gridTemplateColumns: gridTemplate }}>
      <SettingsTableHeader columns={columns} showIndexColumn={showIndexColumn} />
      {children}
    </div>
  );
};
