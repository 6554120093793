import { hoursToSeconds } from "date-fns";
import { WorkingPatterns } from "./systemSettings.types";

export const useDefaultWorkingPattern = () => {
  const defaultPattern: WorkingPatterns[] = [];
  const days = [0, 1, 2, 3, 4, 5, 6, 7];
  days.forEach(day => {
    defaultPattern.push({
      day: day,
      startTime: hoursToSeconds(9.5),
      endTime: hoursToSeconds(21.5),
      id: ''
    });
  });

  return defaultPattern;
}