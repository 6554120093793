import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { MarkedButton } from '@/components/buttons/MarkedButton';
import { GridDropdownType, GridSelect, GridSelectOption } from '@/components/form/GridSelect';
import { SelectInput, SelectOption } from '@/components/form/SelectInput';
import { TextInputField } from '@/components/form/TextInputField';
import { iconArray } from '@/helpers/dropdownOptionHelper';
import { generateSelectOptions } from '@/helpers/helper';
import { strings } from '@/services/translation/strings';
import { MultiSelect } from 'primereact/multiselect';
import { FC, useEffect, useState } from 'react';
import { SkillType } from '../../../../services/tasks/skillSettings.types';
import { TaskType, TextColors } from '../../../../services/tasks/task.types';
import ColorSelect from '../../ColorSelect';
import { TableColumn } from '../../layout/SettingsTable';
import { SettingsTableCell } from '../../layout/SettingsTableCell';
import { getColumnWidthStyles, updateObjectByKeys } from '../../layout/settingsTableHelper';

interface TaskProps {
  parentContainerId?: string;
  task: TaskType;
  index: number;
  showIndexColumn?: boolean;
  deleteTaskEnabled?: boolean;
  isToBeDeleted?: boolean;
  skillsList?: SkillType[];
  columns: TableColumn[];
  onTaskUpdate: (task: TaskType) => void;
  onTaskDelete: (taskId: number | string, isToBeDeleted: boolean) => void;
}

export const textColorOptions: SelectOption[] = [
  { name: strings.colors.black, value: TextColors.BLACK },
  { name: strings.colors.white, value: TextColors.WHITE }
];

export const Task: FC<TaskProps> = ({
  parentContainerId,
  task,
  index,
  showIndexColumn,
  deleteTaskEnabled,
  isToBeDeleted,
  skillsList,
  columns,
  onTaskUpdate,
  onTaskDelete
}) => {
  const [workingTask, setWorkingTask] = useState<TaskType>(task);
  const [skillsToSelect, setSkillsToSelect] = useState<SelectOption[]>([]);

  useEffect(() => {
    setSkillsToSelect(generateSelectOptions(skillsList!, 'name', 'id'));
  }, [skillsList]);

  const durationOptions: SelectOption[] = [
    { name: '0', value: 0 },
    { name: '10', value: 10 },
    { name: '15', value: 15 },
    { name: '30', value: 30 },
    { name: '45', value: 45 },
    { name: '60', value: 60 },
    { name: '120', value: 120 },
    { name: strings.settings.manageTasks.wholeShift, value: 99999999999999999 }
  ];

  const onChange = updateObjectByKeys(newTask => {
    setWorkingTask(newTask);
    onTaskUpdate(newTask);
  }, workingTask);

  return (
    <div key={workingTask.id} className="flex">
      {showIndexColumn && <SettingsTableCell indexCell>{index + 1}</SettingsTableCell>}
      <SettingsTableCell className="justify-center" styles={getColumnWidthStyles(columns[0])}>
        <Toggle value={workingTask.isActive} onChange={e => onChange('isActive', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[1])}>
        <TextInputField value={workingTask.name} setValue={e => onChange('name', e)}></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[2])}>
        <TextInputField value={workingTask.displayName} setValue={e => onChange('displayName', e)}></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[3])}>
        {/* ICON DROPDOWN */}
        <GridSelect
          type={GridDropdownType.ICON}
          selected={workingTask.icon}
          dropdownOptions={iconArray}
          itemsPerRow={8}
          className="w-full"
          dropdownId={`icons-${workingTask.id}`}
          parentContainerId={parentContainerId}
        >
          {iconArray.map((icon, index) => {
            return (
              <GridSelectOption
                key={index}
                className="p-[6px] text-[24px]"
                isSelected={icon.id === workingTask.icon}
                onChange={e => onChange('icon', e.id)}
                options={iconArray}
                option={icon}
                type={GridDropdownType.ICON}
              ></GridSelectOption>
            );
          })}
        </GridSelect>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[4])}>
        {/* TEXT COLOR DROPDOWN */}
        <SelectInput
          options={textColorOptions}
          value={workingTask.textColor}
          setValue={newValue => onChange('textColor', newValue)}
        />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[5])}>
        {/* COLORS DROPDOWN */}
        <ColorSelect
          className="w-full"
          selectedColor={workingTask.color}
          onSelectColor={color => onChange('color', color)}
          dropdownId={`colors-${workingTask.id}`}
          parentContainerId={parentContainerId}
        />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[6])}>
        <SelectInput
          options={durationOptions}
          value={workingTask.defaultDuration}
          setValue={newValue => onChange('defaultDuration', newValue)}
        />
      </SettingsTableCell>
      <SettingsTableCell className="justify-center" styles={getColumnWidthStyles(columns[7])}>
        <Toggle value={workingTask.isSupplementary} onChange={e => onChange('isSupplementary', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="justify-center" styles={getColumnWidthStyles(columns[8])}>
        <Toggle value={workingTask.isInMonthlyTotals} onChange={e => onChange('isInMonthlyTotals', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="justify-center" styles={getColumnWidthStyles(columns[9])}>
        <Toggle
          value={workingTask.isInCalculatedWorkingHours}
          onChange={e => onChange('isInCalculatedWorkingHours', e)}
        />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[10])}>
        {/* SKILLS DROPDOWN MULTISELECT */}
        {skillsToSelect.length > 0 && (
          <MultiSelect
            id={`task-skills-multiselect-${task.id}`}
            value={workingTask.skills}
            onChange={e => onChange('skills', e.value)}
            options={skillsToSelect.filter(s => !workingTask.optionalSkills?.includes(s.value))}
            optionLabel="name"
            filter
            display="chip"
            placeholder={strings.settings.manageTasks.selectSkills}
            className="skills-multiselect multiselect--expanded w-full"
          />
        )}
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center" styles={getColumnWidthStyles(columns[11])}>
        {/* OPTIONAL SKILLS DROPDOWN MULTISELECT */}
        {skillsToSelect.length > 0 && (
          <MultiSelect
            id={`task-skills-multiselect-${task.id}`}
            value={workingTask.optionalSkills}
            onChange={e => onChange('optionalSkills', e.value)}
            options={skillsToSelect.filter(s => !workingTask.skills?.includes(s.value))}
            optionLabel="name"
            filter
            display="chip"
            placeholder={strings.settings.manageTasks.selectSkills}
            className="skills-multiselect multiselect--expanded w-full"
          />
        )}
      </SettingsTableCell>
      {deleteTaskEnabled && (
        <SettingsTableCell styles={getColumnWidthStyles(columns[12])}>
          {!isToBeDeleted && (
            <Button size="sm" variant="destructive" onClick={() => onTaskDelete(task.id, true)} className="w-20">
              {strings.common.delete}
            </Button>
          )}
          {isToBeDeleted && (
            <MarkedButton
              label={strings.common.toBeDeleted}
              mainClass="text-[#c23934]"
              buttonClass="border-[#c23934] hover:bg-[#c2393420]"
              onButtonClick={() => onTaskDelete(task.id, false)}
            />
          )}
        </SettingsTableCell>
      )}
    </div>
  );
};
