import { Button } from '@/components/button';
import { Modal } from '@/components/modal/Modal';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const TemplateLimitModal: FC<Props> = ({ open, onClose }) => {
  const templateListMax = useSystemSettings(state => state.settings.maxCustomTemplates);

  return (
    <Modal onClose={close} open={open} title={strings.daily.templates.myTemplates.limitModal.title}>
      <div className="space-y-4">
        <div className="space-y-2">
          <span className="font-semibold text-sm">
            {strings.daily.templates.myTemplates.limitModal.info(templateListMax)}
          </span>
        </div>
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={onClose}>{strings.common.ok}</Button>
        </div>
      </div>
    </Modal>
  );
};
