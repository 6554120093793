import { ReadTemplate } from '@/services/gql/graphql.generated';
import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { useCallback, useMemo } from 'react';
import uuid from 'react-uuid';
import { useGqlTemplateToWorkingTemplate } from '../template/templateHelper';
import { useWorkingRoster } from './providers/WorkingRosterService';

export const useReplaceRoster = () => {
  const { workingRoster, setWorkingRoster } = useWorkingRoster();
  const { allTemplateOptions } = useTemplatesForLocation();
  const templateList = useMemo(() => allTemplateOptions.map(t => t.template as ReadTemplate), [allTemplateOptions]);

  const gqlTemplateToWorkingRoster = useGqlTemplateToWorkingTemplate(true);

  return useCallback(
    (templateId: string) => {
      if (!workingRoster) {
        throw Error('Roster must be defined');
      }

      const template = templateList.find(tem => tem.id === templateId);

      if (!template) {
        throw Error('Could not find template in list');
      }

      const newWorkingRoster = { ...workingRoster };
      const templateAsWorkingRoster = gqlTemplateToWorkingRoster(template as ReadTemplate);
      newWorkingRoster.templateId = templateId;
      newWorkingRoster.templateName = template.name;
      newWorkingRoster.shifts = templateAsWorkingRoster.shifts;
      newWorkingRoster.rosterHistoryNotes = templateAsWorkingRoster.templateHistoryNotes;
      newWorkingRoster.notes = templateAsWorkingRoster.notes;
      newWorkingRoster.startTime = templateAsWorkingRoster.startTime;
      newWorkingRoster.endTime = templateAsWorkingRoster.endTime;
      newWorkingRoster.scratchpad = templateAsWorkingRoster.scratchpad;
      newWorkingRoster.openTime = templateAsWorkingRoster.openTime;
      newWorkingRoster.closeTime = templateAsWorkingRoster.closeTime;

      // Clear the old task IDs.
      newWorkingRoster.shifts.forEach(shift => {
        shift.tasks.forEach(task => {
          task.id = `CREATE-${uuid()}`;
        });
      });

      setWorkingRoster(newWorkingRoster);
    },
    [workingRoster, templateList, setWorkingRoster]
  );
};
