import { View } from '@react-pdf/renderer';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  lastCell?: boolean;
  width?: number;
  height?: number;
  dashed?: boolean;
  flex?: number;
}

export const PdfTableCell: FC<Props> = ({ lastCell, width, height, children, dashed, flex }) => {
  return (
    <View
      style={{
        width,
        height,
        flex: width === undefined ? flex ?? 1 : undefined,
        borderRightWidth: lastCell ? 0 : 0.5,
        flexDirection: 'row',
        alignItems: 'center',
        borderStyle: dashed ? 'dashed' : 'solid',
        opacity: dashed ? 0.2 : 1,
        zIndex: dashed ? 50 : undefined
      }}
    >
      {children}
    </View>
  );
};
