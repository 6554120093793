import Toggle from '@/components/Toggle';
import { Button } from '@/components/button';
import { MarkedButton } from '@/components/buttons/MarkedButton';
import { TextInputField } from '@/components/form/TextInputField';
import { strings } from '@/services/translation/strings';
import React, { FC, useState } from 'react';
import { SkillType } from '../../../../services/tasks/skillSettings.types';
import { SettingsTableCell } from '../../layout/SettingsTableCell';
import { updateObjectByKeys } from '../../layout/settingsTableHelper';

export enum FilterOptions {
  ACTIVE = 'active',
  ALL = 'all',
  INACTIVE = 'inactive'
}

interface SkillProps {
  skill: SkillType;
  index: number;
  showIndexColumn?: boolean;
  deleteSkillEnabled?: boolean;
  isToBeDeleted?: boolean;
  onSkillUpdate: (skill: SkillType) => void;
  onSkillDelete: (skillId: string, isToBeDeleted: boolean) => void;
}

export const Skill: FC<SkillProps> = ({
  skill,
  index,
  showIndexColumn,
  deleteSkillEnabled,
  isToBeDeleted,
  onSkillUpdate,
  onSkillDelete
}) => {
  const [workingSkill, setWorkingSkill] = useState<SkillType>(skill);

  const onChange = updateObjectByKeys((newSkill: SkillType) => {
    setWorkingSkill(newSkill);
    onSkillUpdate(newSkill);
  }, workingSkill);

  return (
    <React.Fragment>
      {showIndexColumn && <SettingsTableCell indexCell>{index + 1}</SettingsTableCell>}
      <SettingsTableCell className="justify-center">
        <Toggle value={workingSkill.isActive} onChange={e => onChange('isActive', e)} />
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center">
        <TextInputField value={workingSkill.name} setValue={e => onChange('name', e)}></TextInputField>
      </SettingsTableCell>
      <SettingsTableCell className="px-2 justify-center">
        <TextInputField value={workingSkill.tooltip} setValue={e => onChange('tooltip', e)}></TextInputField>
      </SettingsTableCell>
      {deleteSkillEnabled && (
        <SettingsTableCell>
          {!isToBeDeleted && (
            <Button size="sm" variant="destructive" onClick={() => onSkillDelete(skill.id, true)} className="w-20">
              {strings.common.delete}
            </Button>
          )}
          {isToBeDeleted && (
            <MarkedButton
              label={strings.common.toBeDeleted}
              mainClass="text-[#c23934]"
              buttonClass="border-[#c23934] hover:bg-[#c2393420]"
              onButtonClick={() => onSkillDelete(skill.id, false)}
            />
          )}
        </SettingsTableCell>
      )}
    </React.Fragment>
  );
};
