import { secondsToTimeString } from '@/helpers/dateHelper';
import { PrintViewTypes } from '@/pages/pdf/roster/usePrintOptions';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { styles } from '../../PdfStyles';
import { PdfTableCell } from '../../PdfTableCell';
import { PdfParams, PdfSummaryDay } from '../PdfSummaryDay';
import { MONTHLY_NAME_WIDTH, MONTHLY_TOTAL_WIDTH } from './PdfMonthlyDocument';

interface Props {
  days: Date[];
  employee: SummaryEmployee;
  params: PdfParams;
}

export const PdfMonthlyEmployeeRow: FC<Props> = ({ days, employee, params }) => {
  const total = employee.compliance.actualTime;
  const min = employee.settings.minMonthlyHours;
  const max = employee.settings.maxMonthlyHours;

  let color: string | undefined = undefined;
  if (min && total < min) {
    color = '#0070D1';
  } else if (max && total > max) {
    color = '#D53D6B';
  }

  const complicance = employee.compliance;

  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={MONTHLY_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{employee.name}</Text>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{employee.role}</Text>
        </View>
      </PdfTableCell>
      {params.viewType === PrintViewTypes.SCREEN && (
        <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
          <Text style={{ ...styles.tableCell }}>{complicance.holidays}</Text>
        </PdfTableCell>
      )}
      {params.viewType === PrintViewTypes.SCREEN && (
        <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
          <Text style={{ ...styles.tableCell }}>{complicance.attendanceDays}</Text>
        </PdfTableCell>
      )}
      {params.viewType === PrintViewTypes.SCREEN && (
        <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
          <View style={{ padding: '2px 0px' }}>
            <Text style={{ ...styles.tableCell }}>{secondsToTimeString(max)}</Text>
            <Text style={{ ...styles.tableCell }}>{secondsToTimeString(min)}</Text>
          </View>
        </PdfTableCell>
      )}
      {params.viewType === PrintViewTypes.SCREEN && (
        <PdfTableCell width={MONTHLY_TOTAL_WIDTH / 4}>
          <Text style={{ ...styles.tableCell, color }}>{secondsToTimeString(total)}</Text>
        </PdfTableCell>
      )}
      {days.map((day: Date, dayIndex: number) => (
        <PdfTableCell lastCell={dayIndex === days.length - 1}>
          <PdfSummaryDay day={day} employee={employee} params={params} />
        </PdfTableCell>
      ))}
    </View>
  );
};
