import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { deltaToColorClass } from '@/helpers/deltaHelper';
import { WorkingBudgetDay } from '@/services/budget/budget.types';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BudgetCell } from '../../budget/BudgetCell';
import { BudgetShiftsCell } from './BudgetShiftsCell';

export const BudgetDay: FC<{
  day: WorkingBudgetDay;
}> = ({ day }) => {
  const navigate = useNavigate();
  const { setDate } = useLocalSettings(state => ({
    setDate: state.setSelectedDate
  }));

  const onDeltaClick = () => {
    if (day !== null) {
      setDate(dateToApiFormat(day.date));
      navigate('/rosters');
    }
  };

  const delta = day.rosteredBudget - day.locationForecast;

  return (
    <>
      <BudgetCell className="justify-center">{secondsToTimeString(day.hqAllocatedHours)}</BudgetCell>
      <BudgetCell className="justify-center">{secondsToTimeString(day.locationForecast)}</BudgetCell>
      <BudgetCell className="justify-center">{secondsToTimeString(day.rosteredBudget)}</BudgetCell>
      <BudgetCell className={twMerge(day && deltaToColorClass(delta), 'justify-center underline')}>
        <button tabIndex={-1} className="px-1 hover:bg-active rounded-sm" onClick={onDeltaClick}>
          {secondsToTimeString(delta, true)}
        </button>
      </BudgetCell>
      <BudgetShiftsCell day={day} />
    </>
  );
};
