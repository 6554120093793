import { dateToApiFormat } from '@/helpers/dateHelper';
import { ReadRoster, useGetRostersOverviewQuery } from '../gql/graphql.generated';
import { useDaysInCurrentMonth } from '../settings/useDates';
import { useCallback } from 'react';

export const useGetRostersForMonth = (locationId: number) => {
  const dates = useDaysInCurrentMonth();
  const [{ data }, refetchMutation] = useGetRostersOverviewQuery({
    variables: {
      locationId,
      startDate: dateToApiFormat(dates[0]),
      endDate: dateToApiFormat(dates[dates.length - 1])
    },
    requestPolicy: 'cache-and-network'
  });

  const rosters = data?.rostersInDateRange as ReadRoster[];

  if (!rosters) {
    throw Error('Roster data not available');
  }

  const refetch = useCallback(async () => {
    await refetchMutation({ requestPolicy: 'network-only' });
  }, [refetchMutation]);

  return { rosters, refetch };
};
