import { useUpdateStaffSettings } from '@/pages/settings/services/useUpdateStaffSettings';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { useGetMeQuery } from '../gql/graphql.generated';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { useGetDefaultPreferences } from '../settings/useGetDefaultPreferences';
import { useGetMySettings } from '../settings/useGetMySettings';
import { EditSettingsProvider } from '@/pages/settings/services/EditSettingsService';

export enum MONTHLY_SORT_TYPES {
  max = 1,
  min = 2,
  periodTotal = 3,
  shopPosition = 4,
  name = 5,
  employeeId = 6
}

export interface MonthlySortConfig {
  type: MONTHLY_SORT_TYPES;
  asc: boolean;
}

interface MonthlySortStore {
  sortOrder: MonthlySortConfig[];
  setSortOrder: (order: MonthlySortConfig[]) => void;
}

const MonthlySortContext = createContext<StoreApi<MonthlySortStore> | null>(null);

const DEFAULT_SORT_ORDER: MonthlySortConfig[] = [
  {
    type: MONTHLY_SORT_TYPES.max,
    asc: true
  },
  {
    type: MONTHLY_SORT_TYPES.min,
    asc: true
  },
  {
    type: MONTHLY_SORT_TYPES.periodTotal,
    asc: true
  },
  {
    type: MONTHLY_SORT_TYPES.shopPosition,
    asc: true
  },
  {
    type: MONTHLY_SORT_TYPES.name,
    asc: true
  },
  {
    type: MONTHLY_SORT_TYPES.employeeId,
    asc: true
  }
];

const MonthlySortProviderInner: FC<PropsWithChildren> = ({ children }) => {
  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);

  const [_, refetch] = useGetMeQuery();
  const mySettings = useGetMySettings();
  const defaultPreferences = useGetDefaultPreferences();
  const { update } = useUpdateStaffSettings();

  const defaultSortOrder = useMemo(() => {
    let order = DEFAULT_SORT_ORDER;

    if (defaultPreferences.monthlySortOrder) {
      order = defaultPreferences.monthlySortOrder;
    }

    return order.filter(item => (displayIds ? true : item.type !== MONTHLY_SORT_TYPES.employeeId));
  }, [defaultPreferences, displayIds]);

  const store = useMemo(() => {
    return createStore<MonthlySortStore>(set => ({
      sortOrder: defaultSortOrder,
      setSortOrder: async (newVal: MonthlySortConfig[]) => {
        set(state => ({ ...state, sortOrder: newVal }));
        mySettings!.settings.preferences = {
          ...defaultPreferences,
          monthlySortOrder: newVal
        };
        await update([mySettings!]);
        refetch({ requestPolicy: 'network-only' });
      }
    }));
  }, []);

  return <MonthlySortContext.Provider value={store}>{children}</MonthlySortContext.Provider>;
};

export const MonthlySortProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <EditSettingsProvider>
      <MonthlySortProviderInner>{children}</MonthlySortProviderInner>
    </EditSettingsProvider>
  );
};

type SelectorReturn<S extends (s: MonthlySortStore) => any> = ReturnType<S>;

export function useMonthlySortService<S extends (s: MonthlySortStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(MonthlySortContext);
  if (!context) {
    throw new Error('useMonthlySortService must be used within a MonthlySortProvider');
  }
  return useStore(context, selector);
}
