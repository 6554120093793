import { Timestamp } from '@/components/Timestamp';
import { Button } from '@/components/button';
import { dateToApiFormat } from '@/helpers/dateHelper';
import DownloadReportButtonWithModal from '@/pages/pdf/DownloadReportButtonWithModal';
import { PdfParams } from '@/pages/pdf/summary/PdfSummaryDay';
import { SummaryExcelDownloadButton } from '@/pages/summary/download/SummaryExcelDownloadButton';
import { DeleteRostersButton } from '@/pages/summary/reset/DeleteRostersButton';
import { useBudgetForLocation } from '@/services/budget/useBudget';
import { useBudgetStats } from '@/services/budget/useBudgetStats';
import { useLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { useRosterNotes } from '@/services/rosters/useRosterNotes';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { format } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { MonthSelector } from '../../layout/MonthSelector';
import { LocationSelector } from '../../layout/main/LocationSelector';
import { PageHeader } from '../../layout/main/PageHeader';
import { usePrintOptions } from '../../pdf/roster/usePrintOptions';
import { MonthlyReportAsPDF } from '../../pdf/summary/monthly/PdfMonthlyDocument';
import { NotifyVacantToggle } from '../notifyVacant/NotifyVacantToggle';
import { OptimiserButton } from '../optimiser/OptimiserButton';
import { PublishRostersButton } from '../publish/PublishRostersButton';
import { usePublishSummary } from '../publish/usePublishSummary';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { ComplianceTotals } from '@/services/summary/buildCompliance';

interface Props {
  onOptimiseComplete: () => void;
  summary: SummaryEmployee[];
  switchView?: any;
  complianceTotals: ComplianceTotals;
}

export const MonthlyPageHeader: FC<Props> = ({
  onOptimiseComplete,
  summary,
  switchView,
  complianceTotals
}) => {
  const {
    summary: { latestPublishDate, allPublished, rosterIds },
    refetch
  } = usePublishSummary();

  const leaveTypes = useLeaveTypes();
  const [getViewType] = usePrintOptions(state => [state.getViewType]);

  const publishDate = useMemo(() => {
    return latestPublishDate ? new Date(latestPublishDate + 'Z') : undefined;
  }, [latestPublishDate]);

  const days = useDaysInCurrentMonth();
  const includeBreaks = useSystemSettings(s => s.settings.includeBreaksInTotals);
  const { budgetData } = useBudgetForLocation(days);
  const budgetStats = useBudgetStats(days);

  const language = useLanguageProvider(s => s.language);

  const debug = useFlag('debug');

  const pastMonth = useMemo(() => {
    const today = dateToApiFormat(new Date());
    const endOfMonth = dateToApiFormat(days[days.length - 1]);

    return endOfMonth <= today;
  }, [days]);

  const selectedLocation = useSelectedLocation();
  const { notes: rosterNotes } = useRosterNotes(days[0], days[days.length - 1], selectedLocation.id);

  const createReport = useCallback(() => {
    const publishDateTimeString = publishDate
      ? strings.summary.lastPublished + ': ' + format(publishDate, 'yyyy/MM/dd HH:mm')
      : undefined;
    const params: PdfParams = { leaveTypes, viewType: getViewType() };
    return (
      <MonthlyReportAsPDF
        locationId={selectedLocation.id.toString()}
        locationName={selectedLocation.name}
        publishDateTime={publishDateTimeString}
        days={days}
        summary={summary}
        notes={rosterNotes}
        language={language}
        budgetData={budgetData}
        budgetStats={budgetStats}
        params={params}
        complianceTotals={complianceTotals}
      />
    );
  }, [
    days,
    summary,
    rosterNotes,
    includeBreaks,
    language,
    budgetData,
    budgetStats,
    leaveTypes,
    getViewType,
    selectedLocation,
    publishDate,
    complianceTotals
  ]);

  return (
    <PageHeader className="flex justify-between">
      <div className="flex gap-5">
        <LocationSelector />
        <MonthSelector showNavigationArrows={true} switchView={switchView} />
      </div>
      <div className="flex gap-5 items-center">
        {publishDate && (
          <Timestamp
            color={allPublished ? 'green' : undefined}
            label={strings.summary.lastPublished}
            timestamp={publishDate}
          />
        )}

        <DownloadReportButtonWithModal
          name={strings.summary.print.monthly.fileName(format(days[0], 'yyyy/MM/dd'))}
          createReport={createReport}
          testRoutePath={'/monthlyTest'}
        />

        <SummaryExcelDownloadButton />
        {debug && <Button onClick={onOptimiseComplete}>Generate Complete Test</Button>}
        <DeleteRostersButton
          onComplete={() => {
            onOptimiseComplete();
            refetch();
          }}
        />
        <NotifyVacantToggle />
        <OptimiserButton
          onOptimiseComplete={onOptimiseComplete}
          disabled={pastMonth}
          haveBeenGenerated={rosterIds.length > 0}
        />
        <PublishRostersButton
          rosterIds={rosterIds}
          published={allPublished}
          onComplete={refetch}
          disabled={pastMonth}
        />
      </div>
    </PageHeader>
  );
};
