import {Button} from '@/components/button';
import {strings} from '@/services/translation/strings';
import {pdf} from '@react-pdf/renderer';
import {FC, useCallback} from 'react';
import {saveAs} from "file-saver";
import {PdfTasksDocument, PdfTasksParams} from "@/pages/pdf/settings/PdfTasksDocument";
import {useTasks} from "@/services/tasks/useTasks";
import {useOrganisationHierarchy} from "@/services/hierarchy/useOrganisationHierarchy";
import {useSkills} from "@/services/tasks/useSkills";
import {format} from "date-fns";

const DownloadTasksButton: FC = () => {
  const tasks = useTasks();
  const skills = useSkills();
  const hierarchy = useOrganisationHierarchy();

  const params: PdfTasksParams = { tasks, skills, organisationName: hierarchy.name };

  const onPrint = useCallback(async () => {
    const fileName = strings.settings.manageTasks.printFileName(params.organisationName, format(new Date(), 'yyyy/MM/dd'));
    const blob = await pdf(<PdfTasksDocument
      params={params}
    />).toBlob();
    saveAs(blob, fileName);
  }, [params]);

  return (
    <Button onClick={onPrint}>
      {strings.common.print}
    </Button>
  );
};

export default DownloadTasksButton;
