import { FC, useMemo, useState } from 'react';
import { NotificationBoxHeader } from './NotificationBoxHeader';
import { FilterOptions, NotificationsRadioButtonsFilter } from './NotificationsRadioButtonsFilter';
import { NotificationListItem } from './NotificationListItem';
import { strings } from '@/services/translation/strings';
import { NotificationItem, NotificationType } from '@/services/notifications/NotificationService';

interface Props {
  notifications: NotificationItem[];
  boxHeightCorrection?: number;
  onClose: () => void;
  markNotificationAsRead: (notificationId: string) => void;
}

export const NotificationBox: FC<Props> = ({ notifications, boxHeightCorrection, onClose, markNotificationAsRead }) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOptions>(FilterOptions.ALL);

  const onFilterChanged = (event: any) => {
    const type = event.target.value;

    setSelectedFilterOption(type);
  };

  const filteredNotifications = useMemo(() => {
    let filtered: NotificationItem[] = [];

    switch (selectedFilterOption) {
      case FilterOptions.ROSTER_GENERATION:
        filtered = notifications.filter(n => n.type === NotificationType.ROSTER_GENERATION);
        break;
      case FilterOptions.ROSTER_PUBLISH:
        filtered = notifications.filter(n => n.type === NotificationType.ROSTER_PUBLISH);
        break;
      default:
        filtered = notifications;
        break;
    }

    return filtered;
  }, [notifications, selectedFilterOption]);

  return (
    <div
      className="absolute flex flex-col right-0 top-16 bg-[white] w-1/4 border-2 border-[#323232bf] rounded select-none overflow-hidden"
      style={{ height: `calc(100% - ${boxHeightCorrection}px)`, zIndex: 150 }}
    >
      <NotificationBoxHeader onClose={onClose} />
      <div className="flex flex-col gap-2.5 flex-1 overflow-hidden">
        <NotificationsRadioButtonsFilter selectedOption={selectedFilterOption} onSelect={onFilterChanged} />
        <div className="flex-1 gap-2.5 overflow-auto space-y-2 px-[20px] pb-[10px]">
          {filteredNotifications.length ? (
            filteredNotifications.map(n => (
              <NotificationListItem notification={n} onView={markNotificationAsRead} key={n.id} />
            ))
          ) : (
            <div className="text-[#323232] font-bold text-center">{strings.notifications.noNotifications}</div>
          )}
        </div>
      </div>
    </div>
  );
};
