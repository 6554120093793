import { cn } from '@/lib/utils';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, HTMLAttributes, PropsWithChildren, createContext, useContext } from 'react';

interface ListProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  itemIds: string[];
  onReorder?: (source: number, destination: number) => void | Promise<void>;
  onDelete?: (id: string) => void;
}

interface ListActions {
  onDelete?: (id: string) => void;
}

const ListContext = createContext<ListActions | null>(null);

const useList = () => {
  const context = useContext(ListContext);
  if (!context) {
    throw new Error('useList must be used within a List');
  }
  return context;
};

export const List: FC<PropsWithChildren<ListProps>> = ({
  children,
  className,
  onDelete,
  onReorder,
  itemIds,
  ...props
}) => {
  return (
    <ListContext.Provider value={{ onDelete }}>
      <DndContext
        onDragEnd={async event => {
          if (event.over && onReorder) {
            const source = itemIds.indexOf(event.active.id as string);
            const destination = itemIds.indexOf(event.over.id as string);
            await onReorder(source, destination);
          }
        }}
      >
        <SortableContext items={itemIds}>{children}</SortableContext>
      </DndContext>
    </ListContext.Provider>
  );
};

interface OrderableProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  index: number;
  buttonClassname?: string;
}

const Handle = () => (
  <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.625" width="3.75" height="3.75" fill="#B0B0B0" />
    <rect y="6.625" width="3.75" height="3.75" fill="#B0B0B0" />
    <rect y="12.625" width="3.75" height="3.75" fill="#B0B0B0" />
    <rect x="5.25" y="0.625" width="3.75" height="3.75" fill="#B0B0B0" />
    <rect x="5.25" y="6.625" width="3.75" height="3.75" fill="#B0B0B0" />
    <rect x="5.25" y="12.625" width="3.75" height="3.75" fill="#B0B0B0" />
  </svg>
);

const Delete = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.45176 0.2491L7.46418 6.26152C7.79631 6.59365 7.7332 7.19481 7.46418 7.46379C7.13205 7.79592 6.59357 7.79592 6.26191 7.46379L0.249489 1.45137C-0.0826447 1.11924 -0.019534 0.518081 0.249489 0.2491C0.581623 -0.0830334 1.12009 -0.0830334 1.45176 0.2491Z"
      fill="#6F6F6F"
    />
    <path
      d="M0.2491 6.23847L6.26152 0.226052C6.59365 -0.106082 7.19481 -0.0429715 7.46379 0.226052C7.79592 0.558185 7.79592 1.09666 7.46379 1.42832L1.45137 7.44074C1.11924 7.77288 0.518081 7.70976 0.2491 7.44074C-0.0830334 7.10861 -0.0830334 6.57014 0.2491 6.23847Z"
      fill="#6F6F6F"
    />
  </svg>
);

export const Orderable: FC<PropsWithChildren<OrderableProps>> = ({
  id,
  children,
  buttonClassname,
  className,
  ...props
}) => {
  const { onDelete } = useList();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...props.style
  };

  return (
    <div
      ref={setNodeRef}
      {...props}
      className={cn('flex w-full items-center justify-center gap-3', className)}
      style={style}
      {...attributes}
    >
      <button className={buttonClassname} type="button" {...listeners}>
        <Handle />
      </button>
      {children}
      {onDelete && (
        <button type="button" onClick={() => onDelete(id)}>
          <Delete />
        </button>
      )}
    </div>
  );
};
