import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { format } from 'date-fns';
import { WorkingShift } from './daily.types';
import { WorkingTemplate } from './template/template.types';
import { TaskType } from '@/services/tasks/task.types';
import { calculateShiftTime } from '@/services/rosters/rosterHelper';

export const BLANK_GUID = '00000000-0000-0000-0000-000000000000';

export const workingIdToGqlId = (workingId: string) => {
  return workingId.includes('CREATE') ? BLANK_GUID : workingId;
};

export const itemTypeToColor = (type: ItemTypes, useColorizedBar?: boolean, isActive?: boolean) => {
  switch (type) {
    case ItemTypes.BREAK:
      return 'rgba(126, 178, 221, 1)';
    case ItemTypes.TASK:
      throw Error('Item type to color no longer supports tasks.');
    case ItemTypes.ROSTER_SHIFT:
      if (useColorizedBar) {
        if (isActive) return 'rgba(110, 235, 131, 0.6)';
        return `repeating-linear-gradient(45deg, #c23934, #c23934 30px,#c23934f0 30px,#c23934f0 60px)`;
      }
      return 'rgba(110, 235, 131, 0.6)';
    case ItemTypes.TEMPLATE_SHIFT:
      return 'rgba(50, 50, 50, 0.5)';
    case ItemTypes.REASSIGNED:
      return '#E8AA14';
  }
};

export const generateDefaultTemplateName = (selectedDate?: Date | string) => {
  selectedDate = selectedDate ? new Date(selectedDate) : new Date();
  return format(selectedDate, 'EEEE_yyyy_MM_dd');
};

export const getDailyAttendanceTotals = (
  workingTemplate: WorkingTemplate,
  tasks: TaskType[],
  includeBreaks?: boolean
) => {
  let templateTotalDaily = 0;
  let templateTotalActual = 0;
  let templateTotalBreak = 0;

  workingTemplate?.shifts?.forEach((s: WorkingShift) => {
    if (s.end && s.start) {
      const totals = calculateShiftTime(s, tasks, includeBreaks);

      templateTotalDaily += totals.basicTime;
      templateTotalActual += totals.actualTime;
      templateTotalBreak += totals.breakTime;
    }
  });

  return {
    templateTotalDaily,
    templateTotalBreak,
    templateTotalActual
  };
};
