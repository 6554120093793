import { Button } from '@/components/button';
import { FilterOption, RadioFilterSelector } from '@/components/flterSelector/RadioFilterSelector';
import { toastError, toastSuccess } from '@/helpers/helper';
import { LocationSelector } from '@/pages/layout/main/LocationSelector';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { StaffSettingsErrors, StaffWithSettings } from '@/services/settings/systemSettings.types';
import { StaffSettingsFilterOptions } from '@/services/settings/useFilterStaffSettings';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { FC, ReactNode, useMemo, useState } from 'react';
import { SettingsSectionHeader } from '../../layout/SettingsSectionHeader';
import { useEditSettingsService } from '../../services/EditSettingsService';
import { useDeleteStaff } from '../../services/useDeleteStaff';
import { useUpdateStaffSettings } from '../../services/useUpdateStaffSettings';
import StaffSettingsErrorModal from './StaffSettingsErrorModal';

interface Props {
  filterOption: StaffSettingsFilterOptions;
  setFilterOption: (newOpt: StaffSettingsFilterOptions) => void;
  employeeIdsToUpdate: string[];
  employeeIdsToDelete: string[];
  additionalControls?: ReactNode[];
}

const validateEmployees = (employees: StaffWithSettings[]) => {
  const invalidEmployees: StaffSettingsErrors[] = [];

  employees.forEach(employee => {
    const errors: string[] = [];

    const settings = employee.settings;
    const employeeName = displayName({
      firstName: employee.employee.firstName,
      lastName: employee.employee.lastName
    });
    const employeeId = employee.employee.employeeId || strings.settings.manageStaff.casual;
    if (settings.maxMonthlyHours < settings.minMonthlyHours) {
      errors.push(strings.settings.staffFilter.errors.invalidMonthlyTimes(employeeId, employeeName));
    }
    if (settings.maxWeeklyHours < settings.minWeeklyHours) {
      errors.push(strings.settings.staffFilter.errors.invalidMonthlyTimes(employeeId, employeeName));
    }
    settings.workingPatterns.forEach(workingPattern => {
      if (workingPattern.endTime < workingPattern.startTime) {
        errors.push(
          strings.settings.staffFilter.errors.invalidWorkPatternTimes(
            employeeId,
            employeeName,
            strings.common.daysOfWeekFull[workingPattern.day]
          )
        );
      }
    });

    if (errors.length > 0) {
      invalidEmployees.push({
        employeeId,
        errors
      });
    }
  });

  return invalidEmployees;
};

const validateDeletedEmployees = (employees: StaffWithSettings[]) => {
  const invalidEmployees: StaffSettingsErrors[] = [];

  employees.forEach(employee => {
    const errors: string[] = [];

    const settings = employee.settings;
    const employeeName = displayName({
      firstName: employee.employee.firstName,
      lastName: employee.employee.lastName
    });
    const employeeId = employee.employee.employeeId || strings.settings.manageStaff.casual;

    if (!settings.leftCompanyDate) {
      errors.push(strings.settings.staffFilter.errors.invalidLeavingDate(employeeId, employeeName));
    }

    if (errors.length > 0) {
      invalidEmployees.push({
        employeeId,
        errors
      });
    }
  });

  return invalidEmployees;
};

export const StaffSettingsFilterHeader: FC<Props> = ({
  filterOption,
  setFilterOption,
  employeeIdsToUpdate,
  employeeIdsToDelete,
  additionalControls
}) => {
  const filterOptions: FilterOption[] = useMemo(() => {
    return [
      {
        displayName: strings.settings.staffFilter.displayAll,
        value: StaffSettingsFilterOptions.ALL
      },
      {
        displayName: strings.settings.staffFilter.displayCurrent,
        value: StaffSettingsFilterOptions.CURRENT
      },

      {
        displayName: strings.settings.staffFilter.displayPast,
        value: StaffSettingsFilterOptions.PAST
      }
    ];
  }, []);

  const { saveNeeded } = useConfirm(state => ({ saveNeeded: state.confirmationNeeded }));

  const { settings, setWorkingStaffSettingsErrors } = useEditSettingsService(state => ({
    settings: state.workingStaffSettings,
    setWorkingStaffSettingsErrors: state.setWorkingStaffSettingsErrors
  }));

  const { update, fetching: fetchingUpdate } = useUpdateStaffSettings();
  const { deleteEmployees, fetching: fetchingDelete } = useDeleteStaff();
  const fetching = fetchingDelete || fetchingUpdate;

  const [workingStaffSettingsModalErrors, setWorkingStaffSettingsModalErrors] = useState<string[]>([]);

  const onSave = async () => {
    try {
      let gqlError = false;

      const employeesSettingsToUpdate = settings.filter(staff =>
        employeeIdsToUpdate.includes(staff.employee.identityId)
      );

      const updateErrors = validateEmployees(employeesSettingsToUpdate);

      const employeeSettingsToDelete = settings.filter(staff =>
        employeeIdsToDelete.includes(staff.employee.identityId)
      );

      const deleteErrors = validateDeletedEmployees(employeeSettingsToDelete);

      const errors = [...updateErrors, ...deleteErrors];

      if (errors.length > 0) {
        setWorkingStaffSettingsErrors(errors);
        setWorkingStaffSettingsModalErrors(
          errors.reduce<string[]>((acc, obj) => {
            return [...acc, ...obj.errors];
          }, [])
        );
        setStaffSettingsErrorModalOpen(true);
        return;
      } else {
        setWorkingStaffSettingsErrors([]);
      }

      const updateResult = await update(employeesSettingsToUpdate);
      if (updateResult?.error) {
        gqlError = true;
      }

      const deleteResult = await deleteEmployees(employeeSettingsToDelete);
      if (deleteResult.error) {
        gqlError = true;
      }

      if (gqlError) {
        toastError();
      } else {
        toastSuccess();
      }
    } catch (error) {
      console.log('Update staff settings error', error);
    }
  };

  const [staffSettingsErrorModalOpen, setStaffSettingsErrorModalOpen] = useState(false);

  return (
    <>
      <StaffSettingsErrorModal
        errors={workingStaffSettingsModalErrors}
        open={staffSettingsErrorModalOpen}
        onClose={() => setStaffSettingsErrorModalOpen(false)}
      />
      <SettingsSectionHeader hasBottomBorder={true}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <LocationSelector />
            <RadioFilterSelector options={filterOptions} selectedOption={filterOption} onSelect={setFilterOption} />
          </div>
          <div className="flex items-center space-x-2">
            {additionalControls?.map(control => control)}
            <Button
              className="w-24"
              loading={fetching}
              variant={saveNeeded ? 'submit' : undefined}
              onClick={onSave}
              disabled={saveNeeded ? undefined : true}
            >
              {strings.common.save}
            </Button>
          </div>
        </div>
      </SettingsSectionHeader>
    </>
  );
};
