import { Tooltip, TooltipTypes } from '@/components/tooltip/Tooltip';

import { FunctionComponent } from 'react';
import { MdWarning } from 'react-icons/md';

interface StaffRowDetailsTooltipProps {
  employeeId: string;
  errors: string[];
}

const StaffRowDetailsTooltip: FunctionComponent<StaffRowDetailsTooltipProps> = ({ employeeId, errors }) => {
  return (
    <div className="flex space-x-1">
      <MdWarning className="text-red-500 text-lg" />
      <Tooltip type={TooltipTypes.error}>
        {errors.map((error, index) => (
          <p key={`error-${employeeId}-${index}`}>{error}</p>
        ))}
      </Tooltip>
    </div>
  );
};

export default StaffRowDetailsTooltip;
