import { Button } from "@/components/button";
import { strings } from "@/services/translation/strings";
import {FC, useState} from "react";
import {RostersDeleteModal} from "@/pages/summary/reset/RostersDeleteModal";
interface Props {
  onComplete: () => void;
}

export const DeleteRostersButton: FC<Props> = ({onComplete}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex gap-5 items-center font-semibold">
      <Button loading={false} onClick={() => setIsModalOpen(true)}>
        {strings.summary.resetRosters.buttonLabel}
      </Button>
      <RostersDeleteModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onComplete={onComplete}
      />
    </div>
  );
};
