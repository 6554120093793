import { useRole } from '@/services/authentication/hooks/user';
import { useFlag } from '@softwareimaging/backstage';
import { FC } from 'react';
import { SystemSettings } from './SystemSettings';
import { RoleType } from '@/services/authentication/roles';
import { AdminSettings } from './AdminSettings';

export const SystemSettingsView: FC = () => {
  const superAdmin = useFlag('superAdmin');
  const role = useRole();

  return (
    <div className="overflow-auto">
      <SystemSettings />
      {(role === RoleType.Super_Admin || !!superAdmin) && <AdminSettings />}
    </div>
  );
};
