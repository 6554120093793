import { ToggleField } from '@/components/Toggle';
import { Button } from '@/components/button';
import { useGetMeQuery } from '@/services/gql/graphql.generated';
import { useGetDefaultPreferences } from '@/services/settings/useGetDefaultPreferences';
import { useGetMySettings } from '@/services/settings/useGetMySettings';
import { strings } from '@/services/translation/strings';
import { Form } from '@softwareimaging/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Setting } from '../layout/Setting';
import { SettingsSection } from '../layout/SettingsSection';
import { useUpdateStaffSettings } from '../services/useUpdateStaffSettings';
import { DailyRosterHeader } from './DailyRosterHeader';

export interface PreferenceFields {
  staffPicker: {
    status: boolean;
    timeAvailable: boolean;
    allocation: boolean;
    role: boolean;
    holidays: boolean;
    attendances: boolean;
    workingHours: boolean;
    tasks: boolean;
    taskNames: boolean;
  };
}

export const StaffPickerColumns: FC = () => {
  const [_, refetch] = useGetMeQuery();
  const mySettings = useGetMySettings();
  const defaultPreferences = useGetDefaultPreferences();
  const { update, fetching } = useUpdateStaffSettings();
  const prefStrings = strings.settings.preferences;

  const methods = useForm<PreferenceFields>({
    defaultValues: {
      staffPicker: defaultPreferences.staffPicker
    }
  });

  const submitPrefs = async (data: PreferenceFields) => {
    mySettings!.settings.preferences = {
      ...defaultPreferences,
      staffPicker: data.staffPicker
    };
    await update([mySettings!]);
    methods.reset(data);
    refetch({ requestPolicy: 'network-only' });
  };

  const onCancel = () => {
    methods.reset();
  };

  return (
    <div className="overflow-auto h-full pb-5">
      <Form methods={methods} onSubmit={submitPrefs}>
        <DailyRosterHeader />
        <SettingsSection title={prefStrings.dailyRoster.staffPicker}>
          <Setting title={prefStrings.dailyRoster.status}>
            <ToggleField id="status" name="staffPicker.status" className="justify-self-center"></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.timeAvailable}>
            <ToggleField
              id="timeAvailable"
              name="staffPicker.timeAvailable"
              className="justify-self-center"
            ></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.allocation}>
            <ToggleField id="allocation" name="staffPicker.allocation" className="justify-self-center"></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.role}>
            <ToggleField id="role" name="staffPicker.role" className="justify-self-center"></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.totalHolidays}>
            <ToggleField id="holidays" name="staffPicker.holidays" className="justify-self-center"></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.attendances}>
            <ToggleField id="attendances" name="staffPicker.attendances" className="justify-self-center"></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.workingHours}>
            <ToggleField
              id="workingHours"
              name="staffPicker.workingHours"
              className="justify-self-center"
            ></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.tasks}>
            <ToggleField id="tasks" name="staffPicker.tasks" className="justify-self-center"></ToggleField>
          </Setting>
          <Setting title={prefStrings.dailyRoster.capableTasks}>
            <ToggleField id="taskNames" name="staffPicker.taskNames" className="justify-self-center"></ToggleField>
          </Setting>
        </SettingsSection>
        <div className="px-5 mt-2 gap-4 flex">
          <Button disabled={!methods.formState.isDirty} type="button" onClick={onCancel}>
            {strings.common.cancel}
          </Button>
          <Button disabled={!methods.formState.isDirty} variant={'primary'} type="submit" loading={fetching}>
            {strings.common.save}
          </Button>
        </div>
      </Form>
    </div>
  );
};
