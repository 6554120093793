import { dateToApiFormat } from '@/helpers/dateHelper';
import { StaffDayTimes } from '@/services/availability/useStaffAvailability';
import {
  UpdateAvailabilityExceptions,
  useDeleteAvailabilityExceptionMutation,
  useUpdateAvailabilityExceptionsMutation
} from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { OperationResult } from 'urql';

export const useSaveAvailability = (date: Date, userId: string) => {
  const [{ fetching: updateFetching }, updateException] = useUpdateAvailabilityExceptionsMutation();
  const [{ fetching: deleteFetching }, deleteException] = useDeleteAvailabilityExceptionMutation();

  const fetching = updateFetching || deleteFetching;

  const saveAvailability = useCallback(
    async (leaveTypeId?: string, times?: StaffDayTimes[]) => {
      let result: OperationResult;
      if (!!leaveTypeId || times !== undefined) {
        const exceptions: UpdateAvailabilityExceptions[] = times?.map(t => {
          return {
            employeeId: userId,
            date: dateToApiFormat(date),
            available: !leaveTypeId,
            leaveTypeId: leaveTypeId,
            start: t.start || 0,
            end: t.end || 0
          }
        }) || [];

        result = await updateException({ start: dateToApiFormat(date), end: dateToApiFormat(date), exceptions });
      } else {
        result = await deleteException({ employeeId: userId, date: dateToApiFormat(date) });
      }

      return result;
    },
    [date, userId]
  );

  return { saveAvailability, fetching };
};
