import { SeverityType, severityTypeToColor } from '@/components/warning/warningDefinitions';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, Suspense } from 'react';
import { FaTriangleExclamation } from 'react-icons/fa6';
import * as RadixTooltip from '@radix-ui/react-tooltip';

interface Props extends PropsWithChildren {
  severity: SeverityType;
  parentContainerId: string;
}

export const WarningPopup: FC<Props> = ({ children, severity }) => {
  const color = severityTypeToColor(severity);

  return (
    <RadixTooltip.Provider delayDuration={100}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger>
          <FaTriangleExclamation className="h-4 w-4" color={color} />
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <Suspense>
            <RadixTooltip.Content
              side={'right'}
              sideOffset={5}
              className="z-[1000] bg-background-mid border rounded-xl p-2 w-[350px] text-sm"
            >
              <div className="flex items-center space-x-2 mb-1" style={{ borderColor: color }}>
                <FaTriangleExclamation className="h-4 w-4" color={color} />
                <div className="font-bold drop-shadow text-sm" style={{ color: color }}>
                  {severity === SeverityType.CRITICAL
                    ? strings.common.severity.critical
                    : strings.common.severity.warning}
                </div>
              </div>
              <div className="space-y-1.5">{children}</div>
            </RadixTooltip.Content>
          </Suspense>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
