import { Svg, Path } from "@react-pdf/renderer";
import { FC } from "react";

export const SvgIcon: FC<{ paths: string[]; viewBoxSize: number; size?: number; color?: string }> = ({
  paths,
  viewBoxSize,
  size,
  color = '#FFFFFF'
}) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
      {paths.map((path, index) => (
        <Path key={index} fill={color} d={path} />
      ))}
    </Svg>
  );
};