import { DailyEditingType } from '@/pages/daily/daily.types';
import { getTimeAvailable } from '@/pages/daily/roster/rosterHelper';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { TaskType } from '@/services/tasks/task.types';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { Tooltip, TooltipTypes } from '../../../components/tooltip/Tooltip';
import { BaseTaskTooltipContent } from './BaseTaskTooltipContent';
import { useAvailableCapableRosteredStaffForTask, useCapableRosterStaffForTask } from './useCapableEmployees';

interface Props {
  taskId: string;
  type?: DailyEditingType;
  color: string;
}

interface RosterTooltipProps {
  task?: TaskType;
  date: string;
}

const RosterTaskTooltipTitle: FC<RosterTooltipProps> = ({ task, date }) => {
  const availableCapableEmployees = useAvailableCapableRosteredStaffForTask(task);

  return (
    <p>
      {strings.daily.drag.taskTooltips.capableStaffCount(
        availableCapableEmployees,
        new Date(date).toLocaleDateString('ja-JP')
      )}
    </p>
  );
};

const RosterTooltipContent: FC<RosterTooltipProps> = ({ task, date }) => {
  const capableEmployees = useCapableRosterStaffForTask(task);

  return (
    <div className="gap-1 flex flex-col pt-1 border-t-2">
      <div>
        <span className="font-bold">{strings.daily.drag.taskTooltips?.staffWhoCanPerform}</span>
      </div>
      {capableEmployees && capableEmployees.length > 0 ? (
        <ul className="overflow-y-auto max-h-64">
          {capableEmployees.map(employeeWithSkills => {
            const schedule = employeeWithSkills.schedule.find(s => s.date === date);
            const hasAvailableTime = schedule?.availableTimes && schedule.availableTimes.length > 0;

            return (
              <li key={employeeWithSkills.id} className="flex justify-between">
                <span className="w-80">{employeeWithSkills.name}</span>
                {hasAvailableTime ? (
                  <span className="text-primary w-full">
                    {strings.daily.drag.taskTooltips.availableToday(
                      schedule.availableTimes!.map(t => getTimeAvailable(t)).join(' / ')
                    )}
                  </span>
                ) : (
                  <span className="text-red-600 w-full">{strings.daily.drag.taskTooltips.notAvailableToday}</span>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <span>{strings.daily.drag.taskTooltips.noStaffCanPerformTask}</span>
      )}
    </div>
  );
};

const TaskTooltipContent: FC<{ task?: TaskType; color: string }> = ({ task, color }) => {
  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));

  return (
    <div className="font-regular space-y-2 text-black">
      <div className="flex justify-between gap-5">
        <div style={{ color }} className="font-bold whitespace-nowrap">
          {task?.displayName}
        </div>
        <div className="flex items-center gap-1">
          <RosterTaskTooltipTitle task={task} date={selectedDate} />
        </div>
      </div>
      <BaseTaskTooltipContent taskId={task?.id} />
      <RosterTooltipContent task={task} date={selectedDate} />
    </div>
  );
};

export const RosterTaskTooltip: FC<Props> = ({ color, taskId }) => {
  const tasks = useTasks();
  const task = tasks.find(task => task.id == taskId);
  const availableCapableEmployees = useAvailableCapableRosteredStaffForTask(task);

  return (
    <Tooltip
      position="top"
      className="max-h-[500px] overflow-y-auto w-[400px]"
      type={availableCapableEmployees === 0 ? TooltipTypes.error : TooltipTypes.info}
    >
      <TaskTooltipContent color={color} task={task} />
    </Tooltip>
  );
};
