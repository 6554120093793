import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { TableColumn } from '../../layout/SettingsTable';
import { useFlag } from '@softwareimaging/backstage';

export const usePersonaColumns = () => {
  const deletePersonaEnabled = useFlag('deletePersonas');
  const columns: TableColumn[] = useMemo(() => {
    const cols: TableColumn[] = [
      {
        name: '',
        width: 100
      },
      {
        name: strings.settings.managePersonas.personaName,
        width: 400
      },
      {
        name: strings.settings.managePersonas.admin.name,
        width: 150,
        tooltipText: strings.settings.managePersonas.admin.tooltip
      },
      {
        name: strings.settings.managePersonas.management.name,
        width: 150,
        tooltipText: strings.settings.managePersonas.management.tooltip
      },
      {
        name: strings.settings.managePersonas.templateLibrarian.name,
        width: 150,
        tooltipText: strings.settings.managePersonas.templateLibrarian.tooltip
      },
      { name: '', width: 'auto' }
    ];

    if (deletePersonaEnabled) {
      cols.push({
        name: '',
        width: 100
      });
    }

    return cols;
  }, [deletePersonaEnabled]);

  return columns;
};
