import { Button } from '@/components/button';
import { Modal } from '@/components/modal/Modal';
import {useDeleteRostersMutation} from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';
import {useSelectedLocation} from "@/services/settings/LocalSettingsProvider";
import {useDaysInCurrentMonth} from "@/services/settings/useDates";
import {convertDateToGQLFormat} from "@/helpers/dateHelper";

interface Props {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export const RostersDeleteModal: FC<Props> = ({ open, onClose, onComplete }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [{ fetching }, deleteRosters] = useDeleteRostersMutation()
  const { id: locationId } = useSelectedLocation();
  const dates = useDaysInCurrentMonth();

  const onDelete = async () => {
    const result = await deleteRosters({
      locationAndDateRange: {
        locationId: locationId,
        dateRangeStart: convertDateToGQLFormat(dates[0]),
        dateRangeEnd: convertDateToGQLFormat(dates[dates.length - 1])
      }
    });

    if (result.error) {
      setError(strings.summary.resetRosters.error);
    } else {
      onComplete();
      onClose();
    }
  };

  const closeAndClear = () => {
    onClose();
    setTimeout(() => {
      setError(undefined);
    }, 500);
  };

  return (
    <Modal onClose={closeAndClear} open={open} title={strings.summary.resetRosters.modal.title}>
      <div className="space-y-4">
        <div className="space-y-2">
          <span className="font-semibold text-sm">
            {strings.summary.resetRosters.modal.message}
          </span>
        </div>
        {error && <p className="text-red-500 text-xs">{error}</p>}
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={closeAndClear}>{strings.common.cancel}</Button>
          <Button onClick={onDelete} loading={fetching} variant={'destructive'}>
            {strings.common.reset}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
