import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { GanttSection } from '@/components/gantt/gantt/GanttTimePicker';
import { TooltipTypes } from '@/components/tooltip/Tooltip';
import { TaskType } from '@/services/tasks/task.types';
import { useGetTask, useGetTaskIcon } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { BsCupFill } from 'react-icons/bs';
import { DailyEditingType, WorkingShift, WorkingShiftSubsection } from './daily.types';
import { itemTypeToColor } from './dailyHelper';
import { EmployeeForShiftItem, EmployeeStatus } from './employeeSelector/employeeForShift.types';
import { useEmployeesForShift } from './roster/employeeSelector/useEmployeesForShift';

const buildTaskCapability = (employees: EmployeeForShiftItem[], task: TaskType) => {
  const employeesForTask = employees.filter(e => e.taskMatch?.find(t => t.id === task.id));

  const unavailableEmployees = employeesForTask.filter(e => e.status === EmployeeStatus.NOT_AVAILABLE);

  if (unavailableEmployees.length === employees.length)
    return {
      text: strings.daily.taskCapability.unavailable,
      type: TooltipTypes.error
    };

  const availableEmployees = employeesForTask.filter(e => e.status === EmployeeStatus.AVAILABLE);
  if (availableEmployees.length > 0) {
    return {
      text: strings.daily.taskCapability.available,
      type: TooltipTypes.info
    };
  } else {
    return {
      text: strings.daily.taskCapability.partlyAvailable,
      type: TooltipTypes.warn
    };
  }
};

export const useBuildGanttSubSections = (
  shift: WorkingShift,
  onShiftChange: (newShift: WorkingShift) => void,
  type: DailyEditingType
) => {
  const onChangeBreak = (breakIndex: number) => (newStart: number, newEnd: number) => {
    const oldBreak = shift.breaks[breakIndex];
    const newBreak = { ...oldBreak, start: newStart, end: newEnd };

    const newBreakArray = [...shift.breaks];
    newBreakArray.splice(breakIndex, 1, newBreak);

    const newShift = { ...shift, breaks: newBreakArray };
    onShiftChange(newShift);
  };

  const onChangeTask = (taskIndex: number) => (newStart: number, newEnd: number) => {
    const oldTask = shift.tasks[taskIndex];
    const newTask = { ...oldTask, start: newStart, end: newEnd };

    const newTaskArray = [...shift.tasks];
    newTaskArray.splice(taskIndex, 1, newTask);

    const newShift = { ...shift, tasks: newTaskArray };
    onShiftChange(newShift);
  };

  const onClearBreak = (index: number) => () => {
    const newBreakArray = [...shift.breaks];
    newBreakArray.splice(index, 1);

    const newShift = { ...shift, breaks: newBreakArray };
    onShiftChange(newShift);
  };

  const onClearTask = (index: number) => () => {
    const newTaskArray = [...shift.tasks];
    newTaskArray.splice(index, 1);

    const newShift = { ...shift, tasks: newTaskArray };
    onShiftChange(newShift);
  };

  const breaks: GanttSection[] = shift.breaks.map((brek, index) => ({
    id: brek.id,
    startTime: brek.start,
    endTime: brek.end,
    color: itemTypeToColor(ItemTypes.BREAK),
    type: ItemTypes.BREAK,
    onChangeTimes: onChangeBreak(index),
    clear: onClearBreak(index),
    icon: <BsCupFill />,
    iconColor: 'black'
  }));

  const getTaskIcon = useGetTaskIcon();
  const getTask = useGetTask();

  const employeesForShift = useEmployeesForShift(shift);

  const taskSection: GanttSection[] = shift.tasks.map((task, index) => {
    const taskDetails = getTask(task.typeContext || '');

    return {
      id: task.id,
      startTime: task.start,
      endTime: task.end,
      color: taskDetails?.color || '',
      icon: getTaskIcon(task.typeContext || ''),
      iconColor: taskDetails?.textColor,
      tooltip: taskDetails?.displayName,
      type: ItemTypes.TASK,
      typeContext: task.typeContext,
      onChangeTimes: onChangeTask(index),
      clear: onClearTask(index),
      ...(taskDetails &&
        type === DailyEditingType.ROSTER && {
          capabilityTooltip: buildTaskCapability(employeesForShift, taskDetails)
        })
    };
  });

  return breaks.concat(taskSection) as GanttSection[];
};
