import { UpdateTemplate, useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';

export const useReplaceTemplate = () => {
  const [{ fetching, error }, update] = useUpdateTemplateMutation();

  const replaceTemplate = useCallback(
    async (requestedTemplate: UpdateTemplate) => {
      if (!requestedTemplate) {
        throw Error('Template must be defined');
      }

      const result = await update({
        updateTemplate: requestedTemplate
      });

      return result;
    },
    [update]
  );

  return { replaceTemplate, error, fetchingReplace: fetching };
};
