import { useAPIMutation } from '../APIProvider';
import { DownloadStaffSkillPayload } from '../contracts/download';
import { APIError } from '../errors/APIError';

export const useDownloadStaffSkills = () => {
  return useAPIMutation(client => {
    return async (payload: DownloadStaffSkillPayload) => {
      const response = await client.download.downloadStaffSkills({
        body: payload
      });

      if (response.status !== 200) throw APIError.fromResponse(response);

      return response.body;
    };
  });
};
