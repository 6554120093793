import { useCallback } from 'react';
import { getMePreferences } from '../staff/preferences';
import { TaskType } from './task.types';

export const useTaskDisplay = () => {
  const prefs = getMePreferences();

  return useCallback(
    (task: TaskType) => {
      let display = true;
      if (prefs && prefs.taskDisplay) {
        if (prefs.taskDisplay[task.id] === false) {
          display = false;
        }
      }

      if (!task.isActive) {
        display = false;
      }

      return display;
    },
    [prefs]
  );
};
