import { LockUnlockToggle } from '@/components/LockUnlockToggle';
import { cn } from '@/lib/utils';
import { EmployeeSelector } from '@/pages/daily/employeeSelector/EmployeeSelector';
import { EMPLOYEE_DETAILS_COLUMN_WIDTH, EMPLOYEE_NAME_COLUMN_WIDTH } from '@/pages/summary/constants';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { hoursToSeconds } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { FiX } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';
import { EmployeeTooltip } from '../../../components/tooltip/EmployeeTooltip';
import { SeverityIndicator } from '../../../components/warning/SeverityIndicator';
import { useGetContractName } from '../../../services/contract/useGetContractName';
import { AttendanceTotalColumns } from '../AttendanceTotalColumns';
import { GhostShift, ShiftTimePicker } from '../ShiftTimePicker';
import { DailyEditingType } from '../daily.types';
import { MonthlyTotalColumns } from './MonthlyTotalColumns';
import { EmployeeForShiftItem } from '../employeeSelector/employeeForShift.types';
import { useEmployeesForShift } from './employeeSelector/useEmployeesForShift';
import { useWarningsForShift } from './providers/RosterWarningsService';
import { useWorkingRoster } from './providers/WorkingRosterService';
import { WorkingShift } from "@/pages/daily/daily.types";
import { useNextDayShift, usePreviousDayShift } from './useOtherDayShifts';
import {EmployeeOptionSettingsProvider} from "@/pages/daily/employeeSelector/EmployeeOptionSettingsProvider";
import {showStaffPickerColumn} from "@/services/staff/preferences";
import { toastMessage } from '@/helpers/helper';
import { strings } from '@/services/translation/strings';
import {useWorkPreferencesTrackingService} from "@/services/availability/WorkPreferencesTrackingService";

interface Props {
  shift: WorkingShift;
  readOnly?: boolean;
  updateShift: (newShift: WorkingShift) => void;
  removeShift: () => void;
  selectStaff: (newStaff?: EmployeeForShiftItem, isStaffRequesting?: boolean) => void;
  showCannotRemoveModal: () => void;
}

export const RosterShift: FC<Props> = ({
  shift,
  readOnly,
  updateShift,
  removeShift,
  selectStaff,
  showCannotRemoveModal
}) => {
  const reassignment = useSystemSettings(state => state.settings.reassignment);
  const locationId = useLocalSettings(state => state.selectedLocationId);
  const getContractName = useGetContractName();

  const warnings = useWarningsForShift(shift.id);

  const employeeDropdownOptions = useEmployeesForShift(shift);
  const employee = employeeDropdownOptions.find(e => e.id === shift.assignedEmployeeId);

  const nextDayShift = useNextDayShift(shift.assignedEmployeeId);
  const previousDayShift = usePreviousDayShift(shift.assignedEmployeeId);
  const { workingRoster } = useWorkingRoster();

  const startTime = workingRoster?.startTime;
  const endTime = workingRoster?.endTime;

  const { lastUpdated } = useWorkPreferencesTrackingService(state => ({
    lastUpdated: state.lastUpdated
  }));

  const workPrefUpdatedOn = shift.assignedEmployeeId ? lastUpdated.find(x => x.identityId === shift.assignedEmployeeId)?.updatedOn : undefined;

  const ghostShifts = useMemo(() => {
    const ghostShifts: GhostShift[] = [];

    if (nextDayShift?.start !== undefined && nextDayShift.start + hoursToSeconds(24) < workingRoster!.endTime) {
      ghostShifts.push({
        startTime: nextDayShift.start + hoursToSeconds(24),
        endTime: nextDayShift.end + hoursToSeconds(24)
      });
    }

    if (previousDayShift?.end !== undefined && previousDayShift.end - hoursToSeconds(24) > workingRoster!.startTime) {
      ghostShifts.push({
        startTime: previousDayShift.start - hoursToSeconds(24),
        endTime: previousDayShift.end - hoursToSeconds(24)
      });
    }

    return ghostShifts;
  }, [nextDayShift, previousDayShift, startTime, endTime]);

  const removeButtonEnabled =
    !shift.assignedStaffHomeLocationId ||
    shift.assignedStaffHomeLocationId === locationId ||
    reassignment.model === 'pull';

  const onDelete = () => {
    removeButtonEnabled ? removeShift() : showCannotRemoveModal();
  };

  const reassigned =
    shift?.properties?.requesting === true ||
    (shift.assignedStaffHomeLocationId && shift.assignedStaffHomeLocationId !== locationId);

  const fromOtherShop = employee && employee?.details?.homeLocationId !== locationId;

  const staffLocked = shift?.properties?.staffLocked === true;

  const lockStaff = useCallback(
    (locked: boolean) => {
      const newShift = { ...shift };
      newShift.properties = newShift.properties ? { ...newShift.properties } : {};
      newShift.properties['staffLocked'] = locked;
      updateShift(newShift);
    },
    [shift, updateShift]
  );

  const onSelect = useCallback((selectedOption?: EmployeeForShiftItem, isStaffRequesting?: boolean) => {
    if (shift.properties['staffLocked']) {
      toastMessage(strings.daily.roster.shiftLockedError);
    } else {
      selectStaff(selectedOption, isStaffRequesting);
    }
  }, [shift, selectStaff]);

  return (
    <div className="flex flex-row border-b">
      <div className={cn('flex sticky left-0 z-[60] bg-white border-r')}>
        <div
          className="flex items-center px-2 font-semibold gap-3 border-r"
          style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
        >
          {!readOnly && (
            <button onClick={onDelete}>
              <FiX className={twMerge('h-6 w-6', removeButtonEnabled ? 'opacity-100' : 'opacity-25')} />
            </button>
          )}
          <div className="flex flex-col w-[90%] gap-1">
            <EmployeeOptionSettingsProvider showStaffPickerColumn={showStaffPickerColumn} reassignmentModel={reassignment.model}>
              <EmployeeSelector
                onSelect={onSelect}
                readOnly={readOnly || !removeButtonEnabled}
                workingShifts={workingRoster?.shifts}
                shift={shift}
                waitingAllocation={shift?.properties?.requesting === true || shift?.properties?.providingLocationId}
                employeeDropdownOptions={employeeDropdownOptions}
              />
            </EmployeeOptionSettingsProvider>
            <div className="flex flex-row justify-between items-center">
              <span className="text-xs">
                {shift.assignedContractId ? getContractName(shift.assignedContractId) : ''}
              </span>
              <div className="flex space-x-1.5">
                <SeverityIndicator warnings={warnings} />
                <LockUnlockToggle
                  value={staffLocked}
                  readOnly={readOnly ?? false}
                  onChange={lockStaff}
                  hidden={!shift.assignedEmployeeId}
                />
                <EmployeeTooltip employee={employee?.details} workPrefUpdatedOn={workPrefUpdatedOn}/>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }} className="flex">
          <div className="flex-[2] flex border-r">
            <MonthlyTotalColumns shift={shift} />
          </div>
          <div className="flex-[3] flex">
            <AttendanceTotalColumns shift={shift} />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <ShiftTimePicker
          key={shift.id}
          shift={shift}
          updateShift={updateShift}
          type={reassigned || fromOtherShop ? DailyEditingType.REASSIGNED : DailyEditingType.ROSTER}
          useColorizedBar={true}
          hasSetEmployee={!!shift.assignedEmployeeId}
          readOnly={readOnly}
          ghostShifts={ghostShifts}
        />
      </div>
    </div>
  );
};
