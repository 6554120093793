import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';

interface WeeklyTotalHeaderProps {}

export const WeeklyTotalHeader: FC<WeeklyTotalHeaderProps> = ({}) => {
  return (
    <>
      <div className="text-center border-b">{strings.common.totals.weeklyTotal}</div>
      <div className="flex">
        <InfoHeaderCell className="border-r">
          <p>{strings.common.totals.max}/{strings.common.totals.min}</p>
        </InfoHeaderCell>
        <InfoHeaderCell>
          <p>{strings.common.totals.periodTotal}</p>
        </InfoHeaderCell>
      </div>
    </>
  );
};
