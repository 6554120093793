import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useCallback } from 'react';
import {
  useSetBreakOffsetsMutation,
  useSetOrganisationPropertiesMutation
} from '../../../services/gql/graphql.generated';
import { useSystemSettings } from '../../../services/settings/SystemSettingsProvider';
import { SystemSettings } from '../../../services/settings/systemSettings.types';
import { useUpdateAvailabilityDeadline } from './useUpdateAvailabilityDeadline';
import { useUpdateOptimizerSettings } from './useUpdateOptimizerDuration';
import { OperationResult } from 'urql';

export const useUpdateSystemSettings = () => {
  const [{ fetching }, updateProperties] = useSetOrganisationPropertiesMutation();
  const [, updateOffsets] = useSetBreakOffsetsMutation();

  const setSettings = useSystemSettings(state => state.setSettings);
  const orgId = '1';
  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));

  const updateAvailabiltyDeadline = useUpdateAvailabilityDeadline();
  const updateOptimizerSettings = useUpdateOptimizerSettings();

  const updateSettings = useCallback(
    async (settings: SystemSettings) => {
      let result: OperationResult | undefined;

      try {
        result = await updateProperties({ orgId: parseInt(orgId), properties: JSON.stringify(settings) });
        setSettings(settings);
  
        await updateAvailabiltyDeadline(
          settings.availabilityDeadlineSettings.notifyMonthDay,
          settings.availabilityDeadlineSettings.notifyHour
        );
        await updateOptimizerSettings(settings.optimizerDuration, settings.optimizerManagementAttendance);
  
        await updateOffsets({
          timeOffsetAfterLastBreak: settings.timeOffsetAfterLastBreak,
          timeOffsetBeforeFirstBreak: settings.timeOffsetBeforeFirstBreak
        });
  
        unregisterForConfirmation();
      } catch (error: any) {
        result = undefined;
      }


      return result;
    },
    [updateProperties, orgId]
  );

  return { updateSettings, fetching };
};
