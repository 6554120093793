import { SelectOption } from '@/components/form/SelectInput';
import { displaySecondsAsTime } from '@/components/gantt/gantt/ganttHelper';
import { hoursToSeconds } from 'date-fns';
import { useMemo } from 'react';

export const useMinMaxTimeOptions = () => {
  return useMemo(() => {
    const options: number[] = [];

    for (let i = 0; i <= 36; i++) {
      options.push(hoursToSeconds(i));
    }

    const selectOptions: SelectOption[] = options.map(val => ({
      value: val,
      name: displaySecondsAsTime(val)
    }));

    return selectOptions;
  }, []);
};
