import { WorkingShift } from "@/pages/daily/daily.types";
import { getManagerRequiredWarning } from './helpers/managerRequired';
import { getMissingSkillsWarnings } from './helpers/missingSkills';
import { getOutsideHoursShiftWarnings } from './helpers/outsideHours';
import { getOverlappingSplitShiftWarnings } from './helpers/overlappingShifts';
import { getAloneRookieWarnings } from './helpers/rookies';
import { getWarningsForUnderageNightShifts } from './helpers/underageNightShift';
import { getAvailabilityWarnings } from './helpers/availability';
import { RosterWarningModel, WarningsProps } from './warnings.types';
import { getShiftsOtherLocationWarnings } from './helpers/shiftAtOtherLocation';

export const getRosterWarnings = (props: WarningsProps): RosterWarningModel[] => {
  const { workingRoster, validationSettings, buildEmployeeOptions } = props;
  const shifts = workingRoster.shifts;

  const warningList: RosterWarningModel[] = [];

  warningList.push(...getAloneRookieWarnings(props));

  if (validationSettings.managementAttendance) {
    const managerWarning = getManagerRequiredWarning(props);
    if (managerWarning) {
      warningList.push(managerWarning);
    }
  }

  shifts
    .filter(s => s.assignedEmployeeId !== undefined)
    .forEach((shift: WorkingShift) => {
      const dropdownOptions = buildEmployeeOptions(shift);
      const assignedEmployee = dropdownOptions.find(o => o.id === shift.assignedEmployeeId);

      if (assignedEmployee) {
        warningList.push(...getAvailabilityWarnings(shift, assignedEmployee, props));
        warningList.push(...getWarningsForUnderageNightShifts(shift, assignedEmployee, props));
        warningList.push(...getOverlappingSplitShiftWarnings(shift, props));
        warningList.push(...getMissingSkillsWarnings(shift, props));
        warningList.push(...getOutsideHoursShiftWarnings(shift, props));

        const otherLocationWarning = getShiftsOtherLocationWarnings(shift, props);
        if (otherLocationWarning) {
          warningList.push(otherLocationWarning);
        }
      }
    });

  return warningList.sort((a, b) => b.severity - a.severity);
};
