import { strings } from '@/services/translation/strings';
import { Document, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import {FC, ReactNode, useMemo} from 'react';
import {TaskType} from "@/services/tasks/task.types";
import {PdfTaskPages} from "@/pages/pdf/settings/PdfTaskPages";
import {SkillType} from "@/services/tasks/skillSettings.types";

// Context provider cannot be used inside the Document element
// The PdfTasksParams object is passed in from outside the Document element.
export interface PdfTasksParams {
  organisationName: string;
  tasks: TaskType[];
  skills: SkillType[];
}

interface Props {
  params: PdfTasksParams;
}

const PAGE_LENGTH = 20;

export const PdfTasksDocument: FC<Props> = ({params}) => {
  const activeTasks = params.tasks.filter(task => task.isActive);
  const inactiveTasks = params.tasks.filter(task => !task.isActive);

  const activePages: TaskType[][] = useMemo(() => {
    const pages: TaskType[][] = [];
    const rowsToAdd = [...activeTasks];
    while (rowsToAdd.length > 0) {
      const pageToAdd = rowsToAdd.splice(0, PAGE_LENGTH);
      pages.push(pageToAdd);
    }
    return pages;
  }, [activeTasks]);

  const inactivePages: TaskType[][] = useMemo(() => {
    const pages: TaskType[][] = [];
    const rowsToAdd = [...inactiveTasks];
    while (rowsToAdd.length > 0) {
      const pageToAdd = rowsToAdd.splice(0, PAGE_LENGTH);
      pages.push(pageToAdd);
    }
    return pages;
  }, [inactiveTasks]);

  const commonHeader: ReactNode = <View style={{
    flexDirection: 'row',
    justifyContent: 'space-between',
  }}>
    <Text style={{ fontSize: 18 }}>{params.organisationName}</Text>
    <Text style={{ fontSize: 14 }}>{format(new Date(), 'yyyy/MM/dd')}</Text>
  </View>;

  const activeTaskHeader: ReactNode = <View>
    {commonHeader}
    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
      <Text style={{ fontSize: 18 }}>{strings.settings.manageTasks.activeTasks}</Text>
    </View>
  </View>;

  const inactiveTaskHeader: ReactNode = <View>
    {commonHeader}
    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
      <Text style={{ fontSize: 18 }}>{strings.settings.manageTasks.inactiveTasks}</Text>
    </View>
  </View>;

  return (
    <Document>
      <PdfTaskPages header={activeTaskHeader} pages={activePages} skills={params.skills} />
      <PdfTaskPages header={inactiveTaskHeader} pages={inactivePages} skills={params.skills} />
    </Document>
  );
};
