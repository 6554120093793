import { Button } from '@/components/button';
import { Modal } from '@/components/modal/Modal';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';
import { useEditTemplateDetails } from '../useEditTemplateDetails';
import { Tag, TagInput } from '@/components/tags/TagInput';
import { useFlag } from '@softwareimaging/backstage';
import { twMerge } from 'tailwind-merge';

interface Props {
  open: boolean;
  onClose: () => void;
  templateId: string;
  defaultTemplateName: string;
  defaultTagList?: Tag[];
  validateName?: (newName: string) => string | null;
  allowNameChange?: boolean;
}

export const TemplateMenuUpdateModal: FC<Props> = ({
  open,
  onClose,
  templateId,
  defaultTemplateName,
  defaultTagList,
  validateName,
  allowNameChange
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>(defaultTemplateName);
  const [tagList, setTagList] = useState<Tag[]>(defaultTagList || []);

  const { updateTemplateName, fetching } = useEditTemplateDetails(templateId);

  const onChange = (newVal: string) => {
    setInputValue(newVal);
    setError(undefined);
  };

  const onSave = async () => {
    const editedTemplateName = inputValue.trim();
    if (allowNameChange) {
      if (!validateName) {
        throw Error('Name validator must be provided if name change is allowed.');
      }
      const errorMessage = validateName(editedTemplateName);
      if (errorMessage) {
        setError(errorMessage);
        return;
      }
    }

    const result = await updateTemplateName(editedTemplateName, tagList);

    if (result.error) {
      setError(strings.daily.templates.myTemplates.editModal.generalError);
    } else {
      onClose();
    }
  };

  const closeAndClear = () => {
    onClose();
    setTimeout(() => {
      setInputValue('');
      setError(undefined);
    }, 500);
  };

  const useTags = useFlag('enableTags');

  return (
    <Modal onClose={closeAndClear} open={open} title={strings.daily.templates.myTemplates.editModal.title}>
      <div className={twMerge('space-y-4', useTags ? 'w-[800px]' : '')}>
        <div className="space-y-2">
          {allowNameChange && (
            <>
              <span className="font-semibold text-sm">
                {strings.daily.templates.myTemplates.editModal.editTemplateName}
              </span>
              <input
                className="w-full rounded-md border-border text-base"
                value={inputValue}
                onChange={e => onChange(e.target.value)}
                type="text"
                required
              ></input>
            </>
          )}
          {useTags && (
            <TagInput
              tags={tagList}
              setTags={setTagList}
              label={strings.daily.templates.tags.label}
              description={strings.daily.templates.tags.description}
              placeholder={strings.daily.templates.tags.placeholder}
            />
          )}
          {error && <p className="text-red-500 text-xs">{error}</p>}
        </div>
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={closeAndClear}>{strings.common.cancel}</Button>
          <Button onClick={onSave} loading={fetching} variant={'submit'}>
            {strings.common.save}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
