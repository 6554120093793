import { FC, useMemo } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { strings } from '@/services/translation/strings';
import { WorkingPatterns } from '@/services/settings/systemSettings.types';
import { StaffRowPatternSectionDay } from './StaffRowPatternSectionDay';

const requestedDayOrder = [1, 2, 3, 4, 5, 6, 0, 7];

export const StaffRowPatternSection: FC<StaffRowSectionProps> = ({ staff, hidden, onChangeSetting, group }) => {
  const workingPatterns = useMemo(() => {
    const orderedPatterns: WorkingPatterns[] = requestedDayOrder.map(dayNumber => {
      const pattern = staff.settings.workingPatterns.find(p => p.day === dayNumber);
      if (!pattern) {
        throw Error('Working pattern not correctly defined');
      }

      return pattern;
    });

    return orderedPatterns;
  }, [staff.settings.workingPatterns]);

  const onSetTimes = (dayIndex: number) => (startTime: number, endTime: number) => {
    const newWorkingPatterns = [...workingPatterns];
    newWorkingPatterns[dayIndex] = { ...newWorkingPatterns[dayIndex] };
    newWorkingPatterns[dayIndex]['startTime'] = startTime;
    newWorkingPatterns[dayIndex]['endTime'] = endTime;

    onChangeSetting('workingPatterns')(newWorkingPatterns);
  };

  return (
    <>
      {strings.common.daysOfWeekFullStartMonday.map((_, dayIndex) => (
        <StaffRowPatternSectionDay
          staff={staff}
          key={dayIndex}
          pattern={workingPatterns[dayIndex]}
          hidden={hidden || group.columns[dayIndex].isColumnHidden}
          setTimes={onSetTimes(dayIndex)}
        />
      ))}
    </>
  );
};
