import { Button } from '@/components/button';
import { SelectInput } from '@/components/form/SelectInput';
import { useGetMeQuery } from '@/services/gql/graphql.generated';
import {
  GanttGridLineSpacing,
  GanttGridLinesThickness,
  OpenClosingTimeOptions,
  PersonalizationPreferences
} from '@/services/settings/systemSettings.types';
import { useGetDefaultPreferences } from '@/services/settings/useGetDefaultPreferences';
import { useGetMySettings } from '@/services/settings/useGetMySettings';
import { strings } from '@/services/translation/strings';
import { Form } from '@softwareimaging/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Setting } from '../layout/Setting';
import { SettingsSection, SettingsSubSection } from '../layout/SettingsSection';
import { useUpdateStaffSettings } from '../services/useUpdateStaffSettings';
import { DailyRosterHeader } from './DailyRosterHeader';

interface PreferenceFields {
  personalization: PersonalizationPreferences;
}

export const DEFAULT_PERSONALIZATION_VALUES = {
  ganttGridLines: GanttGridLineSpacing.none,
  ganttGridLinesThickness: GanttGridLinesThickness.extraLarge,
  openClosingTimes: OpenClosingTimeOptions.all
} as PersonalizationPreferences;

const prefStrings = strings.settings.preferences;

const getOpenClosingTimeOptionLabel = (option: OpenClosingTimeOptions) => {
  switch (option) {
    case OpenClosingTimeOptions.hidden:
      return prefStrings.dailyRoster.openClosingTimes.hidden;
    case OpenClosingTimeOptions.fieldsOnly:
      return prefStrings.dailyRoster.openClosingTimes.fieldsOnly;
    case OpenClosingTimeOptions.all:
      return prefStrings.dailyRoster.openClosingTimes.all;
  }
};

export const Personalization: FC = () => {
  const [_, refetch] = useGetMeQuery();
  const mySettings = useGetMySettings();
  const defaultPreferences = useGetDefaultPreferences();
  const { update, fetching } = useUpdateStaffSettings();

  const methods = useForm<PreferenceFields>({
    defaultValues: {
      personalization: defaultPreferences.personalization
    }
  });

  const submitPrefs = async (data: PreferenceFields) => {
    mySettings!.settings.preferences = {
      ...defaultPreferences,
      personalization: data.personalization
    };
    await update([mySettings!]);
    methods.reset(data);
    refetch({ requestPolicy: 'network-only' });
  };

  const onCancel = () => {
    methods.reset();
  };

  const ganttGridLineSelectOptions = Object.values(GanttGridLineSpacing)
    .filter(value => typeof value === 'number')
    .map(option => {
      return {
        name:
          option === GanttGridLineSpacing.none
            ? String(GanttGridLineSpacing.none)
            : prefStrings.dailyRoster.gridLines(Number(option)),
        value: option
      };
    });

  const ganttGridBarThicknessSelectOptions = Object.values(GanttGridLinesThickness).map(option => {
    return {
      name: option,
      value: option
    };
  });

  const openClosingTimesOptions = Object.values(OpenClosingTimeOptions).map(option => {
    return {
      name: getOpenClosingTimeOptionLabel(option as OpenClosingTimeOptions),
      value: option
    };
  });

  return (
    <div className="overflow-auto h-full pb-5">
      <Form methods={methods} onSubmit={submitPrefs}>
        <DailyRosterHeader />
        <SettingsSection title={prefStrings.dailyRoster.personalization}>
          <SettingsSubSection title={prefStrings.dailyRoster.personalization}>
            <Setting title={prefStrings.dailyRoster.ganttGridLines}>
              <SelectInput
                options={ganttGridLineSelectOptions}
                value={methods.watch('personalization.ganttGridLines') ?? DEFAULT_PERSONALIZATION_VALUES.ganttGridLines}
                setValue={value => {
                  methods.setValue('personalization.ganttGridLines', Number(value), {
                    shouldDirty: true
                  });
                }}
              />
            </Setting>
            <Setting title={prefStrings.dailyRoster.ganttGridBarThickness}>
              <SelectInput
                options={ganttGridBarThicknessSelectOptions}
                value={
                  methods.watch('personalization.ganttGridLinesThickness') ??
                  DEFAULT_PERSONALIZATION_VALUES.ganttGridLinesThickness
                }
                setValue={value => {
                  methods.setValue('personalization.ganttGridLinesThickness', value, {
                    shouldDirty: true
                  });
                }}
              />
            </Setting>
            <Setting title={prefStrings.dailyRoster.openClosingTimes.label}>
              <SelectInput
                options={openClosingTimesOptions}
                value={
                  methods.watch('personalization.openClosingTimes') ?? DEFAULT_PERSONALIZATION_VALUES.openClosingTimes
                }
                setValue={value => {
                  methods.setValue('personalization.openClosingTimes', value, {
                    shouldDirty: true
                  });
                }}
              />
            </Setting>
          </SettingsSubSection>
        </SettingsSection>
        <div className="px-5 mt-2 gap-4 flex">
          <Button disabled={!methods.formState.isDirty} type="button" onClick={onCancel}>
            {strings.common.cancel}
          </Button>
          <Button disabled={!methods.formState.isDirty} variant={'primary'} type="submit" loading={fetching}>
            {strings.common.save}
          </Button>
        </div>
      </Form>
    </div>
  );
};
