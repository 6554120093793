import { FC } from 'react';
import { FiPlus } from 'react-icons/fi';

interface Props {
  label: string;
  textColorClass?: string;
  backgroundColorClass?: string;
  hoverColorClass?: string;
  readOnly?: boolean;
  onButtonClick: () => void;
}

export const AddButton: FC<Props> = ({
  label,
  textColorClass = 'text-primary',
  backgroundColorClass = 'bg-white',
  hoverColorClass = 'hover:bg-[#0070d210]',
  readOnly,
  onButtonClick
}) => {
  const readOnlyClasses = `hover:cursor-not-allowed bg-white/[0.5] text-primary/[0.6] border-primary/[0.6]`;
  const defaultClasses = `border-primary ${textColorClass} ${backgroundColorClass} ${hoverColorClass}`;
  return (
    <div className={`rounded-md ${readOnly ? 'bg-transparent' : 'bg-[white]'}`}>
      <button
        className={`flex flex-row items-center px-2 py-1 border rounded-md gap-1.5 font-semibold ${
          readOnly ? readOnlyClasses : defaultClasses
        }`}
        onClick={readOnly ? () => {} : onButtonClick}
      >
        <FiPlus className="h-4 w-4" />
        <span className="text-sm">{label}</span>
      </button>
    </div>
  );
};
