import { FilterOption } from "@/components/flterSelector/RadioFilterSelector";
import { strings } from "@/services/translation/strings";
import { useMemo } from "react";
import { FilterOptions } from "./Announcement";

export const useFilterOptions = () => {
  const filterOptions: FilterOption[] = useMemo(() => {
    return [
      {
        displayName: strings.settings.filter.displayActive,
        value: FilterOptions.ACTIVE
      },
      {
        displayName: strings.settings.filter.displayAll,
        value: FilterOptions.ALL
      },
      {
        displayName: strings.settings.filter.displayInactive,
        value: FilterOptions.INACTIVE
      }
    ];
  }, []);

  return filterOptions;
};
