import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export const SettingsTableHeaderCell: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, style }) => {
  return (
    <div
      className={twMerge(
        className,
        'bg-background-mid border border-border px-2.5 py-2 text-sm text-[#323232bf] font-semibold'
      )}
      style={style}
    >
      {children}
    </div>
  );
};
