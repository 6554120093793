import { useCreateTemplateMutation } from '@/services/gql/graphql.generated';
import { TemplateTag } from '@/services/rosters/useTemplateTags';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useCallback } from 'react';

export const useCreateTemplate = (library?: boolean) => {
  const [responseData, create] = useCreateTemplateMutation();

  const { selectedLocation } = useLocalSettings(state => ({
    selectedLocation: state.selectedLocationId
  }));

  const createTemplate = useCallback(
    async (name: string, tags: TemplateTag[]) => {
      const result = await create({
        locationId: library ? undefined : selectedLocation,
        name: name,
        version: library ? '0.1' : undefined,
        draft: library,
        tags: tags.map(t => ({ label: t.label }))
      });

      const newTemplate = result.data?.createTemplate;

      if (!newTemplate) {
        throw Error('Template create failed');
      }

      return result;
    },
    [create, selectedLocation, library]
  );

  return { fetching: responseData.fetching, create: createTemplate };
};
