import { Icon, iconArray } from '@/helpers/dropdownOptionHelper';
import { useAllEmployeeOptions } from '@/pages/daily/roster/employeeSelector/useAllEmployeeOptions';
import { useReassignmentService } from '@/pages/daily/roster/providers/ReassignmentService';
import {
  useWorkingRoster,
  useRosterHomeEmployeesSummary,
  useUnassignedEmployeesWithGhostShifts,
  useRosterStats
} from '@/pages/daily/roster/providers/WorkingRosterService';
import { WorkingRoster } from '@/pages/daily/roster/roster.types';
import { useHourSummary } from '@/pages/daily/useHourSummary';
import { SummaryEmployee } from '@/services/summary/summary.types';
import { BudgetStats, WorkingBudgetDay, WorkingBudgetLocation } from '@/services/budget/budget.types';
import { useBudgetForDay, useBudgetForLocation } from '@/services/budget/useBudget';
import { useBudgetStats } from '@/services/budget/useBudgetStats';
import { useGetContractName } from '@/services/contract/useGetContractName';
import { ReadEmployee } from '@/services/gql/graphql.generated';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { TaskType } from '@/services/tasks/task.types';
import { useTasks } from '@/services/tasks/useTasks';
import { PrintViewType, usePrintOptions } from "@/pages/pdf/roster/usePrintOptions";
import { WorkingShift } from "@/pages/daily/daily.types";
import {fakeShift} from "@/pages/daily/roster/UnassignedGhostShifts";

export interface PdfRosterProps {
  workingRoster?: WorkingRoster;
  summary: SummaryEmployee[];
  getContractName: (id: string) => string;
  reassignedShifts: WorkingShift[];
  unassignedGhostShifts: WorkingShift[];
  employees: ReadEmployee[];
  maxTime: number;
  minTime: number;
  locationName: string;
  counts: number[];
  minuteIncrement: number;
  taskList: TaskType[];
  iconList: Icon[];
  budgetStats: BudgetStats;
  budgetLocation: WorkingBudgetLocation;
  budgetDay: WorkingBudgetDay;
  viewType?: PrintViewType;
}

export const usePdfRosterProps = () => {
  const [viewType] = usePrintOptions(state => [state.viewType]);
  const { workingRoster } = useWorkingRoster();
  const getContractName = useGetContractName();
  const { reassignedShifts } = useReassignmentService(state => ({
    reassignedShifts: state.reassignedShifts
  }));
  const defaultMaxTime = useSystemSettings(state => state.settings.maxTime);
  const defaultMinTime = useSystemSettings(state => state.settings.minTime);
  const maxTime = workingRoster ? workingRoster.endTime : defaultMaxTime;
  const minTime = workingRoster ? workingRoster.startTime : defaultMinTime;
  const { homeEmployees, awayEmployees } = useAllEmployeeOptions();
  const employees = homeEmployees.concat(awayEmployees);
  const location = useSelectedLocation();
  const taskList = useTasks();
  const counts = useHourSummary(workingRoster?.shifts || [], minTime, maxTime, false);
  const minuteIncrement = useSystemSettings(state => state.settings.minuteIncrement);
  const summary = useRosterHomeEmployeesSummary();

  const daysInMonth = useDaysInCurrentMonth();
  const rosterStats = useRosterStats();
  const budgetStats = useBudgetStats(daysInMonth, rosterStats);
  const { budgetData: budgetLocation } = useBudgetForLocation(daysInMonth);
  const budgetDay = useBudgetForDay();
  const unassignedEmployees = useUnassignedEmployeesWithGhostShifts();

  const props: PdfRosterProps = {
    workingRoster,
    summary,
    getContractName,
    reassignedShifts,
    unassignedGhostShifts: unassignedEmployees.map(employee => {
      const shift = {...fakeShift};
      shift.assignedEmployeeId = employee.id;
      shift.assignedContractId = employee.contractId;
      return shift
    }),
    maxTime,
    minTime,
    employees,
    locationName: location.name,
    counts,
    minuteIncrement,
    iconList: iconArray,
    taskList,
    budgetStats,
    budgetDay,
    budgetLocation,
    viewType: viewType
  };

  return props;
};
