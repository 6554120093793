import { TemplateMenuUpdateModal } from '@/pages/daily/template/menu/TemplateUpdateModal';
import { WorkingTemplate } from '@/pages/daily/template/template.types';
import { strings } from '@/services/translation/strings';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import React, { Suspense, useState } from 'react';
import { EditButton } from '../buttons/EditButton';
import { TagChit } from './TagChit';

interface Props {
  template: WorkingTemplate
}

const NUMBER_OF_TAGS = 3;
export const TagsSummary: React.FC<Props> = ({ template }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const onEdit = () => {
    setIsEditModalOpen(true);
  };

  const tags = template.tags;

  const tagsToShow = tags.length === NUMBER_OF_TAGS ? tags : tags.slice(0, NUMBER_OF_TAGS - 1);
  const otherTags = tags.length === NUMBER_OF_TAGS ? [] : tags.slice(NUMBER_OF_TAGS - 1);

  return (
    <>
      <div className="flex items-center px-2 text-center gap-2">
        <div className="text-xs pr-1 font-semibold">{strings.daily.templates.tags.label}</div>
        {tagsToShow.length === 0 && <TagChit tag={{ label: strings.daily.templates.tags.none }} />}
        {tagsToShow.map(t => (
          <TagChit key={t.label} tag={t} />
        ))}
        {otherTags.length > 0 && (
          <RadixTooltip.Provider delayDuration={100}>
            <RadixTooltip.Root>
              <RadixTooltip.Trigger>
                <TagChit tag={{ label: strings.daily.templates.tags.andMore(otherTags.length) }}></TagChit>
              </RadixTooltip.Trigger>
              <RadixTooltip.Portal>
                <Suspense>
                  <RadixTooltip.Content
                    side="right"
                    className="z-[1000] flow-text break-words bg-white border rounded-sm p-2 max-w-[350px] text-sm"
                    sideOffset={5}
                  >
                    <div className="font-semibold">{strings.daily.templates.tags.otherTags}</div>
                    {otherTags.map(t => t.label).join(', ')}
                  </RadixTooltip.Content>
                </Suspense>
              </RadixTooltip.Portal>
            </RadixTooltip.Root>
          </RadixTooltip.Provider>
        )}
        <EditButton onClick={onEdit} />
      </div>
      <TemplateMenuUpdateModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        templateId={template.id}
        defaultTemplateName={template.name}
        defaultTagList={template.tags}
      />
    </>
  );
};
