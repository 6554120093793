import { Modal } from '@/components/modal/Modal';
import { DateCellHeaders } from '@/components/table/DateCellHeaders';
import { buildRosterTooltip } from '@/components/tooltip/RosterTooltip';
import { dateToApiFormat } from '@/helpers/dateHelper';
import {
  StaffAvailabilityDay,
  StaffAvailabilityMonth,
} from '@/services/availability/useStaffAvailability';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailabilityDetails } from './AvailabilityDetails';
import { AvailabilityTableRow } from './AvailabilityTableRow';
import {useWorkPreferencesTracking} from "@/services/availability/WorkPreferencesTrackingService";

export const EMPLOYEE_NAME_COLUMN_WIDTH = 250;

interface Props {
  days: Date[];
  availabilityForMonth: StaffAvailabilityMonth[];
}

export const AvailabilityTable: FC<Props> = ({days, availabilityForMonth}) => {
  const [editing, setEditing] = useState<{ row: StaffAvailabilityMonth; day: StaffAvailabilityDay } | undefined>(
    undefined
  );
  useWorkPreferencesTracking(days[0], days[days.length - 1]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const tooltips = buildRosterTooltip(days)

  const onClick = (month: StaffAvailabilityMonth) => (day: StaffAvailabilityDay) => {
    setEditing({ row: month, day: day });
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
    setTimeout(() => setEditing(undefined), 100);
  };

  const navigate = useNavigate();
  const setSelectedDate = useLocalSettings(state => state.setSelectedDate);
  const onDateClick = (date: Date) => {
    setSelectedDate(dateToApiFormat(date));
    navigate('/rosters');
  };

  return (
    <div className="flex flex-col flex-1 overflow-hidden" id="availability-table">
      <div className="flex border-b bg-background-mid pr-5">
        <div className="border-r" style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }} />
        <DateCellHeaders days={days} tooltips={tooltips} onClick={onDateClick} />
      </div>
      <div className="overflow-y-scroll flex-1">
        <div className="w-screen">
          {availabilityForMonth.map(row => (
            <AvailabilityTableRow data={row} key={row.employee.identityId} onClick={onClick(row)} />
          ))}
        </div>
        <Modal open={modalOpen} onClose={onClose}>
          {editing && (
            <AvailabilityDetails selectedDay={editing?.day} selectedMonth={editing?.row} onComplete={onClose} />
          )}
        </Modal>
      </div>
    </div>
  );
};
