import { FC } from 'react';
import { severityTypeToColor, WarningModel } from './warningDefinitions';

interface WarningsProps {
  warnings: WarningModel[];
}

export const WarningsList: FC<WarningsProps> = ({ warnings }) => {
  return (
    <div className="flex items-center space-0 text-xs leading-tight">
      <div className="space-y-0.5">
        {warnings.map((warning: WarningModel, index: number) => (
          <div
            className="flex flex-col"
            style={{ color: severityTypeToColor(warning.severity) }}
            key={'warning-' + index}
          >
            <div className="font-bold drop-shadow">{warning.name}</div>
            {warning?.details &&
              warning.details.length > 0 &&
              warning?.details?.map((detail: string, index: number) => (
                <div className="pl-1 flex grow text-[#323232]/[0.8]" key={'detail-' + index}>
                  - <div className="pl-1 flex grow">{detail}</div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
