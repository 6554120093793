const changeSettingsObject = (object: any, compositeKey: string, newVal: any) => {
  const splitKey = compositeKey.split(".");

  let currentObject = object;
  splitKey.forEach((key, index) => {
    const keys = Object.keys(currentObject);
    if (!keys.includes(key)) {
      throw Error('Attempting to set undefined property in Location settings');
    }
    
    if (index === splitKey.length - 1) {
      currentObject[key] = newVal;
    } else {
      currentObject = currentObject[key];
    }
  });
};

export const onChangeSettingsArray =
  (settingsArray: any[], updateSettingsArray: (newSettingsArray: any[]) => void) =>
  (key: string, index: number) =>
  (newVal: any) => {
    const newSettings = [...settingsArray];
    changeSettingsObject(newSettings[index].settings as any, key, newVal);
    updateSettingsArray(newSettings);
  };

export const onChangeSettings =
  (settings: any, updateSettings: (newSettings: any) => void) => (key: string) => (newVal: any) => {
    const newSettings = { ...settings };

    changeSettingsObject(newSettings, key, newVal);
    updateSettings(newSettings);
  };
