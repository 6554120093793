import { InfoCell } from '@/components/table/InfoCell';
import { EmployeeTooltip } from '@/components/tooltip/EmployeeTooltip';
import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { cn } from '@/lib/utils';
import { useWorkPreferencesTrackingService } from "@/services/availability/WorkPreferencesTrackingService";
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { SummaryEmployee } from '@/services/summary/summary.types';
import clsx from 'clsx';
import { FC } from 'react';
import { SummaryEmployeeCell } from './SummaryEmployeeCell';
import { COMPLIANCE_TOTAL_COLUMN_WIDTH, EMPLOYEE_NAME_COLUMN_WIDTH, MONTHLY_TOTAL_COLUMN_WIDTH } from './constants';
import { useAdvancedLeaveDataForEmployee } from '@/services/staff/advancedLeave';

export enum SummaryEmployeeRowStyle {
  WEEK,
  MONTH
}

interface Props {
  employee: SummaryEmployee;
  rowStyle: SummaryEmployeeRowStyle;
  days: Date[];
  isMonthlyShiftsPage?: boolean;
  isWeeklyShiftsPage?: boolean;
  showCompliance?: boolean;
  advancedLeaveCols?: string[];
}

export const SummaryEmployeeRow: FC<Props> = ({
  employee,
  days,
  rowStyle,
  isMonthlyShiftsPage,
  isWeeklyShiftsPage,
  showCompliance,
  advancedLeaveCols
}) => {
  const settings = employee.settings;
  const shiftDuration = employee.compliance.actualTime;
  const advancedLeave: boolean = useSystemSettings(state => state.settings.advancedLeaveManagement);

  let totalColorClass = '';
  if (shiftDuration) {
    // Check if the monthly total hours are within the min/max hours
    if (isMonthlyShiftsPage) {
      if (shiftDuration < settings.minMonthlyHours) {
        totalColorClass = 'text-primary';
      } else if (shiftDuration > settings.maxMonthlyHours) {
        totalColorClass = 'text-secondary';
      }
    }

    // Check if the weekly total hours are within the min/max hours
    if (isWeeklyShiftsPage) {
      if (shiftDuration < settings.minWeeklyHours) {
        totalColorClass = 'text-primary';
      } else if (shiftDuration > settings.maxWeeklyHours) {
        totalColorClass = 'text-secondary';
      }
    }
  }

  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);
  let nameToDisplay = employee.name;
  if (displayIds) {
    nameToDisplay += ` (${employee.employeeId})`;
  }

  const { lastUpdated } = useWorkPreferencesTrackingService(state => ({
    lastUpdated: state.lastUpdated
  }));

  const workPrefUpdatedOn = lastUpdated.find(x => x.identityId === employee.details.identityId)?.updatedOn;

  const { advancedLeaveData, advancedLeaveTotal } = useAdvancedLeaveDataForEmployee(employee.schedule)

  return (
    <div className="flex border-b">
      <div
        className={cn(
          'p-2 border-r flex items-center justify-between',
          isMonthlyShiftsPage && settings.maxMonthlyHours === 0 && 'bg-gray-500'
        )}
        style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
      >
        <div>
          <p>{nameToDisplay}</p>
          <p>{employee.role}</p>
        </div>
        <EmployeeTooltip employee={employee.details} workPrefUpdatedOn={workPrefUpdatedOn} />
      </div>
      {isMonthlyShiftsPage && (
        <div
          style={{ width: showCompliance ? COMPLIANCE_TOTAL_COLUMN_WIDTH : MONTHLY_TOTAL_COLUMN_WIDTH }}
          className="flex"
        >
          <>
            {!showCompliance && (
              <>
                <InfoCell className="border-r flex-1">
                  <p>{employee.compliance.holidays}</p>
                </InfoCell>
                <InfoCell className="border-r flex-1">
                  <p>{employee.compliance.attendanceDays}</p>
                </InfoCell>
              </>
            )}
            <InfoCell className="border-r flex-1">
              <p>{secondsToTimeString(settings.maxMonthlyHours)}</p>
              <p>{secondsToTimeString(settings.minMonthlyHours)}</p>
            </InfoCell>
            <InfoCell className={`${totalColorClass} flex-1`}>
              <p>{employee.id ? secondsToTimeString(shiftDuration) : '--:--'}</p>
            </InfoCell>
          </>
        </div>
      )}
      {isWeeklyShiftsPage && (
        <div style={{ width: 140 }} className="flex">
          <InfoCell className="border-r flex-1">
            <p>{secondsToTimeString(settings.maxWeeklyHours)}</p>
            <p>{secondsToTimeString(settings.minWeeklyHours)}</p>
          </InfoCell>
          <InfoCell className={`${totalColorClass} flex-1`}>
            <p>{employee.id ? secondsToTimeString(shiftDuration) : '--:--'}</p>
          </InfoCell>
        </div>
      )}
      {!showCompliance &&
        days.map((day, index) => {
          const scheduleDay = employee.schedule.find(s => s.date === dateToApiFormat(day));

          return (
            <SummaryEmployeeCell
              key={`${dateToApiFormat(day)}-${index}`}
              scheduleDay={scheduleDay}
              rowStyle={rowStyle}
            />
          );
        })}
      {showCompliance && (
        <>
          {advancedLeave && advancedLeaveData ? (
            advancedLeaveData.map((leave, index) => {
              const showCol = (advancedLeaveCols && advancedLeaveCols?.includes(leave.id)) || !advancedLeaveCols;

              return (
                showCol && (
                  <InfoCell className={clsx('border-r', index === 0 && ' border-l')}>
                    <p>{leave.count}</p>
                  </InfoCell>
                )
              );
            })
          ) : (
            <InfoCell className="border-l border-r">
              <p>{employee.compliance.holidays}</p>
            </InfoCell>
          )}
          {advancedLeave && (
            <InfoCell className="border-r">
              <p>{advancedLeaveTotal}</p>
            </InfoCell>
          )}
          <InfoCell className="border-r">
            <p>{employee.compliance.attendanceDays}</p>
          </InfoCell>
          <InfoCell className="border-r">
            <p>{employee.compliance.shifts}</p>
          </InfoCell>
          <InfoCell className="border-r">
            <p>{employee.compliance.avaliableDays}</p>
          </InfoCell>
        </>
      )}
      <div className="w-5"></div>
    </div>
  );
};
