import { FC } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

interface Props {
  onUpClick: () => void;
  onDownClick: () => void;
}

export const UpDownButton: FC<Props> = ({ onUpClick, onDownClick }) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <button onClick={onUpClick}>
        <FiChevronUp className="h-4 w-4" />
      </button>
      <button onClick={onDownClick}>
        <FiChevronDown className="h-4 w-4" />
      </button>
    </div>
  );
};
