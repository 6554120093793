import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useCallback } from 'react';
import { LocationWithSettings } from '@/services/settings/systemSettings.types';
import { useLocationSettings } from '@/services/settings/LocationSettingsProvider';
import {
  UpdateEmployeeLocations,
  useUpdateEmployeeLocationsMutation,
  useUpdateLocationsMutation
} from '@/services/gql/graphql.generated';
import {OperationResult} from "urql";

const extractSvLocations = (settings: LocationWithSettings[]) => {
  const svLocationsArray: UpdateEmployeeLocations[] = [];
  settings.filter(s => s.settings.supervisorId?.length > 0) // select settings where supervisor is set
          .forEach(s => {
    let svLocations = svLocationsArray.find(el => el.employeeId === s.settings.supervisorId);
    if (!svLocations) {
      svLocations = {
        employeeId: s.settings.supervisorId,
        locationIds: []
      };
      svLocationsArray.push(svLocations);
    }
    svLocations.locationIds?.push(s.location.id);
  });
  return svLocationsArray;
}

export const useUpdateLocationSettings = () => {
  const setSettings = useLocationSettings(state => state.setSettings);

  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));

  const [{ fetching: updateLocationsFetching }, updatLocations] = useUpdateLocationsMutation();
  const [{ fetching: updateEmployeeLocationsFetching }, updateEmployeeLocations] = useUpdateEmployeeLocationsMutation();

  const updateSettings = useCallback(
    async (settings: LocationWithSettings[]) => {
      let result: OperationResult = await updatLocations({
        locations: settings.map(s => ({
          id: s.location.id,
          locationNumber: s.location.locationNumber,
          name: s.location.name,
          properties: JSON.stringify(s.settings),
          areaId: s.location.area?.id,
          active: true
        }))
      });

      if (!result.error) {
        result = await updateEmployeeLocations({
          employeeLocations: extractSvLocations(settings)
        });
      }

      setSettings(settings);
      unregisterForConfirmation();

      return result;
    },
    [setSettings, unregisterForConfirmation, updatLocations, updateEmployeeLocations]
  );

  return { updateSettings, fetching: updateLocationsFetching || updateEmployeeLocationsFetching };
};
