import { useTimeSlots } from '@/components/gantt/gantt/ganttHelper';
import { Icon } from '@/helpers/dropdownOptionHelper';
import { WorkingShift } from "@/pages/daily/daily.types";
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC, useContext } from 'react';
import { PdfTableCell } from '../PdfTableCell';
import { SvgIcon } from '../SvgIcon';
import { PdfRosterContext } from './PdfRosterProvider';
import { isReasignedShift } from '@/pages/daily/roster/providers/ReassignmentService';
import { SummaryEmployeeShift } from '@/services/summary/summary.types';

interface BarProps {
  start: number;
  end: number;
  color: string;
  textColor: string;
  sub?: boolean;
  icon?: Icon;
  ghost?: boolean;
}

const Bar: FC<BarProps> = ({ start, end, color, textColor, sub, icon, ghost }) => {
  const { maxTime: max, minTime: min } = useContext(PdfRosterContext);

  const startToUse = Math.max(min, start);
  const endToUse = Math.min(max, end);
  const totalLength = max - min;
  const shiftLength = endToUse - startToUse;
  let percentageWidth = (shiftLength / totalLength) * 100;
  let leftPercentageWidth = ((startToUse - min) / totalLength) * 100;

  if (percentageWidth === 0) {
    percentageWidth += 0.5;
    leftPercentageWidth -= percentageWidth / 2;
  }

  const baseStyle: Style = {
    width: `${percentageWidth}%`,
    height: '80%',
    backgroundColor: color,
    position: 'absolute',
    left: `${leftPercentageWidth}%`,
    zIndex: 10,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const regularStyle: Style = {
    ...baseStyle
  };

  const subStyle: Style = {
    ...baseStyle,
    borderLeft: shiftLength > 0 ? '1px solid #DDDDDD' : undefined,
    borderRight: shiftLength > 0 ? '1px solid #DDDDDD' : undefined,
    borderRadius: 0,
    height: '50%'
  };

  const ghostStyle: Style = {
    ...baseStyle,
    opacity: 0.2,
    border: 1,
    borderStyle: 'dashed',
    borderLeft: startToUse === min ? 0 : undefined,
    borderRight: endToUse === max ? 0 : undefined
  };

  return (
    <View style={ghost ? ghostStyle : sub ? subStyle : regularStyle}>
      {icon && shiftLength > 0 && (
        <View style={{ paddingVertical: 1 }}>
          <SvgIcon paths={icon.svgPaths} viewBoxSize={icon.svgViewboxSize} color={textColor} />
        </View>
      )}
      {icon && shiftLength === 0 && (
        <View style={{ position: 'absolute', bottom: -5, width: 10, height: 10 }}>
          <SvgIcon paths={icon.svgPaths} viewBoxSize={icon.svgViewboxSize} color={'black'} />
        </View>
      )}
    </View>
  );
};

export const PdfGanttBar: FC<{
  shift: WorkingShift;
  ghostShifts: SummaryEmployeeShift[];
}> = ({ shift, ghostShifts }) => {
  const { maxTime, minTime, taskList, iconList } = useContext(PdfRosterContext);

  const hourlySlots = useTimeSlots(minTime, maxTime, 60 * 60);
  let mainBarColor = '#c23934';
  if (isReasignedShift(shift)) {
    mainBarColor = '#E8AA14';
  } else if (shift.assignedEmployeeId) {
    mainBarColor = 'rgba(110, 235, 131, 0.6)';
  }

  const hasShift = typeof shift.start === 'number' && typeof shift.end === 'number';

  return (
    <PdfTableCell lastCell={true}>
      <View style={{ position: 'relative', height: '100%', width: '100%' }}>
        <View style={{ display: 'flex', position: 'absolute', width: '100%', flexDirection: 'row' }}>
          {hourlySlots.map((slot, index) => (
            <PdfTableCell key={slot.start} height={25} lastCell={index === hourlySlots.length - 1} dashed />
          ))}
        </View>
      </View>
      {hasShift && <Bar start={shift.start || 0} end={shift.end || 0} color={mainBarColor} textColor="black" />}
      {shift.breaks.map(brek => (
        <Bar key={brek.id} start={brek.start} end={brek.end} color={'rgba(126, 178, 221, 1)'} sub textColor="white" />
      ))}
      {shift.tasks.map(task => {
        const taskConfig = taskList.find(t => t.id.toString() === task.typeContext);

        if (!taskConfig) {
          return undefined;
        }

        const icon = iconList.find(i => i.id.toString() == taskConfig.icon);

        return (
          <Bar
            key={task.id}
            start={task.start}
            end={task.end}
            color={taskConfig.color}
            textColor={taskConfig.textColor}
            icon={icon}
            sub
          />
        );
      })}
      {ghostShifts.map(ghost => {
        return <Bar start={ghost.start} end={ghost.end} color={mainBarColor} textColor={'black'} ghost></Bar>;
      })}
    </PdfTableCell>
  );
};
