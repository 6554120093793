import {
  useGetRosterPropertiesQuery,
} from '@/services/gql/graphql.generated';
import {Note} from "@/pages/pdf/roster/PdfRosterNotes";
import {convertDateToGQLFormat} from "@/helpers/dateHelper";

export const useRosterNotes = (startDate: Date, endDate: Date, locationId?: number) => {
  const [{data, fetching}] = useGetRosterPropertiesQuery({
    pause: locationId === undefined,
    variables: {
      startDate: convertDateToGQLFormat(startDate),
      endDate: convertDateToGQLFormat(endDate),
      locationId: locationId ?? 0
    }
  });

  const rosterNotes: Note[] = [];
  data?.rostersInDateRange?.forEach(roster => {
    if (roster.properties) {
      const notes = JSON.parse(roster.properties)?.notes;
      if (notes) {
        rosterNotes.push({
          date: new Date(roster.rosterDate),
          text: notes
        });
      }
    }
  });

  return {notes: rosterNotes, loading: fetching};
}
