import { Location, ReadDivision } from '../gql/graphql.generated';
import { LocationDetails, defaultLocationDetails } from '../settings/systemSettings.types';
import { useMyLocation } from '../useMyLocation';
import { useOrganisationHierarchy } from './useOrganisationHierarchy';
import { useCallback, useMemo } from 'react';

const searchDivision = (division: ReadDivision, locationId: number, breadcrumbs: ReadDivision[]) => {
  let locationFound = false;
  const newBreadcrumbs = [...breadcrumbs, division];

  division.locations?.forEach(loc => {
    if (loc.id === locationId) {
      locationFound = true;
    }
  });

  if (locationFound) {
    return newBreadcrumbs;
  }

  let breadcrumbsToReturn: ReadDivision[] | undefined = undefined;
  division.children?.forEach(childDivision => {
    if (!breadcrumbsToReturn) {
      breadcrumbsToReturn = searchDivision(childDivision, locationId, newBreadcrumbs);
    }
  });

  return breadcrumbsToReturn;
};

const getDetailsFromHierarchy = (hierarchy: ReadDivision) => (location: Location) => {
  const details: LocationDetails = { ...defaultLocationDetails };

  const breadcrumbs = searchDivision(hierarchy, location.id, []);

  if (breadcrumbs?.length === 4) {
    details.companyName = breadcrumbs[1].name;
    details.brandName = breadcrumbs[3].name;
    details.divisionName = breadcrumbs[2].name;
    details.perfecture = location.area?.name || '';
    details.companyCode = breadcrumbs[1].divisionId || '';
  }

  return details;
};

export const useGetLocationHierarchyDetails = () => {
  const hierarchy = useOrganisationHierarchy();

  return useCallback(getDetailsFromHierarchy(hierarchy as ReadDivision), [hierarchy]);
};

export const useCurrentLocationHierarchyDetails = () => {
  const selectedLocation = useMyLocation();
  const hierarchy = useOrganisationHierarchy();

  return useMemo(() => {
    if (!selectedLocation) {
      return defaultLocationDetails;
    }

    return getDetailsFromHierarchy(hierarchy as ReadDivision)(selectedLocation);
  }, [hierarchy, selectedLocation]);
};
