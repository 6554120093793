import { dateToApiFormat } from '@/helpers/dateHelper';
import { strings } from '@/services/translation/strings';
import {FC, useCallback} from 'react';
import { PdfRosterDocument } from './PdfRosterDocument';
import { usePdfRosterProps } from './useRosterPrintProps';
import DownloadReportButtonWithModal from "@/pages/pdf/DownloadReportButtonWithModal";

export const PdfRosterDownloadButton: FC = () => {
  const printProps = usePdfRosterProps();
  const viewType = printProps.viewType;

  const createReport = useCallback(() => {
    let report = <div></div>;

    if (printProps.workingRoster) {
      report = (
        <PdfRosterDocument
          {...printProps}
          workingRoster={printProps.workingRoster}
        />
      );
    }
    return report;
  }, [printProps.workingRoster, viewType]);

  const locationName = printProps.locationName.replaceAll(' ', '');
  const fileName = printProps.workingRoster
    ? strings.daily.roster.print.fileName(dateToApiFormat(printProps.workingRoster.date), locationName)
    : '';

  return <DownloadReportButtonWithModal name={fileName} createReport={createReport} testRoutePath={"/rosterTest"} />;
};
