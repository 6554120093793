import { ToggleField } from '@/components/Toggle';
import { Button } from '@/components/button';
import { useGetMeQuery } from '@/services/gql/graphql.generated';
import { useGetDefaultPreferences } from '@/services/settings/useGetDefaultPreferences';
import { useGetMySettings } from '@/services/settings/useGetMySettings';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { Form } from '@softwareimaging/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Setting } from '../layout/Setting';
import { SettingsSection } from '../layout/SettingsSection';
import { useUpdateStaffSettings } from '../services/useUpdateStaffSettings';
import { DailyRosterHeader } from './DailyRosterHeader';

export interface PreferenceFields {
  taskDisplay: {
    [key: string]: boolean;
  };
}

export const TaskDisplay: FC = () => {
  const [_, refetch] = useGetMeQuery();
  const mySettings = useGetMySettings();
  const defaultPreferences = useGetDefaultPreferences();
  const { update, fetching } = useUpdateStaffSettings();
  const prefStrings = strings.settings.preferences;

  const tasks = useTasks();

  const methods = useForm<PreferenceFields>({
    defaultValues: {
      taskDisplay: defaultPreferences.taskDisplay
    }
  });

  const submitPrefs = async (data: PreferenceFields) => {
    mySettings!.settings.preferences = {
      ...defaultPreferences,
      taskDisplay: data.taskDisplay
    };
    await update([mySettings!]);
    methods.reset(data);
    refetch({ requestPolicy: 'network-only' });
  };

  const onCancel = () => {
    methods.reset();
  };

  return (
    <div className="overflow-auto h-full pb-5">
      <Form methods={methods} onSubmit={submitPrefs}>
        <DailyRosterHeader />
        <SettingsSection title={prefStrings.dailyRoster.taskDisplay}>
          {tasks.map(task => (
            <Setting key={task.id} title={task.displayName}>
              <ToggleField id={task.id} name={`taskDisplay.${task.id}`} className="justify-self-center"></ToggleField>
            </Setting>
          ))}
        </SettingsSection>
        <div className="px-5 mt-2 gap-4 flex">
          <Button disabled={!methods.formState.isDirty} type="button" onClick={onCancel}>
            {strings.common.cancel}
          </Button>
          <Button disabled={!methods.formState.isDirty} variant={'primary'} type="submit" loading={fetching}>
            {strings.common.save}
          </Button>
        </div>
      </Form>
    </div>
  );
};
