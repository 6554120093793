import { useSaveReassignedShifts } from '@/pages/daily/roster/useSaveReassignedShifts';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadRoster, useUpdateRosterMutation } from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { useUpdateStatusAndTimestamps, useWorkingRosterToGqlRoster } from './rosterHelper';
import { useSelectedRoster } from './providers/RosterOptionsService';
import { useWorkingRoster } from './providers/WorkingRosterService';

export const useSaveRoster = () => {
  const { workingRoster, setWorkingRoster } = useWorkingRoster();
  const selectedRoster = useSelectedRoster();
  const [{ fetching, error }, update] = useUpdateRosterMutation();
  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));

  const workingRosterToGqlRoster = useWorkingRosterToGqlRoster();
  const updateStatusAndTimestamps = useUpdateStatusAndTimestamps();

  const { saveReassignedShifts } = useSaveReassignedShifts();

  const saveRoster = useCallback(async () => {
    if (!workingRoster || !selectedRoster) {
      throw Error('Roster must be defined');
    }

    workingRoster.shifts = [...workingRoster.shifts].sort((a, b) => {
      if (a.start === undefined) {
        return 1;
      } else if (b.start === undefined) {
        return -1;
      }

      return a.start - b.start;
    });

    const result = await update({
      updateRoster: workingRosterToGqlRoster(workingRoster, selectedRoster as ReadRoster)
    });

    if (result.data?.updateRoster) {
      // merge status and timestamps from the result of the updateRoster mutation
      const newWorkingRoster = {...workingRoster};
      updateStatusAndTimestamps(newWorkingRoster, result.data?.updateRoster as ReadRoster);

      setWorkingRoster(newWorkingRoster);

      await saveReassignedShifts();

      unregisterForConfirmation();
    }

    return result;
  }, [workingRoster, setWorkingRoster, updateStatusAndTimestamps, unregisterForConfirmation,
    workingRosterToGqlRoster, update, selectedRoster, saveReassignedShifts]);

  return { saveRoster, error, fetching };
};
