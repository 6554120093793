import { BlobDownloadResponseParsed, BlobServiceClient } from '@azure/storage-blob';
import { useVariable } from '@softwareimaging/backstage';

export const convertBlobToJson = async (blob: BlobDownloadResponseParsed) => {
  const body = await blob.blobBody;
  const text = await body?.text();

  return JSON.parse(text!) as unknown;
};

export const useBlobService = () => {
  const sasString = useVariable('rosterStorageAccountSASConnectionString');

  if (!sasString) throw new Error('Missing SAS token');

  const blobServiceClient = BlobServiceClient.fromConnectionString(sasString);

  return blobServiceClient;
};
