import { FC, PropsWithChildren, createContext } from 'react';

export interface EmployeeOptionSettings {
  showStaffPickerColumn: (key: string) => boolean | undefined;
  reassignmentModel: 'on-request' | 'pull' | 'disabled';
}

interface Props extends PropsWithChildren {
  showStaffPickerColumn: (key: string) => boolean | undefined;
  reassignmentModel: 'on-request' | 'pull' | 'disabled';
}

export const EmployeeOptionSettingsContext = createContext<EmployeeOptionSettings>({
  showStaffPickerColumn: () => undefined,
  reassignmentModel: 'disabled'
});

export const EmployeeOptionSettingsProvider: FC<Props> = ({ showStaffPickerColumn, reassignmentModel, children }) => {
  const settings = {
    showStaffPickerColumn: showStaffPickerColumn,
    reassignmentModel: reassignmentModel
  }

  return <EmployeeOptionSettingsContext.Provider value={settings}>{children}</EmployeeOptionSettingsContext.Provider>;
};
