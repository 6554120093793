import { Button } from '@/components/button';
import { downloadFile } from '@/helpers/downloadHelper';
import { WorkingBudgetDay, WorkingBudgetLocation } from '@/services/budget/budget.types';
import { useBudget } from '@/services/budget/useBudget';
import { useSetRosteredBudgets } from '@/services/budget/useRosteredBudgets';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useDaysInCurrentMonth } from '@/services/settings/useDates';
import { strings } from '@/services/translation/strings';
import { useMyLocations } from '@/services/useLocations';
import { format } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { FC, useRef } from 'react';
import { FiDownload } from 'react-icons/fi';

interface Props {}

interface BudgetLine {
  shopId: number;
  shopName: string;
  date: Date;
  locationForecast?: number;
  dailySalesTarget?: number;
  rosterHours?: number;
}

const UNKNOWN = '【ご入力不要です】';

const BudgetCsvLine: FC<{ day: WorkingBudgetDay; locationId: number }> = ({ day, locationId }) => {
  const locations = useMyLocations();
  const locationData = locations.find(loc => loc.id === locationId);

  if (!locationData) {
    throw Error('Could not find location data');
  }

  const data: BudgetLine = {
    shopId: locationData.id,
    shopName: locationData.name,
    date: day.date,
    locationForecast: day.locationForecast / (60 * 60),
    dailySalesTarget: day.financialTarget,
    rosterHours: day.rosteredBudget / (60 * 60)
  };

  const dataToDisplay: string[] = [
    data.shopId.toString(),
    data.shopName,
    format(data.date, 'yyyy/MM/dd'),
    data.locationForecast?.toString() || UNKNOWN,
    data.dailySalesTarget?.toString() || UNKNOWN,
    data.rosterHours?.toString() || UNKNOWN
  ];

  return <p>{`\n${dataToDisplay.join(',')}`}</p>;
};

const BudgetCsvLocation: FC<{ location: WorkingBudgetLocation }> = ({ location }) => {
  const daysInMonth = useDaysInCurrentMonth();
  const { setRosteredBudgets } = useSetRosteredBudgets(location.id, daysInMonth);
  setRosteredBudgets(location.days);

  return (
    <>
      {location.days.map(day => (
        <BudgetCsvLine locationId={location.id} day={day} key={`${location.id}-${day.date}`} />
      ))}
    </>
  );
};

export const BudgetCsvDownload: FC<Props> = ({}) => {
  const daysInMonth = useDaysInCurrentMonth();
  const language = useLanguageProvider(state => state.language);
  const locale = language === 'ja' ? ja : enUS;
  const monthString = format(daysInMonth[0], 'MMMM-yyyy', { locale });
  const fileName = `${strings.budget.filePrefix}-${monthString}.csv`.toLowerCase();

  const ref = useRef(null);

  const onClick = () => {
    // @ts-ignore
    downloadFile(ref.current.textContent, 'text/csv', fileName);
  };

  const selectedLocations = useLocalSettings(state => state.viewableLocationIds);

  const { locations } = useBudget(selectedLocations, daysInMonth);

  const columnHeaders = [
    strings.budget.csvHeader.shopId,
    strings.budget.csvHeader.shopName,
    strings.budget.csvHeader.date,
    strings.budget.csvHeader.locationForecast,
    strings.budget.csvHeader.dailySalesTarget,
    strings.budget.csvHeader.rosterHours
  ];

  return (
    <>
      <Button onClick={onClick}>
        <FiDownload className="mr-2" />
        {strings.common.file.download}
      </Button>
      <div ref={ref} className="hidden">
        <p>{columnHeaders.join(',')}</p>
        {locations.map(location => (
          <BudgetCsvLocation location={location} key={location.id} />
        ))}
      </div>
    </>
  );
};
