import { DurationInput } from '@/components/form/DurationInput';
import { FC } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { StaffRowCell } from './StaffRowCell';

export const StaffRowMinMaxWeeklySection: FC<StaffRowSectionProps> = ({ staff, hidden, onChangeSetting, group }) => {
  return (
    <>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[0].isColumnHidden}>
        <DurationInput
          className="w-[90%] text-xs p-2"
          value={staff.settings.minWeeklyHours}
          setValue={onChangeSetting('minWeeklyHours')}
        />
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[1].isColumnHidden}>
        <DurationInput
          className="w-[90%] text-xs p-2"
          value={staff.settings.maxWeeklyHours}
          setValue={onChangeSetting('maxWeeklyHours')}
        />
      </StaffRowCell>
    </>
  );
};


export const StaffRowMinMaxMonthlySection: FC<StaffRowSectionProps> = ({ staff, hidden, onChangeSetting, group }) => {
  return (
    <>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[0].isColumnHidden}>
        <DurationInput
          className="w-[90%] text-xs p-2"
          value={staff.settings.minMonthlyHours}
          setValue={onChangeSetting('minMonthlyHours')}
        />
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[1].isColumnHidden}>
        <DurationInput
          className="w-[90%] text-xs p-2"
          value={staff.settings.maxMonthlyHours}
          setValue={onChangeSetting('maxMonthlyHours')}
        />
      </StaffRowCell>
    </>
  );
};
