import { FC, Suspense, useCallback, useState } from 'react';
import { TemplateAddModal } from '../menu/TemplateAddModal';
import { TemplateDeleteModal } from '../menu/TemplateDeleteModal';
import { TemplateImportModal } from '../menu/TemplateImportModal';
import { TemplateMenuUpdateModal } from '../menu/TemplateUpdateModal';
import { TemplateMenuOptions } from './TemplateMenuOptions';
import { TemplateShopOption, useTemplateService } from './TemplateService';
import { useValidateTemplateName } from './useValidateTemplateName';

export const TemplateMenu: FC = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState<TemplateShopOption | undefined>(undefined);
  const isEditModalOpen = !!templateToEdit;
  const [templateToDelete, setTemplateToDelete] = useState<TemplateShopOption | undefined>(undefined);
  const isDeleteModalOpen = !!templateToDelete;

  const createTemplateAndNavigate = useTemplateService(s => s.createTemplateAndNavigate);
  const validateName = useValidateTemplateName();

  const selectedTemplate = useTemplateService(s => s.selectedTemplateId);
  const setSelectedTemplate = useTemplateService(s => s.setSelectedTemplateId);
  const templateOptions = useTemplateService(s => s.templateOptions);
  
  const refetchTemplates = useTemplateService(s => s.refetchTemplates);

  const onDeleteComplete = useCallback(() => {
    if (selectedTemplate === templateToDelete?.id) {
      const findNextTemplate = templateOptions.find(t => t.id !== templateToDelete?.id);
      if (findNextTemplate) {
        setSelectedTemplate(findNextTemplate.id);
      }
    }

    refetchTemplates();
  }, [selectedTemplate, setSelectedTemplate, templateOptions, templateToDelete]);

  return (
    <>
      <TemplateMenuOptions
        onImport={() => setIsImportModalOpen(true)}
        onAdd={() => setIsAddModalOpen(true)}
        onEdit={setTemplateToEdit}
        onDelete={setTemplateToDelete}
      />
      <TemplateAddModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onComplete={createTemplateAndNavigate}
        validateName={validateName}
      />
      <TemplateImportModal
        open={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onComplete={createTemplateAndNavigate}
        validateName={validateName}
      />
      <Suspense>
        {templateToEdit && (
          <TemplateMenuUpdateModal
            open={isEditModalOpen}
            onClose={() => setTemplateToEdit(undefined)}
            templateId={templateToEdit.id}
            defaultTemplateName={templateToEdit.name}
            defaultTagList={templateToEdit.template.templateTags}
            validateName={validateName}
            allowNameChange
          />
        )}
      </Suspense>
      {templateToDelete && (
        <TemplateDeleteModal
          open={isDeleteModalOpen}
          onClose={() => setTemplateToDelete(undefined)}
          templateIdToDelete={templateToDelete.id}
          templateName={templateToDelete.name}
          onComplete={onDeleteComplete}
        />
      )}
    </>
  );
};
