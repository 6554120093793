import {
    useAssignEmployeesToPositionsMutation, useSetPositionPropertiesMutation
} from '@/services/gql/graphql.generated';
import { useCallback } from 'react';
import { useReassignmentService } from './providers/ReassignmentService';

export const useSaveReassignedShifts = () => {
  const { reassignedShifts, refetchReassignedShifts } = useReassignmentService(state => ({
    reassignedShifts: state.reassignedShifts, refetchReassignedShifts: state.refetchReassignedShifts
  }));
  const [{ error: provideStaffError }, provideStaffToOtherShops] = useAssignEmployeesToPositionsMutation();
  const [{ error: updatePropertiesError }, updatePositionProperties] = useSetPositionPropertiesMutation();

  const saveReassignedShifts = useCallback(async () => {
    if (reassignedShifts.length) {
      await provideStaffToOtherShops({
        positionAndEmployeePairs: reassignedShifts.map(s => {
          return {
            positionId: s.id,
            employeeId: s.assignedEmployeeId
          };
        })
      });

      await updatePositionProperties({
        idAndPropertiesPairs: reassignedShifts.map(s => {
          return {
            id: s.id,
            properties: JSON.stringify(s.properties)
          };
        })
      });

      // The urql client skips making a network request (of the FindShifts query) on re-render while the requestPolicy is set to 'network-only'.
      // To work around, refetch data...
      refetchReassignedShifts();
    }
    return true;
  }, [reassignedShifts, refetchReassignedShifts, provideStaffToOtherShops, updatePositionProperties]);

  return { saveReassignedShifts, provideStaffError, updatePropertiesError };
};
