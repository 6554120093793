import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import {StaffAvailabilityMonth} from "@/services/availability/useStaffAvailability";
import {styles} from "@/pages/pdf/PdfStyles";
import {PdfTableCell} from "@/pages/pdf/PdfTableCell";
import {MONTHLY_NAME_WIDTH} from "@/pages/pdf/availability/PdfAvailabilityDocument";
import {PdfAvailabilityDay, PdfAvailabilityParams} from "@/pages/pdf/availability/PdfAvailabilityDay";

interface Props {
  data: StaffAvailabilityMonth;
  params: PdfAvailabilityParams;
}

export const PdfAvailabilityEmployeeRow: FC<Props> = ({ data, params }) => {
  const { days } = data;
  return (
    <View style={styles.tableRow}>
      <PdfTableCell width={MONTHLY_NAME_WIDTH}>
        <View style={{ padding: '2px 0px' }}>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{data.displayEmployeeName}</Text>
          <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{data.displayContractName}</Text>
        </View>
      </PdfTableCell>
      {days.map((day, dayIndex: number) => (
        <PdfTableCell key={dayIndex} lastCell={dayIndex === days.length - 1}>
          <PdfAvailabilityDay data={day} params={params} />
        </PdfTableCell>
      ))}
    </View>
  );
};
