import { Tooltip, TooltipTypes } from '@/components/tooltip/Tooltip';
import { FunctionComponent } from 'react';
import { GanttTaskCapabilityTooltip } from './GanttTimePicker';

export interface GanttTaskTooltip {
  text?: string;
  color: string;
  bgColor: string;
}

interface GanttBarTooltipProps {
  capabilityTooltip?: GanttTaskCapabilityTooltip;
  taskTooltip?: GanttTaskTooltip;
  className?: string;
}

const GanttBarTooltip: FunctionComponent<GanttBarTooltipProps> = ({ capabilityTooltip, taskTooltip, className }) => {
  const tooltipType = capabilityTooltip ? capabilityTooltip?.type : TooltipTypes.info;

  return (
    <Tooltip
      position="left"
      className="w-[150px]"
      triggerClassName={className}
      type={tooltipType}
      solidIcon
      iconColorOverride={tooltipType === TooltipTypes.info ? taskTooltip?.bgColor : ''}
    >
      <div className="space-y-2 text-xs ">
        {taskTooltip && taskTooltip.text && (
          <p className="font-semibold" style={{ color: taskTooltip.color }}>
            {taskTooltip.text}
          </p>
        )}
        <p>{capabilityTooltip && capabilityTooltip.text}</p>
      </div>
    </Tooltip>
  );
};

export default GanttBarTooltip;
