import { useGetMeQuery } from '../gql/graphql.generated';
import { useStaffSettings } from './StaffSettingsProvider';

export const useGetMySettings = () => {
  const [{ data: myData }] = useGetMeQuery();
  const settings = useStaffSettings(state => state.settings);
  const mySettings = settings.find(item => item.employee.employeeId == myData?.myEmployee?.employeeId);

  return mySettings;
};
