import { Icon } from '@/helpers/dropdownOptionHelper';
import { TaskType } from '@/services/tasks/task.types';
import { strings } from '@/services/translation/strings';
import { Text, View } from '@react-pdf/renderer';
import { FC, useContext } from 'react';
import { styles } from '../PdfStyles';
import { SvgIcon } from '../SvgIcon';
import { PdfRosterContext } from './PdfRosterProvider';

export const TASK_ROW_LENGTH = 6;

const PdfRosterShiftBox: FC<{
  name: string;
  color: string;
  icon?: Icon;
  textColor?: string;
  widthPercentage?: number;
}> = ({ name, color, icon, textColor, widthPercentage }) => {
  return (
    <View
      style={{
        width: widthPercentage ? `${widthPercentage}%` : undefined,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
      }}
    >
      <View
        style={{
          backgroundColor: color,
          width: '20px',
          height: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid black'
        }}
      >
        {icon ? (
          <SvgIcon paths={icon.svgPaths} viewBoxSize={icon.svgViewboxSize} size={12} color={textColor} />
        ) : (
          <Text />
        )}
      </View>
      <Text style={{ ...styles.tableCellSm, textAlign: 'left' }}>{name}</Text>
    </View>
  );
};

const PdfRosterTask: FC<{ task: TaskType }> = ({ task }) => {
  const { iconList } = useContext(PdfRosterContext);

  const icon = iconList.find(i => i.id.toString() == task.icon);

  const width = Math.floor(100 / TASK_ROW_LENGTH);

  return (
    <PdfRosterShiftBox
      name={task.name}
      color={task.color}
      icon={icon}
      textColor={task.textColor}
      widthPercentage={width}
    />
  );
};

export const PdfRosterTaskKey: FC = () => {
  const { taskList } = useContext(PdfRosterContext);

  const rows: TaskType[][] = [[]];
  let currentRow = rows[0];
  taskList.forEach(task => {
    if (currentRow.length === TASK_ROW_LENGTH) {
      currentRow = [];
      rows.push(currentRow);
    }

    currentRow.push(task);
  });

  return (
    <View style={{ width: '100%', border: '1px solid black', marginTop: 10, padding: 5, flexDirection: 'row' }}>
      <View style={{ width: '17%' }}>
        <Text style={{ fontSize: 10 }}>{strings.daily.drag.shift}</Text>
        <View style={{ flexDirection: 'row'}}>
          <View style={{ gap: 5, flex: 1}}>
            <PdfRosterShiftBox name={strings.daily.drag.shiftTypes.work} color={'rgba(110, 235, 131, 0.6)'} />
            <PdfRosterShiftBox name={strings.daily.drag.shiftTypes.break} color={'rgba(126, 178, 221, 1)'} />
          </View>
          <View style={{ gap: 5, flex: 1 }}>
            <PdfRosterShiftBox name={strings.keys.reassigned} color={'#E8AA14'} />
            <PdfRosterShiftBox name={strings.keys.unassigned} color={'#c23934'} />
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ fontSize: 10 }}>{strings.daily.drag.tasks}</Text>
        <View style={{ gap: 5, alignItems: 'flex-start' }}>
          {rows.map((row, index) => (
            <View
              key={index}
              style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5, justifyContent: 'flex-start' }}
            >
              {row.map(task => (
                <PdfRosterTask task={task} key={task.id} />
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};
