import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { MONTHLY_SORT_TYPES, useMonthlySortService } from '@/services/sort/MonthlySortProvider';
import { useCallback } from 'react';
import { SummaryEmployee } from '@/services/summary/summary.types';

export const useSortMonthlyData = () => {
  const sortOrder = useMonthlySortService(state => state.sortOrder);
  const displayIds = useSystemSettings(s => s.settings.displayEmployeeIds);
  const language = useLanguageProvider(state => state.language);

  return useCallback(
    (data: SummaryEmployee[]) => {
      return data.sort((a, b) => {
        for (const sortType of sortOrder) {
          if (sortType.type === MONTHLY_SORT_TYPES.periodTotal) {
            if (a.compliance.actualTime < b.compliance.actualTime) return sortType.asc ? -1 : 1;
            if (a.compliance.actualTime > b.compliance.actualTime) return sortType.asc ? 1 : -1;
          }
          if (sortType.type === MONTHLY_SORT_TYPES.max) {
            if (a.settings.maxMonthlyHours < b.settings.maxMonthlyHours) return sortType.asc ? -1 : 1;
            if (a.settings.maxMonthlyHours > b.settings.maxMonthlyHours) return sortType.asc ? 1 : -1;
          }
          if (sortType.type === MONTHLY_SORT_TYPES.min) {
            if (a.settings.minMonthlyHours < b.settings.minMonthlyHours) return sortType.asc ? -1 : 1;
            if (a.settings.minMonthlyHours > b.settings.minMonthlyHours) return sortType.asc ? 1 : -1;
          }
          if (sortType.type === MONTHLY_SORT_TYPES.shopPosition) {
            if (a.role < b.role) return sortType.asc ? -1 : 1;
            if (a.role > b.role) return sortType.asc ? 1 : -1;
          }
          if (sortType.type === MONTHLY_SORT_TYPES.name) {
            const comparison = a.name.localeCompare(b.name, [language === 'ja' ? 'ja' : 'en']);
            if (comparison !== 0) {
              return sortType.asc ? comparison : -comparison;
            }
          }
          if (sortType.type === MONTHLY_SORT_TYPES.employeeId && displayIds) {
            if (a.employeeId < b.employeeId) return sortType.asc ? -1 : 1;
            if (a.employeeId > b.employeeId) return sortType.asc ? 1 : -1;
          }
        }
        return 0;
      });
    },
    [sortOrder, displayIds]
  );
};
