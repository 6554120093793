import { Button } from '@/components/button';
import { Modal } from '@/components/modal/Modal';
import { ReadTemplate, useCopyTemplateMutation, useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { DailyProperties } from '../../daily.types';
import { useConvertToUpdatable } from '../templateHelper';
import { TemplateSearchAndSelect } from '../templateSearch/TemplateSearchAndSelect';
import { usePublishedTemplateLibraryOptions } from '@/services/rosters/useTemplateLibraryOptions';

interface Props {
  open: boolean;
  onClose: () => void;
  onComplete: (newTemplate: ReadTemplate) => void;
  validateName: (newName: string) => string | null;
}

export const TemplateImportModal: FC<Props> = ({ open, onClose, onComplete, validateName }) => {
  const [error, setError] = useState<string | undefined>(undefined);

  const [{ fetching: copyFetching }, copy] = useCopyTemplateMutation();
  const [{ fetching: updateFetching }, update] = useUpdateTemplateMutation();

  const libraryTemplates = usePublishedTemplateLibraryOptions();
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(undefined);

  const location = useSelectedLocation();

  const convertToUpdatable = useConvertToUpdatable();

  const onImport = async () => {
    if (!selectedOptionId) {
      setError(strings.daily.templates.library.importModal.mustSelect);
      return;
    }

    const selectedOption = libraryTemplates.find(t => t.id === selectedOptionId);
    if (!selectedOption) {
      throw Error('Invalid template option selected');
    }

    const newTemplateName = `${selectedOption.name}- v${selectedOption.template.version}`;
    const errorMessage = validateName(newTemplateName);
    if (errorMessage) {
      setError(strings.daily.templates.library.importModal.alreadyImported);
      return;
    }

    const copyResult = await copy({
      sourceTemplateId: selectedOption.id,
      name: newTemplateName,
      locationId: location.id
    });
    const newTemplate = copyResult.data?.copyTemplate as ReadTemplate;

    if (copyResult.error || !newTemplate) {
      setError(strings.daily.templates.myTemplates.addModal.generalError);
      return;
    }

    const updateTemplate = convertToUpdatable(newTemplate);

    if (!updateTemplate.properties) {
      throw Error('Copied template is missing properties object');
    }

    const properties = JSON.parse(updateTemplate.properties) as DailyProperties;
    properties.importDate = format(new Date(), 'yyyy/MM/dd HH:mm:ss');
    properties.libraryTemplateName = updateTemplate.name;
    updateTemplate.properties = JSON.stringify(properties);
    updateTemplate.locationId = location.id;

    const updateResult = await update({ updateTemplate });

    if (updateResult.error) {
      setError(strings.daily.templates.myTemplates.addModal.generalError);
    } else {
      onClose();
      onComplete(newTemplate);
    }
  };

  const closeAndClear = () => {
    onClose();
    setTimeout(() => {
      setSelectedOptionId(undefined);
      setError(undefined);
    }, 500);
  };

  return (
    <Modal onClose={closeAndClear} open={open} title={strings.daily.templates.library.importModal.title}>
      <div className="space-y-4">
        <div className="space-y-2 flex flex-col">
          <span className="font-semibold text-sm">{strings.daily.templates.library.importModal.selectTemplate}</span>
          <TemplateSearchAndSelect
            selectedTemplateId={selectedOptionId}
            onSelect={setSelectedOptionId}
            templateOptions={libraryTemplates}
            addVersion
          />
          {error && <p className="text-red-500 text-xs">{error}</p>}
        </div>
        <div className="flex justify-end gap-5 items-center">
          <Button onClick={closeAndClear}>{strings.common.cancel}</Button>
          <Button onClick={onImport} loading={copyFetching || updateFetching}>
            {strings.common.ok}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
