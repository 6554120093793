import { InfoCell } from '@/components/table/InfoCell';
import { secondsToTimeString } from '@/helpers/dateHelper';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { WorkingShift } from '@/pages/daily/daily.types';
import { useRosterEmployeeSummary } from './providers/WorkingRosterService';

interface MonthlyTotalColumnsProps {
  shift: WorkingShift;
}

export const MonthlyTotalColumns: FC<MonthlyTotalColumnsProps> = ({ shift }) => {
  const summary = useRosterEmployeeSummary(shift.assignedEmployeeId);

  const minTime = summary?.homeEmployee ? summary?.settings.minMonthlyHours : undefined;
  const maxTime = summary?.homeEmployee ? summary?.settings.maxMonthlyHours : undefined;
  const total = summary?.homeEmployee ? summary?.compliance.actualTime : undefined

  let totalColorClass = '';
  if (total) {
    if (minTime !== undefined && total < minTime) {
      totalColorClass = 'text-primary';
    } else if (maxTime !== undefined && total > maxTime) {
      totalColorClass = 'text-secondary';
    }
  }

  return (
    <>
      <InfoCell className="border-r flex-1">
        <p>{maxTime !== undefined ? secondsToTimeString(maxTime) : '--:--'}</p>
        <p>{minTime !== undefined ? secondsToTimeString(minTime) : '--:--'}</p>
      </InfoCell>
      <InfoCell className={twMerge('flex-1', totalColorClass)}>
        <p>{total ? secondsToTimeString(total) : '--:--'}</p>
      </InfoCell>
    </>
  );
};
