import { useStaffSettings } from '@/services/settings/StaffSettingsProvider';
import { useEditSettingsService } from './EditSettingsService';
import { useEffect } from 'react';

export const useRefreshStaffSettings = () => {
  const staffSettingsFromDb = useStaffSettings(state => state.settings);
  const { workingStaffSettings, updateWorkingStaffSettings } = useEditSettingsService(state => ({
    workingStaffSettings: state.workingStaffSettings,
    updateWorkingStaffSettings: state.setWorkingStaffSettings
  }));

  useEffect(() => {
    if (workingStaffSettings.length !== staffSettingsFromDb.length) {
      updateWorkingStaffSettings(staffSettingsFromDb, true);
    }
  }, [workingStaffSettings, staffSettingsFromDb, updateWorkingStaffSettings]);
};
