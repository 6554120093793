import { FC, useEffect, useRef, useState } from 'react';
import { NotificationBox } from './NotificationBox';
import { useNotificationService } from '@/services/notifications/NotificationService';
import { useTrackOptimiserProgress } from '@/services/optimiser/useTrackOptimiserProgress';
import { useSelectedLocation } from "@/services/settings/LocalSettingsProvider";
import { useRole } from "@/services/authentication/hooks/user";
import { RoleType } from "@/services/authentication/roles";

interface Props {
  navbarHeight?: number;
}

export const Notifications: FC<Props> = ({ navbarHeight }) => {
  useTrackOptimiserProgress();
  const [isNotificationBoxOpen, setIsNotificationBoxOpen] = useState(false);
  const { filterNotifications, markAsRead } = useNotificationService(state => ({
    filterNotifications: state.filterNotifications,
    markAsRead: state.markAsRead
  }));

  const role = useRole();
  const selectedLocationId = useSelectedLocation()?.id;
  const notifications = filterNotifications((role === RoleType.Admin || role === RoleType.Super_Admin) ? undefined : selectedLocationId);
  const unreadNotificationsCount = notifications.filter(n => !n.isRead).length;

  const notificationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setIsNotificationBoxOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onClose = () => {
    setIsNotificationBoxOpen(false);
  };

  return (
    <div>
      <div className="relative">
        <div
          className="relative flex select-none hover:cursor-pointer"
          onClick={() => setIsNotificationBoxOpen(!isNotificationBoxOpen)}
        >
          <img src="/notifications.svg" alt="Notifications" width="25 lg:30" />
          {unreadNotificationsCount > 0 && (
            <div className="absolute flex justify-center items-center content-center text-center rounded-full bg-[#D63566] text-white text-[8px] p-0 font-bold leading-none w-[16px] h-[16px] right-[-5px] top-[-5px] outline outline-[1.5px]">
              {unreadNotificationsCount}
            </div>
          )}
        </div>
      </div>
      {isNotificationBoxOpen && (
        <div ref={notificationRef}>
          <NotificationBox
            notifications={notifications}
            onClose={onClose}
            markNotificationAsRead={markAsRead}
            boxHeightCorrection={navbarHeight}
          />
        </div>
      )}
    </div>
  );
};
