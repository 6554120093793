import React, { FC, PropsWithChildren } from 'react';
import { PdfRosterProps } from './useRosterPrintProps';

interface Props extends PropsWithChildren {
  rosterPdfProps: PdfRosterProps;
}

export const PdfRosterContext = React.createContext<PdfRosterProps>({
  getContractName: function (id: string): string {
    throw new Error('Function not implemented.');
  },
  reassignedShifts: [],
  unassignedGhostShifts: [],
  employees: [],
  maxTime: 0,
  minTime: 0,
  locationName: '',
  counts: [],
  minuteIncrement: 0,
  taskList: [],
  iconList: [],
  budgetStats: {
    totalHq: 0,
    totalRostered: 0,
    totalLocationForecast: 0,
    monthlyDelta: 0,
    rosteredBudget: 0,
    totalShifts: 0,
    totalFilledShifts: 0
  },
  budgetLocation: {
    id: 0,
    days: []
  },
  budgetDay: {
    date: new Date(),
    locationForecast: 0,
    rosteredBudget: 0,
    hqAllocatedHours: 0,
    financialTarget: 0,
    filledShiftCount: 0,
    totalShiftCount: 0,
    unfilledShifts: []
  },
  summary: []
});

export const PdfRosterProvider: FC<Props> = ({ rosterPdfProps, children }) => {
  return <PdfRosterContext.Provider value={rosterPdfProps}>{children}</PdfRosterContext.Provider>;
};
