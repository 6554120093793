const RESOLUTION_BREAKPOINT = 1400;
let EMPLOYEE_NAME_WIDTH;

// if we are on a smaller resolution, make the employee name column smaller
if(window.innerWidth <= RESOLUTION_BREAKPOINT) {
    EMPLOYEE_NAME_WIDTH = 150;
} else {
    // default employee name column
    EMPLOYEE_NAME_WIDTH = 200;
}

export const EMPLOYEE_NAME_COLUMN_WIDTH = `${EMPLOYEE_NAME_WIDTH}px`;

const EMPLOYEE_DETAILS_SUBCOLUMN_WIDTH = 70;
export const EMPLOYEE_DETAILS_COLUMN_WIDTH = `${EMPLOYEE_DETAILS_SUBCOLUMN_WIDTH * 5}px`;

export const EMPLOYEE_INFO_WIDTH = `${EMPLOYEE_DETAILS_SUBCOLUMN_WIDTH * 5 + EMPLOYEE_NAME_WIDTH}px`;

export const BUDGET_NAME_COLUMN_WIDTH = '160px';
export const BUDGET_TOTAL_COLUMN_WIDTH = '70px';
export const WEEKLY_TOTAL_COLUMN_WIDTH = '140px';

export const MONTHLY_TOTAL_COLUMN_WIDTH = 240;
export const COMPLIANCE_TOTAL_COLUMN_WIDTH = 120;