import { objectDeepClone, showToastMessage } from '@/helpers/helper';
import { Announcement } from '@/pages/settings/sections/manageAnnouncements/Announcement';
import { useEditSettingsService } from '@/pages/settings/services/EditSettingsService';
import { useUpdateLocationSettings } from '@/pages/settings/services/useUpdateLocationSettings';
import { useCallback, useMemo } from 'react';
import { useConfirm } from '../confirm/ConfirmationService';
import { useLocalSettings, useSelectedLocation } from '../settings/LocalSettingsProvider';
import { LocationSettings, LocationWithSettings } from '../settings/systemSettings.types';

export const useAnnouncements = () => {
  const { properties: propertiesString } = useSelectedLocation();

  return useMemo((): Announcement[] => {
    const properties: LocationSettings = propertiesString ? JSON.parse(propertiesString) : {};

    return properties.announcements || [];
  }, [propertiesString]);
};

export const useActiveAnnouncements = () => {
  const announcements = useAnnouncements();

  return useMemo(() => announcements.filter(a => a.isActive), [announcements]);
};

export const useSaveAnnouncements = (): any => {
  const locationId = useLocalSettings(state => state.selectedLocationId);
  const { updateSettings: updateLocationSettings, fetching } = useUpdateLocationSettings();
  const workingLocationSettings = useEditSettingsService(state => state.workingLocationSettings);
  
  const { unregisterForConfirmation } = useConfirm(state => ({ unregisterForConfirmation: state.unregisterForConfirmation}));

  const saveAnnouncements = useCallback(
    async (announcements: Announcement[]) => {
      try {
        const locationSettings: LocationWithSettings[] = objectDeepClone(workingLocationSettings);
        const selectedLocationsSettingsIndex = locationSettings.findIndex((wl: any) => wl.location.id === locationId);

        if (selectedLocationsSettingsIndex === -1) {
          throw Error('Announcements failed to set because of not existing location in the settings!');
        }

        locationSettings[selectedLocationsSettingsIndex].settings['announcements'] = announcements;

        const result = await updateLocationSettings(locationSettings);
        showToastMessage(result);
      } catch (error) {
        console.log('Save announcements to location failed!', error);
      }

      unregisterForConfirmation();
    },
    [locationId, updateLocationSettings, workingLocationSettings]
  );

  return { fetching, save: saveAnnouncements };
};
