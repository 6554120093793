import { SelectOption } from '@/components/form/SelectInput';
import { displaySecondsAsTime } from '@/components/gantt/gantt/ganttHelper';
import { hoursToSeconds } from 'date-fns';
import { useMemo } from 'react';

export const useOpenClosingTimeOptions = (startTime: number, endTime: number) => {
  return useMemo(() => {
    const options: number[] = [];

    const startTimeInHours = startTime / 3600;
    const endTimeInHours = endTime / 3600;

    for (let i = startTimeInHours; i <= endTimeInHours; i += 0.5) {
      options.push(hoursToSeconds(i));
    }

    const selectOptions: SelectOption[] = [
      {
        value: undefined,
        name: '--:--'
      },
      ...options.map(val => ({
        value: val,
        name: displaySecondsAsTime(val)
      }))
    ];

    return selectOptions;
  }, [startTime, endTime]);
};
