import { cn } from '@/lib/utils';
import { GanttGridLinesThickness } from '@/services/settings/systemSettings.types';
import { getMePreferences } from '@/services/staff/preferences';
import { clsx } from 'clsx';
import { FC, HTMLAttributes, useCallback, useEffect, useRef } from 'react';

interface HandleProps extends HTMLAttributes<HTMLDivElement> {
  onDragHandle: (event: MouseEvent) => void;
  start?: boolean;
  sub?: boolean;
  internal?: boolean;
}

const convertThicknessToPxValue = (size: GanttGridLinesThickness) => {
  switch (size) {
    case GanttGridLinesThickness.extraLarge:
      return 22;
    case GanttGridLinesThickness.large:
      return 18;
    case GanttGridLinesThickness.medium:
      return 14;
    case GanttGridLinesThickness.small:
      return 10;
  }
};

const getLineSize = (size: GanttGridLinesThickness) => {
  switch (size) {
    case GanttGridLinesThickness.extraLarge || GanttGridLinesThickness.large:
      return 2;
    case GanttGridLinesThickness.large:
      return 2;
    case GanttGridLinesThickness.medium:
      return 1;
    case GanttGridLinesThickness.small:
      return 1;
  }
};

export const Handle: FC<HandleProps> = ({ onDragHandle, className, start, sub, internal }) => {
  const ref = useRef<HTMLDivElement>(null);

  const prefs = getMePreferences();

  const size = prefs ? prefs.personalization.ganttGridLinesThickness : GanttGridLinesThickness.extraLarge;
  const barThicknessPxSize = convertThicknessToPxValue(size);
  const linePxSize = getLineSize(size);

  let intClassName = 'absolute z-30 rounded-md h-full cursor-ew-resize';

  const barThicknessClasses: any = {};

  if (sub) {
    intClassName = clsx(intClassName, 'bg-white border w-[5px]');

    if (start) {
      intClassName = clsx(intClassName, 'left-[-5px]');
    } else {
      intClassName = clsx(intClassName, 'right-[-5px]');
    }
  } else {
    intClassName = clsx(intClassName, `bg-gray-600 flex px-[4px] py-1 justify-evenly`);
    barThicknessClasses.width = `${barThicknessPxSize}px`;
    if (start) {
      barThicknessClasses.left = internal ? '' : `-${barThicknessPxSize}px`;
    } else {
      barThicknessClasses.right = internal ? '' : `-${barThicknessPxSize}px`;
    }
  }

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onDragHandle);
  }, [onDragHandle]);

  const onMouseDown = useCallback(() => {
    if (window.getSelection) {
      window.getSelection()?.removeAllRanges();
    }
    window.addEventListener('mousemove', onDragHandle);
    window.addEventListener('mouseup', onMouseUp, { once: true });
  }, [onDragHandle, onMouseUp]);

  useEffect(() => {
    const element = ref.current;

    element?.addEventListener('mousedown', onMouseDown);

    return () => {
      element?.removeEventListener('mousedown', onMouseDown);
      element?.removeEventListener('mousemove', onDragHandle);
      element?.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseDown, onMouseUp, onDragHandle, ref]);

  return (
    // Interpolated values in tailwind seem to not generate/apply correctly, so use inline style instead
    <div ref={ref} style={barThicknessClasses} className={clsx(className, intClassName)}>
      {!sub && (
        <>
          {size !== GanttGridLinesThickness.small && (
            <div style={{ width: `${linePxSize}px` }} className={cn(`bg-white h-full`)} />
          )}
          {size !== GanttGridLinesThickness.small && (
            <div
              style={{ width: `${linePxSize}px` }}
              className={cn(`h-full`, size === GanttGridLinesThickness.extraLarge ? 'bg-white' : 'bg-gray-600')}
            />
          )}
          <div style={{ width: `${linePxSize}px` }} className={cn(`bg-white h-full`)} />
        </>
      )}
    </div>
  );
};
