import { useConfirm } from '@/services/confirm/ConfirmationService';
import { ReadTemplate, useUpdateTemplateMutation } from '@/services/gql/graphql.generated';
import { useTemplatesForLocation } from '@/services/shared/useTemplatesForLocation';
import { useCallback } from 'react';
import { useWorkingTemplateToGqlTemplate } from '../templateHelper';
import { useSelectedTemplate, useWorkingTemplate } from './TemplateService';

export const useSaveTemplate = () => {
  const { workingTemplate } = useWorkingTemplate();
  const selectedTemplate = useSelectedTemplate();
  const [{ fetching, error }, update] = useUpdateTemplateMutation();
  const { unregisterForConfirmation } = useConfirm(state => ({
    unregisterForConfirmation: state.unregisterForConfirmation
  }));
  const workingTemplateToGqlTemplate = useWorkingTemplateToGqlTemplate();

  const { templateOptions } = useTemplatesForLocation();
  const isDailyTemplate = !!templateOptions.find(t => t.id === workingTemplate?.id);

  const saveTemplate = useCallback(async () => {
    if (!workingTemplate || !selectedTemplate) {
      throw Error('Template must be defined');
    }

    const result = await update({
      updateTemplate: workingTemplateToGqlTemplate(workingTemplate, selectedTemplate as ReadTemplate, isDailyTemplate)
    });

    unregisterForConfirmation();

    return result;
  }, [workingTemplate, update, selectedTemplate, workingTemplateToGqlTemplate, isDailyTemplate]);

  return { saveTemplate, error, fetching };
};
