import { ItemTypes } from '@/components/gantt/dragDrop/types';
import { GanttTimePicker, GhostGanttSection } from '@/components/gantt/gantt/GanttTimePicker';
import { FC, useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useDebouncedCallback } from 'use-debounce';
import { DailyEditingType, WorkingShift, WorkingShiftSubsection } from './daily.types';
import { itemTypeToColor } from './dailyHelper';
import { useBuildGanttSubSections } from './useBuildGanttSubSections';

export interface GhostShift {
  startTime: number;
  endTime: number;
}

interface Props {
  shift: WorkingShift;
  ghostShifts?: GhostShift[];
  updateShift: (newShift: WorkingShift) => void;
  type: DailyEditingType;
  readOnly?: boolean;
  useColorizedBar?: boolean;
  hasSetEmployee?: boolean;
}

const mapToItemType = (editType: DailyEditingType) => {
  if (editType === DailyEditingType.REASSIGNED) return ItemTypes.REASSIGNED;
  return editType === DailyEditingType.ROSTER ? ItemTypes.ROSTER_SHIFT : ItemTypes.TEMPLATE_SHIFT;
};

export const ShiftTimePicker: FC<Props> = ({
  shift: extShift,
  updateShift,
  type,
  readOnly,
  useColorizedBar,
  hasSetEmployee,
  ghostShifts
}) => {
  const [shift, setShift] = useState<WorkingShift>(extShift);

  const debouncedUpdate = useDebouncedCallback(updateShift, 100);

  useEffect(() => {
    if (shift !== extShift) {
      debouncedUpdate(shift);
    }
  }, [shift, debouncedUpdate]);

  useEffect(() => {
    setShift(extShift);
  }, [extShift, setShift]);

  const mainSection =
    shift.start !== undefined && shift.end !== undefined
      ? {
          id: shift.id,
          startTime: shift.start,
          endTime: shift.end,
          color: itemTypeToColor(mapToItemType(type), useColorizedBar, hasSetEmployee),
          onChangeTimes: (newStart: number, newEnd: number) => {
            const newShift = { ...shift };
            newShift.start = newStart;
            newShift.end = newEnd;
            setShift(newShift);
          },
          clear: () => {
            const newShift = { ...shift };
            newShift.start = undefined;
            newShift.end = undefined;
            newShift.breaks = [];
            newShift.tasks = [];
            setShift(newShift);
          }
        }
      : undefined;

  const subsections = useBuildGanttSubSections(shift, setShift, type);

  const ghostSections: GhostGanttSection[] | undefined = ghostShifts?.map(shift => ({
    ...shift,
    color: itemTypeToColor(mapToItemType(type), useColorizedBar, true)
  }));

  const onShiftAll = (change: number) => {
    const newShift = { ...shift };

    if (typeof newShift.start === 'number' && typeof newShift.end === 'number') {
      newShift.start = newShift.start + change;
      newShift.end = newShift.end + change;

      newShift.tasks = newShift.tasks.map(task => {
        const newTask = {...task};
        newTask.start = newTask.start + change;
        newTask.end = newTask.end + change;

        return newTask;
      });

      newShift.breaks = newShift.breaks.map(brek => {
        const newBrek = {...brek};
        newBrek.start = newBrek.start + change;
        newBrek.end = newBrek.end + change;

        return newBrek;
      });

    }

    setShift(newShift);
  };

  return (
    <GanttTimePicker
      mainSection={mainSection}
      readOnly={readOnly}
      subSections={subsections}
      ghostSections={ghostSections}
      onShiftAll={onShiftAll}
      addSection={(start: number, end: number, context: string, main?: boolean) => {
        if (readOnly) {
          return
        }
        
        if (main) {
          const newShift = { ...shift };
          newShift.start = start;
          newShift.end = end;
          setShift(newShift);
        } else {
          const newShift = { ...shift };

          const newSubsection: WorkingShiftSubsection = {
            id: `CREATE-${uuid()}`,
            type: '',
            start,
            end
          };

          if (context === ItemTypes.BREAK) {
            newSubsection.type = ItemTypes.BREAK;
            const newBreaks = [...newShift.breaks];
            newBreaks.push(newSubsection);
            newShift.breaks = newBreaks;
          } else {
            newSubsection.type = ItemTypes.TASK;
            newSubsection.typeContext = context;
            const newTasks = [...newShift.tasks];
            newTasks.push(newSubsection);
            newShift.tasks = newTasks;
          }

          setShift(newShift);
        }
      }}
    />
  );
};
