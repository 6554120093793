import { LoadingIndicator } from '@/components/LoadingIndicator';
import { ErrorMessage } from '@/components/error/ErrorMessage';
import { GanttMarkerLine, GanttProvider } from '@/components/gantt/gantt/GanttProvider';
import { GanttTimerBar } from '@/components/gantt/gantt/GanttTimerBar';
import { Modal } from '@/components/modal/Modal';
import NotesField from '@/components/notes/NotesField';
import { EditNotesFields } from '@/components/notes/NotesModal';
import { DailyRosterToolTip } from '@/components/tooltip/RosterTooltip';
import { secondsToHours, secondsToTimeString } from '@/helpers/dateHelper';
import { toastMessage } from '@/helpers/helper';
import { adjustPadLength, calculateScratchpadLength } from '@/helpers/scratchpadHelper';
import { cn } from '@/lib/utils';
import { WorkingShift } from '@/pages/daily/daily.types';
import { ReassignedShifts } from '@/pages/daily/roster/ReassignedShifts';
import {
  EMPLOYEE_DETAILS_COLUMN_WIDTH,
  EMPLOYEE_INFO_WIDTH,
  EMPLOYEE_NAME_COLUMN_WIDTH
} from '@/pages/summary/constants';
import { MonthlyTotalHeader } from '@/pages/summary/monthly/MonthlyTotalHeader';
import { useWorkPreferencesTracking } from '@/services/availability/WorkPreferencesTrackingService';
import { ReadRosterPositions } from '@/services/gql/graphql.generated';
import { useLocalSettings, useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { OpenClosingTimeOptions } from '@/services/settings/systemSettings.types';
import { useOpenClosingTimePref } from '@/services/settings/useOpenClosingTimePref';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/strings';
import { useRequestedShifts } from '@/services/useRequestedShifts';
import { hoursToSeconds } from 'date-fns';
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AttendanceTotalHeader } from '../AttendanceTotalHeader';
import { AttendanceTotalSummary } from '../AttendanceTotalSummary';
import { DragDropButtons } from '../DragDropButtons';
import { OpenClosingTimePicker } from '../OpenClosingTimePicker';
import { StartEndTimePicker } from '../StartEndTimePicker';
import { DailyEditingType } from '../daily.types';
import { useSubsections } from '../useSubsections';
import { RosterBudgetSummary } from './RosterBudgetSummary';
import { RosterCreatePage } from './RosterCreatePage';
import { RosterHourSummary } from './RosterHourSummary';
import { RosterShifts } from './RosterShifts';
import { UnassignedGhostShifts } from './UnassignedGhostShifts';
import { useReassignmentService } from './providers/ReassignmentService';
import { useRosterAllEmployeesSummary, useRosterStats, useWorkingRoster } from './providers/WorkingRosterService';
import { useSaveRoster } from './useSaveRoster';
import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { useComplianceDataTotals } from '@/services/summary/useCompliance';

interface RosterProps {
  setIsSaveFetching: (isFetching: boolean) => void;
}

export const Roster: FC<RosterProps> = ({ setIsSaveFetching }) => {
  const { id: locationId } = useSelectedLocation();
  const minuteIncrement = useSystemSettings(state => state.settings.minuteIncrement);
  const minShiftDuration = useSystemSettings(state => state.settings.minShiftDuration);
  const defaultShiftDuration = useSystemSettings(state => state.settings.defaultShiftDuration);

  // used so the tasks can be used to calculate the actual time
  const tasks = useTasks();

  const { reassignedShifts, setReassignedShifts } = useReassignmentService(state => ({
    reassignedShifts: state.reassignedShifts,
    setReassignedShifts: state.setReassignedShifts
  }));

  const [isSendStaffModalOpen, setIsSendStaffModalOpen] = useState(false);

  const { saveRoster, fetching } = useSaveRoster();
  const { workingRoster, setWorkingRoster } = useWorkingRoster();

  const { requestedShifts, removeRequestedShift } = useRequestedShifts();

  const addShift = useCallback(() => {
    if (!workingRoster) {
      throw Error('Working Roster should be set');
    }
    let startTime = workingRoster.startTime + hoursToSeconds(2);
    let endTime = startTime + Number(defaultShiftDuration);

    if (endTime > workingRoster.endTime) {
      startTime = workingRoster.startTime;
      endTime = startTime + Number(defaultShiftDuration);
    }

    const newShift: WorkingShift = {
      id: `CREATE-${uuidv4()}`,
      start: startTime,
      end: endTime,
      breaks: [],
      tasks: [],
      properties: {}
    };

    const shifts = [...workingRoster.shifts, newShift];
    setWorkingRoster({ ...workingRoster, shifts });
  }, [setWorkingRoster, workingRoster]);

  const reassignShift = useCallback(
    (shift: ReadRosterPositions, requestingLocationid: number) => {
      if (!reassignedShifts.find(s => s.id === shift.id)) {
        const reassignedShift: WorkingShift = {
          id: shift.id,
          start: shift.start,
          end: shift.end,
          tasks: [],
          breaks: [],
          properties: JSON.parse(shift.properties!), // The properties must exist here
          assignedEmployeeId: undefined,
          assignedContractId: undefined,
          locationId: requestingLocationid
        };
        delete reassignedShift.properties!.requesting;
        reassignedShift.properties!['providingLocationId'] = locationId;
        setReassignedShifts([...reassignedShifts, reassignedShift]);
        removeRequestedShift(shift);
      }

      setIsSendStaffModalOpen(false);
    },
    [setIsSendStaffModalOpen, reassignedShifts, setReassignedShifts, removeRequestedShift, locationId]
  );

  const selectedDate = useLocalSettings(s => s.selectedDate);
  const isInPast = new Date() > new Date(selectedDate);

  const subsections = useSubsections();

  useWorkPreferencesTracking(selectedDate, selectedDate);

  const hoursCovered = workingRoster ? secondsToHours(workingRoster.endTime - workingRoster.startTime) : 0;

  const onChangeStart = useCallback(
    (start: number) => {
      if (!workingRoster) {
        throw Error('Working roster should be defined');
      }

      const errorStartRow = workingRoster.shifts.find(shift => shift.start && shift.start < start);

      if (errorStartRow?.start) {
        toastMessage(strings.daily.changeTimes.startError(secondsToTimeString(errorStartRow.start)));
      } else {
        const newScratchpad = adjustPadLength(
          workingRoster.scratchpad,
          'start',
          calculateScratchpadLength(start, workingRoster.endTime)
        );
        setWorkingRoster({
          ...workingRoster,
          startTime: start,
          openTime: workingRoster.openTime !== undefined ? Math.max(workingRoster.openTime, start) : undefined,
          scratchpad: newScratchpad
        });
      }
    },
    [workingRoster, setWorkingRoster]
  );

  const onChangeEnd = useCallback(
    (end: number) => {
      if (!workingRoster) {
        throw Error('Working roster should be defined');
      }
      const errorEndRow = workingRoster.shifts.find(shift => shift.end && shift.end > end);

      if (errorEndRow?.end) {
        toastMessage(strings.daily.changeTimes.endError(secondsToTimeString(errorEndRow.end)));
      } else {
        const newScratchpad = adjustPadLength(
          workingRoster.scratchpad,
          'end',
          calculateScratchpadLength(workingRoster.startTime, end)
        );
        setWorkingRoster({
          ...workingRoster,
          endTime: end,
          closeTime: workingRoster.closeTime !== undefined ? Math.min(workingRoster.closeTime, end) : undefined,
          scratchpad: newScratchpad
        });
      }
    },
    [workingRoster, setWorkingRoster]
  );

  const openClosingTimesPref = useOpenClosingTimePref();

  const onChangeOpen = useCallback(
    (open?: number) => {
      if (!workingRoster) {
        throw Error('Working roster should be defined');
      }

      if (typeof open === 'number' && open < workingRoster.startTime) {
        toastMessage(strings.daily.changeTimes.openBeforeStartError);
        return;
      }

      if (typeof workingRoster.closeTime === 'number' && typeof open === 'number' && open >= workingRoster.closeTime) {
        toastMessage(strings.daily.changeTimes.closeBeforeOpenError);
        return;
      }

      setWorkingRoster({ ...workingRoster, openTime: open });
    },
    [workingRoster]
  );

  const onChangeClose = useCallback(
    (close?: number) => {
      if (!workingRoster) {
        throw Error('Working roster should be defined');
      }

      if (close !== undefined && close > workingRoster.endTime) {
        toastMessage(strings.daily.changeTimes.closeAfterEndError);
        return;
      }

      if (workingRoster.openTime !== undefined && close !== undefined && close <= workingRoster.openTime) {
        toastMessage(strings.daily.changeTimes.closeBeforeOpenError);
        return;
      }

      setWorkingRoster({ ...workingRoster, closeTime: close });
    },
    [workingRoster]
  );

  const submitNotes = async (data: EditNotesFields) => {
    workingRoster!.notes = data.notes;
    setWorkingRoster({ ...workingRoster! });
    saveRoster();
  };

  useEffect(() => {
    setIsSaveFetching(fetching);
  }, [fetching]);

  const markerLines: GanttMarkerLine[] = useMemo(() => {
    if (openClosingTimesPref !== OpenClosingTimeOptions.all) {
      return [];
    }

    const lines: GanttMarkerLine[] = [];

    if (workingRoster?.openTime) {
      lines.push({ time: workingRoster.openTime, color: '#22c55e' });
    }

    if (workingRoster?.closeTime) {
      lines.push({ time: workingRoster.closeTime, color: '#ef4444' });
    }

    return lines;
  }, [workingRoster, openClosingTimesPref]);

  const summary = useRosterAllEmployeesSummary();
  const complianceTotals = useComplianceDataTotals(summary);
  const rosterStats = useRosterStats();

  /*
   * DO NOT ADD ANYTHING BELOW THIS BIT
   * IT WILL BREAK THE RULE OF HOOKS
   */
  if (!workingRoster && isInPast) {
    return <ErrorMessage message={strings.daily.roster.noRosterCreated}></ErrorMessage>;
  } else if (!workingRoster) {
    return <RosterCreatePage />;
  } else {
    return (
      <>
        <GanttProvider
          markerLines={markerLines}
          subsections={subsections}
          minTime={workingRoster.startTime}
          maxTime={workingRoster.endTime}
          minMainLength={minShiftDuration}
          minuteIncrement={minuteIncrement}
        >
          <div id="roster-shifts-wrapper" className="flex-1 flex flex-col overflow-hidden">
            <div>
              <RosterBudgetSummary />
            </div>
            <div id="roster-table-wrapper" className="overflow-scroll flex-1 relative">
              <div style={{ minWidth: 100 * hoursCovered }}>
                <div className="sticky top-0 z-[100]">
                  <div className="flex py-1 flex-row border-b bg-background-mid content-between">
                    <div
                      className="flex sticky top-0 left-0 z-[150] items-center px-10 font-semibold space-x-2"
                      style={{ width: EMPLOYEE_INFO_WIDTH }}
                    >
                      <StartEndTimePicker
                        startTime={workingRoster.startTime}
                        endTime={workingRoster.endTime}
                        minLength={defaultShiftDuration}
                        onChangeStart={onChangeStart}
                        onChangeEnd={onChangeEnd}
                        readonly={workingRoster.readOnly}
                      />
                      {openClosingTimesPref !== OpenClosingTimeOptions.hidden && (
                        <OpenClosingTimePicker
                          key={workingRoster.id}
                          openTime={workingRoster.openTime}
                          closeTime={workingRoster.closeTime}
                          onChangeOpen={onChangeOpen}
                          onChangeClose={onChangeClose}
                          readonly={workingRoster.readOnly}
                        />
                      )}
                    </div>

                    {/* adding div to add extra space to the div otherwise right-0 is next to the OpenClosingTimerPicker  */}
                    <div className="flex items-center flex-grow"></div>

                    <div className="flex sticky top-0 right-0">
                      <NotesField
                        notes={workingRoster.notes}
                        historyNotes={workingRoster.rosterHistoryNotes}
                        emptyNoteString={strings.daily.roster.noNotes}
                        subsectionTitle={strings.daily.roster.rosterHistory}
                        submitNotes={submitNotes}
                        readonly={workingRoster.readOnly}
                      />
                    </div>
                  </div>
                  <RosterHourSummary />
                  <div className="flex flex-row border-b bg-background-mid">
                    <div className="flex sticky left-0 border-r">
                      <div
                        className="border-r flex items-start justify-end pt-1 pr-1"
                        style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
                      >
                        <DailyRosterToolTip />
                      </div>
                      <div className="flex" style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }}>
                        <div className="flex-[2] border-r">
                          <MonthlyTotalHeader />
                        </div>
                        <div className="flex-[3]">
                          <AttendanceTotalHeader />
                        </div>
                      </div>
                    </div>
                    <div className={'bg-background-mid border-b'}></div>
                  </div>
                  <div className="flex flex-row">
                    <div className={cn('flex border-b bg-background-mid sticky left-0 border-r z-[70]')}>
                      <div
                        className="flex items-center justify-center px-2 gap-2.5 font-semibold border-r"
                        style={{ width: EMPLOYEE_NAME_COLUMN_WIDTH }}
                      >
                        <span className="text-[#323232bf]">{strings.common.employee}</span>
                        {!workingRoster.readOnly && (
                          <div className="flex items-center justify-center content-center gap-2.5">
                            <img
                              className="hover:cursor-pointer"
                              src="/add.svg"
                              alt="Add shift"
                              width="20 lg:25"
                              onClick={addShift}
                            />
                            <img
                              className="hover:cursor-pointer"
                              src="/person-btn.svg"
                              alt="Staff allocation"
                              width="20 lg:25"
                              onClick={() => setIsSendStaffModalOpen(true)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex" style={{ width: EMPLOYEE_DETAILS_COLUMN_WIDTH }}>
                        <div className="flex-[2] border-r flex">
                          <InfoHeaderCell className="border-r flex-1" />
                          <InfoHeaderCell className="flex-1">
                            {secondsToTimeString(complianceTotals.actualTime)}
                          </InfoHeaderCell>
                        </div>
                        <div className="flex-[3] flex">
                          <AttendanceTotalSummary rosterStats={rosterStats} />
                        </div>
                      </div>
                    </div>
                    <GanttTimerBar />
                  </div>
                </div>
                <Suspense fallback={<LoadingIndicator />}>
                  <RosterShifts />
                  <ReassignedShifts readonlyEmployeeSelector={workingRoster.readOnly} />
                  <UnassignedGhostShifts />
                </Suspense>
              </div>
            </div>
            <DragDropButtons readOnly={workingRoster.readOnly} type={DailyEditingType.ROSTER} showKeys={true} />
          </div>
        </GanttProvider>
        <Modal onClose={() => setIsSendStaffModalOpen(false)} open={isSendStaffModalOpen}>
          <div className="flex flex-col gap-5 select-none">
            <div className="flex items-center justify-center text-lg font-bold text-[#E8AA14] drop-shadow">
              {strings.daily.staffAllocation.sendStaffToAnotherLocation}
            </div>

            <div className="text-[#323232]">
              {requestedShifts?.length ? (
                <>
                  <span className="text-[#002742] font-semibold text-md">
                    {strings.daily.staffAllocation.selectShiftsAndLocations}:
                  </span>
                  <div>
                    {requestedShifts.map(requested => {
                      return (
                        <div key={requested.locationId} className="p-2">
                          <span className="font-semibold text-sm">
                            {requested.locationNumber} - {requested.locationName}
                          </span>
                          {requested.shifts?.map((shift: any) => {
                            return (
                              <div
                                key={shift.id}
                                className="px-4 py-px text-[#0070D2] rounded hover:bg-[#EFEFC2] hover:cursor-pointer"
                                onClick={() => reassignShift(shift, requested.locationId)}
                              >{`${secondsToTimeString(shift.start)} ~ ${secondsToTimeString(shift.end)}`}</div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center font-bold px-4">
                  {strings.daily.staffAllocation.noNeedOfAllocation}!
                </div>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
};
