import { FC, PropsWithChildren } from 'react';
import { RosterOptionsProvider } from './RosterOptionsService';
import { WorkingRosterProvider } from './WorkingRosterService';
import { RosterWarningsProvider } from './RosterWarningsService';
import { ReassignmentProvider } from './ReassignmentService';

export const RosterProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <RosterOptionsProvider>
      <WorkingRosterProvider>
        <ReassignmentProvider>
          <RosterWarningsProvider>{children}</RosterWarningsProvider>
        </ReassignmentProvider>
      </WorkingRosterProvider>
    </RosterOptionsProvider>
  );
};
