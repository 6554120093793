import { FunctionComponent, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends PropsWithChildren {
  className?: string;
}

export const TABLE_ROW_CELL_CLASSES = 'break-all border border-[#DDDDDD] p-3 flex justify-center items-center';

const TableRowCell: FunctionComponent<Props> = ({ className, children }) => {
  return <div className={twMerge(TABLE_ROW_CELL_CLASSES, className)}>{children}</div>;
};

export default TableRowCell;
