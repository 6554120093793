import { TooltipTypes } from '@/components/tooltip/Tooltip';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { FC } from 'react';
import { DropZoneItem } from './GanttDropZone';
import { GanttGhostSection } from './GanttGhostSection';
import { useGanttService, useGanttTimeSlots } from './GanttProvider';
import { GanttSlider } from './GanttSlider';
import { GanttSubsection } from './GanttSubsection';
import { Grid } from './Grid';
import { findSlotIndex } from './ganttHelper';

export interface GhostGanttSection {
  startTime: number;
  endTime: number;
  color: string;
}

export interface GanttTaskCapabilityTooltip {
  text: string;
  type: TooltipTypes;
}

export interface GanttSection {
  id: string;
  startTime: number;
  endTime: number;
  onChangeTimes: (newStart: number, newEnd: number) => void;
  clear: () => void;
  color: string;
  type?: string;
  typeContext?: string;
  icon?: JSX.Element;
  iconColor?: string;
  tooltip?: string;
  capabilityTooltip?: GanttTaskCapabilityTooltip;
}

interface GanttTimePickerProps {
  mainSection?: GanttSection;
  subSections?: GanttSection[];
  ghostSections?: GhostGanttSection[];
  addSection: (start: number, end: number, context: string, main?: boolean) => void;
  onShiftAll: (change: number) => void;
  readOnly?: boolean;
}

export const DEFAULT_MINUTE_INCREMENT = 15;
export const TIME_PICKER_CELL_SIZE = 120;
export const END_BUFFER_CELL_SIZE = 100;

export const GanttTimePicker: FC<GanttTimePickerProps> = ({
  mainSection,
  subSections,
  ghostSections,
  addSection,
  onShiftAll,
  readOnly
}) => {
  const subsectionConfigs = useGanttService(state => state.subsections);

  const onDrop = (item: DropZoneItem, start: number, end: number) => {
    if (item.main) {
      if (!mainSection) {
        addSection(start, end, 'main', true);
      }
    } else if (mainSection) {
      let canBeAdded = true;

      const subsectionConfig = subsectionConfigs?.find(sub => sub.id === item.type);
      const startBuffer = subsectionConfig?.startBuffer || 0;
      const endBuffer = subsectionConfig?.endBuffer || 0;

      let startToAdd = Math.max(mainSection.startTime, start);
      let endToAdd = Math.min(mainSection.endTime, end);
      const length = end - start;


      // If the subsection would go outside the main bar, correct it.
      if (startToAdd < mainSection.startTime + startBuffer) {
        startToAdd = mainSection.startTime + startBuffer;
        endToAdd = startToAdd + length;
      }

      if (endToAdd > mainSection.endTime - endBuffer) {
        endToAdd = mainSection.endTime - endBuffer;
        startToAdd = endToAdd - length;
      }

      // Check each existing subsection to make sure there's no overlaps.
      subSections?.forEach(sub => {
        // If the start time lies inside another subsection, there's an overlap.
        if (sub.startTime < startToAdd && startToAdd < sub.endTime) {
          canBeAdded = false;
        }

        // If the end time lies inside another subsection, there's an overlap.
        if (sub.startTime < endToAdd && endToAdd < sub.endTime) {
          canBeAdded = false;
        }

        if (sub.startTime === startToAdd && sub.endTime === endToAdd) {
          canBeAdded = false;
        }

        // If the sub's start time lies inside the new section, there's an overlap.
        if (startToAdd < sub.startTime && sub.startTime < endToAdd) {
          canBeAdded = false;
        }

        // If the sub's end time lies inside the new section, there's an overlap.
        if (startToAdd < sub.endTime && sub.endTime < endToAdd) {
          canBeAdded = false;
        }
      });

      if (!canBeAdded) {
        return;
      }

      addSection(startToAdd, endToAdd, item.context || item.type);
    }
  };

  const timeSlots = useGanttTimeSlots();
  let resizeEndMinSlot: number | undefined;
  let resizeStartMaxSlot: number | undefined;

  if (subSections?.length) {
    const sortedSubsections = subSections.sort((a, b) => a.startTime - b.startTime);

    const firstSub = sortedSubsections[0];
    const firstSubConfig = subsectionConfigs?.find(sub => sub.id === firstSub.type);
    let resizeStartMax = firstSub.startTime;
    if (firstSubConfig?.startBuffer) {
      resizeStartMax = resizeStartMax - firstSubConfig.startBuffer;
    }
    resizeStartMaxSlot = findSlotIndex(resizeStartMax, timeSlots, true);

    const lastSub = sortedSubsections[sortedSubsections.length - 1];
    const lastSubConfig = subsectionConfigs?.find(sub => sub.id === lastSub.type);
    let resizeEndMin = lastSub.endTime;
    if (lastSubConfig?.endBuffer) {
      resizeEndMin = resizeEndMin + lastSubConfig.endBuffer;
    }
    resizeEndMinSlot = findSlotIndex(resizeEndMin, timeSlots, false);
  }

  const gapBetween = useSystemSettings(s => s.settings.splitShifts.minSecondsBetween);
  const indexGapBetween = gapBetween / (timeSlots[0].end - timeSlots[0].start);

  let maximumSlot: number | undefined;
  let minimumSlot: number | undefined;

  ghostSections?.forEach(g => {
    const startSlot = findSlotIndex(g.startTime, timeSlots, true);
    const endSlot = findSlotIndex(g.endTime, timeSlots, false);

    if (startSlot >= 0) {
      const possibleMaxSlot = startSlot - 1 - indexGapBetween;
      if (maximumSlot === undefined) {
        maximumSlot = possibleMaxSlot;
      }
      maximumSlot = Math.min(maximumSlot, possibleMaxSlot);
    }

    if (endSlot < timeSlots.length) {
      const possibleMinSlot = endSlot + 1 + indexGapBetween;
      if (minimumSlot === undefined) {
        minimumSlot = possibleMinSlot;
      }
      minimumSlot = Math.max(minimumSlot, possibleMinSlot);
    }
  });

  // get only the allowed slots that subsections can use
  const startSlot = mainSection?.startTime ? findSlotIndex(mainSection.startTime, timeSlots, true) : 0;
  const endSlot = mainSection?.endTime ? findSlotIndex(mainSection.endTime, timeSlots, false) : timeSlots.length - 1;

  const minuteIncrement = useSystemSettings(state => state.settings.minuteIncrement);
  const minSlotLength = useSystemSettings(state => state.settings.minShiftDuration) / 60 / minuteIncrement;

  return (
    <div className="flex w-full pr-9 overflow-hidden">
      <div className="flex-1 relative h-[5.5rem]">
        <Grid onDrop={onDrop} />
        {mainSection && (
          <>
            <GanttSlider
              startSlot={startSlot}
              endSlot={endSlot}
              readOnly={readOnly}
              onChangeStart={newStart => mainSection.onChangeTimes(newStart, mainSection.endTime)}
              onChangeEnd={newEnd => mainSection.onChangeTimes(mainSection.startTime, newEnd)}
              onChangeStartAndEnd={newStart => {
                onShiftAll(newStart - mainSection.startTime);
              }}
              onClear={mainSection.clear}
              color={mainSection.color}
              timeSlots={timeSlots}
              minSlotIndex={minimumSlot || 0}
              maxSlotIndex={maximumSlot || timeSlots.length - 1}
              minimumSlotLength={minSlotLength}
              resizeEndMinSlot={resizeEndMinSlot}
              resizeStartMaxSlot={resizeStartMaxSlot}
            />
            {subSections?.map((section, index) => {
              const subsectionConfig = subsectionConfigs?.find(sub => sub.id === section.type);
              const startBuffer = subsectionConfig?.startBuffer || 0;
              const endBuffer = subsectionConfig?.endBuffer || 0;


              const otherSubSections = [...subSections];
              otherSubSections.splice(index, 1);

              return (
                <GanttSubsection
                  mainSection={mainSection}
                  key={section.id}
                  subSection={section}
                  readOnly={readOnly}
                  otherSubSections={otherSubSections}
                  max={mainSection.endTime - endBuffer}
                  min={mainSection.startTime + startBuffer}
                  timeSlots={timeSlots}
                  minSubSlotIndex={startSlot}
                  maxSubSlotIndex={endSlot}
                />
              );
            })}
          </>
        )}
        {ghostSections?.map((ghost, index) => (
          <GanttGhostSection ghostSection={ghost} key={index} timeSlots={timeSlots} />
        ))}
      </div>
    </div>
  );
};
