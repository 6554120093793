import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { INDEX_COLUMN_WIDTH, TableColumn } from '../../layout/SettingsTable';
import { SettingsTableHeaderCell } from '../../layout/SettingsTableHeaderCell';

interface Props {
  columns: TableColumn[];
  showIndexColumn?: boolean;
  calculatedOffset?: number;
  className?: string;
  headerDescription?: boolean;
}

export const TasksTableHeader: FC<Props> = ({
  columns,
  showIndexColumn,
  calculatedOffset = 0,
  className,
  headerDescription
}) => {
  if (columns.length < 1) {
    throw Error('Must provide at least one column');
  }

  return (
    <div className="sticky flex top-0 z-10">
      {showIndexColumn && (
        <SettingsTableHeaderCell
          className="sticky left-0 top-0"
          style={{ width: INDEX_COLUMN_WIDTH, zIndex: columns.length + 1 }}
        />
      )}
      {columns.map((col, index) => {
        let styles: React.CSSProperties = {
          zIndex: columns.length - index
        };

        if (col.width === 'auto') {
          styles.flexGrow = 1;
        } else {
          styles.width = col.width + 'px';
        }

        if (col.isFixedPosition) {
          styles.left =
            calculatedOffset !== undefined
              ? calculatedOffset + (showIndexColumn ? INDEX_COLUMN_WIDTH : 0)
              : calculatedOffset;
        }

        return (
          <SettingsTableHeaderCell
            key={col.name}
            style={styles}
            className={twMerge('sticky top-0 select-none', className)}
          >
            {col.name}
          </SettingsTableHeaderCell>
        );
      })}
    </div>
  );
};
