import { WarningPopup } from '@/components/warning/WarningPopup';
import { getSeverityType } from '@/components/warning/severityHelper';
import { WarningModel } from '@/components/warning/warningDefinitions';
import { FC } from 'react';
import { WarningsList } from './WarningsList';

interface Props {
  warnings: WarningModel[];
}

export const SeverityIndicator: FC<Props> = ({ warnings }) => {
  const severity = getSeverityType(warnings);

  return (
    <>
      {warnings.length > 0 && (
        <WarningPopup severity={severity} parentContainerId={'roster-table-wrapper'}>
          <WarningsList warnings={warnings} />
        </WarningPopup>
      )}
    </>
  );
};
