import { toastError, toastSuccess } from '@/helpers/helper';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { } from '@/services/gql/graphql.generated';
import { LeaveLimitPeriod, LeaveMeasurementUnit, LeaveType } from '@/services/leaveManagement/leave.types';
import { useCreateLeaveTypes, useDeleteLeaveTypes, useLeaveTypes, useRefreshLeaveTypes, useUpdateLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { strings } from '@/services/translation/strings';
import { useCallback, useState } from 'react';
import uuid from 'react-uuid';

export const useEditAdvancedLeave = () => {
  const fetchedLeaveTypes = useLeaveTypes();
  const [leaveTypes, setLeaveTypes] = useState<LeaveType[]>(fetchedLeaveTypes);

  const [canSave, setCanSave] = useState<boolean>(false);

  const [idsToAdd, setIdsToAdd] = useState<string[]>([]);
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);

  const { createLeaveTypes } = useCreateLeaveTypes();
  const { updateLeaveTypes } = useUpdateLeaveTypes();
  const { deleteLeaveTypes } = useDeleteLeaveTypes();
  const refresh = useRefreshLeaveTypes();

  const onAdd = useCallback(() => {
    setCanSave(true);

    let newName = `${strings.settings.manageLeave.leaveType} ${leaveTypes.length + 1}`;
    const similarNames = leaveTypes.filter(t => t.name === newName);
    if (similarNames.length) {
      newName += `-${similarNames.length}`;
    }

    const newLeaveType: LeaveType = {
      id: `CREATE-${uuid()}`,
      name: newName,
      active: false,
      properties: {
        displayName: '',
        systemCode: '',
        color: '',
        tapNumber: -1
      },
      limitSettings: {
        max: {
          value: 0,
          period: LeaveLimitPeriod.ROSTER,
          mandatory: false
        },
        min: {
          value: 0,
          period: LeaveLimitPeriod.ROSTER,
          mandatory: false
        },
        unit: LeaveMeasurementUnit.DAYS,
        consecutiveMax: 0,
        consecutiveOverride: false,
        availableInEss: false
      }
    };

    idsToAdd.push(newLeaveType.id);

    setLeaveTypes([...leaveTypes, newLeaveType]);
  }, [leaveTypes, idsToAdd]);

  const onUpdate = (leaveType: LeaveType) => {
    setCanSave(true);

    const newLeaveTypes = [...leaveTypes];

    const index = leaveTypes.findIndex(s => s.id === leaveType.id);
    newLeaveTypes[index] = { ...leaveType };
    setLeaveTypes(newLeaveTypes);
  };

  const onDelete = (id: number | string, isToBeDeleted: boolean) => {
    setCanSave(true);

    const idsToBeDeleted = [...idsToDelete];
    const deleteIndex = idsToBeDeleted.indexOf(id as any);

    if (deleteIndex === -1 && isToBeDeleted) {
      idsToBeDeleted.push(id as any);
    } else if (deleteIndex >= 0 && !isToBeDeleted) {
      idsToBeDeleted.splice(deleteIndex, 1);
    }

    setIdsToDelete(idsToBeDeleted);
  };

  const onSave = async () => {
    let errorHasOccured = false;

    const approvedForUpdate = leaveTypes
      .filter(t => !idsToDelete.includes(t.id) && !t.id.includes('CREATE'))
      .map(lt => lt.id);
    const approvedForAdd = idsToAdd.filter(t => !idsToDelete.includes(t));

    if (idsToDelete.length) {
      setIdsToAdd(idsToAdd.filter(t => !idsToDelete.includes(t)));
      const approvedForDelete = idsToDelete.filter(t => !t.includes('CREATE'));

      try {
        if (approvedForDelete.length) {
          const deleteResult = await deleteLeaveTypes(approvedForDelete);
          setIdsToDelete([]);

          if (deleteResult.error) {
            errorHasOccured = true;
          }
        }
      } catch (error) {
        console.log('Delete leave types error', error);
      }

      setLeaveTypes(leaveTypes.filter(t => !idsToDelete.includes(t.id)));
      setIdsToDelete([]);
    }

    const remainingAdvancedLeaveTypes = leaveTypes.filter(
      t => !idsToDelete.includes(t.id) && t.id !== FixedLeaveTypeId.ROSTER
    );

    let tapNumber = 1;
    remainingAdvancedLeaveTypes.forEach(lt => {
      if (lt.active) {
        lt.properties.tapNumber = tapNumber;
        tapNumber++;
      } else {
        lt.properties.tapNumber = -1;
      }
    });

    if (approvedForAdd.length) {
      try {
        const result = await createLeaveTypes(leaveTypes.filter(p => approvedForAdd.includes(p.id)));

        if (result.error) {
          errorHasOccured = true;
        } else {
          setIdsToAdd([]);
        }
      } catch (error) {
        console.log('Add personas error', error);
      }
    }

    if (approvedForUpdate.length) {
      try {
        const result = await updateLeaveTypes(leaveTypes.filter(p => approvedForUpdate.includes(p.id)));

        if (result.error) {
          errorHasOccured = true;
        }
      } catch (error) {
        console.log('Update personas error', error);
      }
    }

    if (errorHasOccured) {
      toastError();
    } else {
      toastSuccess();
      refresh();
    }

    setCanSave(false);
  };

  return {
    leaveTypes,
    onAdd,
    onUpdate,
    onDelete,
    onSave,
    canSave,
    idsToBeDeleted: idsToDelete
  };
};
