import { FC } from 'react';
import { GhostGanttSection } from './GanttTimePicker';
import { TimeSlot, findSlotIndex } from './ganttHelper';
import { Bar } from './Bar';

interface Props {
  ghostSection: GhostGanttSection;
  timeSlots: TimeSlot[];
}

export const GanttGhostSection: FC<Props> = ({ timeSlots, ghostSection }) => {
  let sectionStartSlot = findSlotIndex(ghostSection.startTime, timeSlots, true);
  let sectionEndSlot = findSlotIndex(ghostSection.endTime, timeSlots, false);

  return (
    <Bar
      ghost
      color={ghostSection.color}
      slotCount={timeSlots.length}
      startSlot={sectionStartSlot}
      endSlot={sectionEndSlot}
    />
  );
};
