import { dateToApiFormat } from '@/helpers/dateHelper';
import { usePost } from '../usePost';
import { useCallback } from 'react';
import { useUser } from '../authentication/hooks/user';

export const useOptimiserEndpoint = (errorCallback?: (message: string) => void) => {
  const { post, ...others } = usePost(() => {}, errorCallback);
  const { id: userId } = useUser();

  const optimise = useCallback(
    async (startDate: Date, endDate: Date, locationId: number) => {
      const endpoint = `/Optimiser/OptimiseMultiDayTemplated`;

      const body = {
        "start": dateToApiFormat(startDate),
        "end": dateToApiFormat(endDate),
        "locationid": locationId
      }

      return await post(JSON.stringify(body), endpoint, 'application/json');
    },
    [post, userId]
  );

  return { optimise, ...others };
};
