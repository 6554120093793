import { WorkingShift } from '@/pages/daily/daily.types';
import { EmployeeOptionSettingsContext } from '@/pages/daily/employeeSelector/EmployeeOptionSettingsProvider';
import { useSelectedLocation } from '@/services/settings/LocalSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC, useContext } from 'react';
import { AvailabilitiesFromOtherLocations } from '../roster/employeeSelector/AvailabilitiesFromOtherLocations';
import { EmployeeOption } from './EmployeeOption';
import { DropdownSettings } from './EmployeeSelector';
import { EmployeeForShiftItem } from './employeeForShift.types';

interface Props {
  employeeDropdownOptions: EmployeeForShiftItem[];
  dropdownSettings: DropdownSettings;
  shift: WorkingShift;
  onChange: (newOption: EmployeeForShiftItem) => void;
  onSetAllocationRequest: () => void;
  noStaffAvailableMessage: string;
}

interface DropdownColumnProps {
  key: string;
  translationPath?: string;
  useKeyAsTranslationProp?: boolean;
  wrapText?: boolean;
}

export const columnProps: DropdownColumnProps[] = [
  { key: 'status', useKeyAsTranslationProp: true },
  { key: 'name', useKeyAsTranslationProp: true },
  { key: 'timeAvailable', useKeyAsTranslationProp: true },
  { key: 'allocation', useKeyAsTranslationProp: true },
  { key: 'role', useKeyAsTranslationProp: true },
  { key: 'holidays', useKeyAsTranslationProp: true },
  { key: 'attendances', useKeyAsTranslationProp: true },
  { key: 'workingHours', useKeyAsTranslationProp: true },
  { key: 'tasks', useKeyAsTranslationProp: true },
  { key: 'taskNames', useKeyAsTranslationProp: true, wrapText: true }
];
const columnNames = columnProps.map(col => col.key);

export const EmployeeTable: FC<Props> = ({
  employeeDropdownOptions,
  dropdownSettings,
  shift,
  onChange,
  onSetAllocationRequest,
  noStaffAvailableMessage
}) => {
  const selectedLocation = useSelectedLocation();
  const config = useContext(EmployeeOptionSettingsContext);

  const handleOptionChange = (employeeId: string) => {
    let option: EmployeeForShiftItem | undefined = undefined;

    if (employeeId !== 'default_empty_option') {
      option = employeeDropdownOptions.find(em => em.id === employeeId);

      if (!option) {
        throw Error('Selected employee option not in list');
      }

      if (!option.assignable) {
        return;
      }
    }

    onChange(option!);
  };

  const defaultSelectEmptyOption: any = {
    id: 'default_empty_option',
    assignable: true
  };

  // If you are providing or provided a staff member, you are not allowed to ask other shops for the staff member.
  const staffProvided =
    shift?.locationId !== undefined &&
    shift?.assignedEmployeeId !== undefined &&
    selectedLocation &&
    shift.locationId !== selectedLocation.id;
  const staffProviding =
    shift?.properties &&
    shift.properties.providingLocationId !== undefined &&
    shift.properties.providingLocationId === selectedLocation.id;

  return (
    <div className={`table-dropdown overflow-y-auto`}>
      <table className="custom-table w-full border-collapse h-1" cellSpacing="0" cellPadding="0">
        <thead>
          <tr className="h-8 align-top">
            {columnNames.map(name => {
              return config.showStaffPickerColumn(name) ? (
                <></>
              ) : (
                <th
                  key={name}
                  className="text-sm text-center px-1 first:pl-1 first:text-start last:pr-1 bg-[#fafafa] sticky top-0"
                >
                  {(strings.daily.employeesDropdown.column as any)[name]}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {/* This is for default empty option which we can use to deselect already selected staff member */}
          <EmployeeOption
            key={shift?.id + '_EMPTY'}
            employee={defaultSelectEmptyOption}
            shift={shift}
            isEmptyOption={true}
            onClick={() => handleOptionChange(defaultSelectEmptyOption.id)}
          />
          {!employeeDropdownOptions.length && <tr className="group/tr">{noStaffAvailableMessage}</tr>}
          {employeeDropdownOptions.map(employee => (
            <EmployeeOption
              key={employee.id}
              employee={employee}
              shift={shift}
              onClick={() => handleOptionChange(employee.id)}
            />
          ))}
          {!(staffProvided || staffProviding) && config.reassignmentModel === 'on-request' && (
            <AvailabilitiesFromOtherLocations
              shift={shift!}
              selectedLocation={selectedLocation!.locationNumber!}
              onAllocationRequest={onSetAllocationRequest}
              tableColumnsCount={columnProps.length}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};
