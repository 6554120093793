import { strings } from "@/services/translation/strings";
import { useCallback } from "react";
import { useTemplateService } from "./TemplateService";

export const useValidateTemplateName = () => {
  const customTemplateList = useTemplateService(state => state.customTemplateOptions);

  return useCallback((inputValue: string) => {
    if (!inputValue.trim()) {
      return strings.daily.templates.myTemplates.nameRequired;
    }

    if (customTemplateList.some(template => template.name === inputValue || template.dbName === inputValue)) {
      return strings.daily.templates.myTemplates.nameExists;
    }

    return null;
  }, [customTemplateList]);
}