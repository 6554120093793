import { RosteredEmployee } from '@/services/gql/graphql.generated';

export interface WorkingShift {
  id: string;
  start?: number;
  end?: number;
  properties?: any;
  breaks: WorkingShiftSubsection[];
  tasks: WorkingShiftSubsection[];
  assignedContractId?: string;
  assignedEmployeeId?: string;
  assignedStaffHomeLocationId?: number;
  locationId?: number;
}

export interface WorkingShiftSubsection {
  id: string;
  type: string;
  typeContext?: string;
  start: number;
  end: number;
}

export enum DailyEditingType {
  ROSTER,
  TEMPLATE,
  REASSIGNED
}

export interface DailyProperties {
  scratchpad: number[];
  notes?: string;
  lastPublished?: string;
  libraryTemplateName?: string;
  importDate?: string;
  startTime?: number;
  endTime?: number;
  templateName?: string;
}

export interface StaffRequestingShift {
  employee: RosteredEmployee;
  start: number;
  end: number;
}
export interface StaffRequestingLocation {
  locationId: number;
  locationName: string;
  locationNumber: number;
  shifts: StaffRequestingShift[];
}
