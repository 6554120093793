import { InfoHeaderCell } from '@/components/table/InfoHeaderCell';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';

interface AttendanceTotalHeaderProps {}

export const AttendanceTotalHeader: FC<AttendanceTotalHeaderProps> = ({}) => {
  return (
    <>
      <div className="text-center border-b">{strings.common.totals.attendanceTotal}</div>
      <div className="flex">
        <InfoHeaderCell className="border-r">
          <p>{strings.common.totals.dailyTotal}</p>
        </InfoHeaderCell>
        <InfoHeaderCell className="border-r">
          <p>{strings.common.totals.actualTotal}</p>
        </InfoHeaderCell>
        <InfoHeaderCell className="">
          <p>{strings.common.totals.break}</p>
        </InfoHeaderCell>
      </div>
    </>
  );
};
