import { FC, PropsWithChildren, Suspense } from 'react';
import { MainHeader } from './MainHeader';
import { LoadingIndicator } from '@/components/LoadingIndicator';

export const PageContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="h-screen flex flex-col">
      <MainHeader />
      <div className="flex-1 overflow-hidden">
        <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
      </div>
    </div>
  );
};
