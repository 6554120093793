import { useCallback } from 'react';

export interface BudgetTableCell {
  location: number;
  day: number;
  index: number;
}

export const idToCell = (id: string): BudgetTableCell => {
  const split = id.split('-');

  return { location: parseInt(split[1]), day: parseInt(split[2]), index: parseInt(split[3]) };
};

export const cellToId = (cell: BudgetTableCell): string => {
  return `budget-${cell.location}-${cell.day}-${cell.index}`;
};

export const buildCellId = (locationIndex: number, dayIndex: number, index: number) => {
  return cellToId({ location: locationIndex, day: dayIndex, index });
};

export const useCellHelper = (locationCount: number, dayCount: number, indexCount: number) => {
  const cellDown = useCallback(
    (cell: BudgetTableCell) => {
      const newCell = { ...cell };

      newCell.index++;

      if (newCell.index === indexCount) {
        newCell.index = 0;
        newCell.location++;
      }

      if (newCell.location === locationCount) {
        return cell;
      } else {
        return newCell;
      }
    },
    [locationCount, indexCount]
  );

  const cellUp = useCallback(
    (cell: BudgetTableCell) => {
      const newCell = { ...cell };

      newCell.index--;

      if (newCell.index < 0) {
        newCell.index = indexCount - 1;
        newCell.location--;
      }

      if (newCell.location < 0) {
        return cell;
      } else {
        return newCell;
      }
    },
    [locationCount, indexCount]
  );

  const cellRight = useCallback(
    (cell: BudgetTableCell) => {
      const newCell = { ...cell };

      newCell.day++;

      if (newCell.day === dayCount) {
        newCell.day = 0;
        newCell.index++;
      }

      if (newCell.index === indexCount) {
        newCell.index = 0;
        newCell.location++;
      }

      if (newCell.location === locationCount) {
        return cell;
      } else {
        return newCell;
      }
    },
    [locationCount, indexCount, dayCount]
  );

  const cellLeft = useCallback(
    (cell: BudgetTableCell) => {
      const newCell = { ...cell };

      newCell.day--;

      if (newCell.day < 0) {
        newCell.day = dayCount - 1;
        newCell.index--;
      }

      if (newCell.index < 0) {
        newCell.index = indexCount - 1;
        newCell.location--;
      }

      if (newCell.location < 0) {
        return cell;
      } else {
        return newCell;
      }
    },
    [locationCount, indexCount, dayCount]
  );

  return { cellDown, cellLeft, cellRight, cellUp };
};
